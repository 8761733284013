import * as React from 'react';
import { useCallback, Dispatch, useMemo } from 'react';
import { CommandBarButton, Text } from 'office-ui-fabric-react';
import classNames from 'classnames';

import { Slideout } from '../../../../../components';

import { ManagedConfigSegment, SegmentToggleInfo } from '../../../constants';
import { visualOptionsSelectorBuilder } from '../../../lib';
import { useETPSelector } from '../../../../../store/editTile';

import { managedConfigComponents } from '../managedConfig';
import { DefaultVariants } from '../types';
import { SectionsAction } from './Container';

import { SectionToggle } from './SectionToggle';
import { ResetButton } from './ResetButton';

import styles from './ConfigSections.module.scss';

const Spacer = () => <div className={styles.spacer} />;

const iconProps = { iconName: 'ChevronDown' };

export interface EmptyConfigSectionProps {
    title: string;
    segmentToggleInfo: SegmentToggleInfo;
}

/**
 * Section with an toggle, but no configuration inside of it
 */
export const EmptyConfigSection: React.FC<EmptyConfigSectionProps> = ({ title, segmentToggleInfo }) => (
    <div className={styles.emptySection}>
        <Text className={styles.emptySectionTitle}>{title}</Text>
        <SectionToggle {...segmentToggleInfo} />
    </div>
);

export interface ConfigSegmentProps extends ManagedConfigSegment {
    open: boolean;
    sectionsDispatch: Dispatch<SectionsAction>;
    defaultVariants: DefaultVariants;
    hideReset: boolean;
}

export const ConfigSegment: React.FC<ConfigSegmentProps> = ({
    displayName,
    configKeys,
    segmentToggleInfo,
    open,
    sectionsDispatch,
    defaultVariants,
    hideReset,
}) => {
    const toggleOpen = useCallback(
        () => sectionsDispatch({ type: 'toggleSegmentOpen', displayName }),
        [displayName, sectionsDispatch]
    );

    const sectionUserDisabledSelector = useMemo(() => {
        if (segmentToggleInfo) {
            return visualOptionsSelectorBuilder((o) => {
                let enabled = o[segmentToggleInfo.optionKey];
                if (segmentToggleInfo.invert) {
                    enabled = !enabled;
                }
                return !enabled;
            });
        } else {
            return () => false;
        }
    }, [segmentToggleInfo]);

    const disabled = useETPSelector(sectionUserDisabledSelector);

    return (
        // This needs to be an element, and not a React.Fragment, to create a sticky
        // boundary
        <div>
            <div
                className={classNames(styles.head, {
                    [styles.open]: open,
                    [styles.hasToggle]: segmentToggleInfo,
                })}
            >
                <CommandBarButton
                    className={styles.commandBarButton}
                    iconProps={iconProps}
                    onClick={toggleOpen}
                    aria-expanded={open}
                >
                    {displayName}
                </CommandBarButton>
                {segmentToggleInfo && <SectionToggle {...segmentToggleInfo} />}
            </div>
            <Slideout open={open} className={styles.slideoutOuter}>
                {/* Extra div for slideout sizing */}
                <div className={styles.slideoutInner}>
                    {configKeys.map((key) => {
                        const Component = managedConfigComponents[key];
                        return (
                            <React.Fragment key={key}>
                                <Component disabled={disabled} />
                                {/* Spacer is used so that components can adjust their spacing with +/- margins */}
                                <Spacer />
                            </React.Fragment>
                        );
                    })}
                    {!hideReset && (
                        <ResetButton configKeys={configKeys} defaultVariants={defaultVariants} disabled={disabled} />
                    )}
                </div>
            </Slideout>
        </div>
    );
};
