import * as React from 'react';
import { Stack } from 'office-ui-fabric-react';

import styles from './NoData.module.scss';

export interface INoDataProps {
    message: string;
    className?: string;
}

export const NoData: React.FC<INoDataProps> = ({ className, message }) => {
    return (
        <Stack
            className={className ? `${styles.noData} ${className}` : styles.noData}
            horizontal
            verticalAlign="center"
            horizontalAlign="center"
        >
            {message}
        </Stack>
    );
};
