import { err } from '../../../common';

import type { EppStaticDropdownOptions, EppBasicParamOptions, EppReducerState } from './reducer';
import { tryApplyBuildParameter } from './tryBuildParameter';

export const initialStateStaticDropdownOptions: EppStaticDropdownOptions = {
    kind: 'static',
    defaultValue: undefined,
    options: new Map([
        [
            'default',
            {
                id: 'default',
                value: '',
                displayText: undefined,
            },
        ],
    ]),
    parsedConfig: err({ options: [], defaultValue: undefined }),
    selectionKind: 'scalar',
};

export const initialStateUnion: EppBasicParamOptions = {
    kind: 'basic',
    dataType: 'string',
    union: initialStateStaticDropdownOptions,
};

export const initialState: EppReducerState = tryApplyBuildParameter({
    id: 'new',
    shouldPin: true,
    displayName: '',
    variableNames: [''],
    union: initialStateUnion,
    existingParameterDisplayNames: new Set<string>(),
    existingParameterVariableNames: new Set<string>(),
    activeError: { kind: 'error' },
    timeZone: 'UTC',
    errors: {},
});
