import { ok } from '../../common/error';
import { IDashboardDocument } from '../../core/domain/dashboard';

import { addSchemaIfMissing, migrate, MigrationResult } from '../migrate';

import { deserializeParameter, serializeParameter } from './parameter';
import { serializeTile, deserializeTile } from './tile';
import { dashboardMigrations, DashboardLatestVersion, IDashboardVersioned, AnyDashboardDocument } from './versions';

export * from './dataSource';
export * from './parameter';
export * from './tile';
export * as dashboard from './versions';
export type { IDashboardVersioned, AnyDashboardDocument, DashboardLatestVersion } from './versions';

/**
 * Re-generate by the backend every time a dashboard is saved. Can be used
 * to tell if a dashboard has changed. Should _not_ be migrated because it's not created client side.
 */
export type ETag = string;

/**
 * As of writing the server mixes non-dashboard properties into dashboard json
 */
export type DashboardServerResponse = AnyDashboardDocument & DashboardsServerMetaData;

/** Properties mixed into a dashboard by the server */
export interface DashboardsServerMetaData {
    readonly id: string;
    readonly eTag: ETag;
    readonly isDashboardEditor: boolean;
    /** Optional because some dashboards were saved before we started tracking this */
    readonly updatedTimestamp?: string;
}

/**
 * Used for updating dashboards on the server, creating dashboards, and exporting dashboards to json
 */
export type DashboardSerialized = AnyDashboardDocument & SerializedProperties;

export interface DashboardsLatestSerialized extends DashboardLatestVersion, SerializedProperties {}

/**
 * Server properties we may want to include when serializing or updating a
 * dashboard
 */
export type SerializedProperties = Partial<Pick<DashboardsServerMetaData, 'id' | 'eTag'>>;

/**
 * Removes metadata from the response one of the servers dashboard endpoints
 */
export function splitDashboardServerResponse(response: DashboardServerResponse): {
    dashboard: AnyDashboardDocument;
    meta: DashboardsServerMetaData;
} {
    // Remove server properties
    const { id, eTag, isDashboardEditor, updatedTimestamp, ...dashboard } = response;
    const meta: DashboardsServerMetaData = { id, eTag, isDashboardEditor, updatedTimestamp };
    return { dashboard, meta };
}

/**
 * Removed metadata from a serialized dashboard
 */
export function splitSerializedDashboard(response: DashboardsLatestSerialized): {
    dashboard: DashboardLatestVersion;
    meta: SerializedProperties;
};
export function splitSerializedDashboard(response: DashboardSerialized): {
    dashboard: AnyDashboardDocument;
    meta: SerializedProperties;
};
export function splitSerializedDashboard(response: DashboardSerialized): {
    dashboard: AnyDashboardDocument;
    meta: SerializedProperties;
} {
    // Remove extra properties
    const { id, eTag, ...dashboard } = response;
    const meta: SerializedProperties = { id, eTag };
    return { dashboard, meta };
}

export function parseDashboardServerResponse(
    response: DashboardServerResponse
): MigrationResult<IDashboardDocument<DashboardsServerMetaData>> {
    const { dashboard, meta } = splitDashboardServerResponse(response);
    const res = migrateDashboard(dashboard);

    if (res.kind === 'err') {
        return res;
    }

    return ok({ data: deserializeDashboard(res.value.data, meta), warnings: res.value.warnings });
}

export function migrateDashboard(anyDashboard: AnyDashboardDocument): MigrationResult<DashboardLatestVersion> {
    const versioned: IDashboardVersioned = addSchemaIfMissing(anyDashboard, '0');

    return migrate(dashboardMigrations, versioned, '14');
}

export function serializeDashboard(document: IDashboardDocument): DashboardsLatestSerialized {
    return {
        $schema: '14',
        id: document.meta?.id,
        title: document.title,
        eTag: document.meta?.eTag,
        pinnedParameters: [...document.pinnedParameters],
        autoRefresh: document.autoRefresh,
        tiles: Object.values(document.tiles).map(serializeTile),
        parameters: Object.values(document.parameters).map(serializeParameter),
        dataSources: [...document.dataSources],
        pages: Object.values(document.pages),
    };
}

export function deserializeDashboard(
    serialized: DashboardLatestVersion,
    meta: DashboardsServerMetaData
): IDashboardDocument<DashboardsServerMetaData>;
export function deserializeDashboard(
    serialized: DashboardLatestVersion,
    meta: undefined
): IDashboardDocument<undefined>;
export function deserializeDashboard(serialized: DashboardLatestVersion): IDashboardDocument<undefined>;
export function deserializeDashboard(
    serialized: DashboardLatestVersion,
    meta?: DashboardsServerMetaData
): IDashboardDocument {
    return {
        meta,
        title: serialized.title,
        pinnedParameters: new Set(serialized.pinnedParameters),
        autoRefresh: serialized.autoRefresh,
        tiles: serialized.tiles.map(deserializeTile),
        parameters: serialized.parameters.map(deserializeParameter),
        dataSources: serialized.dataSources,
        pages: serialized.pages,
    };
}
