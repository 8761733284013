import React from 'react';
import { IDropdownOption } from 'office-ui-fabric-react';

import { APP_STRINGS, APP_CONSTANTS } from '../../../../../../res';
import {
    CustomElementDropdown,
    RTDDropdown,
    RTDDropdownOption,
    RTDBackingDropdown,
} from '../../../../../../components';
import { RtdValue, StreamQueryResult } from '../../../../../../domain';
import { rtdDataTypeFromKustoDataType } from '../../../../../../domain/kusto/kusto';

import { EppAction, EppQueryDropdownOptions } from '../../../../reducer';

import pageStyles from '../../../../EditParameterPage.module.scss';

export interface ValueColumnDropdownProps {
    parameterType: RtdValue.BasicType;
    dispatch: React.Dispatch<EppAction>;
    result: StreamQueryResult;
    dataSource: EppQueryDropdownOptions;
}

const backingDropdown: RTDBackingDropdown = {
    type: 'standard',
};

export const ValueColumnDropdown: React.FC<ValueColumnDropdownProps> = ({
    result,
    dataSource,
    dispatch,
    parameterType,
}) => {
    const options = React.useMemo((): RTDDropdownOption[] => {
        const columns: RTDDropdownOption[] =
            result.kind === 'success'
                ? result.result.columns.map((c) => ({
                      key: c.name,
                      text: c.name,
                      disabled: rtdDataTypeFromKustoDataType(c.type) !== parameterType,
                  }))
                : [];
        const valueColumn = dataSource.valueColumn;
        if (valueColumn !== undefined && !columns.some((c) => c.key === valueColumn)) {
            // If valueColumn doesn't exist in result set, add it to the list so we have a nice display
            columns.unshift({ key: valueColumn, text: valueColumn, disabled: false });
        }
        return columns;
    }, [result, dataSource.valueColumn, parameterType]);

    const onValueChange = React.useCallback(
        (_: unknown, option?: IDropdownOption | undefined) =>
            dispatch({
                type: 'updateQueryDataSource',
                changes: { valueColumn: option?.key as string | undefined },
            }),
        [dispatch]
    );

    return (
        <div className={pageStyles.input}>
            <RTDDropdown
                label={APP_STRINGS.editParameterPage.valueQueryColumnDropdownLabel}
                required={true}
                disabled={result.kind !== 'success'}
                options={options}
                selectedKey={dataSource.valueColumn ?? null}
                onChange={onValueChange}
                placeholder={APP_STRINGS.editParameterPage.noQueryResultColumnSelectedPlaceholder}
                backingDropdown={backingDropdown}
                errorMessage={dataSource.valueColumnWarning}
            />
        </div>
    );
};

export interface LabelColumnDropdownProps {
    dispatch: React.Dispatch<EppAction>;
    result: StreamQueryResult;
    dataSource: EppQueryDropdownOptions;
}

const emptySelection: RTDDropdownOption = {
    key: APP_CONSTANTS.emptySelection,
    text: APP_STRINGS.editParameterPage.labelQueryColumnDropdownClearSelectionOption,
};

export const LabelColumnDropdown: React.FC<LabelColumnDropdownProps> = ({ result, dataSource, dispatch }) => {
    const options: RTDDropdownOption[] = React.useMemo(() => {
        const columns = result.kind === 'success' ? result.result.columns.map((c) => c.name) : [];
        const labelColumn = dataSource.labelColumn;
        if (labelColumn !== undefined && !columns.includes(labelColumn)) {
            columns.unshift(labelColumn);
        }
        return [emptySelection, ...columns.map((key) => ({ key: key, text: key }))];
    }, [result, dataSource.labelColumn]);

    const onLabelColumnChange = React.useCallback(
        (_: unknown, option?: IDropdownOption | undefined) => {
            const labelColumn = option?.key as string;
            if (labelColumn === APP_CONSTANTS.emptySelection) {
                dispatch({
                    type: 'updateQueryDataSource',
                    changes: { labelColumn: undefined },
                });
            } else {
                dispatch({ type: 'updateQueryDataSource', changes: { labelColumn } });
            }
        },
        [dispatch]
    );

    return (
        <div className={pageStyles.input}>
            <CustomElementDropdown
                label={APP_STRINGS.editParameterPage.labelQueryColumnDropdownLabel}
                disabled={result.kind !== 'success'}
                options={options}
                selectedKey={dataSource.labelColumn ?? APP_CONSTANTS.emptySelection}
                onChange={onLabelColumnChange}
                noSelectionTitle={
                    dataSource.valueColumn ?? APP_STRINGS.editParameterPage.labelQueryColumnDropdownPlaceholder
                }
                backingDropdown={backingDropdown}
                errorMessage={dataSource.labelColumnWarning}
            />
        </div>
    );
};
