import { APP_CONSTANTS } from '../../res';
import { KustoQueryResultRowObject } from '../query';

export interface IKeyTypes {
    xKey: string | undefined;
    yKeys: string[];
    series: string[];
    misc: string[];
}

/**
 * Currently used by host apps when implementing DataVisualComponent's. A small
 * part of the heuristics they have.
 */
export const findKeys = (dataItems: KustoQueryResultRowObject[]): IKeyTypes => {
    // input data looks like:
    // [{Timestamp: t0, Level: Error, count_: 2000}, {Timestamp: t0, Level: Info, count_: 1000},
    // {Timestamp: t1, Level: Error, count_: 3000}, {Timestamp: t1, Level: Info, count_: 5000}]
    // Return looks like:
    // res: { xKey: 'TimeStamp', yKeys: ['count_'], series: ['Level'], misc: [] }

    const res: IKeyTypes = { xKey: undefined, yKeys: [], series: [], misc: [] };

    if (dataItems.length < 1) {
        return res;
    }

    // finds all keys from the first object in data array
    const keys = Object.keys(dataItems[0]);

    // Take Timestamp as the xAxis Key
    for (let key = 0; key < keys.length; key++) {
        if (keys[key].toUpperCase() === 'TIMESTAMP') {
            res.xKey = keys[key];
            keys.splice(key, 1);
            break;
        }
    }

    // default: first column is xAxis key
    if (res.xKey === undefined) {
        res.xKey = keys[0];
        keys.splice(0, 1);
    }

    keys.forEach((key) => {
        if (res && res.yKeys && res.series && res.misc) {
            if (typeof dataItems[0][key] === 'number') {
                res.yKeys.push(key);
            } else if (typeof dataItems[0][key] === 'string') {
                // assumption: if string is too long, it is not a series name, but a message of some sort
                // RechartsVisual behavior: return a table instead of series data
                if (!(String(dataItems[0][key]).length > APP_CONSTANTS.visuals.seriesNameLengthMax)) {
                    res.series.push(key);
                } else {
                    res.misc.push(key);
                }
            }
        }
    });
    return res;
};
