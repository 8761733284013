import React from 'react';
import { TooltipWrapper, TooltipWrapperProps } from './TooltipWrapper';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';

export interface CommandBarButtonWithTooltipProps extends React.ComponentProps<typeof CommandBarButton> {
    tooltipProps?: TooltipWrapperProps;
}

export const CommandBarButtonWithTooltip = ({
    title,
    tooltipProps,
    ...commandBarButtonProps
}: CommandBarButtonWithTooltipProps) => {
    return (
        <TooltipWrapper content={title} {...tooltipProps} styles={{ root: { display: 'inherit' } }}>
            <CommandBarButton {...commandBarButtonProps}></CommandBarButton>
        </TooltipWrapper>
    );
};
