import { produce } from 'immer';
import * as localForage from 'localforage';
import * as _ from 'lodash';
import { getSnapshot, onSnapshot } from 'mobx-state-tree';
import { v4 } from 'uuid';
import { getTelemetryClient } from '../utils/telemetryClient';
import URLSearchParams from '../utils/urlSearchParams';
import { ConnectionPane } from './connectionPane';
import { GridStateCache } from './gridStateCache';
import {
    connectionsStorageKey,
    gridCacheStorageKey,
    isEmptyWorkspaceParam,
    isVolatileWorkspace,
    mainStorageKey,
} from './localStorageHelper';
import { emptySnapshot, RootStore } from './rootStore';
import { Tab } from './tab';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { isSecurityError } from '../common/error';

type ConnectionsSnapshot = typeof ConnectionPane.SnapshotType.connections;
type GridStateCacheSnapshot = typeof GridStateCache.SnapshotType;

const { trackTrace, trackEvent, trackException, startTrackEvent, stopTrackEvent } = getTelemetryClient({
    component: 'statePersistenceHandler',
    flow: 'rehydrate',
});

export function persistMain(snapshot: typeof RootStore.SnapshotType, flow: string, rootStore?: RootStore) {
    if (isVolatileWorkspace(snapshot.settings.emptyWorkspaceOnDeepLinkQuery)) {
        return;
    }
    let mainETagFromStorage: string | undefined;
    try {
        mainETagFromStorage = getETagFromLocalStorage();
    } catch (e) {
        if (isSecurityError(e)) {
            return;
        }
        throw e;
    }

    if (mainETagFromStorage && snapshot.eTag !== mainETagFromStorage) {
        trackTrace('persist.snapshot.skipped.due.to.etags.mismatch');
        // TODO: add support for merging in-memory data with storage.
        // for now just skip saving until data is reloaded in SyncBrowserTabs.
        return;
    }
    const eventName = 'persist.main';
    startTrackEvent(eventName);

    const newETag = v4();
    const snapshotWithoutConnections = produce<typeof RootStore.SnapshotType>(snapshot, (draft) => {
        draft.connectionPane.connections = {};
        draft.gridStateCache.states = {};
        draft.eTag = newETag;
    });

    const keys = Object.keys(snapshotWithoutConnections);
    const lengths = keys.reduce((dictionary: { [ind: string]: string }, currKey) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = JSON.stringify((snapshotWithoutConnections as any)[currKey]);
        dictionary[currKey] = value == null || value.length == null ? '0' : value.length.toString();
        return dictionary;
    }, {});
    const json = JSON.stringify(snapshotWithoutConnections);
    const stringLength = json.length.toString();
    try {
        rootStore?.setStopPersistency(true);
        rootStore?.setMainETag(newETag);
        rootStore?.setStopPersistency(false);
        localStorage.setItem(mainStorageKey, json);
        rootStore?.tabs.tabs.forEach((tab) => {
            tab.setDirty(false);
            tab?.setSaveError(undefined);
        });
    } catch (e) {
        if (!isSecurityError(e)) {
            trackException(e, 'persistMain', { flow, stringLength, ...lengths });
        }
        rootStore?.tabs.tabs.forEach((tab) => {
            if (tab.dirty) {
                // persist the error per tab, this is not required now, when all tabs are saved together
                // but it can be useful in the future when every tab is saved separately.
                tab?.setSaveError(e.message ?? e);
            }
        });
    }

    stopTrackEvent(eventName, { flow, stringLength, ...lengths });
}

async function persistConnectionsAsync(connections: ConnectionsSnapshot, flow: string) {
    const eventName = 'persist.connections';
    startTrackEvent(eventName);
    try {
        await localForage.setItem(connectionsStorageKey, connections);
    } catch (e) {
        trackException(e, 'persistConnectionsAsync', { flow });
    }

    stopTrackEvent(eventName);
}
async function persistGridState(snapshot: GridStateCacheSnapshot, flow: string) {
    const eventName = 'persist.GridStateCache';
    startTrackEvent(eventName);
    try {
        await localForage.setItem(gridCacheStorageKey, snapshot);
    } catch (e) {
        trackException(e, 'persistGridStateCache', { flow });
    }

    stopTrackEvent(eventName);
}

// using an arrow function in order to bind 'this'. otherwise - app insights tracing fail.
const persistMainDebounced = _.debounce(
    (snapshot: typeof RootStore.SnapshotType, flow: string, rootStore?: RootStore) =>
        persistMain(snapshot, flow, rootStore),
    2000,
    { maxWait: 5000 }
);
const persistGridStateDebounced = _.debounce(
    (snapshot: GridStateCacheSnapshot, flow: string) => persistGridState(snapshot, flow),
    2000,
    { maxWait: 5000 }
);

export const flush = () => {
    persistGridStateDebounced.flush();
    persistMainDebounced.flush();
    persistGridStateDebounced.flush();
};

/**
 * Register to state changes and persist whenever there's a change (debounced)
 */
function setupPersistence(rootStore: RootStore) {
    if (isVolatileWorkspace(rootStore.settings.emptyWorkspaceOnDeepLinkQuery)) {
        return;
    }
    window.addEventListener('beforeunload', () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        persistMain(getSnapshot(rootStore!), 'beforeUnload');
        persistGridStateDebounced.flush();
    });
    onSnapshot(rootStore, (snapshot) => {
        if (rootStore.stopPersistency) {
            return;
        }
        persistMainDebounced(snapshot, 'onSnapshot(rootStore)', rootStore);
    });
    onSnapshot(rootStore.connectionPane.connections, (snapshot) => {
        persistConnectionsAsync(snapshot, 'onSnapshot(connections)');
    });
    onSnapshot(rootStore.gridStateCache, (snapshot) => {
        persistGridStateDebounced(snapshot, 'onSnapshot(gridStateCache)');
    });
}

/**
 * Rehydrate state from storage. Return the model if successful and undefined otherwise.
 * We're loading connections snapshot from a different place (indexed DB) since it can be too big for local storage.
 * Thus we need to merge the 2 snapshot to a big snapshot.
 * This function is very high on telemetry events because of the reliance on browser facilities and importance
 * of finding issues.
 */
async function rehydrate(): Promise<RootStore | undefined> {
    trackEvent('Rehydrate.Fetch.Main.Start');
    let snapshotString: string | null = null;
    try {
        snapshotString = localStorage.getItem(mainStorageKey);
    } catch (e) {
        if (isSecurityError(e)) {
            trackEvent('Rehydrate.Fetch.Main.SecurityError');
            return undefined;
        }
        throw e;
    }

    if (!snapshotString) {
        trackEvent('Rehydrate.Fetch.Main.NotFound');
        return undefined;
    }

    trackEvent('Rehydrate.Fetch.Main.Success', { snapshotLength: `${snapshotString.length}` });

    trackEvent('Rehydrate.Deserialize.Main');
    const storeSnapshot = JSON.parse(snapshotString) as typeof RootStore.SnapshotType;
    const clearWorkspace = isVolatileWorkspace(storeSnapshot.settings.emptyWorkspaceOnDeepLinkQuery);
    const snapshot: typeof RootStore.SnapshotType = clearWorkspace
        ? {
              ...storeSnapshot,
              tabs: {} as typeof RootStore.SnapshotType.tabs,
          }
        : storeSnapshot;

    let snapshotWithLocalForage = snapshot;
    if (clearWorkspace) {
        trackEvent('Rehydrate.Fetch.EmptyWorkSpace');

        // don't load connection store
    } else {
        trackEvent('Rehydrate.Fetch.Async.Start');
        // Start get operations in parallel
        const connectionsSnapshotAsync = localForage.getItem<ConnectionsSnapshot>(connectionsStorageKey);
        const gridStateSnapshotAsync = localForage.getItem<GridStateCacheSnapshot>(gridCacheStorageKey);

        // wait for both
        const connectionsSnapshot = await connectionsSnapshotAsync;
        const gridStateSnapshot = await gridStateSnapshotAsync;

        if (!connectionsSnapshot && !gridStateSnapshot) {
            trackEvent('Rehydrate.Fetch.Async.NotFound');
        } else {
            trackEvent('Rehydrate.Fetch.Async.Merge', {
                connections: (!!connectionsSnapshot).toString(),
                gridState: (!!gridStateSnapshot).toString(),
            });

            snapshotWithLocalForage = produce<typeof RootStore.SnapshotType>(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                snapshot,
                (draft) => {
                    if (connectionsSnapshot) {
                        draft.connectionPane.connections = connectionsSnapshot;
                    }
                    if (gridStateSnapshot) {
                        draft.gridStateCache = gridStateSnapshot;
                    }
                }
            );
        }
    }

    trackEvent('Rehydrate.CreateStore.Start');
    try {
        const store = RootStore.create(snapshotWithLocalForage);
        validateStoreAfterRehydration(store);
        trackEvent('Rehydrate.CreateStore.Success');
        return store;
    } catch (e) {
        trackException(
            e,
            'StatePersistenceHandler',
            {
                details: 'Could not create Store instance from snapshot. This could be a "db" versioning issue',
            },
            undefined,
            2
        );
        throw e;
    }
}

function validateStoreAfterRehydration(store: RootStore) {
    try {
        const connectionsCount = Array.from(store.connectionPane.connections).length;
        const tabsCount = Array.from(store.tabs.tabs).length;
        if (connectionsCount === 0 && tabsCount > 0) {
            trackTrace('tabs-but-no-connections', SeverityLevel.Information, {
                connections: `${connectionsCount}`,
                tabs: `${tabsCount}`,
            });
        }
    } catch (e) {
        trackException(e, 'StatePersistenceHandler', {
            details: 'Failed to get tabs and connections count',
        });
    }
}

export function getETagFromLocalStorage(): string | undefined {
    if (isEmptyWorkspaceParam) {
        return undefined;
    }

    const snapshotString = localStorage.getItem(mainStorageKey);
    if (!snapshotString) {
        trackTrace('getETag.NotFound');
        return undefined;
    }

    trackTrace('getETag.Deserialize.Main');
    const storeSnapshot = JSON.parse(snapshotString) as typeof RootStore.SnapshotType;
    trackTrace('getETag.Deserialize.Finished');
    return storeSnapshot.eTag;
}

/**
 * When user's disk space is getting low, data in indexedDB might get deleted by the browser.
 * By requesting a persistent storage, the browser "agree" not to delete the data.
 * When calling persist the browser may ask the user to allow the app to persist the data, but only if the web page
 * behaves as an app (user spends a lot of time in the web page, the app is bookmarked or added to the home screen).
 * To reduce the chances of showing this notification, only call persist if disk space usage >= 60%.
 *
 * The persist method is experimental and might not work on some browsers (tested successfully on Firefox, Microsoft Edge, Chrome).
 */
async function requestPersistentStorage() {
    if (!navigator || !navigator.storage) {
        trackTrace('navigator.storage is undefined');
        return;
    }
    if (!navigator.storage.persist) {
        trackTrace('navigator.storage.persist is undefined');
        return;
    }

    if (navigator.storage.estimate) {
        const storageEstimate: StorageEstimate = await navigator.storage.estimate();
        if (storageEstimate && storageEstimate.usage && storageEstimate.quota) {
            const usedSpace = (storageEstimate.usage / storageEstimate.quota) * 100;
            trackTrace(`localStorage used space: ${usedSpace.toFixed(2)}%`);
            if (usedSpace < 60) {
                return;
            }
        }
    } else {
        trackTrace('navigator.storage.estimate is undefined');
    }

    try {
        const didPersist = await navigator.storage.persist();
        if (didPersist) {
            trackTrace('localStorage persisted successful');
        } else {
            trackTrace('localStorage did not persist');
        }
    } catch (e) {
        trackException(e, 'storage.persistance');
    }
}

/**
 * Try to rehydrate state from local storage, or deliver a fresh empty state if local storage doesn't have any
 * or if the 'forget' query param exists.
 * Also register to snapshot events in order to continuously save snapshots to persistent storage.
 * if fails - we'll stick with the default one. This usually happens when making
 * non back compat changes to the object model (which shouldn't happen in production)
 */
export async function initRootStore(): Promise<RootStore> {
    let maybeRootStore: RootStore | undefined;
    try {
        requestPersistentStorage();
        const url = new URL(window.location.href);

        const params = new URLSearchParams(url.search);

        const forget = params && params.get('forget');
        if (!forget) {
            maybeRootStore = await rehydrate();
            if (maybeRootStore) {
                try {
                    const tabWithLongestContent = maybeRootStore.tabs.tabs.reduce(
                        (maxTab: Tab | undefined, curTab: Tab) =>
                            !maxTab || curTab.text.length > maxTab.text.length ? curTab : maxTab,
                        undefined as Tab | undefined
                    );
                    trackEvent('Rehydrate.Success', {
                        maxTabSize: `${tabWithLongestContent?.text.length ?? 0}`,
                        tabCount: `${maybeRootStore.tabs.tabs.length}`,
                    });
                } catch (e) {
                    trackException(e, 'Rehydrate.Success.But.Failed.Tracing');
                }
            }
        } else {
            localForage.removeItem(connectionsStorageKey).catch((e) => {
                trackException(e, 'RootStore_forget');
            });
        }
    } catch (e) {
        trackException(
            e,
            'RootStore',
            {
                details: 'Either url params parsing failed, or accessing local storage threw an exception',
            },
            undefined,
            3
        );
        if (process.env.NODE_ENV !== 'production') {
            throw e;
        }
    } finally {
        if (!maybeRootStore) {
            trackEvent('Rehydrate.CreateDefaultState');
            maybeRootStore = RootStore.create(emptySnapshot);
        }

        // showEventNotification is used to show a one line message banner (like a notification about an upcoming event).
        // Setting it to undefined will prevent the banner from being shown, but it also deletes it from RootStore and disk.
        // Next time the banner is needed, it will be better to change showEventNotification to a range of dates instead of a boolean.
        // The banner will only be shown in those dates, and it can easily be re-enabled by setting new dates.
        maybeRootStore.setShowEventNotification(undefined);
    }

    setupPersistence(maybeRootStore);
    return maybeRootStore;
}
