import { ICommandBarItemProps, DirectionalHint } from 'office-ui-fabric-react';

import { WithRequiredKeys } from '../../../common';
import { cssVariableNames } from '../../../domain/theming/themes';

export const createLongSplitCommandBarButton = (
    splitButtonText: string,
    props: ICommandBarItemProps & WithRequiredKeys<ICommandBarItemProps, 'subMenuProps'>
): ICommandBarItemProps => ({
    ...props,
    splitButtonMenuProps: {
        className: 'test',
        text: splitButtonText,
    },
    subMenuProps: {
        directionalHint: DirectionalHint.bottomRightEdge,
        ...props.subMenuProps,
    },
    split: true,
    buttonStyles: {
        ...props.buttonStyles,
        splitButtonMenuButton: {
            width: 'unset',
            // Unfortunately placing this button in farItems (`OverflowSet`) attempts to change its styles, requiring the `!important`
            paddingLeft: '12px !important',
            order: 3,
            color: cssVariableNames.neutralPrimary,
            fontFamily:
                '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
            fontSize: 14,
            lineHeight: 14,
        },
        splitButtonMenuIcon: {
            paddingLeft: 6,
            order: 2,
        },
        splitButtonDivider: {
            position: 'relative',
            top: 0,
            bottom: 0,
            right: 0,
            margin: '8px 0',
            // Move divider into middle
            order: 2,
        },
    },
});
