import * as React from 'react';

import { VisualConfigProvider, VisualConfig } from './domain/visualConfig';
import { TimeZoneProvider, TimeZone } from './domain/timezone';
import { IRtdCoreProps, RtdCoreProvider } from './core/react';
import { DashboardsStoreProvider } from './store';

export interface DashboardsContextsProviderProps extends IRtdCoreProps {
    // any required because VisualConfig<'cat'> isn't assignable to VisualConfig<string>
    // Isn't assignable because of the React.ComponentType<T> issues.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visualConfig: VisualConfig<any>;
    enableDarkTheme?: boolean;
    timeZone: TimeZone;
}

export const DashboardsContextsProvider: React.FC<DashboardsContextsProviderProps> = ({
    children,
    core,
    visualConfig,
    enableDarkTheme,
    timeZone,
}) => (
    <RtdCoreProvider core={core}>
        <VisualConfigProvider value={visualConfig}>
            <TimeZoneProvider timeZone={timeZone}>
                <DashboardsStoreProvider isDark={enableDarkTheme}>{children}</DashboardsStoreProvider>
            </TimeZoneProvider>
        </VisualConfigProvider>
    </RtdCoreProvider>
);
