import * as React from 'react';
import { useCallback } from 'react';
import { ActionButton } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

import { useDashboardStore } from '../../../store/dashboard';
import { APP_STRINGS } from '../../../res';
import { TFormComponent, formActionMacro, doesRecursiveParentsContainClass } from '../../../common';
import { RTDPanel } from '../../fabric';

import styles from './Manage.module.scss';

import { ParameterPreview } from './components/ParameterPreview';
import { paramterPreviewActionKey } from './constants';

interface IManageParameterWithDataProps {
    onAddParameter: () => void;
}

const ManageParametersWithData: React.FC<IManageParameterWithDataProps> = observer(function ManageParametersWithData({
    onAddParameter,
}) {
    const store = useDashboardStore();
    const dashboardState = store.state;
    const dashboardChanges = dashboardState?.changes;
    return (
        <div className={styles.manage}>
            <ActionButton iconProps={{ iconName: 'Add' }} onClick={onAddParameter}>
                {APP_STRINGS.forms.parameter.new}
            </ActionButton>
            {dashboardState &&
                dashboardChanges &&
                dashboardState.parameters.map((parameter) => (
                    <ParameterPreview
                        key={parameter.id}
                        parameter={parameter}
                        dashboardState={dashboardState}
                        dashboardChanges={dashboardChanges}
                    />
                ))}
        </div>
    );
});

export const ManageParametersPanel: TFormComponent = ({ onClose }) => {
    const store = useDashboardStore();

    const onAdd = useCallback(() => {
        runInAction(() => {
            if (store.state !== undefined && store.state.changes) {
                store.state.changes.editParameter = 'new';
            }
        });

        onClose();
    }, [onClose, store]);

    // TODO #8283090: Temporary hack to fix this issue. Removing this is contingent on Fabric fixing https://github.com/microsoft/fluentui/issues/15025
    const onDismiss = useCallback(
        (event: React.SyntheticEvent<HTMLElement, Event> | undefined) => {
            const source = event?.nativeEvent.srcElement as Element | null | undefined;

            if (source && doesRecursiveParentsContainClass(source, 'ms-PanelAction-close')) {
                // All other events are caused by light dismiss or similar, and should be ignored
                onClose();
            }
        },
        [onClose]
    );

    return (
        <RTDPanel
            headerText={APP_STRINGS.forms.parameter.title}
            isOpen={true}
            onDismiss={onDismiss}
            closeButtonAriaLabel={APP_STRINGS.forms.parameter.closePanelAriaLabel}
        >
            <ManageParametersWithData onAddParameter={onAdd} />
        </RTDPanel>
    );
};

export const manageParametersPanelAction = formActionMacro(ManageParametersPanel, paramterPreviewActionKey);
