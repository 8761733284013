import * as React from 'react';
import { useMemo } from 'react';
import {
    SelectionMode,
    IGroup,
    IColumn,
    IDetailsGroupRenderProps,
    DetailsHeader,
    CollapseAllVisibility,
    DetailsListLayoutMode,
    IDetailsHeaderProps,
    IRenderFunction,
    IDetailsListStyles,
    IDetailsListStyleProps,
    PersonaSize,
    ColumnActionsMode,
} from 'office-ui-fabric-react';

import { GraphPersona, ExtendedPersonaProps, SortableDetailsList } from '../../fabric';
import { DashboardPermission, DashboardMember } from '../../../core/domain';
import { APP_STRINGS, APP_CONSTANTS } from '../../../res';
import { NewIndicator } from '../../fabric/icon/NewIndicator';

import { PermissionTypePicker } from './PermissionTypePicker';
import { PermissionsFormDispatch } from './permissionsFormReducer';
import { RemoveCell } from './RemoveCell';

import styles from './DashboardPermissions.module.scss';

interface PermissionsListProps {
    items: DashboardMember[];
    fetchedPersonas: {
        [id: string]: ExtendedPersonaProps;
    };
    dashboardId: string;
    canEdit: boolean;
    dispatch: PermissionsFormDispatch;
    isMounted: React.MutableRefObject<boolean>;
}

const sortDisabledColumnKeys = new Set(['delete']);

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props?: IDetailsHeaderProps) => {
    return (
        <DetailsHeader
            {...props}
            className={styles.detailsHeader}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            collapseAllVisibility={CollapseAllVisibility.hidden}
        />
    );
};

const groupProps: IDetailsGroupRenderProps = {
    showEmptyGroups: true,
};

export const PermissionsList: React.FunctionComponent<PermissionsListProps> = ({
    items,
    fetchedPersonas,
    dashboardId,
    dispatch,
    canEdit,
    isMounted,
}) => {
    const editorCount = useMemo(
        () =>
            items.reduce((count, item) => {
                return item.permission === DashboardPermission.editor ? count + 1 : count;
            }, 0),
        [items]
    );

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'member',
                name: APP_STRINGS.forms.permissions.name,
                minWidth: 100,
                isRowHeader: true,
                fieldName: 'member',
                onRender: (memberItem: DashboardMember) => (
                    <div className={styles.nameColumn}>
                        {!!memberItem.edited && <NewIndicator />}
                        <GraphPersona
                            person={{
                                ...memberItem,
                                type: memberItem.type === 'group' ? 'group' : 'user',
                            }}
                            size={PersonaSize.size32}
                            onLoadPersona={(persona) => dispatch({ type: 'addFetchedPersona', persona })}
                        />
                    </div>
                ),
            },
            {
                key: 'email',
                name: APP_STRINGS.forms.permissions.email,
                minWidth: 300,
                fieldName: 'email',
                onRender: (memberItem: DashboardMember) => (
                    <div className={styles.detailsListText}>{memberItem.email}</div>
                ),
            },
            {
                key: 'type',
                name: APP_STRINGS.forms.permissions.type,
                minWidth: 100,
                onRender: (memberItem: DashboardMember) => (
                    <div className={styles.detailsListText}>
                        {memberItem.type === 'user'
                            ? APP_STRINGS.forms.permissions.user
                            : APP_STRINGS.forms.permissions.group}
                    </div>
                ),
            },
            {
                key: 'permission',
                name: APP_STRINGS.forms.permissions.permission,
                minWidth: 100,
                fieldName: 'permission',
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (memberItem: DashboardMember) =>
                    canEdit ? (
                        <PermissionTypePicker
                            className={styles.permissionsTypePicker}
                            memberItem={memberItem}
                            dashboardId={dashboardId}
                            dispatch={dispatch}
                            isMounted={isMounted}
                        />
                    ) : (
                        <div className={styles.detailsListText}>
                            {memberItem.permission === DashboardPermission.editor
                                ? APP_CONSTANTS.permissionsOptions[0].text
                                : APP_CONSTANTS.permissionsOptions[1].text}
                        </div>
                    ),
            },
            {
                key: 'delete',
                name: '',
                minWidth: 60,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (memberItem: DashboardMember) =>
                    canEdit && (
                        <RemoveCell
                            dashboardId={dashboardId}
                            id={memberItem.id}
                            dispatch={dispatch}
                            isMounted={isMounted}
                        />
                    ),
            },
        ],
        [dashboardId, canEdit, isMounted, dispatch]
    );

    const extendedMemberItems = useMemo(
        () =>
            items.map((item): DashboardMember => {
                const email = fetchedPersonas[item.id]?.graphPerson.email;

                if (item.email || !email) {
                    // Nothing to change, return same object
                    return item;
                }

                return {
                    ...item,
                    email,
                };
            }),
        [items, fetchedPersonas]
    );

    // GroupedDetailsList will not update items at a given index unless `groups` is also updated
    // Therefore, base `groups` calculation off of `extendedMemberItems`
    const groups = useMemo((): IGroup[] => {
        return [
            {
                key: 'ownerGroup',
                name: 'Owners',
                count: editorCount,
                startIndex: 0,
            },
            {
                key: 'viewerGroup',
                name: 'Viewers',
                count: extendedMemberItems.length - editorCount,
                startIndex: editorCount,
            },
        ];
    }, [extendedMemberItems, editorCount]);

    return (
        <SortableDetailsList
            items={extendedMemberItems}
            groups={groups}
            groupProps={groupProps}
            columns={columns}
            sort={true}
            secondarySortFieldName={'id'}
            primarySortFieldName={'permission'}
            defaultSortFieldName={'edited'}
            sortDisabledColumnKeys={sortDisabledColumnKeys}
            selectionMode={SelectionMode.none}
            onRenderDetailsHeader={onRenderDetailsHeader}
            compact={true}
            styles={detailsListStyles}
        />
    );
};

const detailsListStyles = (props: IDetailsListStyleProps): Partial<IDetailsListStyles> => ({
    root: {
        backgroundColor: props.theme.palette.white,
        selectors: {
            '.ms-GroupHeader': {
                borderBottom: `1px solid ${props.theme.palette.neutralLighter}`,
                selectors: {
                    '.ms-GroupHeader-title': {
                        cursor: 'unset',
                        fontWeight: 'unset',
                    },
                    '.ms-GroupHeader-expand': {
                        cursor: 'pointer',
                    },
                    ':hover': {
                        color: 'unset',
                        backgroundColor: 'unset',
                    },
                },
            },
            '.ms-DetailsRow': {
                borderBottom: `1px solid ${props.theme.palette.neutralLighter}`,
            },
        },
    },
});
