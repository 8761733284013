import * as React from 'react';
import { observer } from 'mobx-react-lite';

import { ParameterSelectorList } from '../../../components/parameter';
import { useDashboardStore } from '../../../store/dashboard';

export const DashboardParameters: React.FC = observer(function DashboardParameters() {
    const store = useDashboardStore();

    if (store.state === undefined) {
        return null;
    }

    const pinnedParameters = Object.values(store.state.pinnedParameters);

    if (pinnedParameters.length === 0) {
        return null;
    }

    return (
        <ParameterSelectorList
            parameters={pinnedParameters}
            selectedParameters={store.state.selectedParameters}
            telemetryComponentName="DashboardPage"
        />
    );
});
