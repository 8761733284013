import * as React from 'react';
import { useMemo, useState, useRef, useEffect, ComponentType, useLayoutEffect, MutableRefObject } from 'react';
import { throttle } from 'lodash';
import { assertNever } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { APP_CONSTANTS } from '../../../../res';
import { Tile, DataVisual, DataVisualProps, InvalidLayoutError } from '../../../../components';
import { QueryNotRunVisual } from '../../../../components/QueryEditing';
import {
    VisualDisplayedResultsCounts,
    useVisualConfig,
    VisualConfig,
    VisualOptions,
    TileSize,
    StreamQuerySuccess,
    StreamResultError,
    StreamQueryResult,
    QueryHash,
    StreamResult,
    KustoQueryResult,
} from '../../../../domain';
import {
    ETPState,
    ETPVisual,
    IParameterSelections,
    useDashboardStore,
    useETPSelector,
    useETPSelectors,
} from '../../../../store';
import { useCore } from '../../../../core';

import { convertFromEditVisualOptions } from '../../lib';

import { TileSizeWrapper, getTileLayout } from './TileSizeWrapper';

import styles from './Query.module.scss';

const queryTileSizes = (
    visualConfig: VisualConfig,
    visualType: string,
    visualOptions: VisualOptions
): {
    defaultSize: TileSize;
    minimumSize: TileSize;
} => {
    function resolveSize(
        size: TileSize | undefined | ((options: Partial<VisualOptions>) => TileSize),
        defaultSize: TileSize
    ): TileSize {
        switch (typeof size) {
            case 'undefined':
                return defaultSize;
            case 'function':
                return size(visualOptions);
            case 'object':
                return size;
            default:
                assertNever(size);
        }
    }

    const hostAppConfig = visualConfig.visualTypes[visualType];

    const defaultSize = resolveSize(hostAppConfig?.defaultSize, APP_CONSTANTS.tile.unknownTypeEditingSize);
    const minimumSize = resolveSize(hostAppConfig?.minimumSize, defaultSize);

    return { defaultSize, minimumSize };
};

const titleSelector = (s: ETPState) => (s.type === 'query' ? s.title : undefined);

interface RenderTileProps {
    crossFilterTarget?: IParameterSelections;
    throttledVisualComponent: MutableRefObject<React.FC<DataVisualProps>>;
    queryStatus: StreamQuerySuccess | StreamResultError;
    queryHash: QueryHash | undefined;
    onRefreshClick: () => void;
    tileId?: string;
    visualState: ETPVisual;
}

const RenderTile: React.FC<RenderTileProps> = observer(function RenderTile({
    throttledVisualComponent,
    queryStatus,
    queryHash,
    onRefreshClick,
    tileId,
    crossFilterTarget,
    visualState,
}) {
    const [resultCounts, setResultCounts] = useState<VisualDisplayedResultsCounts | null>(null);
    const visualConfig = useVisualConfig();

    const title = useETPSelector(titleSelector);
    const convertedVisualOptions = useMemo(() => convertFromEditVisualOptions(visualState.options), [visualState]);
    const store = useDashboardStore();
    const dashboardTileSize = getTileLayout(
        store,
        tileId,
        visualState.visualType,
        visualConfig,
        convertedVisualOptions
    );

    const { defaultSize, minimumSize } = queryTileSizes(visualConfig, visualState.visualType, convertedVisualOptions);
    const userSettings = useCore().userSettingsState.userSettings;

    // Change the query status so that when error is reloading in the bottom pane, it shows spinner.
    // TODO #8837749: make change to Chart in kusto to separate the error bar from the Chart because we want to
    // show different UI based on either ETP or dashboard page.
    // We _want_ different behavior on the edit tile page and the catalog page. Hiding the last error
    // on the catalog page causes flicker, and the error is likely not going to go away. On the edit tile
    // page showing the error is undesirable because it may have gone away because the user changing
    // the query.
    //
    // TODO: While doing #8837749 we should get enough flexibility to do this as part of the transform
    // from the query result into the chart result more directly. That is to say, the QueryResult =>
    // DataVisual should be simpler, so having different implementations for the dashboard page and
    // edit tile page should be ok at that point.
    const queryResult = useMemo((): StreamResult<KustoQueryResult> => {
        if (queryStatus.kind === 'err' && queryStatus.status?.kind === 'loading') {
            return {
                kind: 'notRun',
                status: queryStatus.status,
            };
        }
        return queryStatus;
    }, [queryStatus]);

    const ThrottledDataVisualComponent = throttledVisualComponent.current;

    let visual: React.ReactNode;

    /**
     * Layout errors don't apply when expanded, because the expanded view doesn't
     * use the dashboard layout.
     */
    if (!userSettings.editTilePagePreviewExpanded && dashboardTileSize?.valid === false) {
        visual = <InvalidLayoutError layout={dashboardTileSize} />;
    } else {
        visual = (
            <ThrottledDataVisualComponent
                selectedParameters={crossFilterTarget}
                queryResult={queryResult}
                visualType={visualState.visualType}
                visualOptions={convertedVisualOptions}
                setResultCounts={setResultCounts}
            />
        );
    }

    return (
        <TileSizeWrapper
            className={styles.tileContainer}
            defaultSize={defaultSize}
            minimumSize={minimumSize}
            dashboardLayout={dashboardTileSize}
        >
            <Tile
                pageState={store.state}
                tileId="etp"
                visualOptions={convertedVisualOptions}
                title={title ?? ''}
                queryHash={queryHash}
                onRefreshClick={onRefreshClick}
                visualType={visualState.visualType}
                resultCounts={resultCounts ?? undefined}
            >
                {visual}
            </Tile>
        </TileSizeWrapper>
    );
});

export const throttleComponent =
    <P extends {}>( // eslint-disable-line @typescript-eslint/ban-types
        Component: ComponentType<P>,
        timeout: number
    ) =>
    (props: P) => {
        const [innerProps, setInnerProps] = useState(props);
        /**
         * useMemo and useCallback are only for optimizing code, and don't make
         * guarantees that state will be preserved. We need to guarantee that we only
         * create a single debounce instance per component.
         */
        const updateTile = useRef(throttle(setInnerProps, timeout, { leading: true, trailing: true }));
        useEffect(() => updateTile.current.cancel, [updateTile]);
        useLayoutEffect(() => updateTile.current(props), [props, updateTile]);

        return <Component {...innerProps} />;
    };

const etpVisualSelectors = {
    visualPane: (s: ETPState) => (s.type === 'query' ? s.visualPane : undefined),
    visualState: (s: ETPState) => (s.type === 'query' ? s.visual : undefined),
};

export interface VisualProps {
    crossFilterTarget?: IParameterSelections;
    queryStatus: StreamQueryResult;
    queryHash: QueryHash | undefined;
    onRefreshClick: () => void;
    tileId?: string;
}

export const Visual: React.FC<VisualProps> = ({
    crossFilterTarget,
    queryStatus,
    queryHash,
    onRefreshClick,
    tileId,
}) => {
    const {
        visualPane,
        visualState,
    }: {
        visualState: ETPVisual;
        visualPane: 'results' | 'visual' | undefined;
    } = useETPSelectors(etpVisualSelectors);

    const throttledVisualComponent = useRef(
        throttleComponent(DataVisual, APP_CONSTANTS.editTilePage.visualizationThrottleTime)
    );

    if (queryStatus.kind === 'notRun' || (queryStatus.kind === 'err' && !queryStatus.didRun)) {
        return <QueryNotRunVisual queryStatus={queryStatus} className={styles.queryNotRun} />;
    }

    if (visualPane === 'visual' && visualState) {
        return (
            <RenderTile
                crossFilterTarget={crossFilterTarget}
                tileId={tileId}
                queryStatus={queryStatus}
                queryHash={queryHash}
                visualState={visualState}
                throttledVisualComponent={throttledVisualComponent}
                onRefreshClick={onRefreshClick}
            />
        );
    }

    return (
        <div className={styles.table}>
            <DataVisual queryResult={queryStatus} visualType="table" />
        </div>
    );
};
