import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import './index.css';
import { Router } from 'react-router-dom';
import { history } from './history';
import { configure } from 'mobx';

configure({
    isolateGlobalState: true,
});

// ModuleRegistry.registerModules(AllModules);
// LicenseManager.setLicenseKey(
//     'CompanyName=Zones LLC_on_behalf_of_Microsoft Israel R&D center,LicensedGroup=KustoWeb,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=4,AssetReference=AG-007797,ExpiryDate=17_April_2021_[v2]_MTYxODYxNDAwMDAwMA==15798b176a4396b473bb9a42fe426145'
// );

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
    document.getElementById('root') as HTMLElement
);

unregister();
// registerServiceWorker();
