import { Provider } from 'mobx-react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import * as React from 'react';
import './App.scss';
import { Keys } from './common/types';
import { dependencies } from './dependencies';
import { RootStoreContextProvider } from './stores/hooks';
import { RootStore, rootStorePromise } from './stores/rootStore';
import { flush as flushRootStore } from './stores/statePersistenceHandler';
import { SyncBrowserTabs } from './stores/SyncBrowserTabs';
import { ThemeHandler } from './stores/themeHandler';
import { getTelemetryClient } from './utils/telemetryClient';

// ag-grid license before App is loaded
import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import { AllModules, ModuleRegistry } from '@ag-grid-enterprise/all-modules';
import { GlobalHotKeys } from './GlobalHotKeys';
LicenseManager.setLicenseKey(
    'CompanyName=Zones LLC_on_behalf_of_Microsoft Corporation,LicensedGroup=KustoWeb,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=8,LicensedProductionInstancesCount=4,AssetReference=AG-014350,ExpiryDate=24_May_2022_[v2]_MTY1MzM0NjgwMDAwMA==c3e419a7b49c11d260c90c63ec55367f'
);
ModuleRegistry.registerModules(AllModules);

// This has to be done once in entry point of program in order for office fabric icons to work.
// On government clouds the Fluent UI CDN is not available, use hosted icons instead.
if (process.env.REACT_APP_FLUENT_UI_ICONS_LOCATION === 'hosted') {
    initializeIcons(`${window.location.protocol}//${window.location.host}/icons/`);
} else {
    initializeIcons();
}

const tracer = getTelemetryClient({ component: 'App.tsx', flow: '' });
const AppWithStore = React.lazy(() => import('./App.withStore'));

interface State {
    rootStore: RootStore | null;
    didUpdateUserSpecificDependencies: boolean;
    focused: boolean;
}

/**
 * the entry point to our React application.
 * Will display loading screen while loading the data store from disk
 * once ready, will render the actual app.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
class App extends React.Component<{}, State> {
    private themeHandler?: ThemeHandler;

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(props: {}) {
        super(props);

        this.state = {
            rootStore: null,
            didUpdateUserSpecificDependencies: false,
            focused: false,
        };
    }

    /**
     * rootStore will be loaded asynchronously since it's being read from storage.
     * thus we need to have a local state changed when it's loaded and re-render.
     */
    async componentDidMount() {
        window.addEventListener('focus', this.onWindowFocus);
        window.addEventListener('blur', this.onWindowBlur);
        window.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('keydown', this.onKeyDown);
        rootStorePromise.then((rootStore) => {
            this.themeHandler = new ThemeHandler(rootStore, dependencies.theme);
            this.setState({ rootStore });
        });

        // Customize dependencies for authenticated user (usually enabling experimental features for internal Microsoft users)
        // If something goes wrong we don't want to fail site loading - we'll just pass on user-specific flags.
        try {
            const user = await dependencies.authProvider.getUser();
            dependencies.setUserSpecificDependencies(user);
        } catch (ex) {
            tracer.trackException(ex, 'componentDidMount', { flow: 'setUserSpecificDependencies' });
        }
        this.setState({ didUpdateUserSpecificDependencies: true });
    }

    componentWillUnmount() {
        if (this.themeHandler) {
            this.themeHandler.disposer();
            this.themeHandler = undefined;
        }
        window.removeEventListener('focus', this.onWindowFocus);
        window.removeEventListener('blur', this.onWindowBlur);
    }

    render() {
        const loading = (
            <div className="loading">
                <Spinner label="Loading" size={SpinnerSize.large} />
            </div>
        );
        return (
            <Fabric>
                {this.state.rootStore && this.state.didUpdateUserSpecificDependencies ? (
                    <RootStoreContextProvider value={this.state.rootStore}>
                        <Provider store={this.state.rootStore}>
                            {dependencies.featureFlags.SyncBrowserTabs && (
                                <SyncBrowserTabs hasFocus={this.state.focused} rootStore={this.state.rootStore} />
                            )}
                            <React.Suspense fallback={loading}>
                                <GlobalHotKeys />
                                <AppWithStore />
                            </React.Suspense>
                        </Provider>
                    </RootStoreContextProvider>
                ) : (
                    loading
                )}
            </Fabric>
        );
    }

    onWindowFocus = () => {
        this.setState({ focused: true });
    };

    onWindowBlur = () => {
        if (dependencies.featureFlags.SyncBrowserTabs) {
            flushRootStore();
        }
        this.setState({ focused: false });
    };

    onKeyDown = (event: KeyboardEvent) => {
        if (event.key === Keys.tab) {
            document.body.classList.remove('using-mouse');
        }
    };

    onMouseDown = () => {
        document.body.classList.add('using-mouse');
    };
}

export default App;
