// Tried to add a @types package for these with non luck
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/// @ts-expect-error
import type { CompiledTemplate } from 'es6-template-strings';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/// @ts-expect-error
import compileStrTemplate from 'es6-template-strings/compile';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/// @ts-expect-error
import resolveStrTemplate from 'es6-template-strings/resolve-to-string';

export const trimChar = (input: string, char: string): string => {
    const regex = new RegExp(`^(${char})+|(${char})+$`, 'g');
    return input.replace(regex, '');
};

/**
 * Doesn't follow the proper GUID formatting rules. Just validates the basic structure followed in Microsoft
 */
export const naiveGuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

// Dictionary for literals to be used as caching in formatLiterals()
const LiteralsDictionary = new Map<string, CompiledTemplate>();

/**
 * Copied from @kusto/common
 *
 * Use this function to convert regular string to string literals
 * string literals are saved in the string.json as regular strings and we need to use them as literals.
 * eval is not allowed so we convert them to literals with a Function.
 * example: formatLiterals('Welcome ${firstName} ${lastName}', {firstName: "John", lastName: "Doe"})
 * result: 'Welcome John Doe'
 */
export const formatLiterals = (text: string, params: Record<string, string>): string => {
    let compiledTemplate = undefined;
    let result = '';
    // new Function() is used for injecting template literals for a regular string.
    // this is basically eval, a heavy operation and cached using the text as key.
    // when we change the lang, the text will be changed and new function will be created and cached
    compiledTemplate = LiteralsDictionary.get(text);
    if (compiledTemplate === undefined) {
        // eslint-disable-next-line no-new-func
        compiledTemplate = compileStrTemplate(text);
        LiteralsDictionary.set(text, compiledTemplate);
    }
    // try/catch in case the string replacement fails, like when the $(param) had a typo
    try {
        result = resolveStrTemplate(compiledTemplate, params);
    } catch {
        result = text;
    }
    return result;
};

/**
 * Copies a string to the clipboard. Must be called from within an
 * event handler such as click. May return false if it failed, but
 * this is not always possible. Browser support for Chrome 43+,
 * Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
 * Internet Explorer: The clipboard feature may be disabled by
 * an administrator. By default a prompt is shown the first
 * time the clipboard is used (per session).
 */
// Taken from https://stackoverflow.com/a/33928558/2108817
export const copyToClipboard = (text: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).clipboardData && (window as any).clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (window as any).clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');

        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();

        try {
            return document.execCommand('copy'); // Security exception may be thrown by some browsers.
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error('Copy to clipboard failed.', ex);
            return false;
        } finally {
            document.body.removeChild(textarea);

            return false;
        }
    }

    return false;
};
