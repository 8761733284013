import { Migrations } from '../../migrate';

import * as preSchema from './preSchema';
import * as v0 from './0';
import * as v1 from './1-0';
import * as v1_1 from './1-1';
import * as v1_2 from './1-2';
import * as v2 from './2';
import * as v3 from './3';
import * as v4 from './4';
import * as v5 from './5';
import * as v6 from './6';
import * as v7 from './7';
import * as v8 from './8';
import * as v9 from './9';
import * as v10 from './10';
import * as v11 from './11';
import * as v12 from './12';
import * as v13 from './13';
import * as v14 from './14';

export * as preSchema from './preSchema';
export * as v0 from './0';
export * as v1 from './1-0';
export * as v1_1 from './1-1';
export * as v1_2 from './1-2';
export * as v2 from './2';
export * as v3 from './3';
export * as v4 from './4';
export * as v5 from './5';
export * as v6 from './6';
export * as v7 from './7';
export * as v8 from './8';
export * as v9 from './9';
export * as v10 from './10';
export * as v11 from './11';
export * as v12 from './12';
export * as v13 from './13';
export * as v14 from './14';

export type IDashboardVersioned =
    | v0.IDashboardV0
    | v1.IDashboardV1
    | v1_1.IDashboardV1_1
    | v1_2.IDashboardV1_2
    | v2.IDashboardV2
    | v3.IDashboardV3
    | v4.IDashboardV4
    | v5.IDashboardV5
    | v6.IDashboardV6
    | v7.IDashboardV7
    | v8.IDashboardV8
    | v9.IDashboardV9
    | v10.IDashboardV10
    | v11.IDashboardV11
    | v12.IDashboardV12
    | v13.IDashboardV13
    | v14.IDashboardV14;

export type AnyDashboardDocument = preSchema.IDashboardPreSchema | IDashboardVersioned;

export type DashboardLatestVersion = v14.IDashboardV14;

export const dashboardMigrations: Migrations<IDashboardVersioned> = {
    migrations: {
        '0': { up: v1.up },
        '1.0': { up: v1_1.up },
        '1.1': { up: v1_2.up },
        '1.2': { up: v2.up },
        '2': { up: v3.up },
        '3': { up: v4.up },
        '4': { up: v5.up },
        '5': { up: v6.up },
        '6': { up: v7.up },
        '7': { up: v8.up },
        '8': { up: v9.up },
        '9': { up: v10.up },
        '10': { up: v11.up, down: v10.down },
        '11': { down: v11.down, up: v12.up },
        '12': { down: v12.down, up: v13.up },
        '13': { down: v13.down, up: v14.up },
        '14': { down: v14.down },
    },
    order: ['0', '1.0', '1.1', '1.2', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'],
};
