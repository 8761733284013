import React from 'react';

import { RGLLayout } from '../../domain';
import { APP_STRINGS } from '../../res';

import { TileMessage } from './TileMessage';

const formatTileSmallerThanRequiredSizeError = (layout: RGLLayout) => {
    const strings = APP_STRINGS.visuals.tileSmallerThanRequiredSizeError;
    return `${strings.start} (${layout.w}, ${layout.h}) ${strings.end} (${layout.minW}, ${layout.minH})`;
};

export const InvalidLayoutError: React.FC<{ layout: RGLLayout }> = ({ layout }) => (
    <TileMessage message={formatTileSmallerThanRequiredSizeError(layout)} level="error" />
);
