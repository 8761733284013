import * as React from 'react';
import { createContext, useRef, useContext } from 'react';
import {
    createContext as createSelectorContext,
    useContextSelector,
    ContextSelector,
    useContextSelectors,
} from '@fluentui/react-context-selector';

import { assertUnreachable } from '../../common';
import { useThunkReducer } from '../../common';

import { ETPDispatch, ETPState, etpReducer } from './reducer';

type TEtpDispatchContext = [ETPDispatch, () => ETPState];

export const ETPDispatchContext = createContext<TEtpDispatchContext>([
    () => assertUnreachable('Edit tile page dispatch context default dispatch'),
    () => assertUnreachable('Edit tile page dispatch context default getState'),
]);

const initialState: ETPState = { type: 'loading' };

export const ETPStateContext = createSelectorContext<ETPState>(initialState);

ETPStateContext.Provider.displayName = 'etpStateContextProvider';

export const ETPStateProvider: React.FC = ({ children }) => {
    const [state, dispatch, getState] = useThunkReducer(etpReducer, initialState);

    const dispatchArrayRef = useRef<TEtpDispatchContext>([dispatch, getState]);

    return (
        <ETPStateContext.Provider value={state}>
            <ETPDispatchContext.Provider value={dispatchArrayRef.current}>{children}</ETPDispatchContext.Provider>
        </ETPStateContext.Provider>
    );
};

export const useETPDispatch = () => useContext(ETPDispatchContext);

export const useETPSelector = <T extends unknown>(selector: ContextSelector<ETPState, T>): T =>
    useContextSelector(ETPStateContext, selector);

export const useETPSelectors = <
    Properties extends string,
    SelectedValue extends unknown,
    Selectors extends Record<Properties, ContextSelector<ETPState, SelectedValue>>
>(
    selectors: Selectors
    // TODO: Fix useGlobalSelectorsType
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => useContextSelectors(ETPStateContext, selectors);
