import * as React from 'react';
import { useCallback, useState } from 'react';
import { DialogContent, Text, Target } from 'office-ui-fabric-react';

import { APP_STRINGS, APP_CONSTANTS } from '../../../../../../res';
import { Slot } from '../../../../../../domain';
import { RTDCallout } from '../../../../../../components';

import { createSlotLabel, SlotLabel } from './lib';

import styles from './SlotCallout.module.scss';

interface ExtendedSlot extends Slot {
    index: number;
    label: SlotLabel;
}

// Slot grid code is somewhat hot. Do this work outside of it so that we don't
// need to create any slot objects and we create less labels
const cells: ExtendedSlot[] = [];
{
    let index = 0;
    for (let height = 1; height <= APP_CONSTANTS.visuals.slotMaxWidth; height++) {
        for (let width = 1; width <= APP_CONSTANTS.visuals.slotMaxHeight; width++) {
            const slot = { width, height };
            cells.push({ index, ...slot, label: createSlotLabel(slot) });
            index++;
        }
    }
}

const Cell: React.FC<{
    selected: Slot;
    slot: ExtendedSlot;
    hovered?: Slot;
    onSave: (slot: Slot) => void;
    setHovered: (slot: ExtendedSlot) => void;
}> = ({ selected, hovered, slot, onSave, setHovered }) => {
    const onClick = useCallback(() => onSave({ width: slot.width, height: slot.height }), [slot, onSave]);

    const isSelected = slot.width <= selected.width && slot.height <= selected.height;
    const applyHoverHighlight = !!hovered && slot.width <= hovered.width && slot.height <= hovered.height;
    const onMouseEnter = () => setHovered(slot);

    return (
        <button
            onMouseEnter={onMouseEnter}
            aria-label={slot.label.text}
            className={`${styles.cell} ${isSelected ? styles.selected : ''} ${
                applyHoverHighlight ? styles.hovered : ''
            }`}
            onClick={onClick}
        />
    );
};

const SlotGrid: React.FC<{ selected: Slot; onSave: (slot: Slot) => void }> = ({ selected, onSave }) => {
    const [hovered, setHovered] = useState<ExtendedSlot | undefined>();

    return (
        <>
            <div onMouseLeave={() => setHovered(undefined)} className={styles.grid}>
                {cells.map((slot) => (
                    <Cell
                        key={slot.index}
                        hovered={hovered}
                        selected={selected}
                        slot={slot}
                        onSave={onSave}
                        setHovered={setHovered}
                    />
                ))}
            </div>
            <Text className={styles.selectedText}>{hovered ? hovered.label.node : createSlotLabel(selected).node}</Text>
        </>
    );
};

export interface SlotCalloutProps {
    target: Target;
    slot: Slot;
    onClose: () => void;
    onSave: (slot: Slot) => void;
}

export const SlotCallout: React.FC<SlotCalloutProps> = ({ target, slot, onClose, onSave }) => (
    <RTDCallout target={target} onDismiss={onClose}>
        <DialogContent
            className={styles.dialogContent}
            title={APP_STRINGS.editTilePage.visualConfig.multiState__slot.calloutTitle}
            onDismiss={onClose}
        >
            <SlotGrid onSave={onSave} selected={slot} />
        </DialogContent>
    </RTDCallout>
);
