import * as React from 'react';
import { Spinner } from 'office-ui-fabric-react';

import { IRtdDashboardsCore, useCore } from '../../core';
import {
    useVisualConfig,
    IDataVisualProps,
    VisualOptions,
    createTelemetry,
    TRtdValue,
    StreamQueryResult,
    useTimeZone,
    TileMessageProps,
} from '../../domain';
import { useThemeState } from '../../domain/theming';
import { UnsupportedVisualType } from '../errors/UnsupportedVisualType';
import { APP_CONSTANTS, APP_STRINGS } from '../../res';
import { DashboardStore, useDashboardStore } from '../../store/dashboard';
import { generateCid } from '../../domain';
import { IParameterSelections } from '../../store';
import { assertNever, Result } from '../../common';
import type { TileHtmlErrorId } from '../../domain/visualConfig';

import styles from './DataVisual.module.scss';

import { TileMessage } from './TileMessage';

class DataVisualErrorBoundary extends React.Component<{
    htmlErrorId?: TileHtmlErrorId;
    core: IRtdDashboardsCore;
    store: DashboardStore;
}> {
    state: { err?: { err: unknown; cid: string } } = {};

    componentDidCatch(err: unknown) {
        const cid = generateCid('DVEB');
        this.setState({ err: { err, cid } });
        createTelemetry('DataVisual', this.props.core.telemetryService, this.props.store)(String(err), { cid });
    }

    render() {
        if (this.state.err) {
            const { err, cid } = this.state.err;
            return (
                <TileMessage
                    htmlErrorId={this.props.htmlErrorId}
                    title={err instanceof Error && err.name}
                    message={err instanceof Error && err.message}
                    level="error"
                >
                    <p>
                        {APP_STRINGS.error.correlationIdLabelText}: {cid}
                    </p>
                </TileMessage>
            );
        }
        return this.props.children;
    }
}

const noop = () => {};

export interface DataVisualProps {
    /**
     * Cross filtering and drill-down will be applied to these selections. If
     * `undefined` cross filtering will be disabled.
     */
    selectedParameters?: IParameterSelections;
    visualOptions?: VisualOptions;
    queryResult: StreamQueryResult;
    setResultCounts?: IDataVisualProps['setResultCounts'];
    htmlErrorId?: TileHtmlErrorId;
    visualType: string;
}

/**
 * memo'd because re-rendering a chart or something can be _real_ expensive
 */
export const DataVisual: React.FC<DataVisualProps> = React.memo(
    ({
        selectedParameters,
        visualType,
        visualOptions = APP_CONSTANTS.visuals.defaultVisualOptions,
        queryResult,
        setResultCounts = noop,
        htmlErrorId,
    }) => {
        const core = useCore();
        const store = useDashboardStore();
        const { isDark } = useThemeState();
        const { visualTypes, DataVisualComponent } = useVisualConfig();
        const timeZone = useTimeZone();

        const config = visualTypes[visualType];

        const setParameter = React.useMemo(
            () =>
                selectedParameters &&
                ((parameterId: string, value: Result<TRtdValue, React.ReactNode>) =>
                    selectedParameters.crossFilter(parameterId, value)),
            [selectedParameters]
        );

        if (config === undefined) {
            return <UnsupportedVisualType id={htmlErrorId} type={visualType} />;
        }

        switch (queryResult.kind) {
            case 'err':
                return (
                    <TileMessage
                        htmlErrorId={htmlErrorId}
                        title={queryResult.reason.title}
                        message={queryResult.reason.body}
                        level="error"
                    />
                );
            case 'notRun':
                return (
                    <Spinner
                        className={styles.loadingSpinner}
                        label="Loading..."
                        data-testid="visual-spinner"
                        ariaLive="assertive"
                    />
                );
            case 'success':
                return (
                    <DataVisualErrorBoundary htmlErrorId={htmlErrorId} core={core} store={store}>
                        <DataVisualComponent
                            MessageComponent={(props: TileMessageProps) => TileMessage({ htmlErrorId, ...props })}
                            columns={queryResult.result.columns}
                            rows={queryResult.result.rows}
                            isSorted={queryResult.result.isSorted}
                            isDarkTheme={isDark}
                            timeZone={timeZone}
                            visualType={visualType}
                            visualOptions={visualOptions}
                            setResultCounts={setResultCounts}
                            setParameter={setParameter}
                        />
                    </DataVisualErrorBoundary>
                );
            default:
                assertNever(queryResult);
        }
    }
);
