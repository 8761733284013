import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { TextField } from 'office-ui-fabric-react';
import { debounce } from 'lodash';

import { APP_STRINGS, APP_CONSTANTS } from '../../../../../res';

import { useETPDispatch, useETPSelector } from '../../../../../store/editTile';
import { useRegisterDebounce, visualOptionsSelectorBuilder } from '../../../lib';
import { ManagedConfigComponent } from '../types';

import styles from '../configUtils/styles.module.scss';

const axisLimit = (property: 'yAxisMinimumValue' | 'yAxisMaximumValue'): ManagedConfigComponent => {
    const limitSelector = visualOptionsSelectorBuilder((options) => options[property]);

    return ({ disabled }) => {
        const [dispatch] = useETPDispatch();
        const limit = useETPSelector(limitSelector);
        const [text, setText] = useState(limit);

        useEffect(() => setText(limit), [limit]);

        const { onValueChange, dispatchValueChange } = useMemo(() => {
            const dispatchChange = debounce(
                (value: string) => dispatch({ type: 'updateYAxisLimit', property, value: value }),
                APP_CONSTANTS.debounce.high
            );
            return {
                dispatchValueChange: dispatchChange,
                onValueChange: (_: unknown, value?: string) => {
                    setText(value as string);
                    dispatchValueChange(value as string);
                },
            };
        }, [dispatch, setText]);

        useRegisterDebounce(dispatchValueChange);

        return (
            <TextField
                className={styles.basicInput}
                placeholder={APP_STRINGS.editTilePage.visualConfig.textInputInferPlaceholder}
                value={text}
                label={APP_STRINGS.editTilePage.visualConfig[property].inputLabel}
                type="number"
                onChange={onValueChange}
                disabled={disabled}
            />
        );
    };
};

export const YAxisMinimumValue = axisLimit('yAxisMinimumValue');
export const YAxisMaximumValue = axisLimit('yAxisMaximumValue');
