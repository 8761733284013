import * as React from 'react';
import { useRef, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { autorun, reaction, runInAction } from 'mobx';

import { withTelemetry } from '../../core/react';
import { MonacoResizeContainer } from '../../components/QueryEditing';
import { APP_CONSTANTS } from '../../res';
import { IQueryEditor } from '../../components';

import { DashboardStore, ETPDispatch, IParameterSelections, useDashboardStore, useETPDispatch } from '../../store';

import {
    TileLoadingError,
    ParametersListWrapper,
    BottomPane,
    ETPPageContent,
    VisualConfig,
    QueryHandler,
    TopPane,
} from './components';
import { FilteredTileId } from './types';
import { FlushHandlesProvider, useInitializeETP, useFlushHandles } from './lib';
import { etpTelemetryKey } from './constants';

import styles from './styles.module.scss';

export interface ETPDisplayOnlyProps {
    dashboardId: string;
    tileId: FilteredTileId;
    parameterSelections: undefined | IParameterSelections;
}

export const ETPDisplayOnly: React.FC<ETPDisplayOnlyProps> = ({ dashboardId, tileId, parameterSelections }) => {
    const queryEditorRef = useRef<IQueryEditor>(null);
    const flushHandles = useFlushHandles();

    useEffect(() => {
        const flush = async () => {
            if (queryEditorRef.current) {
                await queryEditorRef.current.forceUpdate();
            }
        };
        flushHandles.handles.add(flush);
        return () => {
            flushHandles.handles.delete(flush);
        };
    }, [flushHandles]);

    return (
        <ETPPageContent dashboardId={dashboardId} tileId={tileId}>
            <ParametersListWrapper parameterSelections={parameterSelections} />
            <VisualConfig />
            <MonacoResizeContainer
                queryEditorRef={queryEditorRef}
                className={styles.resizeContainer}
                topPaneClassName={styles.resizeContainerTopPane}
                topChildren={<TopPane ref={queryEditorRef} />}
                bottomChildren={<BottomPane crossFilterTarget={parameterSelections} tileId={tileId} />}
            />
        </ETPPageContent>
    );
};

function useETPParameterSelections(dashboardStore: DashboardStore, dispatch: ETPDispatch) {
    // TODO: Move into dashboard store when it's refactored to use mobx
    const [parameterState, setParameterState] = React.useState<undefined | IParameterSelections>(() =>
        runInAction(() => dashboardStore.state?.selectedParameters.clone())
    );

    useEffect(
        () =>
            reaction(
                () => dashboardStore.state,
                (state) => {
                    setParameterState(state?.selectedParameters.clone());
                },
                { fireImmediately: false }
            ),
        [dashboardStore]
    );

    useEffect(() => {
        if (parameterState) {
            return autorun(() =>
                dispatch({
                    type: 'updateParameterSelections',
                    parameterSelections: Object.values(parameterState?.selections),
                })
            );
        }
        return;
    }, [dispatch, parameterState]);

    return parameterState;
}

const NoTelemetryEditTilePage: React.FC = () => {
    const { dashboardId, tileId } = useRouteMatch<{
        dashboardId: string;
        tileId: string;
    }>().params;

    const filteredTileId =
        tileId !== APP_CONSTANTS.editTilePage.newMarkdownTileId && tileId !== APP_CONSTANTS.editTilePage.newQueryTileId
            ? tileId
            : undefined;

    const dashboardStore = useDashboardStore();
    const [dispatch] = useETPDispatch();

    const parameterSelections = useETPParameterSelections(dashboardStore, dispatch);

    useInitializeETP(tileId, parameterSelections);

    return (
        <FlushHandlesProvider>
            <QueryHandler parameterSelections={parameterSelections}>
                <TileLoadingError dashboardId={dashboardId} />
                <ETPDisplayOnly
                    dashboardId={dashboardId}
                    tileId={filteredTileId}
                    parameterSelections={parameterSelections}
                />
            </QueryHandler>
        </FlushHandlesProvider>
    );
};

export const EditTilePage = withTelemetry(NoTelemetryEditTilePage, etpTelemetryKey, styles.telemetry);
