import * as _ from 'lodash';
import {
    getQueryResourceConsumption,
    DataSetCompletion,
    DataTable,
    EmptyVisualizationOptionsSnapshot,
    ErrorDescription,
    extractErrorDescription,
    Frame,
    KustoResult,
    KustoResultV2,
    ValueRow,
    VisualizationOptions,
    QueryResourceConsumption,
} from '@kusto/common';
import { TableResult } from 'agGrid/AgGridWithKustoData';
import { QueryResultRowObject } from '../@types/chart.types';

const convertRow = (row: ValueRow, columnNames: string[]): QueryResultRowObject =>
    row.reduce((rowAsObject: { [key: string]: string | null }, val, index): {} => {
        let valAsString: string | null = '';
        if (val === null || val === undefined) {
            valAsString = val as null;
        } else if (typeof val === 'object') {
            valAsString = JSON.stringify(val);
        } else {
            valAsString = val as string;
        }
        rowAsObject[columnNames[index]] = valAsString;
        return rowAsObject;
    }, {});

const convertRows = (rowsAsArrays: ValueRow[], columnNames: string[]) =>
    rowsAsArrays.map((row) => convertRow(row, columnNames));

/**
 * translate raw results from raw Kusto json to store model.
 * @param rawQueryResults raw query results from kusto endpoint.
 */
export const kustoResultV1ToResultTable = (rawQueryResults: KustoResult): TableResult[] => {
    const kindIndex = 1; // the kind column in the TOC result table.

    // If there's only one table, this is probably a control command and it's the only primary result.
    // otherwise, the last table will be the TOC and we can extract the number of query results from it.
    const numberOfPrimaryResults =
        rawQueryResults.Tables.length === 1
            ? 1
            : (rawQueryResults.Tables[rawQueryResults.Tables.length - 1].Rows as ValueRow[]).filter(
                  (row) => row[kindIndex] === 'QueryResult'
              ).length;

    const results = _.range(0, numberOfPrimaryResults).map((i) => {
        const columnsAndTypes = rawQueryResults.Tables[i].Columns;
        const columnNames = columnsAndTypes.map((x) => x.ColumnName);
        const rowsAsArrays = rawQueryResults.Tables[i].Rows;

        // convert an array of arrays to an array of objects
        const rows = convertRows(rowsAsArrays as ValueRow[], columnNames);

        const columns = columnsAndTypes.map((nameAndType) => ({
            headerName: nameAndType.ColumnName,
            field: nameAndType.ColumnName,
            dataType: nameAndType.DataType,
            columnType: nameAndType.ColumnType,
        }));

        let visualizationOptions = getVisualizationOptions(i, numberOfPrimaryResults, rawQueryResults);

        return {
            rows,
            columns,
            visualizationOptions,
        };
    });
    return results;
};

/**
 * translate raw results from raw Kusto json to store model.
 * @param rawQueryResults raw query results from kusto endpoint.
 */
// TODO: this logic should be in the kusto client, otherwise,
// any part running queries won't parse them properly for the grid
export const kustoResultV2ToResultTable = (
    rawQueryResults: KustoResultV2
): {
    results: TableResult[];
    queryResourceConsumption?: QueryResourceConsumption;
    errorDescription?: ErrorDescription;
} => {
    // type guard for a data table frame.
    const isDataTable = (x: Frame): x is DataTable => x.FrameType === 'DataTable';
    const isDataSetCompletion = (x: Frame): x is DataSetCompletion => x.FrameType === 'DataSetCompletion';
    const dataSetCompletion = rawQueryResults.filter(isDataSetCompletion)[0];
    let errorDescription: undefined | ErrorDescription = undefined;
    if (dataSetCompletion && dataSetCompletion.HasErrors) {
        const rawError = dataSetCompletion.OneApiErrors[0];
        errorDescription = extractErrorDescription(rawError);
    }

    // If there's only one table, this is probably a control command and it's the only primary result.
    // otherwise, the last table will be the TOC and we can extract the number of query results from it.
    const dataTableFrames = rawQueryResults.filter(isDataTable);
    const primaryResults = dataTableFrames.filter((dt) => dt.TableKind === 'PrimaryResult');
    const queryPropertiesFrame = dataTableFrames.filter((frame) => frame.TableKind === 'QueryProperties')[0];
    const queryResourceConsumption = getQueryResourceConsumption(dataTableFrames) ?? undefined;

    const visualizationOptionsMapping = parseVisualizationOptionsV2(queryPropertiesFrame);

    const results = primaryResults.map((primaryResult, _i): TableResult => {
        const tableId = primaryResult.TableId;
        const columnsAndTypes = primaryResult.Columns;
        const columnNames = columnsAndTypes.map((x) => x.ColumnName);
        const rowsAsArrays = primaryResult.Rows;

        // convert an array of arrays to an array of objects
        const rows = rowsAsArrays
            .map((row) => {
                // if this isn't an array, it can be an error (such as query limits exceeded).
                if (!Array.isArray(row)) {
                    const rawError = row.OneApiErrors[0];
                    errorDescription = extractErrorDescription(rawError);
                    return undefined;
                }

                return convertRow(row, columnNames);
            })
            .filter((row) => row !== undefined) as QueryResultRowObject[];

        const columns = columnsAndTypes.map((nameAndType) => ({
            headerName: nameAndType.ColumnName,
            field: nameAndType.ColumnName,
            columnType: nameAndType.ColumnType,
        }));

        const visualizationOptions = visualizationOptionsMapping[tableId] ?? EmptyVisualizationOptionsSnapshot;

        return {
            rows,
            columns,
            visualizationOptions,
        };
    });

    return { results, errorDescription, queryResourceConsumption };
};

/**
 * get visualization options for result i in API v1
 * @param i table index
 * @param numberOfPrimaryResults number of primary results
 * @param queryResults kusto result
 */
function getVisualizationOptions(i: number, numberOfPrimaryResults: number, queryResults: KustoResult) {
    let visualizationOptions = EmptyVisualizationOptionsSnapshot;
    // currently we only get visualization data to for the last primary result.
    if (i === numberOfPrimaryResults - 1) {
        // Extract visualization data. it is the first table after primary results
        // if we got a visualization table as a second result
        if (queryResults.Tables.length > numberOfPrimaryResults) {
            // it might be a control command without any visualization info and we're just reading the wrong field.
            try {
                // only a single cell with json string
                const visualizationDataString: string = (
                    queryResults.Tables[numberOfPrimaryResults].Rows[0] as ValueRow
                )[0] as string;
                const visualizationDataObject = JSON.parse(visualizationDataString);
                visualizationOptions = visualizationDataObject;
            } catch {
                // do nothing
            }
        }
    }
    return visualizationOptions;
}

/**
 * parse visualization options for API v2
 * @param queryPropertiesFrame A Kusto DataTable. 
 * @example queryPropertiesFrame:
 * {
        "FrameType": "DataTable",
        "TableId": 0,
        "TableKind": "QueryProperties",
        "TableName": "@ExtendedProperties",
        "Columns": [
            {
                "ColumnName": "TableId",
                "ColumnType": "int"
            },
            {
                "ColumnName": "Key",
                "ColumnType": "string"
            },
            {
                "ColumnName": "Value",
                "ColumnType": "dynamic"
            }
        ],
        "Rows": [
            [
                1,
                "Visualization",
                "{\"Visualization\":\"barchart\",\"Title\":null,\"XColumn\":null,\"Series\":null,\"YColumns\":null,\"AnomalyColumns\":null,\"XTitle\":null,\"YTitle\":null,\"XAxis\":null,\"YAxis\":null,\"Legend\":null,\"YSplit\":null,\"Accumulate\":false,\"IsQuerySorted\":false,\"Kind\":null,\"Ymin\":\"NaN\",\"Ymax\":\"NaN\"}"
            ]
        ]
    },
 */
function parseVisualizationOptionsV2(queryPropertiesFrame: DataTable): { [tableId: string]: VisualizationOptions } {
    const tableIdIndex = 0;
    const keyIndex = 1;
    const valueIndex = 2;

    if (!queryPropertiesFrame || !queryPropertiesFrame.Rows) {
        return {};
    }

    const rows = queryPropertiesFrame.Rows as { [key: string]: any }[];
    return rows
        .filter((row) => row[keyIndex] === 'Visualization')
        .reduce((visualizations, row) => {
            const tableId = row[tableIdIndex];
            let value = row[valueIndex];
            if (typeof value === 'string') {
                value = JSON.parse(value) as VisualizationOptions;
            }
            visualizations[`${tableId}`] = value;
            return visualizations;
        }, {});
}
