import { Subscription, Observable, interval } from 'rxjs';
import { startWith, share } from 'rxjs/operators';

export class SynchronizedIntervalManager {
    private intervals: {
        [interval: number]: Observable<void>;
    } = {};

    public subscribe(intervalTime: number, callback: () => void): Subscription {
        const existingInterval = this.intervals[intervalTime];

        if (existingInterval) {
            callback();

            return existingInterval.subscribe({
                next: callback,
            });
        }

        const observable = interval(intervalTime).pipe(startWith(0), share());

        this.intervals[intervalTime] = observable as Observable<unknown> as Observable<void>;

        return observable.subscribe({ next: callback });
    }
}
