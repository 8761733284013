import * as React from 'react';
import { useCallback } from 'react';

import { GlobalAction } from '../store/redux/types';
import { useGlobalDispatch } from '../store/redux/context';
import { CloseActiveFromAction, PushActiveFormAction } from '../store';

export type FormHOC<T> = (closeAction: GlobalAction, args: T) => React.ComponentType;

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export type TFormComponent<P extends Props | undefined = undefined> = React.ComponentType<
    P extends undefined ? { onClose: () => void } : P & { onClose: () => void }
>;

const formKeys = new Set<string>();

export type FormActionCreator<T> = {
    (...args: T extends undefined ? [] : [T]): PushActiveFormAction;
    key: string;
    close: CloseActiveFromAction;
};

export const formActionMacro = <T extends Props | undefined = undefined>(
    FormComponent: TFormComponent<T>,
    key: string
): FormActionCreator<T> => {
    if (formKeys.has(key)) {
        throw new Error(`Duplicate form key: ${key}`);
    }
    formKeys.add(key);
    const closeAction: CloseActiveFromAction = { type: 'closeActiveForm', key };
    const action = (...args: T extends undefined ? [] : [T]) => ({
        type: 'pushActiveForm' as const,
        key,
        Form: () => {
            const [dispatch] = useGlobalDispatch();
            const onClose = useCallback(() => dispatch(closeAction), [dispatch]);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return <FormComponent {...(args[0] as any)} onClose={onClose} />;
        },
    });
    action.key = key;
    action.close = closeAction;
    return action;
};
