import * as React from 'react';
import { useCallback } from 'react';
import { IconButton } from 'office-ui-fabric-react/lib/components/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import classNames from 'classnames';

import { APP_STRINGS } from '../../../res';
import type { StreamQueryResult, QueryHash } from '../../../domain';
import { useCore } from '../../../core';
import { useTelemetry } from '../../../domain/telemetry';
import { useAsync } from '../../../common';

import styles from './Header.module.scss';

const refreshButtonIconProps = { iconName: 'Refresh' };

interface RefreshTileButtonProps {
    onRefreshClick?: () => void;
    queryHash: QueryHash;
}

export const RefreshTileButton: React.FC<RefreshTileButtonProps> = ({
    onRefreshClick: externalRefreshClick,
    queryHash,
}) => {
    const { queryService } = useCore();
    const trackEvent = useTelemetry('RefreshTileButton');

    const { value } = useAsync<StreamQueryResult>(() => queryService.observeQuery(queryHash), [queryHash]);

    const status = value?.status;
    const isRefreshing = !status || status.kind === 'loading';

    const onRefreshClick = useCallback(() => {
        queryService.requestQueryUpdate(queryHash);
        trackEvent('ManualIndividualRefreshTriggered');
    }, [queryHash, queryService, trackEvent]);

    return (
        <IconButton
            className={classNames(styles.headerAction, { [styles.isRefreshing]: isRefreshing })}
            iconProps={isRefreshing ? undefined : refreshButtonIconProps}
            disabled={isRefreshing}
            onClick={externalRefreshClick ?? onRefreshClick}
            title={APP_STRINGS.components.tile.refreshButtonTitle}
            aria-label={APP_STRINGS.components.tile.refreshButtonTitle}
        >
            {isRefreshing && <Spinner className={styles.headerAction} size={SpinnerSize.small} />}
        </IconButton>
    );
};
