import React from 'react';
import {
    DefaultButton,
    PrimaryButton,
    DialogFooter,
    TextField,
    ITextFieldProps,
    IRefObject,
    ITextField,
} from 'office-ui-fabric-react';
import { autorun, runInAction } from 'mobx';
import { useHistory } from 'react-router-dom';

import { formActionMacro, TFormComponent } from '../../../common';
import { APP_STRINGS, APP_CONSTANTS } from '../../../res';
import { RTDDialog } from '../../fabric';
import { newDashboardDocument, RouteState } from '../../../domain';
import { useCore } from '../../../core';
import { useDashboardStore } from '../../../store/dashboard';
import { manageDataSourcesAction } from '../manage/ManageDataSources';
import { useGlobalDispatch } from '../../../store';
import {
    DashboardLatestVersion,
    DashboardSerialized,
    migrateDashboard,
    serializeDashboard,
    splitSerializedDashboard,
} from '../../../migration';
import { WarningsList } from '../../errors/WarningsList';
import { RtdHeader } from '../../RtdHeader';

import styles from './CreateDashboardDialog.module.scss';

const formatMaxLengthError = (maxLength: number) => {
    const strings = APP_STRINGS.dashboardPage.maxLengthError;
    return `${strings.start} ${maxLength} ${strings.end}`;
};

export interface CreateDashboardNameFieldProps extends ITextFieldProps {
    value: string;
}

export const CreateDashboardNameField: React.FC<CreateDashboardNameFieldProps> = (props) => (
    <TextField
        data-automation-id="dashboard-title-input"
        id="dashboard-title"
        label={APP_STRINGS.forms.newDashboard.name}
        placeholder={APP_STRINGS.forms.newDashboard.namePlaceholder}
        maxLength={APP_CONSTANTS.dashboardTitleMaxLength}
        autoComplete="off"
        errorMessage={
            props.value.length >= APP_CONSTANTS.dashboardTitleMaxLength
                ? formatMaxLengthError(APP_CONSTANTS.dashboardTitleMaxLength)
                : ''
        }
        {...props}
    />
);

// Focus input text on render
const nameInputRefCallback: IRefObject<ITextField> = (ref) => {
    if (ref && ref.value) {
        ref.setSelectionRange(0, ref.value.length);
    }
};

export interface CreateDashboardFormArgs {
    template?: DashboardSerialized;
    dashboardTitle?: string;
    title?: string;
    openDataSources?: boolean;
    migrationWarnings?: string[];
}

export const CreateDashboardDialog: TFormComponent<CreateDashboardFormArgs> = ({
    template,
    dashboardTitle,
    title: argTitle,
    onClose,
    openDataSources,
    migrationWarnings: propMigrationWarnings,
}) => {
    const core = useCore();
    const history = useHistory<RouteState>();
    const [dispatch] = useGlobalDispatch();
    const dashboardStore = useDashboardStore();

    const migrationResults = React.useMemo(() => {
        if (!template) return undefined;
        const { dashboard, meta: _ } = splitSerializedDashboard(template);
        return migrateDashboard(dashboard);
    }, [template]);

    const [title, setTitle] = React.useState(migrationResults?.value?.data.title ?? '');

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) =>
        runInAction(() => {
            event.preventDefault();

            if (title.trim() === '') {
                return;
            }

            let dashboardTemplate: DashboardLatestVersion;

            if (migrationResults) {
                if (migrationResults.kind === 'err') {
                    return;
                }
                dashboardTemplate = { ...migrationResults.value.data, title };
            } else {
                dashboardTemplate = splitSerializedDashboard(serializeDashboard(newDashboardDocument(title))).dashboard;
            }

            if (openDataSources) {
                // NOTE: If we ever have correctness issues with this, then we could add
                // an "open data sources" argument to the route state that would be
                // more reliable, at the cost of some perf related to functionally
                // replacing the route after open.
                const dispose = autorun(() => {
                    if (dashboardStore.state !== undefined) {
                        dispatch(
                            manageDataSourcesAction({
                                dashboardState: dashboardStore.state,
                                straightToAddDataSource: true,
                            })
                        );
                        dispose();
                    }
                });
            }

            history.push(core.navUtil.path.dashboard('new'), {
                dashboardTemplate,
            });

            onClose();
        });

    const isValid = title.trim() !== '' && migrationResults?.kind !== 'err';

    const dialogTitle =
        argTitle ??
        (template ? (
            <>
                {APP_STRINGS.forms.createDashboard.duplicateTitle} <i>{dashboardTitle}</i>
            </>
        ) : (
            APP_STRINGS.forms.createDashboard.newTitle
        ));

    const migrationWarnings = propMigrationWarnings ?? migrationResults?.value?.warnings;
    // If migration warnings are present, increase width to accommodate them
    const maxWidth = migrationWarnings && migrationWarnings.length !== 0 ? 480 : undefined;

    return (
        <RTDDialog hidden={false} dialogContentProps={{ title: dialogTitle }} onDismiss={onClose} maxWidth={maxWidth}>
            <form onSubmit={onSubmit} className={styles.container}>
                <CreateDashboardNameField
                    value={title}
                    onChange={(_, newValue) => setTitle(newValue ?? '')}
                    componentRef={nameInputRefCallback}
                />
                {migrationWarnings && migrationWarnings.length !== 0 && (
                    <>
                        <RtdHeader level={3}>{APP_STRINGS.migration.warnings.onLoadWithWarningsTitle}</RtdHeader>
                        <WarningsList warnings={migrationWarnings} />
                    </>
                )}
                <DialogFooter>
                    <PrimaryButton
                        data-automation-id="create-dashboard-button"
                        text={APP_STRINGS.utilButtons.create}
                        disabled={!isValid}
                        type="submit"
                    />
                    <DefaultButton
                        data-automation-id="cancel-create-dashboard-button"
                        onClick={onClose}
                        text={APP_STRINGS.utilButtons.cancel}
                    />
                </DialogFooter>
            </form>
        </RTDDialog>
    );
};

export const createDashboardDialogAction = formActionMacro(CreateDashboardDialog, 'createDashboard');
