export interface ParameterSelectorListState {
    isOpen: boolean;
    isAnimating: boolean;
}

export type ParameterSelectorListAction =
    | {
          type: 'setIsOpen';
          isOpen: boolean;
      }
    | {
          type: 'toggleIsOpen';
      }
    | {
          type: 'finishAnimation';
      };

export const parameterSelectorListReducer = (
    state: ParameterSelectorListState,
    action: ParameterSelectorListAction
): ParameterSelectorListState => {
    switch (action.type) {
        case 'setIsOpen': {
            if (action.isOpen === state.isOpen) {
                return state;
            }

            return {
                ...state,
                isOpen: action.isOpen,
                isAnimating: true,
            };
        }
        case 'toggleIsOpen': {
            return {
                ...state,
                isOpen: !state.isOpen,
                isAnimating: true,
            };
        }
        case 'finishAnimation': {
            if (!state.isAnimating) {
                return state;
            }

            return {
                ...state,
                isAnimating: false,
            };
        }
    }
};
