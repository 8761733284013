import { useContext } from 'react';

import type { IRtdDashboardsCore } from '../types';
import { dashboardsCoreContext } from './context';

/**
 * The RTD application context with user-level services and settings.
 */
export const useCore = (): IRtdDashboardsCore => {
    const coreContext = useContext(dashboardsCoreContext);

    switch (coreContext.status) {
        case 'ready':
            return coreContext.appContext;

        case 'error':
            throw new Error(coreContext.error.message);

        default:
            throw new Error('RTD application not initialized.');
    }
};
