import * as React from 'react';
import { ITextField, KeyCodes, TextField } from 'office-ui-fabric-react';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { APP_CONSTANTS } from '../../../res';
import { DashboardLoaded } from '../../../store';

import styles from './Header.module.scss';

interface EditTileTitleProps {
    dashboardLoaded: DashboardLoaded;
    tileId: string;
    title: string;
}

const textFieldRef: React.RefCallback<ITextField> = (ref) => {
    ref?.focus();
    ref?.select();
};

export const EditTileTitle: React.FC<EditTileTitleProps> = observer(function TileTitle({
    dashboardLoaded,
    tileId,
    title,
}) {
    const [newTitle, setNewTitle] = React.useState(title);
    const pressedEscapeKey = React.useRef<boolean>(false);
    const tile = dashboardLoaded.tilesRecord[tileId];

    const updateNewTitle = React.useCallback((_: unknown, newValue?: string) => {
        if (newValue === undefined) return;
        setNewTitle(newValue);
    }, []);

    const applyNewTitle = action(() => {
        dashboardLoaded.addItem('tiles', {
            ...tile,
            title: newTitle,
        });

        dashboardLoaded.activeTileRename?.onFormClosed();
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        applyNewTitle();
    };

    const handleBlur = () => {
        /**
         * pressedEscapeKey is needed
         * because closing the form
         * will trigger onBlur and so
         * we want to avoid applying
         * the new title when the user
         * presses "Escape"
         */
        if (pressedEscapeKey.current) {
            pressedEscapeKey.current = false; // resetting for next pass
            return;
        }
        applyNewTitle();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === KeyCodes.escape) {
            pressedEscapeKey.current = true;
            dashboardLoaded.activeTileRename?.onFormClosed();
        }
    };

    return (
        <form
            className={classNames(styles.titleContainer, APP_CONSTANTS.dashboardPage.notDraggableTitleHeaderClassName)}
            onSubmit={handleSubmit}
            onBlur={handleBlur}
        >
            <TextField
                componentRef={textFieldRef}
                data-automation-id="tile-title-input"
                id={`tile-title-input-${tileId}`}
                className={styles.titleInputWrapper}
                inputClassName={styles.titleInput}
                onChange={updateNewTitle}
                defaultValue={title}
                autoComplete="off"
                onKeyDown={handleKeyDown}
            />
        </form>
    );
});
