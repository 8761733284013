import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';

import { QueryEditor, IQueryEditor } from '../../../../components';
import { APP_CONSTANTS } from '../../../../res';

import { useETPDispatch, useETPSelectors, ETPState, ETPQueryState } from '../../../../store/editTile';
import { etpTelemetryKey } from '../../constants';
import { useRegisterDebounce } from '../../lib';

import styles from '../../styles.module.scss';
import { useDashboardStore } from '../../../../store/dashboard';

type ETPQueryEditorProps = {
    runQuery: () => void;
};

const editorDidMount = (editor: monaco.editor.IStandaloneCodeEditor) => editor.focus();

const queryEditorSelectors = {
    // Treat loading as markdown to prevent fetching monaco-kusto unnecessarily
    isMarkdown: (s: ETPState) => s.type === 'loading' || s.type === 'markdown',
    // TODO: This doesn't seem right
    initialValue: (s: ETPState) => (s.type !== 'loading' && s.type !== 'error' ? s.query : undefined),
    dataSourceId: (s: ETPState) => (s.type === 'query' ? s.dataSourceId : undefined),
};

export const ETPQueryEditor = observer<ETPQueryEditorProps, IQueryEditor | null>(
    ({ runQuery }, ref) => {
        const [dispatch] = useETPDispatch();
        const {
            isMarkdown,
            initialValue,
            dataSourceId,
        }: {
            isMarkdown: boolean;
            initialValue: undefined | ETPQueryState['query'];
            dataSourceId: undefined | string;
        } = useETPSelectors(queryEditorSelectors);
        const store = useDashboardStore();
        const dataSource = dataSourceId === undefined ? undefined : store.state?.dataSourcesRecord[dataSourceId];

        const onParameterChange = useCallback(
            (usedParameters) => dispatch({ type: 'updateUsedVariables', usedParameters }),
            [dispatch]
        );

        // useMemo instead of useCallback so we can avoid re-calling debounce
        const setQuery = useMemo(
            () =>
                debounce((query: string) => dispatch({ type: 'updateQuery', query }), APP_CONSTANTS.debounce.standard),
            [dispatch]
        );

        useRegisterDebounce(setQuery);

        return (
            <QueryEditor
                className={styles.queryEditor}
                ref={ref}
                isMarkdown={isMarkdown}
                initialValue={initialValue}
                dataSource={dataSource}
                telemetryComponentName={etpTelemetryKey}
                parameters={store.state?.parameters}
                onParameterChange={onParameterChange}
                onChange={setQuery}
                runQuery={runQuery}
                editorDidMount={editorDidMount}
            />
        );
    },
    { forwardRef: true }
);
