import * as React from 'react';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';

import { APP_STRINGS } from '../../../../res';
import { UDataSource } from '../../../../core/domain';
import { useDashboardStore } from '../../../../store/dashboard';
import { useCore } from '../../../../core';

import { IOverflowMenuItem } from './overflow/OverflowMenu';
import { ManageModelPreview } from './ManageModelPreview';

interface DataSourcePreviewProps {
    dataSource: UDataSource;

    onEdit: (dataSource: UDataSource) => void;
}

export const DataSourcePreview: React.FC<DataSourcePreviewProps> = observer(function DataSourcePreview({
    dataSource,
    onEdit,
}) {
    const core = useCore();
    const store = useDashboardStore();

    const usingTiles = useMemo(
        () => store.state?.tiles.filter((t) => t.dataSourceId === dataSource.id).map((t) => t.id) ?? [],
        [dataSource.id, store.state?.tiles]
    );

    const items = useMemo(
        (): IOverflowMenuItem[] => [
            {
                key: 'edit',
                name: APP_STRINGS.utilButtons.edit,
                iconProps: {
                    iconName: 'Edit',
                },
                ariaLabel: APP_STRINGS.forms.dataSource.aria.editButtonLabel,
                onClick: () => onEdit(dataSource),
            },
            {
                key: 'delete',
                name: APP_STRINGS.utilButtons.delete,
                iconProps: {
                    iconName: 'Delete',
                },
                ariaLabel: APP_STRINGS.forms.dataSource.aria.deleteButtonLabel,
                onClick: action(() => {
                    store.state?.deleteItem('dataSources', dataSource.id);
                }),
            },
        ],
        [onEdit, dataSource, store]
    );

    const scope = core.queryService.getScope(dataSource.scopeId);

    const variableText = scope.value?.formatDataSourceCardText(dataSource) ?? scope.err;

    return (
        <ManageModelPreview
            title={dataSource.name}
            variableText={variableText}
            items={items}
            statusItems={[
                {
                    type: 'tiles',
                    usedTileIds: usingTiles,
                    alwaysDisplay: true,
                },
            ]}
        />
    );
});
