import * as React from 'react';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { APP_CONSTANTS } from '../../../../res';
import { DashboardStore } from '../../../../store';
import { useCore } from '../../../../core';

import { RGLLayout, TileSize, updateLayoutValid, VisualConfig, VisualOptions } from '../../../../domain';

import styles from './TileSizeWrapper.module.scss';

export function getTileLayout(
    store: DashboardStore,
    tileId: string | undefined,
    visualType: string,
    visualConfig: VisualConfig,
    visualOptions?: VisualOptions
) {
    const layout =
        tileId === undefined || store.state === undefined
            ? undefined
            : Object.values(store.state.tilesLayout)
                  .flat()
                  .find((t) => t.i === tileId);

    if (layout) {
        return updateLayoutValid(layout, visualType, visualConfig, visualOptions);
    }

    return undefined;
}

const gridConstants = APP_CONSTANTS.dashboardPage.grid;

const convertLayoutSizeToPixels = (size: TileSize) => ({
    width:
        size.width * gridConstants.columnWidth +
        (size.width - 1) * gridConstants.tilePadding +
        gridConstants.containerVerticalPadding * 2,
    height:
        size.height * gridConstants.rowHeight +
        (size.height - 1) * gridConstants.tilePadding +
        gridConstants.containerVerticalPadding * 2,
});

const tileContainerPadding = `${gridConstants.containerVerticalPadding}px ${gridConstants.containerHorizontalPadding}px`;

export interface TileSizeWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
    className: string;
    defaultSize?: TileSize;
    minimumSize: TileSize;
    /**
     * Undefined if it's a new tile
     */
    dashboardLayout?: RGLLayout;
}

/**
 * Wraps a tile of a specified size in a full size container with a scrollbar
 * and padding.
 */
export const TileSizeWrapper: React.FC<TileSizeWrapperProps> = observer(function TileSizeWrapper({
    children,
    className,
    defaultSize = APP_CONSTANTS.tile.unknownTypeEditingSize,
    minimumSize,
    dashboardLayout,
    ...props
}) {
    const { editTilePagePreviewExpanded } = useCore().userSettingsState.userSettings;
    const sizeStyle = useMemo((): React.CSSProperties => {
        if (editTilePagePreviewExpanded) {
            const minSizePixels = convertLayoutSizeToPixels(minimumSize);
            return {
                minWidth: minSizePixels.width,
                minHeight: minSizePixels.height,
                width: '100%',
                height: '100%',
            };
        } else {
            const pixelSize = convertLayoutSizeToPixels(
                dashboardLayout ? { width: dashboardLayout.w, height: dashboardLayout.h } : defaultSize
            );
            // Min width/height need to be set so we can animate to them
            return {
                minWidth: pixelSize.width,
                minHeight: pixelSize.height,
                ...pixelSize,
            };
        }
    }, [dashboardLayout, defaultSize, editTilePagePreviewExpanded, minimumSize]);

    return (
        // This is done with two divs so we can avoid having padding on the element
        // with the scrollbar. Applying padding to an element with a left/right
        // scrollbar causes the right padding to not get applied correctly.
        <div className={`${styles.tileContainerScrollable} ${className}`} {...props}>
            <div style={{ padding: tileContainerPadding, ...sizeStyle }} className={styles.tileContainerInner}>
                {children}
            </div>
        </div>
    );
});
