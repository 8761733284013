import * as React from 'react';
import { useRef, useEffect, ReactElement, useLayoutEffect, useCallback } from 'react';
import { Icon, KeyCodes } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { useCore } from '../../core';
import { useDashboardStore } from '../../store';
import type { IQueryEditor } from '../Editors';
import { APP_STRINGS } from '../../res';

import styles from './MonacoResizeContainer.module.scss';

const noop = () => {};

const keyPressHeightPixels = 30;

export interface ResizeContainerProps {
    topChildren: ReactElement;
    bottomChildren: ReactElement;
    className?: string;
    topPaneClassName?: string;
    queryEditorRef: React.RefObject<IQueryEditor>;
}

const clampHeight = (height: number) => Math.max(Math.min(100, height), 0);

/**
 * Not intended to be generic, contains code specific to our query editing
 * experiences resize needs.
 *
 * Note: Component does not handle touch events. This isn't an issue because
 * the monaco editor doesn't support mobile browsers anyhow.
 */
export const MonacoResizeContainer: React.FC<ResizeContainerProps> = observer(function MonacoResizeContainer({
    topChildren,
    bottomChildren,
    queryEditorRef,
    className,
    topPaneClassName,
}) {
    const { userSettings, updateUserSetting } = useCore().userSettingsState;
    const store = useDashboardStore();

    const height = userSettings.editTilePageDividerPositionPercentage;
    const setHeight = useCallback(
        (value: number) => {
            updateUserSetting('editTilePageDividerPositionPercentage', value, store);
        },
        [updateUserSetting, store]
    );

    const containerRef = useRef<HTMLDivElement>(null);
    const removeEventListeners = useRef(noop);

    // After a height change as been committed to the dom we need to update the monaco editor layout
    useLayoutEffect(() => queryEditorRef.current?.monacoEditor.current?.layout(), [height, queryEditorRef]);

    useEffect(() => () => removeEventListeners.current(), [removeEventListeners]);

    const onDragStart = (dragStartEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const container = containerRef.current;
        if (!container) {
            return;
        }
        const mouseStartY = dragStartEvent.clientY;

        const heightFromEvent = (event: MouseEvent) => {
            const raw = height - ((mouseStartY - event.clientY) / container.getBoundingClientRect().height) * 100;
            setHeight(clampHeight(raw));
        };

        const mouseup = (event: MouseEvent) => {
            heightFromEvent(event);
            removeEventListeners.current();
        };

        document.addEventListener('mousemove', heightFromEvent);
        document.addEventListener('mouseup', mouseup);

        removeEventListeners.current();
        removeEventListeners.current = () => {
            document.removeEventListener('mousemove', heightFromEvent);
            document.removeEventListener('mouseup', mouseup);
        };
    };

    const onKeyboardResize = useCallback(
        (keyPressEvent: React.KeyboardEvent<HTMLDivElement>) => {
            const container = containerRef.current;
            if (!container) {
                return;
            }

            let newHeight: number;
            const percentageChange = (keyPressHeightPixels / container.getBoundingClientRect().height) * 100;

            switch (keyPressEvent.keyCode) {
                case KeyCodes.up: {
                    newHeight = height - percentageChange;
                    break;
                }
                case KeyCodes.down: {
                    newHeight = height + percentageChange;
                    break;
                }
                default:
                    return;
            }

            setHeight(clampHeight(newHeight));
        },
        [height, setHeight]
    );

    return (
        <div
            className={className ? `${styles.resizeContainer} ${className}` : styles.resizeContainer}
            ref={containerRef}
        >
            <div
                className={topPaneClassName ? `${styles.topPane} ${topPaneClassName}` : styles.topPane}
                style={{ height: `calc(${height}% - 2px)` }}
            >
                {topChildren}
            </div>
            <div
                role="presentation"
                onMouseDown={onDragStart}
                className={styles.divider}
                // tabIndex allows resizing the editor to be keyboard focusable and
                // resizable using aria-role:slider to imply using arrow keys
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                onKeyDown={onKeyboardResize}
            >
                <div>
                    <Icon iconName="More" role="slider" aria-label={APP_STRINGS.editTilePage.resizePaneAria} />
                </div>
            </div>
            {bottomChildren}
        </div>
    );
});
