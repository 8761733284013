import { useLayoutEffect, useState, useRef } from 'react';

/**
 * Determines whether a child DOM element is truncated or overflowing relative to its parent
 *
 * **Usage:**
 * `parentRef` must be assigned as a ref to the wrapping element, i.e. the one with the limited width/height.
 * `childRef` must be assigned as a ref to the full width/height of the content.
 *
 * @param dimension The direction to compare the parent and child sizing to determine truncation
 * @param deps Any dependencies that should cause the widths/heights of the DOM elements in question to change
 * @param options `parentDimensionMargin` applies an additional offset to the measure parent offset (in case the parent has additional styling/margins). `disable` prevents evaluation of `isTruncation`
 */
export const useIsTruncated = (
    dimension: 'width' | 'height',
    deps: unknown[],
    {
        parentDimensionMargin = 0,
        disable = false,
    }: {
        parentDimensionMargin?: number;
        disable?: boolean;
    } = {}
) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    const [isTruncated, setIsTruncated] = useState<boolean | undefined>(undefined);

    // Both hooks could be combined, but it would make it less easy to understand
    // We have to force a render with isTruncated === undefined in order to do our measuring, so we setIsTruncated(undefined) in the first useLayoutEffect,
    // then after the render tick caused by the setState, we perform our measuring logic in the second useLayoutEffect.
    useLayoutEffect(() => {
        if (!disable) {
            setIsTruncated(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disable, ...deps]);

    useLayoutEffect(() => {
        if (isTruncated !== undefined) {
            // Truncation already set. Nothing to measure
            return;
        }

        const parentBounds = parentRef.current?.getBoundingClientRect();
        const optionBounds = childRef.current?.getBoundingClientRect();

        const newIsTruncated = (parentBounds?.[dimension] ?? 0) + parentDimensionMargin !== optionBounds?.[dimension];

        setIsTruncated(newIsTruncated);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTruncated, ...deps]);

    return {
        isTruncated,
        parentRef,
        childRef,
    };
};
