import { assertNever } from 'office-ui-fabric-react';

import { APP_CONSTANTS, APP_STRINGS } from '../../../../res';
import { err, ok, Result } from '../../../../common';
import type { IParameterConfig, Parameter } from '../../../parameter';
import { rtdNullValue, tagScalar, RtdValue, tagArray, TRtdType, TRtdValue, ValueImpl } from '../../../value';

import { BasicParamValue } from './value';
import { BasicParam } from './types';

const localStrings = APP_STRINGS.domain.parameter;

export class BasicParamConfig<T, K extends string> implements IParameterConfig {
    readonly kind = 'basic' as const;
    readonly dataType: K;
    readonly defaultValue: BasicParamValue<T, K>;
    readonly variableNames: [string];

    constructor(
        readonly id: string,
        readonly displayName: string,
        /**
         * Union options
         */
        readonly options: BasicParam.Options<T, K>,
        readonly impl: ValueImpl<T, K>
    ) {
        this.dataType = impl.dataType;
        this.defaultValue = new BasicParamValue(this, options.defaultValue);
        this.variableNames = [options.variableName];
    }

    canApply(...types: TRtdType[]) {
        return types.every((t) => {
            if (t[0] === 'null') {
                return this.options.selectionKind !== 'scalar';
            }
            if (t[0] === 'array') {
                return this.options.selectionKind === 'array-null' && this.dataType === t[1];
            }

            return t[0] === this.dataType;
        });
    }

    parseUrlStrings(serialized: Parameter.UrlSerialized): undefined | Result<BasicParamValue<T, K>> {
        const values = serialized[this.options.variableName];

        let selected: RtdValue.Value<T, K>;

        if (values.length === 0) {
            return undefined;
        }

        if (values[0] === APP_CONSTANTS.parameter.allSelection) {
            if (this.options.selectionKind === 'scalar') {
                return err(localStrings.errors.notNullable);
            }
            selected = rtdNullValue;
        } else if (this.options.selectionKind !== 'array-null') {
            const maybeValue = this.impl.urlStringToValue(values[0]);
            if (maybeValue.kind === 'err') {
                return maybeValue;
            }
            selected = tagScalar(this.dataType, maybeValue.value);
        } else {
            const parsedValues: T[] = [];

            for (const value of values) {
                const maybeValue = this.impl.urlStringToValue(value);
                if (maybeValue.kind === 'err') {
                    return maybeValue;
                }
                parsedValues.push(maybeValue.value);
            }

            selected = tagArray(this.dataType, parsedValues);
        }

        return ok(new BasicParamValue(this, selected));
    }

    consumedVariableNames(): Set<string> {
        if (this.options.selectionKind !== 'freetext' && this.options.dataSource.kind === 'query') {
            return this.options.dataSource.consumedVariables;
        }
        return new Set();
    }

    tryCreateParamValue(value: TRtdValue): Result<BasicParamValue<T, K>> {
        if (value.kind === 'null') {
            if (this.options.selectionKind === 'scalar') {
                return err(localStrings.errors.notNullable);
            }
            return ok(new BasicParamValue(this, value));
        }
        const maybeNarrowed = this.impl.tryNarrowValue(value);
        if (maybeNarrowed === undefined) {
            return err(
                `${localStrings.errors.basic.unexpectedType} "${APP_STRINGS.domain.value.dataTypes[value.tag]}"`
            );
        }

        let newValue: RtdValue.Value<T, K>;

        switch (this.options.selectionKind) {
            case 'freetext':
            case 'scalar':
            case 'scalar-null':
                if (maybeNarrowed.kind === 'array') {
                    return err(localStrings.errors.singleSelectionOnly);
                }
                newValue = maybeNarrowed;
                break;
            case 'array-null':
                if (maybeNarrowed.kind === 'scalar') {
                    newValue = tagArray(maybeNarrowed.tag, [maybeNarrowed.value]);
                }
                newValue = maybeNarrowed;
                break;
            default:
                assertNever(this.options);
        }

        return ok(new BasicParamValue(this, newValue));
    }
}
