import { parse } from 'url';
import { FeatureFlagMap, mergeFeatureFlags } from './FeatureFlags';
import {
    AuthenticationProvider,
    AdalAuthenticationProvider,
    MsalAuthenticationProvider,
    MsalAuthenticationProviderV2,
} from './OpenSource/AuthenticationProviders';
import { User } from '@kusto/common';
import { QueryResultPersistentStore, QueryResultStore } from './stores/QueryResultPersistentStore';
import { DeepLinkProperties, parseDeepLink } from './utils/deepLinkParser';
import { IFrameAuthenticationProvider } from './utils/IFrameAuthenticationProvider';
import { KustoClient } from './utils/kustoClient';
import { DefaultLocalizationStrings, LocalizedStrings } from './utils/localizationHelper';
import { KWE_CONSTANTS } from './common/constants';

/**
 * A place to store global singleton dependencies such as authentication provider and kusto client.
 */
const EnvFeatureFlag: FeatureFlagMap = process.env.REACT_APP_FEATURE_FLAGS
    ? JSON.parse(process.env.REACT_APP_FEATURE_FLAGS)
    : {};
export class Dependencies {
    /**
     * Merge a list of feature flags from feature flags set in key 'kwe.feature.flag.overrides' in local storage.
     * Only for development purposes.
     *
     * @param featureFlags A list of feature flags to merge with
     */
    private static mergeFeatureFlagsFromLocalStorage(featureFlags: FeatureFlagMap) {
        try {
            const localStorageOverrides = localStorage.getItem('kwe.feature.flag.overrides');
            console.log(`found feature flag override in local storage - ${localStorageOverrides}`);
            if (localStorageOverrides) {
                const featureFlagOverrides = JSON.parse(localStorageOverrides);
                featureFlags = mergeFeatureFlags(featureFlags, featureFlagOverrides);
            }
        } catch (ex) {
            console.error(ex);
        }
        return featureFlags;
    }

    static createIndependentWebsiteDependencies(deepLinkProps: DeepLinkProperties): Dependencies {
        const defaultFeatureFlags: FeatureFlagMap = {
            ShowConnectionButtons: true,
            ShowShareMenu: true,
            ShowFileMenu: true,
            ShowPersona: true,
            ShowToS: true,
            IFrameAuth: false,
            ShowOpenNewWindowButton: false,
            ShowSmoothIngestion: true,
            ShowPageHeader: true,
            UseMeControl: true,
            EnableDashboards: true,
            QueryResultsSearch: true,
            Msal: true,
            SyncBrowserTabs: true,
            EnableEventHubSource: true,
            HighlightUrlColumns: true,
            EnableCreateExternalTable: true,
            Timezone: true,
            HideDashboardsOnly: false,
            BrowseSource: true,
            UndoLatestClosedTabs: true,
            UseIFrameSharing: false,
            Msalv2: false,
            Msalv2Logs: false,
            ...EnvFeatureFlag,
        };

        let featureFlags = mergeFeatureFlags(defaultFeatureFlags, deepLinkProps.featureFlags);
        featureFlags = Dependencies.mergeFeatureFlagsFromLocalStorage(featureFlags);

        const redirectUri = `${window.location.protocol}//${window.location.host}`;

        let auth: AuthenticationProvider;
        if (featureFlags.IFrameAuth) {
            auth = new IFrameAuthenticationProvider();
        } else if (!featureFlags.Msal && !featureFlags.Msalv2) {
            if (!process.env.REACT_APP_AAD_CLIENT) {
                throw Error('AAD Client is missing');
            }
            auth = new AdalAuthenticationProvider(redirectUri, process.env.REACT_APP_AAD_CLIENT);
        } else {
            if (!process.env.REACT_APP_AAD_CLIENT) {
                throw Error('AAD Client is missing');
            }
            if (featureFlags.Msalv2) {
                auth = new MsalAuthenticationProviderV2(
                    redirectUri,
                    process.env.REACT_APP_AAD_CLIENT,
                    featureFlags.Msalv2Logs
                );
            } else {
                auth = new MsalAuthenticationProvider(redirectUri, process.env.REACT_APP_AAD_CLIENT);
            }
        }
        const client = new KustoClient(auth);
        const localStorageKey = deepLinkProps.storeName || 'rootStore';
        const queryResultStore = new QueryResultPersistentStore(localStorageKey);

        // Override feature flag values for Microsoft tenant (but only if explicit feature flag was not set in url query param)
        const setUserSpecificDependencies = (user: User) => {
            // Example of user specific dependency for setting a feature flag for internal msft users.
            //  !Note! - this will override environment-based flags!)
            // const microsoftTenant = '72f988bf-86f1-41af-91ab-2d7cd011db47';
            //
            // if (
            //     user.profile?.tid === microsoftTenant &&
            //     typeof dependencies.deepLinkProperties.featureFlags.EnableDashboards === 'undefined'
            // ) {
            //     dependencies.featureFlags.EnableDashboards = true;
            // }

            if (user.profile?.tid) {
                dependencies.featureFlags = {
                    ...dependencies.featureFlags,
                    ...KWE_CONSTANTS.tenantFeatureFlags[user.profile.tid],
                    ...KWE_CONSTANTS.domainFeatureFlags[window.location.host],
                };
            }
        };

        const deps = new Dependencies(
            auth,
            client,
            localStorageKey,
            deepLinkProps,
            queryResultStore,
            DefaultLocalizationStrings,
            featureFlags,
            deepLinkProps.theme,
            setUserSpecificDependencies
        );
        return deps;
    }

    static createIbizaDependencies(deepLinkProps: DeepLinkProperties): Dependencies {
        const auth = new IFrameAuthenticationProvider();
        const client = new KustoClient(auth);
        const localStorageKey = `ibizaRootStore_${deepLinkProps.clusterName || ''}`;
        const queryResultStore = new QueryResultPersistentStore(localStorageKey);
        const defaultFeatureFlags: FeatureFlagMap = {
            ShowOpenNewWindowButton: true,
            ShowShareMenu: true,
            ShowFileMenu: true,
            PersistAfterEachRun: true,
            IFrameAuth: true,
            ShowPageHeader: false,
            // Due to issues with focus on monaco empty (new) tab in Firefox & chrome from ibiza portal
            //
            // Monaco set the focus once and follow up clicks (in the editor) are ignored until blur event (keeping the state internally)
            // due to some race condition between monaco and Ibiza portal focus handling
            // the document active element is not monaco (although editor.focus() was called ).
            SkipMonacoFocusOnInit: true,
            EnableDashboards: false,
            QueryResultsSearch: true,
            SyncBrowserTabs: false,
            HighlightUrlColumns: false,
            Timezone: false,
            HideDashboardsOnly: false,
            UndoLatestClosedTabs: true,
            UseIFrameSharing: false,
            Msalv2: false,
            Msalv2Logs: false,
            ...EnvFeatureFlag,
        };

        let featureFlags = mergeFeatureFlags(defaultFeatureFlags, deepLinkProps.featureFlags);
        featureFlags = Dependencies.mergeFeatureFlagsFromLocalStorage(featureFlags);

        const deps = new Dependencies(
            auth,
            client,
            localStorageKey,
            deepLinkProps,
            queryResultStore,
            DefaultLocalizationStrings,
            featureFlags,
            deepLinkProps.theme
        );
        return deps;
    }

    static getDeepLinkParameters(): DeepLinkProperties {
        return parseDeepLink(parse(window.location.href, true));
    }

    constructor(
        public authProvider: AuthenticationProvider,
        public kustoClient: KustoClient,
        public localStorageKey: string,
        public deepLinkProperties: DeepLinkProperties,
        public queryResultStore: QueryResultStore,
        public strings: LocalizedStrings,
        public featureFlags: FeatureFlagMap,
        public theme?: string,
        public setUserSpecificDependencies: (user: User) => void = () => {}
    ) {}
}

export const initDependencies = (isIbizaPortal: boolean): Dependencies => {
    const deepLinkParameters = Dependencies.getDeepLinkParameters();
    dependencies = isIbizaPortal
        ? Dependencies.createIbizaDependencies(deepLinkParameters)
        : Dependencies.createIndependentWebsiteDependencies(deepLinkParameters);

    return dependencies;
};

export let dependencies: Dependencies = {} as Dependencies;
