import { observer } from 'mobx-react-lite';
import React from 'react';
import { DirectionalHint, ICalloutProps, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import classNames from 'classnames';

import { RTDCallout } from '../../fabric';
import { IParameterSelections, useDashboardStore } from '../../../store';

import styles from './TemporalErrors.module.scss';
import selectorListStyles from './ParameterSelectorList.module.scss';

const ErrorList: React.FC<{ errors: readonly React.ReactNode[] }> = ({ errors }) => (
    <>
        {errors.map((e, i) => (
            <MessageBar messageBarType={MessageBarType.error} key={i}>
                {e}
            </MessageBar>
        ))}
    </>
);

const ParameterPillCallout: React.FC<ICalloutProps> = ({ className, ...props }) => {
    return (
        <RTDCallout {...props} className={classNames(styles.callout, className)} hidden={false} calloutMaxWidth={480} />
    );
};

export interface TemporalErrorsProps {
    firstRowParameterIds: Set<string>;
    isOpen: boolean;
    selectedParameters: IParameterSelections;
}

export const TemporalErrors = observer<TemporalErrorsProps>(function CTemporalErrors({
    firstRowParameterIds,
    isOpen,
    selectedParameters,
}) {
    const dashboardStore = useDashboardStore();

    const visiblePillErrors: Array<[parameterId: string, errors: React.ReactNode[]]> = [];
    const hiddenPillErrors: Array<[parameterId: string, errors: React.ReactNode[]]> = [];

    for (const errors of selectedParameters.paramErrors.errors) {
        if (!isOpen && !firstRowParameterIds.has(errors[0])) {
            hiddenPillErrors.push(errors);
        } else {
            visiblePillErrors.push(errors);
        }
    }

    const generalErrors = selectedParameters.generalErrors.errors;

    return (
        <>
            {visiblePillErrors.map(([id, errors]) => (
                <ParameterPillCallout key={id} target={`.${selectorListStyles.itemWrapper}[data-parameter_id="${id}"]`}>
                    <ErrorList errors={errors} />
                </ParameterPillCallout>
            ))}

            {hiddenPillErrors.length !== 0 && (
                <ParameterPillCallout
                    className={styles.hiddenPillErrors}
                    target={`.${selectorListStyles.expandButton}`}
                >
                    {hiddenPillErrors.map(([id, errors]) => (
                        <>
                            <h4>{dashboardStore.state?.parametersRecord[id]?.displayName ?? id}:</h4>
                            <ErrorList key={id} errors={errors} />
                        </>
                    ))}
                </ParameterPillCallout>
            )}

            {generalErrors.length !== 0 && (
                <ParameterPillCallout
                    gapSpace={4}
                    directionalHint={DirectionalHint.bottomCenter}
                    target={`.${selectorListStyles.selectorList}`}
                >
                    <ErrorList errors={generalErrors} />
                </ParameterPillCallout>
            )}
        </>
    );
});
