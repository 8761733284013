import { useCallback, useState } from 'react';
import { Observer } from 'rxjs';

import { parseException } from '../../../common';

import { StreamResult, streamResultNotRun } from '../streamResult';

export interface UseStreamingObserverReturnValue<T> {
    /**
     * The current output from the observable
     */
    result: StreamResult<T>;

    /**
     * A creator for the observer of an observable that emits `StreamResult`s
     */
    observer: (subscriptionStartTime: Date) => Observer<StreamResult<T>>;

    /**
     * Escape hatch to set current output value (primarily for errors)
     */
    setState: (state: StreamResult<T>) => void;
}

/**
 * Translates from a `StreamResult` observable to React state
 */
export const useStreamingObserver = <T>(): UseStreamingObserverReturnValue<T> => {
    const [state, setState] = useState<StreamResult<T>>(streamResultNotRun);

    const observer = useCallback(
        (subscriptionStartTime: Date): Observer<StreamResult<T>> => ({
            next: (result) => setState(result),
            error: (err) => {
                const receivedTime = new Date();
                setState({
                    kind: 'err',
                    didRun: true,
                    reason: { title: parseException(err) },
                    receivedTime,
                    status: {
                        kind: 'done',
                        executionId: undefined,
                        startTime: subscriptionStartTime,
                        endTime: receivedTime,
                    },
                });
            },
            complete: () => {
                const completionTime = new Date();
                setState((lastStatus) => ({
                    ...lastStatus,
                    status: {
                        executionId: undefined,
                        ...lastStatus.status,
                        kind: 'done',
                        startTime: subscriptionStartTime,
                        endTime: completionTime,
                    },
                }));
            },
        }),
        []
    );

    return { result: state, observer, setState };
};
