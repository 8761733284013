import { makeObservable, action, computed, observable, reaction, values } from 'mobx';

import {
    IDashboardDocument,
    AutoRefreshUpdateInterval,
    UDataSource,
    IPage,
    AutoRefreshConfig,
} from '../../core/domain';
import { RGLLayout, updateLayoutWithTiles } from '../../domain/layout';
import { VisualConfig } from '../../domain/visualConfig';
import { isTileDisplayType, ITile } from '../../domain/tile';
import { ReadonlyRecord } from '../../common';
import { ParameterConfig } from '../../domain';
import { InitialParameters, IParameterSelections, ParameterSelections } from '../ParameterSelections';
import { DashboardsServerMetaData } from '../../migration';
import { APP_CONSTANTS } from '../../res';

import { PagesNavState } from './PagesNav';
import { DashboardChanges, ItemType, ItemTypeMap } from './DashboardChanges';
import { diffAppliedRecord } from './diffAppliedRecord';

const metaDefault: DashboardsServerMetaData = {
    id: APP_CONSTANTS.dashboardPage.newDashboardId,
    eTag: '',
    isDashboardEditor: true,
};

export type DashboardLayout = Record<string, RGLLayout[]>;

export class DashboardLoaded {
    /**
     * Stored separately from tiles to more closely match the "onChange" data
     * type RGL uses. Should be readonly, but RGL doesn't like that type.
     */
    public tilesLayout: DashboardLayout = {};
    public readonly selectedParameters: IParameterSelections;
    public currentAutoRefreshRate: AutoRefreshUpdateInterval | undefined;
    /**
     * undefined when not editing
     */
    public changes: DashboardChanges | undefined = undefined;
    public readonly pagesNav: PagesNavState;
    /**
     * This is used to indicate which tile
     * is currently rendering an
     * edit title form.
     */
    public activeTileRename:
        | {
              tileId: string;
              /**
               * Used to re-focus the tile menu button after
               * the edit tile title form is closed
               */
              onFormClosed: () => void;
          }
        | undefined;

    /**
     * undefined or dispose in-progress save
     */
    public disposeCurrentSave: undefined | (() => void);

    public readonly tilesRecord: ReadonlyRecord<string, ITile>;
    public readonly dataSourcesRecord: ReadonlyRecord<string, UDataSource>;
    public readonly parametersRecord: ReadonlyRecord<string, ParameterConfig>;
    public readonly pagesRecord: ReadonlyRecord<string, IPage>;

    private readonly disposeLayoutObserver: () => void;

    constructor(
        public visualConfig: VisualConfig,
        /**
         * Source dashboard. Does not include changes.
         */
        public document: IDashboardDocument,
        public migrationWarnings: string[] = [],
        initialParameterSelections?: InitialParameters
    ) {
        this.currentAutoRefreshRate = document.autoRefresh?.defaultInterval;

        makeObservable(this, {
            visualConfig: observable.ref,
            document: observable.ref,
            currentAutoRefreshRate: observable,
            changes: observable.ref,
            activeTileRename: observable.ref,
            disposeCurrentSave: observable,
            tilesLayout: observable.shallow,
            startEditing: action,
            stopEditing: action,
            addItem: action,
            deleteItem: action,
            changePageLayout: action,
            autoRefresh: computed,
            isNew: computed,
            tiles: computed,
            dataSources: computed,
            parameters: computed,
            pages: computed,
            dependentTileRecords: computed,
            pinnedParameters: computed,
            variableToParameterId: computed,
            variableToDependentParameterIds: computed,
        });

        this.tilesRecord = diffAppliedRecord(
            () => this.document.tiles,
            () => this.changes?.diffMaps.tiles
        );
        this.dataSourcesRecord = diffAppliedRecord(
            () => this.document.dataSources,
            () => this.changes?.diffMaps.dataSources
        );
        this.parametersRecord = diffAppliedRecord(
            () => this.document.parameters,
            () => this.changes?.diffMaps.parameters
        );
        this.pagesRecord = diffAppliedRecord(
            () => this.document.pages,
            () => this.changes?.diffMaps.pages
        );

        this.pagesNav = new PagesNavState(this);
        this.selectedParameters = new ParameterSelections(this, initialParameterSelections);

        // TODO: Make incremental
        this.disposeLayoutObserver = reaction(
            () => {
                // Reading properties so we'll get a reaction only when, and always when,
                // these changes
                void this.visualConfig;
                void this.tiles;
                void this.pages;
                void this.changes;

                // Returning a new object so the reaction always triggers
                return {};
            },
            () => {
                this.tilesLayout = updateLayoutWithTiles(
                    this.visualConfig,
                    this.tiles,
                    this.pages,
                    this.changes ? this.tilesLayout : undefined
                );
            },
            { fireImmediately: true }
        );
    }

    dispose() {
        this.selectedParameters.dispose();
        this.pagesNav.dispose();
        this.disposeLayoutObserver();
        this.disposeCurrentSave?.();
    }

    startEditing(): DashboardChanges {
        if (this.changes === undefined) {
            this.changes = new DashboardChanges(this.document);
        }
        return this.changes;
    }

    stopEditing() {
        this.changes = undefined;
    }

    addItem<T extends ItemType>(kind: T, item: ItemTypeMap[T]) {
        if (this.changes === undefined) {
            return;
        }
        this.changes.addItem(kind, item);
    }

    deleteItem<T extends ItemType>(kind: T, id: string) {
        if (this.changes === undefined) {
            return;
        }
        this.changes.deleteItem(kind, id);
    }

    changePageLayout(pageId: string, layout: RGLLayout[]) {
        if (this.changes) {
            this.tilesLayout[pageId] = layout;
        }
    }

    get meta(): DashboardsServerMetaData {
        return this.document.meta ?? metaDefault;
    }

    get autoRefresh(): undefined | AutoRefreshConfig {
        return this.changes?.autoRefresh ?? this.document.autoRefresh;
    }

    get isNew() {
        return this.document.meta === undefined;
    }

    get dataSources() {
        return values(this.dataSourcesRecord);
    }

    get parameters() {
        return values(this.parametersRecord);
    }

    get tiles() {
        return values(this.tilesRecord);
    }

    get pages() {
        return values(this.pagesRecord);
    }

    get pinnedParameters(): Record<string, ParameterConfig> {
        const changes = this.changes;

        let ids: string[];

        if (changes) {
            ids = [...changes.pinnedParameters].filter((id) => !changes.diffMaps.parameters.delete.has(id));
        } else if (this.document) {
            ids = [...this.document.pinnedParameters];
        } else {
            throw new Error();
        }

        const parameters: Record<string, ParameterConfig> = {};
        for (const id of ids) {
            const parameter = this.parametersRecord[id];
            if (parameter) {
                parameters[parameter.id] = parameter;
            }
        }

        return parameters;
    }

    get title() {
        return this.changes?.title ?? this.document.title;
    }

    /**
     * A map from parameter variableName to the id of the parameter that owns that
     * name
     */
    get variableToParameterId(): Record<string, undefined | string> {
        const variableMap: Record<string, string> = {};

        for (const parameter of this.parameters) {
            for (const variableName of parameter.variableNames) {
                variableMap[variableName] = parameter.id;
            }
        }

        return variableMap;
    }

    get variableToDependentParameterIds(): Record<string, undefined | string[]> {
        const result: Record<string, undefined | string[]> = {};

        for (const param of this.parameters) {
            if (
                param.kind === 'duration' ||
                param.options.selectionKind === 'freetext' ||
                param.options.dataSource.kind === 'static'
            ) {
                continue;
            }
            for (const name of param.options.dataSource.consumedVariables) {
                const ids = result[name];
                if (ids) {
                    ids.push(param.id);
                } else {
                    result[name] = [param.id];
                }
            }
        }
        return result;
    }

    get dependentTileRecords(): {
        /**
         * variables to dependent tile id's
         */
        readonly variables: ReadonlyRecord<string, ReadonlySet<string>>;
        /**
         * data source id to dependent tile id's
         */
        readonly dataSources: ReadonlyRecord<string, ReadonlySet<string>>;
    } {
        const variables: Record<string, Set<string>> = {};
        const dataSources: Record<string, Set<string>> = {};

        function assertAddId(record: Record<string, Set<string>>, key: string, id: string) {
            let set = record[key];

            if (!set) {
                set = new Set();
                record[key] = set;
            }

            set.add(id);
        }

        for (const tile of this.tiles) {
            if (isTileDisplayType(tile)) {
                continue;
            }

            if (tile.dataSourceId) {
                assertAddId(dataSources, tile.dataSourceId, tile.id);
            }

            for (const variableName of tile.usedVariables) {
                assertAddId(variables, variableName, tile.id);
            }
        }

        return { variables, dataSources };
    }
}
