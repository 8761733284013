import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './Markdown.module.scss';

export interface MarkdownProps extends React.HTMLAttributes<HTMLDivElement> {
    markdownText: string;
}

const InnerMarkdown: React.FC<MarkdownProps> = ({ markdownText, className, ...divProps }) => (
    <div
        // Tab index required because this div may have a scroll bar. See link below.
        // https://www.chromestatus.com/feature/5231964663578624
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        {...divProps}
        className={className ? `${styles.markdown} ${className}` : styles.markdown}
    >
        <ReactMarkdown source={markdownText} />
    </div>
);

export const Markdown = React.memo(InnerMarkdown);
