import * as React from 'react';
import { useCallback } from 'react';
import { IconButton } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { useDashboardStore } from '../../../store/dashboard';
import { IDashboardListItem } from '../../../core/domain';
import { APP_STRINGS } from '../../../res';
import { useCore } from '../../../core';

import { styles } from './RowActionButtonStyle';

interface IFavoriteCellProps {
    dashboardItem: IDashboardListItem;
}

export const FavoriteCell: React.FC<IFavoriteCellProps> = observer(function FavoriteCell({ dashboardItem }) {
    const core = useCore();
    const store = useDashboardStore();

    const { favoriteDashboards } = core.userSettingsState.userSettings;
    const { id } = dashboardItem;

    const onFavorite = useCallback(() => {
        core.userSettingsState.toggleDashboardFavoriteStatus(id, store);
    }, [id, store, core]);

    const isFavorite = favoriteDashboards.has(dashboardItem.id);

    const props = isFavorite
        ? {
              title: APP_STRINGS.catalogPage.favoriteDashboardButtonTitle.unFavorite,
              iconProps: { iconName: 'FavoriteStarFill' },
          }
        : {
              title: APP_STRINGS.catalogPage.favoriteDashboardButtonTitle.favorite,
              iconProps: { iconName: 'FavoriteStar' },
          };

    return <IconButton styles={styles} onClick={onFavorite} {...props} />;
});
