import { observer } from 'mobx-react-lite';
import { ISelectableOption } from 'office-ui-fabric-react';
import React from 'react';

import { RTDDropdown } from '../../../../../components';
import { CrossFilterConfig, ParameterConfig, useVisualConfig } from '../../../../../domain';
import type { VisualDimension } from '../../../../../domain/visualConfig';
import { useDashboardStore } from '../../../../../store';
import { useETPDispatch, useETPSelectors } from '../../../../../store/editTile';
import { visualAddedSelectorBuilder, visualOptionsSelectorBuilder } from '../../../lib';
import { ETPVisualOptions } from '../../../types';

import { useSchema } from '../../QueryHandler/SchemaProvider';
import { ManagedConfigComponent } from '../types';

export interface CrossFilterProps {
    value: Partial<CrossFilterConfig>;
    onChange(value: Partial<CrossFilterConfig>): void;
    getValue(): undefined | Partial<CrossFilterConfig>;
    disabled: boolean;
    dimensions: VisualDimension[];
    parameters: readonly ParameterConfig[];
}

export const CrossFilter: React.FC<CrossFilterProps> = ({
    value,
    onChange,
    getValue,
    disabled,
    dimensions,
    parameters,
}) => {
    const dimensionOptions = React.useMemo(() => {
        let dimensionsFiltered = dimensions;
        const parameter = value.parameterId && parameters.find((p) => p.id === value.parameterId);

        if (parameter) {
            dimensionsFiltered = dimensionsFiltered.filter((d) => parameter.canApply(...d.dataTypes));
        }

        return dimensionsFiltered.map((d) => ({
            key: d.id,
            text: d.displayName,
        }));
    }, [dimensions, parameters, value.parameterId]);

    const parameterOptions = React.useMemo(() => {
        if (value.dimensionId === undefined) {
            return [];
        }

        const dimension = dimensions.find((d) => d.id === value.dimensionId);

        if (dimension === undefined) {
            return [];
        }

        return parameters
            .filter((p) => p.canApply(...dimension.dataTypes))
            .map((p) => ({ key: p.id, text: p.displayName }));
    }, [value.dimensionId, dimensions, parameters]);

    const { onDimensionChange, onParameterChange } = React.useMemo(
        () => ({
            // If we can find a parameter update the global value, otherwise set the dirty state
            onDimensionChange(_event: unknown, option?: ISelectableOption) {
                if (option) {
                    const dimensionId = option.key as string;
                    const parameterId = getValue()?.parameterId;
                    onChange({ dimensionId, parameterId });
                }
            },
            // If we can find a dimension update the global value and clear the dirty state
            onParameterChange(_event: unknown, option?: ISelectableOption) {
                if (option) {
                    const parameterId = option.key as string;
                    const dimensionId = getValue()?.dimensionId;
                    onChange({ dimensionId, parameterId });
                }
            },
        }),
        [getValue, onChange]
    );

    return (
        <>
            <RTDDropdown
                label="Dimension"
                disabled={disabled}
                selectedKey={value.dimensionId ?? null}
                options={dimensionOptions}
                onChange={onDimensionChange}
            />
            <RTDDropdown
                label="Parameter"
                disabled={disabled}
                selectedKey={value.parameterId ?? null}
                options={parameterOptions}
                onChange={onParameterChange}
            />
        </>
    );
};

const selectors = {
    visualType: visualAddedSelectorBuilder((o) => o.visualType),
    visualOptions: visualOptionsSelectorBuilder((o) => o),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emptyArr = [] as any[];

export const CrossFilterManagedConfig: ManagedConfigComponent = observer((props) => {
    const [dispatch, getState] = useETPDispatch();
    const {
        visualType,
        visualOptions,
    }: {
        visualType: string;
        visualOptions: ETPVisualOptions;
    } = useETPSelectors(selectors);
    const schema = useSchema();
    const parameters = useDashboardStore().state?.parameters;
    const visualConfig = useVisualConfig();

    const { onChange, getValue } = React.useMemo(
        () => ({
            onChange(crossFilter: CrossFilterConfig) {
                dispatch({ type: 'updateVisualOptions', options: { crossFilter } });
            },
            getValue() {
                const state = getState();
                if (state.type !== 'query') {
                    return undefined;
                }
                return state.visual?.options.crossFilter;
            },
        }),
        [dispatch, getState]
    );

    const crossFilterDimensions = visualConfig.visualTypes[visualType].crossFilterDimensions;
    const dimensions = React.useMemo(() => {
        if (crossFilterDimensions === undefined) {
            // eslint-disable-next-line no-console
            console.error('Cross filter selector must be present if `crossFilter` visual option is enabled');
            return undefined;
        }

        if (typeof crossFilterDimensions !== 'function') {
            return crossFilterDimensions;
        }

        if (schema.kind !== 'available') {
            return undefined;
        }

        return crossFilterDimensions(
            () => visualOptions,
            () => schema.schema
        );
    }, [crossFilterDimensions, schema, visualOptions]);

    return (
        <CrossFilter
            value={visualOptions.crossFilter}
            onChange={onChange}
            getValue={getValue}
            disabled={props.disabled || dimensions === undefined}
            dimensions={dimensions || emptyArr}
            parameters={parameters ?? emptyArr}
        />
    );
});
