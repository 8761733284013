export const identity = <T>(arg: T): T => arg;

/**
 * For use with `filter` so type casts are not needed.
 */
export function notUndefined<T>(item: T): item is Exclude<T, undefined> {
    return item !== undefined;
}

/**
 * AssertNever is a utility function that can be used for exhaustiveness checks
 * in switch statements.
 *
 * Use this instead of the `office-ui-fabric` version so code dependent on it is
 * usable in node.
 */
export function assertNever(x: never): never {
    throw new Error(`Unexpected value: ${x}`);
}
