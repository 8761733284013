import * as React from 'react';
import { Text } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../res';

import { useHeaderTag } from '../../RtdHeader';
import { StreamResultNotRun, StreamResultCannotRunError } from '../../../domain';

import { QueryNotRunVisualIcon } from './QueryNotRunVisualIcon';

import styles from './QueryNotRunVisual.module.scss';

export interface QueryNotRunVisualProps {
    queryStatus?: StreamResultNotRun | StreamResultCannotRunError;
    className?: string;
}

export const QueryNotRunVisual: React.FC<QueryNotRunVisualProps> = ({ queryStatus, className }) => {
    const reason =
        queryStatus && queryStatus.kind === 'err' ? queryStatus.reason : APP_STRINGS.editTilePage.noQueryMessage;
    const tag = useHeaderTag(2);

    return (
        <div className={className ? `${styles.noData} ${className}` : styles.noData}>
            <QueryNotRunVisualIcon />
            <Text className={styles.title} variant="mediumPlus" as={tag}>
                {reason.title}
            </Text>
            {reason.body && (
                <Text className={styles.message} variant="medium">
                    {reason.body}
                </Text>
            )}
        </div>
    );
};
