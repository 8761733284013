import { Function } from '../../../common/types';
import { RowDataTypeBase } from './RowDataTypeBase';
import { iconMapper } from './IconMapper';
import { getActions } from './ActionMapper';
import { Database } from '../../../stores/cluster';
import { getFromCacheOrCreate } from './RowDataTypeCache';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';

export class FunctionRowDataType extends RowDataTypeBase {
    // eslint-disable-next-line @typescript-eslint/ban-types
    private constructor(func: Function, database: Database) {
        const folderPath = func.folder ? func.folder.split(/[\\/]/g) : [];
        super(
            func.id,
            [database.cluster.getAlias(), database.name, 'Functions', ...folderPath, func.name],
            func.entityType,
            database,
            iconMapper[func.entityType]
        );

        this.info = func.docstring;
        this.actions = getActions(this.entityType, false, this.info);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static fromCacheOrCreate(func: Function, database: Database): RowDataType {
        return getFromCacheOrCreate(database, func.id, () => new FunctionRowDataType(func, database));
    }
}
