import type { KustoQueryResult, QueryHash, QuerySourceId } from '../../domain';
import type { IQueryCache } from '../types';

import { DashboardsIndexedDB, CachedResultItem } from './types';
import { IndexedDBMemCache } from './IndexedDBMemCache';
import { maxQueriesCachedPerSource, cachedQueryMaxAge } from './constants';

export class PersistentQueryCache implements IQueryCache {
    private readonly memCache: IndexedDBMemCache<'QueryResultCache'>;

    constructor(private readonly db: DashboardsIndexedDB) {
        this.memCache = new IndexedDBMemCache('QueryResultCache', db);
    }

    async getCachedData(hash: QueryHash): Promise<KustoQueryResult | undefined> {
        const cacheEntry = await this.memCache.get(hash);
        if (!cacheEntry) {
            return undefined;
        }

        this.memCache.set(hash, { ...cacheEntry, lastAccessed: Date.now() });

        return cacheEntry.data;
    }

    saveCachedData(sourceId: QuerySourceId, hash: QueryHash, data: KustoQueryResult): void {
        const cacheEntry: CachedResultItem = {
            hash,
            sourceId,
            data,
            lastAccessed: Date.now(),
        };
        this.memCache.set(hash, cacheEntry);
        this.clearExtraCacheEntriesBySourceId(sourceId);
    }

    /**
     * Clears cache entries by number of entries for a given source id
     */
    private async clearExtraCacheEntriesBySourceId(sourceId: QuerySourceId) {
        if (this.db === undefined) {
            return;
        }
        // Not using a cursor because we need to delete based on 2 properties, and
        // as far as I can tell we can't filter by one index and sort by another.
        // Shouldn't be a problem because getting everything by source id shouldn't
        // return more than 6 results.
        const entries = await (await this.db.db)?.getAllFromIndex('QueryResultCache', 'sourceId', sourceId);

        if (entries === undefined) {
            return;
        }

        if (entries.length <= maxQueriesCachedPerSource) {
            return;
        }

        const extraEntries = entries.sort((a, b) => b.lastAccessed - a.lastAccessed).slice(5);

        for (const entry of extraEntries) {
            // Intentionally _not_ waiting for promise to resolve
            (await this.db.db)?.delete('QueryResultCache', entry.hash);
        }
    }

    /**
     * Deletes cached query data older than "cachedQueryMaxAge".
     */
    public async clearOldQueries(): Promise<void> {
        if (this.db === undefined) {
            return;
        }

        const minimumLastAccessed = Date.now() - cachedQueryMaxAge;
        let cursor = await (await this.db.db)
            ?.transaction('QueryResultCache')
            .store.index('lastAccessed')
            .openCursor(IDBKeyRange.upperBound(minimumLastAccessed));

        while (cursor) {
            // Intentionally _not_ waiting for promise to resolve
            (await this.db.db)?.delete('QueryResultCache', cursor.primaryKey);
            cursor = await cursor.continue();
        }
    }
}
