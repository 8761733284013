import { useMemo } from 'react';
import { action } from 'mobx';

import { useCore } from '../../core/react/useCore';
import type { ITelemetryService } from '../../core/backend';
import { useDashboardStore } from '../../store/dashboard/context';
import type { DashboardStore } from '../../store';

export type CreateTelemetry<T> = (telemetry: T, additionalProperties?: Record<string, unknown> | undefined) => void;

/**
 * Builds the shared telemetry properties used by the telemetry hooks
 */
const getTelemetrySharedProperties = (component: string | undefined, store: DashboardStore) => {
    const currentDashboard = store.state?.meta.id;
    return component
        ? {
              // Styled after the defaults from the App Insights React plugin
              'Component Name': component,
              dashboardId: currentDashboard,
          }
        : {
              dashboardId: currentDashboard,
          };
};

/**
 * Creates and returns a function for tracking events from within React
 * @param component The name of the component to be associated with this event
 */
export const useTelemetry = (component?: string): CreateTelemetry<string> => {
    const core = useCore();
    const store = useDashboardStore();

    return useMemo(() => createTelemetry(component, core.telemetryService, store), [component, core, store]);
};

/**
 * Creates and returns a function for tracking events from within React
 * @param component The name of the component to be associated with this event
 */
export const createTelemetry = (
    component: string | undefined,
    telemetryService: ITelemetryService,
    dashboardStore: DashboardStore
): CreateTelemetry<string> =>
    action((eventName: string, additionalProperties?: Record<string, unknown>) =>
        telemetryService.trackEvent(
            eventName,
            getTelemetrySharedProperties(component, dashboardStore),
            additionalProperties
        )
    );

/**
 * Creates and returns a function for tracking exceptions from within React
 * @param component The name of the component to be associated with this event
 */
export const useTelemetryException = (component?: string): CreateTelemetry<Error> => {
    const core = useCore();
    const store = useDashboardStore();

    return useMemo(() => createTelemetryException(component, core.telemetryService, store), [component, core, store]);
};

/**
 * Creates and returns a function for tracking exceptions from within React
 * @param component The name of the component to be associated with this event
 */
export const createTelemetryException = (
    component: string | undefined,
    telemetryService: ITelemetryService,
    dashboardStore: DashboardStore
): CreateTelemetry<Error> =>
    action((exception: Error, additionalProperties?: Record<string, unknown>) =>
        telemetryService.trackException(
            exception,
            getTelemetrySharedProperties(component, dashboardStore),
            additionalProperties
        )
    );

/**
 * Creates and returns a function for tracking exceptions from within React
 * @param component The name of the component to be associated with this event
 */
export const useCoreTelemetryException = (component: string): CreateTelemetry<Error> => {
    const core = useCore();

    return useMemo(() => createCoreTelemetryException(component, core.telemetryService), [component, core]);
};

/**
 * Creates and returns a function for tracking exceptions from within React
 * @param component The name of the component to be associated with this event
 */
export const createCoreTelemetryException = (
    component: string,
    telemetryService: ITelemetryService
): CreateTelemetry<Error> =>
    action((exception: Error, additionalProperties?: Record<string, unknown>) =>
        telemetryService.trackException(exception, { 'Component Name': component }, additionalProperties)
    );
