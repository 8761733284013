import { ConstrainMode, IColumn } from 'office-ui-fabric-react/lib/components/DetailsList';
import { DetailsList, IDetailsListProps } from 'office-ui-fabric-react/lib/components/DetailsList/DetailsList';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useCurrent } from '../../../common';
import { APP_STRINGS } from '../../../res';

import styles from './SortableDetailsList.module.scss';

export interface ISortableDetailsListProps extends IDetailsListProps {
    sort?: boolean;
    defaultSortFieldName?: string;
    defaultSortDescending?: boolean;
    /**
     * If supplied, sort to this field is applied before the selected and secondary sorts
     */
    primarySortFieldName?: string;
    /**
     * If supplied, sort to this field is applied after the primary and selected sorts
     */
    secondarySortFieldName?: string;
    sortDisabledColumnKeys?: Set<string>;
    /**
     * If true, the primary sort field will always be ascending, irregardless of the sortDescending state
     */
    primarySortFieldOnlyAscending?: boolean;
    onSortChange?: (sortField: string, sortDescending: boolean) => void;
}

export const SortableDetailsList: React.FunctionComponent<ISortableDetailsListProps> = (props) => {
    const {
        columns: propsColumns,
        sort,
        items,
        primarySortFieldName,
        secondarySortFieldName,
        defaultSortFieldName: controlledSortFieldName,
        sortDisabledColumnKeys,
        primarySortFieldOnlyAscending,
        onSortChange,
        defaultSortDescending: controlledSortDescending,
    } = props;

    const [sortDescending, setSortDescending] = useState(!!controlledSortDescending);
    const [sortFieldName, setSortFieldName] = useState(controlledSortFieldName);
    const currentOnSortChange = useCurrent(onSortChange);

    const columns = useMemo((): IColumn[] => {
        // Columns changed, regenerate inferred properties
        if (!propsColumns) {
            return [];
        }

        if (!sort) {
            return propsColumns;
        }

        const onColumnClick = (_event: React.MouseEvent<HTMLElement>, columnCopy: IColumn) => {
            if (!sort) {
                return;
            }

            let sortOrder = false;
            if (columnCopy.fieldName === sortFieldName) {
                // Existing sort column selected
                sortOrder = !sortDescending;
                setSortDescending(!sortDescending);
            } else if (sortDisabledColumnKeys?.has(columnCopy.key)) {
                // Don't sort by this field
                return;
            } else {
                // New sort column selected
                setSortFieldName(columnCopy.fieldName);
            }

            if (currentOnSortChange.current && columnCopy.fieldName) {
                currentOnSortChange.current(columnCopy.fieldName, sortOrder);
            }
        };

        return propsColumns.map((column): IColumn => {
            const isSortField = column.fieldName === sortFieldName;

            const customHeaderClassName = sortDisabledColumnKeys?.has(column.key)
                ? styles.unsortableColumnHeader
                : styles.sortableColumnHeader;

            return {
                ...column,
                isSorted: isSortField,
                isSortedDescending: isSortField && sortDescending,
                onRender: column.onRender ? column.onRender : renderTooltip(column.fieldName),
                onColumnClick,
                headerClassName: column.headerClassName
                    ? `${customHeaderClassName} ${column.headerClassName}`
                    : customHeaderClassName,
                sortDescendingAriaLabel: APP_STRINGS.utilButtons.descending,
                sortAscendingAriaLabel: APP_STRINGS.utilButtons.ascending,
            };
        });
    }, [propsColumns, sort, sortDisabledColumnKeys, sortFieldName, sortDescending, currentOnSortChange]);

    const sortedItems = useMemo(() => {
        if (sort && sortFieldName) {
            const keys: Array<{
                key: string;
                sortDescending: boolean;
            }> = [];

            if (primarySortFieldName) {
                keys.push({
                    key: primarySortFieldName,
                    sortDescending: primarySortFieldOnlyAscending ? false : sortDescending,
                });
            }

            keys.push({
                key: sortFieldName,
                sortDescending,
            });

            if (secondarySortFieldName) {
                keys.push({
                    key: secondarySortFieldName,
                    sortDescending,
                });
            }

            return [...items].sort(sortFunction(keys));
        }

        return items;
    }, [
        sort,
        sortDescending,
        items,
        sortFieldName,
        primarySortFieldName,
        secondarySortFieldName,
        primarySortFieldOnlyAscending,
    ]);

    useEffect(() => {
        setSortDescending(!!controlledSortDescending);
        setSortFieldName(controlledSortFieldName);
    }, [controlledSortDescending, controlledSortFieldName, onSortChange, sortDescending, sortFieldName]);

    return (
        <DetailsList
            {...props}
            columns={columns}
            items={sortedItems}
            constrainMode={props.constrainMode ? props.constrainMode : ConstrainMode.unconstrained}
        />
    );
};

const sortFunction =
    <T extends unknown>(
        sortKeys: Array<{
            key: keyof T;
            sortDescending: boolean;
        }>
    ) =>
    (a: T, b: T): number => {
        let i = 0;
        let result = 0;

        while (result === 0 && i < sortKeys.length) {
            const sortObject = sortKeys[i];
            const sortKey = sortObject.key;

            const valueA = a[sortKey];
            const valueB = b[sortKey];

            result = compareFunction(sortObject.sortDescending, valueA, valueB);
            i++;
        }

        return result;
    };

const compareFunction = <T extends unknown>(sortDescending: boolean, firstValue: T, secondValue: T): number => {
    let returnValue: number;

    if (firstValue === secondValue) {
        return 0;
    } else if (firstValue === null || firstValue === undefined) {
        returnValue = 1;
    } else if (secondValue === null || secondValue === undefined) {
        returnValue = -1;
    } else if (typeof firstValue === 'number' && typeof secondValue === 'number') {
        returnValue = firstValue - secondValue;
    } else if (typeof firstValue === 'string' && typeof secondValue === 'string') {
        returnValue = firstValue.localeCompare(secondValue);
    } else {
        const firstVal = !!firstValue;
        const secondVal = !!secondValue;
        if (firstVal === secondVal) {
            return 0;
        } else {
            returnValue = firstVal ? -1 : 1;
        }
    }

    return sortDescending ? -returnValue : returnValue;
};

const renderTooltip = <T extends unknown>(fieldName: keyof T | undefined) => {
    if (!fieldName) {
        return;
    }

    return (item: T) => {
        const fieldItem = item[fieldName];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldItemString = fieldItem ? (fieldItem as any).toString() : null;

        return <div title={fieldItemString}>{fieldItem}</div>;
    };
};
