import { UserSettingsV0 } from './0';

type Infer = { readonly type: 'infer' };
type Specified<T> = { readonly type: 'specified'; readonly value: T };
type CanInfer<T> = Infer | Specified<T>;

export interface UserSettingsV1 {
    $schema: '1';
    theme: 'light' | 'dark' | 'automatic';
    language: CanInfer<string>;
    regionalFormat: CanInfer<string>;
    enableChartPerformanceMode: boolean;
    enableAccessabilityOptimizationMode: boolean;
    favoriteDashboards: string[];
}

export function up(settings: UserSettingsV0): UserSettingsV1 {
    return {
        $schema: '1',
        theme: 'automatic',
        language: { type: 'infer' },
        regionalFormat: { type: 'infer' },
        enableChartPerformanceMode: false,
        enableAccessabilityOptimizationMode: true,
        favoriteDashboards: settings.favoriteDashboards,
    };
}
