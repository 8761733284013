import { BehaviorSubject } from 'rxjs';

import type { StreamQueryResult } from '../../domain/query';

export class QueryStore {
    private registeredQueries: Record<string, BehaviorSubject<StreamQueryResult>> = {};

    getData(key: string): BehaviorSubject<StreamQueryResult> | undefined {
        return this.registeredQueries[key];
    }

    create(key: string, seed: StreamQueryResult | undefined): BehaviorSubject<StreamQueryResult> {
        const subject = new BehaviorSubject<StreamQueryResult>(
            seed ?? {
                kind: 'notRun',
            }
        );

        this.registeredQueries[key] = subject;

        return subject;
    }

    remove(key: string): boolean {
        const subject = this.getData(key);

        if (!subject) {
            return false;
        }

        subject.unsubscribe();

        delete this.registeredQueries[key];

        return true;
    }
}
