import * as React from 'react';
import { IconButton } from 'office-ui-fabric-react/lib/components/Button';
import { useHistory } from 'react-router-dom';

import { IDashboardListItem } from '../../../core/domain';
import { APP_STRINGS } from '../../../res';
import { useCore } from '../../../core';
import { hrefCapturing } from '../../../common';

import { styles } from './RowActionButtonStyle';

const iconProps = { iconName: 'Edit' };

interface IRowEditButton {
    dashboardItem: IDashboardListItem;
}

export const RowEditButton: React.FC<IRowEditButton> = ({ dashboardItem }) => {
    const core = useCore();
    const history = useHistory();

    const pathname = core.navUtil.path.dashboard(dashboardItem.id);

    return (
        <IconButton
            styles={styles}
            title={APP_STRINGS.utilButtons.edit}
            iconProps={iconProps}
            href={pathname}
            onClick={hrefCapturing(() => {
                history.push(pathname, { edit: true });
            })}
        />
    );
};
