import * as React from 'react';
import { ReactElement, useMemo } from 'react';

import { useDebugFlag, DebugFlagContextProvider } from '../../dev/debugFlags';
import { useCore } from '../../core';
import { APP_CONSTANTS } from '../../res';
import {
    VisualConfig,
    VisualConfigProvider,
    emptyVisualConfig,
    useVisualConfig,
    VisualDimension,
    VisualTypeConfig,
} from '../../domain';
import { DebugDataVisualComponent } from '../../dev/DebugVisual';

const debugVisualConfig = (visualConfig: VisualConfig<string>): VisualConfig<string> => {
    const visualTypes: Record<string, VisualTypeConfig> = {};

    for (const [visualType, config] of Object.entries(visualConfig.visualTypes)) {
        visualTypes[visualType] = {
            ...config,
            defaultSize: { width: 4, height: 4 },
            minimumSize: { width: 1, height: 1 },
            configurationCompatibility: APP_CONSTANTS.visuals.visualOptionKeys,
            crossFilterDimensions: (_getVisualOptions, getSchema) =>
                getSchema().columns.map(
                    (c): VisualDimension => ({
                        id: c.name,
                        displayName: c.name,
                        dataTypes: [c.type],
                    })
                ),
        };
    }

    return {
        ...emptyVisualConfig,
        layout: APP_CONSTANTS.visuals.standardVisualTypes,
        visualTypes,
        DataVisualComponent: DebugDataVisualComponent,
    };
};

const DebugVisualContextProvider: React.FC<{
    children: ReactElement | null;
}> = ({ children }) => {
    const visualConfig = useVisualConfig();
    const visualConfigValue = useMemo(() => debugVisualConfig(visualConfig), [visualConfig]);

    const enabled = useDebugFlag('debugVisualConfig');
    if (enabled) {
        return <VisualConfigProvider value={visualConfigValue}>{children}</VisualConfigProvider>;
    }
    return children;
};

export const DebugWrapper: React.FC<{ children: ReactElement | null }> = (props) => {
    const core = useCore();

    if (core.hostConfig.enableDevMenu) {
        return (
            <DebugFlagContextProvider>
                <DebugVisualContextProvider>{props.children}</DebugVisualContextProvider>
            </DebugFlagContextProvider>
        );
    }

    return props.children;
};
