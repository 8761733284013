import {
    createContext,
    useContextSelector,
    ContextSelector,
    useContextSelectors,
} from '@fluentui/react-context-selector';

import { IReduxState, Dispatch, GlobalAction } from './types';

export type DispatchContextValue = [Dispatch<GlobalAction>, () => IReduxState];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GlobalStateContext = createContext<IReduxState>(undefined as any);
export const DispatchContext = createContext<DispatchContextValue>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    undefined as any
);

export const useGlobalDispatch = () => useContextSelector(DispatchContext, (s) => s);

export const useGlobalSelector = <T>(selector: ContextSelector<IReduxState, T>): T =>
    useContextSelector(GlobalStateContext, selector);

export const useGlobalSelectors = <
    Properties extends string,
    SelectedValue extends unknown,
    Selectors extends Record<Properties, ContextSelector<IReduxState, SelectedValue>>
>(
    selectors: Selectors
    // TODO: Fix useGlobalSelectorsType #7728855
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => useContextSelectors(GlobalStateContext, selectors);
