/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EntityType, Action } from '../../../common/types';
import { Cluster } from '../../../stores/cluster';
import { RowDataTypeBase } from './RowDataTypeBase';
import { iconMapper } from './IconMapper';
import { getAction, getActions } from './ActionMapper';
import { dependencies } from '../../../dependencies';

export class ClusterRowDataType extends RowDataTypeBase {
    get cluster(): Cluster {
        return this.baseData as Cluster;
    }

    constructor(cluster: Cluster) {
        super(
            cluster.id,
            cluster.fetchState === 'failed' ? [`Error: ${cluster.getAlias()}`] : [cluster.getAlias()],
            EntityType.Cluster,
            cluster,
            iconMapper[cluster.entityType]
        );

        this.isFavorite = cluster.isFavorite;
        this.actions = this.getActions();
        this.details = this.cluster.isFetching && !this.cluster.isBackgroundFetch ? 'Loading' : undefined;
    }

    private getActions(): Action[] {
        const isFavorite = !!this.isFavorite;
        const cluster = this.cluster;

        if (this.cluster.isFetching && !this.cluster.isBackgroundFetch) {
            return [getAction('favorite', isFavorite, undefined)!];
        }

        let actions = [];
        switch (cluster.fetchState) {
            case 'notStarted':
                actions = dependencies.featureFlags.ShowConnectionButtons
                    ? [
                          getAction('refresh', isFavorite, undefined)!,
                          getAction('delete', isFavorite, undefined)!,
                          getAction('edit', isFavorite, undefined)!,
                          getAction('favorite', isFavorite, undefined)!,
                      ]
                    : [getAction('refresh', isFavorite, undefined)!, getAction('favorite', isFavorite, undefined)!];
                break;
            case 'failed':
                actions = dependencies.featureFlags.ShowConnectionButtons
                    ? [
                          getAction('refresh', isFavorite, undefined)!,
                          getAction('delete', isFavorite, undefined)!,
                          getAction('edit', isFavorite, undefined)!,
                          getAction('info', isFavorite, this.cluster.fetchStateError.errorMessage)!,
                          getAction('favorite', isFavorite, undefined)!,
                      ]
                    : [
                          getAction('refresh', isFavorite, undefined)!,
                          getAction('info', isFavorite, this.cluster.fetchStateError.errorMessage)!,
                          getAction('favorite', isFavorite, undefined)!,
                      ];
                break;
            case 'done':
                actions = getActions(this.entityType, isFavorite, undefined);
                break;
            default:
                throw new Error('unexpected fetchState ' + this.cluster.fetchState);
        }

        return actions.filter((action) => action);
    }
}
