import * as React from 'react';
import { IButton, IconButton, IContextualMenuItem, Text } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { VisualOptions, VisualDisplayedResultsCounts, useVisualConfig, QueryHash } from '../../../domain';

import { TileInfoCallout } from '../infoToolCallout/TileInfoCallout';
import { DashboardLoaded } from '../../../store';
import { TileUpdateIndicator } from './TileUpdateIndicator';

import { EditTileTitle } from './EditTileTitle';
import { EditButton } from './EditButton';
import { RefreshTileButton } from './RefreshTileButton';
import styles from './Header.module.scss';

const useShowColorRulesInfo = (visualType: string | undefined, visualOptions: VisualOptions) => {
    const visualConfig = useVisualConfig();

    const visualTypeConfig = visualType !== undefined ? visualConfig.visualTypes[visualType] : undefined;

    return (
        visualTypeConfig !== undefined &&
        visualTypeConfig.configurationCompatibility.includes('colorRules') &&
        visualOptions.colorRules.length > 0 &&
        !visualOptions.colorRulesDisabled
    );
};

interface TileTitleProps {
    pageState: DashboardLoaded | undefined;
    tileId: string;
    title: string;
    hideTileTitle: VisualOptions['hideTileTitle'];
}

const TileTitle: React.FC<TileTitleProps> = observer(function TileTitle({ pageState, tileId, title, hideTileTitle }) {
    if (pageState?.activeTileRename?.tileId === tileId) {
        return <EditTileTitle dashboardLoaded={pageState} tileId={tileId} title={title} />;
    }

    if (hideTileTitle) return null;

    return (
        <Text className={classNames(styles.titleContainer, styles.title)} data-automation-id="tile-title" title={title}>
            {title}
        </Text>
    );
});

export interface TileHeaderProps {
    pageState: DashboardLoaded | undefined;
    tileId: string;
    queryHash?: QueryHash;
    menuItems?: IContextualMenuItem[];
    title: string;
    visualOptions: VisualOptions;
    visualType?: string;
    onRefreshClick?: () => void;
    editing?: boolean;
    resultCounts?: VisualDisplayedResultsCounts;
}

const menuIconProps = { iconName: 'More' };

export const TileHeader: React.FC<TileHeaderProps> = ({
    pageState,
    tileId,
    queryHash,
    menuItems,
    onRefreshClick,
    title,
    visualOptions,
    visualType,
    editing = false,
    resultCounts,
}) => {
    const menuButtonRef = React.createRef<IButton>();

    const titleText = (
        <TileTitle pageState={pageState} tileId={tileId} title={title} hideTileTitle={visualOptions.hideTileTitle} />
    );

    const updateIndicator = queryHash && <TileUpdateIndicator queryHash={queryHash} />;

    const showColorRulesInfo = useShowColorRulesInfo(visualType, visualOptions);

    const tileInfoButton = (showColorRulesInfo || resultCounts) && (
        <TileInfoCallout
            className={styles.headerAction}
            tileId={tileId}
            visualOptions={visualOptions}
            resultCounts={resultCounts}
            showColorRulesInfo={showColorRulesInfo}
        />
    );

    const editButton = editing && <EditButton tileId={tileId} />;

    const refreshButton = queryHash && <RefreshTileButton queryHash={queryHash} onRefreshClick={onRefreshClick} />;

    // Rending OverflowMenu with `[]` or `undefined` items causes it to render a
    // single div, causing extra whitespace to appear, but not button
    const menuButton = menuItems && menuItems.length > 0 && (
        <IconButton
            componentRef={menuButtonRef}
            data-automation-id="tile-menu"
            data-tile-menu-btn={tileId}
            className={styles.headerAction}
            menuIconProps={menuIconProps}
            menuProps={{ items: menuItems }}
            title={APP_STRINGS.components.tile.menuButtonTitle}
            ariaLabel={APP_STRINGS.components.tile.menuButtonTitle}
        />
    );

    const className = classNames(styles.tileHeader, APP_CONSTANTS.dashboardPage.draggableTitleHeaderClassName, {
        [styles.editing]: editing,
    });

    return (
        <div className={className}>
            {titleText}
            <div className={styles.spaceFiller} />
            {updateIndicator}
            <div
                className={classNames(
                    styles.actionsContainer,
                    APP_CONSTANTS.dashboardPage.notDraggableTitleHeaderClassName
                )}
            >
                {tileInfoButton}
                {refreshButton}
                {editButton}
                {menuButton}
            </div>
        </div>
    );
};
