import { ErrorSnackbar, formatLiterals } from '@kusto/common';
import { MessageBarType } from 'office-ui-fabric-react';
import React, { useEffect, useMemo } from 'react';
import { ExtendedVisualizationOptions } from 'utils/visualization';
import { Columns, Rows, Strings } from '../@types/chart.types';
import { MultiStatCard } from './MultiStatCard';
import { StatCard } from './StatCard';
import {
    getSingleStatConditionalFormattingOptions,
    getConditionalFormattingOptions,
} from '../utils/conditionalFormatting/conditionalFormatting';
import moment from 'moment';

interface Props {
    /**
     * Kusto visualization options as supported by Kusto render command
     */
    visualizationOptions: ExtendedVisualizationOptions;
    rows: Rows;
    columns: Columns;
    strings: Strings;
    timezone?: string;

    setResultCounts?: (info: { displayedResults: number; totalResults: number } | null) => void;
}

export enum DataType {
    string = 'string',
    int = 'int',
    dynamic = 'dynamic',
    bool = 'bool',
    real = 'real',
    datetime = 'datetime',
    timespan = 'timespan',
    guid = 'guid',
    long = 'long',
    decimal = 'decimal',
}

const STRING_DATA_TYPE = [DataType.string];
const NUMERIC_DATA_TYPE = [DataType.int, DataType.real, DataType.long, DataType.decimal];

/**
 * The first column of the types specified by the dataTypes prioritized array.
 * @param allColumns
 * @param dataTypes an array of arrays data types. the outer array indicates priority, the 2nd array is a set of data types to look for.
 * for example, stat card tries to find a numeric data type as a first priority, and if it does not find one it looks for any numeric type.
 * this will look like [NUMERIC_DATA_TYPE][DataType.string].
 * @param excludedColumnNames columns that shouldn't be considered (for example, they're already selected to be some axis by the user)
 */
const getFirstColumnOfType = (allColumns: Columns, dataTypes: DataType[][], excludedColumnNames: string[]) => {
    if (!allColumns) {
        return null;
    }

    const excluded = new Set(excludedColumnNames);
    // go in order of priority
    for (let index = 0; index < dataTypes.length; index++) {
        const types = dataTypes[index];
        const relevantColumns = allColumns.filter((col) => {
            if (!col.columnType) {
                return false;
            }
            if (excluded.has(col.field)) {
                return false;
            }

            const dataType = col.columnType as DataType;
            return types.includes(dataType);
        });

        if (relevantColumns.length > 0) {
            return relevantColumns[0];
        }
    }
    return null;
};

/**
 * Enrich visualization option with heuristic axis and series column selection.
 * This method updates visualization options in-place for perf reasons.
 */
export const inferColumns = (visualizationOptions: ExtendedVisualizationOptions, columns: Columns): void => {
    let takenColumns: string[] = [];
    if (visualizationOptions.XColumn) {
        takenColumns.push(visualizationOptions.XColumn);
    }
    if (visualizationOptions.YColumns) {
        takenColumns = takenColumns.concat(visualizationOptions.YColumns);
    }
    if (visualizationOptions.Series) {
        takenColumns = takenColumns.concat(visualizationOptions.Series);
    }

    switch (visualizationOptions.Visualization) {
        case 'card':
            if (!visualizationOptions.YColumns || visualizationOptions.YColumns.length === 0) {
                const columnName = getFirstColumnOfType(
                    columns,
                    [NUMERIC_DATA_TYPE, STRING_DATA_TYPE],
                    takenColumns
                )?.field;
                if (columnName) {
                    visualizationOptions.YColumns = [columnName];
                    takenColumns.push(columnName);
                }
            }
            break;
        case 'multistat':
            if (!visualizationOptions.YColumns || visualizationOptions.YColumns.length === 0) {
                const columnName = getFirstColumnOfType(
                    columns,
                    [NUMERIC_DATA_TYPE, STRING_DATA_TYPE],
                    takenColumns
                )?.field;
                if (columnName) {
                    visualizationOptions.YColumns = [columnName];
                    takenColumns.push(columnName);
                }
            }

            if (!visualizationOptions.XColumn) {
                const columnName = getFirstColumnOfType(columns, [STRING_DATA_TYPE], takenColumns)?.field;
                if (columnName) {
                    visualizationOptions.XColumn = columnName;
                    takenColumns.push(columnName);
                }
            }

            break;
        // TODO: make typing better so that we will get compilation error if we don't include web-only visualization here.
    }
};

/**
 * Return a react component that displays an error if values are either null, undefined or empty.
 * @param visualizationType The visualization type
 * @param strings localized error message
 * @param values configuration values that must not be empty in order for the chart component to work.
 * // TODO: make typing better so we won't have to assert non-nulls with exclamation mark
 */
const getVisualizationOptionsError = (visualizationType: string, strings: Strings, ...values: any[]) => {
    if (values.some((val) => val === null || val === undefined || (Array.isArray(val) && val.length === 0))) {
        return (
            <ErrorSnackbar
                level={MessageBarType.info}
                message={formatLiterals(strings.chartsErrorsNoDraw, {
                    visualizationType: visualizationType,
                })}
            />
        );
    }

    return null;
};

/**
 * If value is of type datetime, apply the timezone to it.
 */
export const localizeTimezone = (value: string, type: string, timezone?: string): string => {
    if (value && timezone && type === DataType.datetime) {
        try {
            return moment(value).tz(timezone).format();
        } catch {
            // do nothing
        }
    }

    return value;
};

/**
 * Renders all components that don't have native support in desktop explorer.
 * @param props rows, columns and options needed to render the visualization
 */
export const WebOnlyChart = ({ visualizationOptions, columns, rows, setResultCounts, strings, timezone }: Props) => {
    const numberOfColumns = visualizationOptions.Width;
    const numberOfRows = visualizationOptions.Height;
    useEffect(() => {
        numberOfColumns &&
            numberOfRows &&
            setResultCounts &&
            setResultCounts(
                numberOfColumns * numberOfRows < rows.length
                    ? { displayedResults: numberOfColumns * numberOfRows, totalResults: rows.length }
                    : null
            );
    }, [rows.length, visualizationOptions.Width, visualizationOptions.Height]);
    const columnDataTypeMap: { [id: string]: string } = useMemo(() => {
        const map: { [id: string]: string } = {};
        columns?.forEach((column) => {
            if (column.headerName && column.columnType) {
                map[column.headerName] = column.columnType;
            }
        });
        return map;
    }, [columns]);
    inferColumns(visualizationOptions, columns);

    const vis = visualizationOptions.Visualization;
    let error: JSX.Element | null = null;
    switch (vis) {
        case 'card':
            error = getVisualizationOptionsError(vis, strings, visualizationOptions.YColumns);
            if (error) {
                return error;
            }

            const columnName = visualizationOptions.YColumns![0];
            const row = rows && rows.length ? rows[rows.length - 1] : undefined;
            const value = row?.[columnName];
            const dataType = columnDataTypeMap[columnName];
            const conditionalFormattingOptions = getSingleStatConditionalFormattingOptions(
                getConditionalFormattingOptions(
                    row as any,
                    undefined,
                    visualizationOptions.ColumnFormatting?.ConditionalFormattingConfig,
                    columnName
                ),
                columnName
            );
            return (
                <StatCard
                    locale={strings.locale}
                    value={localizeTimezone(value as any, dataType, timezone)}
                    textSize={visualizationOptions.TextSize ?? 'auto'}
                    {...conditionalFormattingOptions}
                />
            );

        case 'multistat':
            error = getVisualizationOptionsError(vis, strings, visualizationOptions.YColumns);
            if (error) {
                return error;
            }

            return (
                <MultiStatCard
                    textSize={visualizationOptions.TextSize ?? 'auto'}
                    numberOfColumns={numberOfColumns!}
                    numberOfRows={numberOfRows!}
                    data={rows.slice(0, numberOfColumns! * numberOfRows!).map((row) => {
                        const columnName = visualizationOptions.YColumns![0];
                        return {
                            header: row[visualizationOptions.XColumn!] as any,
                            value: localizeTimezone(row[columnName] as any, columnDataTypeMap[columnName], timezone),
                        };
                    })}
                    conditionalFormattingProps={rows.slice(0, numberOfColumns! * numberOfRows!).map((row) => {
                        const formattingOptions = getConditionalFormattingOptions(
                            row,
                            undefined,
                            visualizationOptions.ColumnFormatting?.ConditionalFormattingConfig,
                            visualizationOptions.YColumns![0]
                        );
                        return getSingleStatConditionalFormattingOptions(
                            formattingOptions,
                            visualizationOptions.YColumns![0]
                        );
                    })}
                    locale={strings.locale}
                />
            );
        default:
            return null;
    }
};
