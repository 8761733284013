import { History } from 'history';
import { CustomReactPlugin } from './CustomAppInsightsReactPlugin';
import { ITelemetryConfig } from './types';

export const rtdAppInsightsReactPlugin = new CustomReactPlugin();

export const buildTelemetryConfig = (history?: History<unknown>): ITelemetryConfig => {
    return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extensions: [rtdAppInsightsReactPlugin],
        extensionConfig: history && {
            [rtdAppInsightsReactPlugin.identifier]: { history },
        },
        disableFetchTracking: false,
    };
};
