import * as React from 'react';

import { useThemeState } from '../../../../../domain/theming';

import styles from './styles.module.scss';

interface MultiSelectCodeSampleProps {
    tableName: string;
    columnName: string;
    variableName: string;
}

export const MultiSelectCodeSample: React.FC<MultiSelectCodeSampleProps> = ({
    tableName,
    columnName,
    variableName,
}) => {
    const { isDark } = useThemeState();

    const themeClass = isDark ? styles['vs-dark'] : styles.vs;

    const formattedVariableName = `'${variableName}'`;

    return (
        <>
            <span className={themeClass}>
                <span className={styles.Table}>{tableName}</span>
            </span>
            <span className={themeClass}>
                <span className={styles.Punctuation}>|</span>
                <span>&nbsp;</span>
                <span className={styles.QueryOperator}>where</span>
                <span>&nbsp;</span>
                <span className={styles.Function}>isempty</span>
                <span className={styles.Punctuation}>([</span>
                <span className={styles.StringLiteral}>{formattedVariableName}</span>
                <span className={styles.Punctuation}>])</span>
                <span>&nbsp;</span>
                <span className={styles.ScalarOperator}>or</span>
                <span>&nbsp;</span>
                <span className={styles.Column}>{columnName}</span>
                <span>&nbsp;</span>
                <span className={styles.Keyword}>in</span>
                <span>&nbsp;</span>
                <span className={styles.Punctuation}>([</span>
                <span className={styles.StringLiteral}>{formattedVariableName}</span>
                <span className={styles.Punctuation}>])</span>
            </span>
            <span className={themeClass}>
                <span className={styles.Punctuation}>|</span>
                <span>&nbsp;</span>
                <span className={styles.QueryOperator}>take</span>
                <span>&nbsp;</span>
                <span className={styles.Literal}>100</span>
            </span>
        </>
    );
};
