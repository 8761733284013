import { FocusZone, IFocusZone, RefObject, IFocusZoneProps, initializeComponentRef } from 'office-ui-fabric-react';

export interface UnsafeFocusZoneProps extends Omit<IFocusZoneProps, 'componentRef'> {
    componentRef?:
        | React.RefObject<IUnsafeFocusZone>
        | RefObject<IUnsafeFocusZone>
        | ((ref: IUnsafeFocusZone | null) => void);
}

export interface IUnsafeFocusZone extends IFocusZone {
    /**
     * Clears the active selection and recalculates the tab indexes
     */
    clearActiveSelection(): void;
}

/**
 * Fabric FocusZone with some internal functionality exposed as a public API
 */
export class UnsafeFocusZone extends FocusZone implements IUnsafeFocusZone {
    constructor(props: IFocusZoneProps) {
        super(props);

        // Required to point componentRef to this subclass
        initializeComponentRef(this);
    }

    clearActiveSelection() {
        this['_activeElement'] = null;

        this['_updateTabIndexes']();
    }
}
