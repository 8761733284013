import { v4 as uuid } from 'uuid';

import type { RGLLayout, FallibleVisualTypeConfig } from '../../domain';
import { APP_CONSTANTS } from '../../res';
import { findNextOpenTilePosition } from '../layout';

import { VisualOptions, ITile } from './types';

export const isTileDisplayType = (tile: ITile) => tile.visualType === 'markdownCard';

export interface ICreateTileOptions {
    title: string;
    query: string;
    pageId: string;
    visualConfig: FallibleVisualTypeConfig;
    pageLayout: readonly RGLLayout[];
    dataSourceId?: string;
    visualType?: string;
    visualOptions?: Partial<VisualOptions>;
    usedVariables?: Set<string>;
    /**
     * Useful when we want to ensure that the tile uses existing cache data
     */
    id?: string;
}

export const createTile = ({
    title,
    query,
    dataSourceId,
    visualConfig,
    pageLayout,
    pageId,
    visualType = 'table',
    visualOptions = {},
    usedVariables = new Set(),
    id = uuid(),
}: ICreateTileOptions): ITile => {
    const config = visualType === 'markdownCard' ? APP_CONSTANTS.tile.markdownConfiguration : visualConfig[visualType];

    if (config === undefined) {
        throw new Error(`Missing configuration for visual type ${visualType}`);
    }

    let size = config.defaultSize ?? config.minimumSize;
    if (typeof size === 'function') {
        size = size(visualOptions);
    }

    return {
        id,
        title,
        query,
        dataSourceId,
        visualType,
        usedVariables,
        pageId,
        layout: {
            ...size,
            ...findNextOpenTilePosition(
                pageLayout,
                size.width,
                size.height,
                APP_CONSTANTS.dashboardPage.grid.columnCount
            ),
        },
        visualOptions,
    };
};
