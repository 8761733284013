import * as React from 'react';
import { useMemo } from 'react';
import { IconButton } from 'office-ui-fabric-react/lib/components/Button';
import { observer } from 'mobx-react-lite';

import { APP_STRINGS } from '../../../res';
import { useUrlCallback, useVisitUrl } from '../../../domain/navigation';
import { useDashboardStore } from '../../../store/dashboard';

import styles from './Header.module.scss';

const iconProps = { iconName: 'Edit' };

interface TileHeaderEditButtonProps {
    tileId: string;
}

export const EditButton: React.FC<TileHeaderEditButtonProps> = observer(function EditButton({ tileId }) {
    const getUrl = useUrlCallback<string>();
    const store = useDashboardStore();
    const dashboardId = store.state?.meta.id;

    const visitUrl = useVisitUrl();

    const onEdit = useMemo(
        () =>
            dashboardId === undefined
                ? undefined
                : (event: React.MouseEvent<unknown>) => {
                      event.preventDefault();
                      visitUrl(`/${dashboardId}/edit/${tileId}`);
                  },
        [tileId, dashboardId, visitUrl]
    );

    return (
        <IconButton
            className={styles.headerAction}
            title={APP_STRINGS.utilButtons.edit}
            iconProps={iconProps}
            disabled={store.state === undefined || !store.state.meta.isDashboardEditor}
            href={dashboardId && getUrl(`/${dashboardId}/edit/${tileId}`)}
            onClick={onEdit}
        />
    );
});
