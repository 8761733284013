import { dependencies } from '../dependencies';
import defaultLocalizationStrings from '../Localization/en/strings.json';

export type LocalizedStrings = typeof defaultLocalizationStrings & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};
export const DefaultLocalizationStrings = defaultLocalizationStrings as LocalizedStrings;

type LocalizationLanguage = {
    code: string;
    name: string;
    localizedName: string;
    alias: string[];
};

// key: supported culture by Microsoft's localization team. must be lowercase
// value: array of cultures to be mapped to Microsoft's standard (like cultures from the browser)
export const supportedCultures: LocalizationLanguage[] = [
    { code: 'cs', name: 'Czech', localizedName: 'Čeština', alias: [] },
    { code: 'de', name: 'German', localizedName: 'Deutsch', alias: [] },
    { code: 'en', name: 'English', localizedName: 'English', alias: [] },
    { code: 'es', name: 'Spanish', localizedName: 'Español', alias: [] },
    { code: 'fr', name: 'French', localizedName: 'Français', alias: [] },
    { code: 'hu', name: 'Hungarian', localizedName: 'Magyar', alias: [] },
    { code: 'it', name: 'Italian', localizedName: 'Italiano', alias: [] },
    { code: 'nl', name: 'Dutch', localizedName: 'Nederlands', alias: [] },
    { code: 'pl', name: 'Polish', localizedName: 'Polski', alias: [] },
    { code: 'ru', name: 'Russian', localizedName: 'Pусский', alias: [] },
    { code: 'sv', name: 'Swedish', localizedName: 'Svenska', alias: [] },
    { code: 'tr', name: 'Turkish', localizedName: 'Türkçe', alias: [] },
    { code: 'ja', name: 'Japanese', localizedName: '日本語', alias: [] },
    { code: 'ko', name: 'Korean', localizedName: '한국어', alias: [] },
    {
        code: 'pt-br',
        name: 'Portuguese (Brazil)',
        localizedName: 'Português (Brasil)',
        alias: [],
    },
    {
        code: 'pt-pt',
        name: 'Portuguese (Portugal)',
        localizedName: 'Português (Portugal)',
        alias: ['pt'],
    },
    {
        code: 'zh-hans',
        name: 'Chinese (Simplified)',
        localizedName: '中国 - 简体中文',
        alias: ['zh-cn'],
    },
    {
        code: 'zh-hant',
        name: 'Chinese (Traditional)',
        localizedName: '台灣 - 繁體中文',
        alias: ['zh-tw'],
    },
];

export const getLanguage = (requestedLang?: string): string => {
    if (requestedLang === undefined || requestedLang === '') {
        requestedLang = dependencies.deepLinkProperties.language
            ? dependencies.deepLinkProperties.language
            : navigator.language;
    }
    requestedLang = requestedLang.toLowerCase();
    let appLang = '';
    const lang = requestedLang.split('-'); // requestedLang=en-us etc
    supportedCultures.forEach((supportedCulture) => {
        // checking to see if the entire culture is supported (example: pt-pt)
        if (requestedLang === supportedCulture.code) {
            appLang = requestedLang;
            return;
        }
        // if the culture is in alias, return the key (example: zh-cn -> return zh-hans)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (supportedCulture.alias.includes(requestedLang!)) {
            appLang = supportedCulture.code;
            return;
        }
        // only language is supported (en-US -> only [en] is supported)
        if (lang.length > 0 && supportedCulture.code === lang[0]) {
            appLang = lang[0];
            return;
        }
    });
    return appLang === '' ? 'en' : appLang; // default culture
};

const initPageLocale = () => {
    const html: HTMLElement = document.getElementsByTagName('html')[0];
    if (html.lang !== dependencies.strings.pageLang) {
        html.setAttribute('lang', dependencies.strings.pageLang);
    }
    if (document.title !== dependencies.strings.pageTitle) {
        document.title = dependencies.strings.pageTitle;
    }
};

export async function stringsPromise(langFromSettings?: string): Promise<void> {
    const lang = getLanguage(langFromSettings);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const localizedStrings = await Promise.resolve(require(`../Localization/${lang}/strings.json`));
    // merging the localized strings into the English (default) strings
    dependencies.strings = Object.assign({}, DefaultLocalizationStrings, localizedStrings);
    initPageLocale();
}

export function agGridLocaleTextFunc(key: string, defaultValue = '') {
    // to avoid key clash with external keys, we add 'grid$' to the start of each key.
    // keys will not be a part of LocalizedStrings
    const gridKey = 'grid$' + key;
    const value = dependencies.strings[gridKey];
    return value ? value : defaultValue;
}
