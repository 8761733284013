import { KustoDomains, User } from '@kusto/common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
    PublicClientApplication,
    Configuration,
    InteractionStatus,
    AccountInfo,
    LogLevel,
    RedirectRequest,
    SilentRequest,
    EventMessage,
    EventMessageUtils,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { v4 } from 'uuid';
import { getTelemetryClient } from '../../utils/telemetryClient';
import { decodeJwtToken, resourceToScopes } from './authenticationUtils';
import { AuthenticationProvider } from './AuthenticationProvider';
import { parseAuthState, stringifyAuthState } from './AuthState';
import { basePathName } from '../../utils/url';

const { trackException, trackTrace } = getTelemetryClient({
    component: 'MsalAuthenticationProviderV2',
    flow: '',
});

const MsalMultiTenant = 'organizations';

const levelToSeverity = (level: LogLevel): SeverityLevel | undefined => {
    switch (level) {
        case LogLevel.Error:
            return SeverityLevel.Error;
        case LogLevel.Info:
            return SeverityLevel.Information;
        case LogLevel.Verbose:
            return SeverityLevel.Verbose;
        case LogLevel.Warning:
            return SeverityLevel.Warning;
    }
};

export class MsalAuthenticationProviderV2 implements AuthenticationProvider {
    private readonly application: PublicClientApplication;
    private readonly defaultScopes: string[];
    private extraQueryParameters: StringDict | undefined = undefined;
    private tenant: string;
    private forceRefresh = false;
    private interactionStatus: InteractionStatus = InteractionStatus.None;
    /**
     * This is for debugging purposes. should remove once v2 is stable.
     */
    private enableLogs = false;
    /**
     * Used to save the localAccountId of the currently logged in user
     */
    private userLocalAccountId: string | undefined = undefined;

    constructor(private redirectUri: string, private clientId: string, enableLogs?: boolean) {
        this.enableLogs = !!enableLogs;
        this.logDebug('=== MSALV2 CONSTRUCTOR START ===');
        const defaultResource = 'https://help' + KustoDomains.Default;
        this.defaultScopes = resourceToScopes(defaultResource);
        this.tenant = MsalMultiTenant;
        const config: Configuration = {
            auth: {
                clientId: this.clientId,
                redirectUri: this.redirectUri,
                authority: `${process.env.REACT_APP_AAD_ENDPOINT}${this.tenant}`,
            },
            cache: {
                cacheLocation: 'sessionStorage',
                // Fix for auth loop issues https://aka.ms/known-issues-on-Microsoft-Browsers-due-to-security-zones
                storeAuthStateInCookie: true,
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level: LogLevel, message: string): void => {
                        trackTrace(message, levelToSeverity(level), { flow: 'msalv2' });
                    },
                    logLevel: LogLevel.Info,
                    piiLoggingEnabled: false,
                },
                // Allow more time before a token renewal response from Azure AD should be considered timed out
                loadFrameTimeout: 30000,
            },
        };
        this.application = new PublicClientApplication(config);

        // Listening to status change, to indentify when login is in progress
        this.application.addEventCallback((message: EventMessage) => {
            const status = EventMessageUtils.getInteractionStatusFromEvent(message);
            if (status !== null) {
                this.logDebug('=== MSALV2 EVENT LISTENER STATUS CHANGE ===', status);
                this.interactionStatus = status;
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logDebug(message: any, ...optionalParams: any[]) {
        if (this.enableLogs) {
            console.log(message, optionalParams);
        }
    }

    logOut(): void {
        this.logDebug('=== MSALV2 LOGOUT ===');
        this.application.logout();
    }

    login() {
        this.logDebug('=== MSALV2 LOGIN ===');
        /**
         * Removes login hint before redirecting in certain cases.
         * @param url the url as provided by MSAL library
         */
        const onRedirect = (urlString: string) => {
            const url = new URL(urlString);
            const searchParams = new URLSearchParams(url.search);
            const prompt = searchParams.get('prompt');
            const loginHint = searchParams.get('login_hint');
            // MSAL always adds a login_hint, so prompt=login will always redirect to currently logged in tenant.
            // Asked following question on github https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1865
            if (prompt === 'login' && loginHint) {
                trackTrace('removing login hint', SeverityLevel.Information, { flow: 'msalv2' });
                searchParams.delete('login_hint');
                url.search = searchParams.toString();
                window.location.href = url.href;
                return false;
            }

            return true;
        };

        const authParams = this.getRedirectRequestParams(true);
        this.logDebug('=== MSALV2 LOGIN AUTH PARAMS ===', authParams);

        this.application.loginRedirect({
            ...authParams,
            onRedirectNavigate: onRedirect,
        });
    }

    loginInProgress() {
        this.logDebug('=== MSALV2 loginInProgress ===', this.interactionStatus);
        return this.interactionStatus !== InteractionStatus.None;
    }

    setAuthContextExtraQueryParameter(value?: string) {
        if (value) {
            const [key, val] = value.split('=');
            this.extraQueryParameters = { [key]: val };
        } else {
            this.extraQueryParameters = undefined;
        }
    }

    clearCache() {
        this.logDebug('=== MSALV2 CLEAR CACHE ===');
        this.forceRefresh = true;
    }

    private getRedirectRequestParams(isLogin = false) {
        const authParameters: RedirectRequest = {
            extraQueryParameters: this.extraQueryParameters,
            authority: isLogin
                ? `${process.env.REACT_APP_AAD_ENDPOINT}${MsalMultiTenant}`
                : this.getAuthorityFromTenant(this.tenant),
            loginHint: this.extraQueryParameters?.login_hint,
            sid: this.extraQueryParameters?.sid,
            scopes: this.defaultScopes,
            state: stringifyAuthState({ basePathName }),
        };

        return authParameters;
    }

    private getAuthorityFromTenant(tenant: string) {
        this.logDebug('=== MSALV2 getAuthorityFromTenant START ===', tenant);
        let tenantFromAccount;
        if (!tenant || tenant === MsalMultiTenant) {
            // until the following msal bug is fixed https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1761 and our package version dependency is incremented accordingly
            // we will use the tenant from id token otherwise we get constant cache miss.
            // Originally this code path returned an undefined authority but turns out that MSAL doesn't know what to do when there are multiple access tokens in its cache and the authority is undefined.
            const account: AccountInfo = this.getLoggedInUser();
            tenantFromAccount = account?.tenantId;

            // If, for some reason we cannot get the tenant from id token, we fall back to returning undefined (which will be common endpoint if there aren't multiple access tokens in cache)
            if (!tenantFromAccount) {
                return undefined;
            }
        }
        return `${process.env.REACT_APP_AAD_ENDPOINT}${tenantFromAccount ?? tenant}`;
    }

    private async msalGetToken(scopes: string[], forceRefresh?: boolean): Promise<string> {
        this.logDebug('=== MSALV2 msalGetToken START ===', scopes, forceRefresh);
        const silentRequestParams: SilentRequest = {
            extraQueryParameters: this.extraQueryParameters,
            authority: this.getAuthorityFromTenant(this.tenant),
            account: this.getLoggedInUser(),
            forceRefresh: forceRefresh ?? this.forceRefresh,
            scopes,
        };

        try {
            const response = await this.application.acquireTokenSilent(silentRequestParams);
            return response.accessToken;
        } catch (error) {
            this.logDebug('=== MSALV2 msalGetToken ERROR ===', error.errorCode);
            if (error instanceof InteractionRequiredAuthError) {
                this.application.acquireTokenRedirect({ ...this.getRedirectRequestParams(), scopes });
            } else {
                throw error;
            }
        }

        // won't matter since we're redirecting anyway.
        return '';
    }

    async getToken(scopes?: string[], tokenExpired?: (message: string) => void): Promise<string> {
        this.logDebug('=== MSALV2 getToken START ===', scopes);
        if (!scopes) {
            // Request the Kusto access token if no scopes are provided
            scopes = this.defaultScopes;
        }
        if (!scopes.length) {
            throw new Error('No scopes provided for the access token request.');
        }

        try {
            const token = await this.msalGetToken(scopes);

            // TODO: consider doing this only when the query fails.
            // When multiple kustoweb tabs are open there can be a race condition where the token expiry adal stores
            // in its cache is wrong (i.e doesn't represent the real exp date in the token).
            // specifically - token is no longer valid (expired), but the expiry date that adal.js stores is a valid date.
            // thus adal js decides to return the token to us, but it's damaged and will never work and the service will
            // reject it.
            // Thus, we validate it here before returning to client.
            const decodedToken = token ? decodeJwtToken(token) : undefined;

            if (
                decodedToken &&
                decodedToken.exp &&
                decodedToken.exp < Math.round(new Date().getTime() / 1000.0) + 300
            ) {
                if (tokenExpired) {
                    tokenExpired(`exp time: ${decodedToken.exp}, current: ${new Date().getTime()}`);
                }
                return this.msalGetToken(scopes, true);
            }

            return token;
        } finally {
            this.forceRefresh = false;
        }
    }

    refreshToken() {
        return false;
    }

    getCachedUser(): User {
        this.logDebug('=== MSALV2 getCachedUser START ===');
        this.logDebug('=== MSALV2 getCachedUser getAllAccounts ===', this.application.getAllAccounts());
        const account = this.getLoggedInUser();
        this.logDebug('=== MSALV2 getCachedUser ACCOUNT ===', account);
        return this.accountToUser(account);
    }

    getUser(): Promise<User> {
        const user = this.getCachedUser();
        return Promise.resolve(user);
    }
    async getUserName(): Promise<string> {
        const user = await this.getUser();
        return user.userName;
    }

    async authenticateAndRun(app: () => void) {
        this.logDebug('=== MSALV2 authenticateAndRun START ===');
        const contextID = v4();
        const trace = (message: string, severity: SeverityLevel = SeverityLevel.Information) =>
            trackTrace(message, severity, { flow: 'authenticateAndRun_v2', contextID });

        trace('authenticateAndRun: start');
        const loginResult = await this.application.handleRedirectPromise();
        if (loginResult?.state) {
            trace(`authenticateAndRun: state is ${loginResult?.state}`);
            // If basePathName was set, navigate to it. (see comment on AuthState.basePathName)
            const authState = parseAuthState(loginResult?.state);
            if (authState.basePathName && authState.basePathName !== basePathName) {
                const redirectTo = `${window.location.protocol}//${window.location.host}${authState.basePathName}`;
                trace(`authenticateAndRun: basePathName exist in state. Redirecting to '${redirectTo}'`);
                window.location.replace(redirectTo);
                return;
            }
        }

        this.logDebug('=== MSALV2 authenticateAndRun LOGIN RESULT ===', loginResult);

        // If this is the iframe - we don't want to load the app.
        if (window.parent !== window) {
            trace('authenticateAndRun: return because running in an iframe');
            return;
        }
        let account: AccountInfo | null;
        if (loginResult) {
            account = loginResult.account;
        } else {
            account = await this.getAccount();
        }
        trace('authenticateAndRun: calling getAccount');
        if (!account) {
            this.logDebug('=== MSALV2 account or claims are falsy. calling loginRedirect. ===');
            trace('authenticateAndRun: account or claims are falsy. calling loginRedirect');
            let hardLogin = false;
            try {
                // loginRedirect is not guaranteed to redirect. (for example - a token already exists on ADAL cache location. since loginRedirect knows how to pull it from ADAL cache, this will return without redirecting.)
                // loginRedirect may return before redirect happens. (according to our tests. In newer versions of MSAL this can be re-validated.)
                this.application.loginRedirect({
                    state: stringifyAuthState({ basePathName }), // see AuthState.basePathName for more information.
                    scopes: this.defaultScopes,
                });
                trace('authenticateAndRun: loginRedirect returned.');
                this.logDebug('=== authenticateAndRun: loginRedirect returned. ===');

                account = await this.getAccount(1); // the retry will give enough time to loginRedirect to actually redirect.
                if (account) {
                    this.logDebug('=== MSALV2 authenticateAndRun account found after loginRedirect. ===');
                    trace('authenticateAndRun: account found after loginRedirect.');
                } else {
                    this.logDebug(
                        '=== MSALV2 authenticateAndRun account did not found after loginRedirect. Trying force refresh. ==='
                    );
                    trace('authenticateAndRun: account did not found after loginRedirect. Trying force refresh.');
                    hardLogin = true; // if logic redirect failed, try a force redirect.
                }
            } catch (e) {
                this.logDebug('=== MSALV2 authenticateAndRun loginRedirect or getAccount throws. ===');
                trace('authenticateAndRun: loginRedirect or getAccount throws.');
                trackException(e, 'authenticateAndRun', { contextID });
                hardLogin = true; // if logic redirect failed, try a force redirect.
            }

            if (hardLogin) {
                this.logDebug('=== MSALV2 authenticateAndRun: force refresh. ===');
                trace('authenticateAndRun: force refresh.');
                // Don't replace the next line with login() and forceRefresh=true,
                // Explanation: If for some reason we still don't have token claims, go get another token from server.
                // we're specifying client id as scope here because otherwise MSAL will still try to get old ADAL id tokens which
                // results in a null account for some reason.
                this.application.acquireTokenSilent({ forceRefresh: true, scopes: resourceToScopes(this.clientId) });
                trace('authenticateAndRun: login with forceRefresh=true returned without redirecting.');
            }

            // In some cases the account is not available immediately (needs to be re-validated with future versions of MSAL).
            // Try to get account with retries.
            // this will also give time to this.login() with forceRefresh=true to perform the redirect.
            this.logDebug('=== authenticateAndRun: getAccount with retries ===');
            account = await this.getAccount(4);
            if (!account) {
                this.logDebug('=== MSALV2 authenticateAndRun account not found. Logging out... ===');
                trace('authenticateAndRun: account not found. Logging out...');
                // TODO: Added in July 17th. In few weeks, check if this trace ever happens, since we are doing force refresh it should never happen,
                // if it does we should show an error page, currently users will see a white screen.
                return;
            }
        }
        this.userLocalAccountId = account.localAccountId;
        this.logDebug('=== MSALV2 authenticateAndRun END ===');
        app();
    }

    private async getAccount(retries = 0): Promise<AccountInfo | null> {
        this.logDebug('=== getAccount START ===', retries);
        const loggedInUser = this.getLoggedInUser();
        if (loggedInUser && loggedInUser.idTokenClaims) {
            return loggedInUser;
        }
        const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

        let retriesCount = retries;
        while (retriesCount > 0) {
            await sleep(500);
            const loggedInUser = this.getLoggedInUser();
            if (loggedInUser && loggedInUser.idTokenClaims) {
                return loggedInUser;
            }
            retriesCount = retriesCount - 1;
        }

        return null;
    }

    async setTenant(tenant: string) {
        this.logDebug('=== MSALV2 setTenant START ===', tenant);
        const prevTenant = this.tenant;
        // MSAL cannot change configuration once the application object is created.
        // it _does_ allow to provide overrides to authority on login and getToken calls though,
        // this  is what adal was doing:  this.authContext.config.tenant = tenant;
        // we're going to change the tenant in a property and reference it when sking for a login or a token.
        this.tenant = tenant;

        // preloading of kusto token
        try {
            await this.msalGetToken(this.defaultScopes, true);
            this.logDebug('=== MSALV2 setTenant SUCCESS ===');
        } catch (ex) {
            this.logDebug('=== MSALV2 setTenant ERROR ===', ex);
            this.tenant = prevTenant;
            return Promise.reject(ex);
        }
    }

    async switchTenant(tenant: string) {
        this.logDebug('=== MSALV2 switchTenant ===', tenant);
        const currentTenant = this.getTenant();
        if (currentTenant === tenant) {
            return;
        }
        await this.setTenant(tenant);
    }

    getTenant(): string {
        this.logDebug('=== MSALV2 getTenant START ===');
        // actual tenant is configured on application
        const tid = this.tenant;
        if (tid && tid !== MsalMultiTenant) {
            this.logDebug('=== MSALV2 getTenant FROM TENANT ===', tid);
            return tid;
        }

        // in case configured tenant is the default, take tid from actual user data
        const account = this.getLoggedInUser();
        const user = this.accountToUser(account);
        const profile = user?.profile;
        if (!profile?.tid) {
            this.logDebug('=== MSALV2 getTenant ERROR ===', user);
            throw new Error(`getTenant: could not get tenant id from account`);
        }
        this.logDebug('=== MSALV2 getTenant FROM PROFILE ===', profile?.tid);
        return profile?.tid;
    }

    /**
     * Checks whether the account is a consumer facing account (MSA)
     */
    getIsMSAAccount(): boolean {
        this.logDebug('=== MSALV2 getIsMSAAccount ===', this.getTenant());
        const tenant = this.getTenant();

        return (
            // MSA common
            tenant === '9188040d-6c67-4c5b-b112-36a304b66dad' ||
            // First party app global tenant
            tenant === 'f8cdef31-a31e-4b4a-93e4-5f571e91255a'
        );
    }

    getLoginError() {
        // TODO: looks like msal does not support getRequestInfo. returning false..
        return undefined;
    }

    private accountToUser(account: AccountInfo): User {
        this.logDebug('=== MSALV2 accountToUser ACCOUNT ===', account);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { tid, upn, oid, name, family_name, given_name } = account.idTokenClaims as any;
        const user: User = {
            userName: account.name ?? account.username,
            profile: {
                tid,
                upn: upn ?? account.username, // email - ADAL: account.idTokenClaims, MSAL: account.userName
                oid,
                name,
                family_name, // not supported in MSAL
                given_name, // not supported in MSAL
            },
        };
        this.logDebug('=== MSALV2 accountToUser USER ===', user);
        return user;
    }

    /**
     *
     * @returns {AccountInfo} The account that is currently logged in (localAccountId === this.userLocalAccountId)
     */
    private getLoggedInUser() {
        this.logDebug('getLoggedInUser START');
        const allAccounts = this.application.getAllAccounts();
        // Default account is the first one
        let account = allAccounts[0];
        if (this.userLocalAccountId) {
            // If userLocalAccountId is set, we should look for an account that matches it
            account = allAccounts.find((acc) => acc.localAccountId === this.userLocalAccountId) ?? account;
            if (!account) {
                this.logDebug('getLoggedInUser NO ACCOUNT FOUND!', allAccounts, this.userLocalAccountId);
                this.userLocalAccountId = undefined;
            }
        }
        this.logDebug('getLoggedInUser FINAL ACCOUNT', account);
        return account;
    }
}
