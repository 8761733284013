import { Column, Table, EntityType } from '../../../common/types';
import { RowDataTypeBase } from './RowDataTypeBase';
import { iconMapper } from './IconMapper';
import { getActions } from './ActionMapper';
import { Database } from '../../../stores/cluster';
import { getFromCacheOrCreate } from './RowDataTypeCache';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';
import { TableRowDataType } from './TableRowDataType';

export class ColumnRowDataType extends RowDataTypeBase {
    private constructor(column: Column, table: Table, database: Database) {
        const pathFromRoot = TableRowDataType.buildTableFolderPath(database, table);
        pathFromRoot.push(column.name);
        super(column.id, pathFromRoot, EntityType.Column, database, iconMapper[column.entityType]);

        this.details = column.type;
        this.actions = getActions(this.entityType, false, undefined);
    }

    public static fromCacheOrCreate(column: Column, table: Table, database: Database): RowDataType {
        return getFromCacheOrCreate(database, column.id, () => new ColumnRowDataType(column, table, database));
    }
}
