import * as React from 'react';
import { ICommandBarProps } from 'office-ui-fabric-react';
import { useMemo } from 'react';

import { RTDCommandBar } from '../fabric';
import { useCore } from '../../core';

import { StaticBreadcrumb, IStaticBreadcrumbItem } from './StaticBreadcrumb';
import { DashboardLoading } from './DashboardLoading';

import styles from './PageContent.module.scss';

export interface IPageContentProps {
    commandBar?: Pick<ICommandBarProps, 'items' | 'farItems' | 'overflowItems'>;
    breadcrumbs: IStaticBreadcrumbItem[];
    farChildren?: React.ReactNode;
    contentWrapperClassName?: string;
    breadcrumbClassName?: string;

    breadcrumbBackgroundColor?: string;
}

export const PageContent: React.FC<IPageContentProps> = ({
    commandBar,
    breadcrumbs,
    farChildren,
    children,
    contentWrapperClassName,
    breadcrumbClassName,
    breadcrumbBackgroundColor,
}) => {
    const core = useCore();
    const wrapperStyles = useMemo(() => ({ backgroundColor: breadcrumbBackgroundColor }), [breadcrumbBackgroundColor]);

    const breadcrumb = core.hostConfig.disableLegacyBreadcrumbs ? null : (
        <nav
            className={breadcrumbClassName ? `${styles.breadcrumb} ${breadcrumbClassName}` : styles.breadcrumb}
            data-automation-id="headerBreadcrumb"
        >
            <StaticBreadcrumb items={breadcrumbs} farItems={farChildren} />
        </nav>
    );

    return (
        <>
            {!!commandBar && <RTDCommandBar {...commandBar} />}
            {breadcrumbBackgroundColor ? <div style={wrapperStyles}>{breadcrumb}</div> : breadcrumb}
            <main id={styles.contentWrapper} className={contentWrapperClassName ?? styles.contentWrapperClass}>
                <DashboardLoading />
                {children}
            </main>
        </>
    );
};
