import { createContext, useContext } from 'react';
import { RootStore } from './rootStore';

const RootStoreContext = createContext<null | RootStore>(null);

/**
 * To be used by App.tsx (the entry point of the app) to provide the value of rootStore as read from local storage.
 */
export const RootStoreContextProvider = RootStoreContext.Provider;

/**
 * Gain access to root store from any functional react component.
 * Note that components that use the store usually also need to be wrapped in an observer() call (otherwise value updates won't work)
 */
export function useRootStore() {
    const store = useContext(RootStoreContext);
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider');
    }
    return store;
}
