import { Person } from '@microsoft/microsoft-graph-types';
import * as React from 'react';
import { useMemo, useState, useRef, useCallback, useLayoutEffect, forwardRef } from 'react';
import {
    NormalPeoplePicker,
    IPersonaProps,
    IBasePickerSuggestionsProps,
    Label,
    IBasePicker,
} from 'office-ui-fabric-react';

import { useCore } from '../../../core';
import { IGraphService } from '../../../core/graph/IGraphService';
import { APP_STRINGS } from '../../../res';
import { compactMap } from '../../../common';

import { GraphPerson, ExtendedPersonaProps } from './types';
import { userOrGroupToPersona } from './util';

const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: APP_STRINGS.fabric.graph.suggestionsHeader,
    mostRecentlyUsedHeaderText: APP_STRINGS.fabric.graph.contactsHeader,
    noResultsFoundText: APP_STRINGS.fabric.graph.noResults,
    loadingText: APP_STRINGS.loading,
    showRemoveButtons: false,
    suggestionsAvailableAlertText: APP_STRINGS.fabric.graph.suggestionsAvailableAlert,
    suggestionsContainerAriaLabel: APP_STRINGS.fabric.graph.contactsHeader,
};

export interface GraphPeoplePickerProps {
    inputId?: string;
    className?: string;
    label?: string;
    selectedItems?: GraphPerson[];
    onChange?: (items: GraphPerson[]) => void;
}

export const GraphPeoplePicker = forwardRef<IBasePicker<IPersonaProps>, GraphPeoplePickerProps>(
    ({ inputId, className, label, selectedItems: graphSelectedItems, onChange: graphOnChange }, ref) => {
        const { graphService } = useCore();

        const selectedIds = useRef<Set<string> | undefined>();
        const [selectedItems, setSelectedItems] = useState<IPersonaProps[] | undefined>();

        const onResolveSuggestions = useCallback(
            async (filter: string) =>
                graphService
                    .searchUsersAndGroups(filter)
                    .then((people) => people.map(userOrGroupToPersona))
                    // Return empty array on error
                    .catch(() => []),
            [graphService]
        );

        const onChange = useMemo(
            () =>
                graphOnChange
                    ? (items: IPersonaProps[] | undefined) => {
                          // All Personas should be extended, so this cast is safe
                          const persons = (items as ExtendedPersonaProps[] | undefined)?.map(
                              (p): GraphPerson => p.graphPerson
                          );

                          selectedIds.current = new Set(persons?.map((p) => p.id));

                          graphOnChange(persons ?? []);
                      }
                    : undefined,
            [graphOnChange]
        );

        const onItemSelected = useCallback((selectedItem?: IPersonaProps) => {
            // Prevent selecting duplicate items
            if (!selectedItem) {
                return null;
            }

            const person = (selectedItem as ExtendedPersonaProps).graphPerson;
            if (selectedIds.current?.has(person.id)) {
                return null;
            }

            return selectedItem;
        }, []);

        const getMostRecentlyUsed = useMemo(() => {
            let promise: Promise<Person[]> | undefined = undefined;

            return () => {
                if (!promise) {
                    promise = graphService
                        .getRelatedPeople()
                        .then((people) => people.map(userOrGroupToPersona))
                        // Return empty array on error
                        .catch(() => []);
                    return promise;
                }

                return promise;
            };
        }, [graphService]);

        // useLayoutEffect due to hitting the cache. We don't want a flicker if we can help it
        useLayoutEffect(() => {
            if (!graphSelectedItems) {
                return;
            }

            selectedIds.current = new Set(graphSelectedItems?.map((p) => p.id));

            transformGraphPeople(graphSelectedItems, setSelectedItems, graphService);
        }, [graphSelectedItems, graphService]);

        return (
            <div className={className}>
                {label && <Label>{label}</Label>}
                <NormalPeoplePicker
                    inputProps={{
                        id: inputId,
                        type: 'search',
                    }}
                    componentRef={ref ?? undefined}
                    pickerSuggestionsProps={suggestionProps}
                    onEmptyResolveSuggestions={getMostRecentlyUsed}
                    onResolveSuggestions={onResolveSuggestions}
                    resolveDelay={250}
                    selectedItems={selectedItems}
                    onChange={onChange}
                    onItemSelected={onItemSelected}
                    removeButtonAriaLabel={APP_STRINGS.fabric.graph.removePersonAriaLabel}
                />
            </div>
        );
    }
);

const transformGraphPeople = (
    people: GraphPerson[],
    setter: (items: IPersonaProps[] | undefined) => void,
    graphService: IGraphService
) =>
    graphService
        .getUsersOrGroups(people)
        .then((results) =>
            setter(
                compactMap(people, (graphPerson) => {
                    const result = results[graphPerson.id];

                    return result ? userOrGroupToPersona(result) : undefined;
                })
            )
        )
        .catch(() => {});
