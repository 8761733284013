import { dataSource } from '../dataSource';
import { UDataSourceV2 } from '../dataSource/versions/2';

import { IDashboardV7 } from './7';

export interface IDashboardV8 extends Omit<IDashboardV7, '$schema' | 'dataSources'> {
    $schema: '8';
    dataSources: UDataSourceV2[];
}

/**
 * Updates tiles to v2
 */
export function up(prev: IDashboardV7): IDashboardV8 {
    return {
        ...prev,
        $schema: '8',
        dataSources: prev.dataSources.map(dataSource.v2.up),
    };
}
