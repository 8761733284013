import { UDataSource } from '../../core/domain';
import { APP_STRINGS } from '../../res';

/**
 * Parts of a data source required to resolve it
 */
export type DataSourceWorkingData = Omit<UDataSource, 'id' | 'title'>;

export function validateDataSourceName(name: string, existingTitles: Set<string>): string | undefined {
    const trimmedName = name.trim().toLowerCase();

    if (trimmedName.length < 1 && name.length > 0) {
        // Don't mark as no name unless there is only whitespace
        return APP_STRINGS.forms.dataSource.errors.noName;
    } else if (existingTitles.has(trimmedName)) {
        return APP_STRINGS.forms.dataSource.errors.duplicateName;
    }

    return undefined;
}
