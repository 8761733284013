import { DataTable } from './kustoClient';

export interface Memory {
    hits: number;
    misses: number;
    total: number;
}

export interface Disk {
    hits: number;
    misses: number;
    total: number;
}

export interface Cache {
    memory: Memory;
    disk: Disk;
    shards: Shards;
}

export interface ShardStats {
    hitbytes: number;
    missbytes: number;
    retrievebytes: number;
}

export interface Shards {
    hot: ShardStats;
    cold: ShardStats;
    bypassbytes: number;
}

export interface Cpu {
    user: string;
    kernel: string;
    'total cpu': string;
}

export interface ResourceUsage {
    cache: Cache;
    cpu: Cpu;
    memory: {
        peak_per_node: number;
    };
}

export interface DatasetStatistic {
    table_row_count: number;
    table_size: number;
}

export interface QueryResourceConsumption {
    ExecutionTime: number;
    resource_usage: ResourceUsage;
    dataset_statistics: DatasetStatistic[];
}

/**
 * extract query resource consumption data from json to a strongly typed object.
 */
export const getQueryResourceConsumption = (dataTableFrames: DataTable[]): QueryResourceConsumption | null => {
    const completionInfoFrame = dataTableFrames.filter((frame) => frame.TableKind === 'QueryCompletionInformation')[0];

    if (!completionInfoFrame) {
        return null;
    }

    const eventTypeNameIndex = 10;
    const payloadIndex = 11;
    // cannot have OneApiErorr here so we can cast it out.
    const rows = completionInfoFrame.Rows as { [key: string]: any }[];
    const queryStatsRow = rows.filter((row) => row[eventTypeNameIndex] === 'QueryResourceConsumption')[0];
    if (!queryStatsRow) {
        return null;
    }

    const resourceConsumption = JSON.parse(queryStatsRow[payloadIndex]) as QueryResourceConsumption;
    return resourceConsumption;
};
