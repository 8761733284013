import { EntityType } from './types';

export const isTable = (entityType: EntityType) => {
    switch (entityType) {
        case EntityType.Table:
        case EntityType.ExternalTable:
        case EntityType.MaterializedViewTable:
            return true;
        default:
            return false;
    }
};

/**
 * Determines the order of all the database folders (=folders that are immediate children of a database).
 */
export const sortedDatabaseFolders = [
    EntityType.FunctionsFolder,
    EntityType.MaterializedViewTableFolder,
    EntityType.TablesFolder,
    EntityType.ExternalTableFolder,
];

/** Type that includes all possible folders */
export type FolderEntityType =
    | EntityType.TablesFolder
    | EntityType.FunctionsFolder
    | EntityType.Folder
    | EntityType.MaterializedViewTableFolder
    | EntityType.ExternalTableFolder;

export const isFolder = (entityType: EntityType) => {
    return sortedDatabaseFolders.includes(entityType) || entityType === EntityType.Folder;
};

export const isFunction = (entityType: EntityType) => {
    return entityType === EntityType.Function;
};
