import * as React from 'react';
import { Stack, DefaultButton, TooltipHost } from 'office-ui-fabric-react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { WhiteShadowContent } from '../../../components/lib';
import { useCore } from '../../../core';
import { hrefCapturing } from '../../../common';
import { RouteState } from '../../../domain';

import { NoDataTileIcon } from './NoDataTileIcon';

import styles from './NoDataTile.module.scss';

export interface INoDataTileProps {
    onClick?: () => void;
}

const style: React.CSSProperties = {
    margin: `${APP_CONSTANTS.dashboardPage.grid.containerVerticalPadding}px ${APP_CONSTANTS.dashboardPage.grid.containerHorizontalPadding}px`,
};

export const NoDataTile: React.FC = () => {
    const history = useHistory<RouteState>();
    const core = useCore();
    const match = useRouteMatch<{ dashboardId: string }>();

    const onClick = hrefCapturing(() =>
        history.push(core.navUtil.newTile(match.params.dashboardId, 'query', history.location.hash.slice(1)))
    );

    return (
        <WhiteShadowContent className={styles.tile} style={style}>
            <NoDataTileIcon />
            <Stack horizontal verticalAlign="center" verticalFill horizontalAlign="center" onClick={onClick}>
                <TooltipHost content={APP_STRINGS.addComponent.addQueryDescription}>
                    <DefaultButton iconProps={{ iconName: 'Add' }} onClick={onClick}>
                        {APP_STRINGS.addComponent.addQuery}
                    </DefaultButton>
                </TooltipHost>
            </Stack>
        </WhiteShadowContent>
    );
};
