import { APP_CONSTANTS } from '../../res';

export interface RtdAccount {
    oid?: string;
    upn?: string;
    tenantId: string;
}

/**
 * Checks whether the account is a consumer facing account (MSA)
 */
export const isMSAAccount = (account: RtdAccount): boolean =>
    // MSA common
    account.tenantId === APP_CONSTANTS.tenants.msa ||
    // First party app global tenant
    account.tenantId === APP_CONSTANTS.tenants.firstPartyGlobal;

/**
 * Checks whether the account is a Microsoft internal account
 */
export const isMicrosoftInternalAccount = (account: RtdAccount): boolean =>
    account.tenantId === APP_CONSTANTS.tenants.microsoft;
