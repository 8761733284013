import { addSchemaIfMissing, migrate } from '../migrate';
import type { UserSettings } from '../../core/domain';

/**
 * TODO: User settings migrations were/are a mistake. Updating the users settings
 * schema blocks the users from access dashboards using any dashboards library
 * without the schema which isn't acceptable.
 */

import { userSettingMigrations } from './versions';
import type { UserSettingsLatestVersion, UserSettingsResponse, UserSettingsVersioned } from './versions';
export type { UserSettingsLatestVersion, UserSettingsResponse } from './versions';

const serializeUserSettings = (settings: UserSettings): UserSettingsLatestVersion => {
    const { favoriteDashboards, ...shared } = settings;
    return {
        $schema: '2',
        favoriteDashboards: [...favoriteDashboards],
        ...shared,
    };
};

const deserializeUserSettings = (settings: UserSettingsLatestVersion): UserSettings => {
    const { $schema: _, favoriteDashboards, ...shared } = settings;

    return {
        favoriteDashboards: new Set(favoriteDashboards),
        ...shared,
    };
};

export const convertUserSettingsFromBackend = (settings: UserSettingsResponse): UserSettings => {
    const versionedSettings: UserSettingsVersioned = addSchemaIfMissing(settings, '0');
    const latestVersion = migrate(userSettingMigrations, versionedSettings, '2');

    if (latestVersion.kind === 'err') {
        throw new Error("We're not adding new user setting migrations, so they should never fail");
    }
    // Ignoring warnings, we're not ever down migrating user settings
    return deserializeUserSettings(latestVersion.value.data);
};

export const convertUserSettingsToBackend = serializeUserSettings;
