/* eslint-disable @typescript-eslint/no-redeclare */

import { normalizeSpaces, ErrorDescription, ColumnType } from '@kusto/common';
import { Instance, ISimpleType, types } from 'mobx-state-tree';
import {
    ColumnFormatting,
    ExtendedVisualizationOptions,
    TableVisualizationOptionsSnapshot,
    VisualizationOptions,
} from '@kusto/visualizations';

/**
 * An ok string hashing function.
 * @param str input string
 * @param enable continues hashing by passing previous sub string hash
 */
const stringHashCode = function (str: string, progressive = 0): number {
    let hash = progressive;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        // tslint:disable-next-line
        hash = (hash << 5) - hash + char;
        // tslint:disable-next-line
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

/**
 * Contains all relevant data for a kusto query / command execution.
 */
export const RequestInfo = types
    .model('RequestInfo', {
        url: types.string,
        dbName: types.string,
        queryText: types.maybeNull(types.string),
        timeStarted: types.maybeNull(types.Date),
    })
    .actions((self) => ({
        setTimeStarted(time: Date) {
            self.timeStarted = time;
        },
    }))
    .views((self) => ({
        get normalizedQueryText() {
            return self.queryText ? normalizeSpaces(self.queryText) : null;
        },
    }))
    .views((self) => ({
        get hashCode() {
            return stringHashCode(self.url.concat(self.dbName).concat(self.normalizedQueryText || ''));
        },
    }));
// eslint-disable-next-line no-redeclare
export type RequestInfo = typeof RequestInfo.Type;

/**
 * Kusto response column. There usually be more than 1 per response.
 */
export const ColumnDef = types.model('ColumnDef', {
    headerName: types.string,
    field: types.string,
    dataType: types.maybe(types.string),
    // Note: this is optional, as it is not always present in the response for v1 control commands.
    // TODO: make the typing here better reflect the fact that this can be undefined.
    columnType: types.maybe(types.string) as ISimpleType<ColumnType>,
});

export const TableResult = types
    .model('TableResult', {
        rows: types.maybeNull(types.array(types.frozen())),
        columns: types.maybeNull(types.array(ColumnDef)),
        visualizationOptions: types.maybeNull(types.frozen<VisualizationOptions>()),
        tableName: types.maybeNull(types.string),
    })
    .views((self) => ({
        get isChart() {
            return (
                self.visualizationOptions &&
                self.visualizationOptions.Visualization &&
                self.visualizationOptions.Visualization !== 'table'
            );
        },
    }))
    .views((self) => {
        let _columnHash: number | undefined;
        return {
            // Lazy calculation of column hash and cache it
            get columnsHash() {
                if (_columnHash === undefined) {
                    _columnHash =
                        self.columns && self.columns.length > 0
                            ? self.columns
                                  .slice(0, 100)
                                  .reduce(
                                      (hash, col) =>
                                          stringHashCode(
                                              col.headerName,
                                              stringHashCode(col.columnType || col.dataType || '', hash)
                                          ),
                                      0
                                  )
                            : -1;
                }
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return _columnHash!;
            },
        };
    });

// eslint-disable-next-line no-redeclare
export type TableResult = Instance<typeof TableResult>;
export type QueryCompletionRows = typeof TableResult.Type.rows;
export type QueryCompletionColumns = typeof TableResult.Type.columns;
export type Column = typeof ColumnDef.Type;
export type MultiTableResult = TableResult & { queryIndex: number };

/**
 * Represents a response from kusto in a way that's easily digestible for display.
 * This contains the metadata but not the response data itself.
 */
export const QueryCompletionInfo = types
    .model('QueryCompletionInfo', {
        id: types.maybe(types.identifierNumber),
        request: types.maybe(RequestInfo),
        timeEnded: types.Date,
        isSuccess: types.boolean,
        haveCachedResults: types.optional(types.boolean, true),
        failureReason: types.frozen(),
        errorDescription: types.maybeNull(types.frozen<ErrorDescription>()),
        clientActivityId: types.string,
        queryResourceConsumption: types.optional(types.frozen(), null),
        resultInContext: types.optional(types.string, '0'),
    })
    .views((self) => ({
        get executionTimeInMilliseconds() {
            return (
                (self.request &&
                    self.request.timeStarted &&
                    self.timeEnded.getTime() - self.request.timeStarted.getTime()) ||
                null
            );
        },
        get resultIndex() {
            return parseInt(self.resultInContext, 10);
        },
    }))
    .actions((self) => ({
        setResultInContext(itemKey: string) {
            self.resultInContext = itemKey;
        },
        noCachedResults: () => (self.haveCachedResults = false),
    }));
// eslint-disable-next-line no-redeclare
export type QueryCompletionInfo = typeof QueryCompletionInfo.Type;

/**
 * Represents the results of a query. basically an array of tables with some convenience methods.
 */
export const QueryResults = types
    .model('QueryResults', {
        results: types.array(TableResult),
    })
    .views((self) => ({
        /**
         * Add Column Formatting configuration into resultToDisplay.visualizationOptions and
         * return resultToDisplay as a POJO (plain old JavaScript object - instead of a mobx model).
         * Why POJO? As long as it is a mobx model visualizationOptions in resultToDisplay can't be changed into type ExtendedVisualizationOptions.
         */
        pojoResultWithColumnFormatting(
            resultToDisplay: MultiTableResult,
            enableClickableLinks: boolean
        ): MultiTableResult {
            const columnFormatting: ColumnFormatting = {
                LinkConfig: {
                    clickableLinksColumns: enableClickableLinks ? [new RegExp('.')] : [],
                },
                ConditionalFormattingConfig: undefined /* currently only used in dashboards */,
            };

            const visualizationOptions: ExtendedVisualizationOptions = Object.assign(
                {},
                resultToDisplay.visualizationOptions,
                { ColumnFormatting: columnFormatting }
            );

            const resultToDisplayAsPlainObject = Object.assign({}, resultToDisplay, {
                visualizationOptions,
            }) as MultiTableResult;

            return resultToDisplayAsPlainObject;
        },

        get resultsToDisplay(): Array<MultiTableResult> {
            // Each result with a chart visualization gets duplicated into 2 results
            // - one with table visualization and one with chart visualizations,
            // since we want to have both in separate tabs.
            return self.results
                .map((result, i) =>
                    result.isChart
                        ? [
                              {
                                  ...result,
                                  queryIndex: i,
                                  isChart: true,
                                  columnsHash: result.columnsHash,
                              },
                              {
                                  ...result,
                                  queryIndex: i,
                                  visualizationOptions: {
                                      ...TableVisualizationOptionsSnapshot,
                                      IsQuerySorted: result.visualizationOptions?.IsQuerySorted,
                                  } as VisualizationOptions,
                                  isChart: false,
                                  columnsHash: result.columnsHash,
                              },
                          ]
                        : [
                              {
                                  ...result,
                                  queryIndex: i,
                                  isChart: result.isChart,
                                  columnsHash: result.columnsHash,
                              },
                          ]
                )
                .reduce((prev: Array<MultiTableResult>, curr) => prev.concat(curr), []);
        },
    }));
// eslint-disable-next-line no-redeclare
export type QueryResults = typeof QueryResults.Type;
