import * as React from 'react';
import { Spinner } from 'office-ui-fabric-react';

import { useCoreTelemetryException } from '../../domain/telemetry';
import { generateCid } from '../../domain';
import { useCore } from '../../core';
import { APP_STRINGS } from '../../res/App.strings';

interface IInternalErrorBoundaryProps {
    wrapError: (error: React.ReactNode) => React.ReactNode;
    onCatch: (exception: Error, additionalProperties?: Record<string, unknown>) => void;
    generateExceptionEmailUrl: (cid: string) => string;
}

export interface IErrorBoundaryState {
    error?: Error;
    cid?: string;
}

export class InternalRootErrorBoundary extends React.Component<IInternalErrorBoundaryProps, IErrorBoundaryState> {
    public readonly state: IErrorBoundaryState = {};

    static getDerivedStateFromError(error: Error): Partial<IErrorBoundaryState> {
        return { error };
    }

    componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
        // eslint-disable-next-line no-console
        console.error('RootErrorBoundary exception: ', error);

        const cid = generateCid('REB');

        this.props.onCatch(error, {
            cid,
        });

        this.setState({
            cid,
        });
    }

    render() {
        if (this.state.error) {
            return this.props.wrapError(
                <>
                    <h1>{APP_STRINGS.components.rootErrorBoundary.title}</h1>
                    <br />
                    {APP_STRINGS.components.rootErrorBoundary.subText}{' '}
                    <a href={this.props.generateExceptionEmailUrl(this.state.cid ?? '')}>
                        {APP_STRINGS.components.rootErrorBoundary.cidFailed}
                    </a>
                    <br />
                    {this.state.cid ? (
                        <span>
                            {APP_STRINGS.error.correlationIdLabelText}: {this.state.cid}
                        </span>
                    ) : (
                        <Spinner />
                    )}
                </>
            );
        }

        return this.props.children;
    }
}

export interface RootErrorBoundaryProps {
    /**
     * If provided, error is wrapped in this.
     */
    wrapError?: (error: React.ReactNode) => React.ReactNode;
}

export const RootErrorBoundary: React.FC<RootErrorBoundaryProps> = ({ children, wrapError = (e) => e }) => {
    const { telemetryService } = useCore();
    const onException = useCoreTelemetryException('RootErrorBoundary');

    return (
        <InternalRootErrorBoundary
            wrapError={wrapError}
            onCatch={onException}
            generateExceptionEmailUrl={telemetryService.generateExceptionEmailUrl}
        >
            {children}
        </InternalRootErrorBoundary>
    );
};
