/**
 * Recursively examines the provided elements and its parents for the selected `className`
 * @param element The element with the ancestors to consider
 * @param className The CSS class name to search for
 * @param maxIterations The maximum number of parents to traverse
 */
export const doesRecursiveParentsContainClass = (element: Element, className: string, maxIterations = 5): boolean => {
    let node: Element | null = element;
    let i = 0;

    while (node && i < maxIterations) {
        if (node.classList.contains(className)) {
            return true;
        }

        node = node.parentElement;
        i += 1;
    }

    return false;
};
