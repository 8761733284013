import { tile } from '../tile';
import { ITileV5 } from '../tile/versions/5';

import { IDashboardV6 } from './6';

export interface IDashboardV7 extends Omit<IDashboardV6, '$schema' | 'tiles'> {
    $schema: '7';
    tiles: ITileV5[];
}

/**
 * Updates tiles to v5
 */
export function up(prev: IDashboardV6): IDashboardV7 {
    return {
        ...prev,
        $schema: '7',
        tiles: prev.tiles.map((t) => tile.v5.up(t)),
    };
}
