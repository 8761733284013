import { SetStateAction, useRef, useState } from 'react';

/**
 * Like `useState`, but with an additional `getState` function returned.
 *
 * Having a `getState` function is important to avoid the stale closure problem.
 * That's to say, a callback shouldn't rely on state on the `state` returned
 * from `useState` most of the time, because it may not be the latest state
 * value.
 */
export function usePullState<T>(initialState: T | (() => T)): [T, (state: SetStateAction<T>) => void, () => T] {
    const [reactiveState, setReactiveState] = useState(initialState);
    const ref = useRef<{
        state: T;
        getState: () => T;
        setState: (state: SetStateAction<T>) => void;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }>(undefined as any);

    if (ref.current === undefined) {
        ref.current = {
            state: reactiveState,
            getState: () => ref.current.state,
            setState: (state) => {
                if (state instanceof Function) {
                    state = state(ref.current.state);
                }

                ref.current.state = state;
                setReactiveState(state);
            },
        };
    }

    return [reactiveState, ref.current.setState, ref.current.getState];
}
