import React from 'react';
import { IDropdownOption, IRenderFunction } from 'office-ui-fabric-react';

import { APP_CONSTANTS, APP_STRINGS } from '../../../../../res';
import { valueImplFor, RtdValue, TimeZone, useTimeZone, ValueImpl } from '../../../../../domain';
import { EppStaticDropdownOptions, EppStaticOption } from '../../../reducer';
import { RTDDropdown, RTDDropdownOption } from '../../../../../components';
import { EditParameterPageDispatch } from '../../../types';

import pageStyles from '../../../EditParameterPage.module.scss';

function getOptionText<T extends unknown>(
    rawOption: EppStaticOption,
    impl: ValueImpl<T, string>,
    timeZone: TimeZone
): string {
    if (rawOption.displayText !== undefined) {
        return rawOption.displayText;
    }

    const value = impl.editStringToValue(rawOption.value, timeZone);

    if (value.kind === 'ok') {
        return impl.valueToDisplayString(value.value, timeZone);
    }

    return rawOption.value;
}

function prefixOption(id: string): string {
    return `v-${id}`;
}

const onRenderTitle: IRenderFunction<IDropdownOption[]> = (options, defaultRender) => {
    if (options?.some((o) => o.key === APP_CONSTANTS.parameter.allSelection)) {
        return <>{APP_STRINGS.domain.parameter.selection.all}</>;
    }
    return (options && defaultRender?.(options)) ?? null;
};

export interface StaticParamDefaultValueProps {
    dropdownState: EppStaticDropdownOptions;
    dispatch: EditParameterPageDispatch;
    dataType: RtdValue.BasicType;
}

export const StaticParamDefaultValue: React.FC<StaticParamDefaultValueProps> = ({
    dropdownState,
    dispatch,
    dataType,
}) => {
    const timeZone = useTimeZone();

    const multiSelect = dropdownState.selectionKind === 'array-null';

    const options = React.useMemo(() => {
        const innerOptions: RTDDropdownOption[] = [];

        if (dropdownState.selectionKind !== 'scalar') {
            innerOptions.push({
                key: 'null',
                text: APP_STRINGS.domain.parameter.selection.all,
                data: null,
            });
        }

        const impl = valueImplFor(dataType);

        for (const option of dropdownState.options.values()) {
            innerOptions.push({
                key: prefixOption(option.id),
                text: getOptionText(option, impl as ValueImpl<unknown, string>, timeZone),
                data: option.id,
            });
        }
        return innerOptions;
    }, [dataType, dropdownState.options, dropdownState.selectionKind, timeZone]);

    const selected = React.useMemo(() => {
        if (multiSelect) {
            let selectedKeys: null | string[];
            if (dropdownState.defaultValue === undefined) {
                selectedKeys = null;
            } else if (dropdownState.defaultValue === null) {
                selectedKeys = ['null'];
            } else {
                selectedKeys = [...dropdownState.defaultValue].map(prefixOption);
            }
            return { selectedKeys };
        }

        let selectedKey: null | string;

        if (dropdownState.defaultValue === undefined) {
            selectedKey = null;
        } else if (dropdownState.defaultValue === null) {
            selectedKey = 'null';
        } else {
            selectedKey = prefixOption(dropdownState.defaultValue.values().next().value);
        }

        return { selectedKey };
    }, [dropdownState.defaultValue, multiSelect]);

    const onChange = React.useCallback(
        (_event: unknown, option?: IDropdownOption) => {
            if (option) {
                const value: null | string = option.data;
                dispatch({ type: 'selectStaticDropdownDefaultValueOption', selected: value });
            }
        },
        [dispatch]
    );

    return (
        <RTDDropdown
            onRenderTitle={onRenderTitle}
            className={pageStyles.input}
            required
            label={APP_STRINGS.editParameterPage.defaultValue}
            options={options}
            multiSelect={multiSelect}
            placeholder={APP_STRINGS.editParameterPage.selection.noDefault}
            {...selected}
            onChange={onChange}
        />
    );
};
