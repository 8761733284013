import * as React from 'react';
import { IPersonaProps } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import { useCore } from '../../../../core';

export interface PersonaImageFetchWrapperProps {
    persona: IPersonaProps;
    oid: string | undefined;
    defaultRenderer: (props: IPersonaProps) => JSX.Element | null;
}

export const PersonaImageFetchWrapper: React.FC<PersonaImageFetchWrapperProps> = ({
    persona,
    oid,
    defaultRenderer,
}) => {
    const { graphService } = useCore();

    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (oid) {
            graphService
                .getUserImage(oid, '48x48')
                .then(setImageUrl)
                // Do nothing on error
                .catch(() => {});
        }
    }, [oid, graphService]);

    return (
        defaultRenderer(
            imageUrl
                ? {
                      ...persona,
                      imageUrl,
                  }
                : persona
        ) ?? null
    );
};
