import * as React from 'react';
import { MessageBar, MessageBarType, IconButton } from 'office-ui-fabric-react';

import styles from './ErrorBanner.module.scss';

export interface IErrorBannerItem<T> {
    message: string;
    data?: T;
}

export interface IErrorBannerProps {
    errors: Array<IErrorBannerItem<unknown>>;

    onDismiss?: (error: IErrorBannerItem<unknown>) => void;
}

export const ErrorBanner: React.FC<IErrorBannerProps> = ({ errors, onDismiss }) => {
    return (
        <MessageBar
            messageBarType={MessageBarType.error}
            styles={{
                iconContainer: {
                    lineHeight: '32px',
                },
                innerText: {
                    width: '100%',
                },
            }}
        >
            {errors.map((error) => {
                return (
                    <div
                        className={!!onDismiss ? `${styles.item} ${styles.dismissItem}` : styles.item}
                        key={error.message}
                    >
                        {error.message}
                        {!!onDismiss && (
                            <IconButton iconProps={{ iconName: 'ChromeClose' }} onClick={() => onDismiss(error)} />
                        )}
                    </div>
                );
            })}
        </MessageBar>
    );
};
