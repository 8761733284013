import * as React from 'react';
import { useMemo } from 'react';
import { Spinner, SpinnerSize, Modal, IModalProps } from 'office-ui-fabric-react';

import { useResourceMetadata } from '../../resource/hooks/useResourceMetadata';
import { IResourceState, IResourceMetadataStore } from '../../resource/metadata/types';
import { ResourceTypes } from '../../resource/types';
import { APP_STRINGS } from '../../res';
import { useGlobalSelector } from '../../store/redux/context';
import { IReduxState } from '../../store/redux/types';
import { removeDashboardDialogAction } from '../Forms/dashboard/RemoveDashboardDialog';

import styles from './DashboardLoading.module.scss';
import pageContentStyles from './PageContent.module.scss';
import { useGlobalLoadingStatus } from './globalLoadingContext';

export type IMetadataCount = {
    [T in ResourceTypes]?: number;
};

const supportedLoadingTypes: ResourceTypes[] = [
    'getDashboard',
    'patchDashboard',
    'deleteDashboard',
    'createDashboard',
    'forgetDashboard',
];

const renderLocalModalProps: IModalProps = {
    layerProps: { hostId: pageContentStyles.contentWrapper },
    overlay: { isDarkThemed: false },
};

const getMetaDataCounts = (allMetadata: IResourceMetadataStore): IMetadataCount => {
    const sums: IMetadataCount = {};

    for (const type of Object.keys(allMetadata) as ResourceTypes[]) {
        const metadataOfType = allMetadata[type];

        if (!metadataOfType) {
            continue;
        }

        for (const id of Object.keys(metadataOfType)) {
            const metadata = metadataOfType[id];

            if (metadata.status === IResourceState.loading) {
                const currentSum = sums[type];

                sums[type] = currentSum !== undefined ? currentSum + 1 : 1;
            }
        }
    }

    return sums;
};

const getHighestPriorityLoadingMessage = (
    metadataCount: IMetadataCount,
    deleteModalOpen: boolean
): string | undefined => {
    if (metadataCount.patchDashboard || metadataCount.createDashboard) {
        return APP_STRINGS.dashboardLoading.savingDashboard;
    } else if (metadataCount.deleteDashboard && !deleteModalOpen) {
        return APP_STRINGS.dashboardLoading.deletingDashboard;
    } else if (metadataCount.getDashboard) {
        return APP_STRINGS.dashboardLoading.loadingDashboard;
    } else if (metadataCount.forgetDashboard) {
        return APP_STRINGS.dashboardLoading.forgetDashboard;
    }

    return;
};

const deleteModalOpenSelector = (s: IReduxState) => s.shared.activeForms[removeDashboardDialogAction.key] !== undefined;

export const DashboardLoading: React.FC = () => {
    const allMetadata = useResourceMetadata(supportedLoadingTypes);
    const renderGlobal = useGlobalLoadingStatus();
    // If (when?) we end up needing to control global loading based on other stuff
    // we should make code call into a global loading manager, instead of global
    // loading observing out state
    const deleteModalOpen = useGlobalSelector(deleteModalOpenSelector);

    const loadingMessage = useMemo(() => {
        const metadataCounts = getMetaDataCounts(allMetadata);
        return getHighestPriorityLoadingMessage(metadataCounts, deleteModalOpen);
    }, [allMetadata, deleteModalOpen]);

    if (!loadingMessage) {
        return null;
    }

    return (
        <Modal isOpen={true} {...(!renderGlobal ? renderLocalModalProps : undefined)} className={styles.popup}>
            <Spinner label={loadingMessage} size={SpinnerSize.large} />
        </Modal>
    );
};
