/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { types, getRoot, flow, Instance } from 'mobx-state-tree';
import { dependencies } from '../../dependencies';
import { Cluster, Database } from '../cluster';
import { RootStore, Notification } from '../rootStore';
import { getTelemetryClient } from '../../utils/telemetryClient';

import { EntityType } from '../../common/types';
import { size } from 'lodash';
import { clustersToRowData } from '../../components/connectionExplorer/RowDataTypes/LazyLoadingFlow';

const { trackEvent } = getTelemetryClient({
    component: 'connectionPane',
    flow: '',
});

const ClusterOrDatabase = types.union(Cluster, Database);
// eslint-disable-next-line no-redeclare
type ClusterOrDatabase = typeof ClusterOrDatabase.Type;

export const ClusterOrDatabaseSafeReference = types.safeReference(ClusterOrDatabase);
// eslint-disable-next-line no-redeclare
export type ClusterOrDatabaseSafeReference = typeof ClusterOrDatabaseSafeReference.Type;

export const ConnectionPane = types
    .model('ConnectionPane', {
        favorites: types.optional(types.map(ClusterOrDatabaseSafeReference), {}),
        connections: types.map(Cluster),
        entityInContext: types.maybe(ClusterOrDatabaseSafeReference),
        showOnlyFavorites: types.optional(types.boolean, false),
        expandedEntities: types.optional(types.map(ClusterOrDatabaseSafeReference), {}),
    })
    .actions((self) => ({
        updateExpanded(entity: Cluster | Database, expanded: boolean) {
            if (expanded) {
                self.expandedEntities.put(entity);
            } else {
                self.expandedEntities.delete(entity.id);
            }
        },
        clearExpanded() {
            self.expandedEntities.replace({});
        },
        getEntityInContextByName(clusterName: string, databaseName?: string): Database | Cluster | undefined {
            let entity: Cluster | Database | undefined = self.connections.get(clusterName);

            if (!entity) {
                return;
            }

            if (databaseName && entity.databases) {
                entity = entity.databases.get(`${clusterName}/${databaseName}`);
            }

            return entity;
        },
        /**
         * id: entity's ID. e.g. help/samples
         * clusterName: if empty the clusterName will be extracted from the ID.
         * TODO: why not always fetch the cluster name from the id?
         */
        getEntityFromId(id: string, clusterName?: string) {
            if (!id) {
                return;
            }

            const isFolder = id.startsWith('$folder_');
            if (isFolder) {
                return;
            }

            const isFunction = id.startsWith('$function_');
            const path = isFunction ? id.substr('$function_'.length).split('/') : id.split('/');
            const _clusterName = clusterName ?? path[0];

            const cluster = self.connections.get(_clusterName);

            if (path[1] === undefined) {
                return cluster;
            }
            const databaseName = path[1];

            const database = cluster?.databases.get(`${_clusterName}/${databaseName}`);
            if (!database) {
                return;
            }

            if (path[2] === undefined) {
                return database;
            }

            if (isFunction) {
                const functionName = path[2];
                const fn = database!.functions.get(`$function_${_clusterName}/${databaseName}/${functionName}`);
                return fn;
            }

            const tableName = path[2];
            const table = database!.tables.get(`${_clusterName}/${databaseName}/${tableName}`);

            if (path[3] === undefined) {
                return table;
            }
            const columnName = path[3];

            const column = table!.columns[`${_clusterName}/${databaseName}/${tableName}/${columnName}`];
            return column;
        },
        openInWebUI(relativePath?: string) {
            trackEvent('openInWebUi', {
                flow: 'openInWebUi',
                relativePath: relativePath ?? '',
            });
            const authProvider = dependencies.authProvider;

            // Best effort try to extract the user from our token (if we have one).
            // If we do - inject the tenant name as a query param so that external portal will know what's the preferred
            // tenant to provide to AAD when requesting its own token.
            const user = authProvider.getCachedUser();
            const tenant = user.profile && user.profile.tid;
            const queryParams = tenant ? `?tenant=${tenant}` : '';
            const path = relativePath ?? window.location.pathname;
            window.open(`${window.location.protocol}//${window.location.host}${path}${queryParams}`);
        },
        /**
         * Refetch schema for the database in context.
         * If the entity in context is a cluster or if the cluster's schema is already cached, do nothing.
         */
        refetchSchemaForDatabaseInContextIfNeeded() {
            const entity = self.entityInContext;
            if (!entity) {
                return;
            }

            if (entity && entity.entityType === EntityType.Database && entity.fetchState === 'notStarted') {
                entity.fetchCurrentSchema(true, false);
            }
        },
    }))
    .actions((self) => {
        /**
         * Set the connection pane entity in context and
         * follow up integrity updates:
         *      - fetch database schema if needed
         *      - more ?
         */
        function setEntityInContextInternal(entity: Cluster | Database | undefined) {
            self.entityInContext = entity;
            self.refetchSchemaForDatabaseInContextIfNeeded();
        }

        return {
            setEntityInContextByObject(entity?: Cluster | Database) {
                trackEvent('setEntityInContextByObject', {
                    flow: 'setEntityInContextByObject',
                    id: entity ? entity.id : 'none',
                });

                setEntityInContextInternal(entity);
            },
            /**
             * entity in context entity can be either a cluster or a database.
             * if second parameter is not undefined - it's a database that's in context.
             */
            setEntityInContextByName(clusterName: string, databaseName?: string): Database | Cluster | undefined {
                trackEvent('setEntityInContextByName', {
                    flow: 'setEntityInContextByName',
                    clusterName,
                    databaseName: databaseName ? databaseName : '',
                });

                const entity = self.getEntityInContextByName(clusterName, databaseName);

                if (entity && entity !== self.entityInContext) {
                    setEntityInContextInternal(entity);
                }

                return entity;
            },
            setNotification(notification: Notification) {
                (getRoot(self) as RootStore).setNotification(notification);
            },
            addConnection: flow(function* addConnection(
                clusterName: string,
                connectionString: string,
                initialCatalog?: string,
                alias?: string
            ) {
                trackEvent('addConnection', { flow: 'addConnection', clusterName });
                try {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const test = new URL(connectionString);
                } catch {
                    return;
                }
                const newCluster: Cluster = Cluster.create({
                    alias: alias,
                    name: clusterName,
                    connectionString: connectionString,
                    id: clusterName,
                    initialCatalog,
                });
                self.connections.put(newCluster);

                // set the new cluster to be the entity in context both for the connection tree and for the current tab.
                setEntityInContextInternal(newCluster);
                const tabs = (getRoot(self) as RootStore).tabs;
                if (tabs.tabInContext) {
                    tabs.tabInContext.setEntityInContext(newCluster);
                }

                yield newCluster!.fetchCurrentSchema(false, false);
                return newCluster;
            }),
            removeConnection(clusterId: string) {
                trackEvent('removeConnection', {
                    flow: 'removeConnection',
                    clusterId,
                });

                // if entity in context is in cluster to be removed - change it otherwise
                // we'll have a dangling reference.

                // Find the first cluster that isn't the one we're going to remove (or undefined if that's the only one).
                const newEntityInContext = Array.from(self.connections.values()).filter(
                    (conn) => conn.id !== clusterId
                )[0];

                // Change entity in context in connection pane to the new one
                if (self.entityInContext && self.entityInContext.id.split('/')[0] === clusterId) {
                    setEntityInContextInternal(newEntityInContext);
                }

                // Change entity in context in all tabs that point to it.
                const tabs = (getRoot(self) as RootStore).tabs;
                tabs.tabs.forEach((tab) => {
                    if (tab.entityInContext && tab.entityInContext.id.split('/')[0] === clusterId) {
                        tab.setEntityInContext(newEntityInContext);
                    }
                });

                const favorites = self.favorites;
                // First delete favorites entry (since it refers to the connection entity).
                const entitiesToDeleteFromFavorites = Array.from(favorites.values()).filter((fav) => {
                    // This is a weak entity reference so can actually be undefined
                    if (!fav) {
                        return false;
                    }
                    const idParts = fav.id.split('/');
                    return idParts[0] === clusterId;
                });

                // We're asserting this cannot be undefined since we've just filtered all undefined values.
                entitiesToDeleteFromFavorites.forEach((entity) => favorites.delete(entity!.id));
                self.connections.delete(clusterId);
            },
            addToFavorites(entity: Cluster | Database) {
                trackEvent('addToFavorites', {
                    flow: 'addToFavorites',
                    id: entity.id,
                    name: entity.name,
                });
                entity.setIsFavorite(true);
                self.favorites.put(entity);
            },
            removeFromFavorites(clusterOrDatabase: Cluster | Database | undefined) {
                trackEvent('removeFromFavorites', {
                    flow: 'removeFromFavorites',
                });
                if (clusterOrDatabase) {
                    clusterOrDatabase.setIsFavorite(false);
                    self.favorites.delete(clusterOrDatabase.id);
                }
            },
            toggleFavorites() {
                trackEvent('toggleFavorites', { flow: 'toggleFavorites' });
                self.showOnlyFavorites = !self.showOnlyFavorites;
            },
            refreshEntity(clusterOrDatabase?: Cluster | Database) {
                const entity = clusterOrDatabase ?? self.entityInContext;

                if (!entity) {
                    return;
                }

                if (entity.entityType !== EntityType.Cluster && entity.entityType !== EntityType.Database) {
                    throw new Error(`getContextMenuItems: unexpected entity type ${entity.entityType}`);
                }

                const dbOrCluster = entity as Database | Cluster;

                dbOrCluster.fetchCurrentSchema(false, false);
                return dbOrCluster;
            },
        };
    })
    .views((self) => ({
        /** returns a mapping between clusters' aliases and their name*/
        aliasesToNameMapping: (): { [alias: string]: string } => {
            const mapping = {} as { [alias: string]: string };
            self.connections.forEach((connection) => {
                if (connection.alias) {
                    mapping[connection.alias] = connection.name;
                }
            });

            return mapping;
        },
        countClustersWithDescendant: (): {
            clusters: number;
            databases: number;
            functions: number;
            tables: number;
            columns: number;
        } => {
            let [clusters, databases, tables, functions, columns] = [0, 0, 0, 0, 0];
            self.connections.forEach((cluster) => {
                clusters++;
                databases += cluster.databases.size;
                cluster.databases.forEach((database) => {
                    functions += database.functions.size;
                    tables += database.tables.size;
                    database.tables.forEach((table) => {
                        columns += size(table.columns);
                    });
                });
            });
            return { clusters, databases, functions, tables, columns };
        },
        getRowData: (expanded: { [id: string]: boolean }) =>
            clustersToRowData(
                Array.from(self.connections.values()),
                self.showOnlyFavorites,
                self.entityInContext ? [self.entityInContext] : [],
                expanded
            ),
    }));
// eslint-disable-next-line no-redeclare
export type ConnectionPane = Instance<typeof ConnectionPane>;
