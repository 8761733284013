import { assertNever } from 'office-ui-fabric-react';

import { formatLiterals } from '../../common/string';
import { MigrationError } from '../../migration';
import { APP_STRINGS } from '../../res';

/**
 * Kept separate from migrations so migrations doesn't take a dependency on React or copy.
 */
export function formatMigrationError(error: MigrationError) {
    switch (error.kind) {
        case 'missing-migration':
            return formatLiterals(APP_STRINGS.migration.errors.missingMigration, error);
        case 'unexpected-crash':
            return APP_STRINGS.migration.errors.unexpectedCrash;
        default:
            assertNever(error);
    }
}
