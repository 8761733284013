import * as React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { IContextualMenuItem } from 'office-ui-fabric-react';
import { useInView } from 'react-intersection-observer';

import {
    VisualOptions,
    newKustoQuery,
    VisualDisplayedResultsCounts,
    ITile,
    RGLLayout,
    ParameterValue,
    useExecuteQuery,
    buildKustoVariableOutput,
    formatKustoQuery,
} from '../../../../domain';
import { DataVisual, InvalidLayoutError, Tile } from '../../../../components';
import { err, hrefCapturing, ok } from '../../../../common';
import { UDataSource } from '../../../../core/domain';
import { APP_STRINGS, APP_CONSTANTS } from '../../../../res';
import { DashboardLoaded, useDashboardStore } from '../../../../store';
import { MaybeQuery } from '../../../../components/QueryEditing';

import styles from '../DashboardTiles.module.scss';
import { ExploreActionConfig } from '../../../..';
import { useCore } from '../../../../core';

function useParseExploreConfig(
    exploreConfig: undefined | ExploreActionConfig,
    dataSource: undefined | UDataSource,
    tile: ITile,
    usedParameterValues: undefined | ParameterValue[]
) {
    return React.useMemo(() => {
        if (exploreConfig === undefined || dataSource === undefined || usedParameterValues === undefined) {
            return;
        }

        const query = formatKustoQuery(tile.query, buildKustoVariableOutput(usedParameterValues));
        const url = exploreConfig.generateExploreUrl(query, dataSource);
        const hostOnExplore = exploreConfig.onExplore;
        const innerDataSource = dataSource;
        function onExplore() {
            hostOnExplore(query, innerDataSource);
        }

        return { onExplore, url };
    }, [exploreConfig, tile.query, dataSource, usedParameterValues]);
}

const noDataSourceError = err({ title: APP_STRINGS.dashboardPage.unableToResolveDataSourceError });

export interface DashboardTileProps {
    tile: ITile;
    isEditing: boolean;
    menuItems: IContextualMenuItem[];
    layout: RGLLayout;
    dashboardLoaded: DashboardLoaded;
}

export const DashboardTile: React.FC<DashboardTileProps> = observer(function QueryTile({
    tile,
    menuItems,
    isEditing,
    layout,
    dashboardLoaded,
}) {
    const core = useCore();

    const dashboardStore = useDashboardStore();
    const htmlErrorId = `dashboard-tile-error--${tile.id}`;
    const [resultCounts, setResultCounts] = React.useState<VisualDisplayedResultsCounts | null>(null);

    const dataSource =
        tile?.dataSourceId === undefined ? undefined : dashboardLoaded.dataSourcesRecord[tile.dataSourceId];

    const usedParameterValues = React.useMemo(
        () => computed(() => dashboardLoaded.selectedParameters.resolveParameterValues(tile.usedVariables)),
        [dashboardLoaded, tile.usedVariables]
    ).get();

    const isEditMode = dashboardLoaded.changes;

    const parsedExploreConfig = useParseExploreConfig(
        core.hostConfig.exploreAction,
        dataSource,
        tile,
        usedParameterValues.value
    );

    const query = React.useMemo((): MaybeQuery => {
        if (!dataSource) {
            return noDataSourceError;
        }
        if (usedParameterValues.kind === 'err') {
            return usedParameterValues;
        }
        return ok(newKustoQuery(tile.query, dataSource, tile.id, usedParameterValues.value));
    }, [dataSource, tile.query, usedParameterValues, tile.id]);

    const visualOptions: VisualOptions = React.useMemo(
        () => ({
            ...APP_CONSTANTS.visuals.defaultVisualOptions,
            ...tile.visualOptions,
        }),
        [tile]
    );

    const [ref, inView] = useInView();

    const streamingResult = useExecuteQuery(dashboardStore, query, {
        forceUpdateOnQueryChange: false,
        preventSubscription: !inView,
        isPersistent: true,
    });

    const menuItemsWithExplore = React.useMemo((): IContextualMenuItem[] => {
        if (parsedExploreConfig && !isEditMode) {
            return [
                ...menuItems,
                {
                    key: 'explore',
                    name: APP_STRINGS.dashboardPage.tileMenu.explore,
                    href: parsedExploreConfig.url,
                    onClick: hrefCapturing(parsedExploreConfig.onExplore),
                },
            ];
        }
        return menuItems;
    }, [isEditMode, menuItems, parsedExploreConfig]);

    return (
        <Tile
            pageState={dashboardLoaded}
            tileId={tile.id}
            visualOptions={visualOptions}
            queryHash={query.kind === 'ok' ? query.value.queryHash : undefined}
            editing={isEditing}
            title={tile.title}
            menuItems={menuItemsWithExplore}
            visualType={tile.visualType}
            resultCounts={resultCounts ?? undefined}
            htmlErrorId={htmlErrorId}
            className={styles.tile}
        >
            <div ref={ref} className={styles.queryVisual}>
                {!layout.valid ? (
                    <InvalidLayoutError layout={layout} />
                ) : (
                    <DataVisual
                        selectedParameters={dashboardLoaded.selectedParameters}
                        visualType={tile.visualType}
                        queryResult={streamingResult}
                        visualOptions={visualOptions}
                        setResultCounts={setResultCounts}
                        htmlErrorId={htmlErrorId}
                    />
                )}
            </div>
        </Tile>
    );
});
