import * as React from 'react';
import { useMemo } from 'react';
import { useCore } from './useCore';

/**
 * Provides an HOC for recording activity on a given React component.
 * **NOTE**: This HOC wraps a DIV for providing certain interaction tracking. If this affects styling,
 * make sure to apply a custom `className`
 * @param component The React FunctionComponent to wrap
 * @param componentName The name of the component to be sent in the telemetry
 * @param className A CSS class name to apply to the HOC's DIV
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const withTelemetry = <T extends {}>(
    component: React.FunctionComponent<T>,
    componentName: string,
    className?: string
) => {
    return (props: T) => {
        const { telemetryService } = useCore();

        const Component = useMemo(
            () =>
                telemetryService.withTelemetry(
                    component,
                    componentName,
                    className ? `${componentName} ${className}` : componentName
                ),
            [telemetryService]
        );

        return <Component {...props} />;
    };
};
