import * as React from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import { DefaultButton, Stack, PrimaryButton, Checkbox } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';
import { action, autorun } from 'mobx';
import type * as H from 'history';

import { PageContent, IStaticBreadcrumbItem, FullHeightWhiteWrapper } from '../../components/PageContent';
import { useGlobalDispatch } from '../../store/redux';
import { useThunkReducer } from '../../common';
import { APP_STRINGS } from '../../res';
import { ParameterConfig, useDashboardBreadcrumbs, useTimeZone } from '../../domain';
import { useCore } from '../../core';
import { manageParametersPanelAction } from '../../components/Forms';
import { DashboardLoaded, DashboardChanges, useDashboardStore } from '../../store/dashboard';

import { ParameterSelectionTypeDropdown } from './components/SelectionTypeDropdown';
import { ParameterDataTypeDropdown } from './components/DataTypeDropdown';
import { ParameterVariableNameTextFields } from './components/VariableNameTextFields';
import { DataSourceAndValueConfig } from './components/DataSourceAndValueConfig';
import { ParameterDisplayNameTextField } from './components/DisplayNameTextField';

import { useOnEditParameterDone, eppDirtyMessage } from './useOnEditParameterDone';

import { editParameterPageReducerFunc } from './reducer/reducer';
import { initialState } from './reducer/constants';

import styles from './EditParameterPage.module.scss';

export interface EditParameterPageProps {
    incomingParameterId: string;
    dashboardState: DashboardLoaded;
    dashboardChanges: DashboardChanges;
}

export const EditParameterPage: React.FC<EditParameterPageProps> = observer(function EditParameterPage({
    dashboardState,
    dashboardChanges,
    incomingParameterId,
}) {
    const store = useDashboardStore();
    const core = useCore();

    const [dispatch] = useGlobalDispatch();
    const disableBlockNavigation = React.useRef(false);

    const incomingParameter: undefined | ParameterConfig =
        incomingParameterId === 'new' ? undefined : dashboardState.parametersRecord[incomingParameterId];

    const [state, localDispatch, getState] = useThunkReducer(editParameterPageReducerFunc, initialState);

    const { shouldPin, union, activeError, errors, displayName, variableNames } = state;

    const additionalBreadcrumbItems: IStaticBreadcrumbItem[] = useMemo(
        () => [
            {
                key: 'parameter',
                text: incomingParameter
                    ? APP_STRINGS.editParameterPage.editTitle
                    : APP_STRINGS.editParameterPage.addTitle,
            },
        ],
        [incomingParameter]
    );

    const onDashboardClick = useMemo(
        () =>
            action(() => {
                const noChangesOrUserAgreed =
                    eppDirtyMessage(incomingParameter, getState) === undefined ||
                    window.confirm(APP_STRINGS.edits.unsavedChanges);
                // Has changes, user must approve
                // We have to display a custom prompt, since this doesn't involve a route change
                if (noChangesOrUserAgreed) {
                    dashboardChanges.editParameter = undefined;
                }
            }),
        [dashboardChanges, getState, incomingParameter]
    );

    const breadcrumb = useDashboardBreadcrumbs({
        store,
        dashboardId: dashboardState.meta.id,
        customOnDashboardClick: onDashboardClick,
        additionalItems: additionalBreadcrumbItems,
    });

    const onChangeShouldPin = useCallback(
        (_: unknown, checked = true) =>
            localDispatch({
                type: 'shouldPin',
                shouldPin: checked,
            }),
        [localDispatch]
    );

    const onCancel = useMemo(
        () =>
            action(() => {
                disableBlockNavigation.current = true;
                dashboardChanges.editParameter = undefined;
                // Reopen manageParameters
                dispatch(manageParametersPanelAction());
            }),
        [dashboardChanges, dispatch]
    );

    const onDone = useOnEditParameterDone(disableBlockNavigation, dashboardState, dashboardChanges, dispatch, getState);

    useEffect(
        () =>
            autorun(() => {
                if (incomingParameter) {
                    localDispatch({
                        type: 'initializeIncomingParameter',
                        parameter: incomingParameter,
                        isPinned: dashboardChanges.pinnedParameters.has(incomingParameter.id),
                    });
                }
            }),
        [dashboardChanges, incomingParameter, localDispatch]
    );

    const parameters = dashboardState.parameters;

    useEffect(() => {
        localDispatch({ type: 'setExistingParameters', parameters });
    }, [parameters, localDispatch]);

    const timeZone = useTimeZone();
    useEffect(() => localDispatch({ type: 'setTimeZone', timeZone }), [timeZone, localDispatch]);

    // On unmount, clear all non-persistent queries
    useEffect(() => () => core.queryService.deregisterAllNonPersistentQueries(), [core]);

    useEffect(() => {
        return core.history.block(
            action((location: undefined | H.Location, _: unknown) => {
                if (disableBlockNavigation.current || location?.pathname === core.history.h.get()?.location.pathname) {
                    return;
                }
                return eppDirtyMessage(incomingParameter, getState);
            })
        );
    }, [core, getState, incomingParameter]);

    return (
        <FullHeightWhiteWrapper>
            <PageContent breadcrumbs={breadcrumb}>
                <div className={styles.formWrapper}>
                    <div className={styles.form}>
                        <ParameterDisplayNameTextField
                            className={styles.input}
                            displayName={displayName}
                            placeholderVariableName={variableNames.length > 0 ? variableNames[0] : undefined}
                            error={errors.displayNameError}
                            dispatch={localDispatch}
                        />
                        <ParameterSelectionTypeDropdown
                            className={styles.input}
                            state={union}
                            dispatch={localDispatch}
                        />
                        <ParameterVariableNameTextFields
                            className={styles.input}
                            variableNames={variableNames}
                            errors={errors.variableNameErrors}
                            dispatch={localDispatch}
                        />
                        {union.kind === 'basic' && (
                            <ParameterDataTypeDropdown
                                className={styles.input}
                                state={union}
                                dispatch={localDispatch}
                            />
                        )}
                        <Checkbox
                            className={styles.checkbox}
                            label={APP_STRINGS.editParameterPage.buttons.autoPin}
                            checked={shouldPin}
                            onChange={onChangeShouldPin}
                        />
                        <DataSourceAndValueConfig state={state} dispatch={localDispatch} getState={getState} />
                    </div>
                </div>
                <Stack className={styles.footer} horizontal>
                    <PrimaryButton onClick={onDone} disabled={activeError?.kind === 'error'}>
                        {APP_STRINGS.utilButtons.done}
                    </PrimaryButton>
                    <DefaultButton onClick={onCancel}>{APP_STRINGS.utilButtons.cancel}</DefaultButton>
                </Stack>
            </PageContent>
        </FullHeightWhiteWrapper>
    );
});
