import { assertNever } from 'office-ui-fabric-react';
import { useReducer, Reducer, useLayoutEffect } from 'react';

export interface TransitionHelperState {
    /**
     * What we are trying to animate to, or are at if the status is done
     */
    target: 'closed' | 'open';
    status: 'done' | 'pre-animation' | 'animating';
}

type Action = { type: 'unpark'; target: 'open' | 'closed' } | { type: 'transitionStart' } | { type: 'transitionEnd' };

const reducer = (state: TransitionHelperState, action: Action): TransitionHelperState => {
    switch (action.type) {
        case 'unpark':
            switch (state.status) {
                case 'done':
                case 'pre-animation':
                    // If attempting to start transition to the current state
                    if (action.target === state.target) {
                        if (state.status === 'done') {
                            return state;
                        }
                        return { target: action.target, status: 'done' };
                    }
                    return { target: action.target, status: 'pre-animation' };
                case 'animating':
                    return { target: action.target, status: 'animating' };
                default:
                    assertNever(state.status);
            }
            break;
        case 'transitionStart':
            if (state.status !== 'pre-animation') {
                return state;
            }
            return { target: state.target, status: 'animating' };
        case 'transitionEnd':
            return { target: state.target, status: 'done' };
        default:
            assertNever(action);
    }
};

export interface TranslationHelperReturn extends TransitionHelperState {
    onTransitionEnd: () => void;
}

export const useTransitionHelper = (open: boolean): TranslationHelperReturn => {
    const [state, dispatch] = useReducer<Reducer<TransitionHelperState, Action>, boolean>(reducer, open, (target) => ({
        target: target ? 'open' : 'closed',
        status: 'done',
    }));

    useLayoutEffect(() => {
        dispatch({ type: 'unpark', target: open ? 'open' : 'closed' });

        let canceled = false;
        setTimeout(() => {
            if (!canceled) {
                dispatch({ type: 'transitionStart' });
            }
        }, 0);
        return () => {
            canceled = true;
        };
    }, [open]);

    return {
        ...state,
        onTransitionEnd: () => dispatch({ type: 'transitionEnd' }),
    };
};
