import React from 'react';
import classNames from 'classnames';

import styles from './PanelFooter.module.scss';

export interface PanelFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    footerLeft?: boolean;
}

export const PanelFooter: React.FC<PanelFooterProps> = ({ footerLeft, ...props }) => (
    <div
        {...props}
        className={classNames(styles.footer, props.className, {
            [styles.footerLeft]: footerLeft,
        })}
    />
);
