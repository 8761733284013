import { useMemo } from 'react';

import { RTDDropdownOption } from '../../../components';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { TimeZone } from '../../timezone';

import { RtdValue } from '../../value';
import { BasicParam } from '../models';

export const emptyParameterSelection = {
    value: APP_CONSTANTS.emptySelection,
    displayText: APP_STRINGS.domain.parameter.selection.noResults,
};

export const allParameterOption: RTDDropdownOption<typeof APP_CONSTANTS.parameter.allSelection> = {
    key: APP_CONSTANTS.parameter.allSelection,
    text: APP_STRINGS.domain.parameter.selection.all,
    data: APP_CONSTANTS.parameter.allSelection,
};

// As long as we don't mutate this we're safe. Used for equality in hooks dependencies.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emptyArr: any[] = [];

const createKey = (v: string) => `param-opt-${v}`;

interface UseParameterOptionsParams<T> {
    valueToDisplayString: (value: T, timeZone: TimeZone) => string;
    expectedOptions: undefined | BasicParam.StaticDataSourceValues<T>;
    selected: undefined | RtdValue.Value<T, unknown>;
    includeAll: boolean;
    timeZone: TimeZone;
}

export function useParameterOptions<T extends string | number | boolean>({
    valueToDisplayString,
    expectedOptions,
    selected,
    includeAll,
    timeZone,
}: UseParameterOptionsParams<T>): {
    selectedKeys: string[];
    options: Array<RTDDropdownOption<typeof APP_CONSTANTS.parameter.allSelection | T>>;
} {
    const parsedOptions = useMemo(() => {
        if (expectedOptions === undefined) {
            return { options: [], keys: new Set<string>() };
        }

        const options = expectedOptions.map((o) => {
            return {
                key: createKey(o.value.toString()),
                text: o.displayText ?? valueToDisplayString(o.value, timeZone),
                data: o.value,
            };
        });

        const keys = new Set(options.map((o) => o.key));

        return { options, keys };
    }, [expectedOptions, valueToDisplayString, timeZone]);

    const parsedSelected = useMemo((): {
        newOptions: Array<RTDDropdownOption<T>>;
        keys: string[];
    } => {
        if (selected === undefined) {
            return { newOptions: emptyArr, keys: emptyArr };
        }

        if (selected.kind === 'null') {
            return {
                newOptions: emptyArr,
                keys: [APP_CONSTANTS.parameter.allSelection, ...parsedOptions.keys],
            };
        }

        const keys: string[] = [];
        const newOptions: Array<RTDDropdownOption<T>> = [];

        for (const selection of selected.kind === 'array' ? selected.values : [selected.value]) {
            const key = createKey(selection.toString());
            keys.push(key);
            if (!parsedOptions.keys.has(key)) {
                newOptions.push({
                    key,
                    text: valueToDisplayString(selection, timeZone),
                    data: selection,
                });
            }
        }
        if (newOptions.length === 0) {
            return { newOptions: emptyArr, keys };
        }
        return { newOptions, keys };
    }, [selected, parsedOptions, valueToDisplayString, timeZone]);

    const options = useMemo(
        (): Array<RTDDropdownOption<typeof APP_CONSTANTS.parameter.allSelection | T>> => [
            ...(includeAll ? [allParameterOption] : []),
            ...parsedSelected.newOptions,
            ...parsedOptions.options,
        ],
        [parsedOptions, parsedSelected.newOptions, includeAll]
    );

    return { options, selectedKeys: parsedSelected.keys };
}
