import { AccessibilitySelectorFixer } from './AccessibilitySelectorFixer';
import { GridApi, ColumnApi } from '@ag-grid-enterprise/all-modules';

type ColumnMapType = {
    [colId: string]: {
        headerId: string;
        colIndex: string; // its number, for performance do toString only once
    };
};

export class AccessibleIndexesAndSize extends AccessibilitySelectorFixer {
    private columnMapId: ColumnMapType = {};

    constructor(
        getGridElement: () => HTMLDivElement | null,
        private gridApi: () => GridApi | null,
        private columnApi: () => ColumnApi | null
    ) {
        super(
            [
                {
                    selector: '.ag-root[role="grid"]',
                    fixer: (elem: HTMLElement) => {
                        elem.setAttribute('aria-rowcount', this.gridApi()!.getDisplayedRowCount().toString());
                        elem.setAttribute(
                            'aria-colcount',
                            this.columnApi()!.getAllDisplayedColumns().length.toString()
                        );
                    },
                },
                {
                    selector: 'div[role="columnheader"]',
                    fixer: (elem: HTMLElement) => {
                        const colId =
                            elem.parentElement?.getAttribute('col-id') ??
                            elem.parentElement?.parentElement?.getAttribute('col-id');
                        if (colId !== null && colId !== undefined) {
                            elem.id = this.columnMapId[colId].headerId;
                            elem.setAttribute('aria-colindex', this.columnMapId[colId].colIndex);
                            const labelElem = elem.querySelector('[ref="eText"]');
                            if (labelElem) {
                                labelElem.id = this.columnMapId[colId].headerId + 'label';
                            }
                        }
                    },
                },
                {
                    selector: '.ag-header-cell',
                    fixer: (elem: HTMLElement) => {
                        elem.removeAttribute('aria-colindex');
                    },
                },
                {
                    selector: 'div[role="gridcell"]',
                    fixer: (elem: HTMLElement) => {
                        const colId = elem.getAttribute('col-id');
                        if (colId !== null) {
                            elem.setAttribute('aria-colindex', this.columnMapId[colId].colIndex);
                        }
                        const rowIdStr = elem.parentElement!.getAttribute('row-index');
                        if (rowIdStr === null) {
                            return;
                        }
                        const rowId = parseInt(rowIdStr, 10);
                        elem.setAttribute('aria-rowindex', (rowId + 1).toString());
                    },
                },
            ],
            getGridElement,
            100
        );
    }

    public runFixers(which?: string) {
        if (this.columnApi()) {
            this.columnMapId = this.columnApi()!
                .getAllDisplayedColumns()
                .reduce<ColumnMapType>((prev, col, index) => {
                    const colIndex = (index + 1).toString();
                    prev[col.getId()] = {
                        headerId: `header${colIndex}`,
                        colIndex: colIndex,
                    };
                    return prev;
                }, {} as ColumnMapType);
            super.runFixers(which);
        }
    }
}
