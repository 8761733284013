import * as React from 'react';
import { Fragment, Dispatch } from 'react';
import { ActionButton, MessageBar, MessageBarType, Pivot, PivotItem } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';

import { FeatureFlag } from '../../../../../core/domain';
import { useVisualConfig, VisualConfig, VisualOptionKey } from '../../../../../domain';
import { useCore } from '../../../../../core';
import { UnsupportedVisualType } from '../../../../../components';
import { APP_STRINGS } from '../../../../../res';
import { err, ok, Result, usePullState } from '../../../../../common';
import { useETPSelector } from '../../../../../store';

import { ETPManagedConfigKey } from '../../../types';
import {
    visualOptionsFeatureFlags,
    SegmentToggleInfo,
    ManagedConfigSegment,
    tileOptionsLayout,
} from '../../../constants';
import { visualAddedSelectorBuilder } from '../../../lib';
import { SchemaErrorBanner } from '../SchemaErrorBanner';
import { DefaultVariants } from '../types';

import { ConfigSegment, EmptyConfigSection } from './ConfigSections';
import { VisualizationTypePicker } from './VisualizationTypePicker';
import { TileTitleField } from './TileTitleField';

import styles from './Container.module.scss';

type SegmentLayout =
    | {
          displayName: string;
          segmentToggleInfo?: SegmentToggleInfo;
          configKeys: ETPManagedConfigKey[];
          hideReset: boolean;
      }
    | {
          displayName: string;
          segmentToggleInfo: SegmentToggleInfo;
          configKeys?: undefined;
      };

interface SectionLayout {
    displayName: string;
    segments: SegmentLayout[];
}

function buildSegmentsLayout(compatibility: Set<ETPManagedConfigKey>, config: readonly ManagedConfigSegment[]) {
    const layout: SegmentLayout[] = [];

    for (const section of config) {
        const segmentToggleInfo =
            section.segmentToggleInfo && compatibility.has(section.segmentToggleInfo.optionKey)
                ? section.segmentToggleInfo
                : undefined;

        const configKeys = section.configKeys.filter((o) => compatibility.has(o));

        if (configKeys.length !== 0) {
            layout.push({
                segmentToggleInfo,
                configKeys,
                displayName: section.displayName,
                hideReset: !!section.hideReset,
            });
        } else if (segmentToggleInfo) {
            layout.push({
                segmentToggleInfo,
                displayName: section.displayName,
            });
        }
    }

    return layout;
}

function buildTileOptionsLayout(
    optionsDisabledByFlags: VisualOptionKey[],
    visualConfig: VisualConfig,
    visualType: string
): Result<Record<string, SectionLayout>, React.ReactNode> {
    const visualTypeConfig = visualConfig.visualTypes[visualType];

    if (visualTypeConfig === undefined) {
        return err(<UnsupportedVisualType type={visualType} />);
    }

    const compatibility = new Set<ETPManagedConfigKey>(visualTypeConfig.configurationCompatibility);

    if (visualConfig.layout.some((t) => typeof t === 'object' && t.variants.includes(visualType))) {
        compatibility.add('visualTypeVariant');
    }

    for (const key of optionsDisabledByFlags) {
        compatibility.delete(key);
    }

    return ok(
        Object.fromEntries(
            tileOptionsLayout.map((l) => [
                l.displayName,
                {
                    displayName: l.displayName,
                    segments: buildSegmentsLayout(compatibility, l.segments),
                },
            ])
        )
    );
}

function openSegmentsInitialState(layout: undefined | Record<string, SectionLayout>): OpenSegments {
    if (layout === undefined) {
        return new Set();
    }

    return new Set(
        Object.values(layout).flatMap((section) =>
            section.segments.filter((s) => s.configKeys !== undefined).map((s) => s.displayName)
        )
    );
}

type OpenSegments = Set<string>;

export type SectionsAction =
    | { type: 'toggleSegmentOpen'; displayName: string }
    | { type: 'toggleSectionSegments'; displayNames: string[] };

function openSegmentsReducer(state: OpenSegments, action: SectionsAction): OpenSegments {
    switch (action.type) {
        case 'toggleSegmentOpen': {
            const newState = new Set(state);
            if (newState.has(action.displayName)) {
                newState.delete(action.displayName);
            } else {
                newState.add(action.displayName);
            }
            return newState;
        }
        case 'toggleSectionSegments':
            if (action.displayNames.every((name) => !state.has(name))) {
                return new Set([...state, ...action.displayNames]);
            } else {
                return new Set();
            }
    }
}

const SectionDivider = () => <hr className={styles.sectionDivider} />;

const collapseAllIconProps = { iconName: 'dashboards-CollapseAll' };

interface SegmentsProps {
    segments: SegmentLayout[];
    openSegments: OpenSegments;
    sectionsDispatch: Dispatch<SectionsAction>;
    defaultVariants: Record<string, string | undefined>;
}

const Segments: React.FC<SegmentsProps> = ({ segments, openSegments, sectionsDispatch, defaultVariants }) => {
    return (
        <>
            {segments.map((segment, i) => (
                <Fragment key={segment.displayName}>
                    {segment.configKeys ? (
                        <ConfigSegment
                            {...segment}
                            hideReset={segment.hideReset}
                            sectionsDispatch={sectionsDispatch}
                            open={openSegments.has(segment.displayName)}
                            defaultVariants={defaultVariants}
                        />
                    ) : (
                        <EmptyConfigSection title={segment.displayName} segmentToggleInfo={segment.segmentToggleInfo} />
                    )}
                    {i + 1 !== segments.length && <SectionDivider />}
                </Fragment>
            ))}
        </>
    );
};

const visualTypeSelector = visualAddedSelectorBuilder((o) => o.visualType);

export const SectionsContainer: React.FC = observer(function SectionsContainer() {
    const core = useCore();
    const visualConfig = useVisualConfig();
    const visualType = useETPSelector(visualTypeSelector);

    const optionsDisabledByFlags = React.useMemo(
        () =>
            computed(() =>
                Object.entries(visualOptionsFeatureFlags)
                    .filter(([flag]) => !core.featureFlags.has(flag as FeatureFlag))
                    .flatMap(([_, key]) => key as VisualOptionKey[])
            ),
        [core]
    ).get();

    const [layout, defaultVariants] = React.useMemo(() => {
        const l = buildTileOptionsLayout(optionsDisabledByFlags, visualConfig, visualType);

        const variants: DefaultVariants = {};

        for (const option of visualConfig.layout) {
            if (typeof option === 'object') {
                for (const variant of option.variants) {
                    variants[variant] = option.default;
                }
            }
        }

        return [l, variants];
    }, [optionsDisabledByFlags, visualConfig, visualType]);

    const [openVisualSegments, visualSectionsDispatch] = React.useReducer(openSegmentsReducer, undefined, () =>
        openSegmentsInitialState(layout.value)
    );
    const [openSection, setOpenSection, getOpenSection] = usePullState(tileOptionsLayout[0].displayName);

    const { onVisualSectionsCollapse, onChangeOpenSection } = React.useMemo(
        () => ({
            onVisualSectionsCollapse() {
                if (layout.kind === 'ok') {
                    visualSectionsDispatch({
                        type: 'toggleSectionSegments',
                        displayNames: layout.value[getOpenSection()].segments
                            .filter((s) => s.configKeys !== undefined)
                            .map((s) => s.displayName),
                    });
                }
            },
            onChangeOpenSection(item?: PivotItem | undefined) {
                if (item?.props.itemKey) {
                    setOpenSection(item.props.itemKey);
                }
            },
        }),
        [getOpenSection, layout, setOpenSection]
    );

    const filteredTileOptionsLayout = core.featureFlags.has('cross-filter')
        ? tileOptionsLayout
        : tileOptionsLayout.slice(0, 1);

    return (
        <>
            <Pivot selectedKey={openSection} onLinkClick={onChangeOpenSection} className={styles.pivot}>
                {filteredTileOptionsLayout.map((l) => (
                    <PivotItem
                        headerText={l.displayName}
                        itemKey={l.displayName}
                        key={l.displayName}
                        headerButtonProps={{
                            disabled:
                                layout.kind === 'err' ||
                                (l.displayName !== tileOptionsLayout[0].displayName &&
                                    layout.value[l.displayName].segments.length === 0),
                        }}
                    />
                ))}
            </Pivot>

            <SchemaErrorBanner />

            {/* Pivot contents rendered outside of Pivot to avoid wrapping div's making styling much harder */}
            <ActionButton
                disabled={layout.kind === 'err' || layout.value[openSection].segments.length === 0}
                iconProps={collapseAllIconProps}
                onClick={onVisualSectionsCollapse}
                className={styles.collapseButton}
                title={APP_STRINGS.editTilePage.visualConfig.collapseAllButtonTitle}
            >
                {APP_STRINGS.editTilePage.visualConfig.collapseAllButtonText}
            </ActionButton>
            <div className={styles.optionsListBody}>
                <TileTitleField />
                {openSection === tileOptionsLayout[0].displayName && (
                    <VisualizationTypePicker defaultVariants={defaultVariants} />
                )}
                {layout.kind === 'err' ? (
                    <MessageBar messageBarType={MessageBarType.error}>{layout.err}</MessageBar>
                ) : (
                    <Segments
                        segments={layout.value[openSection].segments}
                        openSegments={openVisualSegments}
                        sectionsDispatch={visualSectionsDispatch}
                        defaultVariants={defaultVariants}
                    />
                )}
            </div>
        </>
    );
});
