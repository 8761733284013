import { v4 } from 'uuid';

import type { IParameterV1 } from '../parameter/versions/1-0';
import type { IParameterV1_1 } from '../parameter/versions/1-1';
import type { IParameterV1_2 } from '../parameter/versions/1-2';
import type { IParameterV1_3 } from '../parameter/versions/1-3';
import type { IDataSourceV1 } from '../dataSource/versions/1';
import { dataSource } from '../dataSource';
import { IDataSourceV0 } from '../dataSource/versions/0';

import { IDashboardV1_1, STATIC_DURATION_PARAMETER_ID } from './1-1';

export type AutoRefreshUpdateIntervalV1_2 = '30s' | '1m' | '5m' | '15m' | '30m' | '1h' | '2h' | '1d';

export interface AutoRefreshConfigV1_2 {
    readonly enabled: boolean;
    readonly defaultInterval: AutoRefreshUpdateIntervalV1_2 | undefined;
    readonly minInterval?: AutoRefreshUpdateIntervalV1_2;
}
export interface DashboardConfigOptionsV1_2 {
    readonly autoRefresh?: AutoRefreshConfigV1_2;
}

export interface IDashboardV1_2 extends Omit<IDashboardV1_1, 'parameters' | 'dataSources' | '$schema'> {
    $schema: '1.2';
    /**
     * Old migrations could have migrated to any of these values
     */
    parameters: Array<IParameterV1 | IParameterV1_1 | IParameterV1_2 | IParameterV1_3>;
    /**
     * Old migrations could have migrated to any of these values
     */
    dataSources: IDataSourceV1[] | IDataSourceV0[];

    configuration?: DashboardConfigOptionsV1_2;
}

export function up(prev: IDashboardV1_1): IDashboardV1_2 {
    let newDefaultParameterId: string | undefined = undefined;

    return {
        ...prev,
        $schema: '1.2',
        parameters: prev.parameters.map((value) => {
            let id = value.id;
            if (id === STATIC_DURATION_PARAMETER_ID) {
                if (!newDefaultParameterId) {
                    // Generate new GUID
                    newDefaultParameterId = v4();
                }

                id = newDefaultParameterId;
            }

            return { ...value, id };
        }),
        dataSources: prev.dataSources.map(dataSource.v1.up),
        pinnedParameters: prev.pinnedParameters.map((value) =>
            value === STATIC_DURATION_PARAMETER_ID ? newDefaultParameterId ?? value : value
        ),
    };
}
