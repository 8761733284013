import { assertNever } from '@uifabric/utilities';

import {
    rtdNullValue,
    tagScalar,
    RtdValue,
    tagArray,
    TDuration,
    fixedDuration,
    dynamicDuration,
    valueImpl,
    ValueImpl,
} from '../../../domain/value';
import { BasicParam, BasicParamConfig, DurationParamConfig, ParameterConfig } from '../../../domain/parameter/models';

import type { IParameterSerialized } from './versions';
import type { BasicParamV2, TBasicParamV2, TDurationParamV2 } from './versions/2';

export * as parameter from './versions';

function serializeBasicParameter<T, K extends string>(parameter: BasicParamConfig<T, K>): TBasicParamV2<T, K> {
    const options = parameter.options;

    let defaultValue: BasicParamV2.ValueSelection<T>;

    switch (options.defaultValue?.kind) {
        case undefined:
        case 'null':
            defaultValue = { kind: 'all' };
            break;
        case 'array':
            defaultValue = {
                kind: 'values',
                values: [...options.defaultValue.values],
            };
            break;
        case 'scalar':
            defaultValue = {
                kind: 'value',
                value: options.defaultValue.value,
            };
            break;
        default:
            assertNever(options.defaultValue);
    }

    if (options.selectionKind === 'freetext') {
        const serialized: TBasicParamV2<T, K> = {
            kind: parameter.dataType,
            selectionType: 'freetext',
            id: parameter.id,
            displayName: parameter.displayName,
            variableName: options.variableName,
            defaultValue,
        };
        return serialized;
    }

    const dataSource: BasicParamV2.DropdownDataSource<T> =
        options.dataSource.kind === 'static'
            ? {
                  kind: 'static',
                  values: options.dataSource.values.map((v) => ({
                      value: v.value,
                      displayText: v.displayText,
                  })),
              }
            : {
                  ...options.dataSource,
                  consumedVariables: [...options.dataSource.consumedVariables],
              };

    let selectionType: BasicParamV2.IDropdown<unknown, unknown>['selectionType'];

    switch (options.selectionKind) {
        case 'array-null':
            selectionType = 'multi';
            break;
        case 'scalar':
            selectionType = 'single';
            break;
        case 'scalar-null':
            selectionType = 'single-all';
            break;
        default:
            assertNever(options);
    }

    return {
        kind: parameter.dataType,
        id: parameter.id,
        displayName: parameter.displayName,
        variableName: options.variableName,
        selectionType,
        defaultValue,
        dataSource,
    };
}

function serializeDurationParameter(parameter: DurationParamConfig): TDurationParamV2 {
    const range = parameter.defaultValue.data.value;
    return {
        kind: 'duration',
        id: parameter.id,
        displayName: parameter.displayName,
        beginVariableName: parameter.beginVariableName,
        endVariableName: parameter.endVariableName,
        defaultValue:
            range.kind === 'dynamic'
                ? { kind: 'dynamic', count: range.count, unit: range.unit }
                : {
                      kind: 'fixed',
                      start: range.start.valueOf(),
                      end: range.end.valueOf(),
                  },
    };
}

export function serializeParameter(parameter: ParameterConfig): IParameterSerialized {
    if (parameter.kind === 'duration') {
        return serializeDurationParameter(parameter);
    }

    switch (parameter.dataType) {
        case 'string':
            return serializeBasicParameter(parameter);
        case 'int32':
            return serializeBasicParameter(parameter);
        case 'int64':
            return serializeBasicParameter(parameter);
        case 'float64':
            return serializeBasicParameter(parameter);
        case 'decimal':
            return serializeBasicParameter(parameter);
        case 'bool':
            return serializeBasicParameter(parameter);
        case 'datetime':
            return serializeBasicParameter(parameter);
    }
}

function genericDeserializeParameter<T, K extends string>(
    data: TBasicParamV2<T, K>,
    impl: ValueImpl<T, K>
): BasicParamConfig<T, K> {
    const dataType = data.kind;
    let defaultValue: RtdValue.Value<T, K>;

    switch (data.defaultValue.kind) {
        case 'all':
            defaultValue = rtdNullValue;
            break;
        case 'values':
            defaultValue = tagArray(dataType, data.defaultValue.values);
            break;
        case 'value':
            defaultValue = tagScalar(dataType, data.defaultValue.value);
            break;
        default:
            assertNever(data.defaultValue);
    }

    let userOptions: BasicParam.Options<T, K>;

    if (data.selectionType === 'freetext') {
        userOptions = {
            selectionKind: 'freetext',
            variableName: data.variableName,
            defaultValue,
        };
    } else {
        const dataSource: BasicParam.DataSource<T> =
            data.dataSource.kind === 'static'
                ? {
                      kind: 'static',
                      values: data.dataSource.values.map((v) => ({
                          value: v.value,
                          displayText: v.displayText,
                      })),
                  }
                : {
                      ...data.dataSource,
                      consumedVariables: new Set(data.dataSource.consumedVariables),
                  };

        let selectionKind: BasicParam.SelectionKind;

        switch (data.selectionType) {
            case 'multi':
                selectionKind = 'array-null';
                break;
            case 'single':
                selectionKind = 'scalar';
                break;
            case 'single-all':
                selectionKind = 'scalar-null';
                break;
            default:
                assertNever(data);
        }

        userOptions = {
            selectionKind,
            variableName: data.variableName,
            defaultValue,
            dataSource,
        };
    }

    return new BasicParamConfig(data.id, data.displayName, userOptions, impl);
}

function deserializeDurationParameter(data: TDurationParamV2): DurationParamConfig {
    let defaultValue: TDuration;

    if (data.defaultValue.kind === 'fixed') {
        defaultValue = fixedDuration(new Date(data.defaultValue.start), new Date(data.defaultValue.end));
    } else {
        defaultValue = dynamicDuration(data.defaultValue.count, data.defaultValue.unit);
    }

    return new DurationParamConfig(
        data.id,
        data.beginVariableName,
        data.endVariableName,
        data.displayName,
        tagScalar('duration', defaultValue)
    );
}

export function deserializeParameter(data: IParameterSerialized): ParameterConfig {
    if (data.kind === 'duration') {
        return deserializeDurationParameter(data);
    }

    switch (data.kind) {
        case 'string':
            return genericDeserializeParameter(data, valueImpl.string);
        case 'int32':
            return genericDeserializeParameter(data, valueImpl.int32);
        case 'int64':
            return genericDeserializeParameter(data, valueImpl.int64);
        case 'float64':
            return genericDeserializeParameter(data, valueImpl.float64);
        case 'decimal':
            return genericDeserializeParameter(data, valueImpl.decimal);
        case 'bool':
            return genericDeserializeParameter(data, valueImpl.bool);
        case 'datetime':
            return genericDeserializeParameter(data, valueImpl.datetime);
    }
}
