import * as React from 'react';
import { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { classNamesFunction, IStyle, IStyleFunctionOrObject, ITheme, styled } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../res';

import type { RtdBreadcrumbItem } from './Breadcrumbs';

export interface EditableBreadcrumbItemBaseProps extends RtdBreadcrumbItem {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<EditableBreadcrumbItemStyleProps, EditableBreadcrumbItemStyles>;
}

export interface EditableBreadcrumbItemStyleProps {
    theme: ITheme;
    className?: string;
}

export interface EditableBreadcrumbItemStyles {
    root: IStyle;
}

const getClassNames = classNamesFunction<EditableBreadcrumbItemStyleProps, EditableBreadcrumbItemStyles>();

const BaseEditableBreadcrumbItem: React.FC<EditableBreadcrumbItemBaseProps> = (props) => {
    const [text, setText] = useState(props.text);

    const { onChange, flush } = useMemo(() => {
        if (props.onTextChange === undefined) {
            throw new Error();
        }
        const debouncedSetItemText = debounce(props.onTextChange, 300);

        return {
            flush: debouncedSetItemText.flush,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                debouncedSetItemText(value);
                setText(value);
            },
        };
    }, [props.onTextChange, setText]);

    useEffect(() => () => flush(), [flush]);

    useEffect(() => setText(props.text), [props.text]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const classNames = getClassNames(props.styles, { theme: props.theme! });

    return (
        <input
            aria-label={APP_STRINGS.breadcrumbs.editableItem}
            className={classNames.root}
            value={text}
            onChange={onChange}
        />
    );
};

const styles: IStyleFunctionOrObject<EditableBreadcrumbItemStyleProps, EditableBreadcrumbItemStyles> = (props) => {
    const outline = {
        outline: `1px solid ${props.theme.palette.white}`,
        outlineOffset: '-1px',
    };
    return {
        root: {
            fontFamily: 'inherit',
            fontWeight: 600,
            padding: '4px 8px',
            fontSize: 16,
            border: 'none',
            background: 'none',
            color: props.theme.palette.white,
            selectors: {
                ':hover': outline,
                ':focus': outline,
            },
        },
    };
};

export const EditableBreadCrumbItem = styled<
    EditableBreadcrumbItemBaseProps,
    EditableBreadcrumbItemStyleProps,
    EditableBreadcrumbItemStyles
>(BaseEditableBreadcrumbItem, styles);
