import { isRestApiError, RestApiError } from '../../core/domain';
import { ErrorTypes, IErrorMessage, IResourceError } from './types';
import { networkErrorMessages, unknownErrorMessage } from './errorsConfig';

/**
 * A throwable error class representing a known Resource error originator
 */
export class ResourceError<T, Type extends ErrorTypes = ErrorTypes> extends Error implements IResourceError<T, Type> {
    type: Type;
    statusCode: number;
    error?: Error;
    data?: T | undefined;

    constructor(type: Type, message: IErrorMessage, source?: Error, data?: T) {
        super(message.message);

        this.type = type;
        this.statusCode = message.statusCode;

        if (source) {
            this.error = source;
            this.stack = source.stack;
        }

        this.data = data;
    }

    static fromError<T1, T2 extends ErrorTypes = ErrorTypes>(type: T2, error: Error) {
        const resourceError = new ResourceError<T1, T2>(
            type,
            {
                message: error.message,
                statusCode: isResourceError(error) ? error.statusCode : 0,
            },
            error
        );

        delete resourceError.error;

        return resourceError;
    }
}

export const buildNetworkResourceError = <T extends Error>(
    resourceType: ErrorTypes,
    source: T
): T extends RestApiError ? ResourceError<unknown> : Error => {
    if (!isRestApiError(source)) {
        // Don't know how to avoid the "any" cast with the conditional return
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return source as any;
    }

    // Some network error
    // Check for error messages
    let message = networkErrorMessages[source.statusCode];

    if (!message) {
        // No known error message
        message = unknownErrorMessage;
    }

    // TODO #23634299: Parse error message in JSON
    // Return message so it can be handled as a normal error
    //
    // Don't know how to avoid the "any" cast with the conditional return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new ResourceError(resourceType, message) as any;
};

export const isResourceError = <T>(
    source: IResourceError<T, ErrorTypes> | Error
): source is IResourceError<T, ErrorTypes> => !!(source as IResourceError<T, ErrorTypes>).type;

export const isErrorMessage = (source: IErrorMessage | Error): source is IErrorMessage =>
    (source as IErrorMessage).statusCode !== undefined;
