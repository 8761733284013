import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

import { parseException } from '../../common';
import { APP_STRINGS } from '../../res';
import { dashboardsCoreContext } from './context';

export const LoadingAppError: React.FC<{ error: unknown }> = ({ error }) => (
    <MessageBar messageBarType={MessageBarType.error}>
        <strong>{APP_STRINGS.initializeApp.error}</strong> {parseException(error)}
    </MessageBar>
);

export interface RtdCoreSuspenseProps {
    onRenderLoading: () => React.ReactElement;
}

/**
 * Suspends rendering of nested React components until RTD core initialized.
 */
export const RtdCoreSuspense: React.FC<RtdCoreSuspenseProps> = ({ children, onRenderLoading }) => {
    const coreState = React.useContext(dashboardsCoreContext);

    switch (coreState.status) {
        case 'deferred':
            coreState.startInit();
            return onRenderLoading();

        case 'error':
            return <LoadingAppError error={coreState.error} />;

        default:
            return <>{children}</>;
    }
};
