import { ITileV2 } from './2';

export type ITileV3 = ITileV2;

/**
 * Some tiles "usedParamVariables" were set to contain paramter ids instead of
 * paramter names because of a bad migration. This migration swaps
 * "useParamVariables" out if they match the id of an existing parameter to
 * correct corrupted dashboards.
 */
export function up(prev: ITileV2, parameterIdsToNames: Map<string, string[]>): ITileV3 {
    const usedParamVariables: string[] = [];

    for (const nameOrId of prev.usedParamVariables) {
        const names = parameterIdsToNames.get(nameOrId);
        if (names) {
            usedParamVariables.push(...names);
        } else {
            usedParamVariables.push(nameOrId);
        }
    }

    return { ...prev, usedParamVariables };
}
