import React from 'react';

import { TextFieldWithControlledError } from '../../../../components/fabric/textField/TextFieldWithControlledError';
import { APP_STRINGS } from '../../../../res';

import { EppAction } from '../../reducer';

import pageStyles from '../../EditParameterPage.module.scss';

export interface FreetextParameterConfigProps {
    defaultValueEnteredText: string | undefined;
    error: string | undefined;
    dispatch: React.Dispatch<EppAction>;
}

export const FreetextParameterConfig: React.FC<FreetextParameterConfigProps> = ({
    defaultValueEnteredText,
    error,
    dispatch,
}) => {
    const onChange = React.useCallback(
        (_: unknown, text = '') =>
            dispatch({
                type: 'setFreeTextDefaultValue',
                text,
            }),
        [dispatch]
    );

    return (
        <TextFieldWithControlledError
            className={pageStyles.input}
            name={APP_STRINGS.editParameterPage.defaultValue}
            label={APP_STRINGS.editParameterPage.defaultValue}
            value={defaultValueEnteredText}
            placeholder={APP_STRINGS.editParameterPage.selection.emptyDefault}
            onChange={onChange}
            error={error}
        />
    );
};
