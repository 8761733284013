import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import isEqual from 'lodash/isEqual';
import { action } from 'mobx';

import { DashboardLoaded, DashboardChanges, Dispatch, GlobalAction } from '../../store';
import { manageParametersPanelAction } from '../../components/Forms';
import { saveConfirmParameterDialogAction } from '../../components/Forms/manage/components/parameter/SaveConfirmParameterDialog';
import { ParameterConfig } from '../../domain';
import { APP_STRINGS } from '../../res';

import { EppReducerState } from './reducer/reducer';
import { tryApplyBuildParameter } from './reducer/tryBuildParameter';

const onDoneAction = action(
    (
        disableBlockNavigation: React.MutableRefObject<boolean>,
        dashboardState: DashboardLoaded,
        dashboardChanges: DashboardChanges,
        dispatch: Dispatch<GlobalAction>,
        parameter: ParameterConfig,
        shouldPin: boolean
    ) => {
        dashboardState.addItem('parameters', parameter);
        if (shouldPin) {
            dashboardChanges.pinnedParameters.add(parameter.id);
        } else {
            dashboardChanges.pinnedParameters.delete(parameter.id);
        }
        disableBlockNavigation.current = true;
        dashboardChanges.editParameter = undefined;

        // Reopen manageParameters
        dispatch(manageParametersPanelAction());
    }
);

export function useOnEditParameterDone(
    disableBlockNavigation: React.MutableRefObject<boolean>,
    dashboardState: DashboardLoaded,
    dashboardChanges: DashboardChanges,
    dispatch: Dispatch<GlobalAction>,
    getState: () => EppReducerState
) {
    return useMemo(
        () =>
            action(() => {
                let state = getState();
                const activeError = state.activeError;

                if (activeError && activeError?.kind === 'error') {
                    return;
                }

                if (state.id === 'new') {
                    state = tryApplyBuildParameter({
                        ...state,
                        id: uuid(),
                    });
                }

                const parameter = state.parameter;

                if (!parameter) {
                    return;
                }

                if (!activeError) {
                    onDoneAction(
                        disableBlockNavigation,
                        dashboardState,
                        dashboardChanges,
                        dispatch,
                        parameter,
                        state.shouldPin
                    );
                } else {
                    dispatch(
                        saveConfirmParameterDialogAction({
                            isNew: state.id === 'new',
                            ...activeError,
                            onConfirm: () =>
                                onDoneAction(
                                    disableBlockNavigation,
                                    dashboardState,
                                    dashboardChanges,
                                    dispatch,
                                    parameter,
                                    state.shouldPin
                                ),
                        })
                    );
                }
            }),
        [getState, disableBlockNavigation, dashboardState, dashboardChanges, dispatch]
    );
}

export const eppDirtyMessage = (incomingParameter: ParameterConfig | undefined, getState: () => EppReducerState) => {
    if (!incomingParameter) {
        return undefined;
    }

    const state = getState();

    return isEqual(state.parameter, incomingParameter)
        ? undefined
        : APP_STRINGS.editParameterPage.preventNavigationWarning;
};
