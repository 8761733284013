import { SynchronizedIntervalManager } from '../../../common/hooks/synchronizedInterval/SynchronizedIntervalManager';
import { ISharedState, SharedActions } from './types';

const initialState: ISharedState = {
    activeForms: {},
    synchronizedIntervalManager: new SynchronizedIntervalManager(),
};

export const sharedReducer = (
    // Defaulting the first argument is a redux pattern that we're matching
    // eslint-disable-next-line default-param-last
    state: ISharedState = initialState,
    action: SharedActions
): ISharedState => {
    switch (action.type) {
        case 'pushActiveForm': {
            const activeForms = { ...state.activeForms, [action.key]: action.Form };

            return {
                ...state,
                activeForms,
            };
        }
        case 'closeActiveForm': {
            // Short circuit
            if (Object.keys(state.activeForms).length < 1) {
                return state;
            }

            const { [action.key]: _, ...activeForms } = state.activeForms;

            return {
                ...state,
                activeForms,
            };
        }
        case 'popActiveForm': {
            const formKeys = Object.keys(state.activeForms);

            // Short circuit
            if (formKeys.length < 1) {
                return state;
            }

            const lastFormKey = formKeys[formKeys.length - 1];

            const { [lastFormKey]: _, ...activeForms } = state.activeForms;

            return {
                ...state,
                activeForms,
            };
        }
        case 'clearAllForms': {
            // Short circuit
            if (Object.keys(state.activeForms).length < 1) {
                return state;
            }

            return {
                ...state,
                activeForms: {},
            };
        }
    }

    return state;
};
