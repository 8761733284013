import * as React from 'react';
import { useMemo } from 'react';
import { Text, TooltipHost, FontIcon } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { compactMap } from '../../../common';
import { APP_STRINGS } from '../../../res';
import { useDashboardStore } from '../../../store/dashboard';

import styles from './ParameterFilteredByList.module.scss';

export const ParameterFilteredByList: React.FC<{
    activeParentParameters: Set<string> | undefined;
    withDivider?: boolean;
}> = observer(function ParameterFilteredByList({ activeParentParameters, withDivider }) {
    const store = useDashboardStore();

    const activeParameterNames = useMemo(() => {
        const seenParameters = new Set<string>();

        return activeParentParameters && activeParentParameters.size > 0
            ? compactMap([...activeParentParameters], (variableName): string | undefined => {
                  const parameterId: string | undefined = store.state?.variableToParameterId[variableName];

                  if (!parameterId || seenParameters.has(parameterId)) {
                      return undefined;
                  }

                  seenParameters.add(parameterId);

                  const filteredParameter = parameterId ? store.state?.parametersRecord[parameterId] : undefined;

                  return filteredParameter ? filteredParameter.displayName : undefined;
              })
            : undefined;
    }, [activeParentParameters, store.state?.parametersRecord, store.state?.variableToParameterId]);

    return activeParameterNames && activeParameterNames.length > 0 ? (
        <TooltipHost content={activeParameterNames.join(', ')}>
            {/* Dropdowns don't have trivial access to CSS variables at the moment */}
            <div className={withDivider ? styles.wrapper : undefined}>
                <div className={withDivider ? `${styles.filteredList} ${styles.nested}` : styles.filteredList}>
                    <Text>{`${APP_STRINGS.parameterDropdown.filteredBy}:`}</Text>
                    <FontIcon className={styles.icon} iconName="Filter" />
                    <Text>{activeParameterNames.join(', ')}</Text>
                </div>
            </div>
        </TooltipHost>
    ) : null;
});
