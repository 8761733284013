import { IDropdownStyleProps, IDropdownStyles } from 'office-ui-fabric-react';

export const getStyles = ({ theme }: IDropdownStyleProps): Partial<IDropdownStyles> => {
    // Not sure in what scenario theme would be undefined
    const semanticColors = theme?.semanticColors;

    // Taken from https://github.com/microsoft/fluentui/blob/master/packages/fluent-theme/src/fluent/styles/Dropdown.styles.ts#L30
    const itemSelectors = (isSelected: boolean) => {
        return {
            border: 'none',
            selectors: {
                '&:hover': {
                    color: semanticColors?.menuItemTextHovered,
                    backgroundColor: !isSelected
                        ? semanticColors?.menuItemBackgroundHovered
                        : semanticColors?.menuItemBackgroundPressed,
                },
                '&:focus': {
                    backgroundColor: !isSelected ? 'transparent' : semanticColors?.menuItemBackgroundPressed,
                },
                '&:hover:focus': {
                    backgroundColor: 'unset',
                    outline: 'none',
                },
                '&:focus::after': {
                    outline: 'none',
                },
                '&:active': {
                    color: semanticColors?.menuItemTextHovered,
                    backgroundColor: !isSelected
                        ? semanticColors?.menuItemBackgroundPressed
                        : semanticColors?.menuItemBackgroundHovered,
                },
                'input:focus + label::before': {
                    // Disable remaining outline appearing on focus
                    outline: 'none !important',
                },
            },
        };
    };

    return {
        dropdownItemsWrapper: {
            // Pass the height down so we can keep the searchbox above the scrolling area
            maxHeight: 'inherit',
        },
        dropdownItems: {
            maxHeight: 'inherit',
        },
        dropdownItem: itemSelectors(false),
        dropdownItemSelected: itemSelectors(true),
    };
};
