/* eslint-disable @typescript-eslint/no-redeclare */

import { ColumnType } from '../../../packages/common/dist';

export type ActionKind = 'refresh' | 'edit' | 'delete' | 'favorite' | 'info' | 'keepFocus';

export interface Icon {
    iconName: string;
    iconType: 'fabric' | 'custom';
    isBold?: boolean;
    ariaLabel?: string;
}

/**
 * Actions are displayed when hovering over a row.
 * Typical actions are favorite / unfavorite, info icon, edit, delete ETC.
 * Even though Actions are usually only displayed on hover (in order to reduce mental strain),
 * Occasionally some indication needs to be shown even when not hovering.
 */
export interface Action {
    /**
     * Icon to be displayed when hovering over a row
     */
    mainIcon: Icon;

    /**
     * Icon to be displayed when _not_ hovering on the row.
     * Example scenario is a favorite icon that will be displayed even when not hovering.
     * If not provided, nothing will be displayed when not hovering.
     */
    nonHoverIcon?: Icon;

    /**
     * Text to display when hovering over the icon. This is useful for info icon for example.
     */
    textOnHover?: string;
}

export enum EntityType {
    Cluster = 'Cluster',
    Database = 'Database',
    Table = 'Table',
    ExternalTable = 'ExternalTable',
    MaterializedViewTable = 'MaterializedViewTable',
    Function = 'Function',
    Column = 'Column',
    Folder = 'Folder',
    FunctionsFolder = 'FunctionsFolder',
    TablesFolder = 'TablesFolder',
    ExternalTableFolder = 'ExternalTableFolder',
    MaterializedViewTableFolder = 'MaterializedViewTableFolder',
    LoadingIndicator = 'LoadingIndicator',
}

/**
 *  App Main Pages Explorer (default), Ingestion
 *  Keeping "ingest" as an AppPage for backward compatibility. In practice it's the app page "oneClick" with "ingest" flow.
 */
export enum DeepLinkEnabledAppPages {
    Explorer = '',
    Ingest = 'ingest',
    OneClick = 'oneclick',
}

/**
 * The app pages that do not support Deep Linking
 */
export enum DeepLinkDisabledAppPages {
    Dashboards = 'dashboards',
    GetStarted = 'getstarted',
}

/**
 *  App Main Pages Explorer (default), Ingestion, Dashboard, GetStarted
 */
export const AppPages = {
    ...DeepLinkDisabledAppPages,
    ...DeepLinkEnabledAppPages,
};

// TypeScript will export this type at the same time as the object above, keeping consistency with normal
// enum usage
// eslint-disable-next-line no-redeclare
export type AppPages = DeepLinkEnabledAppPages | DeepLinkDisabledAppPages;

export type PasteLocation = 'cursorPosition' | 'appendAtEndOfCurrentCommand';

/**
 * Represents a kusto column in the connection pane.
 * This is not modeled as a mobx-state-tree entity since it's immutable thus we don't need change tracking for it
 * This results in a lot less memory consumption (since observable entities are much more memory intensive).
 * Originally this was an mst entity but we had out-of-memory crashes in clusters with lots of tables.
 */
export interface Column {
    id: string;
    name: string;
    type: ColumnType;
    entityType: EntityType.Column;
    docstring?: string;
}

export interface EntityWithFolder {
    id: string;
    name: string;
    folder?: string;
}

export type TableEntityTypes = EntityType.Table | EntityType.ExternalTable | EntityType.MaterializedViewTable;

/**
 * Represents a kusto table in the connection pane
 */
export interface Table extends EntityWithFolder {
    columns: { [id: string]: Column };
    docstring?: string;
    entityType: TableEntityTypes;
}

export interface ScalarParameter {
    name: string;
    type?: string;
    cslType?: string;
    cslDefaultValue?: string;
}

export type FunctionParameter = ScalarParameter & {
    columns?: ScalarParameter[];
};

/**
 * Represents a kusto function in the connection pane
 */
export interface Function extends EntityWithFolder {
    inputParameters: FunctionParameter[];
    body: string;
    docstring?: string;
    functionKind: string;
    outputColumns: string[];
    entityType: EntityType.Function;
}

export function assertNever(x: never): never {
    throw new Error('Unexpected object: ' + x);
}

export const Keys = {
    enter: 'Enter',
    tab: 'Tab',
    space: ' ',
};
