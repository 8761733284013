import { User, Group, Person } from '@microsoft/microsoft-graph-types';
import * as React from 'react';
import { PersonaImageFetchWrapper } from './components/PersonaImageFetchWrapper';
import { ExtendedPersonaProps } from './types';

export const isUserOrPerson = (person: User | Person | Group): person is User | Person =>
    !!(person as User | Person).userPrincipalName;
export const isPerson = (person: User | Person | Group): person is Person => !!(person as Person).personType;

export const userOrGroupToPersona = (person: User | Person | Group): ExtendedPersonaProps => ({
    text: person.displayName ?? undefined,
    secondaryText: (isUserOrPerson(person) ? person.userPrincipalName : person.mail) ?? undefined,
    graphPerson: {
        // id should always exist. Graph types are entirely Partial, however
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: person.id!,
        displayName: person.displayName ?? undefined,
        email:
            (isPerson(person) ? person.scoredEmailAddresses?.[0].address : person.mail) ??
            // Cannot find an explicitly named email address. Try UPN
            (isUserOrPerson(person) ? person.userPrincipalName : undefined) ??
            undefined,
        type: isUserOrPerson(person)
            ? isPerson(person) && person.personType?.class === 'Group'
                ? 'group'
                : 'user'
            : 'group',
    },
    onRenderPersonaCoin: (props, defaultRender) => {
        if (!props || !defaultRender) {
            return null;
        }

        return <PersonaImageFetchWrapper persona={props} oid={person.id} defaultRenderer={defaultRender} />;
    },
});
