import { Observable } from 'rxjs';

/**
 * Filters undefined and null values from the input stream
 * @param input Input stream containing possible undefined and null values
 */
export const filterUndefined = <T>(input: T | undefined | null): input is T => input !== undefined && input !== null;

/**
 * Resolves with the first emitted value from the `observable`
 * @param observable The observable to subscribe to
 */
export const firstValueFrom = async <T>(observable: Observable<T>): Promise<T> =>
    new Promise((resolve, reject) => observable.subscribe(resolve, reject));
