import { IDashboardDocument } from '../../core/domain';

import type { DashboardLoaded } from './DashboardLoaded';

export function dashboardStoreToDocument(loadedDashboard: DashboardLoaded): IDashboardDocument {
    const layoutRecord = Object.fromEntries(
        Object.values(loadedDashboard.tilesLayout)
            .flat()
            .map((v) => [v.i, v])
    );
    const tilesWithLayout = loadedDashboard.tiles.map((tile) => {
        const layout = layoutRecord[tile.id];
        return {
            ...tile,
            layout: { x: layout.x, y: layout.y, width: layout.w, height: layout.h },
        };
    });

    return {
        meta: loadedDashboard.document.meta,
        title: loadedDashboard.title,
        pinnedParameters: new Set(Object.keys(loadedDashboard.pinnedParameters)),
        autoRefresh: loadedDashboard.autoRefresh,
        tiles: tilesWithLayout,
        dataSources: loadedDashboard.dataSources,
        parameters: loadedDashboard.parameters,
        pages: loadedDashboard.pagesNav.layout.map((id) => loadedDashboard.pagesRecord[id]),
    };
}
