import React from 'react';
import * as mobx from 'mobx';

import { err, formatLiterals, InterfaceFor, ok, ReadonlyRecord, Result } from '../common';
import { ParameterConfig, ParameterValue, Parameter, TRtdValue } from '../domain';
import { APP_STRINGS } from '../res';

const localStrings = APP_STRINGS.domain.parameter;

const noErrors: React.ReactNode[] = [];

const ERROR_TIMEOUT = 5000;

function isParamValueCorrupted(value: ParameterValue): boolean {
    if (value.kind === 'duration' || value.data === undefined || value.data.kind === 'null') {
        return false;
    }

    if (value.data.kind === 'array') {
        const broadened: readonly unknown[] = value.data.values;
        return broadened.some((v) => !value.config.impl.isValue(v));
    }

    return !value.config.impl.isValue(value.data.value);
}

export type ITemporalGeneralErrors = InterfaceFor<TemporalGeneralErrors>;

class TemporalGeneralErrors {
    readonly errorsMap: Map<() => void, React.ReactNode> = mobx.observable.map(undefined, { deep: false });

    constructor() {
        mobx.makeObservable(this, { add: mobx.action, dispose: mobx.action, errors: mobx.computed });
    }

    add(value: React.ReactNode) {
        const timeout = setTimeout(removeError, ERROR_TIMEOUT);
        this.errorsMap.set(dispose, value);

        const errorsMap = this.errorsMap;
        function removeError() {
            mobx.runInAction(() => errorsMap.delete(dispose));
        }

        function dispose() {
            clearTimeout(timeout);
            removeError();
        }
    }

    get errors() {
        return mobx.values(this.errorsMap);
    }

    /**
     * Clears errors and timeouts
     */
    dispose() {
        for (const dispose of this.errorsMap.keys()) {
            dispose();
        }
    }
}

export type ITemporalParameterErrors = InterfaceFor<TemporalParameterErrors>;

class TemporalParameterErrors {
    /**
     * parameter id => (dispose => error)
     */
    private readonly paramErrorsMap: Map<string, Map<() => void, React.ReactNode>> = mobx.observable.map(undefined, {
        deep: false,
    });

    constructor() {
        mobx.makeObservable(this, { add: mobx.action, dispose: mobx.action, errors: mobx.computed });
    }

    errorsFor(parameterId: string): readonly React.ReactNode[] {
        const errors = this.paramErrorsMap.get(parameterId);
        if (errors === undefined) {
            return noErrors;
        }
        return mobx.values(errors);
    }

    add(parameterId: string, value: React.ReactNode) {
        let maybeErrors = this.paramErrorsMap.get(parameterId);

        if (maybeErrors === undefined) {
            maybeErrors = mobx.observable.map(undefined, { deep: false });
            this.paramErrorsMap.set(parameterId, maybeErrors);
        }

        const errors = maybeErrors;

        const timeout = setTimeout(removeError, ERROR_TIMEOUT);
        errors.set(dispose, value);

        const errorsMap = this.paramErrorsMap;

        function removeError() {
            mobx.runInAction(() => {
                if (errors.size === 1) {
                    errorsMap.delete(parameterId);
                } else {
                    errors.delete(dispose);
                }
            });
        }

        function dispose() {
            clearTimeout(timeout);
            removeError();
        }
    }

    get errors() {
        const errors: Array<[string, React.ReactNode[]]> = [];
        for (const [id, paramErrors] of this.paramErrorsMap.entries()) {
            errors.push([id, [...paramErrors.values()]]);
        }
        return errors;
    }

    /**
     * Clears errors and timeouts
     */
    dispose() {
        for (const paramErrors of this.paramErrorsMap.values()) {
            for (const dispose of paramErrors.keys()) {
                dispose();
            }
        }
    }
}

// Parameter id to parameter
export type ParameterMap = Record<string, ParameterValue>;

function parameterValueFromSearch(
    parameter: ParameterConfig,
    searchParams: Map<string, string[]>
): undefined | ParameterValue {
    const serializedValue: Parameter.UrlSerialized = {};

    for (const variableName of parameter.variableNames) {
        const selection = searchParams.get(variableName);
        if (selection === undefined) {
            return undefined;
        }
        serializedValue[variableName] = selection;
    }
    // Ignore invalid search parameters. TODO: log this
    return parameter.parseUrlStrings(serializedValue)?.value;
}

function parameterMapFromSearch(parameters: readonly ParameterConfig[], urlParameters: string) {
    const searchParams = new Map<string, string[]>();

    for (const [key, value] of new URLSearchParams(urlParameters).entries()) {
        const values = searchParams.get(key);
        if (values) {
            values.push(value);
        } else {
            searchParams.set(key, [value]);
        }
    }

    const selections: ParameterMap = {};

    for (const parameter of Object.values(parameters)) {
        selections[parameter.id] = parameterValueFromSearch(parameter, searchParams) ?? parameter.defaultValue;
    }

    return selections;
}

function defaultParameterMap(parameters: readonly ParameterConfig[]) {
    const selections: ParameterMap = {};
    for (const parameter of parameters) {
        selections[parameter.id] = parameter.defaultValue;
    }
    return selections;
}

function updateParameterSelections(parameters: readonly ParameterConfig[], selections: ParameterMap) {
    const currentParameterIds = new Set(Object.keys(selections));

    for (const parameter of parameters) {
        if (selections[parameter.id]?.config !== parameter) {
            selections[parameter.id] = parameter.defaultValue;
        }
        currentParameterIds.delete(parameter.id);
    }

    for (const id of currentParameterIds) {
        delete selections[id];
    }
}

export type InitialParameters = undefined | { kind: 'map'; params: ParameterMap } | { kind: 'url'; url: string };

export type IParameterSelections = InterfaceFor<ParameterSelections>;

export class ParameterSelections {
    public readonly selections: ParameterMap;
    public readonly paramErrors: ITemporalParameterErrors = new TemporalParameterErrors();
    public readonly generalErrors: ITemporalGeneralErrors = new TemporalGeneralErrors();

    private disposeParameterChangesReaction: () => void;

    constructor(
        private parametersObservable: {
            parameters: readonly ParameterConfig[];
            variableToParameterId: ReadonlyRecord<string, undefined | string>;
        },
        initialParameters?: InitialParameters
    ) {
        this.setParameterValue = this.setParameterValue.bind(this);
        this.crossFilter = this.crossFilter.bind(this);
        this.resetSelections = this.resetSelections.bind(this);
        const parameters = mobx.runInAction(() => parametersObservable.parameters);

        if (initialParameters) {
            this.selections =
                initialParameters.kind === 'map'
                    ? initialParameters.params
                    : parameterMapFromSearch(parameters, initialParameters.url);
        } else {
            this.selections = defaultParameterMap(parameters);
        }

        mobx.makeObservable(this, {
            selections: mobx.observable.shallow,
            changed: mobx.computed,
            clone: mobx.action,
            setParameterValue: mobx.action,
            resetSelections: mobx.action,
            crossFilter: mobx.action,
        });

        this.disposeParameterChangesReaction = mobx.reaction(
            () => parametersObservable.parameters,
            (p) => updateParameterSelections(p, this.selections)
        );
    }

    dispose() {
        this.disposeParameterChangesReaction();
        this.paramErrors.dispose();
        this.generalErrors.dispose();
    }

    get changed() {
        return Object.values(this.selections).some((value) => value.config.defaultValue !== value);
    }

    clone(): IParameterSelections {
        return new ParameterSelections(this.parametersObservable, {
            kind: 'map',
            params: { ...this.selections },
        });
    }

    setParameterValue(value: ParameterValue) {
        this.selections[value.config.id] = value;
    }

    /**
     * Set a parameters value from a rtd value. Not guaranteed to succeed. An
     * error will be shown on failure.
     *
     * @param parameterId Handles when invalid values are passed here.
     */
    crossFilter(parameterId: string, value: Result<TRtdValue, React.ReactNode>): void {
        const param = this.selections[parameterId];

        if (param === undefined) {
            this.generalErrors.add(localStrings.errors.crossFilter.parameterNotFound);
            return;
        }

        if (value.kind === 'err') {
            this.paramErrors.add(
                parameterId,
                <>
                    <strong>{localStrings.errors.crossFilter.creationErrorPrefix}:</strong> {value.err}
                </>
            );
        } else {
            const maybeValue = param.config.tryCreateParamValue(value.value);
            if (maybeValue.kind === 'ok') {
                // If things are working correctly it should not be possible for
                // this value to get corrupted. This is to protect dashboards
                // from corruption that may have happened in kustoweb,
                // rtd-provider, or in a visual.
                //
                // As of writing kustoweb is a lot more tolerant of internal
                // errors and corruption than dashboards, and it's types are a
                // lot less strict. If the situation improves this could be
                // removed.
                //
                // TODO: log corruption as an exception
                const corrupted = isParamValueCorrupted(maybeValue.value);
                if (corrupted) {
                    this.paramErrors.add(
                        parameterId,
                        <>
                            <strong>{localStrings.errors.crossFilter.applicationErrorPrefix}:</strong>{' '}
                            {localStrings.errors.crossFilter.corruptedValueError}
                        </>
                    );
                } else {
                    this.selections[parameterId] = maybeValue.value;
                }
            } else {
                this.paramErrors.add(
                    parameterId,
                    <>
                        <strong>{localStrings.errors.crossFilter.applicationErrorPrefix}:</strong> {maybeValue.err}
                    </>
                );
            }
        }
    }

    resetSelections() {
        for (const parameter of this.parametersObservable.parameters) {
            this.selections[parameter.id] = parameter.defaultValue;
        }
    }

    /**
     * Do _not_ make an action. TODO: Emit information so that duration parameter
     * variable names correspond to only half of a time range.
     */
    resolveParameterValues(variableNames: Set<string>): Result<ParameterValue[], { title: string; body: string }> {
        // Parameters are added to a map so parameters with multiple variables aren't added twice
        const values = new Map<string, ParameterValue>();

        const missingVariableNames: string[] = [];

        for (const name of variableNames) {
            const parameterId = this.parametersObservable.variableToParameterId[name];
            if (parameterId === undefined) {
                missingVariableNames.push(name);
            } else {
                const value = this.selections[parameterId];
                if (value) {
                    values.set(value.config.id, value);
                }
            }
        }

        if (missingVariableNames.length !== 0) {
            return err({
                title: localStrings.errors.unableToResolveVariableNames.title,
                body: formatLiterals(localStrings.errors.unableToResolveVariableNames.body, {
                    variableNames: missingVariableNames.join(', '),
                }),
            });
        }

        return ok([...values.values()]);
    }
}
