import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import { action } from 'mobx';
import { focusAsync, IContextualMenuItem } from 'office-ui-fabric-react';

import { Tile, Markdown, InvalidLayoutError } from '../../../components';
import { findNextOpenTilePosition, RGLLayout, ITile } from '../../../domain';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { useDashboardStore, DashboardLayout, DashboardLoaded } from '../../../store';

import { DashboardTile } from './QueryTile';

import styles from './DashboardTiles.module.scss';

function findTileLocation(
    tileId: string,
    dashboardLayout: DashboardLayout
): undefined | { tileLayout: RGLLayout; pageLayout: RGLLayout[]; pageId: string } {
    for (const [pageId, pageLayout] of Object.entries(dashboardLayout)) {
        for (const tileLayout of pageLayout) {
            if (tileLayout.i === tileId) {
                return { pageId, tileLayout, pageLayout };
            }
        }
    }
    return undefined;
}

function duplicateTile(pageState: DashboardLoaded, tile: ITile): ITile | undefined {
    const location = findTileLocation(tile.id, pageState.tilesLayout);

    if (location === undefined) {
        // TODO: #7180081 Update when we have a error handling strategy.
        return;
    }

    const newTile: ITile = {
        ...tile,
        id: uuid(),
        layout: {
            ...tile.layout,
            ...findNextOpenTilePosition(
                location.pageLayout,
                location.tileLayout.w,
                location.tileLayout.h,
                APP_CONSTANTS.dashboardPage.grid.columnCount
            ),
        },
    };

    pageState.addItem('tiles', newTile);
    return newTile;
}

function changeTilePage(pageState: DashboardLoaded, tile: ITile, newPageId: string) {
    pageState.addItem('tiles', {
        ...tile,
        pageId: newPageId,
        layout: {
            ...tile.layout,
            ...findNextOpenTilePosition(
                pageState.tilesLayout[newPageId],
                tile.layout.width,
                tile.layout.height,
                APP_CONSTANTS.dashboardPage.grid.columnCount
            ),
        },
    });
}

interface MarkdownTileProps {
    dashboardLoaded: DashboardLoaded;
    tile: ITile;
    menuItems: IContextualMenuItem[];
    editing: boolean;
    layout: RGLLayout;
}

export const MarkdownTile: React.FC<MarkdownTileProps> = ({ dashboardLoaded, tile, menuItems, editing, layout }) => {
    return (
        <Tile
            pageState={dashboardLoaded}
            tileId={tile.id}
            title={tile.title}
            editing={editing}
            menuItems={menuItems}
            className={styles.tile}
        >
            {layout.valid ? <Markdown markdownText={tile.query} /> : <InvalidLayoutError layout={layout} />}
        </Tile>
    );
};

export interface IRGLTileProps {
    dashboardLoaded: DashboardLoaded;
    tileId: string;
    layout: RGLLayout;
}

export const RGLTile: React.FC<IRGLTileProps> = observer(function RGLTile({ tileId, layout, dashboardLoaded }) {
    const store = useDashboardStore();

    const tile = dashboardLoaded.tilesRecord[tileId];

    const isEditMode = dashboardLoaded.changes;
    const pages = dashboardLoaded.pages;

    const menuItems = React.useMemo((): IContextualMenuItem[] => {
        if (isEditMode) {
            return [
                {
                    key: 'rename',
                    name: APP_STRINGS.dashboardPage.tileMenu.rename,
                    onClick: action(() => {
                        dashboardLoaded.activeTileRename = {
                            tileId: tile.id,
                            onFormClosed: () => {
                                const element = document.querySelector(`[data-tile-menu-btn="${tile.id}"]`);
                                if (!(element instanceof HTMLElement)) {
                                    throw new Error('Unable to find tile menu button for tile id: ' + tile.id);
                                }
                                dashboardLoaded.activeTileRename = undefined;
                                focusAsync(element);
                            },
                        };
                    }),
                },
                {
                    key: 'duplicate',
                    name: APP_STRINGS.dashboardPage.tileMenu.duplicate,
                    onClick: action(() => {
                        const newTile = duplicateTile(dashboardLoaded, tile);
                        if (newTile) {
                            store.pendingScrollToTile = newTile.id;
                        }
                    }),
                },
                {
                    key: 'delete',
                    name: APP_STRINGS.dashboardPage.tileMenu.delete,
                    onClick: () => dashboardLoaded.deleteItem('tiles', tile.id),
                },
                {
                    key: 'move',
                    name: APP_STRINGS.dashboardPage.tileMenu.move,
                    subMenuProps: {
                        items: pages
                            .filter((p) => p.id !== tile.pageId)
                            .map((p) => ({
                                key: p.id,
                                text: p.name,
                                onClick: action(() => changeTilePage(dashboardLoaded, tile, p.id)),
                            })),
                    },
                },
            ];
        }
        return [];
    }, [tile, isEditMode, pages, dashboardLoaded, store]);

    // While we want to render the outline of the tile even if we are missing
    // information we can't if we don't know the dimensions and title
    if (!tile) {
        return null;
    }

    if (tile.visualType === 'markdownCard') {
        return (
            <MarkdownTile
                layout={layout}
                tile={tile}
                editing={dashboardLoaded.changes !== undefined}
                menuItems={menuItems}
                dashboardLoaded={dashboardLoaded}
            />
        );
    }

    return (
        <DashboardTile
            layout={layout}
            tile={tile}
            isEditing={dashboardLoaded.changes !== undefined}
            menuItems={menuItems}
            dashboardLoaded={dashboardLoaded}
        />
    );
});
