import * as React from 'react';
import { ITheme, classNamesFunction, IStyle, styled, IStyleFunctionOrObject } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../res';

import sassStyles from './PreviewTag.module.scss';

type StyleProps = {
    theme: ITheme;
};

type ComponentStyles = {
    root: IStyle;
};

const getClassNames = classNamesFunction<StyleProps, ComponentStyles>();

const getStyles: IStyleFunctionOrObject<StyleProps, ComponentStyles> = ({ theme }) => ({
    root: { color: theme.palette.neutralSecondary },
});

type BaseProps = {
    className?: string;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<StyleProps, ComponentStyles>;
};

const PreviewTagBase: React.FC<BaseProps> = ({ styles, theme, className }) => {
    // As far as I can tell this is an issue with the fluent ui types, and is
    // never actually null.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const classNames = getClassNames(styles, { theme: theme! });
    return (
        <span className={`${sassStyles.tag} ${classNames.root} ${className ?? ''}`}>{APP_STRINGS.previewTagText}</span>
    );
};

export const PreviewTag = styled(PreviewTagBase, getStyles);
