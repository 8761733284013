import * as React from 'react';

import styles from './QueryNotRunVisual.module.scss';

export const QueryNotRunVisualIcon: React.FC = () => (
    <svg
        className={styles.graphic}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            className={styles.neutralLight}
            d="M38.0763 59.6312L3.20198 44.9313C1.92408 44.3876 0.912844 43.3604 0.388858 42.0737C-0.135129 40.7869 -0.129353 39.345 0.404927 38.0625L15.097 3.273C15.3645 2.61754 15.7614 2.02283 16.2641 1.52448C16.7667 1.02613 17.3646 0.634387 18.0221 0.372694C18.6795 0.111 19.383 -0.0152608 20.0904 0.00146966C20.7977 0.0182001 21.4945 0.177578 22.1389 0.470062L56.7289 15.1769C57.384 15.4446 57.9783 15.8418 58.4764 16.3448C58.9744 16.8477 59.3659 17.4461 59.6275 18.1039C59.889 18.7618 60.0152 19.4658 59.9985 20.1736C59.9818 20.8814 59.8226 21.5786 59.5303 22.2234L44.834 56.8352C44.29 58.0873 43.2811 59.0789 42.0202 59.6006C40.7593 60.1223 39.3451 60.1333 38.0763 59.6312Z"
        />
        <path
            className={styles.neutralTertiaryAlt}
            d="M47.9978 52H12.0022C10.6783 51.9911 9.41108 51.4617 8.4749 50.5262C7.53872 49.5907 7.00886 48.3244 7 47.0015V10.9985C7.00886 9.67557 7.53872 8.40931 8.4749 7.47382C9.41108 6.53832 10.6783 6.00885 12.0022 6H47.9978C49.3245 6 50.5968 6.52663 51.5349 7.46404C52.473 8.40144 53 9.67284 53 10.9985V47.0015C53 48.3272 52.473 49.5985 51.5349 50.536C50.5968 51.4734 49.3245 52 47.9978 52Z"
        />
        <path
            className={styles.white}
            d="M21 20C21 19.4477 21.4477 19 22 19H45C45.5523 19 46 19.4477 46 20V22C46 22.5523 45.5523 23 45 23H22C21.4477 23 21 22.5523 21 22V20Z"
        />
        <path
            className={styles.white}
            d="M14 20C14 19.4477 14.4477 19 15 19H17C17.5523 19 18 19.4477 18 20V22C18 22.5523 17.5523 23 17 23H15C14.4477 23 14 22.5523 14 22V20Z"
        />
        <path
            className={styles.white}
            d="M21 28C21 27.4477 21.4477 27 22 27H45C45.5523 27 46 27.4477 46 28V30C46 30.5523 45.5523 31 45 31H22C21.4477 31 21 30.5523 21 30V28Z"
        />
        <path
            className={styles.white}
            d="M14 28C14 27.4477 14.4477 27 15 27H17C17.5523 27 18 27.4477 18 28V30C18 30.5523 17.5523 31 17 31H15C14.4477 31 14 30.5523 14 30V28Z"
        />
        <path
            className={styles.white}
            d="M21 36C21 35.4477 21.4477 35 22 35H45C45.5523 35 46 35.4477 46 36V38C46 38.5523 45.5523 39 45 39H22C21.4477 39 21 38.5523 21 38V36Z"
        />
        <path
            className={styles.white}
            d="M14 36C14 35.4477 14.4477 35 15 35H17C17.5523 35 18 35.4477 18 36V38C18 38.5523 17.5523 39 17 39H15C14.4477 39 14 38.5523 14 38V36Z"
        />
    </svg>
);
