import moment from 'moment';
import { DataItem, ArgumentColumnType, normalizeArgumentNumeric } from '@kusto/charting';

export const isArgumentNumeric = (argumentType: ArgumentColumnType) =>
    (argumentType & ArgumentColumnType.NumericOrDateTimeOrTimeSpan) !== 0;

const momentFormat = (val: number, timeFormatter: string) =>
    moment(val)
        .utc()
        .format(val % 1000 === 0 ? timeFormatter : timeFormatter + '.SSS');

export function formatMillisecondsToTimeString(ms: number | null | undefined) {
    if (ms == null) {
        return '';
    }

    const absVal = Math.abs(ms);
    const hours = (absVal / (1000 * 60 * 60)) >> 0;
    const rest = momentFormat(absVal, 'mm:ss');
    return hours ? hours + ':' + rest : rest;
}

export const convertXAxisToNumber = <P extends string | number = number>(
    item: DataItem,
    argumentType: ArgumentColumnType
): P => {
    if (argumentType === ArgumentColumnType.Numeric) {
        return item.ArgumentNumeric as P;
    }
    // workaround to DataChartHelper bug - which return abs value for time span
    let normalized = normalizeArgumentNumeric(argumentType, item.ArgumentDateTime);
    if (
        argumentType === ArgumentColumnType.TimeSpan &&
        normalized > 0 &&
        item.ArgumentData &&
        item.ArgumentData.startsWith('-')
    ) {
        normalized = -normalized;
    }
    return normalized as P;
};
