import { EntityType, TableEntityTypes } from '../../../common/types';
import { Database } from '../../../stores/cluster';
import { RowDataTypeBase } from './RowDataTypeBase';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';
import { iconMapper } from './IconMapper';
import { getActions } from './ActionMapper';
import { getFromCacheOrCreate } from './RowDataTypeCache';
import { assertNever } from '../../../utils/types';
import { getTelemetryClient } from '../../../utils/telemetryClient';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FolderEntityType } from '../../../common/entityTypeUtils';

const { trackTrace } = getTelemetryClient({ component: 'DatabaseFolderRowDataType', flow: '' });

export const regularTablesFolderName = 'Tables';
export const externalTablesFolderName = 'External Tables';
export const materializedViewFolderName = 'Materialized Views';
export const functionsFolderName = 'Functions';

/**
 * For each entity type return its root folder.
 * @example External tables will be grouped under the "External Table" folder, so for entity ExternalTable this method will return "External Table".
 */
export const tablesRootFolderName = (entityType?: TableEntityTypes): string => {
    if (!entityType) {
        // A user complained that after opening Kusto Web Explorer for the first time in a while, the site didn't load for him.
        // Investigation showed up, that it was failing in this line because entityType was undefined.
        // Fix: instead of assert, if entityType is undefined, return Table as the root folder name.
        trackTrace('entity type: ' + entityType, SeverityLevel.Error);
        return regularTablesFolderName;
    }
    switch (entityType) {
        case EntityType.Table:
            return regularTablesFolderName;
        case EntityType.ExternalTable:
            return externalTablesFolderName;
        case EntityType.MaterializedViewTable:
            return materializedViewFolderName;
        default:
            trackTrace('entity type: ' + entityType, SeverityLevel.Critical);
            assertNever(entityType);
    }
};

export class DatabaseFolderRowDataType extends RowDataTypeBase {
    /**
     * Creates an instance of DatabaseFolderRowDataType. A RowDataType that represents a database's folder.
     * @param database The database the contains the folder.
     * @param path The full path of the folder from the database and not including the database name. E.g. Tables/tmp
     * @param folderEntityType
     */
    private constructor(database: Database, path: string, folderEntityType: FolderEntityType) {
        super(
            DatabaseFolderRowDataType.buildId(database.id, path),
            [database.cluster.getAlias(), database.name, ...path.split('/')],
            folderEntityType,
            database,
            iconMapper[folderEntityType]
        );

        this.actions = getActions(folderEntityType);
    }

    public static buildId(databaseId: string, path: string): string {
        const id = `$folder_${databaseId}/${path}`;
        return id;
    }

    public static fromCacheOrCreate(database: Database, path: string, folderEntityType: FolderEntityType): RowDataType {
        const id = DatabaseFolderRowDataType.buildId(database.id, path);
        return getFromCacheOrCreate(
            database,
            id,
            () => new DatabaseFolderRowDataType(database, path, folderEntityType)
        );
    }
}
