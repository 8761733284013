import { DialogFooter, PrimaryButton } from 'office-ui-fabric-react';
import React from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import { hrefCapturing } from '../../../common';
import { useCore } from '../../../core';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { RTDDialog } from '../../../components/fabric';

import { WarningsList } from '../../../components/errors/WarningsList';
import { useDashboardStore } from '../../../store';

interface ErrorLoadingDashboardProps {
    errors: React.ReactNode[];
}

const Dialog: React.FC<ErrorLoadingDashboardProps> = (props) => {
    const core = useCore();
    const history = useHistory();
    const href = core.navUtil.path.catalog;

    return (
        <RTDDialog
            hidden={false}
            title={APP_STRINGS.dashboardPage.dashboardLoadErrorMessage.title}
            maxWidth={APP_CONSTANTS.ux.dialogWithTextWidth}
        >
            <WarningsList level="error" warnings={props.errors} />
            <DialogFooter>
                <PrimaryButton
                    href={href}
                    onClick={hrefCapturing(() => {
                        history.push(href);
                    })}
                >
                    {APP_STRINGS.dashboardPage.viewAllDashboards}
                </PrimaryButton>
            </DialogFooter>
        </RTDDialog>
    );
};

export const ErrorLoadingDashboard: React.FC = observer(() => {
    const dashboardStore = useDashboardStore();

    if (dashboardStore.errorMessage) {
        return <Dialog errors={[dashboardStore.errorMessage]} />;
    } else {
        return null;
    }
});
