import { h32, HashObject } from 'xxhashjs';

export type HashableValue = string | number | boolean | undefined | null | HashableValue[];

export interface IHashBuilder {
    add(value: HashableValue): void;
    toString(): string;
}

export class XXHashBuilder implements IHashBuilder {
    private readonly hash: HashObject = h32();

    add(value: HashableValue): void {
        // TODO: Consider switching to ArrayBuffers
        if (value instanceof Array) {
            this.hash.update('a' + value.length);
            for (const arrayItem of value) {
                this.add(arrayItem);
            }
        } else if (value === null) {
            this.hash.update('null');
        } else {
            switch (typeof value) {
                case 'string':
                    this.hash.update('s' + value);
                    break;
                case 'number':
                    this.hash.update('n' + value.toString());
                    break;
                case 'boolean':
                    this.hash.update('b' + value ? '1' : '0');
                    break;
                case 'undefined':
                    this.hash.update('undefined');
                    break;
                // Skip supported types
            }
        }
    }

    toString(): string {
        return this.hash.digest().toString(16);
    }
}
