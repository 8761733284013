import * as React from 'react';
import { useMemo } from 'react';
import { MessageBar, MessageBarType, Spinner } from 'office-ui-fabric-react';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';

import { APP_CONSTANTS } from '../../../../../../res';
import { useThemeState } from '../../../../../../domain/theming';
import { StreamQuerySuccess, StreamQueryResult } from '../../../../../../domain/query';

const QueryResultTable: React.FC<{
    queryResult: StreamQuerySuccess;
}> = ({ queryResult: { result } }) => {
    const { isDark } = useThemeState();
    const { columnDefs, rowData } = useMemo(() => {
        return {
            rowData: result.rows.slice(0, APP_CONSTANTS.editParameterPage.queryVisualMaxRecordsShown),
            columnDefs: result.columns.map((c) => ({
                field: c.name,
                headerName: c.name,
                resizable: true,
            })),
        };
    }, [result]);

    return (
        <div className={isDark ? APP_CONSTANTS.agGridThemes.dark : APP_CONSTANTS.agGridThemes.light}>
            <AgGridReact columnDefs={columnDefs} rowData={rowData} colResizeDefault="shift" domLayout="autoHeight" />
        </div>
    );
};

export const DisplayQueryResult: React.FC<{ result: StreamQueryResult }> = ({ result }) => {
    // Filter out all results where the query wasn't actually run. The error (or 'notRun' message) will be displayed more visibly by the EPP,
    // rather than just this banner
    if (result.kind === 'notRun' || (result.kind === 'err' && !result.didRun)) {
        return null;
    }
    if (result.status?.kind === 'loading') {
        return <Spinner />;
    }
    switch (result.kind) {
        case 'err':
            return <MessageBar messageBarType={MessageBarType.error}>{result.reason.title}</MessageBar>;
        case 'success':
            return <QueryResultTable queryResult={result} />;
    }
};
