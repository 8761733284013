import React from 'react';

import { valueImpl } from '../../../../../domain';

import { IEditParameterPageComponentProps } from '../../../types';
import { EppReducerState, EppBasicParamOptions, EppStaticDropdownOptions } from '../../../reducer';

import { ParameterStaticValueForm } from '../StaticDropdownParamConfig/StaticOptions';
import { DropdownParamConfig } from '../DropdownParamConfig/DropdownParamConfig';
import { StaticParamDefaultValue } from './DefaultValueDropdown';

export interface StaticDropdownParamConfigProps extends IEditParameterPageComponentProps {
    state: EppReducerState;
    dropdownOptions: EppStaticDropdownOptions;
    basicParamOptions: EppBasicParamOptions;
}

export const StaticDropdownParamConfig: React.FC<StaticDropdownParamConfigProps> = (props) => {
    return (
        <DropdownParamConfig
            {...props}
            dropdownOptionsForm={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                <ParameterStaticValueForm<any, any>
                    // If generic arguments change, component state needs to be cleared
                    key={props.basicParamOptions.dataType}
                    impl={valueImpl[props.basicParamOptions.dataType]}
                    dataSource={props.dropdownOptions}
                    optionErrors={props.state.errors.optionsErrors}
                    displayTextOnly={props.basicParamOptions.dataType === 'bool'}
                    dispatch={props.dispatch}
                />
            }
            configureDefaultValue={
                <StaticParamDefaultValue
                    dropdownState={props.dropdownOptions}
                    dispatch={props.dispatch}
                    dataType={props.basicParamOptions.dataType}
                />
            }
        />
    );
};
