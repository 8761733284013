import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { MessageBar, MessageBarType, assertNever } from 'office-ui-fabric-react';

import { Slideout } from '../../../../components';
import { APP_STRINGS } from '../../../../res';

import { useSchema } from '../QueryHandler/SchemaProvider';

import styles from './SchemaErrorBanner.module.scss';

type Message = { level: MessageBarType; text: string };

const fillerMessage: Message = { level: MessageBarType.info, text: '' };

export const SchemaErrorBanner: React.FC = () => {
    const schema = useSchema();

    const [lastMessage, setLastMessage] = useState<Message>(fillerMessage);

    const incomingMessage = useMemo((): Message | undefined => {
        switch (schema.kind) {
            case 'available':
                if (schema.errorMessage) {
                    return { level: MessageBarType.warning, text: schema.errorMessage };
                }
                return undefined;
            case 'unavailable':
                return { level: MessageBarType.error, text: schema.errorMessage };
            case 'uninitialized':
                return undefined;
            default:
                assertNever(schema);
        }
    }, [schema]);

    useEffect(() => {
        if (incomingMessage) {
            setLastMessage(incomingMessage);
        }
    }, [incomingMessage]);

    const message = incomingMessage ?? lastMessage;

    const open = incomingMessage !== undefined;

    return (
        <Slideout className={styles.errorBannerSlideout} open={open}>
            <MessageBar
                messageBarType={message.level}
                truncated={true}
                isMultiline={false}
                overflowButtonAriaLabel={APP_STRINGS.editTilePage.visualConfig.errorBannerExpandButtonTitle}
            >
                {message.text}
            </MessageBar>
        </Slideout>
    );
};
