import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { FeatureFlag } from '../../../core/domain';
import { CanInfer } from '../../../common';
import { VisualOptionKey } from '../../../domain';

import type { ETPVisualKeyForType, ETPVisualOptions } from '../types';

export const etpTelemetryKey = 'EditTilePage';

// Not created with a utils function to avoid import loops
export const etpVisualOptionsDefaults: ETPVisualOptions = {
    ...APP_CONSTANTS.visuals.defaultVisualOptions,
    crossFilter: {},
    yAxisMaximumValue: '',
    yAxisMinimumValue: '',
};

export const axisScaleOptions = [
    {
        key: 'linear' as const,
        text: APP_STRINGS.editTilePage.visualConfig.axisScale.linear,
    },
    {
        key: 'log' as const,
        text: APP_STRINGS.editTilePage.visualConfig.axisScale.log,
    },
];

export const inferableETPVisualOptions: Array<ETPVisualKeyForType<CanInfer<unknown>>> = [
    'xColumn',
    'yColumns',
    'yColumn',
    'seriesColumns',
    'map__minBubbleSizeColumn',
    'map__latitudeColumn',
    'map__longitudeColumn',
    'multiStat__labelColumn',
    'multiStat__valueColumn',
    'heatMap__dataColumn',
];

export const visualOptionsFeatureFlags: Partial<Record<FeatureFlag, VisualOptionKey[]>> = {
    'cross-filter': ['crossFilter'],
};
