import { VisualizationType as vt, VisualizationOptions as vo } from '@kusto/charting';
import { MultipleYAxesConfig } from '@rtd/dashboard/src/domain';
import { visualOptionsColors, ColorRule, ColorStyle } from './conditionalFormatting/types';

// Originally these exports were X = X
// but TS 3.7.5 now complain about the type already being defined. so had to rename the imports.
export type VisualizationType = vt;
export type VisualizationOptions = vo;

export type TextSize = 'small' | 'large' | 'auto';

/**
 * VisualizationType that extends Query visualization types to include types that are supported by dashboards.
 */
export type ExtendedVisualizationType = VisualizationType | 'multistat';

export declare type VisualOptionsColor = typeof visualOptionsColors[number];

export interface ColumnFormatting {
    /**
     * Allow for Conditional Formatting configuration
     */
    ConditionalFormattingConfig?: ConditionalFormattingConfig;

    /**
     * Configuration for links
     */
    LinkConfig?: LinkConfig;
}

export type ConditionalFormattingConfig = {
    colorRulesDisabled: boolean;
    colorRules: ColorRule[];
    colorStyle: ColorStyle;
};

export type LinkConfig = {
    clickableLinksColumns: (string | RegExp)[];
};

/**
 * Additional visualization options that aren't necessarily supported by Kusto render command
 */
export interface ExtendedVisualizationOptions extends Omit<VisualizationOptions, 'Visualization'> {
    /**
     * can contain visualization types that aren't included in kusto visualization type.
     */
    Visualization: ExtendedVisualizationType;

    /**
     * Size of text to display.
     */
    TextSize?: TextSize;

    /**
     * for visualizations like multistat that have a grid-like structure, heigh specifies the number of quadrants / cells on the up/down direction
     */
    Height?: number;

    /**
     * for visualizations like multistat that have a grid-like structure, width specifies the number of quadrants  / cells on the left-right direction.
     */
    Width?: number;

    /**
     * Optional configuration for Column formatting
     */
    ColumnFormatting?: ColumnFormatting;
    /**
     * Horizontal line value currently available only for line and time charts
     */
    HorizontalLine?: string;
    /**
     * Vertical line value currently available only for line and time charts
     */
    VerticalLine?: string;

    MultipleYAxes?: MultipleYAxesConfig;
}

export const TableVisualizationOptionsSnapshot: VisualizationOptions = {
    Accumulate: false,
    IsQuerySorted: false,
    Kind: null,
    Legend: null,
    Series: null,
    Title: null,
    Visualization: 'table',
    XAxis: null,
    XColumn: null,
    XTitle: null,
    YAxis: null,
    YColumns: null,
    YSplit: null,
    YTitle: null,
    AnomalyColumns: null,
    Ymin: 'NaN',
    Ymax: 'NaN',
};

export function isSupportedByDesktopChartingLogic(
    options: ExtendedVisualizationOptions
): options is VisualizationOptions {
    return options.Visualization !== 'card' && options.Visualization !== 'multistat';
}
