import * as React from 'react';
import { useRef, useState } from 'react';

import { RtdHeader, rtdPrompt } from '../components';
import * as migration from '../migration';
import { useGlobalDispatch } from '../store';
import { createDashboardDialogAction } from '../components/Forms';

export const JsonUpload: React.FC = () => {
    const [dispatch] = useGlobalDispatch();
    const [loading, setLoading] = useState(false);
    const loadingRef = useRef(false);
    const [file, setFile] = useState<File | undefined>();

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                if (!file || loadingRef.current) {
                    return;
                }
                setLoading(true);
                loadingRef.current = true;
                file.text()
                    .then((text) => {
                        let serialized: migration.DashboardSerialized;
                        try {
                            serialized = JSON.parse(text);
                        } catch (e) {
                            rtdPrompt(dispatch, 'Failed to parse json file', {
                                subText: e.toString(),
                            });
                            return;
                        }

                        const template = migration.splitSerializedDashboard(serialized).dashboard;

                        dispatch(createDashboardDialogAction({ template }));
                    })
                    .finally(() => {
                        setLoading(false);
                        loadingRef.current = false;
                    });
            }}
        >
            <RtdHeader level={3}>Use json template</RtdHeader>
            <label htmlFor="json-file">Choose json file</label>
            <input
                id="json-file"
                type="file"
                onChange={(event) => {
                    setFile(event.target.files?.[0] ?? undefined);
                }}
            />
            <br />
            <br />
            <button type="submit" disabled={file === undefined || loading}>
                Use json template
            </button>
        </form>
    );
};
