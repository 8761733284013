import { v4 as uuid } from 'uuid';

import { tile } from '../tile';
import { ITileV1 } from '../tile/versions/1';

import { IDashboardV2 } from './2';

export interface IPageV3 {
    name: string;
    id: string;
}

export interface IDashboardV3 extends Omit<IDashboardV2, '$schema' | 'tiles'> {
    $schema: '3';
    tiles: ITileV1[];
    pages: IPageV3[];
}

/**
 * Adds pages and updates tiles to v1
 */
export function up(prev: IDashboardV2): IDashboardV3 {
    const pageId = uuid();
    const allParameterVariableNames = prev.parameters
        .map((p) => {
            if (p.type === 'duration') {
                return [p.beginVariableName, p.endVariableName];
            }
            return [p.variableName];
        })
        .flat();

    return {
        ...prev,
        $schema: '3',
        tiles: prev.tiles.map((t) => tile.v1.up(t, pageId, allParameterVariableNames)),
        pages: [{ name: 'Page 1', id: pageId }],
    };
}
