import * as React from 'react';
import { Spinner } from 'office-ui-fabric-react';

import styles from './SpinnerText.module.scss';

/**
 * Meant to be used with an fluent-ui button
 */
export const SpinnerText: React.FC<{ on?: boolean }> = ({ on, children }) => (
    <>
        <span className={on ? styles.hideText : undefined}>{children}</span>
        {on && <Spinner className={styles.spinner} />}
    </>
);
