import * as React from 'react';
import { useMemo } from 'react';

import { useCore } from '../../core';
import { useGlobalDispatch } from '../../store/redux/context';
import { APP_STRINGS } from '../../res';
import { FullHeightWhiteWrapper, PageContent, dashboardsBreadcrumb, IPageContentProps } from '../../components';
import { createDashboardDialogAction } from '../../components/Forms';
import { dashboard } from '../../migration/dashboard';

export const CatalogPageContext: React.FC<{
    onRefresh: () => void;
}> = ({ children, onRefresh }) => {
    const {
        hostConfig: { dashboardTemplates },
    } = useCore();
    const [dispatch] = useGlobalDispatch();

    const pageContentProps: IPageContentProps = useMemo(() => {
        function createSampleMenuItem(
            key: string,
            title: string,
            template?: dashboard.IDashboardVersioned,
            openDataSources?: boolean
        ) {
            const onClick = () =>
                dispatch(
                    createDashboardDialogAction({
                        template,
                        title,
                        openDataSources,
                    })
                );
            return { key, text: title, iconProps: { iconName: 'Add' }, onClick };
        }

        const sampleDashboardMenuItems =
            dashboardTemplates.map((d) =>
                createSampleMenuItem(d.template.title, d.template.title, d.template, d.openDataSources)
            ) ?? [];

        return {
            commandBar: {
                items: [
                    {
                        key: 'addDashboard',
                        name: APP_STRINGS.catalogPage.commandBar.addDashboard.text,
                        iconProps: {
                            iconName: 'ViewDashboard',
                        },
                        onClick: () => dispatch(createDashboardDialogAction({})),
                        split: true,
                        splitButtonAriaLabel: APP_STRINGS.catalogPage.commandBar.addDashboard.splitButtonAriaLabel,
                        ...(sampleDashboardMenuItems.length > 0 && {
                            subMenuProps: {
                                items: sampleDashboardMenuItems,
                            },
                        }),
                    },
                ],
                farItems: [
                    {
                        key: 'refresh',
                        name: APP_STRINGS.utilButtons.refresh,
                        iconProps: {
                            iconName: 'Refresh',
                        },
                        onClick: onRefresh,
                    },
                ],
            },
            breadcrumbs: [{ key: 'catalog', ...dashboardsBreadcrumb }],
        };
    }, [dashboardTemplates, onRefresh, dispatch]);

    return (
        <FullHeightWhiteWrapper>
            <PageContent {...pageContentProps}>{children}</PageContent>
        </FullHeightWhiteWrapper>
    );
};
