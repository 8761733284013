import { KustoVariableOutput, TKustoVariableOutput } from './variableOutput';

function formatArrayString(output: KustoVariableOutput.Array) {
    return `dynamic([${output.serializedValues.join(',')}])`;
}

function formatScalarString(output: KustoVariableOutput.Scalar | KustoVariableOutput.All) {
    return `${output.serializedValue}`;
}

function formatParameterValues(parameters: TKustoVariableOutput): string {
    return parameters
        .map(([key, output]) => {
            const outputString = output.type === 'array' ? formatArrayString(output) : formatScalarString(output);

            return `let ['${key}']=${outputString};\n`;
        })
        .join('');
}

export function formatKustoQuery(query: string, parameters: TKustoVariableOutput): string {
    return formatParameterValues(parameters) + query;
}
