import { InterfaceFor } from '../../common';
import { DashboardMember, DashboardPermissions } from '../domain/membership';

import { RestApiClient } from './RestApiClient';

export type IMembershipService = InterfaceFor<MembershipService>;

export class MembershipService {
    private readonly restApi: RestApiClient;

    constructor(metadataApiClient: RestApiClient) {
        this.restApi = metadataApiClient;
    }
    public getPermissions = (dashboardId: string): Promise<DashboardPermissions> =>
        this.restApi.httpGet(`/dashboards/${dashboardId}/permissions`).then((response) => response.json());

    public getMemberships = (dashboardId: string): Promise<DashboardMember[]> =>
        this.restApi.httpGet(`/dashboards/${dashboardId}/permissions/members`).then((response) => response.json());

    public getMembership = (dashboardId: string, id: string): Promise<DashboardMember> =>
        this.restApi
            .httpGet(`/dashboards/${dashboardId}/permissions/members/${id}`)
            .then((response) => response.json());

    public async addMemberships(dashboardId: string, addedMemberships: DashboardMember[]): Promise<void> {
        await this.restApi.httpPut(`/dashboards/${dashboardId}/permissions/members`, addedMemberships);
    }

    public async updateMembership(dashboardId: string, id: string, membership: DashboardMember): Promise<void> {
        await this.restApi.httpPut(`/dashboards/${dashboardId}/permissions/members/${id}`, membership);
    }

    public async deleteMembership(dashboardId: string, id: string): Promise<void> {
        await this.restApi.httpDelete(`/dashboards/${dashboardId}/permissions/members/${id}`);
    }
}
