import React from 'react';

import { TextFieldWithControlledError } from '../../../components/fabric/textField/TextFieldWithControlledError';
import { APP_STRINGS } from '../../../res';
import { IEditParameterPageComponentProps } from '../types';

interface IParameterDisplayNameTextFieldProps extends IEditParameterPageComponentProps {
    displayName: string;
    placeholderVariableName?: string;
    error?: string;
}

export const ParameterDisplayNameTextField: React.FC<IParameterDisplayNameTextFieldProps> = ({
    className,
    disabled,
    displayName,
    placeholderVariableName,
    error,
    dispatch,
}) => {
    const onChange = React.useCallback(
        (_: unknown, value = '') =>
            dispatch({
                type: 'setDisplayName',
                displayName: value,
            }),
        [dispatch]
    );

    return (
        <TextFieldWithControlledError
            className={className}
            label={APP_STRINGS.editParameterPage.displayName}
            value={displayName}
            placeholder={placeholderVariableName}
            onChange={onChange}
            disabled={disabled}
            error={error}
        />
    );
};
