import * as React from 'react';
import {
    AzureMap,
    AzureMapsProvider,
    IAzureMapOptions,
    AzureMapDataSourceProvider,
    AzureMapLayerProvider,
    AzureMapPopup,
} from 'react-azure-maps';
import { AuthenticationType, data as mapData, MapMouseEvent, MapEvent, Map, ControlStyle } from 'azure-maps-control';
import { useState, useRef, useMemo, useEffect } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { bringDataIntoView } from './utils';
import { InternalChartProps } from '../@types/chart.types';

interface PopUpState {
    position: [number, number];
    value?: number;
    argument?: string;
}

// position is taken from the point inside the feature,
// so it's not part of feature properties
type FeatureProperties = Omit<PopUpState, 'position'>;

export const KustoAzureMap: React.FC<InternalChartProps> = (props) => {
    const visualization = props.visualizationOptions.Visualization;
    const [popUpState, setPopUpState] = useState<PopUpState | undefined>(undefined);
    const mapRef = useRef<Map | undefined>(undefined);

    const featureCollection = useMemo(() => {
        let dataItems = props.dataItems;
        const featureArray = dataItems.map((dataItem) => {
            const point = new mapData.Point([dataItem.GeoCoordinates!.Longitude, dataItem.GeoCoordinates!.Latitude]);
            return new mapData.Feature<mapData.Point, FeatureProperties>(point, {
                value: visualization === 'piechart' ? dataItem.ValueData : undefined,
                argument: visualization === 'piechart' ? dataItem.ArgumentData! : dataItem.SeriesName!,
            });
        });
        const featureCollection = new mapData.FeatureCollection(featureArray);

        return featureCollection;
    }, [props.dataItems, visualization]);

    // Turns out we're rendering multiple times when switching in dashboards from param = A to param = B.
    // onReady is only called on the 1st time, and it has the old feautres value (even when using a ref).
    // thus in this dashboarding scenario the zoom box will allways be the previous one.
    // This useEffect solves this issue.
    // We cannot get rid of the onReady call , since when rendering a map in the query experience, this effect runs
    // before the map is ready (mapRef is undefined), thus it would have no effect.
    // using timeout here to increase the change this happens after the ready call.
    useEffect(() => {
        setTimeout(() => mapRef.current && bringDataIntoView(featureCollection, mapRef.current), 500);
    }, [featureCollection]);

    const maxValue = useMemo<number | undefined>(() => {
        if (visualization !== 'piechart') {
            return undefined;
        }

        return props.dataItems.reduce((prev, curr) => {
            return typeof prev === 'undefined' || curr.ValueData > prev ? curr.ValueData : prev;
        }, undefined as number | undefined);
    }, [props.dataItems, visualization]);

    const minValue = useMemo<number | undefined>(() => {
        if (visualization !== 'piechart') {
            return undefined;
        }

        return props.dataItems.reduce((prev, curr) => {
            return typeof prev === 'undefined' || curr.ValueData <= prev ? curr.ValueData : prev;
        }, undefined as number | undefined);
    }, [props.dataItems, visualization]);

    const options: IAzureMapOptions = {
        zoom: 3,
        center: [-95.7129, 37.0902],
        preserveDrawingBuffer: true,
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: props.azureMapSubscriptionKey,
        },
        style: props.isDarkTheme ? 'night' : 'road',
    };

    if (!props.azureMapSubscriptionKey) {
        return <></>;
    }

    // Currently maps are always "light theme", thus popup text needs to be dark.
    const popup = (
        <div style={{ padding: 10, color: 'black' }}>
            {popUpState ? `[${popUpState.position[0]}, ${popUpState.position[1]}]` : ''}
            <br />
            {popUpState?.argument}
            <br />
            {popUpState?.value}
        </div>
    );

    return (
        <div style={{ height: '100%' }}>
            <ReactResizeDetector
                handleHeight={true}
                handleWidth={true}
                refreshMode="debounce"
                refreshRate={100}
                onResize={() => {
                    mapRef.current && mapRef.current.resize();
                }}
            />
            <AzureMapsProvider>
                <AzureMap
                    events={{
                        ready: (event: MapEvent) => {
                            mapRef.current = event.map;
                            bringDataIntoView(featureCollection, event.map);
                        },
                    }}
                    options={options}
                    controls={[{ controlName: 'ZoomControl', controlOptions: { style: ControlStyle.auto } }]}
                >
                    <AzureMapDataSourceProvider id="QueryResult" collection={featureCollection}>
                        <AzureMapLayerProvider
                            id="Bubble"
                            options={{
                                radius:
                                    visualization === 'piechart' && minValue !== maxValue
                                        ? ['interpolate', ['linear'], ['get', 'value'], minValue, 5, maxValue, 60]
                                        : 10,
                                opacity: 0.6,
                            }}
                            type={'BubbleLayer'}
                            events={{
                                mouseover: (event: MapMouseEvent) => {
                                    event.map.getCanvasContainer().style.cursor = 'pointer';
                                },
                                mouseout: (event: MapMouseEvent) => {
                                    event.map.getCanvasContainer().style.cursor = 'grab';
                                },
                                click: (event: MapMouseEvent) => {
                                    if (!event || !event.shapes || !event.shapes[0]) {
                                        return;
                                    }

                                    setPopUpState({
                                        position: (event.shapes[0] as any).data.geometry.coordinates,
                                        value: (event.shapes[0] as any).data.properties.value,
                                        argument: (event.shapes[0] as any).data.properties.argument,
                                    });
                                },
                            }}
                        ></AzureMapLayerProvider>
                    </AzureMapDataSourceProvider>
                    <AzureMapPopup
                        isVisible={!!popUpState}
                        options={{ position: popUpState?.position }}
                        popupContent={popup}
                    />
                </AzureMap>
            </AzureMapsProvider>
        </div>
    );
};
