import { Person, User, Group } from '@microsoft/microsoft-graph-types';
import * as fuzzysort from 'fuzzysort';
import { assertNever } from 'office-ui-fabric-react';

import { IGraphService, TypedGraphEntityId, UserOrGroupResponse, GraphImageSize } from './IGraphService';

export class DevGraphService implements IGraphService {
    private readonly people: Person[] = [
        {
            id: 'd91017fa-a684-42f0-b681-ec43f894dbd0',
            displayName: 'Michael Negrete',
            userPrincipalName: 'mnegrete@microsoft.com',
            scoredEmailAddresses: [
                {
                    address: 'mnegrete@microsoft.com',
                },
            ],
            personType: {
                class: 'Person',
            },
        },
        {
            id: '8925ca59-43e6-4245-bf20-495af44afc3c',
            displayName: 'Gabi Lehner',
            userPrincipalName: 'gabil@microsoft.com',
            scoredEmailAddresses: [
                {
                    address: 'gabil@microsoft.com',
                },
            ],
            personType: {
                class: 'Person',
            },
        },
        {
            id: '2c64965f-d5b4-4a55-873a-99f141e19141',
            displayName: 'Rony Liderman',
            userPrincipalName: 'ronyl@microsoft.com',
            scoredEmailAddresses: [
                {
                    address: 'Rony.Liderman@microsoft.com',
                },
            ],
            personType: {
                class: 'Person',
            },
        },
        {
            id: '5f4c1e9b-c053-4379-bafa-af23a7412876',
            displayName: 'Max Burson',
            userPrincipalName: 'maburson@microsoft.com',
            scoredEmailAddresses: [
                {
                    address: 'Maxwell.Burson@microsoft.com',
                },
            ],
            personType: {
                class: 'Person',
            },
        },
    ];

    private readonly users: User[] = [
        {
            id: '2eaea485-e495-4588-87b4-97e97e998def',
            displayName: 'Michal Bar',
            userPrincipalName: 'mibar@microsoft.com',
            mail: 'Michal.Bar@microsoft.com',
            userType: 'Member',
        },
        {
            id: 'f00adcc9-8cec-4eab-a559-31cd36658d0f',
            displayName: 'Lital Bar Noy',
            userPrincipalName: 'litalbarnoy@microsoft.com',
            mail: 'litalbarnoy@microsoft.com',
            userType: 'Member',
        },
        {
            id: '53057b0d-d35c-4965-9a36-e76c40f18686',
            displayName: 'Gil Koifman',
            userPrincipalName: 'gikoifma@microsoft.com',
            mail: 'Gil.Koifman@microsoft.com',
            userType: 'Member',
        },
        // Add people to users
        ...this.people.map((p) => ({
            ...p,
            birthday: p.birthday ?? undefined,
            userType: 'Member',
        })),
    ];

    private readonly groups: Group[] = [
        {
            id: '813de75b-99a3-4597-a96d-fc65342effc9',
            displayName: 'Kusto Web Explorer Devs',
            mail: 'kwedevs@microsoft.com',
        },
        {
            id: '57639460-26c4-491f-9df8-88ed7fa23212',
            displayName: 'KustoWebExpFeedback',
            mail: 'KustoWebExpFeedback@service.microsoft.com',
        },
    ];

    getRelatedPeople(limit?: number): Promise<Person[]> {
        return Promise.resolve(this.people.slice(0, limit));
    }

    searchRelatedPeople(searchText: string, limit?: number): Promise<Person[]> {
        return this.search(searchText, this.people, limit);
    }

    getUsersOrGroups(ids: TypedGraphEntityId[]): Promise<UserOrGroupResponse> {
        const response: UserOrGroupResponse = {};

        const userIds = new Set();
        const groupIds = new Set();

        for (const id of ids) {
            if (id.type === 'group') {
                groupIds.add(id.id);
            } else if (id.type === 'user') {
                userIds.add(id.id);
            } else {
                assertNever(id.type);
            }
        }

        for (const item of this.users) {
            if (item.id && userIds.has(item.id)) {
                response[item.id] = item;
            }
        }

        for (const item of this.groups) {
            if (item.id && groupIds.has(item.id)) {
                response[item.id] = item;
            }
        }

        return Promise.resolve(response);
    }

    searchUsers(searchText: string, limit?: number): Promise<User[]> {
        return this.search(searchText, this.users, limit);
    }

    searchGroups(searchText: string, limit?: number): Promise<Group[]> {
        return this.search(searchText, this.groups, limit);
    }

    async searchUsersAndGroups(
        searchText: string,
        userLimit?: number,
        groupLimit?: number
    ): Promise<Array<User | Group>> {
        const [users, groups] = await Promise.all([
            this.searchUsers(searchText, userLimit),
            this.searchGroups(searchText, groupLimit),
        ]);

        return [...users, ...groups];
    }

    getUserImage(_pid: string, _size?: GraphImageSize): Promise<string> {
        // Never return an image
        return new Promise(() => {});
    }

    private async search<T>(searchText: string, items: T[], limit?: number): Promise<T[]> {
        const results = await fuzzysort.goAsync(searchText, items, {
            keys: ['displayName'],
        });

        return results.map((r) => r.obj).slice(0, limit);
    }
}
