import * as React from 'react';
import { FocusTrapZone, ISearchBoxStyles, ScrollToMode } from 'office-ui-fabric-react';
import { useCallback, useRef, useLayoutEffect } from 'react';
import debounce from 'lodash/debounce';
import fuzzysort from 'fuzzysort';

import { APP_CONSTANTS, APP_STRINGS } from '../../../../../res';
import { NoData } from '../../../../noData';
import { useCurrent } from '../../../../../common';

import { UnsafeKeydownSearchBox } from '../keydownSearchBox/UnsafeKeydownSearchBox';
import { CustomListDropdownMenu, CustomListDropdownMenuProps } from '../customListDropdown/CustomListDropdownMenu';
import {
    useCustomListDropdownDispatch,
    useCustomListDropdownSelector,
} from '../customListDropdown/CustomListDropdownMenuContext';
import {
    RTDDropdownOption,
    PrerenderedCustomListDropdownOption,
    CustomListDropdownHeaderProps,
} from '../customListDropdown/types';
import { buildRowHeightFunc } from '../customListDropdown/util';

import styles from '../customListDropdown/CustomListDropdownMenu.module.scss';

const disabledIconProps = { iconName: '' };

const debouncedFilter = debounce(
    (...args: Parameters<typeof filter>) => filter(...args),
    APP_CONSTANTS.debounce.standard
);

/**
 * The dropdown menu component of a SearchDropdown.
 *
 * **NOTE**: If renderedOptions are not provided, or it is not an array of elements, rendering falls back to the
 * default Fabric dropdown renderer, **without** search functionality
 */
export const SearchDropdownMenu = (props: CustomListDropdownMenuProps) => {
    const { options, currentSelectedIndexes, onRenderHeaderPrefix } = props;

    const currentOptions = useCurrent(options);

    const filterPromiseRef = useRef<Fuzzysort.CancelablePromise<unknown>>();

    const { listRef, activeIndex, orderedFilteredKeys } = useCustomListDropdownSelector((state) => state);
    const [dispatch] = useCustomListDropdownDispatch();

    const currentListRef = useCurrent(listRef);

    const setFilteredOptions = useCallback(
        (innerOptions: PrerenderedCustomListDropdownOption[] | undefined) =>
            dispatch({
                type: 'setFilteredOptions',
                options: innerOptions,
            }),
        [dispatch]
    );

    const onRenderHeader = useCallback(
        ({
            unfilteredOptions,
            onInputKeyDown,
            onRenderHeaderPrefix: innerOnRenderHeaderPrefix,
        }: CustomListDropdownHeaderProps) => {
            const onSearch = (_event: React.ChangeEvent<HTMLInputElement> | undefined, value: string | undefined) => {
                debouncedFilter(value, unfilteredOptions, setFilteredOptions, filterPromiseRef);
            };

            return (
                <FocusTrapZone isClickableOutsideFocusTrap={true}>
                    {innerOnRenderHeaderPrefix?.()}
                    <UnsafeKeydownSearchBox
                        className={styles.search}
                        styles={searchBoxStyles}
                        autoComplete="off"
                        onChange={onSearch}
                        onKeyDown={onInputKeyDown}
                        disableAnimation={true}
                        iconProps={disabledIconProps}
                        placeholder={APP_STRINGS.parameterDropdown.searchPlaceholder}
                        ariaLabel={APP_STRINGS.parameterDropdown.searchAriaLabel}
                        data-automation-id="dropdownSearchBox"
                    />
                </FocusTrapZone>
            );
        },
        [setFilteredOptions]
    );

    // As long as List has getPageHeight set (has a stable page height), scrolling can be run as a layoutEffect
    // If the page height was not stable, Fabric would have to render the list to calculate the page height then perform some logic, so
    // useEffect would have to be used instead
    useLayoutEffect(() => {
        // Scroll to top every time filter changes
        currentListRef.current?.scrollToIndex(0, buildRowHeightFunc(currentOptions.current));

        // Clear active index
        dispatch({
            type: 'setActiveIndex',
            index: undefined,
        });
    }, [orderedFilteredKeys, currentListRef, currentOptions, dispatch]);

    useLayoutEffect(() => {
        if (!currentSelectedIndexes?.current) {
            // No selected indexes
            return;
        }

        const minIndex = Math.min(...currentSelectedIndexes.current);

        if (minIndex !== Infinity) {
            // Row height method is necessary to properly scroll
            listRef?.scrollToIndex(minIndex, buildRowHeightFunc(currentOptions.current), ScrollToMode.center);
        }
        // Only run on mount, when listRef becomes available
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRef]);

    useLayoutEffect(() => {
        if (activeIndex !== undefined) {
            listRef?.scrollToIndex(activeIndex, buildRowHeightFunc(currentOptions.current));
        }
    }, [activeIndex, listRef, currentOptions]);

    return (
        <CustomListDropdownMenu
            {...props}
            onRenderHeaderPrefix={onRenderHeaderPrefix}
            onRenderHeader={onRenderHeader}
            noData={<NoData className={styles.noResults} message={APP_STRINGS.domain.parameter.selection.noResults} />}
            extendedOptionFunc={extendedOptionFunc}
        />
    );
};

const extendedOptionFunc = (option: RTDDropdownOption) => ({
    preparedSearchTarget: fuzzysort.prepare(option.text),
});

const filter = (
    value: string | undefined,
    options: PrerenderedCustomListDropdownOption[],
    setFilteredOptions: (o: PrerenderedCustomListDropdownOption[] | undefined) => void,
    filterPromiseRef: React.MutableRefObject<Fuzzysort.CancelablePromise<unknown> | undefined>
) => {
    if (filterPromiseRef.current) {
        // If there are any running filters, cancel
        filterPromiseRef.current.cancel();
        filterPromiseRef.current = undefined;
    }

    if (value === undefined || value.trim().length < 1) {
        setFilteredOptions(undefined);
        return;
    }

    const promise = fuzzysort.goAsync(
        value,
        options.filter((o) => !o.key.startsWith(APP_CONSTANTS.rtdInternalPrefix)),
        {
            key: 'preparedSearchTarget',
            threshold: -10000,
        }
    );

    filterPromiseRef.current = promise;

    promise.then((results) => setFilteredOptions(results.map((result) => result.obj)));
};

const searchBoxStyles: ISearchBoxStyles = {
    iconContainer: {
        // Fabric default width for collapsed iconContainer
        // We don't want it to animate, so make it always collapsed
        width: 4,
    },
};
