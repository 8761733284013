import * as React from 'react';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Text } from 'office-ui-fabric-react';
import { APP_STRINGS } from '../../../../../../res';
import {
    ColorRule,
    colorRulesColors,
    infer,
    formatColorRuleConditions,
    ColorResolutionSet,
    useVisualConfig,
    useThemeState,
} from '../../../../../../domain';
import { visualOptionsSelectorBuilder } from '../../../../lib';
import { useETPDispatch, useETPSelectors } from '../../../../../../store/editTile';
import { CanInfer } from '../../../../../../common';
import { ManagedConfigComponent } from '../../types';
import { ConfigurationList, ApplyConfigChange } from '../configurationList/ConfigurationList';
import styles from '../configurationList/styles.module.scss';
import { ColorRuleCallout } from './ColorRulesCallout/ColorRuleCallout';

const appStringsLocal = APP_STRINGS.editTilePage.visualConfig.colorRules;

const formatColorRuleColumn = (column: CanInfer<string>) =>
    column.type === 'infer'
        ? appStringsLocal.ruleRowInferColumn
        : `${appStringsLocal.columnDropdownLabel} ${column.value}`;

type ApplyColorRuleChange = ApplyConfigChange<ColorRule>;

const replaceColorRule =
    (rule: ColorRule): ApplyColorRuleChange =>
    (rules) => {
        const index = rules.findIndex((r) => r.id === rule.id);

        if (index === -1) {
            return undefined;
        }
        return [...rules.slice(0, index), rule, ...rules.slice(index + 1)];
    };

const addColorRule: ApplyColorRuleChange = (rules) => {
    const color = colorRulesColors.find((c) => !rules.some((r) => r.color === c)) ?? colorRulesColors[0];

    const newRule: ColorRule = {
        id: uuid(),
        column: infer,
        conditions: [{ operator: '>', value: '' }],
        color,
        indicator: { kind: 'text', text: infer },
    };

    return [...rules, newRule];
};

export interface ColorRulesProps {
    rules: ColorRule[];
    onChange: (applyChange: ApplyColorRuleChange) => void;
    disabled?: boolean;
    colors: ColorResolutionSet;
}

export const ColorRules: React.FC<ColorRulesProps> = ({ rules, onChange, disabled, colors }) => {
    const onAddColorRule = useCallback(() => onChange(addColorRule), [onChange]);

    const [{ showCallout, calloutTarget, calloutRule }, setCalloutState] = useState<{
        showCallout: boolean;
        calloutTarget?: React.MutableRefObject<null>;
        calloutRule?: ColorRule;
    }>({ showCallout: false });
    const onCalloutClose = useCallback(() => setCalloutState({ showCallout: false }), []);
    const onCalloutSave = useCallback(
        (newRule: ColorRule) => {
            onChange(replaceColorRule(newRule));
            setCalloutState({ showCallout: false });
        },
        [onChange]
    );

    const itemComponent = React.useMemo(() => {
        return getItemComponent(colors);
    }, [colors]);

    const onEditClicked = React.useCallback((item, ref) => {
        setCalloutState({
            showCallout: true,
            calloutTarget: ref,
            calloutRule: item,
        });
    }, []);

    return (
        <>
            <ConfigurationList
                items={rules}
                onChange={onChange}
                disabled={disabled}
                onEdit={onEditClicked}
                addItemButtonText={appStringsLocal.addRuleButtonText}
                addConfigItem={onAddColorRule}
                ItemComponent={itemComponent}
            />
            {showCallout && calloutRule && calloutTarget && (
                <ColorRuleCallout
                    onClose={onCalloutClose}
                    onSave={onCalloutSave}
                    rule={calloutRule}
                    targetRef={calloutTarget}
                    colors={colors}
                />
            )}
        </>
    );
};

const getItemComponent: (colors: ColorResolutionSet) => React.FC<{ item: ColorRule }> =
    (colors) =>
    ({ item }) => {
        const backgroundColor = colors[item.color].color;
        return (
            <>
                <span className={styles.configItemColor} style={{ backgroundColor }} aria-label={item.color} />
                <span className={styles.configItemText}>
                    <Text>{formatColorRuleConditions(item.conditions)}</Text>
                    <Text variant="small">{formatColorRuleColumn(item.column)}</Text>
                </span>
            </>
        );
    };

const colorRulesSelectors = {
    rules: visualOptionsSelectorBuilder((s) => s.colorRules),
    colorStyle: visualOptionsSelectorBuilder((o) => o.colorStyle),
};

/**
 * Connects `ColorRules` to the edit tile page. Before here all the color rules
 * code is (mostly) not context dependent
 */
export const ColorRulesManagedConfig: ManagedConfigComponent = ({ disabled }) => {
    const [dispatch] = useETPDispatch();
    const { rules, colorStyle }: { rules: ColorRule[]; colorStyle: ColorRule.ColorStyle } =
        useETPSelectors(colorRulesSelectors);

    const { colorValues } = useVisualConfig();
    const { isDark } = useThemeState();

    const colors = colorValues[isDark ? 'dark' : 'light'][colorStyle];

    const onChange = useCallback(
        (applyChange: (newRules: ColorRule[]) => ColorRule[] | undefined) =>
            dispatch((innerDispatch, getState) => {
                const pageState = getState();
                if (pageState.type !== 'query' || !pageState.visual) {
                    return;
                }
                const colorRules = applyChange(pageState.visual.options.colorRules);
                if (colorRules) {
                    innerDispatch({
                        type: 'updateVisualOptions',
                        options: { colorRules },
                    });
                }
            }),
        [dispatch]
    );

    return <ColorRules colors={colors} rules={rules} onChange={onChange} disabled={disabled} />;
};
