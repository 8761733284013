import { EntityType } from '../../../common/types';
import { RowDataTypeBase } from './RowDataTypeBase';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';
import { iconMapper } from './IconMapper';
import { getActions } from './ActionMapper';
import { Database } from '../../../stores/cluster';
import { getFromCacheOrCreate } from './RowDataTypeCache';

export class DatabaseLoadingRowDataType extends RowDataTypeBase {
    private constructor(database: Database) {
        super(
            DatabaseLoadingRowDataType.buildId(database.id),
            [database.cluster.getAlias(), database.name, 'Loading'],
            EntityType.LoadingIndicator,
            database,
            iconMapper.LoadingIndicator
        );

        this.actions = getActions(database.entityType);
    }

    private static buildId(databaseId: string) {
        return `$loadingIndicator_${databaseId}`;
    }

    public static fromCacheOrCreate(database: Database): RowDataType {
        const id = DatabaseLoadingRowDataType.buildId(database.id);
        return getFromCacheOrCreate(database, id, () => new DatabaseLoadingRowDataType(database));
    }
}
