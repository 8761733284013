import { assertNever } from '@uifabric/utilities';

import { BasicParamValue, ParameterValue, UBasicParamValue } from '../parameter';
import { RtdValue, getTimeProceedingTimeByRange } from '../value';

import { kustoScalarTypeFromRtdScalarType } from './kusto';
import { KustoVariableOutput, KustoVariableOutputValue, TKustoVariableOutput } from './variableOutput';

const replacements: Record<string, string> = {
    '\\': '\\\\',
    "'": "\\'",
    '\n': '\\n',
    '\r': '\\r',
};

export function serializeString(raw: string) {
    const escaped = raw.replace(/\\|'|\n|\r/g, (s) => replacements[s]);

    return `'${escaped}'`;
}

export function formatKustoDatetime(date: Date, includeMilliseconds = false): string {
    const dateString = date.toISOString();

    return `datetime('${!includeMilliseconds ? dateString.split('.')[0] + 'Z' : dateString}')`;
}

function kustoAllOutput<T, K extends string>(
    dataType: KustoVariableOutput.ScalarDataType,
    value: BasicParamValue<T, K>
): KustoVariableOutput.All {
    const serializedValue = value.config.options.selectionKind === 'array-null' ? 'dynamic(null)' : `${dataType}(null)`;
    return { type: 'all', dataType, serializedValue };
}

function genericBasicParamToKusto<T, K extends RtdValue.BasicType>(
    value: BasicParamValue<T, K>,
    convert: (v: T) => string
): KustoVariableOutputValue {
    const dataType = kustoScalarTypeFromRtdScalarType(value.config.dataType);

    switch (value.data?.kind) {
        case undefined:
        case 'null': {
            return kustoAllOutput(dataType, value);
        }
        case 'array': {
            if (value.data.values.length === 0) {
                return kustoAllOutput(dataType, value);
            }
            return {
                type: 'array',
                dataType,
                serializedValues: value.data.values.map(convert),
            };
        }
        case 'scalar':
            return {
                type: 'scalar',
                dataType,
                serializedValue: convert(value.data.value),
            };
        default:
            return assertNever(value.data);
    }
}

function basicParamValueToKusto(
    value: UBasicParamValue,
    options: BuildKustoVariableOutputOptions
): KustoVariableOutputValue {
    switch (value.dataType) {
        case 'string':
            // `string(null)` is not a valid kusto value, use `''` instead
            if (
                value.config.options.selectionKind !== 'array-null' &&
                (value.data === undefined || value.data?.kind === 'null')
            ) {
                return { type: 'all', dataType: 'string', serializedValue: `''` };
            }
            const convert = options.enableStringEscape ? (v: string) => serializeString(v) : (v: string) => `'${v}'`;
            return genericBasicParamToKusto(value, convert);
        case 'int32':
            return genericBasicParamToKusto(value, (v) => v.toString());
        case 'int64':
            return genericBasicParamToKusto(value, (v) => v.toString());
        case 'float64':
            return genericBasicParamToKusto(value, (v) => v.toString());
        case 'decimal':
            return genericBasicParamToKusto(value, (v) => v.toString());
        case 'bool':
            return genericBasicParamToKusto(value, (v) => (v ? 'true' : 'false'));
        case 'datetime':
            return genericBasicParamToKusto(value, (v) => formatKustoDatetime(new Date(v)));
        default:
            assertNever(value);
    }
}

function convertParameterToKusto(
    value: ParameterValue,
    options: BuildKustoVariableOutputOptions
): TKustoVariableOutput {
    if (value.kind === 'duration') {
        let start: string;
        let end: string;
        if (value.data.value.kind === 'fixed') {
            start = formatKustoDatetime(value.data.value.start);
            end = formatKustoDatetime(value.data.value.end);
        } else {
            const currentTime = new Date(Date.now());
            const startTime = getTimeProceedingTimeByRange(currentTime, value.data.value);
            start = formatKustoDatetime(startTime);
            end = formatKustoDatetime(currentTime);
        }
        return [
            [value.config.beginVariableName, { type: 'scalar', dataType: 'datetime', serializedValue: start }],
            [value.config.endVariableName, { type: 'scalar', dataType: 'datetime', serializedValue: end }],
        ];
    }

    return [[value.config.options.variableName, basicParamValueToKusto(value, options)]];
}

export interface BuildKustoVariableOutputOptions {
    enableStringEscape?: boolean;
}

export function buildKustoVariableOutput(
    values: ParameterValue[],
    options: BuildKustoVariableOutputOptions = {}
): TKustoVariableOutput {
    const result: TKustoVariableOutput = [];

    for (const v of values) {
        result.push(...convertParameterToKusto(v, options));
    }

    return result;
}
