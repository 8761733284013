import * as React from 'react';
import { ReactNode } from 'react';
import { DefaultButton, DialogFooter, IDialogContentProps, IDialogProps, PrimaryButton } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../res';
import { formActionMacro, TFormComponent } from '../../common';
import { RTDDialog } from '../fabric';
import { GlobalAction } from '../../store';

export interface PromptOptions {
    hideCancel?: boolean;
    subText?: IDialogContentProps['subText'];
    acceptText?: ReactNode;
    cancelText?: ReactNode;
    footer?: ReactNode;
    children?: ReactNode;
    dialogProps?: IDialogProps;
}

export interface PromptProps extends PromptOptions {
    onResolve?: (result: boolean) => void;
    title: IDialogContentProps['title'];
}

export const Prompt: TFormComponent<PromptProps> = (props) => {
    const onResolve = (res: boolean) => {
        props.onResolve?.(res);
        props.onClose();
    };

    return (
        <RTDDialog
            hidden={false}
            dialogContentProps={{ title: props.title, subText: props.subText }}
            onDismiss={() => onResolve(false)}
            {...props.dialogProps}
        >
            {props.children}
            <DialogFooter>
                <PrimaryButton onClick={() => onResolve(true)}>
                    {props.acceptText ?? APP_STRINGS.utilButtons.ok}
                </PrimaryButton>
                {!props.hideCancel && (
                    <DefaultButton onClick={() => onResolve(false)}>
                        {props.cancelText ?? APP_STRINGS.utilButtons.cancel}
                    </DefaultButton>
                )}
                {props.footer}
            </DialogFooter>
        </RTDDialog>
    );
};

export const createPromptAction = formActionMacro(Prompt, 'prompt');

export function rtdPrompt(
    dispatch: React.Dispatch<GlobalAction>,
    title: IDialogContentProps['title'],
    options?: PromptOptions
): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        dispatch(
            createPromptAction({
                title,
                onResolve: resolve,
                ...options,
            })
        );
    });
}
