import * as React from 'react';
import { useCallback, useState, MutableRefObject } from 'react';
import { IDropdownOption, MessageBarType, Label, PrimaryButton } from 'office-ui-fabric-react';

import { DashboardMember, DashboardPermission } from '../../../core/domain';
import { APP_STRINGS, APP_CONSTANTS } from '../../../res';
import { useCore } from '../../../core';
import { useCurrent } from '../../../common';
import { RTDDropdown, GraphPeoplePicker, GraphPerson } from '../../fabric';
import { SpinnerText } from '../../lib';

import { formatPermissionError } from './lib';
import { PermissionsFormDispatch, PermissionsFormReducerState } from './permissionsFormReducer';
import { MessageBanner } from './MessageBanner';

import styles from './AddPermissions.module.scss';

interface AddPermissionProps {
    dispatch: PermissionsFormDispatch;
    state: PermissionsFormReducerState;
    dashboardId: string;
    dashboardTitle: string;
    isMounted: MutableRefObject<boolean>;
}

export const AddPermission: React.FC<AddPermissionProps> = ({ dispatch, state, dashboardId, isMounted }) => {
    const { membershipService } = useCore();

    const { members, permission, addError, duplicateError } = state;
    const [newMembers, setNewMembers] = useState<GraphPerson[]>([]);
    const currentNewMembers = useCurrent(newMembers);
    const currentMembers = useCurrent(members);

    const [showReminder, setShowReminder] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const setInnerNewMembers = useCallback(
        (people: GraphPerson[]) => {
            setNewMembers(people);

            const duplicates = people.filter((person) => currentMembers.current[person.id] !== undefined);
            dispatch({
                type: 'setDuplicateError',
                duplicates,
            });
        },
        [currentMembers, dispatch]
    );

    const handlePermissionChange = useCallback(
        (_event: unknown, option?: IDropdownOption) => {
            if (!option) {
                return;
            }
            dispatch({
                type: 'changeAddPermission',
                permission: option.key as DashboardPermission,
            });
        },
        [dispatch]
    );

    const onAdd = useCallback(async () => {
        if (!currentNewMembers.current) {
            return;
        }

        setIsLoading(true);

        const newDashboardMembers = currentNewMembers.current.map(
            (member): DashboardMember => ({
                id: member.id,
                email: member.email,
                permission,
                type: member.type,
            })
        );

        const promise = membershipService.addMemberships(dashboardId, newDashboardMembers);
        dispatch({ type: 'addAsyncOperation', operation: promise });

        try {
            await promise;
            if (!isMounted.current) {
                return;
            }

            dispatch({
                type: 'addMembers',
                members: newDashboardMembers,
            });

            dispatch({ type: 'setDuplicateError', duplicates: [] });
            setNewMembers([]);
            setShowReminder(true);
        } catch (e) {
            if (!isMounted.current) {
                return;
            }

            dispatch({
                type: 'setAddError',
                errorMessage: formatPermissionError(e.message),
            });
        } finally {
            if (!isMounted.current) {
                return;
            }

            dispatch({ type: 'removeAsyncOperation', operation: promise });
        }

        setIsLoading(false);
    }, [dashboardId, permission, isMounted, membershipService, currentNewMembers, dispatch]);

    const canAdd = newMembers.length > 0;
    const handleDismissReminder = useCallback(() => setShowReminder(false), [setShowReminder]);
    const handleDismissAddError = useCallback(
        () => dispatch({ type: 'setAddError', errorMessage: undefined }),
        [dispatch]
    );

    return (
        <div className={styles.addPermission}>
            <div className={styles.sectionTitle}>{APP_STRINGS.forms.permissions.addNew}</div>
            <div className={styles.addStack}>
                <Label className={styles.emailsLabel} htmlFor="email-input">
                    {APP_STRINGS.forms.permissions.nameOrEmail}
                </Label>
                <GraphPeoplePicker
                    className={styles.emailsInput}
                    inputId="email-input"
                    selectedItems={newMembers}
                    onChange={setInnerNewMembers}
                />
                <Label className={styles.permissionDropDownLabel} htmlFor="permission-dropdown">
                    {APP_STRINGS.forms.permissions.permission}
                </Label>
                <RTDDropdown
                    className={styles.permissionDropDown}
                    id="permission-dropdown"
                    selectedKey={permission}
                    options={APP_CONSTANTS.permissionsOptions}
                    onChange={handlePermissionChange}
                    title={APP_STRINGS.forms.permissions.permissionTypeTitleText}
                />
                <PrimaryButton className={styles.addButton} disabled={!canAdd || isLoading} onClick={onAdd}>
                    <SpinnerText on={isLoading}>{APP_STRINGS.utilButtons.add}</SpinnerText>
                </PrimaryButton>
            </div>
            {addError && (
                <MessageBanner
                    className={styles.addMessageBar}
                    messageBarType={MessageBarType.error}
                    onDismiss={handleDismissAddError}
                    message={addError}
                />
            )}
            {duplicateError && (
                <MessageBanner
                    className={styles.addMessageBar}
                    messageBarType={MessageBarType.warning}
                    message={duplicateError}
                />
            )}
            {showReminder && (
                <MessageBanner
                    className={styles.addMessageBar}
                    messageBarType={MessageBarType.info}
                    onDismiss={handleDismissReminder}
                    message={APP_STRINGS.forms.permissions.messages.addReminder}
                />
            )}
        </div>
    );
};
