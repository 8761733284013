import { DependencyList, Dispatch, useEffect, useReducer, useState } from 'react';
import { isObservable, Observable } from 'rxjs';

export type PromiseGetter<T> = () => T | PromiseLike<T>;
export type ObservableGetter<T> = () => Observable<T>;
export type AsyncGetter<T> = PromiseGetter<T> | ObservableGetter<T>;

export type AsyncReducer<V, S> = (state: S, newValue: V) => S;

export interface IAsyncState<T> {
    value: T;
    error?: Error;
    isError: boolean;
    isComplete: boolean;
}

type Action<V> =
    | { type: 'reset' }
    | { type: 'result'; value: V }
    | { type: 'next'; value: V }
    | { type: 'error'; error: unknown }
    | { type: 'complete' };

const reset: Action<never> = { type: 'reset' };

// prettier-ignore
// TODO #7656828: This return type is incorrect for observables
export function useAsync<T>(getter: AsyncGetter<T>, deps: DependencyList): IAsyncState<T | undefined>;
export function useAsync<T>(getter: AsyncGetter<T>, deps: DependencyList, initialValue: T): IAsyncState<T>;
export function useAsync<V, S = V>(
    getter: ObservableGetter<V>,
    deps: DependencyList,
    initialValue: S,
    reducerFunc: AsyncReducer<V, S>
): IAsyncState<S>;

export function useAsync<V, S = V>(
    getter: AsyncGetter<V>,
    deps: DependencyList,
    initialValue?: S,
    reducerFunc?: AsyncReducer<V, S>
): IAsyncState<S | undefined> {
    // prettier-ignore
    const initialState: IAsyncState<S | undefined> = {
    value: initialValue,
    isError: false,
    isComplete: false,
  };

    function handleState(state: IAsyncState<S | undefined>, action: Action<V>): IAsyncState<S | undefined> {
        switch (action.type) {
            case 'reset':
                return initialState;
            case 'result':
                return {
                    ...state,
                    // If you have a renderFunc set, then you must have initialValue too, thus state.value is not TState | undefined.
                    // if you don't have a renderFunc set, then your TState must be equal TValue.
                    value: reducerFunc ? reducerFunc(state.value as S, action.value) : (action.value as unknown as S),
                    isComplete: true,
                };
            case 'next':
                return {
                    ...state,
                    value: reducerFunc ? reducerFunc(state.value as S, action.value) : (action.value as unknown as S),
                };
            case 'error':
                return {
                    ...state,
                    error: normalizeError(action.error),
                    isError: true,
                    isComplete: true,
                };
            case 'complete':
                return {
                    ...state,
                    isComplete: true,
                };
        }
    }

    const [handledDeps, setHandledDeps] = useState<DependencyList | undefined>(undefined);
    const [asyncState, dispatch] = useReducer(handleState, initialState);

    useEffect(() => {
        setHandledDeps(deps);

        try {
            const getterResult = getter();

            if (isObservable<V>(getterResult)) {
                return subscribeToObservable(getterResult, dispatch);
            } else if (isPromiseLike<V>(getterResult)) {
                return subscribeToPromiseLike(getterResult, dispatch);
            } else {
                dispatch({ type: 'result', value: getterResult });
            }
        } catch (error) {
            dispatch({ type: 'error', error });
        }

        return () => {
            dispatch(reset);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    // asyncState is valid only if useEffect have been called for the provided dependencies,
    // otherwise the hook still in initial state
    return areDepsEqual(deps, handledDeps) ? asyncState : initialState;
}

function isPromiseLike<T>(arg: unknown): arg is PromiseLike<T> {
    return typeof arg === 'object' && arg !== null && 'then' in arg;
}

function subscribeToPromiseLike<V>(arg: PromiseLike<V>, dispatch: Dispatch<Action<V>>): () => void {
    let cancelled = false;
    Promise.resolve(arg)
        .then((value) => {
            if (!cancelled) {
                dispatch({ type: 'result', value });
            }
        })
        .catch((error) => {
            if (!cancelled) {
                dispatch({ type: 'error', error });
            }
        });
    return () => {
        cancelled = true;
        dispatch(reset);
    };
}

function subscribeToObservable<V>(arg: Observable<V>, dispatch: Dispatch<Action<V>>): () => void {
    const subscription = arg.subscribe(
        (value) => dispatch({ type: 'next', value: value }),
        (error) => dispatch({ type: 'error', error: error }),
        () => dispatch({ type: 'complete' })
    );
    return () => {
        subscription.unsubscribe();
        dispatch(reset);
    };
}

function normalizeError(error: unknown): Error {
    return error instanceof Error ? error : new Error(String(error));
}

function areDepsEqual(deps: DependencyList, prevDeps?: DependencyList) {
    if (!prevDeps) {
        return false;
    }
    return deps.length === prevDeps.length && deps.every((dep, i) => Object.is(dep, prevDeps[i]));
}
