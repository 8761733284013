type SelectedDashboardType =
    | {
          type: 'new';
          name: string;
      }
    | {
          type: 'existing';
          id: string | undefined;
      };

export interface QueryImporterReducerState {
    dashboard: SelectedDashboardType;
    tileTitle: string;
    defaultTileTitle?: string;
    dataSourceName: string;
    defaultDataSourceName?: string;

    visitDashboard: boolean;
    dedupeDataSource: boolean;
}

export type QueryImporterAction =
    | {
          type: 'initialize';
          defaultTileTitle?: string;
          defaultDataSourceName: string;
      }
    | {
          type: 'updateProperties';
          properties: Omit<Partial<QueryImporterReducerState>, 'defaultDataSourceName' | 'defaultQueryName'>;
      }
    | { type: 'setSelectedDashboardType'; dashboardType: 'new' | 'existing' };

export const initialState: QueryImporterReducerState = {
    dashboard: {
        type: 'existing',
        id: undefined,
    },
    tileTitle: '',
    dataSourceName: '',

    visitDashboard: false,
    dedupeDataSource: true,
};

export const queryImporterReducerFunc = (
    state: QueryImporterReducerState,
    action: QueryImporterAction
): QueryImporterReducerState => {
    switch (action.type) {
        case 'initialize': {
            return {
                ...initialState,
                tileTitle: action.defaultTileTitle ?? initialState.tileTitle,
                defaultTileTitle: action.defaultTileTitle,
                dataSourceName: action.defaultDataSourceName,
                defaultDataSourceName: action.defaultDataSourceName,
            };
        }
        case 'updateProperties': {
            const updatedProperties = {
                ...state,
                ...action.properties,
            };

            if (action.properties.tileTitle !== undefined && action.properties.tileTitle.length < 1) {
                // If no query name entered, use the default name
                updatedProperties.tileTitle = updatedProperties.defaultTileTitle ?? '';
            }

            if (action.properties.dataSourceName !== undefined && action.properties.dataSourceName.length < 1) {
                // If no Data source name entered, use the default name
                updatedProperties.dataSourceName = updatedProperties.defaultDataSourceName ?? '';
            }

            return updatedProperties;
        }
        case 'setSelectedDashboardType': {
            return {
                ...state,
                dedupeDataSource: true,
                dashboard:
                    action.dashboardType === 'new'
                        ? {
                              type: 'new',
                              name: '',
                          }
                        : {
                              type: 'existing',
                              id: undefined,
                          },
            };
        }
    }
};
