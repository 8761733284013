import * as React from 'react';
import { ComboBox, IComboBoxProps } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../res';

import { cannedTimeOptions } from '../constants';

const buttonIconProps = { iconName: 'Clock' };

export interface IDurationTimerMenusProps {
    onChange: IComboBoxProps['onChange'];
    /**
     * Displays the HH:mm time string
     */
    readonly selectedDateTime: string;
}

export const TimePicker: React.FC<IDurationTimerMenusProps> = ({ onChange, selectedDateTime }) => (
    <ComboBox
        title={APP_STRINGS.duration.fixedRangeTimePickerTitle}
        className="aef-TimePicker-Combobox"
        data-automation-id="time-picker"
        options={cannedTimeOptions}
        onChange={onChange}
        text={selectedDateTime}
        allowFreeform={true}
        autoComplete="on"
        buttonIconProps={buttonIconProps}
    />
);
