import * as React from 'react';
import { useState, useCallback } from 'react';
import { Label, Dropdown, Icon, IDropdownOption, IDropdownProps } from 'office-ui-fabric-react';

import { Slot } from '../../../../../../domain';
import { APP_STRINGS } from '../../../../../../res';

import sharedStyles from '../styles.module.scss';

import { SlotCallout } from './SlotCallout';
import { createSlotLabel } from './lib';

import styles from './SlotInput.module.scss';

const appStringsLocal = APP_STRINGS.editTilePage.visualConfig.multiState__slot;

const onRenderCaretDown = () => <Icon iconName="GridViewMedium" />;

const onRenderPlaceholder = (props?: IDropdownProps) => <span className={styles.inputText}>{props?.placeholder}</span>;

const options: IDropdownOption[] = [];

export interface SlotInputProps {
    slot: Slot;
    onChange: (slot: Slot) => void;
    id: string;
    disabled?: boolean;
}

export const SlotInput: React.FC<SlotInputProps> = ({ slot, onChange, id, disabled }) => {
    const [calloutOpen, setCalloutOpen] = useState(false);

    const onCalloutClose = useCallback(() => setCalloutOpen(false), []);
    const onCalloutSave = useCallback(
        (newSlot: Slot) => {
            onChange(newSlot);
            setCalloutOpen(false);
        },
        [onChange]
    );
    const onCalloutOpen = useCallback(() => setCalloutOpen(true), []);

    return (
        <div className={sharedStyles.basicInput}>
            <Label htmlFor={id}>{appStringsLocal.labelText}</Label>
            <Dropdown
                placeholder={createSlotLabel(slot).text}
                onRenderPlaceholder={onRenderPlaceholder}
                options={options}
                id={id}
                onClick={onCalloutOpen}
                onRenderCaretDown={onRenderCaretDown}
                disabled={disabled}
            />
            {calloutOpen && (
                <SlotCallout target={`#${id}`} slot={slot} onClose={onCalloutClose} onSave={onCalloutSave} />
            )}
        </div>
    );
};
