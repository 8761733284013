import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { assertNever } from 'office-ui-fabric-react';

import { ParameterSelectorList } from '../../../components/parameter';
import { Slideout } from '../../../components';

import { ETPState, useETPSelectors } from '../../../store/editTile';
import { useDashboardStore } from '../../../store/dashboard';
import { IParameterSelections } from '../../../store';

import styles from '../styles.module.scss';

const paramterListWrapperSelectors = {
    usedVariables: (state: ETPState) => (state.type === 'query' ? state.usedVariables : undefined),
    stateType: (state: ETPState) => state.type,
};

const deriveOpen = (stateType: ETPState['type'], filteredParameters: readonly unknown[]) => {
    switch (stateType) {
        case 'error':
        case 'loading':
            return true;
        case 'query':
            return filteredParameters.length !== 0;
        case 'markdown':
            return false;
        default:
            assertNever(stateType);
    }
};

export interface ParametersListWrapperProps {
    parameterSelections: undefined | IParameterSelections;
}

export const ParametersListWrapper: React.FC<ParametersListWrapperProps> = observer(function ParametersListWrapper({
    parameterSelections,
}) {
    const { usedVariables, stateType } = useETPSelectors(paramterListWrapperSelectors);
    const store = useDashboardStore();

    const open = deriveOpen(stateType, store.state?.parameters ?? []);

    return (
        <Slideout open={open} className={styles.parameterBar}>
            <ParameterSelectorList
                parameters={store.state?.parameters}
                selectedParameters={parameterSelections}
                activeVariables={usedVariables}
                displayVariables={true}
            />
        </Slideout>
    );
});
