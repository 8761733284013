import * as React from 'react';
import classNames from 'classnames';
import {
    DirectionalHint,
    IDropdownOption,
    Text,
    TooltipHost,
    styled,
    concatStyleSets,
    FontIcon,
    IPalette,
    TooltipDelay,
    KeyCodes,
    IDropdown,
} from 'office-ui-fabric-react';
import { useMemo, useCallback, useRef, useState, useLayoutEffect, MutableRefObject } from 'react';

import { APP_STRINGS } from '../../../res';
import { useIsTruncated } from '../../../common';
import { RTDDropdownProps } from '../../fabric';
import { RTDStatusDropdown } from '../../fabric/dropdown/components/rtdStatusDropdown/RTDStatusDropdown';

import {
    dropdownStyles,
    optionsStyles,
    PILL_MAX_WIDTH,
    errorDropdownStyles,
    limitedWidthDropdownStyles,
} from './styles';
import { useOnRenderTooltipContent } from './useOnRenderTooltipContent';

import styles from './DropdownPill.module.scss';

const nullRender = () => null;

export type DropdownPillSelectionCount = 'all' | 'selectedKeys';

export interface DropdownPillProps extends RTDDropdownProps {
    name: string;

    /**
     * A custom element to be rendered as the "title"s of the selection
     */
    customOptionsChild?: JSX.Element;

    /**
     * A render prop defining a custom element to be rendered inside the Pill's tooltip
     */
    onRenderCustomTooltipChildren?: () => JSX.Element;

    /**
     * If true, don't render the Pill's tooltip
     */
    disableTooltip?: boolean;

    /**
     * If true, don't limit the width of the dropdown input to PILL_MAX_WIDTH
     */
    useContentSizeWidth?: boolean;

    /**
     * If `all` or `selectedKeys`, forcibly display the truncated options, with the number provided
     */
    forceDisplayedSelectionCount?: DropdownPillSelectionCount;

    /**
     * True if allValue exists in the list. Subtracted from the possible count
     */
    containsSelectAll?: boolean;

    loading?: boolean;

    error?: React.ReactNode;
    errorButtonProps?: DropdownPillErrorButtonProps;
}

export interface DropdownPillErrorButtonProps {
    iconName?: string;
    text?: string;
    onClick?: () => void;
}

const calloutProps = {
    directionalHint: DirectionalHint.bottomLeftEdge,
};

/**
 * Must be wrapped in inline-block or flex to properly calculate the dropdown width
 */
const DropdownPillBase: React.FC<DropdownPillProps> = (props) => {
    const {
        theme,
        name,
        options,
        customOptionsChild,
        disableTooltip,
        useContentSizeWidth,
        forceDisplayedSelectionCount,
        containsSelectAll,
        error,
        loading,
        ...dropdownProps
    } = props;

    const dropdownRef = useRef<IDropdown | null>(null);
    const titleRef = useRef<HTMLDivElement | null>(null);
    const [titleWidth, setTitleWidth] = useState(0);

    const onRenderTitle = useMemo(
        () =>
            createOnRenderTitle(
                // Theme should always exist. Bad Fabric types
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                theme!.palette,
                name,
                options.length,
                titleRef,
                customOptionsChild,
                useContentSizeWidth,
                forceDisplayedSelectionCount,
                containsSelectAll,
                props.multiSelect,
                props.disabled,
                !!error
            ),
        [
            name,
            options.length,
            customOptionsChild,
            useContentSizeWidth,
            forceDisplayedSelectionCount,
            containsSelectAll,
            props.multiSelect,
            props.disabled,
            error,
            theme,
        ]
    );
    const onRenderPlaceholder = useCallback(() => onRenderTitle(undefined), [onRenderTitle]);

    const mergedStyles = useMemo(() => {
        const dropdownStylesWithWidth = !useContentSizeWidth
            ? concatStyleSets(dropdownStyles, limitedWidthDropdownStyles)
            : dropdownStyles;

        return error && theme
            ? concatStyleSets(dropdownStylesWithWidth, errorDropdownStyles(theme.palette))
            : dropdownStylesWithWidth;
    }, [useContentSizeWidth, error, theme]);

    const onRenderTooltip = useOnRenderTooltipContent(props);

    // Calculate width of title for progressbar
    useLayoutEffect(() => setTitleWidth(titleRef.current?.clientWidth ?? 0), [name, options]);

    // Prevent using up/down arrow keys to change the current value of a closed
    // dropdown (sending many query executions). Once the dropdown is open, fire
    // events as expected
    const onKeyDown = useCallback((ev: React.KeyboardEvent<HTMLDivElement>) => {
        switch (ev.which) {
            case KeyCodes.up:
            case KeyCodes.down: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (!(dropdownRef.current as any)?.state.isOpen) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (dropdownRef.current as any).setState({ isOpen: true });
                    ev.stopPropagation();
                    ev.preventDefault();
                }
            }
        }
    }, []);

    const dropdown = (
        <div className={styles.wrapper}>
            <RTDStatusDropdown
                {...dropdownProps}
                componentRef={dropdownRef}
                onKeyDown={onKeyDown}
                loading={loading}
                error={error}
                options={options}
                dropdownWidth={PILL_MAX_WIDTH}
                styles={mergedStyles}
                calloutProps={calloutProps}
                onRenderCaretDown={nullRender}
                onRenderTitle={onRenderTitle}
                // Used to represent no selections
                onRenderPlaceholder={onRenderPlaceholder}
                role="listbox"
            />
            {loading && (
                <div className={styles.progressBarWrapper} style={{ width: titleWidth }}>
                    <div className={styles.progressBar} role="progressbar"></div>
                </div>
            )}
        </div>
    );

    return (
        // Use `onRenderContent`, rather than `content`, to defer the calculation of the tooltip component
        !disableTooltip ? (
            <TooltipHost
                // Delay is longer than default to avoid accidentally opening when the user wants to open the dropdown
                delay={TooltipDelay.long}
                tooltipProps={onRenderTooltip ? { onRenderContent: onRenderTooltip } : undefined}
            >
                {dropdown}
            </TooltipHost>
        ) : (
            dropdown
        )
    );
};

export const DropdownPill: React.FC<DropdownPillProps> = styled<
    DropdownPillProps,
    unknown,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
>(DropdownPillBase, {}, undefined, {
    scope: 'DropdownPill',
});

// Separate function to allow React to have a stable component ref, rather than constantly recreating it through `useCallback`
const createOnRenderTitle =
    (
        palette: IPalette,
        name: string,
        totalOptionsCount: number,
        titleRef: MutableRefObject<HTMLDivElement | null>,
        customOptionsChild?: JSX.Element,
        useContentSizeWidth?: boolean,
        forceDisplayedSelectionCount?: DropdownPillSelectionCount,
        containsSelectAll?: boolean,
        multiselect?: boolean,
        disabled?: boolean,
        error?: boolean
    ) =>
    (options: IDropdownOption[] | undefined) =>
        (
            <RenderTitle
                palette={palette}
                name={name}
                customOptionsChild={customOptionsChild}
                options={options}
                totalOptionsCount={totalOptionsCount}
                useContentSizeWidth={useContentSizeWidth}
                forceDisplayedSelectionCount={forceDisplayedSelectionCount}
                containsSelectAll={containsSelectAll}
                multiselect={multiselect}
                disabled={disabled}
                error={error}
                titleRef={titleRef}
            />
        );

const buildTitleOptionsString = (
    options: IDropdownOption[] | undefined,
    totalOptionsCount: number,
    isTruncated: boolean | undefined,
    forceDisplayedSelectionCount?: DropdownPillSelectionCount,
    containsSelectAll?: boolean
): string => {
    if (forceDisplayedSelectionCount === 'all') {
        return APP_STRINGS.domain.parameter.selection.allTitle;
    } else if (forceDisplayedSelectionCount !== 'selectedKeys' && !isTruncated) {
        // Either false or undefined
        return options?.map((o) => o.text).join(', ') ?? APP_STRINGS.domain.parameter.selection.none;
    }

    // isTruncated
    return `${options?.length ?? 0} of ${containsSelectAll ? totalOptionsCount - 1 : totalOptionsCount} selected`;
};

const RenderTitle: React.FC<{
    palette: IPalette;

    name: string;
    customOptionsChild?: JSX.Element;

    options: IDropdownOption[] | undefined;
    totalOptionsCount: number;

    useContentSizeWidth?: boolean;
    forceDisplayedSelectionCount?: DropdownPillSelectionCount;
    containsSelectAll?: boolean;

    multiselect?: boolean;
    disabled?: boolean;
    loading?: boolean;
    error?: boolean;

    titleRef: MutableRefObject<HTMLDivElement | null>;
}> = ({
    palette,
    name,
    customOptionsChild,
    options,
    totalOptionsCount,
    useContentSizeWidth,
    forceDisplayedSelectionCount,
    containsSelectAll,
    multiselect,
    disabled,
    error,

    titleRef,
}) => {
    const { isTruncated, parentRef, childRef } = useIsTruncated('width', [name, options]);
    const finalIsTruncated = isTruncated && multiselect;

    // If no options, must be placeholder
    const isPlaceholder = options === undefined;
    const augmentColor = isPlaceholder && !disabled;

    return (
        <div ref={titleRef} className={classNames(styles.title, { [styles.augmentColor]: augmentColor })}>
            {error && <FontIcon className={styles.titleIcon} iconName="Info" style={{ color: palette.redDark }} />}
            <Text className={styles.name}>{name}</Text>
            <Text className={styles.separator}>:</Text>
            {/* If truncated, always display whole selection string, otherwise, allow shrinking (for truncation calculation) */}
            <div
                ref={parentRef}
                className={finalIsTruncated ? `${styles.options} ${styles.noShrink}` : styles.options}
                style={!useContentSizeWidth ? { maxWidth: PILL_MAX_WIDTH } : undefined}
            >
                {customOptionsChild ?? (
                    <Text styles={optionsStyles}>
                        <div ref={childRef} className={styles.optionsInline}>
                            {buildTitleOptionsString(
                                options,
                                totalOptionsCount,
                                finalIsTruncated,
                                forceDisplayedSelectionCount,
                                containsSelectAll
                            )}
                        </div>
                    </Text>
                )}
            </div>
        </div>
    );
};
