import * as React from 'react';
import { createContext, useEffect, useContext, useRef } from 'react';

import { assertUnreachable } from '../../../common';

/**
 * TODO: Someone please come up with better names 🙇‍♂️
 * TODO: Should we use this for the whole app?
 */

class FlushHandles {
    public handles: Set<() => void | Promise<void>> = new Set();

    constructor() {
        this.flush = this.flush.bind(this);
    }

    async flush() {
        for (const flush of this.handles) {
            await flush();
        }
    }
}

const flushHandlesContext = createContext<FlushHandles>({
    handles: new Set(),
    flush: () => assertUnreachable('default flush handles context flush function'),
});

export const useFlushHandles = () => useContext(flushHandlesContext);

export const FlushHandlesProvider: React.FC = ({ children }) => {
    const handles = useRef<undefined | FlushHandles>();

    if (handles.current === undefined) {
        handles.current = new FlushHandles();
    }

    return (
        <flushHandlesContext.Provider value={handles.current as FlushHandles}>{children}</flushHandlesContext.Provider>
    );
};

/**
 * @returns stable function that runs all the flush functions passed to useFlushable
 */
export const useFlush = () => {
    const handles = useFlushHandles();

    return handles.flush;
};

/**
 * Right now we'll have a item in the flush handles context for each thing that
 * is debounced. If this gets out of hand we could refactor things so debounced
 * functions would only add themselves to the handles on call, and remove
 * themselves when they don't have anything pending.
 */
export const useRegisterDebounce = (debounced: { flush: () => void; cancel: () => void }) => {
    const handles = useFlushHandles();

    useEffect(() => {
        handles.handles.add(debounced.flush);
        return () => {
            debounced.cancel();
            handles.handles.delete(debounced.flush);
        };
    }, [debounced, handles]);
};
