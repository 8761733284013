import type { ITile } from '../../../domain';

import type { ITileSerialized } from './versions';

export * as tile from './versions';

export function serializeTile({ usedVariables, visualOptions, ...shared }: ITile): ITileSerialized {
    return {
        ...shared,
        visualOptions,
        usedParamVariables: [...usedVariables],
    };
}

export function deserializeTile({ usedParamVariables, ...shared }: ITileSerialized): ITile {
    return {
        ...shared,
        usedVariables: new Set(usedParamVariables),
    };
}
