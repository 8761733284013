import React from 'react';
import { computed } from 'mobx';

import { BasicParamConfig } from '../models';
import { DashboardStore, IParameterSelections } from '../../../store';
import { DurationParamConfig, ParameterValue } from '../models';
import { err, ok, Result } from '../../../common';

export function useParentParameterValues<T, K extends string>(
    dashboardStore: DashboardStore,
    selections: IParameterSelections,
    parameterConfig: DurationParamConfig | BasicParamConfig<T, K>
): Result<ParameterValue[], { title: string; body?: string }> {
    return React.useMemo(
        () =>
            computed(() => {
                if (
                    parameterConfig.kind === 'duration' ||
                    parameterConfig.options.selectionKind === 'freetext' ||
                    parameterConfig.options.dataSource.kind !== 'query'
                ) {
                    return ok([]);
                }
                if (dashboardStore.state === undefined) {
                    return err({ title: 'Loading' });
                }

                return selections.resolveParameterValues(parameterConfig.options.dataSource.consumedVariables);
            }),
        [dashboardStore, selections, parameterConfig]
    ).get();
}
