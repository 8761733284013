import * as React from 'react';
import { useCallback } from 'react';
import { IDropdownOption } from 'office-ui-fabric-react';

import { RTDDropdown, RTDDropdownProps } from '../../../../../components';
import { dropdownIconRenderProps } from '../../../../../components/fabric/dropdown/dropdownIconRenderProps/dropdownIconRenderProps';
import { useETPDispatch } from '../../../../../store/editTile';

/**
 * Includes icon and onChange code that is shared between visual type and visual
 * type variant pickers
 */
export const VisualTypePicker: React.FC<RTDDropdownProps> = (props) => {
    const [dispatch] = useETPDispatch();

    const handleChangeVisualType = useCallback(
        (_: unknown, option?: IDropdownOption) =>
            dispatch({
                type: 'updateVisualType',
                visualType: option?.key as string,
            }),
        [dispatch]
    );

    return <RTDDropdown {...dropdownIconRenderProps} onChange={handleChangeVisualType} {...props} />;
};
