import * as React from 'react';
import { useCallback, useState } from 'react';
import moment from 'moment';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

import type { StreamQueryResult, QueryHash } from '../../../domain';
import { useCore } from '../../../core';
import { useAsync, useSynchronizedInterval } from '../../../common';
import { APP_STRINGS } from '../../../res';

import styles from './Header.module.scss';

export interface TileUpdateIndicatorProps {
    queryHash: QueryHash;
}

const UPDATE_SYNC_INTERVAL = 10000;

function calculateInterval(time?: Date | undefined) {
    if (time) {
        return (
            moment(time)
                .fromNow()
                // the following text moment generates is the first one seen after running a query.
                // unfortunately it is too long to accommodate a 3x3 tile (which is the default size for query card, for example).
                // Thus changing seconds to secs (after consulting with design)
                // This should be solved in a more general way (especially once we start translating to other languages).
                .replace('a few seconds ago', 'a few secs ago')
        );
    }
    return APP_STRINGS.time.now;
}

export const TileUpdateIndicator: React.FC<TileUpdateIndicatorProps> = ({ queryHash }) => {
    const { queryService } = useCore();
    const { value } = useAsync<StreamQueryResult>(() => queryService.observeQuery(queryHash), [queryHash]);

    let updatedTime: undefined | Date;

    if (value === undefined) {
        updatedTime = undefined;
    } else if (value.kind === 'success') {
        updatedTime = value.result.receivedTime;
    } else if (value.kind === 'err' && value.didRun) {
        updatedTime = value.receivedTime;
    } else if (value.status?.kind === 'done') {
        updatedTime = value.status.endTime;
    } else {
        updatedTime = undefined;
    }

    const [currentInterval, setCurrentInterval] = useState(() => calculateInterval(updatedTime));

    const callback = useCallback(() => setCurrentInterval(calculateInterval(updatedTime)), [updatedTime]);
    useSynchronizedInterval(UPDATE_SYNC_INTERVAL, callback);

    return (
        <TooltipHost
            hostClassName={styles.lastUpdatedText}
            content={updatedTime ? updatedTime.toString() : APP_STRINGS.time.now}
        >
            {APP_STRINGS.time.formatAsOf} {currentInterval}
        </TooltipHost>
    );
};
