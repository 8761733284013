import type { CanInfer } from '../../common';

/**
 * Changes to this are blocked on either improvements to how we migrate the user
 * profile, or switching to a different system for managing this data.
 *
 * This is because currently user profile changes block old clients from loading
 * at all, and the user profile is broken enough as is that we'd rather not fuss
 * with it util we have a plan.
 *
 * The user profile is broken because changes to the profile on one client
 * instance will destroy changes made to the profile after the client was
 * started. For example, if we favorite a dashboard on one client, and then open
 * the pages nav on another, the change to favorites is lost.
 */
export interface UserSettings {
    theme: 'light' | 'dark' | 'automatic';
    language: CanInfer<string>;
    regionalFormat: CanInfer<string>;
    enableChartPerformanceMode: boolean;
    enableAccessabilityOptimizationMode: boolean;
    favoriteDashboards: Set<string>;
    // TODO: Rename to "pagesNavPinned"
    pageNavOpen: boolean;
    editTilePagePreviewExpanded: boolean;
    editTilePageDividerPositionPercentage: number;
}

export const availableFeatureFlags = [
    // internal flag includes exportToJson
    'internal',
    'cross-filter',
    'timezone',
] as const;

export type FeatureFlag = typeof availableFeatureFlags[number];
