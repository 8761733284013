import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { useState } from 'react';

import { Slideout } from '../../../../components';

import styles from './CodeSample.module.scss';

export interface CodeSampleProps {
    content: JSX.Element;

    className?: string;

    defaultShow?: boolean;
}

export const CodeSample: React.FC<CodeSampleProps> = ({ className, content, defaultShow = false }) => {
    const [isOpen, setIsOpen] = useState(defaultShow);

    return (
        <div className={className}>
            <DefaultButton onClick={() => setIsOpen((prev) => !prev)}>
                {isOpen ? 'Hide sample' : 'Show sample'}
            </DefaultButton>
            <Slideout open={isOpen}>
                <div className={styles.sample}>
                    <div className={styles.sampleContent}>{content}</div>
                </div>
            </Slideout>
        </div>
    );
};
