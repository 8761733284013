import * as React from 'react';
import { Toggle } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';

import { env } from '../res';
import { useCore } from '../core';

import { RtdHeader } from '../components/RtdHeader';
import { RTDPanel } from '../components';

import { FeatureFlagToggles } from './FeatureFlagToggles';
import { useDebugFlagsContext, debugFlags } from './debugFlags';
import { JsonUpload } from './JsonUpload';

import styles from './DevMenu.module.scss';

const DevMenuInner: React.FC<{ close: () => void }> = ({ close }) => {
    const [open, setOpen] = React.useState(true);
    const [debugFlagStatus, setDebugFlag] = useDebugFlagsContext();

    const { userService } = useCore();
    const currentUser = userService.getCurrentUser();

    return (
        <RTDPanel
            isOpen={open}
            headerText="Dashboards developer menu"
            onDismiss={() => setOpen(false)}
            onDismissed={close}
            isFooterAtBottom={true}
            isLightDismiss={true}
            className={styles.panel}
            onRenderFooterContent={() => (
                <>
                    {currentUser && (
                        <div className={styles.currentUser}>
                            <p>
                                {currentUser.upn}
                                <br />
                                id: {currentUser.oid}
                                <br />
                                tenant: {currentUser.tenantId}
                                <br />
                                RTD version: {env.rtdVersion}
                            </p>
                        </div>
                    )}
                </>
            )}
        >
            <section>
                <RtdHeader level={3}>Feature flags</RtdHeader>
                <FeatureFlagToggles />
            </section>
            <section>
                <RtdHeader level={3}>Debug flags</RtdHeader>
                {debugFlags.map((flag) => (
                    <Toggle
                        key={flag}
                        onText={flag}
                        offText={flag}
                        checked={debugFlagStatus[flag] ?? false}
                        onChange={(_: unknown, checked?: boolean) => setDebugFlag(flag, !!checked)}
                    />
                ))}
            </section>
            <JsonUpload />
        </RTDPanel>
    );
};

export const DevMenu: React.FC = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (
                event.altKey &&
                event.shiftKey &&
                // event.code === 'KeyD' indicates the physical key on the keyboard
                // as opposed to event.key === 'D' which indicates the character that
                // was inputted which may or may not correspond to the physical
                // key
                event.code === 'KeyD'
            ) {
                setOpen(true);
            }
        };

        document.addEventListener('keyup', listener);

        return () => document.removeEventListener('keyup', listener);
    }, []);

    if (open) {
        return <DevMenuInner close={() => setOpen(false)} />;
    }
    return null;
};
