import { boolValueImpl } from './bool';
import { datetimeValueImpl } from './datetime';
import { decimalValueImpl } from './decimal';
import { float64ValueImpl } from './float64';
import { int32ValueImpl } from './int32';
import { int64ValueImpl } from './int64';
import { stringValueImpl } from './string';

export const valueImpl = {
    bool: boolValueImpl,
    datetime: datetimeValueImpl,
    decimal: decimalValueImpl,
    float64: float64ValueImpl,
    int32: int32ValueImpl,
    int64: int64ValueImpl,
    string: stringValueImpl,
};
