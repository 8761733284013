import { createContext, useContext } from 'react';
import type { DashboardStore } from './DashboardStore';

export const dashboardStoreContext = createContext<DashboardStore | undefined>(undefined);

dashboardStoreContext.displayName = 'Dashboard editor store';

export const useDashboardStore = () => {
    const state = useContext(dashboardStoreContext);
    if (state === undefined) {
        throw new Error();
    }
    return state;
};
