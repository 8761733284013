import * as React from 'react';
import { useCallback, Dispatch, useMemo } from 'react';
import { TextField, IDropdownOption } from 'office-ui-fabric-react';

import {
    ColorRule,
    colorRulesIcons,
    colorRulesOperators,
    colorRulesIconToIcon,
    colorRulesColors,
    ColorResolutionSet,
} from '../../../../../../../domain';
import { RTDDropdownOption, RTDDropdown } from '../../../../../../../components';
import { CanInfer, useThunkReducer } from '../../../../../../../common';
import { APP_STRINGS } from '../../../../../../../res';
import {
    DropdownIconOption,
    dropdownIconRenderProps,
} from '../../../../../../../components/fabric/dropdown/dropdownIconRenderProps/dropdownIconRenderProps';

import { SingleColumnDropdown } from '../../columnDropdowns';
import { ConfigurationItemCallout } from '../../configurationList/ConfigurationListCallout/ConfigurationItemCallout';
import { colorRuleCalloutReducer, ColorRuleCalloutActions } from './reducer';

import styles from './ColorRuleCallout.module.scss';

const appStringsLocal = APP_STRINGS.editTilePage.visualConfig.colorRules;

const conditionOptions: RTDDropdownOption[] = colorRulesOperators.map((c) => ({
    text: c,
    key: c,
}));

const disableEnabledConditionOptions: RTDDropdownOption[] = [
    { text: appStringsLocal.noConditionOperatorText, key: 'none' },
    ...conditionOptions,
];

const indicatorTypeOptions: RTDDropdownOption[] = [
    { key: 'text', text: appStringsLocal.indicatorTypeDropdownOptionsText.text },
    { key: 'icon', text: appStringsLocal.indicatorTypeDropdownOptionsText.icon },
    { key: 'none', text: appStringsLocal.indicatorTypeDropdownOptionsText.none },
];

const ConditionInput: React.FC<{
    index: 0 | 1;
    dispatch: Dispatch<ColorRuleCalloutActions>;
    condition: ColorRule.Condition | undefined;
}> = ({ index, dispatch, condition }) => {
    const onValueChange = useCallback(
        (_: unknown, value?: string) => {
            if (value !== undefined) {
                dispatch({ type: 'setConditionValueAtIndex', index, value });
            }
        },
        [index, dispatch]
    );
    const onOperatorChange = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (!option) {
                return;
            }
            if (option.key === 'none') {
                dispatch({ type: 'removeSecondCondition' });
            } else {
                dispatch({
                    type: 'setConditionOperatorAtIndex',
                    index,
                    operator: option.key as ColorRule.Operator,
                });
            }
        },
        [index, dispatch]
    );

    return (
        <>
            <RTDDropdown
                options={index === 0 ? conditionOptions : disableEnabledConditionOptions}
                selectedKey={condition?.operator ?? null}
                placeholder={appStringsLocal.noConditionOperatorText}
                label={`${appStringsLocal.conditionOperatorLabel} ${index}`}
                onChange={onOperatorChange}
            />
            <TextField
                value={condition?.value ?? ''}
                disabled={condition === undefined}
                label={appStringsLocal.conditionValueLabel}
                onChange={onValueChange}
            />
        </>
    );
};

const ColorIcon: React.FC<{ data: string }> = ({ data }) => (
    <span className={styles.colorIcon} style={{ backgroundColor: data }} />
);

const useColorOptions = (colors: ColorResolutionSet) =>
    useMemo(
        (): Array<DropdownIconOption<string>> =>
            colorRulesColors.map((c) => ({
                text: APP_STRINGS.visuals.colorRulesColorLabels[c],
                key: c,
                data: colors[c].color,
                icon: ColorIcon,
            })),
        [colors]
    );

const iconOptions: DropdownIconOption[] = colorRulesIcons.map((icon) => ({
    title: APP_STRINGS.visuals.colorRulesIconsLabels[icon],
    text: '',
    key: icon,
    icon: colorRulesIconToIcon[icon],
}));

export interface ColorRuleCalloutProps {
    rule: ColorRule;
    onClose: () => void;
    onSave: (rule: ColorRule) => void;
    targetRef: React.RefObject<Element>;
    colors: ColorResolutionSet;
}

export const ColorRuleCallout: React.FC<ColorRuleCalloutProps> = ({ rule, onClose, onSave, targetRef, colors }) => {
    const [state, dispatch, getState] = useThunkReducer(colorRuleCalloutReducer, rule);

    const innerOnSave = useCallback(() => onSave(getState()), [onSave, getState]);

    const onIconChange = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({ type: 'setIcon', icon: option.key as ColorRule.Icon });
            }
        },
        [dispatch]
    );

    const onColorChange = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({ type: 'setColor', color: option.key as ColorRule.Color });
            }
        },
        [dispatch]
    );

    const onIndicatorTypeChange = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option) {
                dispatch({
                    type: 'setIndicatorType',
                    indicatorType: option.key as 'none' | 'text' | 'icon',
                });
            }
        },
        [dispatch]
    );

    const onColumnChange = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (option && option.data) {
                dispatch({ type: 'setColumn', column: option.data });
            }
        },
        [dispatch]
    );

    const onLabelChange = useCallback(
        (_: unknown, value?: string) => dispatch({ type: 'setLabel', label: value ?? '' }),
        [dispatch]
    );

    const colorOptions = useColorOptions(colors);

    let labelInput: undefined | { value: CanInfer<string>; default: string; label: string };

    switch (state.indicator.kind) {
        case 'none':
            labelInput = undefined;
            break;
        case 'icon':
            labelInput = {
                value: state.indicator.label,
                default: APP_STRINGS.visuals.colorRulesIconsLabels[state.indicator.icon],
                label: appStringsLocal.iconIndicatorLabelInputLabel,
            };
            break;
        case 'text':
            labelInput = {
                value: state.indicator.text,
                default: APP_STRINGS.visuals.colorRulesInferLabels[state.color],
                label: appStringsLocal.textIndicatorInputLabel,
            };
            break;
    }

    return (
        <ConfigurationItemCallout
            title={appStringsLocal.colorRuleCalloutTitle}
            onSave={innerOnSave}
            onClose={onClose}
            targetRef={targetRef}
        >
            <>
                <SingleColumnDropdown
                    className={styles.fullWidth}
                    label={appStringsLocal.columnDropdownLabel}
                    id="etp--color-rules-column"
                    selectedColumn={state.column}
                    onChange={onColumnChange}
                />
                <ConditionInput index={0} condition={state.conditions[0]} dispatch={dispatch} />
                <ConditionInput index={1} condition={state.conditions[1]} dispatch={dispatch} />
                <RTDDropdown
                    {...dropdownIconRenderProps}
                    onChange={onColorChange}
                    className={styles.fullWidth}
                    options={colorOptions}
                    selectedKey={state.color}
                    label={appStringsLocal.highlightColorDropdownLabel}
                />
                <RTDDropdown
                    onChange={onIndicatorTypeChange}
                    className={styles.fullWidth}
                    options={indicatorTypeOptions}
                    selectedKey={state.indicator.kind}
                    label={appStringsLocal.indicatorTypeDropdownLabel}
                />
                {state.indicator.kind === 'icon' && (
                    <RTDDropdown
                        {...dropdownIconRenderProps}
                        onChange={onIconChange}
                        className={styles.fullWidth}
                        options={iconOptions}
                        selectedKey={state.indicator.icon}
                        placeholder={appStringsLocal.noIconSelectedText}
                        label={appStringsLocal.iconDropdownLabel}
                    />
                )}
                {labelInput && (
                    <TextField
                        onChange={onLabelChange}
                        value={labelInput.value.type !== 'infer' ? labelInput.value.value : ''}
                        className={styles.fullWidth}
                        placeholder={labelInput.default}
                        label={labelInput.label}
                        autoComplete="off"
                    />
                )}
            </>
        </ConfigurationItemCallout>
    );
};
