import * as React from 'react';
import { useMemo } from 'react';
import uniq from 'lodash/uniq';
import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';

import { APP_STRINGS } from '../../../../res';
import { useGlobalDispatch } from '../../../../store/redux/context';
import { DashboardChanges, DashboardLoaded } from '../../../../store/dashboard';
import { paramterPreviewActionKey } from '../constants';
import { buildUniquelyNamedParameter, ParameterConfig } from '../../../../domain';
import { IOverflowMenuItem } from '../../../fabric';

import { ManageModelPreview } from './ManageModelPreview';
import { deleteParameterDialogAction } from './parameter/DeleteParameterDialog';
interface IParameterPreviewProps {
    parameter: ParameterConfig;
    dashboardState: DashboardLoaded;
    dashboardChanges: DashboardChanges;
}

export const ParameterPreview: React.FC<IParameterPreviewProps> = observer(function ParameterPreview({
    parameter,
    dashboardState,
    dashboardChanges,
}) {
    const [dispatch] = useGlobalDispatch();

    const variables = parameter.variableNames;

    const dependentTileRecordsVariables = dashboardState.dependentTileRecords.variables;

    const usingTiles = useMemo(() => {
        const variableSet = new Set(variables);

        // Fixed #6972198: Use lodash uniq rather than converting to a set then back to an array
        return uniq(
            Object.keys(dependentTileRecordsVariables)
                .filter((variableName) => variableSet.has(variableName))
                .flatMap((variableName) => [...dependentTileRecordsVariables[variableName]])
        );
    }, [dependentTileRecordsVariables, variables]);

    const dependentParams = dashboardState.variableToDependentParameterIds;
    const usedParameterIds = useMemo(
        () => variables.flatMap((v) => dependentParams[v] ?? []),
        [dependentParams, variables]
    );

    const isPinned = dashboardState.pinnedParameters[parameter.id] ?? false;

    const menuItems = useMemo(
        (): IOverflowMenuItem[] => [
            {
                key: 'edit',
                name: APP_STRINGS.utilButtons.edit,
                onClick: action(() => {
                    dashboardChanges.editParameter = parameter.id;
                    dispatch({
                        type: 'closeActiveForm',
                        key: paramterPreviewActionKey,
                    });
                }),
            },
            {
                key: 'duplicate',
                name: APP_STRINGS.forms.parameter.previewMenu.duplicate,
                onClick: action(() => {
                    const id = v4();

                    dashboardState.addItem(
                        'parameters',
                        buildUniquelyNamedParameter(id, parameter, dashboardState.parameters)
                    );

                    if (parameter.id in dashboardState.pinnedParameters) {
                        dashboardChanges.pinnedParameters.add(id);
                    }
                }),
            },
            {
                key: 'delete',
                name: APP_STRINGS.utilButtons.delete,
                onClick: () => {
                    // Create delete dialog
                    dispatch(
                        deleteParameterDialogAction({
                            parameterName: parameter.displayName,
                            usedTileIds: usingTiles,
                            usedParameterIds,
                            onConfirm: action(() => dashboardState.deleteItem('parameters', parameter.id)),
                        })
                    );
                },
            },
            {
                key: 'pin',
                name: isPinned
                    ? APP_STRINGS.forms.parameter.previewMenu.unpin
                    : APP_STRINGS.forms.parameter.previewMenu.pin,
                onClick: action(() => {
                    const pinnedParameters = dashboardChanges.pinnedParameters;
                    if (pinnedParameters.has(parameter.id)) {
                        pinnedParameters.delete(parameter.id);
                    } else {
                        pinnedParameters.add(parameter.id);
                    }
                }),
            },
        ],
        [isPinned, dashboardChanges, parameter, dispatch, dashboardState, usingTiles, usedParameterIds]
    );

    return (
        <ManageModelPreview
            title={parameter.displayName}
            variableText={variables.join(', ')}
            overflowAriaLabel={APP_STRINGS.forms.parameter.previewMenu.ariaLabel}
            menuItems={menuItems}
            statusItems={[
                {
                    type: 'pages',
                    isPinned: parameter.id in dashboardState.pinnedParameters,
                },
                { type: 'tiles', usedTileIds: usingTiles },
                {
                    type: 'parameters',
                    usedParameterIds: usedParameterIds,
                },
            ]}
        />
    );
});
