import * as React from 'react';
import * as fuzzysort from 'fuzzysort';
import debounce from 'lodash/debounce';
import { SearchBox, Announced, PivotItem, Pivot, assertNever } from 'office-ui-fabric-react';
import { useEffect, useCallback, useState, useMemo, useLayoutEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { computed, observable, runInAction } from 'mobx';

import { APP_CONSTANTS, APP_STRINGS } from '../../res';
import { useCore, withTelemetry } from '../../core/react';
import { TypedGraphEntityId, UserOrGroupResponse } from '../../core/graph/IGraphService';
import { GlobalAction, ICatalogPageState, useGlobalDispatch, useGlobalSelector } from '../../store';
import { IReduxState, useGlobalSelectors } from '../../store';
import { IDashboardListItem } from '../../core/domain';
import { parseException, usePullState, useAbortController, isAbortError } from '../../common';
import { rtdPrompt } from '../../components';

import { Catalog } from './components/Catalog';
import { CatalogPageContext } from './CatalogPageContent';

import styles from './catalogPage.module.scss';

type Creators = { kind: 'ok'; results: UserOrGroupResponse } | { kind: 'error'; message: string } | { kind: 'loading' };

const debounceSearchQuery = debounce(
    (query: string, setSearchQuery: React.Dispatch<GlobalAction>) =>
        setSearchQuery({ type: 'setCatalogSearch', catalogSearch: query }),
    APP_CONSTANTS.debounce.standard
);

const selectors = {
    activeCatalogTab: (s: IReduxState) => s.catalogPage.catalogTab,
    dashboardList: (s: IReduxState) => s.catalogPage.dashboardList,
};

const pivotStyles = { root: { marginLeft: '24px' } };

const InnerCatalogPage: React.FC = observer(function InnerCatalogPage() {
    const core = useCore();
    const [dispatch] = useGlobalDispatch();
    const abortController = useAbortController();
    // Type added because useGlobalSelectors isn't typed correctly
    const {
        activeCatalogTab,
        dashboardList,
    }: {
        activeCatalogTab: ICatalogPageState['catalogTab'];
        dashboardList: IDashboardListItem[];
    } = useGlobalSelectors(selectors);

    const [dashboardsLoading, setDashboardsLoading, getDashboardsLoading] = usePullState(false);

    const fetchDashboards = useCallback(async () => {
        if (getDashboardsLoading()) {
            return;
        }
        setDashboardsLoading(true);
        try {
            const res = await core.dashboardService.getDashboards(abortController.signal);
            dispatch({ type: 'setDashboardList', dashboardList: res });
        } catch (err) {
            if (isAbortError(err)) {
                return;
            }
            rtdPrompt(dispatch, APP_STRINGS.catalogPage.errorLoadingDashboardsTitle, { subText: parseException(err) });
        }
        setDashboardsLoading(false);
    }, [core, dispatch, getDashboardsLoading, setDashboardsLoading, abortController]);

    useLayoutEffect(() => {
        fetchDashboards();
    }, [fetchDashboards]);

    const searchQuery = useGlobalSelector((s) => s.catalogPage.catalogSearch);
    const userSettings = core.userSettingsState.userSettings;

    const [creators, setCreators] = useState<Creators>({ kind: 'loading' });

    const isLoading = dashboardsLoading || creators.kind === 'loading';
    const favoriteDashboardsObservable = useLocalObservable(
        () => ({
            userSettings,
            get favoriteDashboards() {
                return new Set(this.userSettings.favoriteDashboards);
            },
        }),
        { userSettings: observable.ref, favoriteDashboards: computed }
    );

    useEffect(
        () =>
            runInAction(() => {
                favoriteDashboardsObservable.userSettings = userSettings;
            }),
        [favoriteDashboardsObservable, userSettings]
    );

    const [allTabList, recentTabList, favoriteTabList] = useMemo(() => {
        if (!dashboardList) {
            return [undefined, undefined, undefined];
        }

        const list = dashboardList.map((d) => ({
            ...d,
            createdBy: (creators?.kind === 'ok' ? creators.results[d.createdBy]?.displayName : undefined) ?? '--',
        }));

        const applySearchFilter = (l: IDashboardListItem[]) => {
            if (searchQuery) {
                return fuzzysort
                    .go<IDashboardListItem>(searchQuery, l, {
                        keys: ['title', 'createdBy'],
                        threshold: -10000,
                    })
                    .map((result) => result.obj);
            }
            return l;
        };

        const queryFiltered = applySearchFilter(list);
        // Search filter should be applied to the original recent dashboards
        const recentList: IDashboardListItem[] = applySearchFilter(
            list.sort((a, b) => b.openedAt - a.openedAt).slice(0, 10)
        );
        const favoriteList = queryFiltered.filter((p) => favoriteDashboardsObservable.favoriteDashboards.has(p.id));

        return [queryFiltered, recentList, favoriteList];
    }, [favoriteDashboardsObservable.favoriteDashboards, dashboardList, creators, searchQuery]);

    let tabList: IDashboardListItem[] | undefined;
    switch (activeCatalogTab) {
        case 'recents':
            tabList = recentTabList;
            break;
        case 'favorites':
            tabList = favoriteTabList;
            break;
        case 'all':
            tabList = allTabList;
            break;
        default:
            assertNever(activeCatalogTab);
    }

    const onChangeSearch = useCallback(
        (_e: unknown, query?: string) => {
            if (query !== undefined) {
                debounceSearchQuery(query, dispatch);
            }
        },
        [dispatch]
    );

    const onClearSearch = useCallback(() => dispatch({ type: 'setCatalogSearch', catalogSearch: '' }), [dispatch]);

    useEffect(() => {
        if (!dashboardList) {
            return;
        }

        setCreators({ kind: 'loading' });
        const creatorList: TypedGraphEntityId[] | undefined = dashboardList.map((p) => ({
            id: p.createdBy,
            type: 'user',
        }));

        let cancelled = false;
        core.graphService
            .getUsersOrGroups(creatorList)
            .then((results) => {
                if (!cancelled) {
                    setCreators({ kind: 'ok', results });
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                if (cancelled) {
                    return;
                }
                setCreators({ kind: 'error', message: parseException(error) });
            });
        return () => {
            cancelled = true;
        };
    }, [dashboardList, core, setCreators]);

    const onPivotLinkClick = useCallback(
        (item?: PivotItem) => {
            if (item && item.props.itemKey) {
                dispatch({
                    type: 'setCatalogTab',
                    catalogTab: item.props.itemKey as ICatalogPageState['catalogTab'],
                });
                dispatch({
                    type: 'setCatalogSort',
                    catalogSortField: item.props.itemKey === 'recents' ? 'openedFromNow' : 'title',
                    catalogSortDescending: false,
                });
            }
        },
        [dispatch]
    );

    // using Announced component instead of aria-live because:
    //  - this gives a custom message for results
    //  - aria-live attribute on SearchBox re-reads ariaLabel
    //  - aria-live attribute for the catalog would be on the details list, but this leads to reading out the details list
    //  when not in focus.
    return (
        <CatalogPageContext onRefresh={fetchDashboards}>
            <SearchBox
                className={styles.searchBar}
                ariaLabel={APP_STRINGS.catalogPage.filterDashboardsInput.ariaLabel}
                placeholder={APP_STRINGS.catalogPage.filterDashboardsInput.searchPlaceholder}
                value={searchQuery}
                onChange={onChangeSearch}
                onClear={onClearSearch}
            />
            {searchQuery && tabList && (
                <Announced
                    message={`${tabList.length} ${APP_STRINGS.catalogPage.filterDashboardsInput.filterCompleteNarratorMessage}`}
                />
            )}
            <Pivot styles={pivotStyles} onLinkClick={onPivotLinkClick} selectedKey={activeCatalogTab}>
                <PivotItem
                    headerText={`${APP_STRINGS.catalogPage.pivots.all} (${allTabList?.length ?? 0})`}
                    itemKey="all"
                />
                <PivotItem
                    headerText={`${APP_STRINGS.catalogPage.pivots.recents} (${recentTabList?.length ?? 0})`}
                    itemKey="recents"
                />
                <PivotItem
                    headerText={`${APP_STRINGS.catalogPage.pivots.favorites} (${favoriteTabList?.length ?? 0})`}
                    itemKey="favorites"
                />
            </Pivot>
            <Catalog
                dashboardList={tabList}
                isLoading={isLoading}
                isFiltering={!!searchQuery}
                creatorFetchErrorMessage={creators.kind === 'error' ? creators.message : undefined}
            />
        </CatalogPageContext>
    );
});

export const CatalogPage = withTelemetry(InnerCatalogPage, 'CatalogPage', styles.telemetryWrapper);
