import type { Infer } from '../../common';

import type { ColorRule } from './types';

export const infer: Infer = { type: 'infer' };

/**
 * Internal identifiers. Our host app should map these to actual color values,
 * and we should have copy for each of them.
 */
export const colorRulesColors = ['red', 'yellow', 'green', 'blue'] as const;

/**
 * These are both internal identifiers, and user facing
 */
export const colorRulesOperators = ['>', '>=', '<', '<=', '=='] as const;

/**
 * Identifiers are different from icon keys so icons can be changed without
 * breaking dashboards
 */
export const colorRulesIconToIcon = {
    critical: 'Error',
    warning: 'Warning',
    completed: 'Completed',
    tentative: 'dashboards-TentativeBooking',
    happy: 'dashboards-Happy',
    sad: 'Sad',
    emojiNeutral: 'EmojiNeutral',
    circle: 'CircleRing',
    circleFill: 'CircleFill',
    contrast: 'Contrast',
    square: 'dashboards-SquareShape',
    squareSolid: 'SquareShapeSolid',
    diamond: 'dashboards-Diamond2Solid',
    octagon: 'Octagon',
    cloud: 'Cloud',
    tvMonitor: 'TvMonitor',
    globe: 'Globe',
    strength: 'dashboards-Strength',
    eatDrink: 'EatDrink',
    cocktail: 'Cocktails',
    apple: 'dashboards-DietTrackerApple',
    repair: 'Repair',
    circleDollar: 'dashboards-CircleDollar',
    rate: 'dashboards-Rate',
    arrowUp: 'dashboards-ArrowUp8',
    arrowDown: 'dashboards-ArrowDown8',
    ferry: 'Ferry',
    car: 'Car',
    plane: 'Airplane',
    baseball: 'Baseball',
    soccer: 'Soccer',
    basketball: 'dashboards-Basketball',
    football: 'CollegeFootball',
    tennis: 'Tennis',
    golf: 'Golf',
    world: 'World',
    heart: 'Heart',
    insights: 'dashboards-Insights',
} as const;

export const colorRulesIcons = Object.keys(colorRulesIconToIcon) as ColorRule.Icon[];
