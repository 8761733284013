import * as _ from 'lodash';

//
// Selector Fixer allow easy change in elements
//
// Fixers data: list of individual fixers of according to element selector:
//      selector: Elements selector
//      fixer: method that fix the elements - do what ever you want
//      destroyer: method that will be called for cleanups (remove listeners)
//      once: should run only once (might re-run if destroy was called)
//      when: executed only on special events - define the events (like on "focus")
//
// Interface:
//    - fix (with or without event - when)
//    - debounceFix (same as fix, debounce)
//    - destroy

export interface FixerRow {
    selector: string;
    fixer: (elem: HTMLElement, index: number) => void;
    destroyer?: (elem: HTMLElement, index: number) => void;
    once?: boolean;
    when?: string[];
}

export class AccessibilitySelectorFixer {
    private onceMap: { [selector: string]: boolean | void } = {};

    constructor(
        private toFix: FixerRow[],
        private getComponentRoot: () => HTMLElement | null,
        debounceTimeout: number = 10
    ) {
        this.debounceFix = _.debounce(this.fix, debounceTimeout);
    }

    public readonly fix = (which?: string) => this.runFixers(which);
    public readonly debounceFix: (which?: string) => void;

    execute(baseElement: HTMLElement, selector: string, fixer: (elem: HTMLElement, index: number) => void): boolean {
        if (selector === 'me') {
            fixer(baseElement, 0);
            return true;
        }

        const elements = baseElement.querySelectorAll<HTMLElement>(selector);
        elements.forEach((elem, index) => fixer(elem, index));
        return elements.length > 0;
    }

    public runFixers(which?: string) {
        const baseElement = this.getComponentRoot();
        if (!baseElement) {
            return;
        }
        this.toFix.forEach(({ selector, fixer, once, when }) => {
            if (
                (once && this.onceMap[selector]) || // only once, and executed
                (when && (!which || when.indexOf(which) < 0))
            ) {
                // only when, and not selected
                return;
            }
            this.onceMap[selector] = this.execute(baseElement, selector, fixer);
        });
    }

    public destroy = () => {
        const baseElement = this.getComponentRoot();
        if (!baseElement) {
            return;
        }
        this.toFix.forEach(({ selector, destroyer }) => {
            if (destroyer) {
                this.execute(baseElement, selector, destroyer);
            }
        });
        this.onceMap = {};
    };
}
