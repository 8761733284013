import { action, makeObservable, observable } from 'mobx';

import type { UDataSource, AutoRefreshConfig, IPage, IDashboardDocument } from '../../core/domain';
import { ParameterConfig } from '../../domain';
import type { ITile } from '../../domain/tile';

export interface IItemDiffMap<T> {
    readonly create: Record<string, T>;
    readonly delete: Set<string>;
}

export interface DiffMaps {
    readonly tiles: IItemDiffMap<ITile>;
    readonly dataSources: IItemDiffMap<UDataSource>;
    readonly parameters: IItemDiffMap<ParameterConfig>;
    readonly pages: IItemDiffMap<IPage>;
}

export interface ItemTypeMap {
    tiles: ITile;
    dataSources: UDataSource;
    parameters: ParameterConfig;
    pages: IPage;
}

export type ItemType = keyof ItemTypeMap;

const newDiffMaps = (): DiffMaps => {
    const diffMaps: DiffMaps = {
        tiles: { create: {}, delete: new Set() },
        dataSources: { create: {}, delete: new Set() },
        parameters: { create: {}, delete: new Set() },
        pages: { create: {}, delete: new Set() },
    };

    for (const value of Object.values(diffMaps)) {
        makeObservable(value, { create: observable.shallow, delete: observable });
    }

    return diffMaps;
};

const newAutoRefreshConfig = (): AutoRefreshConfig => ({
    enabled: false,
    defaultInterval: undefined,
});

export class DashboardChanges {
    title: string;
    /**
     * Set to "new" to create a new parameter, undefined to close the
     * parameter page, or a parameter id to edit a parameter
     */
    editParameter: string | undefined = undefined;
    readonly pinnedParameters: Set<string>;
    autoRefresh: AutoRefreshConfig;
    readonly diffMaps: DiffMaps = newDiffMaps();

    constructor(document: IDashboardDocument) {
        // Dashboards needs to be copied so we can mutate it
        this.title = document.title;
        this.pinnedParameters = new Set(document.pinnedParameters);
        // Until auto-refresh is non-nullable we need to default it
        const autoRefresh = document.autoRefresh;
        this.autoRefresh = autoRefresh ? { ...autoRefresh } : newAutoRefreshConfig();

        makeObservable(this, {
            title: observable,
            editParameter: observable,
            pinnedParameters: observable,
            autoRefresh: observable.ref,
            addItem: action,
            deleteItem: action,
            /**
             * Ref is readonly, internal observables are added elsewhere.
             */
            diffMaps: false,
        });
    }

    addItem<T extends ItemType>(kind: T, item: ItemTypeMap[T]) {
        const changes = this.diffMaps[kind] as IItemDiffMap<ItemTypeMap[T]>;
        changes.create[item.id] = item;
    }

    deleteItem<T extends ItemType>(kind: T, id: string) {
        const changes = this.diffMaps[kind] as IItemDiffMap<ItemTypeMap[T]>;
        delete changes.create[id];
        changes.delete.add(id);
    }
}
