import { APP_STRINGS } from '../res';

export type Ok<T> = { kind: 'ok'; value: T; err?: undefined };
export type Err<T = string> = { kind: 'err'; value?: undefined; err: T };

/**
 * `undefined` added to variants for optional chaining support, e.g.,
 * `result.value?.property`
 */
export type Result<V, E = string> = Ok<V> | Err<E>;

export function ok<T>(value: T): Ok<T> {
    return { kind: 'ok', value };
}

export function err<T>(value: T): Err<T> {
    return { kind: 'err', err: value };
}

/**
 * Staring point so we can start using the same error handling code. We should
 * expand on this?
 */
export function parseException(e: unknown): string {
    if (e instanceof Error) {
        return `${e.name}: ${e.message}`;
    }
    return APP_STRINGS.error.unidentifiedErrorMessage;
}

/**
 * Checks if a fetch exception was caused by an abort signal.
 *
 * From MDN:
 * > Note: When abort() is called, the fetch() promise rejects with an Error of
 * > type DOMException, with name AbortError.
 * https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 */
export function isAbortError(e: unknown): boolean {
    return e instanceof DOMException && e.name === 'AbortError';
}

/**
 * Returns true when err was thrown after accessing localstorage/cookies when
 * it's blocked via browsers settings. As of writing, this will occur by default
 * if we're in an iframe in incognito mode. The dashboards library needs
 * to run in this context.
 *
 * Tested that it works with exceptions thrown on localstorage and indexedDB
 * access on Safari, Chrome, and Firefox.
 */
export function isSecurityError(e: unknown) {
    return e instanceof DOMException && e.name === 'SecurityError';
}
