import * as React from 'react';
import { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { APP_STRINGS } from '../../../../res';
import { QueryProvider, MaybeQuery } from '../../../../components/QueryEditing';
import { newKustoQuery } from '../../../../domain';
import { useCore } from '../../../../core';
import { useDashboardStore } from '../../../../store/dashboard';
import { ETPQuery, ETPState, useETPSelector } from '../../../../store/editTile';
import { err, ok } from '../../../../common';
import { IParameterSelections } from '../../../../store';

import { SchemaProvider } from './SchemaProvider';

const missingDataSourceError: MaybeQuery = err(APP_STRINGS.editTilePage.missingDataSourceMessage);
const missingQueryError: MaybeQuery = err(APP_STRINGS.editTilePage.noQueryMessage);

interface ActiveQueryProviderProps {
    renderedQuery: undefined | ETPQuery;
}

const ActiveQueryProvider: React.FC<ActiveQueryProviderProps> = observer(function ActiveQueryProvider({
    renderedQuery,
    children,
}) {
    const store = useDashboardStore();

    const dataSource =
        store.state && renderedQuery?.dataSourceId && store.state.dataSourcesRecord[renderedQuery.dataSourceId];

    const parsedQuery = useMemo((): MaybeQuery => {
        if (!renderedQuery) {
            return missingQueryError;
        }

        if (!dataSource) {
            return missingDataSourceError;
        }

        const selections = renderedQuery.parameterSelections.filter((s) =>
            s.config.variableNames.some((v) => renderedQuery.usedVariables.has(v))
        );

        return ok(newKustoQuery(renderedQuery.query, dataSource, renderedQuery.tileId, selections));
    }, [renderedQuery, dataSource]);

    return (
        <QueryProvider query={parsedQuery}>
            <SchemaProvider query={parsedQuery}>{children}</SchemaProvider>
        </QueryProvider>
    );
});

const renderedQueryQuerySelector = (state: ETPState) => {
    if (state.type !== 'query') {
        return undefined;
    }

    return state.renderedQuery;
};

export interface QueryHandlerProps {
    parameterSelections: undefined | IParameterSelections;
}

/**
 * Mounts the SchemaProvider and the QueryProvider with the EditTilePage state
 */
export const QueryHandler: React.FC<QueryHandlerProps> = ({ children }) => {
    const { queryService } = useCore();
    const renderedQuery = useETPSelector(renderedQueryQuerySelector);

    // On unmount, clear all non-persistent queries
    useEffect(() => () => queryService.deregisterAllNonPersistentQueries(), [queryService]);

    if (!renderedQuery) {
        return <>{children}</>;
    }

    return <ActiveQueryProvider renderedQuery={renderedQuery}>{children}</ActiveQueryProvider>;
};
