import { createContext, useContext } from 'react';
import * as React from 'react';

import { Mutable } from '../../common';

import { ColorRule, colorRulesColors } from '../tile';
import { VisualConfig, ColorResolution } from './types';

import styles from './context.module.scss';

const colorRuleSet = colorRulesColors.reduce((acc, color) => {
    acc[color] = { color, invertText: false };
    return acc;
}, {} as Mutable<Record<ColorRule.Color, ColorResolution>>);

const colorStyles = { light: colorRuleSet, bold: colorRuleSet };

export const emptyVisualConfig: VisualConfig = {
    colorValues: { light: colorStyles, dark: colorStyles },
    DataVisualComponent: ({ rows }) => (
        <div className={styles.container}>
            {rows.map((r, i) => (
                <div key={i}>{JSON.stringify(r)}</div>
            ))}
        </div>
    ),
    layout: ['table'],
    visualTypes: {
        table: {
            label: 'Table',
            defaultSize: { width: 2, height: 2 },
            minimumSize: { width: 2, height: 2 },
            configurationCompatibility: [],
        },
    },
};

/**
 * React Context that contains the component used for chart visuals and
 * the matrix for supported tile configuration options. This is initialized with
 * working options.
 */
const visualConfigContext = createContext<VisualConfig>(emptyVisualConfig);

/**
 * Used to specify default visual configuration
 */
// any required because VisualConfig<'cat'> isn't assignable to VisualConfig<string>
// Isn't assignable because of the React.ComponentType<T> issues.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const VisualConfigProvider: React.Provider<VisualConfig<any>> = visualConfigContext.Provider;

visualConfigContext.displayName = 'VisualConfig';

export const useVisualConfig = (): VisualConfig => useContext(visualConfigContext);
