import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { ActionButton } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../../res';

import { useETPDispatch, useETPSelector } from '../../../../../store/editTile';
import { visualAddedSelectorBuilder } from '../../../lib';
import { etpVisualOptionsDefaults, ManagedConfigSegment } from '../../../constants';

import { DefaultVariants } from '../types';

import styles from './ResetButton.module.scss';

const visualOptionsSelector = visualAddedSelectorBuilder((o) => o);

export interface ResetButtonProps {
    configKeys: ManagedConfigSegment['configKeys'];
    defaultVariants: DefaultVariants;
    disabled: boolean;
}

const iconProps = { iconName: 'dashboards-Reset' };

export const ResetButton: React.FC<ResetButtonProps> = ({ configKeys, defaultVariants, disabled }) => {
    const visualOptions = useETPSelector(visualOptionsSelector);

    const nothingToReset = useMemo(
        () =>
            configKeys.every((key) => {
                if (key === 'visualTypeVariant') {
                    return visualOptions.visualType === defaultVariants[visualOptions.visualType];
                }
                return visualOptions.options[key] === etpVisualOptionsDefaults[key];
            }),
        [visualOptions, configKeys, defaultVariants]
    );

    const [dispatch] = useETPDispatch();

    const onReset = useCallback(() => {
        dispatch({
            type: 'resetVisualOptions',
            options: configKeys.filter((o) => o !== 'visualTypeVariant'),
        });
        if (configKeys.includes('visualTypeVariant')) {
            dispatch((innerDispatch, getState) => {
                const state = getState();
                if (state.type !== 'query' || !state.visual) {
                    return;
                }
                innerDispatch({
                    type: 'updateVisualType',
                    visualType: defaultVariants[state.visual.visualType] as string,
                });
            });
        }
    }, [configKeys, dispatch, defaultVariants]);

    return (
        <ActionButton
            iconProps={iconProps}
            className={styles.resetButton}
            onClick={onReset}
            disabled={disabled || nothingToReset}
        >
            {APP_STRINGS.utilButtons.reset}
        </ActionButton>
    );
};
