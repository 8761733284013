import * as React from 'react';
import { IconButton } from 'office-ui-fabric-react';
import { useCallback } from 'react';

import { APP_STRINGS } from '../../../../../res';
import { TextFieldWithControlledError } from '../../../../../components/fabric/textField/TextFieldWithControlledError';
import { ValueImpl, TimeZone, useTimeZone } from '../../../../../domain';
import { EppStaticOption } from '../../../reducer';

import styles from './ParameterValueOptionRow.module.scss';

function getLabel<T>(impl: ValueImpl<T, string>, value: string, timeZone: TimeZone): string {
    const val = impl.editStringToValue(value, timeZone);
    if (val.kind === 'err') {
        return '';
    }
    return impl.valueToDisplayString(val.value, timeZone);
}

export interface ParamterValueOptionRowProps<T, K extends string> {
    className?: string;
    textFieldClassName?: string;

    displayTextOnly?: boolean;

    impl: ValueImpl<T, K>;
    value: EppStaticOption;

    error?: string;

    onChange(value: EppStaticOption): void;
    onDelete(key: string): void;
}

const deleteIconProps = { iconName: 'Delete' };

export const ParameterValueOptionRow = <T, K extends string>({
    className,
    textFieldClassName,
    value,
    impl,
    error,
    displayTextOnly,
    onChange,
    onDelete,
}: ParamterValueOptionRowProps<T, K>) => {
    const timezone = useTimeZone();
    const stringValue = String(value.value);

    const memoOnDelete = useCallback(() => onDelete(value.id), [value.id, onDelete]);

    return (
        <div className={className}>
            <div className={textFieldClassName}>
                <TextFieldWithControlledError
                    className={styles.valueTextField}
                    value={stringValue}
                    onChange={(_, newValue = '') =>
                        onChange({
                            ...value,
                            value: newValue,
                        })
                    }
                    error={error}
                    disabled={displayTextOnly}
                    ariaLabel={`${APP_STRINGS.editParameterPage.aria.formatValueInputLabel} ${value.id + 1}`}
                />
                <TextFieldWithControlledError
                    value={value.displayText ?? ''}
                    placeholder={getLabel(impl, value.value, timezone)}
                    onChange={(_, displayText) =>
                        onChange({
                            ...value,
                            displayText: displayText === '' ? undefined : displayText,
                        })
                    }
                    ariaLabel={`${APP_STRINGS.editParameterPage.aria.formatDisplayNameInputLabel} ${value.id + 1}`}
                />
            </div>
            {!displayTextOnly && (
                <IconButton
                    iconProps={deleteIconProps}
                    onClick={memoOnDelete}
                    ariaLabel={APP_STRINGS.editParameterPage.aria.deleteValueLabel}
                />
            )}
        </div>
    );
};
