import { assertNever } from 'office-ui-fabric-react';

import { rtdNullValue, tagScalar, RtdValue, tagArray } from '../../../value';
import { APP_CONSTANTS } from '../../../../res';
import { RTDDropdownOption } from '../../../../components';

/**
 * For use in dropdown parameters. Applies a selection to dashboard value.
 *
 * For example:
 *   * `'a'` (selection) + `['a', 'b', 'c']` (previous state) = `['b', 'c']` (new state)
 *   * `null` (selection) +  `['d']` (previous state) = `null` (new state)
 */
export function applyDropdownSelection<T, K>(
    prev: undefined | RtdValue.Value<T, K>,
    dataType: K,
    multiSelect: boolean,
    selection: typeof APP_CONSTANTS.parameter.allSelection | T,
    options: undefined | Array<RTDDropdownOption<typeof APP_CONSTANTS.parameter.allSelection | T>>
): RtdValue.Value<T, K> {
    if (selection === APP_CONSTANTS.parameter.allSelection) {
        if (multiSelect && prev?.kind === 'null') {
            return tagArray(dataType, []);
        }

        return rtdNullValue;
    }

    if (!multiSelect) {
        return tagScalar(dataType, selection);
    }

    let values: T[];

    if (prev) {
        switch (prev.kind) {
            case 'null':
                if (options) {
                    values = [];
                    for (const o of options) {
                        if (o.data && o.data !== APP_CONSTANTS.parameter.allSelection && o.data !== selection) {
                            values.push(o.data);
                        }
                    }
                } else {
                    // Can't deselect value if options aren't available
                    return prev;
                }
                break;
            case 'scalar':
                // Prevent selecting same value twice
                if (prev.value === selection) {
                    values = [];
                }
                values = [prev.value, selection];
                break;
            case 'array':
                {
                    const index = prev.values.indexOf(selection);
                    if (index === -1) {
                        values = [...prev.values, selection];
                    } else {
                        values = [...prev.values.slice(0, index), ...prev.values.slice(index + 1)];
                    }
                }
                break;
            default:
                assertNever(prev);
        }
    } else {
        values = [selection];
    }

    return tagArray(dataType, values);
}
