import { configure } from 'mobx';

import { env, APP_STRINGS, APP_CONSTANTS } from './res';

import './assets/icons/registerCustomIcons';
import './global';

configure(APP_CONSTANTS.mobxConfiguration);

export { dashboardsCoreInit } from './core/dashboardsCoreInit';
export const { rtdVersion } = env;
export * from './core/config';
export type {
    UDataSource,
    IManualKustoDataSource,
    ILogAnalyticsDataSource,
    IAppInsightsDataSource,
    UKustoDataSource,
    IDataSource,
    RtdAccount,
} from './core/domain';

export { formatKustoQuery, findKeys, buildTelemetryConfig, FragmentOperation, ResultSource } from './domain';
export * from './domain/visualConfig/types';
export type {
    IKeyTypes,
    VisualOptions,
    KustoSchema,
    RtdTableSchema,
    KustoQueryResult,
    UQuery,
    QueryResultFragment,
    KustoQueryResultColumn,
    KustoQueryResultRowObject,
    IKustoQuery,
    IQuery,
    CrossFilterConfig,
} from './domain';
export const { colorRulesIconsLabels, colorRulesInferLabels, colorRulesColorLabels } = APP_STRINGS.visuals;
export { RootPage as Dashboards } from './pages/root/RootPage';
export { DashboardsContextsProvider } from './DashboardsContextsProvider';
export type { Result, Ok, Err } from './common';
export { ok, err } from './common';
export type { IRtdDashboardsCore } from './core';
export * from './hostLib';
export * from './domain/kusto';
export type { TDuration, Duration, TRtdValue, RtdValue, TRtdType } from './domain/value';
export { ErrorBanner, SpinnerText } from './components';
export { usePullState } from './common';

// Used by scripts that need to operate on serialized dashboards
export * from './migration';
