import { AutoRefreshUpdateInterval, AutoRefreshConfig } from '../../../../core/domain';
import { APP_CONSTANTS } from '../../../../res';
import { validateAutoRefreshInterval } from '../../../../domain/autoRefresh';

export interface AutoRefreshFormState {
    config: AutoRefreshConfig;
    availableOptions: AutoRefreshUpdateInterval[];
}

export type AutoRefreshFormAction =
    | {
          type: 'initialize';
          config: AutoRefreshConfig;
      }
    | {
          type: 'setEnabled';
          enabled: boolean;
      }
    | {
          type: 'setMinInterval';
          minInterval: AutoRefreshUpdateInterval | undefined;
      }
    | {
          type: 'setDefaultInterval';
          defaultInterval: AutoRefreshUpdateInterval | undefined;
      };

export const autoRefreshReducer = (
    state: AutoRefreshFormState,
    action: AutoRefreshFormAction
): AutoRefreshFormState => {
    switch (action.type) {
        case 'initialize': {
            return calculateAvailableOptions(action.config.minInterval, {
                ...state,
                config: action.config,
            });
        }
        case 'setEnabled': {
            return {
                ...state,
                config: {
                    ...state.config,
                    enabled: action.enabled,
                },
            };
        }
        case 'setMinInterval': {
            return calculateAvailableOptions(action.minInterval, {
                ...state,
                config: {
                    ...state.config,
                    minInterval: action.minInterval,
                },
            });
        }
        case 'setDefaultInterval': {
            return {
                ...state,
                config: {
                    ...state.config,
                    defaultInterval: action.defaultInterval,
                },
            };
        }
    }
};

const calculateAvailableOptions = (
    minInterval: AutoRefreshUpdateInterval | undefined,
    state: AutoRefreshFormState
): AutoRefreshFormState => {
    let startIndex = minInterval ? APP_CONSTANTS.orderedAutoRefreshIntervals.indexOf(minInterval) : 0;

    if (startIndex < 0) {
        startIndex = 0;
    }

    const availableOptions = APP_CONSTANTS.orderedAutoRefreshIntervals.slice(startIndex);

    const hasDefaultInterval =
        state.config.defaultInterval && validateAutoRefreshInterval(state.config.defaultInterval, minInterval);

    return {
        ...state,
        availableOptions,
        config: {
            ...state.config,
            defaultInterval: hasDefaultInterval ? state.config.defaultInterval : undefined,
        },
    };
};
