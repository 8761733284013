import { useCallback } from 'react';
import { LocationDescriptorObject, Path } from 'history';
import { useHistory } from 'react-router-dom';

import { useUrlCallback } from './useUrl';

export type VisitUrl = ReturnType<typeof useVisitUrl>;

export interface ILocationNavigationState {
    bypassEditPrevention?: boolean;
}

/**
 * @deprecated use history object directly vis `useHistory`
 */
export const useVisitUrl = () => {
    const history = useHistory();

    const getUrl = useUrlCallback();

    return useCallback(
        (
            /**
             * The URL string or LocationDescriptor to navigate to
             */
            url: Path | LocationDescriptorObject,
            /**
             * Event is provided to call `preventDefault` in HTML on* handlers
             */
            event?: Event | React.SyntheticEvent,
            /**
             * If true, replace the current history entry, instead of pushing
             */
            shouldReplace = false,
            /**
             * **NOTE:** Only use in specific privileged scenarios
             *
             * If true, ignore any current edit prevention state and always navigate
             */
            bypassEditPrevention = false,
            /**
             * If true, remove the baseUrl (if it exists) from the start of the url
             */
            stripBaseUrl = false
        ) => {
            if (event) {
                event.preventDefault();
            }

            const modifiedUrl = getUrl(url, stripBaseUrl);

            const navigationState: ILocationNavigationState | undefined = bypassEditPrevention
                ? {
                      bypassEditPrevention,
                  }
                : undefined;
            // TypeScript does not understand that there is an overload of history.replace/push supporting both types of the union
            // Running this if allows us to keep type checking with only an extremely minor performance difference
            if (typeof modifiedUrl === 'string') {
                if (shouldReplace) {
                    history.replace(modifiedUrl, navigationState);
                } else {
                    history.push(modifiedUrl, navigationState);
                }
            } else {
                modifiedUrl.state = {
                    ...(typeof modifiedUrl.state === 'object' ? modifiedUrl.state : {}),
                    ...navigationState,
                };

                if (shouldReplace) {
                    history.replace(modifiedUrl);
                } else {
                    history.push(modifiedUrl);
                }
            }
        },
        [getUrl, history]
    );
};
