import type { AuthenticationProvider } from '@microsoft/microsoft-graph-client';

import { IRtdAuthenticationProvider } from '../config';

export class GraphAuthProvider implements AuthenticationProvider {
    private readonly authenticationProvider: IRtdAuthenticationProvider;
    private readonly scopes: string[];

    constructor(authenticationProvider: IRtdAuthenticationProvider, scopes: string[]) {
        this.authenticationProvider = authenticationProvider;
        this.scopes = scopes;
    }

    /**
     * This method will get called before every request to the msgraph server This
     * should return a Promise that resolves to an accessToken (in case of
     * success) or rejects with error (in case of failure) Basically this method
     * will contain the implementation for getting and refreshing accessTokens
     */
    public async getAccessToken(): Promise<string> {
        return this.authenticationProvider.getToken(this.scopes);
    }
}
