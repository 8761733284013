import React, { useCallback } from 'react';
import { DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { useHistory } from 'react-router';

import { RTDDialog } from '../../fabric/panelsWrapped';
import { SpinnerText } from '../../lib';
import { APP_STRINGS } from '../../../res';
import {
    TFormComponent,
    formActionMacro,
    usePullState,
    parseException,
    useAbortController,
    isAbortError,
} from '../../../common';
import { useCore } from '../../../core';
import { useGlobalDispatch } from '../../../store/redux';
import { rtdPrompt } from '../Prompt';

export const RemoveDashboardDialog: TFormComponent<{
    dashboardId: string;
    dashboardTitle: string;
}> = ({ onClose, dashboardId, dashboardTitle }) => {
    const history = useHistory();
    const abortController = useAbortController();
    const [dispatch] = useGlobalDispatch();
    const core = useCore();
    const [loading, setLoading, getLoading] = usePullState(false);

    const onCloseInner = useCallback(() => {
        if (!getLoading()) {
            onClose();
        }
    }, [getLoading, onClose]);

    const handleRemoveDashboard = useCallback(async () => {
        if (!dashboardId || getLoading()) {
            return;
        }

        setLoading(true);

        try {
            await core.dashboardService.deleteDashboard(dashboardId, abortController.signal);
            dispatch({ type: 'removedDashboardListItem', dashboardId });
            history.push(core.navUtil.path.catalog);
        } catch (e) {
            if (isAbortError(e)) {
                return;
            }
            rtdPrompt(dispatch, APP_STRINGS.forms.deleteDashboard.errorTile, { subText: parseException(e) });

            setLoading(false);
            return;
        }
        onClose();
    }, [dashboardId, getLoading, setLoading, onClose, core, abortController, dispatch, history]);

    return (
        <RTDDialog
            hidden={false}
            title={
                <>
                    <>{APP_STRINGS.forms.deleteDashboard.title}</> <i>{dashboardTitle}</i>
                </>
            }
            dialogContentProps={{
                subText: APP_STRINGS.forms.deleteDashboard.subText,
                showCloseButton: !loading,
            }}
            onDismiss={onCloseInner}
        >
            <DialogFooter>
                <PrimaryButton data-automation-id="remove-dashboard" onClick={handleRemoveDashboard} disabled={loading}>
                    <SpinnerText on={loading}>{APP_STRINGS.utilButtons.delete}</SpinnerText>
                </PrimaryButton>
                <DefaultButton
                    data-automation-id="cancel-remove-dashboard"
                    onClick={onCloseInner}
                    text={APP_STRINGS.utilButtons.cancel}
                    disabled={loading}
                />
            </DialogFooter>
        </RTDDialog>
    );
};

export const removeDashboardDialogAction = formActionMacro(RemoveDashboardDialog, 'removeDashboard');
