import * as React from 'react';
import { useThemeState } from '../../../domain/theming';
import { buildShadowContentBoxShadow } from './util';

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type ShadowContentProps = Omit<DivProps, 'ref'>;

export const ShadowContent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ShadowContentProps>>(
    ({ style: externalStyle, children, ...divProps }, ref) => {
        const { isDark } = useThemeState();

        const finalStyles: React.CSSProperties = {
            boxShadow: buildShadowContentBoxShadow(isDark),
            ...externalStyle,
        };

        return (
            <div ref={ref} {...divProps} style={finalStyles}>
                {children}
            </div>
        );
    }
);
