import * as React from 'react';

import { APP_STRINGS } from '../../res';
import { PreviewTag } from '../../hostLib/PreviewTag';
import { RtdHeader } from '../RtdHeader';

import { IStaticBreadcrumbItem } from './StaticBreadcrumb';

import styles from './dashboardsBreadcrumb.module.scss';

/**
 * @deprecated
 */
export const dashboardsBreadcrumb: Pick<IStaticBreadcrumbItem, 'text' | 'onRenderItem'> = {
    text: APP_STRINGS.breadcrumb.dashboards,
    onRenderItem: () => (
        <div className={styles.wrapper}>
            <RtdHeader level={1} className={styles.text}>
                {APP_STRINGS.breadcrumb.dashboards}
            </RtdHeader>
            <PreviewTag className={styles.previewTag} />
        </div>
    ),
};
