import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';

import { Result } from '../../common';
import { UQuery, StreamQueryResult, useExecuteQuery } from '../../domain';
import { useDashboardStore } from '../../store';

export interface NoQueryReason {
    title: string;
    body?: string;
}
export type MaybeQuery<TQuery = UQuery> = Result<TQuery, NoQueryReason>;

type QueryResultContext = [StreamQueryResult, UQuery | undefined];

const notRunState: StreamQueryResult = { kind: 'notRun' };

/**
 * [QueryStatus, IQuery]
 */
export const queryResultContext = createContext<QueryResultContext>([notRunState, undefined]);

export const useQueryResult = () => useContext(queryResultContext);

export const QueryProvider: React.FC<{ query: MaybeQuery }> = ({ query, children }) => {
    const store = useDashboardStore();
    // When query changes, always rerun (editor mode)
    const queryStatus = useExecuteQuery(store, query, { forceUpdateOnQueryChange: true });

    const state = useMemo(
        (): QueryResultContext => [queryStatus, query.kind === 'ok' ? query.value : undefined],
        [queryStatus, query]
    );

    return <queryResultContext.Provider value={state}>{children}</queryResultContext.Provider>;
};
