import { useMemo } from 'react';
import { assertNever } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../../../res';
import { RTDDropdownOption } from '../../../../../../components';
import { CanInfer } from '../../../../../../common';

import { SchemaState, useSchema } from '../../../QueryHandler/SchemaProvider';

/**
 * Column names need to be prefixed before being used as option keys to
 * guarantee we don't have collisions.
 */
export const columnOptionKey = (columnName: string) => `column-${columnName}`;

export const columnsTooltipFromSchema = (schema: SchemaState): string | undefined => {
    switch (schema.kind) {
        case 'uninitialized':
            return APP_STRINGS.editTilePage.schemaContext.loadingMessage;
        case 'unavailable':
            return schema.errorMessage;
        case 'available':
            if (schema.schema.columns.length === 0) {
                return APP_STRINGS.editTilePage.visualConfig.noColumnsAvailableMessage;
            }
            return undefined;
        default:
            assertNever(schema);
    }
};

export interface ETPColumnOption extends RTDDropdownOption {
    data: CanInfer<string>;
}

export const columnDropdownOption = (columnName: string): ETPColumnOption => ({
    key: columnOptionKey(columnName),
    text: columnName,
    data: { type: 'specified' as const, value: columnName },
});

export const useSchemaDerived = (): {
    optionsKeys: Set<string>;
    options: ETPColumnOption[];
    disabledReason: string | undefined;
} => {
    const schema = useSchema();

    return useMemo(() => {
        const disabledReason = columnsTooltipFromSchema(schema);
        if (schema.kind !== 'available') {
            return { optionsKeys: new Set(), options: [], disabledReason };
        }
        const options = schema.schema.columns.map((c) => columnDropdownOption(c.name));

        return {
            optionsKeys: new Set(options.map((o) => o.key)),
            options,
            disabledReason,
        };
    }, [schema]);
};
