import { useRef, useEffect } from 'react';

/**
 * A ref used to check whether or not a component is still mounted.
 */
export const useIsMounted = () => {
    const ref = useRef(true);
    useEffect(() => {
        return () => {
            ref.current = false;
        };
    }, []);
    return ref;
};
