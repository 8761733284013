import { v4 as uuid } from 'uuid';

import type { IDashboardDocument } from '../../core/domain/dashboard';
import { APP_STRINGS } from '../../res';
import { createDefaultDurationParameter } from '../parameter';

export function newDashboardDocument(title: string): IDashboardDocument {
    const durationParam = createDefaultDurationParameter();
    const defaultPage = {
        name: APP_STRINGS.domain.dashboard.defaultPageName,
        id: uuid(),
    };
    return {
        meta: undefined,
        title,
        pinnedParameters: new Set([durationParam.id]),
        autoRefresh: {
            enabled: false,
            defaultInterval: undefined,
        },
        tiles: [],
        dataSources: [],
        parameters: [durationParam],
        pages: [defaultPage],
    };
}
