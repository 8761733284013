import { GridApi, _ } from '@ag-grid-enterprise/all-modules';

// Hack the grid for accessibility
////////////////////////////////////////
// 1. Add more columns to rendered list in AgGrid virtual render to support narrator scan mode -
//    AgGrid virtual render  - render's only the visible columns (for better performance)
//    On the other hand narrator in scan mode doesn't move the focus (when moving between cells)
//    and scroll left/right only if it find another rendered columns in the dom ...
//
//    The solution is :
//    - Add another rendered column on the left and right side of the actual viewable columns
//      in order to support vertical scrolling without focus changes
//    - Make the Last and First columns always rendered, in order to support scrolling
//      beginning/ending of the row when moving to next line
// 2. AgGrid ensureDomOrder only support row order - add support for ensuring cell order

// The hack override the following function in AgGrid (which are not public ;-( )
//
// ColumnController.isColumnInViewport - which is used to check which columns need to be rendered
//
// RowComp.ensureCellInCorrectContainer - which is used when re-using cell from cell render pool
// RowComp.createNewCell                - which is used to create new cell
// CellComp.modifyLeftForPrintLayout    - which is used when updating the cell left position (like drag column order)
//

//
// the hacks use a lot of - ANY - in order to access the private internals of AgGrid components ...
type PositionCache = {
    validTill: number;
    siblings: {
        [cellId: string]: { rightId?: string | null; leftId?: string | null };
    };
};

const originalEnsureDomOrder = _.ensureDomOrder;
_.ensureDomOrder = function (eContainer: HTMLElement, eChild: HTMLElement, eChildBefore: HTMLElement) {
    if (!eChildBefore && eContainer && eContainer.firstChild === eChild) {
        return;
    }
    originalEnsureDomOrder(eContainer, eChild, eChildBefore);
};

export class AccessibleGridHack {
    private static doesEnsureHackImplemented = false;

    public static hackTheGrid(gridApi: GridApi) {
        const hackedApi: any = gridApi as any;
        AccessibleGridHack.setUpAdditionalColumnToDisplay(hackedApi.columnController);
        AccessibleGridHack.ensureCellOrder(hackedApi.rowRenderer);
    }

    public static measureUpTo: any;

    //
    // Replace isColumnInViewport of columnController to enable the additional column rendering
    //
    private static setUpAdditionalColumnToDisplay(controller: any) {
        controller.isColumnInViewport = function (col: any) {
            // Ag grid only measure the visible columns
            // When calculating the default column size in result grid creation
            // it will fake the column up "measureUpTo" as visible
            if (AccessibleGridHack.measureUpTo) {
                if (AccessibleGridHack.measureUpTo === col) {
                    AccessibleGridHack.measureUpTo = undefined;
                }
                return true;
            }

            // check if its the first or last col
            var columnLeft = col.getLeft();
            var columnRight = col.getLeft() + col.getActualWidth();
            if (columnLeft === 0 || columnRight === this.bodyWidth) {
                return true;
            }

            // the same logic as in original function, use the end/start of the edge columns instead
            // of viewport edge, which will add another column on each side.
            var leftColLeft = this.getLeftestColInVisible();
            var rightColRight = this.getRightestColInVisible();
            var columnToMuchLeft = columnLeft < leftColLeft && columnRight < leftColLeft;
            var columnToMuchRight = columnLeft > rightColRight && columnRight > rightColRight;
            return !columnToMuchLeft && !columnToMuchRight;
        };

        const checkPointInColArea = (col: any, point: number) =>
            col.getLeft() <= point && col.getLeft() + col.getActualWidth() > point;

        let cachedLeftestColInVisible: any = null;
        let cachedRightestColInVisible: any = null;
        controller.getLeftestColInVisible = function () {
            // if we have a cached col and is still valid use it, other wise find the col
            if (!cachedLeftestColInVisible || !checkPointInColArea(cachedLeftestColInVisible, this.viewportLeft)) {
                cachedLeftestColInVisible = this.displayedCenterColumns.find((col: any) =>
                    checkPointInColArea(col, this.viewportLeft)
                );
            }
            return cachedLeftestColInVisible ? cachedLeftestColInVisible.getLeft() : this.viewportLeft;
        };
        controller.getRightestColInVisible = function () {
            if (!cachedRightestColInVisible || !checkPointInColArea(cachedRightestColInVisible, this.viewportRight)) {
                cachedRightestColInVisible = this.displayedCenterColumns.find((col: any) =>
                    checkPointInColArea(col, this.viewportRight)
                );
            }
            return cachedRightestColInVisible
                ? cachedRightestColInVisible.getLeft() + cachedRightestColInVisible.getActualWidth()
                : this.viewportRight;
        };
    }

    //
    // Ensure cell order
    private static ensureCellOrder(rowRenderer: any) {
        // hack already applied on the Grid
        if (AccessibleGridHack.doesEnsureHackImplemented) {
            return;
        }

        // Get access to RowComp class
        const aRowComp = rowRenderer.rowCompsByIndex[rowRenderer.firstRenderedRow];
        if (!aRowComp) {
            return;
        }

        const RowCompPrototype = Object.getPrototypeOf(aRowComp);
        AccessibleGridHack.doesEnsureHackImplemented = true;

        // Fix keyboard navigation issue when scrolling down and then up to over top line
        if (aRowComp.bodyContainerComp) {
            const RowContainerCompPrototype = Object.getPrototypeOf(aRowComp.bodyContainerComp);
            RowContainerCompPrototype.noFixEnsureDomOrder = RowContainerCompPrototype.ensureDomOrder;
            RowContainerCompPrototype.ensureDomOrder = function (eRow: HTMLElement) {
                if (this.domOrder) {
                    if (this.eContainer === eRow.parentElement && !eRow.previousSibling && !this.lastPlacedElement) {
                        this.lastPlacedElement = eRow;
                    } else {
                        this.noFixEnsureDomOrder(eRow);
                    }
                }
            };
        }

        const positionCache: PositionCache = {
            validTill: 0,
            siblings: {},
        };

        // keep the original methods, we will use them internally
        RowCompPrototype.noDomOrderEnsureCellInCorrectContainer = RowCompPrototype.ensureCellInCorrectContainer;
        RowCompPrototype.noDomOrderCreateNewCell = RowCompPrototype.createNewCell;

        // Do the original logic and then make sure the added cell in the right order
        /////////////////////////////////////////////////////////////////////////////
        RowCompPrototype.ensureCellInCorrectContainer = function (cellComp: any) {
            this.noDomOrderEnsureCellInCorrectContainer(cellComp);

            AccessibleGridHack.ensureCellPositionInDom(cellComp.getGui(), cellComp.getCellLeft(), positionCache);
        };

        //
        // change the modifyLeftForPrintLayout which is called every time left
        // position is change
        //
        // Do original logic and then ensure the cell is in the right place accroding to
        // new position
        const hackOnLeftChange = (cell: any) => {
            cell.noDomOrderModifyLeftForPrintLayout = cell.modifyLeftForPrintLayout;
            cell.modifyLeftForPrintLayout = function (left: number) {
                const updateLeft = this.noDomOrderModifyLeftForPrintLayout(left);
                AccessibleGridHack.ensureCellPositionInDom(this.getGui(), updateLeft, positionCache);
                return updateLeft;
            };
        };

        //
        // - Do original cell creation
        // - Revert adding to cell to template array - used to add all cell together
        //   without placing in the right position
        //
        // - Add the cell to the dom in the right place
        //
        // - do the follow up post creation
        //
        RowCompPrototype.createNewCell = function (
            col: any,
            eContainer: HTMLElement,
            _ignoredCellTemplates: string[],
            _ignoredNewCellComps: any[]
        ) {
            let cellTemplates: string[] = [];
            let newCellComps: any[] = [];

            // Original
            this.noDomOrderCreateNewCell(col, eContainer, cellTemplates, newCellComps);

            // Place cell in right position
            if (col.getLeft() === 0 || !eContainer.firstElementChild) {
                eContainer.insertAdjacentHTML('afterbegin', cellTemplates[0]);
            } else {
                let elementToLeft = AccessibleGridHack.getLastElementToLeft(
                    eContainer.firstElementChild,
                    col.getLeft()
                );
                elementToLeft.insertAdjacentHTML('afterend', cellTemplates[0]);
            }

            // Followups
            this.callAfterRowAttachedOnCells(newCellComps, eContainer);

            // Add hock for future position change
            hackOnLeftChange(newCellComps[0]);
        };
    }

    private static getExpectedSibling = (cache: PositionCache, colId: string) => {
        if (cache.validTill < Date.now()) {
            cache.validTill = Date.now() + 200;
            cache.siblings = {};
        }
        let expectedSibling = cache.siblings[colId];
        if (!expectedSibling) {
            expectedSibling = {};
            cache.siblings[colId] = expectedSibling;
        }
        return expectedSibling;
    };

    // Make sure the cell in the right order based on the x (left) position of the elements
    // if not, more it to right position
    private static ensureCellPositionInDom = (
        element: HTMLElement | null,
        leftPosition: number,
        cache: PositionCache
    ) => {
        if (!element) {
            return;
        }
        if (leftPosition === 0) {
            // should be the first but is NOT
            if (element.previousElementSibling) {
                element.parentElement!.insertAdjacentElement('afterbegin', element);
            }

            return;
        }
        const expectedSibling = AccessibleGridHack.getExpectedSibling(cache, element.getAttribute('col-id') || 'none');
        // Verify the element to right has bigger offset
        let elementOnTheRight = element.nextElementSibling;
        if (
            elementOnTheRight instanceof HTMLElement &&
            (!expectedSibling.rightId || expectedSibling.rightId !== elementOnTheRight.getAttribute('col-id'))
        ) {
            if (elementOnTheRight.offsetLeft < leftPosition) {
                elementOnTheRight = AccessibleGridHack.getLastElementToLeft(elementOnTheRight, leftPosition);
                elementOnTheRight.insertAdjacentElement('afterend', element);
            }
            if (elementOnTheRight) {
                expectedSibling.rightId = elementOnTheRight.getAttribute('col-id');
            }
            return;
        }
        let elementOnTheLeft = element.previousElementSibling;
        if (
            elementOnTheLeft instanceof HTMLElement &&
            (!expectedSibling.leftId || expectedSibling.leftId !== elementOnTheLeft.getAttribute('col-id'))
        ) {
            if (elementOnTheLeft.offsetLeft > leftPosition) {
                elementOnTheLeft = AccessibleGridHack.getLastElementToRight(elementOnTheLeft, leftPosition);
                elementOnTheLeft.insertAdjacentElement('beforebegin', element);
            }
            if (elementOnTheLeft) {
                expectedSibling.leftId = elementOnTheLeft.getAttribute('col-id');
            }
            return;
        }
    };

    private static getLastElementToRight = function (elemToRight: Element, left: number) {
        let next = elemToRight.previousElementSibling;
        while (next) {
            if (!(next instanceof HTMLElement) || (next as HTMLElement).offsetLeft > left) {
                elemToRight = next;
                next = next.previousElementSibling;
            } else {
                break;
            }
        }
        return elemToRight;
    };
    private static getLastElementToLeft = function (elemToLeft: Element, left: number) {
        let next = elemToLeft.nextElementSibling;
        while (next) {
            if (!(next instanceof HTMLElement) || (next as HTMLElement).offsetLeft < left) {
                elemToLeft = next;
                next = next.nextElementSibling;
            } else {
                break;
            }
        }
        return elemToLeft;
    };
}
