import type { ParameterValue } from '../parameter';
import type { UDataSource, UKustoDataSource } from '../../core/domain/dataSource';
import type { KustoDataType } from '../kusto';
import type { StreamResult, StreamResultSuccess } from './streamResult';

export interface IQueryValidationResult {
    isValid: boolean;
    message: string;
}

/**
 * Unique identifier of the source of the query. Used to identify if queries
 * came form the same place for cache clearing purposes. Should be stable while changes to a query are made.
 * Is likely an id associated with the model that has the query, for example a tile id
 * or a parameter id.
 */
export type QuerySourceId = string;

/**
 * Used to check for query equivalency in the query cache. Should change
 * when anything that causes the query to change changes. For example, the
 * query's text should cause this to change, as well as changes to it's
 * parameters.
 */
export type QueryHash = string;

export interface IQuery<TDataSource = UDataSource> {
    readonly sourceId: QuerySourceId;
    readonly queryHash: QueryHash;
    readonly query: string;
    readonly dataSource: TDataSource;
    readonly parameters: ParameterValue[];
}

export type IKustoQuery = IQuery<UKustoDataSource>;

/**
 * Whenever this changes we need to update the indexed db version
 */
export type UQuery = IKustoQuery;

/// Results ///

// eslint-disable-next-line no-shadow
export enum ResultSource {
    online = 'online',
    cache = 'cache',
}

export interface KustoQueryResultRowObject {
    [column: string]: number | string | null;
}

export interface KustoQueryResultColumn {
    name: string;
    type: KustoDataType;
}

export interface ExternalKustoQueryResult {
    columns: KustoQueryResultColumn[];
    rows: KustoQueryResultRowObject[];
    receivedTime: Date;
    source: ResultSource;
    isSorted: boolean;
}

export interface KustoQueryResult extends ExternalKustoQueryResult {
    scopeId: string;
}

// eslint-disable-next-line no-shadow
export enum FragmentOperation {
    replace = 'replace',
    combine = 'combine',
}

export interface QueryResultFragment {
    data: ExternalKustoQueryResult;
    operation: FragmentOperation;
}

export type StreamQuerySuccess = StreamResultSuccess<KustoQueryResult>;
export type StreamQueryResult = StreamResult<KustoQueryResult>;
