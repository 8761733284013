import { v4 as uuid } from 'uuid';

import { APP_STRINGS, APP_CONSTANTS } from '../../../../res';
import { tagScalar } from '../../../value';

import { DurationParamConfig } from './duration';

export function createDefaultDurationParameter(id = uuid()): DurationParamConfig {
    return new DurationParamConfig(
        id,
        '_startTime',
        '_endTime',
        APP_STRINGS.duration.label,
        tagScalar('duration', APP_CONSTANTS.defaultPickerDuration)
    );
}
