import { useLayoutEffect } from 'react';
import { autorun, runInAction } from 'mobx';

import { useVisualConfig } from '../../../domain';
import { APP_STRINGS, APP_CONSTANTS } from '../../../res';
import { useETPDispatch, ETPAction } from '../../../store/editTile';
import { useDashboardStore } from '../../../store/dashboard';
import { IParameterSelections } from '../../../store';

import { buildQueryHashFromTile } from './util';

const loadingErrorAction: ETPAction = {
    type: 'loadingError',
    message: APP_STRINGS.editTilePage.tileNotFoundError,
};
const initializeNewQueryAction: ETPAction = {
    type: 'loadingError',
    message: APP_STRINGS.noEditPermissionsError,
};
const initializeNewMarkdownAction: ETPAction = {
    type: 'initializeNewMarkdown',
};

export const useInitializeETP = (tileId: string, parameterSelections: undefined | IParameterSelections) => {
    const [dispatch] = useETPDispatch();
    const visualConfig = useVisualConfig();
    const store = useDashboardStore();

    useLayoutEffect(() => {
        const disposeReaction = autorun(() => {
            if (parameterSelections === undefined) {
                return;
            }
            const state = store.state;
            if (state === undefined) {
                dispatch({ type: 'reset' });
                return;
            }

            if (state.changes === undefined) {
                state.startEditing();
            }

            if (!state.meta.isDashboardEditor) {
                dispatch(initializeNewQueryAction);
                return;
            }

            runInAction(() => {
                switch (tileId) {
                    case APP_CONSTANTS.editTilePage.newQueryTileId: {
                        dispatch({
                            type: 'initializeNewQuery',
                            availableParameters: state.parameters,
                            defaultDataSourceId: state.dataSources[0]?.id,
                            parameterSelections: Object.values(parameterSelections.selections),
                        });
                        break;
                    }
                    case APP_CONSTANTS.editTilePage.newMarkdownTileId:
                        dispatch(initializeNewMarkdownAction);
                        break;
                    default: {
                        const tile = state.tilesRecord[tileId];
                        if (tile) {
                            const dataSource =
                                tile?.dataSourceId !== undefined && state.dataSourcesRecord[tile.dataSourceId];

                            const queryHash =
                                state && tile && dataSource
                                    ? buildQueryHashFromTile(tile, state, dataSource)
                                    : undefined;
                            dispatch({
                                type: 'initializeFromTile',
                                tile,
                                visualConfig,
                                queryHash,
                                parameterSelections: Object.values(parameterSelections.selections),
                            });
                        } else {
                            dispatch(loadingErrorAction);
                        }
                    }
                }
            });
        });
        return () => {
            disposeReaction();
            dispatch({ type: 'reset' });
        };
    }, [tileId, store, dispatch, visualConfig, parameterSelections]);
};
