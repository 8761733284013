/* eslint-disable @typescript-eslint/no-redeclare */

import {
    caseInsensitiveComparer,
    dotnetTypeToKustoType,
    formatLiterals,
    KustoDomains,
    extractErrorDescriptionAndTrace,
    ColumnType,
} from '@kusto/common';
import isEmpty from 'lodash/isEmpty';
import { applySnapshot, flow, getParent, getRoot, IMSTMap, SnapshotIn, types } from 'mobx-state-tree';
import { IAnyType } from 'mobx-state-tree/dist/internal';
import { Column, EntityType, Function, Table } from '../common/types';
import { dependencies } from '../dependencies';
import { ExecutionResult, ValueRow } from '@kusto/common';
import { FetchState } from './common';
import { NotificationType, RootStore } from './rootStore';
class BaseTableSchema {
    Name: string | undefined;
    OrderedColumns!: { Name: string; Type: string; CslType?: ColumnType; DocString?: string }[];
    Folder?: string;
    DocString?: string;
    tableType!: EntityType.Table | EntityType.ExternalTable | EntityType.MaterializedViewTable;
}

class TableSchema extends BaseTableSchema {}

class ExternalTableSchema extends BaseTableSchema {}

class MaterializedViewSchema extends BaseTableSchema {}

export const EntityTypeStore = types.enumeration<EntityType>('EntityType', Object.values(EntityType));

function setTableType(
    tables:
        | {
              [name: string]: TableSchema | ExternalTableSchema | MaterializedViewSchema;
          }
        | undefined,
    tableType: EntityType.Table | EntityType.ExternalTable | EntityType.MaterializedViewTable
) {
    if (tables) {
        Object.keys(tables).forEach((tableName) => {
            tables[tableName].tableType = tableType;
        });
    }
}

const convertDatabaseJsonToObjectModel = (clusterName: string, database: KustoSchema['Databases']['id']) => {
    const databaseId = `${clusterName}/${database.Name}`;
    const majorVersion = database.MajorVersion;
    const minorVersion = database.MinorVersion;
    const prettyName = database.PrettyName;

    setTableType(database.Tables, EntityType.Table);
    setTableType(database.ExternalTables, EntityType.ExternalTable);
    setTableType(database.MaterializedViews, EntityType.MaterializedViewTable);
    const tables = Object.assign({}, database.Tables, database.ExternalTables, database.MaterializedViews || {});
    const tableNames = Object.keys(tables).sort(caseInsensitiveComparer);
    const tbls: { [i: string]: Table } = tableNames
        .map((tableName) => {
            const tableId = `${databaseId}/${tableName}`;
            const table: TableSchema | ExternalTableSchema | MaterializedViewSchema = tables[tableName];
            const columns = table.OrderedColumns;
            const folder = table.Folder;
            const docstring = table.DocString;
            const cols = columns
                .map(
                    (column): Column => ({
                        entityType: EntityType.Column as EntityType.Column,
                        id: `${tableId}/${column.Name}`,
                        name: column.Name,
                        // Maybe not correct? `dotnetTypeToKustoType` has properties that aren't of `ColumnType`.
                        type: (column.CslType || dotnetTypeToKustoType[column.Type] || column.Type) as ColumnType,
                        docstring: column.DocString,
                    })
                )
                .reduce<{ [i: string]: Column }>((prev, curr) => {
                    prev[curr.id] = curr;
                    return prev;
                }, {});
            return {
                entityType: table.tableType,
                name: tableName,
                id: tableId,
                columns: cols,
                folder,
                docstring,
            };
        })
        .reduce<{ [i: string]: Table }>((prev, curr) => {
            prev[curr.id] = curr;
            return prev;
        }, {});
    const functions = database.Functions;
    const functionNames = Object.keys(functions);
    const fns = functionNames
        .map((functionName) => {
            const fn = functions[functionName];
            return {
                entityType: EntityType.Function as EntityType.Function,
                body: fn.Body,
                docstring: fn.DocString,
                folder: fn.Folder,
                functionKind: fn.FunctionKind,
                name: fn.Name,
                outputColumns: fn.OutputColumns,
                inputParameters: fn.InputParameters
                    ? fn.InputParameters.map((inputParam) => ({
                          cslType: inputParam.CslType,
                          name: inputParam.Name,
                          type: inputParam.Type,
                          cslDefaultValue: inputParam.CslDefaultValue,
                          columns: inputParam.Columns
                              ? inputParam.Columns.map((col) => ({
                                    name: col.Name,
                                    type: col.Type,
                                    cslType: col.CslType,
                                }))
                              : undefined,
                      }))
                    : [],
                id: `$function_${databaseId}/${fn.Name}`,
            };
        })
        // eslint-disable-next-line @typescript-eslint/ban-types
        .reduce<{ [i: string]: Function }>((prev, curr) => {
            prev[curr.id] = curr;
            return prev;
        }, {});

    return {
        name: database.Name,
        prettyName,
        minorVersion,
        majorVersion,
        id: databaseId,
        tables: tbls,
        functions: fns,
        accessMode: database.DatabaseAccessMode,
    };
};

const kustoClient = dependencies.kustoClient;

/**
 * Represents a Database in the kusto connection pane
 */
export const Database = types
    .model('Database', {
        id: types.identifier,
        name: types.string,
        prettyName: types.optional(types.union(types.string, types.undefined), undefined),
        fetchState: types.optional(FetchState, 'done'),
        fetchStateError: types.optional(types.frozen(), ''),
        tables: types.optional(types.map(types.frozen<Table>()), {}),
        // eslint-disable-next-line @typescript-eslint/ban-types
        functions: types.optional(types.map(types.frozen<Function>()), {}),
        accessMode: types.maybe(types.string),
        minorVersion: types.number,
        majorVersion: types.number,
    })
    .views((self) => ({
        get entityType(): EntityType {
            return EntityType.Database;
        },
        get cluster(): { name: string; getAlias: () => string } {
            return getParent(self, 2) as { name: string; getAlias: () => string };
        },
        get materializedViewTables(): Table[] {
            const tables = Array.from(self.tables.values());
            return tables.filter((table) => table.entityType === EntityType.MaterializedViewTable);
        },
        get externalTables(): Table[] {
            const tables = Array.from(self.tables.values());
            return tables.filter((table) => table.entityType === EntityType.ExternalTable);
        },
        get regularTables(): Table[] {
            const tables = Array.from(self.tables.values());
            return tables.filter((table) => table.entityType === EntityType.Table);
        },
    }))
    .volatile(() => ({
        isFetching: false,
        isBackgroundFetch: false,
        // eslint-disable-next-line @typescript-eslint/ban-types
        rowDataTypeCache: {} as { [id: string]: {} },
    }))
    .actions((self) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const setInCache = (id: string, item: any) => {
            self.rowDataTypeCache[id] = item;
        };
        const getFromCache = (id: string) => {
            return self.rowDataTypeCache[id];
        };
        const getClusterConnection = () =>
            getParent(self, 2) as {
                name: string;
                connectionString: string;
                initialCatalog?: string;
                clusterUrl: string;
                fetchCurrentSchema: (a: boolean, b: boolean) => Promise<void>;
            };
        /**
         * Fetch the current scheme. isBackgroundFetch will hide from the user the fact that a fetch is in progress.
         * This is useful in .create table commands that change the schema, and we would like to refresh the connection
         * pane without drawing too much attention to that fact.
         * @param isBackgroundFetch true if this should be done without displaying any visual loading indication
         * @param fetchParentsAsWell true if we should fetch cluster before fetching database.
         */
        const fetchCurrentSchema = flow(function* (isBackgroundFetch: boolean, fetchParentsAsWell: boolean) {
            if (self.isFetching) {
                return;
            }
            self.isFetching = true;
            self.isBackgroundFetch = isBackgroundFetch;
            try {
                const {
                    name: clusterName,
                    initialCatalog,
                    fetchCurrentSchema: fetchClusterSchema,
                    clusterUrl,
                } = getClusterConnection();

                if (fetchParentsAsWell) {
                    yield fetchClusterSchema(true, false);
                }

                const showSchemaResult: ExecutionResult<'v1'> = yield kustoClient.executeControlCommand(
                    clusterUrl,
                    initialCatalog,
                    `.show database ['${self.name}'] schema as json`
                );

                const singleCell = (showSchemaResult.apiCallResult.Tables[0].Rows[0] as ValueRow)[0] as string;
                const schema = JSON.parse(singleCell) as KustoSchema;
                const databases = schema.Databases;
                // There's only one database. let's get a handle of it.
                const databaseName = Object.keys(databases)[0];
                const databaseJson = databases[databaseName];
                const database = convertDatabaseJsonToObjectModel(clusterName, databaseJson);
                self.rowDataTypeCache = {};
                self.fetchState = 'done';
                self.isFetching = false;
                applySnapshot(self, database);
            } catch (e) {
                self.fetchState = 'failed';
                self.isFetching = false;
                self.fetchStateError = extractErrorDescriptionAndTrace(e);
            }
        });

        const dropTable = flow(function* (table: Table) {
            try {
                const { clusterUrl } = getClusterConnection();
                yield kustoClient.executeControlCommand(clusterUrl, self.name, `.drop table ['${table.name}'] `);
                (getRoot(self) as RootStore).setNotification({
                    type: NotificationType.Success,
                    fadeOut: true,
                    title: dependencies.strings.dropTable,
                    content: formatLiterals(dependencies.strings.tableDropped, {
                        table: table,
                    }),
                });
            } catch (e) {
                (getRoot(self) as RootStore).setNotification({
                    type: NotificationType.Error,
                    fadeOut: false,
                    title: dependencies.strings.dropTable,
                    content: extractErrorDescriptionAndTrace(e).errorMessage,
                });
            }
            fetchCurrentSchema(true, false);
        });
        const getDisplayName = () => self.prettyName || self.name;
        return {
            fetchCurrentSchema,
            dropTable,
            setInCache,
            getFromCache,
            getDisplayName,
        };
    })
    .volatile(() => ({
        _isFavorite: undefined as undefined | boolean,
    }))
    .extend((self) => ({
        views: {
            get isFavorite(): boolean {
                if (self._isFavorite === undefined) {
                    const connections = getParent(self, 4) as {
                        favorites: IMSTMap<IAnyType>;
                    };
                    return connections.favorites.has(self.id);
                }
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return self._isFavorite!;
            },
        },
        actions: {
            setIsFavorite(value: boolean) {
                self._isFavorite = value;
            },
        },
    }));
// eslint-disable-next-line no-redeclare
export type Database = typeof Database.Type;

/**
 * Represents the JSON result of executing .show schema as json
 */
interface KustoSchema {
    Databases: {
        [key: string]: {
            Name: string;
            Tables: {
                [key: string]: TableSchema;
            };
            ExternalTables?: {
                [key: string]: ExternalTableSchema;
            };
            MaterializedViews?: {
                [key: string]: MaterializedViewSchema;
            };
            PrettyName?: string;
            MajorVersion: number;
            MinorVersion: number;
            Functions: {
                [key: string]: {
                    Name: string;
                    InputParameters:
                        | {
                              Name: string;
                              Type?: string;
                              CslType?: string;
                              CslDefaultValue?: string;
                              Columns?: {
                                  Name: string;
                                  Type: string;
                                  CslType: string;
                                  DocString?: string;
                              }[];
                          }[]
                        | undefined;
                    Body: string;
                    Folder: string;
                    DocString: string;
                    FunctionKind: string;
                    OutputColumns: string[];
                };
            };
            DatabaseAccessMode?: string;
        };
    };
}

export const ClusterType = types.enumeration('ClusterType', [
    'Engine',
    'DataManagement',
    'ClusterManager',
    'Bridge',
    'ResourceProvider',
    'HealthSuite',
    'Billing',
    'Gaia',
    'Proxy',
    'ServicesProcHost',
    'Flighting',
]);
// eslint-disable-next-line no-redeclare
export type ClusterType = typeof ClusterType.Type;

export const CmSchema = types.model('CmSchema', {
    accounts: types.array(types.string),
    services: types.array(types.string),
});
// eslint-disable-next-line no-redeclare
export type CmSchema = typeof CmSchema.Type;

/**
 * Represents a kusto cluster in the connection Pane. including all operations on it (including functionaliy lie
 * refreshing schema)
 */
export const Cluster = types
    .model('Cluster', {
        clusterType: types.optional(ClusterType, 'Engine'),
        cmSchema: types.maybe(CmSchema),
        alias: types.maybe(types.string),
        name: types.string,
        connectionString: types.string,
        initialCatalog: types.maybe(types.string),
        databases: types.optional(types.map(Database), {}),
        id: types.identifier,
        fetchState: types.optional(FetchState, 'notStarted'),
        fetchStateError: types.optional(types.frozen(), ''),
        tooBigToCache: types.optional(types.boolean, false),
    })
    .extend((self) => ({
        views: {
            get clusterUrl(): string {
                return getClusterUrl(self.connectionString);
            },
            async getClusterType() {
                const url = getClusterUrl(self.connectionString);
                if (!KustoDomains.isAriaDomain(url)) {
                    const result = await kustoClient.executeControlCommand(url, self.initialCatalog, '.show version');
                    return (result.apiCallResult.Tables[0].Rows[0] as ValueRow)[2] as ClusterType;
                }
            },
            getAlias(): string {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return isEmpty(self.alias) ? self.name : self.alias!;
            },
        },
    }))
    .postProcessSnapshot((snapshot) => {
        if (!snapshot.tooBigToCache) {
            return snapshot;
        }
        // remove tables and functions from DB cache if the cluster is too big for caching
        const updatedDBs = Object.values(snapshot.databases)
            .map((db) => ({
                ...db,
                // Reset data
                tables: {},
                functions: {},
                fetchStateError: '',
                fetchState: 'notStarted',
            }))
            .reduce((dbs, db) => {
                dbs[db.id] = db;
                return dbs;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, {} as { [id: string]: any });
        return {
            ...snapshot,
            databases: updatedDBs,
        };
    })
    .volatile(() => ({
        isFetching: false,
        isBackgroundFetch: false,
    }))
    .actions((self) => ({
        /**
         * Fetch the current scheme. isBackgroundFetch will hide from the user the fact that a fetch is in progress.
         * This is useful in .create table commands that change the schema, and we would like to refresh the connection
         * pane without drawing too much attention to that fact.
         * @param isBackgroundFetch if true, we won't display a loading indicator on the entity.
         * @param fetchParentsAsWell in this case - it does nothing since Cluster is the top level. This is here for API
         * consistency.
         */
        fetchCurrentSchema: flow(function* fetchCurrentSchema(
            isBackgroundFetch: boolean,
            _fetchParentsAsWell: boolean
        ) {
            if (self.isFetching) {
                return;
            }
            self.isFetching = true;
            self.isBackgroundFetch = isBackgroundFetch;
            try {
                const clusterName = self.name;
                self.clusterType = yield self.getClusterType() || self.clusterType;

                if (self.clusterType === 'ClusterManager') {
                    const showServiceModelResult: ExecutionResult<'v1'> = yield kustoClient.executeControlCommand(
                        self.clusterUrl,
                        self.initialCatalog,
                        '.show service model'
                    );

                    const serviceModelResultRows = showServiceModelResult.apiCallResult.Tables[0].Rows as ValueRow[];
                    const accounts = Array.from(new Set(serviceModelResultRows.map((row) => row[0] as string)));
                    const services = Array.from(new Set(serviceModelResultRows.map((row) => row[1] as string)));

                    self.cmSchema = CmSchema.create({
                        accounts,
                        services,
                    });

                    self.databases.replace({});
                    self.fetchState = 'done';
                    self.isFetching = false;
                    return;
                }

                // No dynamic schema for Non CM/Engine clusters.
                if (self.clusterType !== 'Engine') {
                    self.databases.replace({});
                    self.fetchState = 'done';
                    self.isFetching = false;
                    return;
                }

                const showDatabasesResult: ExecutionResult<'v1'> = yield kustoClient.executeControlCommand(
                    self.clusterUrl,
                    self.initialCatalog,
                    '.show databases'
                );

                const resultRows = showDatabasesResult.apiCallResult.Tables[0].Rows as ValueRow[];

                // we won't load the entire schema for clusters with too many databases.
                if (resultRows.length > 50) {
                    self.tooBigToCache = true;
                    const lazyDBs = resultRows
                        .map((row) => {
                            const databaseName = row[0] as string;
                            const prettyName = (row[5] as string) || undefined;
                            const databaseId = `${clusterName}/${databaseName}`;
                            // intellisense library relies on db versions when deciding to cache things.
                            // Since this is a lazy DB, we want the intellisense to refresh the moment we get
                            // a real database version.
                            const majorVersion = -1;
                            const minorVersion = -1;
                            const accessMode = row[4] as string;
                            const db: SnapshotIn<typeof Database> = {
                                name: databaseName,
                                id: databaseId,
                                prettyName,
                                minorVersion,
                                majorVersion,
                                accessMode,
                                fetchState: 'notStarted' as const,
                            };
                            return db;
                        })
                        .reduce((prev, curr) => {
                            prev[curr.id] = curr;
                            return prev;
                        }, {} as { [key: string]: SnapshotIn<typeof Database> });
                    // Cannot handle complexity anymore...
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    self.databases.replace(lazyDBs as any);
                    self.fetchState = 'done';
                    self.isFetching = false;
                    return;
                }

                const showSchemaResult: ExecutionResult<'v1'> = yield kustoClient.executeControlCommand(
                    self.clusterUrl,
                    self.initialCatalog,
                    '.show schema as json'
                );

                const singleCell = (showSchemaResult.apiCallResult.Tables[0].Rows[0] as ValueRow)[0] as string;
                if (singleCell.length > 1024 * 1024) {
                    self.tooBigToCache = true;
                }
                const schema = JSON.parse(singleCell) as KustoSchema;
                const databases = schema.Databases;
                const databaseNames: string[] = Object.keys(databases)
                    .sort(caseInsensitiveComparer)
                    // We don't want to show internal databases.
                    .filter((name) => name !== 'KustoMonitoringPersistentDatabase' && name !== '$systemdb');
                const dbs = databaseNames
                    .map((databaseName) => {
                        return convertDatabaseJsonToObjectModel(clusterName, databases[databaseName]);
                    })
                    .reduce(
                        // couldn't figure out how to make this type-safe
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (prev: any, curr) => {
                            prev[curr.id] = curr;
                            return prev;
                        },
                        {}
                    );
                self.databases.replace(dbs);
                self.fetchState = 'done';
                self.isFetching = false;
            } catch (e) {
                self.fetchState = 'failed';
                self.isFetching = false;
                self.fetchStateError = extractErrorDescriptionAndTrace(e);
            }
        }),
    }))
    .volatile(() => ({
        _isFavorite: undefined as undefined | boolean,
    }))
    .extend((self) => ({
        views: {
            get entityType(): EntityType {
                return EntityType.Cluster;
            },
            get isFavorite(): boolean {
                if (self._isFavorite === undefined) {
                    const connections = getParent(self, 2) as {
                        favorites: IMSTMap<IAnyType>;
                    };
                    return connections.favorites.has(self.id);
                }
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return self._isFavorite!;
            },
        },
        actions: {
            setIsFavorite(value: boolean) {
                self._isFavorite = value;
            },
            setAlias(value?: string) {
                self.alias = value;
            },
        },
    }));
// eslint-disable-next-line no-redeclare
export type Cluster = typeof Cluster.Type;

/**
 * A convenience method to normalize cluster or a database to a cluster, database pair).
 * @param entity the Entity
 */
export const getClusterAndDatabaseFromEntity = (
    entity: Cluster | Database
): { cluster: Cluster; database: Database | null } => {
    switch (entity.entityType) {
        case 'Cluster':
            return { cluster: entity as Cluster, database: null };
        case 'Database':
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const cluster = (getRoot(entity) as RootStore).connectionPane.connections.get(entity.id.split('/')[0])!;
            // first parent is the array. 2nd parent is the cluster.
            return { cluster, database: entity as Database };
        default:
            throw new Error(`unexpected entityType ${entity.entityType}`);
    }
};

export const getClusterUrl = (url: string): string => {
    return url.split(';')[0];
};
