import * as React from 'react';
import { useMemo, useCallback, useLayoutEffect, useState } from 'react';
import { ActionButton } from 'office-ui-fabric-react';

import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { APP_STRINGS } from '../../../res';
import { TFormComponent, formActionMacro } from '../../../common';
import { UDataSource } from '../../../core/domain';
import { DashboardLoaded } from '../../../store/dashboard';

import { PanelWithBack, PanelWithBackProps } from './components/PanelWithBack/PanelWithBack';
import { DataSourcePreview } from './components/DataSourcePreview';

import styles from './Manage.module.scss';
import { DataSourceEditor } from './components/DataSourceEditor';

interface ManageDataSourcesListProps {
    dataSources: readonly UDataSource[];
    onAdd: () => void;
    onEdit: (dataSource: UDataSource) => void;
}

const ManageDataSourcesList: React.FC<ManageDataSourcesListProps> = ({ dataSources, onAdd, onEdit }) => (
    <div className={styles.manage}>
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={onAdd}>
            {APP_STRINGS.forms.dataSource.new}
        </ActionButton>
        {dataSources.map((dataSource) => (
            <DataSourcePreview key={dataSource.id} dataSource={dataSource} onEdit={onEdit} />
        ))}
    </div>
);

export interface ManageDataSourcePanelArgs {
    dashboardState: DashboardLoaded;
    straightToAddDataSource?: boolean;
    exclusiveSingleAdd?: boolean;
    onComplete?: (newDataSource: UDataSource | undefined) => void;
}

export const ManageDataSourcesPanel: TFormComponent<ManageDataSourcePanelArgs> = observer(
    function ManageDataSourcesPanel({
        onClose,
        dashboardState,
        straightToAddDataSource,
        exclusiveSingleAdd,
        onComplete,
    }) {
        const [editingDataSource, setEditingDataSource] = useState<
            undefined | { kind: 'edit' | 'add'; dataSource?: UDataSource }
        >(() => {
            if (straightToAddDataSource) {
                return {
                    kind: 'add',
                    dataSource: dashboardState.dataSources[0],
                };
            }
            return undefined;
        });

        const onAdd = useCallback(() => setEditingDataSource({ kind: 'add' }), []);

        const onEdit = useCallback((dataSource: UDataSource) => setEditingDataSource({ kind: 'edit', dataSource }), []);

        const onSave = useMemo(
            () =>
                action((dataSource: UDataSource) => {
                    dashboardState.addItem('dataSources', dataSource);
                    if (exclusiveSingleAdd) {
                        onClose();
                        onComplete?.(dataSource);
                    } else {
                        setEditingDataSource(undefined);
                    }
                }),
            [dashboardState, exclusiveSingleAdd, onClose, onComplete]
        );

        const onBack = useCallback(() => setEditingDataSource(undefined), []);

        const onCloseWithoutOutput = useCallback(() => {
            onClose();
            onComplete?.(undefined);
        }, [onComplete, onClose]);

        useLayoutEffect(() => {
            // Layout effect to prevent flashing
            if (exclusiveSingleAdd) {
                onAdd();
            }
        }, [exclusiveSingleAdd, onAdd]);

        const sharedProps: Partial<PanelWithBackProps> = {
            isOpen: true,
            onDismiss: onCloseWithoutOutput,
            onBack,
            isFooterAtBottom: true,
            closeButtonAriaLabel: APP_STRINGS.forms.dataSource.aria.closePanelLabel,
            backButtonAriaLabel: APP_STRINGS.forms.dataSource.aria.backPanelLabel,
        };

        // Important that `<PanelWithBack />` is used in both places directly below
        // this component so it doesn't open/close when opening the data source editor

        if (editingDataSource) {
            return (
                <PanelWithBack
                    {...sharedProps}
                    className={styles.panelEditing}
                    headerText={
                        editingDataSource.kind === 'add'
                            ? APP_STRINGS.forms.dataSource.addTitle
                            : APP_STRINGS.forms.dataSource.editTitle
                    }
                >
                    <DataSourceEditor
                        dataSource={editingDataSource.dataSource}
                        onApply={onSave}
                        onCancel={onBack}
                        dashboardState={dashboardState}
                    />
                </PanelWithBack>
            );
        }

        return (
            <PanelWithBack {...sharedProps} headerText={APP_STRINGS.forms.dataSource.title} isLightDismiss>
                <ManageDataSourcesList dataSources={dashboardState.dataSources} onAdd={onAdd} onEdit={onEdit} />
            </PanelWithBack>
        );
    }
);

export const manageDataSourcesAction = formActionMacro(ManageDataSourcesPanel, 'manageDataSources');
