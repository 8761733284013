import { MutableRefObject, useRef, useEffect } from 'react';

/**
 * An always updated ref that contains the latest value.
 * Used to reference a value without recalculating memoized (useEffect/useMemo/useCallback) functions
 */
export const useCurrent = <T>(item: T): MutableRefObject<T> => {
    const refItem = useRef(item);

    useEffect(() => {
        refItem.current = item;
    }, [item]);

    return refItem;
};
