import { caseInsensitiveComparer } from '@kusto/common';
import { EntityType } from '../../../common/types';
import { Database } from '../../../stores/cluster';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';
import { getActions } from './ActionMapper';
import { getFromCacheOrCreate } from './RowDataTypeCache';
import { sortedDatabaseFolders } from '../../../common/entityTypeUtils';
import { RowDataTypeBase } from './RowDataTypeBase';
import { iconMapper } from './IconMapper';

/**
 * A Database's descendants should be sorted in a case insensitive and alphabetical order but the root folders
 * ("Functions", "Materialized Views", "Tables", "External Tables") should be at the top.
 * When sorting, use this comparer to achieve this order.
 */
export const databaseDescendantsComparer = (a: RowDataType, b: RowDataType): number => {
    if (a == null && b == null) return 0;
    if (b == null) return 1;
    if (a == null) return -1;

    // root folders should be at the top
    for (const rootFolderType of sortedDatabaseFolders) {
        if (a.entityType === rootFolderType) return -1;
        if (b.entityType === rootFolderType) return 1;
    }

    // Folders should always come before entities.
    if (a.entityType === EntityType.Folder && b.entityType !== EntityType.Folder) return -1;
    if (a.entityType !== EntityType.Folder && b.entityType === EntityType.Folder) return 1;

    // Entities and regular folders (=none root ones) should have case insensitive sorting.
    const comparerIndex = Math.min(a.pathFromRoot.length - 1, b.pathFromRoot.length - 1);
    return caseInsensitiveComparer(a.pathFromRoot[comparerIndex], b.pathFromRoot[comparerIndex]);
};

export class DatabaseRowDataType extends RowDataTypeBase {
    private database: Database;
    private isLoading: boolean;

    private constructor(database: Database, isLoading?: boolean, details?: string) {
        super(
            database.id,
            [database.cluster.getAlias(), database.name],
            EntityType.Database,
            database,
            iconMapper[database.accessMode || 'none'] || iconMapper.Database
        );
        this.database = database;
        this.isLoading = isLoading ?? DatabaseRowDataType.isLoading(database);
        this.details = details ?? DatabaseRowDataType.details(database, this.isLoading);
        this.isFavorite = database.isFavorite;
        this.actions = getActions(database.entityType, this.isFavorite, undefined);
    }

    public static isLoading(database: Database) {
        return database.isFetching && !database.isBackgroundFetch;
    }

    public static details(database: Database, isLoading: boolean) {
        return isLoading ? 'Loading' : database.prettyName;
    }

    public static fromCacheOrCreate(
        database: Database,
        isLoading?: boolean,
        details?: string,
        shouldRefreshCache?: (old: RowDataType) => boolean
    ): RowDataType {
        return getFromCacheOrCreate(
            database,
            database.id,
            () => new DatabaseRowDataType(database, isLoading, details),
            shouldRefreshCache
        );
    }
}
