import React from 'react';
import { createContext, useContext } from 'react';

import { darkTheme, lightTheme, RtdTheme } from './themes';

/**
 * Contains all css variables
 */
export const THEME_ROOT_CLASS = 'rtd-theme-variables';

/**
 * Dangerous! Ensure that anything we do not trust is not passed as an argument to this.
 */
export function createStyleElement(innerHTML: string): () => void {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = innerHTML;

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(styleElement);

    return () => head.removeChild(styleElement);
}

function createRtdThemeCssRule(rtdTheme: RtdTheme, isDark: boolean): string {
    const { uiFabricTheme, neutralColors, additionalColors } = rtdTheme;

    const parts: string[] = [];

    parts.push(`  color-scheme: ${isDark ? 'dark' : 'light'};`);

    const colors = [
        ...Object.entries(uiFabricTheme.palette),
        ...Object.entries(neutralColors),
        ...Object.entries(additionalColors),
    ];

    for (const [name, color] of colors) {
        parts.push(`  --${name}: ${color};`);
    }

    for (const [name, color] of Object.entries(uiFabricTheme.semanticColors)) {
        parts.push(`  --${name}Color: ${color};`);
    }

    return `.${THEME_ROOT_CLASS} {
${parts.join('\n')}
}`;
}

export interface IThemeProviderProps {
    isDark?: boolean;
}

export interface ThemeContextState {
    isDark: boolean;
    theme: RtdTheme;
    classNames: string;
}

export const ThemeContext = createContext<undefined | ThemeContextState>(undefined);

export const ThemeProvider: React.FC<IThemeProviderProps> = ({ isDark = false, children }) => {
    const state = React.useMemo(() => {
        const theme = isDark ? darkTheme : lightTheme;
        const dispose = createStyleElement(createRtdThemeCssRule(theme, isDark));
        return {
            value: { isDark, theme, classNames: THEME_ROOT_CLASS + ' ' + (isDark ? 'darkMode' : 'lightMode') },
            dispose,
        };
    }, [isDark]);

    React.useEffect(() => () => state.dispose(), [state]);

    return <ThemeContext.Provider value={state.value}>{children}</ThemeContext.Provider>;
};

export const useThemeState = (): ThemeContextState => {
    const value = useContext(ThemeContext);

    if (value === undefined) {
        throw new Error('useThemeState can only be used inside of ThemeContext');
    }

    return value;
};
