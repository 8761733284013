import { formatLiterals, TelemetryContext } from '@kusto/common';
import { IconButtonWithTooltip } from '@kusto/common/';
import { IStyle, ITheme } from 'office-ui-fabric-react/lib/Styling';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@uifabric/utilities';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { IIconProps } from 'office-ui-fabric-react/lib/components/Icon';
import { Label } from 'office-ui-fabric-react/lib/components/Label';
import { ISearchBox, SearchBox } from 'office-ui-fabric-react/lib/components/SearchBox';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/components/Stack';
import React, { useContext, useEffect, useState } from 'react';
const getClassNames = classNamesFunction<ResultTabsStyleProps, SearchStyles>();

export type ResultTabsStyleProps = Required<Pick<SearchProps, 'theme'>>;

export interface SearchStyles {
    stack: IStyle;
    searchBox: IStyle;
}

const getStyles = (props: ResultTabsStyleProps): SearchStyles => {
    const { theme } = props;
    const { palette } = theme;

    return {
        stack: [
            'searchWithPrevNextButtons-stack',
            {
                backgroundColor: palette.white,
                width: '100%',
                paddingLeft: '15px',
            },
        ],
        searchBox: [
            'searchWithPrevNextButtons-searchBox',
            {
                width: 300,
            },
        ],
    };
};

export interface SearchStrings {
    querySearchResults$noResults: string;
    querySearchResults$countResults: string;
    querySearchResults$prevButton: string;
    querySearchResults$nextButton: string;
    querySearchResults$closeButton: string;
}

export interface SearchProps {
    /**
     * Should be updated in onSearch and onChange to reflect the number of results.
     */
    totalResultsCount: number;

    /**
     * Triggered when user tapped on the next button or the next keyboard shortcut (Enter)
     * @returns a new position instead of a simple +/- 1.
     */
    onNext: () => number | undefined;
    /**
     * Triggered when user tapped on the prev button or the prev keyboard shortcut (Shift+Enter)
     * @returns a new position instead of a simple +/- 1.
     */
    onPrev: () => number | undefined;
    onPositionChanged: (position: number) => void;
    /**
     * happens every time a user changes the search term.
     * @param completed needs to be invoked otherwise position will not be changed.
     */
    onSearch: (val: string, completed: () => void) => void;
    /**
     * - When set to true Next button will increase the position and Prev button will decrease it.
     * - When set to false Next button will decrease the position and Prev button will increase it.
     */
    shouldNextIncreaseCount: boolean;
    strings: SearchStrings;
    searchBoxRef?: React.RefObject<ISearchBox>;
    onClear?: () => void;

    theme?: ITheme;
    styles?: IStyleFunctionOrObject<ResultTabsStyleProps, SearchStyles>;
    hideCloseButton?: boolean;
}

const prevButtonIcon: IIconProps = { iconName: 'ChevronUp' };
const nextButtonIcon: IIconProps = { iconName: 'ChevronDown' };

/**
 * A search box with next and prev functionality.
 */
export const SearchBase = (props: SearchProps) => {
    const getTelemetryClient = useContext(TelemetryContext);
    const { trackEvent } = getTelemetryClient({ component: 'Search', flow: '' });

    const [position, setPosition] = useState(1);
    const [searchString, setSearchString] = useState('');
    const { totalResultsCount, onPositionChanged, onNext, onPrev } = props;
    // Forces re-render and allows triggering onPositionChanged even if position hasn't changed.
    const [triggerOnPositionChanged, setTriggerOnPositionChanged] = useState(0);

    // for now if totalResultsCount changes set position to 1.
    useEffect(() => {
        setPosition(1);
    }, [totalResultsCount, setPosition]);

    useEffect(() => {
        if (totalResultsCount) {
            onPositionChanged(position);
        }
    }, [triggerOnPositionChanged, totalResultsCount, onPositionChanged, position, setTriggerOnPositionChanged]);

    let positionLabelString = props.strings.querySearchResults$noResults;
    if (totalResultsCount && searchString && searchString.length > 0) {
        positionLabelString = formatLiterals(props.strings.querySearchResults$countResults, {
            position: position,
            totalResultsCount: totalResultsCount,
        });
    }
    const isButtonDisabled = !totalResultsCount || totalResultsCount <= 0 || searchString === '';
    const classNames = getClassNames(props.styles!, { theme: props.theme! });
    const shouldNextIncreaseCount = props.shouldNextIncreaseCount;
    const shouldPrevIncreaseCount = !shouldNextIncreaseCount;

    return (
        <Stack
            tokens={{ childrenGap: 6 }}
            horizontal
            onContextMenu={(e) => e.stopPropagation()}
            className={classNames.stack}
        >
            <Announced message={positionLabelString} />
            <SearchBox
                componentRef={props.searchBoxRef}
                autoFocus={true}
                onKeyUp={(event) => {
                    if (event.key === 'Enter' && event.shiftKey) {
                        trackEvent('onPrev', { trigger: 'shortcut' });
                        prev(
                            position,
                            setPosition,
                            onPrev,
                            totalResultsCount!,
                            shouldPrevIncreaseCount,
                            setTriggerOnPositionChanged
                        );
                    } else if (event.key === 'Enter') {
                        trackEvent('onNext', { trigger: 'shortcut' });
                        next(
                            position,
                            setPosition,
                            onNext,
                            totalResultsCount!,
                            shouldNextIncreaseCount,
                            setTriggerOnPositionChanged
                        );
                    }
                }}
                className={classNames.searchBox}
                clearButtonProps={{ style: { display: 'none' } }}
                onEscape={() => {
                    if (props.onClear) {
                        trackEvent('onClear', { trigger: 'shortcut' });
                        props.onClear();
                    }
                }}
                onChange={(_event, newValue) => {
                    searched(
                        newValue ?? '',
                        searchString,
                        props.onSearch,
                        setSearchString,
                        setPosition,
                        setTriggerOnPositionChanged
                    );
                }}
            />
            <Label style={{ maxWidth: 200 }}>{positionLabelString}</Label>
            <IconButtonWithTooltip
                tooltipProps={{ content: `${props.strings.querySearchResults$prevButton} (Shift+Enter)` }}
                ariaLabel={props.strings.querySearchResults$prevButton}
                iconProps={prevButtonIcon}
                onClick={() => {
                    trackEvent('onPrev', { trigger: 'button' });
                    prev(
                        position,
                        setPosition,
                        onPrev,
                        totalResultsCount!,
                        shouldPrevIncreaseCount,
                        setTriggerOnPositionChanged
                    );
                }}
                disabled={isButtonDisabled}
                styles={{ rootDisabled: { backgroundColor: props.theme?.palette.white } }}
            />
            <IconButtonWithTooltip
                tooltipProps={{ content: `${props.strings.querySearchResults$nextButton} (Enter)` }}
                ariaLabel={props.strings.querySearchResults$nextButton}
                iconProps={nextButtonIcon}
                onClick={() => {
                    trackEvent('onNext', { trigger: 'button' });
                    next(
                        position,
                        setPosition,
                        onNext,
                        totalResultsCount!,
                        shouldNextIncreaseCount,
                        setTriggerOnPositionChanged
                    );
                }}
                disabled={isButtonDisabled}
                styles={{ rootDisabled: { backgroundColor: props.theme?.palette.white } }}
            />
            <StackItem grow={true} align="stretch">
                <div style={{ width: '100%' }}></div>
            </StackItem>
            {props.hideCloseButton !== true && (
                <IconButtonWithTooltip
                    tooltipProps={{ content: `${props.strings.querySearchResults$closeButton}` }}
                    ariaLabel={props.strings.querySearchResults$closeButton}
                    key="CloseSearchButton"
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={() => {
                        if (props.onClear) {
                            trackEvent('onClear', { trigger: 'button' });
                            props.onClear();
                        }
                    }}
                />
            )}
        </Stack>
    );
};

const prev = (
    position: number,
    setPosition: (position: number) => void,
    onPrev: () => number | undefined,
    totalResultsCount: number,
    shouldPrevIncreaseCount: boolean,
    setTriggerOnPositionChanged: (value: number) => void
) => {
    if (onPrev) {
        const newPosition = onPrev();
        if (newPosition !== undefined) {
            setPosition(newPosition);
            setTriggerOnPositionChanged(Date.now());
            return;
        }
    }
    if (shouldPrevIncreaseCount) {
        increasePosition(position, setPosition, totalResultsCount);
    } else {
        decreasePosition(position, setPosition, totalResultsCount);
    }
};

const next = (
    position: number,
    setPosition: (position: number) => void,
    onNext: () => number | undefined,
    totalResultsCount: number,
    shouldNextIncreaseCount: boolean,
    setTriggerOnPositionChanged: (value: number) => void
) => {
    if (onNext) {
        const newPosition = onNext();
        if (newPosition !== undefined) {
            setPosition(newPosition);
            setTriggerOnPositionChanged(Date.now());
            return;
        }
    }
    if (shouldNextIncreaseCount) {
        increasePosition(position, setPosition, totalResultsCount);
    } else {
        decreasePosition(position, setPosition, totalResultsCount);
    }
};

const decreasePosition = (position: number, setPosition: (position: number) => void, totalResultsCount: number) => {
    let newPosition = position - 1;
    if (newPosition === 0) {
        newPosition = totalResultsCount;
    }
    setPosition(newPosition);
};

const increasePosition = (position: number, setPosition: (position: number) => void, totalResultsCount: number) => {
    let newPosition = position + 1;
    if (newPosition > totalResultsCount) {
        newPosition = 1;
    }
    setPosition(newPosition);
};

const searched = (
    newVal: string,
    oldVal: string,
    propsOnSearch: (val: string, completed: () => void) => void,
    setSearchString: (searchString: string) => void,
    setPosition: (position: number) => void,
    setTriggerOnPositionChanged: (value: number) => void
) => {
    if (oldVal !== newVal) {
        setSearchString(newVal);
        propsOnSearch(newVal, () => {
            const newPosition = 1;
            setPosition(newPosition);
            setTriggerOnPositionChanged(Date.now()); // after search always trigger onPositionChanged
        });
    }
};

export const Search = styled(SearchBase, getStyles);
