import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import lodash from 'lodash';

import { IDashboardDocument } from '../../core/domain';
import { VisualConfig } from '../../domain/visualConfig';
import { InitialParameters } from '../ParameterSelections';
import { deserializeDashboard, DashboardLatestVersion, MigrationResult } from '../../migration';
import { GlobalAction } from '../redux';
import { formatMigrationError } from '../../components/errors/formatMigrationError';
import { APP_CONSTANTS, APP_STRINGS } from '../../res';
import { WarningsList } from '../../components/errors/WarningsList';
import { rtdPrompt } from '../../components/Forms/Prompt';

import { DashboardLoaded } from './DashboardLoaded';
import { dashboardStoreToDocument } from './util';

/**
 * Should not contain any async code
 */
export class DashboardStore {
    state: DashboardLoaded | undefined = undefined;
    errorMessage: React.ReactNode | undefined = undefined;

    /**
     * When set, the next time the dashboard page is rendered, page should be
     * scrolled so tile is visible, and then value should be nulled.
     */
    pendingScrollToTile: string | undefined = undefined;

    constructor(public visualConfig: VisualConfig) {
        makeObservable(this, {
            state: observable.ref,
            pendingScrollToTile: observable.ref,
            errorMessage: observable.ref,
            isEditing: computed,
            startLoading: action,
            loadDashboard: action,
            loadTemplate: action,
            loadMigrationResult: action,
            changed: action,
            setErrorMessage: action,
        });

        this.changed = this.changed.bind(this);
    }

    dispose() {
        this.state?.dispose();
    }

    startLoading() {
        this.state?.dispose();
        this.state = undefined;
        this.errorMessage = undefined;
    }

    loadTemplate(template: DashboardLatestVersion): DashboardLoaded {
        return this.loadDashboard(deserializeDashboard(template));
    }

    loadMigrationResult(
        dashboard: MigrationResult<IDashboardDocument>,
        dispatch: React.Dispatch<GlobalAction>,
        initialParameters?: InitialParameters
    ): undefined | DashboardLoaded {
        if (dashboard.kind === 'err') {
            this.errorMessage = (
                <>
                    <strong>{APP_STRINGS.error.failedToLoadDashboard}</strong> {formatMigrationError(dashboard.err)}
                </>
            );
            return;
        }

        // Let the user know about warnings, but don't block and still load the dashboards.
        if (dashboard.value.warnings.length !== 0) {
            rtdPrompt(dispatch, APP_STRINGS.migration.warnings.onLoadWithWarningsTitle, {
                children: <WarningsList warnings={dashboard.value.warnings} />,
                hideCancel: true,
                acceptText: APP_STRINGS.utilButtons.ok,
                dialogProps: { maxWidth: APP_CONSTANTS.ux.dialogWithTextWidth },
            });
        }
        return this.loadDashboard(dashboard.value.data, dashboard.value.warnings, initialParameters);
    }

    /**
     * Does not initialize selected parameters
     */
    loadDashboard(
        document: IDashboardDocument,
        migrationWarnings?: string[],
        initialParameters?: InitialParameters
    ): DashboardLoaded {
        this.errorMessage = undefined;

        if (this.state) {
            this.state.document = document;
            this.state.migrationWarnings = migrationWarnings = [];
            this.state.changes = undefined;
        } else {
            this.state = new DashboardLoaded(this.visualConfig, document, migrationWarnings ?? [], initialParameters);
        }

        return this.state;
    }

    setErrorMessage(errorMessage: string) {
        this.state?.dispose();
        this.state = undefined;
        this.errorMessage = errorMessage;
    }

    get isEditing() {
        return this.state?.changes !== undefined;
    }

    /**
     * Can be expensive, so don't call it in a render path.
     */
    changed(): boolean {
        if (this.state === undefined || this.state.changes === undefined) {
            return false;
        }

        return !lodash.isEqual(dashboardStoreToDocument(this.state), this.state.document);
    }
}
