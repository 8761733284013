import * as React from 'react';
import { useCallback } from 'react';
import { IDropdownOption } from 'office-ui-fabric-react';

import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { DashboardMember, DashboardPermission } from '../../../core/domain';
import { useCore } from '../../../core';
import { useCurrent } from '../../../common';
import { RTDDropdown } from '../../fabric/dropdown';

import { PermissionsFormDispatch } from './permissionsFormReducer';
import { formatPermissionError } from './lib';

interface IPermissionTypePickerProps {
    className?: string;
    memberItem: DashboardMember;
    dashboardId: string;
    dispatch: PermissionsFormDispatch;
    isMounted: React.MutableRefObject<boolean>;
}

export const PermissionTypePicker: React.FunctionComponent<IPermissionTypePickerProps> = ({
    className,
    memberItem,
    dashboardId,
    dispatch,
    isMounted,
}) => {
    const { membershipService } = useCore();

    const currentMemberItem = useCurrent(memberItem);

    const handlePermissionChange = useCallback(
        async (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (!option || option.key === memberItem.permission) {
                return;
            }

            const updatedMember = {
                ...currentMemberItem.current,
                permission: option.key as DashboardPermission,
            };

            const promise = membershipService.updateMembership(dashboardId, memberItem.id, updatedMember);
            dispatch({ type: 'addAsyncOperation', operation: promise });

            try {
                await promise;
                if (!isMounted.current) {
                    return;
                }

                dispatch({
                    type: 'editMember',
                    member: updatedMember,
                });
            } catch (e) {
                if (!isMounted.current) {
                    return;
                }

                dispatch({
                    type: 'setListError',
                    errorMessage: formatPermissionError(e.message),
                });
            } finally {
                if (!isMounted.current) {
                    return;
                }

                dispatch({ type: 'removeAsyncOperation', operation: promise });
            }
        },
        [currentMemberItem, memberItem, dashboardId, isMounted, membershipService, dispatch]
    );

    return (
        <RTDDropdown
            className={className}
            selectedKey={memberItem.permission}
            options={APP_CONSTANTS.permissionsOptions}
            onChange={handlePermissionChange}
            title={APP_STRINGS.forms.permissions.permissionTypeTitleText}
        />
    );
};
