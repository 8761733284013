import { AuthenticationProvider, decodeJwtToken } from '../OpenSource/AuthenticationProviders';
import { User, Profile } from '@kusto/common';
import { IFrameMessage } from './iFrameMessage';
import { getTelemetryClient } from './telemetryClient';
import { postGetToken } from './IFrameCommunication';

const { trackTrace } = getTelemetryClient({
    component: 'IFrameAuthenticationProvider',
    flow: '',
});

/**
 * Provide authentication tokens through posting messages to iframe parent.
 */
export class IFrameAuthenticationProvider implements AuthenticationProvider {
    // Call this when we get a token back for the 1st time
    private resolveFirstToken?: (value: string | PromiseLike<string>) => void;

    // stores the latest version of an access token for kusto.
    private currentToken: string | undefined = undefined;
    private currentProfile: Profile | undefined = undefined;

    // a promise representing the wait for getting the token for the 1st time.
    // further getToken calls will return immediately with the latest version of the token we have)
    private firstTokenPromise = new Promise<string>((resolve) => {
        this.resolveFirstToken = resolve;
    });

    constructor() {
        postGetToken();
        window.addEventListener('message', (event) => this.handleIncomingMessage(event), false);
    }

    refreshToken() {
        trackTrace('Refreshing Token');
        this.currentToken = undefined;
        this.resolveFirstToken = undefined;
        this.firstTokenPromise = new Promise<string>((resolve) => {
            this.resolveFirstToken = resolve;
        });
        postGetToken();
        return true;
    }

    getToken(): Promise<string> {
        if (this.currentToken) {
            return Promise.resolve(this.currentToken);
        }

        return this.firstTokenPromise;
    }

    logOut(): void {
        throw new Error('Method logOut not implemented');
    }

    setAuthContextExtraQueryParameter(): void {
        throw new Error('Method setAuthContextExtraQueryParameter not implemented');
    }

    clearCache(): void {
        throw new Error('Method clearCache not implemented');
    }

    login(): void {
        throw new Error('Method login not implemented');
    }

    loginInProgress(): boolean {
        throw new Error('Method loginInProgress not implemented');
    }

    getUser(): Promise<User> {
        if (this.currentToken) {
            return Promise.resolve(this.getCachedUser());
        }

        return this.firstTokenPromise.then(() => this.getCachedUser());
    }

    getCachedUser(): User {
        const user: User = {
            profile: this.currentProfile,
            userName: this.currentProfile && this.currentProfile.upn ? this.currentProfile.upn : 'unknown',
        };

        return user;
    }

    getUserName(): Promise<string> {
        if (this.currentToken) {
            return Promise.resolve(
                this.currentProfile && this.currentProfile.upn ? this.currentProfile.upn : 'unknown'
            );
        }

        return this.firstTokenPromise.then(() =>
            this.currentProfile && this.currentProfile.upn ? this.currentProfile.upn : 'unknown'
        );
    }

    authenticateAndRun(): void {
        throw new Error('Method authenticateAndRun not implemented.');
    }

    async setTenant() {
        throw new Error('Method setTenant not implemented.');
    }
    async switchTenant() {
        throw new Error('Method switchTenant not implemented.');
    }

    getTenant(): string {
        throw new Error('Method getTenant not implemented.');
    }

    getIsMSAAccount(): boolean {
        // Ibiza can never be a MSA
        return false;
    }

    getLoginError(): string | undefined {
        throw new Error('Method getLoginError not implemented.');
    }

    private handleIncomingMessage(event: MessageEvent) {
        const eventData: IFrameMessage = event.data;
        switch (eventData.type) {
            case 'postToken':
                let token = eventData.message as string;

                // if we got the Bearer part, strip it out. We just want the token.
                if (token.startsWith('Bearer ')) {
                    token = token.substring('Bearer '.length);
                }

                // If this is the first time we got a token, resolve the promise (people may be waiting on it)
                if (!this.currentToken && this.resolveFirstToken) {
                    this.resolveFirstToken(token);
                }

                // Update our token to the newest posted token.
                this.currentToken = token;
                this.currentProfile = decodeJwtToken(token);

                break;
            default:
                break;
        }
    }
}
