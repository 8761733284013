import * as React from 'react';
import { useCallback } from 'react';
import type { DebouncedFunc } from 'lodash';
import { IDropdownOption, Toggle, TextField } from 'office-ui-fabric-react';
import debounce from 'lodash/debounce';

import { RTDDropdown, RTDDropdownOption } from '../../../../../components';
import { VisualOptionKey } from '../../../../../domain';
import { APP_CONSTANTS } from '../../../../../res';

import { useETPDispatch, useETPSelector } from '../../../../../store/editTile';
import { useRegisterDebounce, visualOptionsSelectorBuilder } from '../../../lib';
import { ETPManagedConfigKeyForType, ETPVisualOptions } from '../../../types';

import { ManagedConfigComponent } from '../types';

import styles from './styles.module.scss';

export const optionLabelStyle = { label: { fontWeight: 'normal' as const } };

export const createBoolToggle = <T extends ETPManagedConfigKeyForType<boolean>>(
    key: T,
    label: string,
    { invert }: { invert?: boolean } = {}
) => {
    const [selectValue, selectNewValue]: [
        (options: ETPVisualOptions) => boolean,
        (checked: boolean) => Partial<ETPVisualOptions>
    ] = invert ? [(o) => !o[key], (o) => ({ [key]: !o })] : [(o) => o[key], (o) => ({ [key]: o })];

    return createToggle(label, selectValue, selectNewValue);
};

/**
 * @param key Key of the visual option this dropdown is for
 * @param label Label applied to toggle
 * @param selectValue Selector for the checked state
 * @param selectNewValue Returns a new value from the checked state
 */
export const createToggle = (
    label: string,
    selectValue: (options: ETPVisualOptions) => boolean,
    selectNewValue: (checked: boolean) => Partial<ETPVisualOptions>
): ManagedConfigComponent => {
    const checkedSelector = visualOptionsSelectorBuilder(selectValue);

    return ({ disabled }) => {
        const [dispatch] = useETPDispatch();
        const checked = useETPSelector(checkedSelector);
        const onChange = useCallback(
            (_: unknown, innerChecked?: boolean) =>
                dispatch({
                    type: 'updateVisualOptions',
                    options: selectNewValue(!!innerChecked),
                }),
            [dispatch]
        );

        return (
            <Toggle
                className={styles.basicInput}
                checked={checked}
                onChange={onChange}
                label={label}
                disabled={disabled}
            />
        );
    };
};

export interface ETPStaticDropdownOption<Key extends string = string> extends RTDDropdownOption {
    key: Key;
}

/**
 *
 * @param key Key of the visual option this dropdown is for
 * @param label Label applied to the dropdown
 * @param dropdownOptions Passed directly to RTDDropdown
 * @param selectKey Selector for the selected option
 * @param selectNewValue Selector for choosing a new visual option value from a
 *   dropdown option
 */
export const createStaticDropdown = <T extends VisualOptionKey, K extends string>(
    key: T,
    label: string,
    dropdownOptions: Array<ETPStaticDropdownOption<K>>,
    selectKey: (options: ETPVisualOptions[T]) => K,
    selectNewValue: (option: ETPStaticDropdownOption<K>) => ETPVisualOptions[T]
): ManagedConfigComponent => {
    const selectedKeySelector = visualOptionsSelectorBuilder((s) => selectKey(s[key]));

    return ({ disabled }) => {
        const [dispatch] = useETPDispatch();
        const selectedKey = useETPSelector(selectedKeySelector);
        const onChange = useCallback(
            (_: unknown, option?: IDropdownOption) =>
                dispatch({
                    type: 'updateVisualOptions',
                    options: {
                        [key]: selectNewValue(option as ETPStaticDropdownOption<K>),
                    },
                }),
            [dispatch]
        );

        return (
            <RTDDropdown
                className={styles.basicInput}
                selectedKey={selectedKey}
                options={dropdownOptions}
                onChange={onChange}
                label={label}
                disabled={disabled}
            />
        );
    };
};

export const createTextInput = (key: ETPManagedConfigKeyForType<string>, label: string): ManagedConfigComponent => {
    const textSelector = visualOptionsSelectorBuilder((o) => o[key]);

    return ({ disabled }) => {
        const [dispatch] = useETPDispatch();
        // Local values shadow global values, and are cleared when debounce resolves
        const [localValue, setLocalValue] = React.useState<undefined | string>(undefined);
        const globalValue = useETPSelector(textSelector);

        const onChange = React.useMemo(() => {
            const setGlobalValue = debounce((newValue: string) => {
                // Clear local state. Until this is done this input won't respond to
                // changes to the global value.
                setLocalValue(undefined);
                dispatch({
                    type: 'updateVisualOptions',
                    options: { [key]: newValue },
                });
            }, APP_CONSTANTS.editTilePage.visualOptionsTextDebounce);

            const innerOnChange = ((_: unknown, newValue?: string) => {
                if (newValue !== undefined) {
                    setLocalValue(newValue);
                    setGlobalValue(newValue);
                }
            }) as DebouncedFunc<(_: unknown, newValue?: string) => void>;
            innerOnChange.flush = setGlobalValue.flush;
            innerOnChange.cancel = setGlobalValue.cancel;
            return innerOnChange;
        }, [dispatch]);

        useRegisterDebounce(onChange);

        return (
            <TextField
                className={styles.basicInput}
                value={localValue ?? globalValue}
                onChange={onChange}
                label={label}
                disabled={disabled}
            />
        );
    };
};
