import * as React from 'react';
import { IContextualMenuItem } from 'office-ui-fabric-react';

import { APP_CONSTANTS } from '../../res';
import type { QueryHash, VisualOptions, VisualDisplayedResultsCounts, TileHtmlErrorId } from '../../domain';
import { WhiteShadowContent } from '../lib';
import { DashboardLoaded } from '../../store';

import { TileHeader } from './header/Header';

import styles from './Tile.module.scss';

export interface TileProps {
    pageState: DashboardLoaded | undefined;
    /**
     * Needs to be unique to each tile instance
     */
    tileId: string;
    title: string;
    visualOptions?: VisualOptions;
    menuItems?: IContextualMenuItem[];
    editing?: boolean;
    queryHash?: QueryHash | undefined;
    onRefreshClick?: () => void;
    visualType?: string;
    resultCounts?: VisualDisplayedResultsCounts;
    htmlErrorId?: TileHtmlErrorId;
    className?: string;
    style?: React.CSSProperties;
}

export const Tile: React.FC<TileProps> = ({
    pageState,
    tileId,
    title,
    menuItems,
    editing,
    queryHash,
    onRefreshClick,
    visualType,
    visualOptions = APP_CONSTANTS.visuals.defaultVisualOptions,
    resultCounts,
    children,
    htmlErrorId,
    className,
    style,
}) => {
    return (
        // Has the role "region" so that tiles become landmarks with announce-able
        // titles
        <WhiteShadowContent
            role="region"
            aria-label={title}
            className={className ? `${styles.wrapper} ${className}` : styles.wrapper}
            style={style}
            data-automation-id="tile"
            aria-describedby={htmlErrorId}
        >
            <TileHeader
                pageState={pageState}
                tileId={tileId}
                title={title}
                visualType={visualType}
                visualOptions={visualOptions}
                onRefreshClick={onRefreshClick}
                menuItems={menuItems}
                queryHash={queryHash}
                editing={editing}
                resultCounts={resultCounts}
            />
            <div className={styles.content} data-automation-id="tile-content">
                {children}
            </div>
        </WhiteShadowContent>
    );
};
