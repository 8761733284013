import { autorun } from 'mobx';
import React from 'react';
import * as H from 'history';

import { DashboardStore, DashboardLoaded } from '../../store/dashboard';

function storeToUrlSearch(state: DashboardLoaded): string {
    const searchParams = new URLSearchParams();

    for (const selection of Object.values(state.pinnedParameters).map(
        (p) => state.selectedParameters.selections[p.id]
    )) {
        if (selection === undefined) {
            continue;
        }
        const serialized = selection.toUrlStrings();
        for (const [key, values] of Object.entries(serialized)) {
            for (const value of values) {
                searchParams.append(key, value);
            }
        }
    }

    return searchParams.toString();
}

/**
 * Updates the url parameters with the current selections
 */
export function useUrlParametersSync(store: DashboardStore, history: H.History) {
    React.useEffect(() => {
        return autorun(
            () => {
                if (store.state) {
                    history.replace({
                        ...history.location,
                        search: storeToUrlSearch(store.state),
                    });
                }
            },
            { name: 'updateUrlParams' }
        );
    }, [history, store]);
}
