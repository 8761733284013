import * as React from 'react';
import { IMessageBarProps, MessageBar } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../res';

import styles from './DashboardPermissions.module.scss';

interface MessageBannerProps extends IMessageBarProps {
    message: string;
}

const bannerStyle = {
    iconContainer: {
        lineHeight: '32px',
    },
    dismissal: {
        margin: '8px 0px',
    },
    innerText: {
        width: '100%',
    },
};

export const MessageBanner: React.FC<MessageBannerProps> = ({ message, ...props }) => {
    return (
        <MessageBar
            {...props}
            styles={bannerStyle}
            isMultiline={!props.actions}
            dismissButtonAriaLabel={APP_STRINGS.editParameterPage.aria.closeMessageBarLabel}
        >
            <div className={styles.bannerText}>{message}</div>
        </MessageBar>
    );
};
