import { Table, EntityType } from '../../../common/types';
import { RowDataTypeBase } from './RowDataTypeBase';
import { iconMapper } from './IconMapper';
import { getActions } from './ActionMapper';
import { Database } from '../../../stores/cluster';
import { getFromCacheOrCreate } from './RowDataTypeCache';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';
import { tablesRootFolderName } from './DatabaseFolderRowDataType';

export class TableRowDataType extends RowDataTypeBase {
    private constructor(table: Table, database: Database) {
        super(
            table.id,
            TableRowDataType.buildTableFolderPath(database, table),
            table.entityType,
            database,
            iconMapper[table.entityType]
        );

        this.info = table.docstring;
        this.actions = getActions(this.entityType, false, this.info);
    }

    public static buildTableFolderPath(database: Database, table: Table): string[] {
        const pathFromRoot: string[] = [];
        if (table.folder) {
            const folderPath = table.folder ? table.folder.split(/[\\/]/g) : [];
            pathFromRoot.push(
                database.cluster.getAlias(),
                database.name,
                tablesRootFolderName(table.entityType),
                ...folderPath,
                table.name
            );
        } else if (table.entityType !== EntityType.Table) {
            // External Tables or Materialized Views that has no folder will be grouped
            // under the root folders External Tables and Materialized Views respectively.
            pathFromRoot.push(
                database.cluster.getAlias(),
                database.name,
                tablesRootFolderName(table.entityType),
                table.name
            );
        } else {
            // Regular tables without a folder will be shown under the database.
            pathFromRoot.push(database.cluster.getAlias(), database.name, table.name);
        }

        return pathFromRoot;
    }

    public static fromCacheOrCreate(table: Table, database: Database): RowDataType {
        return getFromCacheOrCreate(database, table.id, () => new TableRowDataType(table, database));
    }
}
