import { KustoDomains, User } from '@kusto/common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Account, AuthenticationParameters, Configuration, Logger, LogLevel, UserAgentApplication } from 'msal';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { v4 } from 'uuid';
import { getTelemetryClient } from '../../utils/telemetryClient';
import { decodeJwtToken, resourceToScopes } from './authenticationUtils';
import { AuthenticationProvider } from './AuthenticationProvider';
import { basePathName } from '../../utils/url';
import { parseAuthState, stringifyAuthState } from './AuthState';

const { trackException, trackTrace } = getTelemetryClient({
    component: 'MsalAuthenticationProvider',
    flow: '',
});

const MsalMultiTenant = 'organizations';

const levelToSeverity = (level: LogLevel): SeverityLevel => {
    switch (level) {
        case LogLevel.Error:
            return SeverityLevel.Error;
        case LogLevel.Info:
            return SeverityLevel.Information;
        case LogLevel.Verbose:
            return SeverityLevel.Verbose;
        case LogLevel.Warning:
            return SeverityLevel.Warning;
    }
};

export class MsalAuthenticationProvider implements AuthenticationProvider {
    private readonly application: UserAgentApplication;
    private readonly defaultScopes: string[];
    private extraQueryParameters: StringDict | undefined = undefined;
    private tenant: string;
    private forceRefresh = false;

    // In any case of issue accessing resource by external tenant (non-microsoft) try replacing the
    // resource uri with app id ...
    constructor(private redirectUri: string, private clientId: string, resource?: string) {
        const defaultResource = resource ?? 'https://help' + KustoDomains.Default;
        this.defaultScopes = resourceToScopes(defaultResource);
        this.tenant = MsalMultiTenant;
        const config: Configuration = {
            auth: {
                clientId: this.clientId,
                redirectUri: this.redirectUri,
                authority: `${process.env.REACT_APP_AAD_ENDPOINT}${this.tenant}`,
            },
            cache: {
                cacheLocation: 'sessionStorage',
                // Fix for auth loop issues https://aka.ms/known-issues-on-Microsoft-Browsers-due-to-security-zones
                storeAuthStateInCookie: true,
            },
            system: {
                logger: new Logger(
                    (level, message) => {
                        trackTrace(message, levelToSeverity(level), { flow: 'msal' });
                    },
                    { level: LogLevel.Info, piiLoggingEnabled: false }
                ),
                // Allow more time before a token renewal response from Azure AD should be considered timed out
                loadFrameTimeout: 30000,
            },
        };
        this.application = new UserAgentApplication(config);
    }

    logOut(): void {
        this.application.logout();
    }

    login() {
        /**
         * Removes login hint before redirecting in certain cases.
         * @param url the url as provided by MSAL library
         */
        const onRedirect = (urlString: string) => {
            const url = new URL(urlString);
            const searchParams = new URLSearchParams(url.search);
            const prompt = searchParams.get('prompt');
            const loginHint = searchParams.get('login_hint');
            // MSAL always adds a login_hint, so prompt=login will always redirect to currently logged in tenant.
            // Asked following question on github https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1865
            if (prompt === 'login' && loginHint) {
                trackTrace('removing login hint', SeverityLevel.Information, { flow: 'msal' });
                searchParams.delete('login_hint');
                url.search = searchParams.toString();
                window.location.href = url.href;
                return false;
            }

            return true;
        };

        const authParams = this.getAuthenticationParameters(true);

        this.application.loginRedirect({
            ...authParams,
            scopes: this.defaultScopes,
            onRedirectNavigate: onRedirect,
        });
    }

    loginInProgress() {
        return this.application.getLoginInProgress();
    }

    setAuthContextExtraQueryParameter(value?: string) {
        if (value) {
            const [key, val] = value.split('=');
            this.extraQueryParameters = { [key]: val };
        } else {
            this.extraQueryParameters = undefined;
        }
    }

    clearCache() {
        // Clearing the cache causes a lot of issues (null exceptions in msal and whatnot) and doesn't seem
        // to have any benefits.for now we won't clear the cache but we will enable for refresh so that the cache is ignored
        // on th next time getToken is called.
        // this was the original code here:
        // if (!(this.application as any).clearCache) {
        //     trackException(new Error('clearCache does not exist'));
        // }
        // (this.application as any).clearCache();

        this.forceRefresh = true;
    }

    private getAuthenticationParameters(isLogin = false) {
        const authParameters: AuthenticationParameters = {
            extraQueryParameters: this.extraQueryParameters,
            authority: isLogin
                ? `${process.env.REACT_APP_AAD_ENDPOINT}${MsalMultiTenant}`
                : this.getAuthorityFromTenant(this.tenant),
            loginHint: this.extraQueryParameters?.login_hint,
            sid: this.extraQueryParameters?.sid,
            forceRefresh: this.forceRefresh,
            state: stringifyAuthState({ basePathName }), // see AuthState.basePathName for more information.
        };

        return authParameters;
    }
    private async msalGetToken(scopes: string[]): Promise<string> {
        const authParams = { ...this.getAuthenticationParameters(), scopes };

        try {
            const response = await this.application.acquireTokenSilent(authParams);
            return response.accessToken;
        } catch (error) {
            if (
                // hopefully the following AAD  codes are covered by the verbal error code:
                // AADSTS50076 AADSTS50079 AADSTS16002 AADSTS50133
                // Since per documentation these should never be used to react to an error in the code (https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes)
                error.errorCode?.includes('consent_required') ||
                error.errorCode?.includes('interaction_required') ||
                error.errorCode?.includes('login_required') ||
                error.errorCode?.includes('token_renewal_error')
            ) {
                this.application.acquireTokenRedirect(authParams);
            } else {
                throw error;
            }
        }

        // won't matter since we're redirecting anyway.
        return '';
    }

    // Adding scopes for future transition to MSAL
    async getToken(scopes?: string[], tokenExpired?: (message: string) => void): Promise<string> {
        if (!scopes) {
            // Request the Kusto access token if no scopes are provided
            scopes = this.defaultScopes;
        }
        if (!scopes.length) {
            throw new Error('No scopes provided for the access token request.');
        }

        try {
            const token = await this.msalGetToken(scopes);

            // TODO: consider doing this only when the query fails.
            // When multiple kustoweb tabs are open there can be a race condition where the token expiry adal stores
            // in its cache is wrong (i.e doesn't represent the real exp date in the token).
            // specifically - token is no longer valid (expired), but the expiry date that adal.js stores is a valid date.
            // thus adal js decides to return the token to us, but it's damaged and will never work and the service will
            // reject it.
            // Thus, we validate it here before returning to client.
            const decodedToken = token ? decodeJwtToken(token) : undefined;

            if (
                decodedToken &&
                decodedToken.exp &&
                decodedToken.exp < Math.round(new Date().getTime() / 1000.0) + 300
            ) {
                if (tokenExpired) {
                    tokenExpired(`exp time: ${decodedToken.exp}, current: ${new Date().getTime()}`);
                }
                this.clearCacheForAccessTokens();
                return this.msalGetToken(scopes);
            }

            return token;
        } finally {
            this.forceRefresh = false;
        }
    }

    refreshToken() {
        return false;
    }

    getCachedUser(): User {
        const account = this.application.getAccount();
        return this.accountToUser(account);
    }

    getUser(): Promise<User> {
        const user = this.getCachedUser();
        return Promise.resolve(user);
    }
    async getUserName(): Promise<string> {
        const user = await this.getUser();
        return user.userName;
    }

    async authenticateAndRun(app: () => void) {
        const contextID = v4();
        const trace = (message: string, severity: SeverityLevel = SeverityLevel.Information) =>
            trackTrace(message, severity, { flow: 'authenticateAndRun', contextID });

        trace('authenticateAndRun: start');
        this.application.handleRedirectCallback((_error, _response) => {
            trace(`authenticateAndRun: account state is ${_response?.accountState}`);
            // If basePathName was set, navigate to it. (see comment on AuthState.basePathName)
            if (_response?.accountState) {
                const authState = parseAuthState(_response.accountState);
                if (authState.basePathName && authState.basePathName !== basePathName) {
                    const redirectTo = `${window.location.protocol}//${window.location.host}${authState.basePathName}`;
                    trace(`authenticateAndRun: basePathName exist in state. Redirecting to '${redirectTo}'`);
                    window.location.replace(redirectTo);
                }
            }
        });

        // If this is the iframe - we don't want to load the app.
        if (window.parent !== window) {
            trace('authenticateAndRun: return because running in an iframe');
            return;
        }

        trace('authenticateAndRun: calling getAccount');
        let account: Account | null = await this.getAccount();
        if (!account) {
            trace('authenticateAndRun: account or claims are falsy. calling loginRedirect');
            let hardLogin = false;
            try {
                // loginRedirect is not guaranteed to redirect. (for example - a token already exists on ADAL cache location. since loginRedirect knows how to pull it from ADAL cache, this will return without redirecting.)
                // loginRedirect may return before redirect happens. (according to our tests. In newer versions of MSAL this can be re-validated.)
                this.application.loginRedirect({ state: stringifyAuthState({ basePathName }) }); // see AuthState.basePathName for more information.
                trace('authenticateAndRun: loginRedirect returned.');

                account = await this.getAccount(1); // the retry will give enough time to loginRedirect to actually redirect.
                if (account) {
                    trace('authenticateAndRun: account found after loginRedirect.');
                } else {
                    trace('authenticateAndRun: account did not found after loginRedirect. Trying force refresh.');
                    hardLogin = true; // if logic redirect failed, try a force redirect.
                }
            } catch (e) {
                trace('authenticateAndRun: loginRedirect or getAccount throws.');
                trackException(e, 'authenticateAndRun', { contextID });
                hardLogin = true; // if logic redirect failed, try a force redirect.
            }

            if (hardLogin) {
                trace('authenticateAndRun: force refresh.');
                // Don't replace the next line with login() and forceRefresh=true,
                // Explanation: If for some reason we still don't have token claims, go get another token from server.
                // we're specifying client id as scope here because otherwise MSAL will still try to get old ADAL id tokens which
                // results in a null account for some reason.
                this.application.loginRedirect({
                    forceRefresh: true,
                    scopes: resourceToScopes(this.clientId),
                    state: stringifyAuthState({ basePathName }), // see AuthState.basePathName for more information.
                });
                trace('authenticateAndRun: login with forceRefresh=true returned without redirecting.');
            }

            // In some cases the account is not available immediately (needs to be re-validated with future versions of MSAL).
            // Try to get account with retries.
            // this will also give time to this.login() with forceRefresh=true to perform the redirect.
            account = await this.getAccount(4);
            if (!account) {
                trace('authenticateAndRun: account not found. Logging out...');
                // TODO: Added in July 17th. In few weeks, check if this trace ever happens, since we are doing force refresh it should never happen,
                // if it does we should show an error page, currently users will see a white screen.
                return;
            }
        }

        app();
    }

    private async getAccount(retries = 0): Promise<Account | null> {
        const account = this.application.getAccount();
        if (account && account.idTokenClaims) {
            return account;
        }
        const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

        let retriesCount = retries;
        // TODO: this is a bug. retries should be retriesCount. However, fixing it to retriesCount causes another issue:
        // See https://dev.azure.com/msazure/One/_workitems/edit/10724985 for more info.
        while (retries > 0) {
            await sleep(500);
            const account = this.application.getAccount();
            if (account && account.idTokenClaims) {
                return account;
            }
            retriesCount = retriesCount - 1;
        }

        return null;
    }

    private getAuthorityFromTenant(tenant: string) {
        if (!tenant || tenant === MsalMultiTenant) {
            // until the following msal bug is fixed https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1761 and our package version dependency is incremented accordingly
            // we will use the tenant from id token otherwise we get constant cache miss.
            // Originally this code path returned an undefined authority but turns out that MSAL doesn't know what to do when there are multiple access tokens in its cache and the authority is undefined.
            const account: Account = this.application.getAccount();
            tenant = account?.idTokenClaims?.tid;

            // If, for some reason we cannot get the tenant from id token, we fall back to returning undefined (which will be common endpoint if there aren't multiple access tokens in cache)
            if (!tenant) {
                return undefined;
            }
        }
        return `${process.env.REACT_APP_AAD_ENDPOINT}${tenant}`;
    }

    private clearCacheForAccessTokens() {
        // MSAL team did not add typings for this method. that's why the cast to any.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(this.application as any).cacheStorage?.getAllAccessTokens) {
            trackException(new Error('either cacheStorage does not exist of getAllAccessTokens does not exist in it '));
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(this.application as any).clearCacheForScope) {
            trackException(new Error('clearCacheForScope does not exist'));
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tokens: any[] = (this.application as any).cacheStorage.getAllAccessTokens();
        for (const token of tokens) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.application as any).clearCacheForScope(token.value.accessToken);
        }
    }

    async setTenant(tenant: string) {
        const prevTenant = this.tenant;
        // MSAL cannot change configuration once the application object is created.
        // it _does_ allow to provide overrides to authority on login and getToken calls though,
        // this  is what adal was doing:  this.authContext.config.tenant = tenant;
        // we're going to change the tenant in a property and reference it when sking for a login or a token.
        this.tenant = tenant;

        this.clearCacheForAccessTokens();

        // preloading of kusto token
        try {
            await this.msalGetToken(this.defaultScopes);
        } catch (ex) {
            this.tenant = prevTenant;
            return Promise.reject(ex);
        }
    }

    async switchTenant(tenant: string) {
        const currentTenant = this.getTenant();
        if (currentTenant === tenant) {
            return;
        }
        await this.setTenant(tenant);
    }

    getTenant(): string {
        // actual tenant is configured on application
        const tid = this.tenant;
        if (tid && tid !== MsalMultiTenant) {
            return tid;
        }

        // in case configured tenant is the default, take tid from actual user data
        const account = this.application.getAccount();
        const user = this.accountToUser(account);
        const profile = user?.profile;
        if (!profile?.tid) {
            throw new Error(`getTenant: could not get tenant id from account`);
        }
        return profile?.tid;
    }

    /**
     * Checks whether the account is a consumer facing account (MSA)
     */
    getIsMSAAccount(): boolean {
        const tenant = this.getTenant();

        return (
            // MSA common
            tenant === '9188040d-6c67-4c5b-b112-36a304b66dad' ||
            // First party app global tenant
            tenant === 'f8cdef31-a31e-4b4a-93e4-5f571e91255a'
        );
    }

    getLoginError() {
        // TODO: looks like msal does not support getRequestInfo. returning false..
        return undefined;
    }

    private accountToUser(account: Account): User {
        const { tid, upn, oid, name, family_name, given_name } = account.idTokenClaims;
        const user: User = {
            userName: account.name,
            profile: {
                tid,
                upn: upn ?? account.userName, // email - ADAL: account.idTokenClaims, MSAL: account.userName
                oid,
                name,
                family_name, // not supported in MSAL
                given_name, // not supported in MSAL
            },
        };
        return user;
    }
}
