import type { IUserSessionCache } from '../types';

import { IndexedDBMemCache } from './IndexedDBMemCache';
import { DashboardsIndexedDB, RecentEntry, RecentEntryResult } from './types';

/**
 * A cache of user session information
 */
export class PersistentUserSessionCache implements IUserSessionCache {
    private readonly db: IndexedDBMemCache<'UserSessionCacheParameterRecents'>;

    constructor(db: DashboardsIndexedDB) {
        this.db = new IndexedDBMemCache('UserSessionCacheParameterRecents', db);
    }

    async getParameterRecents(key: string, type: string): Promise<RecentEntry> {
        const result = await this.db.get(key);

        // Type provides a mechanism for clearing recents when the expected type changes
        if (!result || result.type !== type) {
            return { type, recents: [] };
        }

        return result;
    }

    saveParameterRecents(key: string, type: string, recents: RecentEntryResult): void {
        this.db.set(key, { type, recents });
    }
}
