import isEqual from 'lodash/isEqual';
import { assertNever } from '@uifabric/utilities';

import { APP_CONSTANTS } from '../../../../res';

import { IParameterValue, Parameter } from '../../models';
import { RtdValue } from '../../../value';

import type { BasicParamConfig } from './config';

function sortPrimitiveValue<K, T>(selectedValues: RtdValue.Value<K, T>): RtdValue.Value<K, T> {
    return selectedValues.kind === 'array'
        ? {
              ...selectedValues,
              values: [...selectedValues.values].sort((a, b) => String(a).localeCompare(String(b))),
          }
        : selectedValues;
}

export class BasicParamValue<T, K extends string> implements IParameterValue {
    readonly data: undefined | RtdValue.Value<T, K>;
    readonly kind = 'basic' as const;
    readonly dataType: K;

    constructor(readonly config: BasicParamConfig<T, K>, value: undefined | RtdValue.Value<T, K>) {
        this.data = value && sortPrimitiveValue(value);
        this.dataType = config.impl.dataType;

        this.toUrlStrings = this.toUrlStrings.bind(this);
    }

    equals(value: IParameterValue): boolean {
        if (!(value instanceof BasicParamValue)) {
            return false;
        }
        return isEqual(this.data, value);
    }

    toUrlStrings(): Parameter.UrlSerialized {
        if (!this.data) {
            return {};
        }

        let values: string[];

        switch (this.data.kind) {
            case 'null':
                values = [APP_CONSTANTS.parameter.allSelection];
                break;
            case 'array':
                values = this.data.values.map(this.config.impl.valueToUrlString);
                break;
            case 'scalar':
                values = [this.config.impl.valueToUrlString(this.data.value)];
                break;
            default:
                assertNever(this.data);
        }

        return { [this.config.options.variableName]: values };
    }
}
