import { registerIcons } from 'office-ui-fabric-react';

import icons from './generated/icons.json';

// Required for registerIcons
import './generated/icons.css';

/**
 * Import this file to register the custom icon font used by RTD
 */

// **IMPORTANT:** All SVGs must have either equal width/height to the viewbox,
// or only viewbox specified
registerIcons({
    fontFace: {
        fontFamily: `"icons"`,
    },
    icons: Object.fromEntries(
        Object.entries(icons).map(([key, icon]) => [
            `dashboards-${key}`,
            String.fromCharCode(parseInt(icon.slice(1), 16)),
        ])
    ),
});
