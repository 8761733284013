import * as React from 'react';
import { useRef } from 'react';

import { useThunkReducer } from '../../common';
import { GlobalStateContext, DispatchContextValue, DispatchContext } from './context';
import { IReduxState, GlobalAction, IPartialState } from './types';
import { reducer } from './reducer';

const init = (initialState: IReduxState) => {
    return reducer(
        // initialState will almost always be undefined and produced instead by this call
        initialState,
        {
            type: 'init',
        }
    );
};

export interface ReduxContextProviderProps {
    initialState?: IPartialState;
}

/**
 * Must remain outside of the context so code that depends on the context does
 * not depend on the store reducer
 */
export const ReduxContextProvider: React.FC<ReduxContextProviderProps> = ({ initialState, children }) => {
    // initialState may be undefined, but don't let TypeScript know that
    const [state, dispatch, getState] = useThunkReducer<IReduxState, GlobalAction, IReduxState>(
        reducer,
        initialState as IReduxState,
        init
    );

    // Memoize the creation of this array exactly once as a minor optimization
    // This prevents updating the DispatchContext when state updates
    const dispatchArrayRef = useRef<DispatchContextValue>([dispatch, getState]);

    return (
        <GlobalStateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatchArrayRef.current}>{children}</DispatchContext.Provider>
        </GlobalStateContext.Provider>
    );
};
