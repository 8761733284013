import * as React from 'react';

import { ThemeProvider, IThemeProviderProps } from '../domain/theming/ThemeProvider';

import { ReduxContextProvider } from './redux';
import { ETPStateProvider } from './editTile';
import { DashboardStoreProvider } from './dashboard';

export const DashboardsStoreProvider: React.FC<IThemeProviderProps> = ({ children, ...themeProps }) => (
    <ReduxContextProvider>
        <DashboardStoreProvider>
            <ETPStateProvider>
                <ThemeProvider {...themeProps}>{children}</ThemeProvider>
            </ETPStateProvider>
        </DashboardStoreProvider>
    </ReduxContextProvider>
);
