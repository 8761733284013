import { HeaderComp, IHeaderParams } from '@ag-grid-community/core/dist/es6/headerRendering/header/headerComp';
import React from 'react';
import { AccessibilitySelectorFixer } from './AccessibilitySelectorFixer';
import { AccessibleStrings } from './types';

/**
 * Accessible header that supports custom headers.
 */
export class AccessibleHeader extends React.Component<{ strings: AccessibleStrings }> {
    private fixer: AccessibilitySelectorFixer | undefined;
    private headerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.headerRef.current) {
            this.fixer = fixer(() => this.headerRef.current!, this.props.strings);
            this.fixer.fix();
        }
    }

    componentWillUnmount() {
        if (this.fixer) {
            this.fixer.destroy();
        }
    }

    render() {
        return (
            <div className="ag-cell-label-container" ref={this.headerRef}>
                {this.props.children}
            </div>
        );
    }
}

let strings: AccessibleStrings | undefined;
export const getAccessibleHeaderComp = (stringsParam: AccessibleStrings) => {
    strings = stringsParam;
    return AccessibleHeaderComp;
};

/**
 * Accessible header that inherits from HeaderComp.
 */
class AccessibleHeaderComp extends HeaderComp {
    private fixer: AccessibilitySelectorFixer = fixer(
        () => this.getGui(),
        strings!,
        (e: Event) => this.onContextMenu(e)
    );

    init(params: IHeaderParams) {
        super.init(params);
        this.fixer.fix();
    }

    onContextMenu = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        this.showMenu(this.getGui());
    };

    destroy() {
        this.fixer.destroy();
    }
}

export const fixer = (
    getElementCallback: () => HTMLElement,
    strings: AccessibleStrings,
    contextMenuCallback?: (e: Event) => void
) =>
    new AccessibilitySelectorFixer(
        [
            {
                selector: '[role="columnheader"]',
                fixer: (elem: HTMLElement) => {
                    elem.removeAttribute('role');
                },
            },
            {
                selector: '.ag-sort-ascending-icon',
                fixer: (elem: HTMLElement) => {
                    elem.removeAttribute('role');
                    elem.removeAttribute('aria-hidden');
                    elem.setAttribute('aria-label', strings.grid$sortAsc);
                },
            },
            {
                selector: '.ag-sort-descending-icon',
                fixer: (elem: HTMLElement) => {
                    elem.removeAttribute('role');
                    elem.removeAttribute('aria-hidden');
                    elem.setAttribute('aria-label', strings.grid$sortDesc);
                },
            },
            {
                selector: 'me',
                fixer: (elem: HTMLElement) => {
                    elem.setAttribute('tabIndex', '-1');
                    elem.setAttribute('data-is-focusable', 'true');
                    elem.setAttribute('role', 'columnheader');
                    if (contextMenuCallback) {
                        elem.addEventListener('contextmenu', contextMenuCallback);
                    }
                },
                destroyer: (elem: HTMLElement) => {
                    if (contextMenuCallback) {
                        elem.removeEventListener('contextmenu', contextMenuCallback);
                    }
                },
                once: true,
            },
        ],
        getElementCallback
    );
