import { getTelemetryClient } from './telemetryClient';
import { dependencies } from '../dependencies';
import { KustoDomains, extractErrorDescriptionAndTrace } from '@kusto/common';
import { getClusterUrl } from '../stores/cluster';

const { trackEvent } = getTelemetryClient({
    component: 'testConnection',
    flow: '',
});
const kustoClient = dependencies.kustoClient;

/**
 * This method uses kusto client to see if the cluster responds to a simple .show version api call.
 * @param clusterName The cluster Name
 * @param connectionString The connection string
 * @param initialCatalog optional initial catalog
 */
export async function testConnection(
    clusterName: string,
    connectionString: string,
    initialCatalog?: string
): Promise<{
    isError: boolean;
    description?: string;
}> {
    trackEvent('testConnection', { flow: 'testConnection', clusterName });
    connectionString = connectionString || `https://${clusterName}.${KustoDomains.DefaultDomainPostfix};fed=true`;
    const url = getClusterUrl(connectionString);

    try {
        if (!KustoDomains.isAriaDomain(url)) {
            await kustoClient.executeControlCommand(url, initialCatalog, '.show version');
        }
    } catch (e) {
        let description = extractErrorDescriptionAndTrace(e).errorMessage;
        if (description === 'Failed to fetch' || description === 'XHR error' || description === 'Network Error') {
            description = dependencies.strings.badConnection;
        }
        return { description, isError: true };
    }

    return { isError: false };
}
