import { APP_STRINGS } from '../../res';

import type { ColorRule } from '../../domain';

const { expressionArgumentText } = APP_STRINGS.editTilePage.visualConfig.colorRules;

/**
 * > and >= must appear directly before <, and <= in order to be merged
 */
const operatorsOrder: Record<ColorRule.Operator, number> = {
    '>': 0,
    '>=': 1,
    '<': 2,
    '<=': 3,
    '==': 4,
};

export const formatColorRuleConditions = (conditions: ColorRule.Condition[]) => {
    const sorted = [...conditions].sort((a, b) => operatorsOrder[a.operator] - operatorsOrder[b.operator]);

    const results: string[] = [];

    let i = 0;
    while (i < sorted.length) {
        const current = sorted[i];
        const next = sorted[i + 1];

        // Special case situations where both operators can be combined. For example,
        // "Value < 8 && Value > 1" becomes "1 < Value < 8".
        if (
            next &&
            (current.operator === '>' || current.operator === '>=') &&
            (next.operator === '<' || next.operator === '<=')
        ) {
            const currentOperator = current.operator === '>' ? '<' : '<=';
            results.push(
                `${current.value} ${currentOperator} ${expressionArgumentText} ${next.operator} ${next.value}`
            );
            i += 2;
        } else {
            results.push(`${expressionArgumentText} ${current.operator} ${current.value}`);
            i++;
        }
    }

    return results.join(' && ');
};
