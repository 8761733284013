import { v4 } from 'uuid';
import { isBoolean } from 'lodash';

import { PostCons } from '../common';
import { ResourceCache } from './ResourceCache';
import { ResourceErrors } from './errors/ResourceErrors';
import { ResourceMetadata } from './metadata/ResourceMetadata';
import { IResourceState } from './metadata/types';
import { ResourceTypes, FuncType, StringValueFuncType } from './types';
import { ResourceError, buildNetworkResourceError } from './errors/ResourceError';
import { errorsTypeConfig } from './errors/errorsConfig';

export class Resource<TArgs extends unknown[], TReturn> {
    constructor(
        private resourceMetadata: ResourceMetadata,
        private resourceCache: ResourceCache,
        private resourceErrors: ResourceErrors,
        protected func: FuncType<TArgs, TReturn>,
        protected cacheKeyFunc: StringValueFuncType<TArgs> | undefined,
        protected resourceType: ResourceTypes,
        protected useFriendlyError = true
    ) {}

    public read(...allArgs: TArgs): Promise<TReturn> {
        return this.readWithInvalidate(...([...allArgs, undefined] as unknown as PostCons<TArgs, boolean>));
    }

    public readWithInvalidate(...allArgs: PostCons<TArgs, boolean>): Promise<TReturn> {
        const shouldInvalidateValue = allArgs.length > 0 ? allArgs[allArgs.length - 1] : undefined;

        let shouldInvalidate = false;
        let args: TArgs = allArgs as unknown as TArgs;
        if (isBoolean(shouldInvalidateValue)) {
            // Last value is invalidation
            shouldInvalidate = shouldInvalidateValue;
            args = allArgs.slice(0, allArgs.length - 1) as TArgs;
        }

        const cacheKey = this.cacheKeyFunc && this.cacheKeyFunc(...args);

        if (cacheKey) {
            if (shouldInvalidate) {
                this.resourceCache.invalidate(cacheKey);
            } else {
                const cachedValue: Promise<TReturn> | undefined = this.resourceCache.get(cacheKey);

                if (cachedValue) {
                    return cachedValue;
                }
            }
        }

        const metadataKey = cacheKey ? cacheKey : v4();

        const appliedPromise = Promise.resolve()
            .then(() => {
                this.resourceMetadata.update(this.resourceType, metadataKey, 'status', IResourceState.loading);
            })
            // First argument to apply is `this`, which we do not care about. Hence undefined
            .then(() => this.func.apply(undefined, args))
            .then((result) => {
                this.resourceMetadata.update(this.resourceType, metadataKey, 'status', IResourceState.complete);

                return result;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: Error | ResourceError<any>) => {
                if (this.resourceType) {
                    // If network error, add message
                    const networkOrOtherError = buildNetworkResourceError(this.resourceType, error);

                    if (this.useFriendlyError) {
                        const typeConfig = errorsTypeConfig[this.resourceType];

                        this.resourceErrors.push(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            new ResourceError<any>(
                                this.resourceType,
                                {
                                    message: typeConfig.message,
                                    statusCode: 0,
                                },
                                networkOrOtherError
                            )
                        );
                    } else {
                        // Normal error, not friendly
                        this.resourceErrors.push(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            ResourceError.fromError<any>(this.resourceType, networkOrOtherError)
                        );
                    }
                }

                this.resourceMetadata.update(this.resourceType, metadataKey, 'status', IResourceState.error);

                if (cacheKey) {
                    // Clear cache entry
                    this.resourceCache.invalidate(cacheKey);
                }

                throw error;
            });

        if (cacheKey) {
            this.resourceCache.set(cacheKey, appliedPromise);
        }

        return appliedPromise;
    }
}
