import * as React from 'react';
import {
    Modal,
    IModalProps,
    Callout,
    ICalloutProps,
    Panel,
    IPanelProps,
    Layer,
    ILayerProps,
    Dialog,
    IDialogProps,
} from 'office-ui-fabric-react';
import { useGlobalLoading } from '../PageContent/globalLoadingContext';

import rootStyles from '../../pages/root/RootPage.module.scss';
import { useThemeState } from '../../domain/theming';

const hostId = rootStyles.RTDRoot;

/**
 * `position` defaults to absolute, which would cause modals to flow over
 * content outside of RTD
 */
const modalProps = (props?: IModalProps): IModalProps => ({
    ...props,
    styles: { root: { position: 'absolute' } },
    layerProps: { hostId, ...props?.layerProps },
});

export const useGlobalLoadingFromProps = (props: { hidden?: boolean; isOpen?: boolean }) =>
    useGlobalLoading(props.isOpen ?? (props.hidden === undefined ? false : !props.hidden));

/*
 * These components render through portals on the document body by default. The
 * wrapper lets the loading context know that a modal is open, so it will render
 * above the modal.
 */

export const RTDCallout: React.FC<ICalloutProps> = (props) => {
    useGlobalLoadingFromProps(props);
    // Passing "hostId" to the layer causes the layer to prevent interaction
    // with items behind it for some reason. TODO: Fix that and re-enable

    // Variables need to be re-applied because the layer is outside of RTDRoot
    const theme = useThemeState();
    return (
        <Callout
            {...props}
            className={
                props.className ? `${theme.classNames} ${props.className}` : theme.classNames
            } /* layerProps={{ hostId, ...props.layerProps }} */
        />
    );
};

export const RTDModal: React.FC<IModalProps> = (props) => {
    useGlobalLoadingFromProps(props);
    return <Modal {...modalProps(props)} />;
};

export const RTDPanel: React.FC<IPanelProps> = (props) => {
    useGlobalLoadingFromProps(props);
    return <Panel {...props} layerProps={{ hostId, ...props.layerProps }} />;
};

export const RTDDialog: React.FC<IDialogProps> = (props) => {
    useGlobalLoadingFromProps(props);
    return <Dialog {...props} modalProps={modalProps(props.modalProps)} />;
};

export const RTDLayer: React.FC<ILayerProps> = (props) => {
    useGlobalLoadingFromProps(props);
    return <Layer hostId={hostId} {...props} />;
};
