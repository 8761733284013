import * as React from 'react';
import { useMemo } from 'react';
import { Spinner, SpinnerSize, FontIcon, CommandButton, Text } from 'office-ui-fabric-react';

import { usePropsRef } from '../../../../../common';
import { APP_STRINGS } from '../../../../../res';

import { RTDDropdownProps, RTDDropdown } from '../../RTDDropdown';
import { errorButtonStyles } from './styles';

import styles from './RTDStatusDropdown.module.scss';

interface RTDStatusDropdownProps extends RTDDropdownProps {
    loading?: boolean;

    error?: React.ReactNode;
    errorButtonProps?: RTDStatusDropdownErrorButtonProps;
}

export interface RTDStatusDropdownErrorButtonProps {
    iconName?: string;
    text?: string;
    onClick?: () => void;
}

/**
 * A RTDDropdown with allowances for loading and error states
 *
 * Currently, a loading state is only represented in the dropdown Callout, and is not visible when the dropdown is closed
 */
export const RTDStatusDropdown: React.FC<RTDStatusDropdownProps> = ({
    error,
    loading,
    errorButtonProps,
    componentRef: externalComponentRef,
    onRenderHeader,
    ...props
}) => {
    const { localRef: dropdownRef, componentRef } = usePropsRef(externalComponentRef);

    const onRenderErrorList = useMemo(() => {
        const {
            iconName: errorIconName,
            text: errorButtonText,
            onClick: externalOnErrorButtonClick,
        } = errorButtonProps ?? {};

        const onErrorButtonClick = () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (dropdownRef.current as any)?.setState({ isOpen: false });

            externalOnErrorButtonClick?.();
        };

        return () => (
            <div className={styles.customList}>
                {onRenderHeader?.()}
                {loading ? (
                    <div className={styles.spinner}>
                        <Spinner
                            size={SpinnerSize.xSmall}
                            label={APP_STRINGS.parameterDropdown.loading}
                            labelPosition="right"
                        />
                    </div>
                ) : (
                    <>
                        <div className={styles.errorBlock}>
                            <FontIcon className={styles.titleIcon} iconName="Info" />
                            <Text className={styles.errorText}>{error}</Text>
                        </div>
                        {externalOnErrorButtonClick && errorButtonText && (
                            <CommandButton
                                iconProps={{ iconName: errorIconName }}
                                text={errorButtonText}
                                styles={errorButtonStyles()}
                                onClick={onErrorButtonClick}
                            />
                        )}
                    </>
                )}
            </div>
        );
    }, [loading, error, errorButtonProps, onRenderHeader, dropdownRef]);

    return (
        <RTDDropdown
            {...props}
            componentRef={componentRef}
            onRenderList={error ?? loading ? onRenderErrorList : undefined}
            onRenderHeader={onRenderHeader}
        />
    );
};
