import * as React from 'react';
import { RefObject } from 'office-ui-fabric-react';
import { useRef, useCallback } from 'react';

/**
 * Consume an external `componentRef` (likely through props), and expose a local ref to the component
 *
 * This is used to allow multiple consumers of the same Fabric ref
 * @param externalComponentRef The possible prop `componentRef` as a Fabric ref
 */
export const usePropsRef = <T>(
    externalComponentRef: React.RefObject<T> | RefObject<T> | ((ref: T | null) => void) | undefined
) => {
    const localRef = useRef<T | null>(null);

    const componentRef = useCallback(
        (ref: T | null) => {
            // Support our internal ref, as well as possible props
            localRef.current = ref;

            if (typeof externalComponentRef === 'function') {
                externalComponentRef(ref);
            } else if (externalComponentRef) {
                (externalComponentRef as { current: T | null }).current = ref;
            }
        },
        [externalComponentRef]
    );

    return {
        localRef,
        componentRef,
    };
};
