import JSBI from 'jsbi';

import { err, ok } from '../../../common';
import { APP_STRINGS } from '../../../res';

import { buildValueImpl, ValueImpl } from '../valueImpl';

const int64Max = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(63));

const bigIntOne = JSBI.BigInt(1);

// From https://stackoverflow.com/a/21505954
const intRegex = /^([+-]?[1-9]\d*|0)$/;

export const int64ValueImpl: ValueImpl<string, 'int64'> = buildValueImpl({
    dataType: 'int64',
    urlStringToValue(value: string) {
        if (!value.match(intRegex)) {
            return err(APP_STRINGS.domain.parameter.errors.error);
        }

        try {
            const bigInt = JSBI.BigInt(value);

            // 2^63 - 1
            const maxValue = JSBI.subtract(int64Max, bigIntOne);

            // >  ||
            if (JSBI.greaterThan(bigInt, maxValue)) {
                return err(`${APP_STRINGS.domain.parameter.errors.int64.tooLarge}${maxValue}`);
            }

            // -2^63
            const minValue = JSBI.unaryMinus(int64Max);

            if (JSBI.lessThan(bigInt, minValue)) {
                return err(`${APP_STRINGS.domain.parameter.errors.int64.tooLarge}${minValue}`);
            }
        } catch (e) {
            // Failed to convert to JSBI.BigInt
            return err(e.message);
        }

        return ok(value);
    },
    valueToUrlString(value: string) {
        return value;
    },
    /**
     * TODO: Use Number.prototype.toLocaleString() after
     * switching to native big ints
     */
    valueToDisplayString(value: string) {
        return value;
    },
    tryNarrowValue(value) {
        if (value.tag === 'int64') {
            return value;
        }
        return undefined;
    },
    isValue(value) {
        return typeof value === 'string' && int64ValueImpl.urlStringToValue(value).kind === 'ok';
    },
});
