import * as React from 'react';
import { IDropdownOption, Icon, IRenderFunction, ISelectableOption, Text } from 'office-ui-fabric-react';
import { useState, useCallback, useMemo } from 'react';

import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { RTDDropdown, RTDDropdownProps } from '../../fabric';

import { TDuration, durationDisplayString, pillShortenedTitle, Duration, TimeZone, useTimeZone } from '../../../domain';
import { DropdownPill } from '../pill/DropdownPill';
import { optionsStyles } from '../pill/styles';
import { ParameterDropdownVariables } from '../ParameterDropdown';

import { FixedDurationPicker } from './FixedDurationPicker/FixedDurationPicker';

import styles from './styles.module.scss';

export interface IDurationPickerProps {
    className?: string;
    label?: string;

    name: string;
    isPill: boolean;

    duration: TDuration;
    onDurationUpdate: (duration: TDuration) => void;

    variableNames?: string[];

    disabled?: boolean;
}

interface Option {
    key: string;
    text: string;
    data?: { count: number; unit: Duration.TimeUnit };
}

const basicOptions = APP_CONSTANTS.durationPicker.dynamicOptions.map(([key, duration]) => ({
    key: `${duration.count}-${duration.unit}`,
    text: APP_STRINGS.duration.dynamicOptions[key],
    data: duration,
}));

const options: Option[] = [{ key: 'custom', text: APP_STRINGS.duration.customDropdownOption }, ...basicOptions];

const findSelectedKey = (duration: TDuration) => {
    if (duration.kind === 'fixed') {
        return 'custom';
    }
    const basicOption = basicOptions.find((o) => o.data.count === duration.count && o.data.unit === duration.unit);
    if (basicOption) {
        return basicOption.key;
    }
    return 'custom';
};

const onRenderItem: (selectedKey: string | number) => IRenderFunction<ISelectableOption> =
    (key) => (props, DefaultRender) => {
        if (!props || !DefaultRender) {
            return <></>;
        }
        return (
            <>
                <CheckMark visible={key === props.key} />
                <DefaultRender {...props} />
            </>
        );
    };

const CheckMark: React.FC<{ visible: boolean }> = ({ visible }) => {
    return <Icon className={styles.optionIcon} iconName={visible ? 'CheckMark' : undefined} />;
};

export const DurationPicker: React.FC<IDurationPickerProps> = ({
    className,
    label,
    name,
    isPill,
    duration,
    variableNames,
    disabled,
    onDurationUpdate,
}) => {
    const timeZone = useTimeZone();
    // containerRef must be managed with useState, not useRef because we need to
    // prevent rendering the fabric callout in FixedDurationPicker if it is null
    const [containerRef, setRef] = useState<Element | null>(null);

    const [fixedPickerOpen, setFixedPickerOpen] = useState(false);
    const selectedKey = useMemo(() => findSelectedKey(duration), [duration]);

    const onChange = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            // A duration should always be selected. Ignore deselect events.
            if (option === undefined) {
                return;
            }
            // Because Dropdown options isn't generic we have to cast here so data isn't
            // typed as `any`
            const data = option.data as Duration.Dynamic;
            if (!data) {
                setFixedPickerOpen(true);
            } else if (duration.kind !== 'dynamic' || duration.unit !== data.unit || duration.count !== data.count) {
                onDurationUpdate(data);
            }
        },
        [duration, onDurationUpdate, setFixedPickerOpen]
    );

    const dropdownProps = {
        name,
        onRenderTitle: selectedKey === 'custom' ? () => <>{durationDisplayString(duration, timeZone)}</> : undefined,
        options,
        onChange,
        selectedKey,
        onRenderOption: onRenderItem(selectedKey),
        disabled,
        'data-automation-id': 'duration-picker',
        'aria-label': name,
    };

    const baseClassName = isPill ? styles.wrapper : undefined;
    const finalClassName = baseClassName ? (className ? `${baseClassName} ${className}` : baseClassName) : className;

    return (
        // FIXME: office-ui-fabric-react dropdown isn't a controlled component. This
        // hack doesn't 100% guarantee that the dropdown can't be open at the same
        // time as the custom duration callout
        //
        // The pointerEvents style applied to this div prevents the dropdown from
        // being opened while the custom duration callout is open.
        <div ref={setRef} className={finalClassName} style={fixedPickerOpen ? { pointerEvents: 'none' } : undefined}>
            {isPill ? (
                <DurationPickerPill
                    duration={duration}
                    variables={variableNames}
                    timeZone={timeZone}
                    {...dropdownProps}
                />
            ) : (
                <RTDDropdown label={label} {...dropdownProps} />
            )}
            {fixedPickerOpen && containerRef && (
                <FixedDurationPicker
                    currentDuration={duration}
                    calloutTarget={containerRef}
                    onApply={(newDuration) => {
                        setFixedPickerOpen(false);
                        if (
                            duration.kind !== 'fixed' ||
                            duration.start.valueOf() !== newDuration.start.valueOf() ||
                            duration.end.valueOf() !== newDuration.end.valueOf()
                        ) {
                            onDurationUpdate(newDuration);
                        }
                    }}
                    onClose={() => setFixedPickerOpen(false)}
                />
            )}
        </div>
    );
};

interface DurationPickerPillProps extends RTDDropdownProps {
    name: string;
    duration: TDuration;
    variables?: string[];
    timeZone: TimeZone;
}

const DurationPickerPill: React.FC<DurationPickerPillProps> = (props) => {
    const { variables, duration, disabled, timeZone } = props;

    const customTitle = useMemo(
        () =>
            // Only show variables if disabled
            variables && disabled ? (
                <ParameterDropdownVariables variables={variables} />
            ) : (
                // TODO #7041524: Re-enable proper date display
                <Text styles={optionsStyles}>
                    {duration.kind !== 'fixed'
                        ? durationDisplayString(duration, timeZone)
                        : pillShortenedTitle(duration, timeZone)}
                </Text>
            ),
        [duration, variables, disabled, timeZone]
    );

    const customTooltip = useMemo(
        () =>
            !variables
                ? () => <Text>{durationDisplayString(duration, timeZone)}</Text>
                : () => <ParameterDropdownVariables variables={variables} />,
        [duration, timeZone, variables]
    );

    return (
        <DropdownPill
            {...props}
            customOptionsChild={customTitle}
            onRenderCustomTooltipChildren={customTooltip}
            useContentSizeWidth={true}
        />
    );
};
