import * as React from 'react';
import { useCallback } from 'react';
import { Dropdown, IDropdown, ISelectableDroppableTextProps } from 'office-ui-fabric-react';

import { RTDDropdownProps } from '../RTDDropdown';

/**
 * Unsafe dropdown with custom header support. If `onRenderList` is provided, the header must be manually rendered by the creator of the `onRenderList` callback
 */
export const UnsafeHeaderDropdown: React.FC<RTDDropdownProps> = ({ onRenderHeader, onRenderList, ...props }) => {
    const internalOnRenderList = useCallback(
        (
            innerProps?: ISelectableDroppableTextProps<IDropdown, HTMLDivElement>,
            defaultRender?: (p?: ISelectableDroppableTextProps<IDropdown, HTMLDivElement>) => JSX.Element | null
        ) =>
            onRenderHeader ? (
                <>
                    {onRenderHeader()}
                    {defaultRender?.(innerProps)}
                </>
            ) : (
                defaultRender?.(innerProps) ?? null
            ),
        [onRenderHeader]
    );

    return <Dropdown onRenderList={onRenderList ? onRenderList : internalOnRenderList} {...props} />;
};
