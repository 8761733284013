import * as React from 'react';
import { useRef, useCallback } from 'react';
import { IconButton } from 'office-ui-fabric-react/lib/components/Button';

import { IDashboardDocument, IDashboardListItem } from '../../../core/domain';
import { useGlobalDispatch } from '../../../store/redux';
import { APP_STRINGS } from '../../../res';
import { copyToClipboard, isAbortError, parseException, useAbortController } from '../../../common';
import { onExportToJson } from '../../../domain/export';
import { useCore } from '../../../core';
import { ButtonWithCalloutRef, ButtonWithCallout, rtdPrompt } from '../../../components';
import {
    createDashboardDialogAction,
    removeDashboardDialogAction,
    updatePermissionsPanelAction,
} from '../../../components/Forms';
import { MigrationResult, serializeDashboard } from '../../../migration';
import { formatMigrationError } from '../../../components/errors/formatMigrationError';

import { styles } from './RowActionButtonStyle';

const menuIconProps = { iconName: 'MoreVertical' };

export interface IRowMenuButtonProps {
    dashboardItem: IDashboardListItem;
}

export const RowMenuButton: React.FunctionComponent<IRowMenuButtonProps> = ({ dashboardItem }) => {
    const [dispatch] = useGlobalDispatch();
    const controller = useAbortController();
    const core = useCore();

    const buttonRef = useRef<ButtonWithCalloutRef>(null);
    const dashboardUrl = core.navUtil.path.dashboard(dashboardItem.id);

    const onEditPermissions = () =>
        dispatch(
            updatePermissionsPanelAction({
                dashboardId: dashboardItem.id,
                dashboardTitle: dashboardItem.title,
            })
        );

    const onCopyLink = useCallback(
        (event) => {
            const url = new URL(dashboardUrl, window.location.href);
            event?.preventDefault();

            copyToClipboard(url.href);
            buttonRef.current?.open();
        },
        [dashboardUrl]
    );

    const onDuplicate = async () => {
        let dashboard: MigrationResult<IDashboardDocument>;
        try {
            dashboard = await core.dashboardService.getDashboard(dashboardItem.id, controller.signal);
        } catch (e) {
            if (isAbortError(e)) {
                return;
            }

            rtdPrompt(dispatch, APP_STRINGS.error.failedToLoadDashboard, {
                subText: parseException(e),
            });
            return;
        }

        if (dashboard.kind === 'err') {
            rtdPrompt(dispatch, APP_STRINGS.error.failedToLoadDashboard, {
                subText: formatMigrationError(dashboard.err),
            });
            return;
        }

        dispatch(
            createDashboardDialogAction({
                template: serializeDashboard(dashboard.value.data),
                dashboardTitle: dashboardItem.title,
                migrationWarnings: dashboard.value.warnings,
            })
        );
    };

    // Using curly braces ro resolve the expected type to void
    const onExport = () => {
        onExportToJson(dashboardItem.id, core, dispatch, controller.signal);
    };

    const onDelete = () =>
        dispatch(
            removeDashboardDialogAction({
                dashboardId: dashboardItem.id,
                dashboardTitle: dashboardItem.title,
            })
        );

    const onForget = async () => {
        if (
            await rtdPrompt(
                dispatch,
                <>
                    <>{APP_STRINGS.forms.forgetDashboard.title}</> <i>{dashboardItem.title}</i>
                </>,
                {
                    subText: APP_STRINGS.forms.forgetDashboard.subText,
                    acceptText: APP_STRINGS.forms.forgetDashboard.acceptText,
                }
            )
        ) {
            await core.dashboardService.forgetDashboard(dashboardItem.id);
            dispatch({
                type: 'removedDashboardListItem',
                dashboardId: dashboardItem.id,
            });
        }
    };

    return (
        <ButtonWithCallout
            styles={styles}
            title={APP_STRINGS.catalogPage.additionalDashboardActionsButtonTitle}
            ref={buttonRef}
            component={IconButton}
            calloutContent={APP_STRINGS.utilButtons.copyLinkSuccess}
            menuIconProps={menuIconProps}
            menuProps={{
                items: [
                    {
                        key: 'copyLink',
                        name: APP_STRINGS.utilButtons.copyLink,
                        onClick: onCopyLink,
                    },
                    {
                        key: 'permissions',
                        name: APP_STRINGS.catalogPage.editPermissions,
                        onClick: onEditPermissions,
                    },
                    {
                        key: 'duplicate',
                        name: APP_STRINGS.catalogPage.duplicateDashboard,
                        onClick: onDuplicate,
                    },
                    {
                        key: 'delete',
                        name: APP_STRINGS.catalogPage.deleteDashboard,
                        onClick: onDelete,
                    },
                    {
                        key: 'forget',
                        name: APP_STRINGS.catalogPage.forgetDashboard,
                        onClick: onForget,
                    },
                    ...(core.featureFlags.has('internal')
                        ? [
                              {
                                  key: 'export',
                                  name: APP_STRINGS.utilButtons.export,
                                  onClick: onExport,
                              },
                          ]
                        : []),
                ],
                isBeakVisible: false,
            }}
        />
    );
};
