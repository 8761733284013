import * as React from 'react';
import { useCallback, useRef } from 'react';
import { IconButton } from 'office-ui-fabric-react';
import { APP_STRINGS } from '../../../../../../res';
import styles from './styles.module.scss';

const editButtonIconProps = { iconName: 'edit' };
const deleteButtonIconProps = { iconName: 'delete' };

export interface ConfigurationItemRowProps<T> {
    item: T;
    onDelete: (itemId: string) => void;
    disabled?: boolean;
    /*
     ** Render the summary of each item
     */
    ItemComponent: React.ComponentType<{ item: T }>;
    onEdit: (item: T, calloutTargetRef: React.MutableRefObject<null>) => void;
    onItemCreated?: (item: T, calloutTargetRef: React.MutableRefObject<null>) => void;
    disableDelete?: (item: T) => boolean;
}

export const ConfigurationItemRow = <T extends { id: string }>({
    item,
    onDelete,
    disabled,
    ItemComponent,
    onEdit,
    onItemCreated,
    disableDelete,
}: ConfigurationItemRowProps<T>) => {
    const calloutTargetRef = useRef(null);

    const onEditClicked = useCallback(() => onEdit(item, calloutTargetRef), [item, onEdit]);
    const innerOnDelete = useCallback(() => onDelete(item.id), [item.id, onDelete]);

    React.useEffect(() => {
        if (onItemCreated) {
            onItemCreated(item, calloutTargetRef);
        }
    }, [item, onItemCreated]);

    return (
        <div ref={calloutTargetRef} className={styles.configItem}>
            <ItemComponent item={item} />
            <IconButton
                className={styles.editButton}
                iconProps={editButtonIconProps}
                onClick={onEditClicked}
                title={APP_STRINGS.utilButtons.edit}
                disabled={disabled}
            />
            <IconButton
                className={styles.deleteButton}
                iconProps={deleteButtonIconProps}
                onClick={innerOnDelete}
                title={APP_STRINGS.utilButtons.delete}
                disabled={disabled || disableDelete?.(item)}
            />
        </div>
    );
};
