import * as React from 'react';
import { IStyle, ITheme } from 'office-ui-fabric-react/lib/Styling';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@uifabric/utilities';
import { StatCard, StatCardStyleProps, StatCardStyles, StatCardProps } from './StatCard';

const getClassNames = classNamesFunction<MultiStatCardStyleProps, MultiStatCardStyles>();

type TextSize = 'small' | 'auto' | 'large';

type MultiStatCardStyleProps = Required<
    Pick<MultiStatCardProps, 'theme' | 'textSize' | 'numberOfColumns' | 'numberOfRows'>
>;

/**
 * A quadrant (one cell in the grid) has a header (text) and value (the number)
 */
interface QuadrantData {
    value: number | string | undefined | null;
    header: string;
}

type ConditionalFormattingProps = Pick<StatCardProps, 'icon' | 'subLabel' | 'colorStyle' | 'colorStyle' | 'color'>;

interface MultiStatCardProps {
    /**
     * number of rows in the multistat
     */
    numberOfRows: number;

    /**
     * number of columns in the multistat
     */
    numberOfColumns: number;

    /**
     * the array containing the data to display.
     */
    data: QuadrantData[];

    /**
     * the array containing the conditional formatting props. It should be indexed in the same order as the "data" array.
     */
    conditionalFormattingProps: ConditionalFormattingProps[];

    /**
     * size of text in the value (header size remains the same)
     */
    textSize: TextSize;

    /**
     * the locale for number localization.
     */
    locale: string;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<MultiStatCardStyleProps, MultiStatCardStyles>;
}

interface SubComponentStyles {
    statCard: IStyleFunctionOrObject<StatCardStyleProps, StatCardStyles>;
}
interface MultiStatCardStyles {
    root: IStyle;
    subComponentStyles: SubComponentStyles;
}

const getMultiStatStyles = (props: MultiStatCardStyleProps): MultiStatCardStyles => {
    // cells in the last column
    const rightmostQuadrants = `:nth-child(${props.numberOfColumns}n)`;
    // cells in the first row
    const topmostQuadrants = `:nth-child(-n + ${props.numberOfColumns})`;
    // first cell in the bottom row
    const leftbottommostQuadrants = `:nth-child(${props.numberOfColumns}n + 1):nth-last-child(-n + ${props.numberOfColumns})`;
    // other cells in the bottom row
    const bottommostQuadrants = `:nth-child(${props.numberOfColumns}n + 1):nth-last-child(-n + ${props.numberOfColumns}) ~ div`;

    return {
        root: {
            // fill tile
            height: '100%',
            display: 'grid',
            // distribute space evenly between quadrants
            gridTemplateColumns: `repeat(${props.numberOfColumns}, 1fr)`,
            gridTemplateRows: `repeat(${props.numberOfRows}, 1fr)`,
        },
        subComponentStyles: {
            statCard: {
                // paint a border between elements in the grid
                root: {
                    borderBottom: `solid 1px ${props.theme!.palette.neutralLight}`,
                    borderRight: `solid 1px ${props.theme!.palette.neutralLight}`,
                    selectors: {
                        // remove right border for the last column
                        [rightmostQuadrants]: {
                            borderRight: 'none',
                        },
                        // give top border for the first row
                        [topmostQuadrants]: {
                            borderTop: `solid 1px ${props.theme!.palette.neutralLight}`,
                        },
                        // remove bottom border for the last row (unless it's also the first row)
                        [`${bottommostQuadrants}&:not(${topmostQuadrants})`]: {
                            borderBottom: 'none',
                        },
                        [`${leftbottommostQuadrants}&:not(${topmostQuadrants})`]: {
                            borderBottom: 'none',
                        },
                    },
                },
            },
        },
    };
};

const MultiStatCardBase = ({
    data,
    styles,
    theme,
    textSize,
    numberOfColumns,
    numberOfRows,
    conditionalFormattingProps,
    locale,
}: MultiStatCardProps) => {
    const classNames = getClassNames(styles, {
        theme: theme!,
        textSize: textSize ?? 'auto',
        numberOfColumns,
        numberOfRows,
    });

    return (
        <div className={classNames.root}>
            {data.map((d, i) => (
                <StatCard
                    value={d.value}
                    textSize={textSize}
                    key={i}
                    header={d.header}
                    styles={classNames.subComponentStyles.statCard}
                    {...conditionalFormattingProps[i]}
                    locale={locale}
                />
            ))}
        </div>
    );
};

MultiStatCardBase.defaultProps = {
    textSize: 'auto' as TextSize,
};

/**
 * A component that displays a single stat (usually numeric, but can be textual).
 * Usually displayed on small rectangular surfaces as part of a dashboard.
 */
export const MultiStatCard = styled(MultiStatCardBase, getMultiStatStyles);
