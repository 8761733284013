import React, { useState, useCallback, useMemo } from 'react';
import { Text } from 'office-ui-fabric-react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { CanInfer } from '../../../../../../common';
import { APP_STRINGS, BASE_Y_AXIS_ID } from '../../../../../../res';
import { BaseYAxisConfig, YAxisConfig } from '../../../../../../domain';
import { useETPDispatch, useETPSelector } from '../../../../../../store/editTile';
import { visualOptionsSelectorBuilder } from '../../../../lib';
import { ManagedConfigComponent } from '../../types';
import { ConfigurationList, ApplyConfigChange, AddItemButton } from '../configurationList/ConfigurationList';
import configListStyles from '../configurationList/styles.module.scss';
import { MultipleYAxesCallout } from './MultipleYAxesCallout/MultipleYAxesCallout';
import { BaseYAxisForm, BaseYAxisInputField } from './BaseYAxisForm';
import multipleYAxesStyles from './styles.module.scss';

type ApplyYAxisChange = ApplyConfigChange<YAxisConfig>;

const appStringsLocal = APP_STRINGS.editTilePage.visualConfig.multipleYAxes;

const formatYAxisColumn = (columns: string[]) => `${appStringsLocal.columnDropdownLabel} ${columns.join(', ')}`;

const replaceYAxis =
    (yAxis: YAxisConfig): ApplyYAxisChange =>
    (yAxes) => {
        const index = yAxes.findIndex((y) => y.id === yAxis.id);

        if (index === -1) {
            return undefined;
        }
        return [...yAxes.slice(0, index), yAxis, ...yAxes.slice(index + 1)];
    };

export interface MultipleYAxesProps {
    baseAxis: YAxisConfig;
    additionalAxes: YAxisConfig[];
    onChange: (applyChange: ApplyYAxisChange) => void;
}

export const MultipleYAxes: React.FC<MultipleYAxesProps> = ({ baseAxis, additionalAxes, onChange }) => {
    const [{ showCallout, calloutTarget, calloutYAxis }, setCalloutState] = useState<{
        showCallout: boolean;
        calloutTarget?: React.MutableRefObject<null>;
        calloutYAxis?: YAxisConfig;
    }>({ showCallout: false });

    // We want to open the callout after the item was created. This property will keep track if we just added an item
    const shouldAutoOpenCallout = React.useRef(false);

    const yColumns = useETPSelector(yColumnsSelector);
    const yColumnNames = React.useMemo(() => {
        return yColumns.type === 'infer' ? [] : yColumns.value;
    }, [yColumns]);

    // adding 1 to include the base y axis
    const addButtonDisabled = additionalAxes.length + 1 >= yColumnNames.length;

    const { onCalloutClose, onCalloutSave, onEditClicked, onItemCreated } = useMemo(() => {
        const onCalloutCloseMemo = () => setCalloutState({ showCallout: false });

        const onCalloutSaveMemo = (newYAxis: YAxisConfig) => {
            onChange(replaceYAxis(newYAxis));
            setCalloutState({ showCallout: false });
        };
        const onEditClickedMemo = (item: YAxisConfig, ref: React.MutableRefObject<null>) => {
            setCalloutState({
                showCallout: true,
                calloutTarget: ref,
                calloutYAxis: item,
            });
        };

        const onItemCreatedMemo = (item: YAxisConfig, ref: React.MutableRefObject<null>) => {
            // Open the callout for the newly created y axis (not for the base axis)
            if (shouldAutoOpenCallout.current && item.id !== BASE_Y_AXIS_ID) {
                setCalloutState({
                    showCallout: true,
                    calloutTarget: ref,
                    calloutYAxis: item,
                });
                shouldAutoOpenCallout.current = false;
            }
        };

        return {
            onCalloutClose: onCalloutCloseMemo,
            onCalloutSave: onCalloutSaveMemo,
            onEditClicked: onEditClickedMemo,
            onItemCreated: onItemCreatedMemo,
        };
    }, [onChange]);

    const availableColumns = getAvailableYColumns(yColumns, additionalAxes);

    const onAddYAxis = useCallback(() => {
        const addYAxis: ApplyYAxisChange = (axes) => {
            if (axes.length >= yColumnNames.length || availableColumns.length <= 1) {
                return axes;
            }

            const newAxis: YAxisConfig = {
                id: uuid(),
                columns: [availableColumns[availableColumns.length - 1]],
                label: '',
                yAxisMaximumValue: null,
                yAxisMinimumValue: null,
                yAxisScale: 'linear',
            };

            return [...axes, newAxis];
        };
        onChange(addYAxis);
        shouldAutoOpenCallout.current = true;
    }, [onChange, availableColumns, yColumnNames]);

    const allYAxes = [baseAxis, ...additionalAxes];

    const horizontalLine = (
        <BaseYAxisInputField
            property="horizontalLine"
            label={APP_STRINGS.editTilePage.visualConfig.horizontalLineInputLabel}
        />
    );

    const addButtonTooltip = addButtonDisabled ? appStringsLocal.addYAxisDisabledTooltip : undefined;

    return (
        <>
            {additionalAxes.length === 0 && (
                <div className={multipleYAxesStyles.formWrapper}>
                    <BaseYAxisForm />
                    {horizontalLine}
                    <AddItemButton
                        addItemButtonText={appStringsLocal.addYAxisButtonText}
                        onAddClick={onAddYAxis}
                        disabled={addButtonDisabled}
                        tooltipText={addButtonTooltip}
                    />
                </div>
            )}
            {additionalAxes.length > 0 && (
                <div>
                    {horizontalLine}
                    <ConfigurationList
                        items={allYAxes}
                        onChange={onChange}
                        onEdit={onEditClicked}
                        onItemCreated={onItemCreated}
                        maxItems={yColumnNames.length}
                        addConfigItem={onAddYAxis}
                        addItemButtonText={appStringsLocal.addYAxisButtonText}
                        ItemComponent={ItemComponent}
                        disableDelete={(item: YAxisConfig) => item.id === BASE_Y_AXIS_ID}
                        addItemButtonTooltip={addButtonTooltip}
                    />
                </div>
            )}
            {showCallout && calloutYAxis && calloutTarget && (
                <MultipleYAxesCallout
                    onClose={onCalloutClose}
                    onSave={onCalloutSave}
                    yAxis={calloutYAxis}
                    targetRef={calloutTarget}
                    baseYAxisColumns={availableColumns}
                />
            )}
        </>
    );
};

const yColumnsSelector = visualOptionsSelectorBuilder((s) => s.yColumns);

export const getAvailableYColumns = (yColumns: CanInfer<string[]>, yAxes: YAxisConfig[]) => {
    const columnsInUse = yAxes.flatMap((axis) => axis.columns);
    return yColumns.type === 'infer' ? [] : yColumns.value.filter((y) => !columnsInUse.includes(y));
};

const ItemComponent: React.FC<{ item: YAxisConfig }> = ({ item }) => {
    let columns = item.columns;
    const yColumns = useETPSelector(yColumnsSelector);
    const yAxes = useETPSelector(yAxesSelector);
    const isBaseAxis = item.id === BASE_Y_AXIS_ID;

    if (isBaseAxis) {
        columns = getAvailableYColumns(yColumns, yAxes.additional);
    }
    const axisColumnsFormatted = formatYAxisColumn(columns);
    const axisLabel = isBaseAxis ? `${appStringsLocal.mainAxis}: ${axisColumnsFormatted}` : axisColumnsFormatted;

    return (
        <span className={configListStyles.configItemText}>
            <Text
                className={classNames(multipleYAxesStyles.axisColumnsLabel, {
                    [multipleYAxesStyles.mainAxisColumnsLabel]: isBaseAxis,
                })}
            >
                {axisLabel}
            </Text>
            <Text variant="small">{item.label}</Text>
        </span>
    );
};

const yAxesSelector = visualOptionsSelectorBuilder((s) => s.multipleYAxes);

export const MultipleYAxesManagedConfig: ManagedConfigComponent = () => {
    const [dispatch] = useETPDispatch();
    const { base, additional } = useETPSelector(yAxesSelector);

    const onChange = useCallback(
        (applyChange: (axes: YAxisConfig[]) => YAxisConfig[] | undefined) =>
            dispatch((innerDispatch, getState) => {
                const pageState = getState();
                if (pageState.type !== 'query' || !pageState.visual) {
                    return;
                }

                const { visual: visualOptions } = pageState;
                const updatedYAxes = applyChange([
                    pageState.visual.options.multipleYAxes.base,
                    ...pageState.visual.options.multipleYAxes.additional,
                ]);

                if (updatedYAxes) {
                    const baseIndex = updatedYAxes.findIndex((y) => y.id === BASE_Y_AXIS_ID);
                    innerDispatch({
                        type: 'updateVisualOptions',
                        options: {
                            ...visualOptions,
                            multipleYAxes: {
                                base: updatedYAxes[baseIndex] as BaseYAxisConfig,
                                additional: [...updatedYAxes.slice(0, baseIndex), ...updatedYAxes.slice(baseIndex + 1)],
                            },
                        },
                    });
                }
            }),
        [dispatch]
    );

    return <MultipleYAxes baseAxis={base} additionalAxes={additional} onChange={onChange} />;
};
