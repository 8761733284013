import * as React from 'react';
import { observer } from 'mobx-react-lite';

import { ControlBar, DataSourceDropdown, RunButton } from '../../../../components/QueryEditing';
import { IQueryEditor } from '../../../../components';
import { ETPAction, ETPState, useDashboardStore, useETPDispatch, useETPSelectors } from '../../../../store';
import { useFlush } from '../../lib';

import { ETPQueryEditor } from './ETPQueryEditor';
import { NoDataSources } from './NoDataSources/NoDataSources';

const runQueryAction: ETPAction = { type: 'runQuery' };

const controlBarSelectors = {
    stateIsQueryType: (s: ETPState) => s.type === 'query',
    dataSourceId: (s: ETPState) => (s.type === 'query' ? s.dataSourceId : undefined),
    runButtonDisabled: (s: ETPState) => s.type !== 'query' || s.query.trim() === '',
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const TopPane = observer<{}, IQueryEditor | null>(
    (_props, ref) => {
        const store = useDashboardStore();
        const flush = useFlush();
        const [dispatch] = useETPDispatch();
        const { stateIsQueryType, dataSourceId, runButtonDisabled } = useETPSelectors(controlBarSelectors);

        const dataSourceMissing =
            dataSourceId === undefined || store.state?.dataSourcesRecord[dataSourceId] === undefined;

        const { runQuery, onDataSourceChange } = React.useMemo(
            () => ({
                async runQuery() {
                    await flush();
                    dispatch(runQueryAction);
                },
                onDataSourceChange(newDataSourceId: string | undefined) {
                    dispatch({ type: 'updateDataSource', dataSourceId: newDataSourceId });
                },
            }),
            [dispatch, flush]
        );

        return (
            <>
                <ControlBar open={stateIsQueryType}>
                    <DataSourceDropdown dataSourceId={dataSourceId} onChange={onDataSourceChange} />
                    <RunButton onRun={runQuery} disabled={dataSourceMissing || runButtonDisabled} />
                </ControlBar>
                {stateIsQueryType && store.state?.dataSources.length === 0 ? (
                    <NoDataSources dispatch={dispatch} />
                ) : (
                    <ETPQueryEditor runQuery={runQuery} ref={ref} />
                )}
            </>
        );
    },
    { forwardRef: true }
);
