import * as React from 'react';
import { Pivot, PivotItem, TextField } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { err, formActionMacro, ok, Result, TFormComponent } from '../common';
import { IDataVisualProps, tagScalar, TRtdValue, useTimeZone } from '../domain';
import { useDashboardStore, useGlobalDispatch } from '../store';
import { RTDDialog } from '../components';

import styles from './DebugVisual.module.scss';

const ParameterErrorForm: TFormComponent<CrossFilterDebugModalProps> = (props) => {
    const [text, setText] = React.useState('');

    return (
        <form
            id="testForm"
            onSubmit={(event) => {
                event.preventDefault();
                props.setParameter(props.parameterId, err(text));
                props.onClose();
            }}
        >
            <TextField
                value={text}
                onChange={(_, newValue = '') => setText(newValue)}
                autoComplete="off"
                spellCheck="false"
            />
            <br />
            <button disabled={text === ''} type="submit">
                Submit
            </button>
        </form>
    );
};

const ParameterValueForm: TFormComponent<CrossFilterDebugModalProps> = observer(function CParameterValueForm(props) {
    const store = useDashboardStore();
    const timeZone = useTimeZone();
    const [text, setText] = React.useState('');

    if (!store.state) {
        return null;
    }

    const parameter = store.state.parametersRecord[props.parameterId];

    const error =
        parameter === undefined
            ? `Parameter ${props.parameterId} not found`
            : parameter?.kind !== 'basic'
            ? 'Debug visual cross filter only supports basic parameters'
            : parameter.impl.editStringToValue(text, timeZone).err;

    return (
        <form
            id="testForm"
            onSubmit={(event) => {
                event.preventDefault();
                if (parameter?.kind !== 'basic') {
                    return;
                }
                const maybeValue = parameter.impl.editStringToValue(text, timeZone);
                if (maybeValue.kind !== 'err') {
                    props.setParameter(
                        props.parameterId,
                        ok(tagScalar(parameter.impl.dataType, maybeValue.value) as TRtdValue)
                    );
                    props.onClose();
                }
            }}
        >
            <TextField
                value={text}
                onChange={(_, newValue = '') => setText(newValue)}
                autoComplete="off"
                spellCheck="false"
                errorMessage={error}
            />
            <br />
            <button disabled={error !== undefined} type="submit">
                Submit
            </button>
        </form>
    );
});

interface CrossFilterDebugModalProps {
    parameterId: string;
    setParameter(parameterId: string, value: Result<TRtdValue, React.ReactNode>): void;
}

const CrossFilterDebugModal: TFormComponent<CrossFilterDebugModalProps> = (props) => {
    return (
        <RTDDialog
            hidden={false}
            dialogContentProps={{ title: 'Cross Filter', showCloseButton: true }}
            onDismiss={props.onClose}
        >
            <Pivot>
                <PivotItem headerText="Value">
                    <ParameterValueForm {...props} />
                </PivotItem>
                <PivotItem headerText="Error">
                    <ParameterErrorForm {...props} />
                </PivotItem>
            </Pivot>
        </RTDDialog>
    );
};

const crossFilterFormAction = formActionMacro(CrossFilterDebugModal, 'debug-menu-cross-filter');

interface RenderJsonProps {
    json: unknown;
    path: string[];
    onDig(path: string[]): void;
}

const RenderJson: React.FC<RenderJsonProps> = ({ json, path, onDig }) => {
    if (typeof json !== 'object' || json === null) {
        return <>{JSON.stringify(json, null, '\t')}</>;
    }

    if (json === undefined) {
        return <>undefined</>;
    }

    return (
        <>
            {Object.entries(json).map(([key, value]) => {
                const newPath = [...path, key];
                return (
                    <div className={styles.jsonSection} key={key}>
                        <button onClick={() => onDig(newPath)}>{key}:</button>
                        <RenderJson json={value} path={newPath} onDig={onDig} />
                    </div>
                );
            })}
        </>
    );
};

function dig(json: unknown, path: string[]): { json: unknown; resolvedPath: string[] } {
    const resolvedPath: string[] = [];
    for (const key of path) {
        if (typeof json === 'object' && json !== null && key in json) {
            json = (json as Record<string, unknown>)[key];
            resolvedPath.push(key);
        }
    }
    return { json, resolvedPath };
}

export const DebugDataVisualComponent: React.FC<IDataVisualProps<string>> = (props) => {
    const [globalDispatch] = useGlobalDispatch();
    const { setResultCounts } = props;
    React.useEffect(() => {
        if (Math.random() > 0.5) {
            const totalResults = Math.floor(Math.random() * 100);
            const displayedResults = Math.random() > 0.5 ? Math.floor(Math.random() * totalResults) : totalResults;
            setResultCounts({ displayedResults, totalResults });
        } else {
            setResultCounts(null);
        }
    }, [setResultCounts]);

    let OpenDimensionSelector: React.ReactNode;

    if (props.visualOptions.crossFilter && props.setParameter) {
        const crossFilter = props.visualOptions.crossFilter;
        const setParameter = props.setParameter;
        OpenDimensionSelector = (
            <button
                onClick={() =>
                    globalDispatch(
                        crossFilterFormAction({
                            parameterId: crossFilter.parameterId,
                            setParameter,
                        })
                    )
                }
            >
                Cross filter
            </button>
        );
    } else {
        OpenDimensionSelector = null;
    }

    const [path, setPath] = React.useState<string[]>([]);

    const { json, resolvedPath } = dig(props, path);

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <div>{resolvedPath.join(', ') || <span className={styles.noPathText}>No path selected</span>}</div>
                <div>
                    <button disabled={resolvedPath.length === 0} onClick={() => setPath([])}>
                        Clear Path
                    </button>
                    {OpenDimensionSelector}
                </div>
            </div>
            <div className={styles.json}>
                <RenderJson json={json} onDig={setPath} path={resolvedPath} />
            </div>
        </div>
    );
};
