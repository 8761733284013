import * as React from 'react';

import styles from './CreateDashboardIcon.module.scss';

export const CreateDashboardIcon: React.FC = () => {
    return (
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className={styles.themeLighter}
                d="M110 0H10C4.47715 0 0 4.47715 0 10V110C0 115.523 4.47715 120 10 120H110C115.523 120 120 115.523 120 110V10C120 4.47715 115.523 0 110 0Z"
            />
            <path
                className={styles.white}
                opacity="0.53"
                d="M105 10H15C12.2386 10 10 12.2386 10 15V105C10 107.761 12.2386 110 15 110H105C107.761 110 110 107.761 110 105V15C110 12.2386 107.761 10 105 10Z"
            />
            <path className={styles.white} d="M65 20H20V70H65V20Z" />
            <path className={styles.white} opacity="0.68" d="M45 80H20V100H45V80Z" />
            <path className={styles.white} d="M100 80H55V100H100V80Z" />
            <path className={styles.white} opacity="0.68" d="M100 50H75V70H100V50Z" />
            <path className={styles.white} opacity="0.68" d="M100 20H75V40H100V20Z" />
        </svg>
    );
};
