import {
    Column,
    ColumnApi,
    ColumnState,
    GetMainMenuItemsParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    SuppressKeyboardEventParams,
} from '@ag-grid-enterprise/all-modules';
import { KeyCodes } from 'office-ui-fabric-react';
import { IFocusZoneProps } from 'office-ui-fabric-react/lib/FocusZone';
import {
    AccessibilityColumnsPanel,
    ColumnsButtonSelector,
    ColumnsPanelFocusSelector,
} from './AccessibilityColumnsPanel';
import { AccessibleColumnMenu } from './AccessibleColumnMenu';
import { AccessibleGridHack } from './AccessibleGridHacks';
import { getAccessibleHeaderComp } from './AccessibleHeader';
import { AccessibleIndexesAndSize } from './AccessibleIndexesAndSize';
import { AccessibleStrings } from './types';

export interface FocusableComponent {
    isActive: (activeElem: HTMLElement, gridApi: GridApi) => boolean;
    focus: (activeElem: HTMLElement, gridApi: GridApi) => string | void;
    clearFocus?: (activeElem: HTMLElement, gridApi: GridApi) => void;
    isVisible?: (activeElem: HTMLElement, gridApi: GridApi) => boolean;
}

export class AccessibleGrid {
    private accessibleGridColumnMenu: AccessibleColumnMenu;
    private accessibleIndexesAndSize: AccessibleIndexesAndSize;
    private gridApi: GridApi | null = null;
    private columnApi: ColumnApi | null = null;

    private activeComponents: FocusableComponent[];
    private readonly DefaultFocusableComponents: FocusableComponent[] = [
        {
            isActive: (activeElem) => activeElem.getAttribute('role') === 'columnheader',
            focus: (_activeElem) => '.kusto-query-header .ag-cell-label-container',
        },
        {
            isActive: (activeElem) => activeElem.getAttribute('role') === 'gridcell',
            focus: () => this.moveToGridTable(),
            clearFocus: (_activeElem, gridApi) => {
                gridApi.clearFocusedCell();
            },
        },
        {
            isActive: (activeElem) => activeElem.getAttribute('data-is-kusto-panel') === 'true',
            focus: (_activeElem) => ColumnsPanelFocusSelector,
            isVisible: (_activeElem, gridApi) => gridApi.isToolPanelShowing(),
        },
        {
            isActive: (activeElem) => activeElem.getAttribute('ref') === 'eToggleButton',
            focus: (_activeElem) => ColumnsButtonSelector,
        },
    ];

    constructor(private getGridElement: () => HTMLDivElement | null, private strings: AccessibleStrings) {
        this.gridOptions = this.gridOptions.bind(this);
        this.activeComponents = this.DefaultFocusableComponents;
        this.accessibleGridColumnMenu = new AccessibleColumnMenu(this.getGridElement, strings);
        this.accessibleIndexesAndSize = new AccessibleIndexesAndSize(
            this.getGridElement,
            () => this.gridApi,
            () => this.columnApi
        );
    }

    public init(e: GridReadyEvent) {
        this.gridApi = e.api!;
        this.columnApi = e.columnApi!;
        AccessibleGridHack.hackTheGrid(e.api!);
        this.fixIndexesAndSize();
    }

    public destroy() {
        this.accessibleGridColumnMenu!.destroy();
    }

    public replaceFocusableComponent = (components: FocusableComponent[] | null) => {
        this.activeComponents = components === null ? this.DefaultFocusableComponents : components;
    };

    public gridOptions() {
        return {
            postProcessPopup: this.accessibleGridColumnMenu!.init,
            ensureDomOrder: true,
            rowBuffer: 0,
            sideBar: {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: this.strings.columns,
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'accessibleColumnsSideBar',
                        toolPanelParams: {
                            onKeydown: this.handleTabs,
                        },
                    },
                ],
                defaultToolPanel: '',
            },
            components: {
                headerRenderer: getAccessibleHeaderComp(this.strings),
                accessibleColumnsSideBar: AccessibilityColumnsPanel,
            },
            getMainMenuItems: (params: GetMainMenuItemsParams) => {
                return [
                    ...params.defaultItems,
                    'separator',
                    {
                        name: this.strings.grid$sortAsc,
                        action: () => {
                            this.setSortForColumnId(params.column.getId(), 'asc');
                        },
                    },
                    {
                        name: this.strings.grid$sortDesc,
                        action: () => {
                            this.setSortForColumnId(params.column.getId(), 'desc');
                        },
                    },
                    {
                        name: this.strings.grid$sortNone,
                        action: () => {
                            this.clearSortForAllColumns();
                        },
                    },
                ];
            },
            defaultColDef: {
                headerClass: 'kusto-query-header',
                headerComponent: 'headerRenderer',
                suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => params.event.keyCode === KeyCodes.tab,
            },
            onColumnEverythingChanged: this.fixIndexesAndSize,
            onViewportChanged: this.fixIndexesAndSize,
            onVirtualColumnsChanged: this.fixIndexesAndSize,
            onDisplayedColumnsChanged: this.fixIndexesAndSize,
            onBodyScroll: this.fixIndexesAndSize,
            localeTextFunc: (key: string, defaultValue: string = '') => {
                // to avoid key clash with external keys, we add 'grid$' to the start of each key.
                // keys will not be a part of LocalizedStrings
                let gridKey = 'grid$' + key;
                let value = (this.strings as any)[gridKey];
                return value ? value : defaultValue;
            },
        } as GridOptions;
    }

    public focusZoneProps = () =>
        ({
            isInnerZoneKeystroke: this.handleTabs,
            onBeforeFocus: (elem) => {
                this.ensureColVisibility(elem, true);
                return true;
            },
        } as IFocusZoneProps);

    public moveToGridTable = () => {
        if (!this.gridApi || !this.columnApi) {
            return;
        }

        const focusCell = this.gridApi.getFocusedCell();
        const focusRow = focusCell ? focusCell.rowIndex : 0;
        const focusCol = focusCell ? focusCell.column : this.getFirstColumn();

        if (focusCol) {
            this.gridApi.ensureIndexVisible(focusRow);
            this.gridApi.ensureColumnVisible(focusCol);
            setTimeout(() => this.gridApi!.setFocusedCell(focusRow, focusCol), 10);
        }
    };

    private handleTabs = (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Tab') {
            if (this.tabOnGrid(e.shiftKey)) {
                e.stopPropagation();
                e.preventDefault();
            }
        }
        return false;
    };

    private tabOnGrid = (prev: boolean): boolean => {
        const active = document.activeElement;
        const gridElement = this.getGridElement();
        if (!this.gridApi || !(active instanceof HTMLElement) || !gridElement) {
            return false;
        }
        const activeComponent = this.activeComponents.findIndex(({ isActive }) => isActive(active, this.gridApi!));
        if (activeComponent === -1) {
            return false;
        }
        let nextComponent = activeComponent + (prev ? -1 : 1);
        while (
            nextComponent >= 0 &&
            nextComponent < this.activeComponents.length &&
            this.activeComponents[nextComponent].isVisible &&
            !this.activeComponents[nextComponent].isVisible!(active, this.gridApi!)
        ) {
            nextComponent = nextComponent + (prev ? -1 : 1);
        }
        if (nextComponent >= 0 && nextComponent < this.activeComponents.length) {
            const { clearFocus } = this.activeComponents[activeComponent];
            const focusSelector = this.activeComponents[nextComponent].focus(active, this.gridApi);
            if (focusSelector) {
                const focusElem = gridElement.querySelector<HTMLElement>(focusSelector);
                if (focusElem) {
                    focusElem.focus();
                } else {
                    return false;
                }
            }
            if (clearFocus) {
                clearFocus(active, this.gridApi);
            }
            return true;
        }

        return false;
    };

    private fixIndexesAndSize = () => this.accessibleIndexesAndSize.debounceFix();

    private getFirstColumn = () => {
        const columns = this.columnApi!.getAllGridColumns();
        return columns && columns.length > 0 ? columns[0] : null;
    };

    private ensureColVisibility = (elem?: HTMLElement, plusBeforeAndAfter?: boolean) => {
        if (!this.gridApi || !this.columnApi) {
            return;
        }

        let column: Column | null = null;
        if (elem) {
            let colId = elem.getAttribute('col-id');
            if (!colId && elem.parentElement) {
                colId = elem.parentElement.getAttribute('col-id');
            }
            if (colId) {
                column = this.columnApi.getColumn(colId);
            }
        } else {
            const columns = this.columnApi.getAllDisplayedColumns();
            if (columns && columns.length > 0) {
                column = columns[0];
            }
        }

        if (column) {
            if (plusBeforeAndAfter) {
                const before = this.columnApi.getDisplayedColBefore(column);
                if (before) {
                    this.gridApi.ensureColumnVisible(before);
                }
                const after = this.columnApi.getDisplayedColAfter(column);
                if (after) {
                    this.gridApi.ensureColumnVisible(after);
                }
            } else {
                this.gridApi.ensureColumnVisible(column);
            }
        }
    };

    private setSortForColumnId = (colId: string, sort: ColumnState['sort']) => {
        if (!this.columnApi) return;

        const columnState = this.columnApi.getColumnState();
        const targetIndex = columnState.findIndex((currentColumnState) => currentColumnState.colId === colId);

        if (targetIndex !== -1) {
            columnState[targetIndex] = { ...columnState[targetIndex], sort };
            this.columnApi.applyColumnState({ state: columnState });
        }
    };

    private clearSortForAllColumns = () => {
        if (!this.columnApi) return;

        const columnState = this.columnApi.getColumnState();

        return columnState.map((currentColumn) => {
            const { sort: _, ...currentColumnState } = currentColumn;
            return currentColumnState;
        });
    };
}
