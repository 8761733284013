import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ActionButton, CommandBarButton, IButton, Icon } from 'office-ui-fabric-react';
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { RtdHeader } from '../../../../components';
import { useCore } from '../../../../core';
import { APP_STRINGS } from '../../../../res';
import { DashboardLoaded, useDashboardStore } from '../../../../store';

import { Pages } from './Pages';

import styles from './PagesNav.module.scss';

const appStringsLocal = APP_STRINGS.dashboardPage.pagesNav;

const TitleText: React.FC = () => (
    <RtdHeader className={styles.titleText} level={2}>
        {appStringsLocal.title}
    </RtdHeader>
);

const AddPageButton: React.FC<{ state: DashboardLoaded }> = React.memo(({ state }) => {
    const ref = useRef<IButton>(null);

    const onAddPage = action(() => {
        const page = {
            name: `${appStringsLocal.newPageName} ${state.pages.length + 1}`,
            id: uuid(),
        };
        state.addItem('pages', page);
        state.pagesNav.renamePage = {
            pageId: page.id,
            onFormClosed: () => {
                // This works, but focus indictor doesn't show sometimes up due to this fabric bug:
                // https://github.com/microsoft/fluentui/issues/12291
                ref.current?.focus();
            },
        };
    });

    return (
        <ActionButton
            componentRef={ref}
            className={styles.addPageButton}
            iconProps={{ iconName: 'add' }}
            onClick={onAddPage}
        >
            {appStringsLocal.addPageButtonText}
        </ActionButton>
    );
});

interface PinButtonProps {
    onToggle: () => void;
    pinned: boolean;
}

const PinUnpinButton: React.FC<PinButtonProps> = ({ onToggle, pinned }) => {
    return (
        <CommandBarButton onClick={onToggle} className={styles.pinUnpinButton}>
            <Icon className={styles.pinIcon} iconName={pinned ? 'unpin' : 'pinned'} />
            <div className={styles.openHeader}>
                <TitleText />
            </div>
            <Icon className={styles.openCloseChevron} iconName="ChevronRight" />
        </CommandBarButton>
    );
};

export interface PagesNavProps {
    state: DashboardLoaded;
}

export const PagesNav: React.FC<PagesNavProps> = observer(function PagesNav({ state }) {
    const store = useDashboardStore();
    const [menusOpen, setMenusOpen] = useState(0);
    const core = useCore();

    const toggleOpen = React.useMemo(
        () =>
            action(() => {
                core.userSettingsState.updateUserSetting(
                    'pageNavOpen',
                    !core.userSettingsState.userSettings.pageNavOpen,
                    store
                );
            }),
        [core, store]
    );

    const onMenuOpen = useCallback(() => setMenusOpen((o) => o + 1), []);
    const onMenuDismiss = useCallback(() => setMenusOpen((o) => o - 1), []);

    const visible = state.pages.length > 1 || state.changes;

    const ensureOpen = menusOpen !== 0 || state?.pagesNav.renamePage !== undefined;
    const pinnedOpen = core.userSettingsState.userSettings.pageNavOpen;

    return (
        <section
            className={styles.pagesNav}
            data-hidden={visible ? undefined : true}
            data-open={pinnedOpen ? true : undefined}
            data-ensure-open={ensureOpen ? true : undefined}
        >
            <div className={styles.pagesNavInner}>
                <PinUnpinButton onToggle={toggleOpen} pinned={pinnedOpen} />
                <div className={styles.closedContent}>
                    <div className={styles.closedHeader}>
                        <TitleText />
                    </div>
                </div>
                <div className={styles.openContent}>
                    <Pages pageState={state} onMenuOpen={onMenuOpen} onMenuDismiss={onMenuDismiss} />
                    {state.changes === undefined ? null : (
                        <>
                            <hr className={styles.footerDivider} />
                            <AddPageButton state={state} />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
});
