import { getClusterConnectionStringAndName } from '@kusto/common';
import { ungzip } from 'pako';
import { ParsedUrlQuery } from 'querystring';
import { Url } from 'url';
import { AppPages, DeepLinkDisabledAppPages, DeepLinkEnabledAppPages, EntityType } from '../common/types';
import { FeatureFlagMap, featureFlags } from '../FeatureFlags';
import { basePathName } from './url';

export interface DeepLinkProperties {
    appPage?: AppPages;
    deepLink: Url;
    clusterName?: string;
    databaseName?: string;
    tableName?: string;
    subPath?: string;
    connectionString?: string;
    query?: string;
    querySrc?: string;
    forget: boolean;
    language?: string;
    theme?: string;
    featureFlags: FeatureFlagMap;
    storeName?: string;
    workspaceName?: string;
    origin?: string;
}

export function getQueryParam(url: Url, queryParam: string): string | undefined {
    const result = url.query && (url.query as ParsedUrlQuery)[queryParam];
    const value = result ? (Array.isArray(result) ? result[0] : result) : undefined;

    return value;
}

function getBooleanQueryParam(deepLink: Url, queryParam: string): boolean {
    const value = getQueryParam(deepLink, queryParam);
    return value === 'true';
}

const ignoredPathPrefixes = (basePathName || '').split('/');
const enabledPathPrefixes: string[] = Object.values(DeepLinkEnabledAppPages);
const disabledPathPrefixes: string[] = Object.values(DeepLinkDisabledAppPages);

/**
 * Parse a deep link and extract useful information such as db and cluster in context.
 * Supports the following formats:
 * https://kusto.azure.com/clusters/${clusterName}/databases/${databaseName}/?query=${query}
 * https://kusto.azure.com/${clusterName}/${databaseName}/?query=${query}
 * https://kusto.azure.com/?cluster${clusterName}/${databaseName}/?query=${query}
 * https://kusto.azure.com/?cluster=${clusterName}&database=${databaseName}&q=${query}
 */
export function parseDeepLink(deepLink: Url): DeepLinkProperties {
    // Handle feature flag parsing
    // Each feature flag becomes a query param like: ShowShareMenu and ShowConnectionButtons
    // https://kusto.azure.com/?IFrameAuth=true&ShowFileMenu=true&ShowShareMenu=false

    const featureFlagMap: FeatureFlagMap = {};

    for (const featureFlag of featureFlags) {
        if (getQueryParam(deepLink, featureFlag)) {
            featureFlagMap[featureFlag] = getBooleanQueryParam(deepLink, featureFlag);
        }
    }

    const storeName = getQueryParam(deepLink, 'storeName');
    const theme = getQueryParam(deepLink, 'theme');
    const workspaceName = getQueryParam(deepLink, 'workspace');
    const origin = getQueryParam(deepLink, 'origin');

    const forget = getBooleanQueryParam(deepLink, 'forget');
    let language = getQueryParam(deepLink, 'l');
    if (language) {
        language = language.toLowerCase();
    }

    const baseLinkData: DeepLinkProperties = {
        deepLink,
        forget,
        language,
        featureFlags: featureFlagMap,
        storeName,
        theme,
        workspaceName,
        origin,
    };

    const pathname = deepLink.pathname;
    if (!pathname) {
        return baseLinkData;
    }

    let pathFragments = pathname.split('/');

    let appName: string | undefined = undefined;
    if (pathFragments) {
        // Cycle through ignored paths
        while (pathFragments.length > 0 && ignoredPathPrefixes.indexOf(pathFragments[0]) !== -1) {
            pathFragments.shift();
        }

        // To work with oneclick - remove last empty fragment
        if (pathFragments.length > 0 && pathFragments[pathFragments.length - 1] === '') {
            pathFragments.unshift();
        }

        if (pathFragments.length > 0 && disabledPathPrefixes.indexOf(pathFragments[0]) !== -1) {
            // Matches disabled path, return deep link defaults
            const appPage = getAppPage(pathFragments[0]);
            baseLinkData.appPage = appPage;
            return baseLinkData;
        }

        // Cycle through enabled paths (capture the last one)
        while (pathFragments.length > 0 && enabledPathPrefixes.indexOf(pathFragments[0]) !== -1) {
            appName = pathFragments.shift();
        }
    }

    const appPage = getAppPage(appName);
    baseLinkData.appPage = appPage;
    if (appPage === AppPages.OneClick) {
        if (pathFragments.length === 0) {
            return baseLinkData;
        }
        baseLinkData.subPath = pathFragments[0];
        pathFragments = pathFragments.slice(1);
    }

    const isNewUriScheme = pathFragments[0] === 'clusters';

    // We're supporting both the new more standard REST api schema:
    // https://kusto.azure.com/clusters/${clusterName}/databases/${databaseName}
    // but also the old weird uri scheme
    // https://kusto.azure.com/${clusterName}/${databaseName}

    // entitiesPrefixes are relevant only for the new uri scheme
    const entitiesPrefixes = {
        [EntityType.Cluster]: 'clusters',
        [EntityType.Database]: 'databases',
        [EntityType.Table]: 'tables',
    };
    // will populate with the entity values parsed from the URL
    const entityValues: { [entity in EntityType]?: string } = {};

    // Parse the entities from the path pragments
    for (const [entity, prefix] of Object.entries(entitiesPrefixes)) {
        if (isNewUriScheme) {
            if (pathFragments[0] === prefix) {
                entityValues[entity as EntityType] = pathFragments[1];
            } else {
                // If it's new uri scheme but the next fragment isn't an entity prefix, then stop parsing entities.
                break;
            }
        } else {
            entityValues[entity as EntityType] = pathFragments[0];
        }
        // remove the parsed entity from the path fragments
        pathFragments = pathFragments.slice(isNewUriScheme ? 2 : 1);
    }

    const rawCluster: string | undefined = getQueryParam(deepLink, 'cluster') || entityValues[EntityType.Cluster];

    const databaseName: string | undefined = decodeURIComponent(
        getQueryParam(deepLink, 'database') || entityValues[EntityType.Database] || ''
    );

    const tableName = entityValues[EntityType.Table];
    if (tableName) {
        baseLinkData.tableName = decodeURIComponent(tableName);
    }
    if (!baseLinkData.subPath) {
        baseLinkData.subPath = pathFragments.join('/');
    }

    // looks like there's no deep link here if we don't have a cluster name
    if (!rawCluster) {
        return baseLinkData;
    }

    // Normalize different supported references to a cluster to its alias.
    const { clusterName, connectionString } = getClusterConnectionStringAndName(rawCluster);
    if (!connectionString) {
        return baseLinkData;
    }

    // we support both 'q' and 'query' as parameters. q is for encoded gzipped queries. querty is for both.
    let query = getQueryParam(deepLink, 'query') || getQueryParam(deepLink, 'q');
    if (query) {
        // If this is a base64 gzipped query, do necessary work to decode and unzip it
        try {
            const replaced = query.replace(/ /g, '+');
            const decoded = atob(replaced);
            const unzipped: string = ungzip(decoded, { to: 'string' });
            query = unzipped;
        } catch (error) {
            // empty
        }
    }

    const querySrc = getQueryParam(deepLink, 'querysrc');

    return {
        appPage,
        clusterName,
        connectionString,
        databaseName,
        tableName: tableName ? decodeURIComponent(tableName) : undefined,
        query,
        querySrc,
        ...baseLinkData,
    };
}

/**
 * get the app page from url fragment.
 * @param appName the app name in url
 */
function getAppPage(appName: string | undefined) {
    if (appName === undefined) {
        return AppPages.Explorer;
    }

    // turns out string enums don't have reverse lookups in typescript.
    return Object.values(AppPages).indexOf(appName as AppPages) !== -1 ? (appName as AppPages) : AppPages.Explorer;
}
