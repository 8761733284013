import * as React from 'react';
import { useCallback } from 'react';
import { tagScalar, TDuration, DurationParamValue } from '../../../domain';

import { DurationPicker } from '../DurationPicker';

import { IParameterSelectorProps2 } from './types';

interface IDurationParameterSelectorProps extends IParameterSelectorProps2 {
    parameterValue: DurationParamValue;
    onChange(value: DurationParamValue): void;
}

export const DurationParameterSelector: React.FC<IDurationParameterSelectorProps> = ({
    parameterValue,
    onChange,
    isActive,
    variableNames,
}) => {
    const onDurationUpdate = useCallback(
        (duration: TDuration) => {
            onChange(new DurationParamValue(parameterValue.config, tagScalar('duration', duration)));
        },
        [parameterValue.config, onChange]
    );

    return (
        <DurationPicker
            name={parameterValue.config.displayName}
            duration={parameterValue.data.value}
            onDurationUpdate={onDurationUpdate}
            isPill
            variableNames={variableNames}
            disabled={!isActive}
        />
    );
};
