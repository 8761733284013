import * as React from 'react';
import {
    getDataItemsAndMetaData,
    ColumnType,
    ArgumentColumnType,
    VisualizationOptions,
    DataItem,
} from '@kusto/charting';
import { ErrorSnackbar, formatLiterals, Theme, v1TypeToKustoType, TaggedKustoValue } from '@kusto/common';
import {
    DisplayMode,
    InternalChartProps,
    LegendPosition,
    Rows,
    Columns,
    Strings,
    QueryResultRowObject,
} from '../@types/chart.types';
import { Highchart } from '../highcharts/Highchart';
import { KustoAzureMap } from '../map/KustoAzureMap';
import {
    ExtendedVisualizationOptions,
    ExtendedVisualizationType,
    isSupportedByDesktopChartingLogic,
} from '../utils/visualization';
import { WebOnlyChart } from './WebOnlyChart';
import { MessageBarType } from 'office-ui-fabric-react';

export interface DataItemWithRow extends DataItem {
    row?: QueryResultRowObject;
}

// Highchart only support timezone if moment is available from "window.moment".
// See https://github.com/highcharts/highcharts/issues/8661.
// moment-timezone is 32k, so load it lazily, so it will be download as a separate chunk/file.
import('moment-timezone').then((m) => ((window as any).moment = m));

export interface ChartProps {
    /**
     * Kusto visualization options as supported by Kusto render command
     */
    visualizationOptions: ExtendedVisualizationOptions;

    /**
     * A bunch of strings that the component displays upon errors
     */
    strings: Strings;
    rows: Rows;
    columns: Columns;
    displayMode?: DisplayMode;
    theme?: Theme;
    useBoost?: boolean;
    showAllSeriesOnHover?: boolean;
    disableAnimation?: boolean;
    events?: Partial<ChartEvents>;
    azureMapSubscriptionKey?: string;
    timezone?: string;
    WarningComponent?: React.ComponentType<{ message: string }>;
}

// taken from https://www.figma.com/file/PASyBiBq72G54ckrtgs86uE7/Azure-Framework-Design-Specs?node-id=3739%3A14
const colorsLight = [
    '#0078D4', // 1
    '#EF6950', // 2
    '#00188F', // 3
    '#00A2AD', // 4
    '#4B003F', // 5
    '#E3008C', // 6
    '#022F22', // 7
    '#917EDB', // 8
    '#001D3F', // 9
    '#502006', // 10
    '#00439B', // 11
    '#A32D21', // 12
    '#5A6BBC', // 13
    '#006875', // 14
    '#895682', // 15
];

const colorsDark = [
    '#0078D4', // 1
    '#CA4A39', // 2
    '#BEDFFF', // 3
    '#038387', // 4
    '#F6C0FF', // 5
    '#D60084', // 6
    '#A4E1D2', // 7
    '#6E5DB7', // 8
    '#C7D3FF', // 9
    '#FFCA8A', // 10
    '#62B2EE', // 11
    '#E89387', // 12
    '#5493BA', // 13
    '#61B9BC', // 14
    '#AA72B3', // 15
];

export type ChartError =
    | 'NoRows'
    | 'ServerSideError'
    | 'LessThen2Columns'
    | 'DotNetLibraryHeuristicReturnedError'
    | 'DotNetLibraryHeuristicReturnedNoData'
    | 'UnknownVisualization';

export interface ChartEvents {
    presentingError: (error: ChartError, visualization: string) => void;
    presentingChart: (rowsCount: number, visualization: string) => void;
    /**
     * the charting library will call this function when it displays less results than what the data has.
     * a typical case for this is multistat visualization that has room for up until 9 results, but the query may return with more.
     * This callback enables the hosting application to display some kind of notification to the user about this.
     */
    setResultCounts: (info: { displayedResults: number; totalResults: number } | null) => void;

    onClickPoint: (value: {
        getX: () => TaggedKustoValue;
        getY: () => TaggedKustoValue;
        getSeries: () => TaggedKustoValue;
    }) => void;

    onClickLegend: (value: TaggedKustoValue) => void;
}

export const Chart = ({
    columns,
    rows,
    visualizationOptions,
    displayMode,
    theme,
    useBoost,
    disableAnimation,
    showAllSeriesOnHover,
    strings,
    events = {},
    azureMapSubscriptionKey,
    timezone,
    WarningComponent = (props: { message: string; children?: React.ReactNode }) => (
        <ErrorSnackbar level={MessageBarType.warning} {...props} />
    ),
}: ChartProps) => {
    const createNotEnoughColumnsMessage = (minNumberOfColumns: number, visualizationType: ExtendedVisualizationType) =>
        formatLiterals(strings.chartsErrorsMinColumns, {
            minNumberOfColumns: minNumberOfColumns,
            visualizationType: visualizationType,
        });

    const visualizationType = visualizationOptions.Visualization ? visualizationOptions.Visualization : 'barchart';
    const colors = theme === Theme.Dark ? colorsDark : colorsLight;
    if (!rows || !columns) {
        if (events.presentingError) {
            events.presentingError('ServerSideError', visualizationType);
        }
        return (
            <WarningComponent
                message={formatLiterals(strings.chartsErrorsNoElements, {
                    elements: rows ? strings.lowercaseColumns : strings.lowercaseRows,
                })}
            />
        );
    }

    if (rows.length === 0) {
        if (events.presentingError) {
            events.presentingError('NoRows', visualizationType);
        }
        return <WarningComponent message={strings.chartsErrorsNoResult} />;
    }

    const animationDuration = disableAnimation ? 0 : 500;

    let dataItems: DataItemWithRow[] | undefined;
    let argumentType: ArgumentColumnType | undefined;
    let metaData: Kusto.Charting.IChartMetaData | undefined;

    if (!isSupportedByDesktopChartingLogic(visualizationOptions)) {
        if (events?.presentingChart) {
            events.presentingChart(rows.length, visualizationType);
        }
        return (
            <WebOnlyChart
                rows={rows}
                columns={columns}
                visualizationOptions={visualizationOptions}
                setResultCounts={events?.setResultCounts}
                strings={strings}
                timezone={timezone}
            />
        );
    }

    try {
        const {
            items,
            argumentType: _argumentType,
            metaData: _metaData,
        } = getDataItemsAndMetaData(
            columns.map((col) => {
                // Work around the fact that control commands use v1 endpoint and return types in
                // a different field and format
                const colType = col.columnType || (col.dataType && v1TypeToKustoType[col.dataType]);

                return {
                    ColumnName: col.field,
                    ColumnType: colType as ColumnType,
                };
            }),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rows as any,
            // Cast must succeed due to check above
            visualizationOptions as VisualizationOptions
        );
        dataItems = items;
        argumentType = _argumentType;
        metaData = _metaData ?? undefined;
    } catch (ex) {
        if (events.presentingError) {
            events.presentingError('DotNetLibraryHeuristicReturnedError', visualizationType);
        }
        return <WarningComponent message={ex.message} />;
    }

    if (!dataItems || (Array.isArray(dataItems) && dataItems.length === 0)) {
        if (events.presentingError) {
            events.presentingError('DotNetLibraryHeuristicReturnedNoData', visualizationType);
        }
        return (
            <WarningComponent
                message={formatLiterals(strings.chartsErrorsNoDraw, {
                    visualizationType: visualizationType,
                })}
            />
        );
    }

    if (metaData && !metaData?.IsDataFormedAsSeries) {
        for (let i = 0; i < dataItems.length; i++) {
            dataItems[i].row = rows[i];
        }
    }

    const tooltipStyle = theme === Theme.Dark ? { backgroundColor: 'black' } : undefined;
    const tickStyle = theme === Theme.Dark ? { fill: 'white' } : { fill: 'black' };
    const legendPosition: LegendPosition = displayMode === 'narrow' ? 'bottom' : 'right';

    const { onClickPoint, onClickLegend } = events;

    const eventHandlers: Pick<InternalChartProps, 'onClickPoint' | 'onClickLegend'> = {
        onClickPoint:
            onClickPoint &&
            ((dataItem) => {
                onClickPoint({
                    getSeries() {
                        if (!dataItem.row) {
                            throw new Error('Missing row. Is this data series formatted?');
                        }
                        throw new Error('Unsupported until we can figure out how to get series column');
                    },
                    getX() {
                        if (!dataItem.row) {
                            throw new Error('Missing row. Is this data series formatted?');
                        }
                        if (!metaData) {
                            throw new Error('Missing metadata');
                        }
                        const column = columns[metaData.ArgumentDataColumnIndex];
                        return {
                            value: dataItem.row[column.headerName],
                            dataType: column.columnType,
                        };
                    },
                    getY() {
                        if (!dataItem.row) {
                            throw new Error('Missing row. Is this data series formatted?');
                        }
                        const column = columns.find((c) => c.headerName === dataItem.ValueName);
                        if (column === undefined) {
                            throw new Error(`Missing column ${dataItem.SeriesName}`);
                        }
                        return {
                            value: dataItem.row[column.headerName],
                            dataType: column.columnType,
                        };
                    },
                });
            }),
        onClickLegend:
            onClickLegend &&
            ((dataItem) => {
                if (!dataItem.row) {
                    throw new Error('Missing row. Is this data series formatted?');
                }
                if (!metaData) {
                    throw new Error('Missing metadata');
                }
                throw new Error('Unsupported until we can figure out how to get series column');
            }),
    };

    const chartBaseProps: InternalChartProps = {
        dataItems,
        colors,
        animationDuration,
        visualizationOptions,
        tooltipStyle,
        tickStyle,
        argumentType,
        legendPosition,
        isDarkTheme: theme === Theme.Dark,
        highPerformanceMode: useBoost,
        showAllSeriesOnHover,
        azureMapSubscriptionKey,
        locale: strings.locale,
        timezone: timezone,
        ...eventHandlers,
    };

    if (visualizationType !== 'card' && visualizationType !== 'table' && columns.length < 2) {
        if (events?.presentingError) {
            events.presentingError('LessThen2Columns', visualizationType);
        }
        return <WarningComponent message={createNotEnoughColumnsMessage(2, visualizationType)} />;
    }

    if (events?.presentingChart) {
        events.presentingChart(rows.length, visualizationType);
    }

    switch (visualizationType) {
        case 'barchart':
        case 'columnchart':
            return <Highchart {...chartBaseProps} />;
        case 'piechart':
            if (visualizationOptions.Kind === 'map') {
                return <KustoAzureMap {...chartBaseProps} />;
            }

            return <Highchart {...chartBaseProps} />;
        case 'anomalychart':
            if (!visualizationOptions.AnomalyColumns) {
                const message = formatLiterals(strings.chartsErrorsAnomalycolumns, {
                    example:
                        'T | extend anom = series_decompose_anomalies(sum_y, 3.0)' +
                        '| render anomalychart with(anomalycolumns =anom)',
                });
                return <WarningComponent message={message} />;
            }
            return <Highchart {...chartBaseProps} />;
        case 'timechart':
        case 'linechart':
            return <Highchart {...chartBaseProps} />;
        case 'stackedareachart':
            chartBaseProps.visualizationOptions = {
                ...chartBaseProps.visualizationOptions!,
                Kind: 'stacked',
            };

            return <Highchart {...chartBaseProps} />;
        case 'areachart':
            return <Highchart {...chartBaseProps} />;
        case 'scatterchart':
            if (visualizationOptions.Kind === 'map') {
                return <KustoAzureMap {...chartBaseProps} />;
            }

            return <Highchart {...chartBaseProps} />;
        default:
            if (events?.presentingError) {
                events.presentingError('UnknownVisualization', visualizationType);
            }
            return (
                <WarningComponent
                    message={formatLiterals(strings.chartsErrorsUnknownVisualization, {
                        visualizationType: visualizationType,
                    })}
                />
            );
    }
};
