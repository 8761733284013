import type { IDataSourceV0 } from '../dataSource/versions/0';
import type { IParameterV0 } from '../parameter/versions/0';
import type { ITileV0 } from '../tile/versions/0';

import type { IDashboardV0 } from './0';

export interface IDashboardV1 extends Omit<IDashboardV0, '$schema' | 'tiles' | 'dataSources' | 'parameters'> {
    $schema: '1.0';
    parameters: IParameterV0[];
    tiles: ITileV0[];
    dataSources: IDataSourceV0[];
}

export function up(prev: IDashboardV0): IDashboardV1 {
    return {
        // Add initial version numbers for existing children models
        ...prev,
        $schema: '1.0',
        parameters:
            prev.parameters?.map((p) => ({
                $schema: '0',
                ...p,
            })) ?? [],
        tiles:
            prev.tiles?.map((t) => ({
                $schema: '0',
                ...t,
            })) ?? [],
        dataSources:
            prev.dataSources?.map((d) => ({
                $schema: '0',
                ...d,
            })) ?? [],
    };
}
