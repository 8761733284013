import { runInAction } from 'mobx';
import { useRef, useEffect, useCallback } from 'react';
import React from 'react';

import { IRtdDashboardsCore } from '../../../../core';
import { Result, useCurrent } from '../../../../common';
import { UDataSource } from '../../../../core/domain';
import {
    useStreamingObserver,
    StreamErrorReason,
    KustoQueryResult,
    streamResultOk,
    streamResultNotRun,
    streamResultDidRunError,
    streamResultCannotRun,
} from '../../../query';
import { DashboardStore } from '../../../../store/dashboard';
import { RtdValue } from '../../../value';
import { APP_STRINGS } from '../../../../res';
import { TimeZone } from '../../../timezone';

import { BasicParam, BasicParamConfig, ParameterValue } from '../../models';

import {
    convertToParameterResult,
    observeQueryParameterOptions,
    requestQueryParameterUpdate,
    StreamParameterResult,
} from '../../convertToParameterResult';

/**
 * Executes the provided parameter, attaching to the query data stream if it already exists. If this parameter has static options, it simply returns those options
 *
 * **Note**: This hook is mirrored in `useExecuteQuery`, with the exception that it follows a different data path
 * @param parameterConfig The parameter to execute
 */
export function useExecuteParameter<T>(
    core: IRtdDashboardsCore,
    dashboardStore: DashboardStore,
    parameterConfig: BasicParamConfig<T, RtdValue.BasicType>,
    usedParameterValues: Result<ParameterValue[], StreamErrorReason>,
    timeZone: TimeZone
): [StreamParameterResult<T>, () => void] {
    const dataSource =
        parameterConfig.options.selectionKind !== 'freetext' &&
        parameterConfig.options.dataSource.kind === 'query' &&
        parameterConfig.options.dataSource.dataSourceId
            ? dashboardStore.state?.dataSourcesRecord[parameterConfig.options.dataSource.dataSourceId]
            : undefined;

    const currentParameter = useCurrent(parameterConfig);

    const lastDataSources = useRef<
        | {
              parameterDataSource: BasicParam.QueryDataSource;
              dataSource: UDataSource;
              usedParameterValues: ParameterValue[];
          }
        | undefined
    >(undefined);

    const { result: streamResult, observer, setState } = useStreamingObserver<KustoQueryResult>();

    const refresh = useCallback(() => {
        if (lastDataSources.current && currentParameter.current) {
            requestQueryParameterUpdate(
                core.queryService,
                currentParameter.current.id,
                lastDataSources.current.parameterDataSource,
                lastDataSources.current.dataSource,
                lastDataSources.current.usedParameterValues
            );
        }
    }, [currentParameter, core]);

    const optionsSource =
        parameterConfig.options.selectionKind !== 'freetext' ? parameterConfig.options.dataSource : undefined;

    useEffect(() => {
        const dashboardId = runInAction(() => dashboardStore.state?.meta.id);
        if (!parameterConfig || dashboardId === undefined || optionsSource?.kind !== 'query') {
            setState(streamResultNotRun);

            return;
        }
        if (!dataSource) {
            setState(
                streamResultCannotRun({
                    title: APP_STRINGS.domain.parameter.errors.query.failedToResolveDataSource,
                })
            );
            return;
        }

        if (usedParameterValues.kind === 'err') {
            setState(streamResultCannotRun(usedParameterValues.err));
            return;
        }

        const startTime = new Date();

        const options = observeQueryParameterOptions(
            core.queryService,
            parameterConfig.id,
            dashboardId,
            optionsSource,
            dataSource,
            usedParameterValues.value,
            lastDataSources.current
        );

        lastDataSources.current = {
            parameterDataSource: optionsSource,
            dataSource,
            usedParameterValues: usedParameterValues.value,
        };
        const subscription = options.subscribe(observer(startTime));

        return () => subscription.unsubscribe();
    }, [dashboardStore, usedParameterValues, dataSource, observer, setState, core, optionsSource, parameterConfig]);

    const result: StreamParameterResult<T> = React.useMemo(() => {
        if (optionsSource?.kind === 'static') {
            const timestamp = new Date();
            return streamResultOk(optionsSource.values, {
                executionId: undefined,
                kind: 'done',
                startTime: timestamp,
                endTime: timestamp,
            });
        }
        if (streamResult.kind !== 'success') {
            return streamResult;
        }

        if (optionsSource === undefined) {
            // TODO: Log
            // eslint-disable-next-line no-console
            console.error(`Unexpectedly missing data source ${dataSource}`);
            return streamResultNotRun;
        }

        const converted = convertToParameterResult<T>(
            parameterConfig.dataType,
            optionsSource,
            streamResult.result,
            core.queryService,
            timeZone
        );
        if (converted.kind === 'err') {
            return streamResultDidRunError(
                { title: converted.err },
                streamResult.result.receivedTime,
                streamResult.status
            );
        }
        return streamResultOk(converted.value, streamResult.status);
    }, [optionsSource, streamResult, parameterConfig, core, timeZone, dataSource]);
    return [result, refresh];
}
