import { dataSource } from '../dataSource';
import { parameter } from '../parameter';
import { tile } from '../tile';
import { AutoRefreshConfigV1_2 } from './1-2';

import { IDashboardV13 } from './13';
import { IPageV3 } from './3';

/** Removed backend generated properties that were getting saved unnecessarily */
export interface IDashboardV14 {
    $schema: '14';
    title: string;

    pinnedParameters: string[];
    autoRefresh?: AutoRefreshConfigV1_2;

    pages: IPageV3[];
    tiles: tile.v7.ITileV7[];
    parameters: parameter.v2.IParameterV2[];
    dataSources: dataSource.v2.UDataSourceV2[];
}

export function up(prev: IDashboardV13): IDashboardV14 {
    return {
        $schema: '14',
        title: prev.title,
        pinnedParameters: prev.pinnedParameters,
        autoRefresh: prev.configuration?.autoRefresh,
        pages: prev.pages,
        tiles: prev.tiles,
        parameters: prev.parameters,
        dataSources: prev.dataSources,
    };
}

export function down({ autoRefresh, ...prev }: IDashboardV14, _warnings: string[]): IDashboardV13 {
    return {
        ...prev,
        configuration: autoRefresh && { autoRefresh },
        $schema: '13',
    };
}
