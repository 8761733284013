import { dateToString, err, formatLiterals, isValidDate, ok, Result } from '../../../../common';
import { TRtdValue, Duration, tagScalar, fixedDuration, TRtdType, RtdValue } from '../../../value';
import { APP_CONSTANTS, APP_STRINGS } from '../../../../res';

import { IParameterConfig, IParameterValue, Parameter } from '../types';

const parameterStrings = APP_STRINGS.domain.parameter;

const dynamicRangeRegex = /([0-9]+)([a-z]+)/;

export function parseSerializedDynamicRange(start: string, end: string): Duration.Dynamic | undefined {
    const lowercaseStart = start.toLowerCase();
    const lowercaseEnd = end.toLowerCase();

    if (lowercaseEnd !== 'now') {
        // We currently only support "now" for the end stop
        return undefined;
    }

    if (lowercaseStart === 'now') {
        // See above
        return undefined;
    }

    const startMatches = lowercaseStart.match(dynamicRangeRegex);

    if (!startMatches || startMatches.length !== 3) {
        return undefined;
    }

    const count = parseInt(startMatches[1], 10);
    const unit = startMatches[2] as Duration.TimeUnit;

    if (isNaN(count)) {
        return undefined;
    }

    return APP_CONSTANTS.durationPicker.dynamicOptions.find(([_, d]) => d.count === count && d.unit === unit)?.[1];
}

const consumedVariableNames = new Set<string>();

export class DurationParamConfig implements IParameterConfig {
    readonly kind = 'duration' as const;
    readonly defaultValue: DurationParamValue;
    readonly variableNames: [string, string];

    constructor(
        readonly id: string,
        readonly beginVariableName: string,
        readonly endVariableName: string,
        readonly displayName: string,
        defaultValue: RtdValue.TaggedDuration
    ) {
        this.defaultValue = new DurationParamValue(this, defaultValue);
        this.variableNames = [this.beginVariableName, this.endVariableName];
    }

    consumedVariableNames() {
        return consumedVariableNames;
    }

    canApply(...types: TRtdType[]) {
        return types.every((t) => t[0] === 'duration');
    }

    parseUrlStrings(strings: Parameter.UrlSerialized): undefined | Result<DurationParamValue> {
        const startString = strings[this.beginVariableName][0];
        const endString = strings[this.endVariableName][0];

        if (!startString) {
            if (!endString) {
                return undefined;
            }

            return err(
                `${APP_STRINGS.domain.parameter.errors.duration.parseUrlMissingVariable} "${this.beginVariableName}"`
            );
        }

        if (!endString) {
            if (!endString) {
                return undefined;
            }

            return err(
                `${APP_STRINGS.domain.parameter.errors.duration.parseUrlMissingVariable} "${this.endVariableName}"`
            );
        }

        const start = new Date(startString);
        const end = new Date(endString);

        if (!isValidDate(start) || !isValidDate(end)) {
            // Both values must be valid
            // Check for relative
            const dynamicValue = parseSerializedDynamicRange(startString, endString);

            if (dynamicValue) {
                return ok(new DurationParamValue(this, tagScalar('duration', dynamicValue)));
            }

            return err(parameterStrings.errors.duration.dynamicRangeUrlParseError);
        }

        return ok(new DurationParamValue(this, tagScalar('duration', fixedDuration(start, end))));
    }
    tryCreateParamValue(value: TRtdValue): Result<DurationParamValue> {
        if (value.kind === 'null') {
            return err(parameterStrings.errors.notNullable);
        }

        if (value.tag !== 'duration') {
            return err(
                formatLiterals(parameterStrings.errors.duration.unexpectedValue, {
                    type: APP_STRINGS.domain.value.dataTypes[value.tag],
                })
            );
        }
        return ok(new DurationParamValue(this, value));
    }
}

export class DurationParamValue implements IParameterValue {
    readonly kind = 'duration' as const;

    constructor(readonly config: DurationParamConfig, readonly data: RtdValue.TaggedDuration) {}

    toUrlStrings(): Parameter.UrlSerialized {
        if (this.data.value.kind === 'fixed') {
            return {
                [this.config.beginVariableName]: [dateToString(this.data.value.start)],
                [this.config.endVariableName]: [dateToString(this.data.value.end)],
            };
        } else {
            return {
                [this.config.beginVariableName]: [`${this.data.value.count}${this.data.value.unit}`],
                [this.config.endVariableName]: ['now'],
            };
        }
    }
    equals(value: IParameterValue) {
        if (!(value instanceof DurationParamValue)) {
            return false;
        }

        if (value.data.value.kind === 'dynamic' && this.data.value.kind === 'dynamic') {
            return value.data.value.count === this.data.value.count && value.data.value.unit === this.data.value.unit;
        }
        if (value.data.value.kind === 'fixed' && this.data.value.kind === 'fixed') {
            return value.data.value.start === this.data.value.start && value.data.value.end === this.data.value.end;
        }
        return false;
    }
}
