import { Subject } from 'rxjs';
import { ResourceTypes } from '../types';
import {
    IResourceMetadata,
    IResourceMetadataStore,
    IResourceMetadataMap,
    IResourceMetadataNotification,
    IResourceMetadataData,
    IResourceState,
} from './types';

export class ResourceMetadata {
    private metadata: IResourceMetadataStore = {};

    public subject = new Subject<IResourceMetadataNotification>();

    public push<T extends ResourceTypes>(metadata: IResourceMetadata<T>) {
        const type = metadata.type;

        // Cast to make sure generic is understood
        const currentMetadataForType = this.metadata[type] as IResourceMetadataMap<T> | undefined;

        const newMetadataForType: IResourceMetadataMap<T> = currentMetadataForType ? { ...currentMetadataForType } : {};

        // More stupid TypeScript
        this.metadata[type] = newMetadataForType as IResourceMetadataStore[T];

        newMetadataForType[metadata.id] = {
            ...metadata,
        };

        this.subject.next({
            type,
            metadata,
            action: 'push',
        });
    }

    public update<T extends ResourceTypes, F extends keyof IResourceMetadataData<T> = keyof IResourceMetadataData<T>>(
        type: T,
        id: string,
        field: F,
        value: IResourceMetadata<T>[F]
    ) {
        const existingMetadata = this.get<T>(type, id);

        const newMetadata: IResourceMetadata<T> = existingMetadata
            ? { ...existingMetadata, [field]: value }
            : {
                  type,
                  id,
                  status: IResourceState.unstarted,
                  [field]: value,
              };

        // Create/replace
        this.push<T>(newMetadata);
    }

    public clear<T extends ResourceTypes>(type: T, id: string) {
        // Cast to make sure generic is understood
        const currentMetadataForType = this.metadata[type] as IResourceMetadataMap<T> | undefined;

        if (!currentMetadataForType || !currentMetadataForType[id]) {
            return;
        }

        const metadata = currentMetadataForType[id];

        if (!metadata) {
            return;
        }

        const newMetadataForType = {
            ...currentMetadataForType,
        };

        delete newMetadataForType[id];

        // More stupid TypeScript
        this.metadata[type] = newMetadataForType as IResourceMetadataStore[T];

        this.subject.next({
            type,
            metadata,
            action: 'remove',
        });
    }

    public getType<T extends ResourceTypes>(type: T): IResourceMetadataMap<T> | undefined {
        return this.metadata[type] as IResourceMetadataMap<T> | undefined;
    }

    public get<T extends ResourceTypes>(type: T, id: string): IResourceMetadata<T> | undefined {
        const currentMetadataForType = this.getType(type);

        if (!currentMetadataForType) {
            return;
        }

        return currentMetadataForType[id];
    }

    public getAllActiveTypes(): ResourceTypes[] {
        return Object.keys(this.metadata) as ResourceTypes[];
    }
}
