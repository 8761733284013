import { runInAction } from 'mobx';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useVisualConfig } from '../../domain/visualConfig/context';

import { dashboardStoreContext } from './context';
import { DashboardStore } from './DashboardStore';

export const DashboardStoreProvider: React.FC = ({ children }) => {
    const visualConfig = useVisualConfig();
    const [value] = useState(() => new DashboardStore(visualConfig));

    useEffect(() => () => value.dispose(), [value]);

    useEffect(
        () =>
            runInAction(() => {
                value.visualConfig = visualConfig;
                if (value.state) {
                    value.state.visualConfig = visualConfig;
                }
            }),
        [value, visualConfig]
    );

    return <dashboardStoreContext.Provider value={value}>{children}</dashboardStoreContext.Provider>;
};
