import * as React from 'react';
import { Text } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../res';

import styles from './VisualConfig.module.scss';

export const NoVisualSelected = (
    <div className={styles.noVisualSelected}>
        <div className={styles.noVisualSelectedImage} />
        <Text as="p">{APP_STRINGS.editTilePage.visualConfig.noVisualSelected}</Text>
    </div>
);
