import * as React from 'react';
import { Icon, TooltipHost } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { WhiteShadowContent } from '../../../lib';
import { APP_STRINGS } from '../../../../res';
import { useDashboardStore } from '../../../../store/dashboard';

import { OverflowMenu, IOverflowMenuItem } from './overflow';

import styles from './ManageModelPreview.module.scss';

type UnableToDetermine = undefined;

type ManageModelPreviewStatusTypes =
    | {
          type: 'tiles';
          usedTileIds: string[] | UnableToDetermine;
          alwaysDisplay?: boolean;
      }
    | {
          type: 'pages';
          // TODO: Switch to number of used pages
          isPinned: boolean;
      }
    | {
          type: 'parameters';
          usedParameterIds: string[] | UnableToDetermine;
      };

interface ManageModelPreviewProps {
    title: string;
    variableText?: string;

    overflowAriaLabel?: string;
    items?: IOverflowMenuItem[];
    menuItems?: IOverflowMenuItem[];

    /**
     * An array of status items to support dynamic configuration and reordering
     */
    statusItems: ManageModelPreviewStatusTypes[];
}

export const ManageModelPreview: React.FC<ManageModelPreviewProps> = ({
    title,
    variableText,
    overflowAriaLabel,
    items,
    menuItems,
    statusItems,
}) => (
    <WhiteShadowContent elevation={1} className={styles.wrapper}>
        <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <OverflowMenu
                className={styles.overflowMenuButton}
                items={items}
                overflowItems={menuItems}
                overflowButtonProps={overflowAriaLabel ? { ariaLabel: overflowAriaLabel } : undefined}
            />
        </div>
        <div className={styles.variables}>{variableText ?? ''}</div>
        <div className={styles.statusBar}>{statusItems.map(componentForStatusItem)}</div>
    </WhiteShadowContent>
);

const componentForStatusItem = (statusItem: ManageModelPreviewStatusTypes): JSX.Element | null => {
    switch (statusItem.type) {
        case 'tiles': {
            return statusItem.alwaysDisplay ||
                (statusItem.usedTileIds !== undefined && statusItem.usedTileIds.length > 0) ? (
                <TileNameTooltip key="tiles" tileIds={statusItem.usedTileIds}>
                    <Icon iconName="BarChartVertical" />
                    <div className={styles.text}>{formatVisualCount(statusItem.usedTileIds?.length ?? 0)}</div>
                </TileNameTooltip>
            ) : null;
        }
        case 'parameters': {
            return statusItem.usedParameterIds !== undefined && statusItem.usedParameterIds.length > 0 ? (
                <ParameterNameTooltip key="parameters" parameterIds={statusItem.usedParameterIds}>
                    <Icon iconName="Filter" />
                    <div className={styles.text}>{formatParameterCount(statusItem.usedParameterIds.length)}</div>
                </ParameterNameTooltip>
            ) : null;
        }
        case 'pages': {
            return statusItem.isPinned ? (
                <div key="pages">
                    <Icon iconName="Pinned" />
                    <div className={styles.text}>{APP_STRINGS.forms.parameter.pinned}</div>
                </div>
            ) : null;
        }
    }
};

const formatVisualCount = (length: number) =>
    length !== 1
        ? `${length} ${APP_STRINGS.forms.parameter.visualPlural}`
        : `1 ${APP_STRINGS.forms.parameter.visualSingular}`;

const formatParameterCount = (length: number) =>
    length !== 1
        ? `${length} ${APP_STRINGS.forms.parameter.filterPlural}`
        : `1 ${APP_STRINGS.forms.parameter.filterSingular}`;

const TileNameTooltip: React.FC<{
    tileIds?: string[];
}> = observer(function TileNameTooltip({ tileIds, children }) {
    const state = useDashboardStore().state;
    return (
        <TooltipHost
            content={!tileIds || !state ? undefined : tileIds.map((id) => state.tilesRecord[id].title).join(', ')}
        >
            {children}
        </TooltipHost>
    );
});

const ParameterNameTooltip: React.FC<{
    parameterIds?: string[];
}> = observer(function ParameterNameTooltip({ parameterIds, children }) {
    const state = useDashboardStore().state;

    return (
        <TooltipHost
            content={
                !parameterIds || !state
                    ? undefined
                    : parameterIds
                          .map((id) => {
                              const parameter = state.parametersRecord[id];
                              return parameter.displayName ?? parameter.defaultValue;
                          })
                          .join(', ')
            }
        >
            {children}
        </TooltipHost>
    );
});
