import * as React from 'react';
import { Text, PrimaryButton } from 'office-ui-fabric-react';

import { FullHeightWhiteWrapper } from '../../../components/PageContent/FullHeightWhiteWrapper';
import { APP_STRINGS } from '../../../res';

import styles from './MSAError.module.scss';

export const MSAError: React.FC = () => {
    return (
        <FullHeightWhiteWrapper className={styles.wrapper}>
            <div className={styles.msaError}>
                <Text>{APP_STRINGS.authentication.msaSignedIn}</Text>
                <br />
                <div className={styles.actions}>
                    <PrimaryButton href="https://docs.microsoft.com/en-us/azure/data-explorer/">
                        {APP_STRINGS.utilButtons.learnMore}
                    </PrimaryButton>
                </div>
            </div>
        </FullHeightWhiteWrapper>
    );
};
