import * as React from 'react';
import { ICommandBarProps, CommandBar, ICommandBarStyles, IStyle } from 'office-ui-fabric-react';
import { useEffect, useState } from 'react';

export const RTDCommandBar: React.FC<ICommandBarProps> = (props) => {
    /**
     * CommandBar items disabled property isn't updating when new props are passed
     * to it for some reason. The below code is meant to be a (very) temporary fix.
     * This code will destroy the command bar and re-create it whenever it's props
     * change.
     */
    const [, setUnused] = useState(0);

    useEffect(() => {
        setUnused((value) => value + 1);
    }, [props]);
    return <CommandBar styles={styles} {...props} />;
};

const setStyles: IStyle = {
    margin: '0 -4px',
    selectors: {
        '.ms-OverflowSet-item': {
            selectors: {
                button: { padding: '0 8px' },
            },
        },
    },
};

const styles: ICommandBarStyles = {
    root: {
        borderBottom: `1px solid var(--neutralLight)`,
        height: '40px',
        fontSize: '13px',
    },
    primarySet: setStyles,
    secondarySet: setStyles,
};
