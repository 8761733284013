import { assertNever } from '../../../../common/function';

import { PrimitiveParameterStaticOptionsV1, PrimitiveParameterTypeV1, TimeUnitsV1 } from './1-0';
import { ISelectedValuesV1_1 } from './1-1';
import { IParameterV1_3, IPrimitiveParameterV1_3, PrimitiveParameterQueryOptionsV1_3 } from './1-3';

export interface IParamterBaseV2 {
    id: string;
    displayName: string;
}

export declare namespace DurationParamV2 {
    export interface IFixed {
        kind: 'fixed';
        start: number;
        end: number;
    }

    export interface IDynamic {
        kind: 'dynamic';
        count: number;
        unit: TimeUnitsV1;
    }

    export type Value = IFixed | IDynamic;

    export interface IParam extends IParamterBaseV2 {
        kind: 'duration';
        beginVariableName: string;
        endVariableName: string;
        defaultValue: Value;
    }
}

export type TDurationParamV2 = DurationParamV2.IParam;

export declare namespace BasicParamV2 {
    export type ValueSelection<T> = { kind: 'value'; value: T } | { kind: 'values'; values: T[] } | { kind: 'all' };

    export type DropdownDataSource<T> =
        | { kind: 'static'; values: Array<{ value: T; displayText?: string }> }
        | {
              kind: 'query';
              consumedVariables: string[];
              query: string;
              dataSourceId: undefined | string;
              columns: {
                  value?: string;
                  label?: string;
              };
          };

    export interface IDataTypes {
        string: string;
        int32: number;
        int64: string;
        float64: number;
        decimal: string;
        bool: boolean;
        datetime: string;
    }

    export interface IBasic<T, K> extends IParamterBaseV2 {
        kind: K;
        /**
         * The name of the parameter to be referenced in a query
         */
        variableName: string;

        defaultValue: ValueSelection<T>;
    }

    export interface IFreetext<T, K> extends IBasic<T, K> {
        selectionType: 'freetext';
    }

    export interface IDropdown<T, K> extends IBasic<T, K> {
        selectionType: 'single' | 'single-all' | 'multi';
        dataSource: DropdownDataSource<T>;
    }
}

export type TBasicParamV2<T, K> = BasicParamV2.IFreetext<T, K> | BasicParamV2.IDropdown<T, K>;

export type UBasicParam =
    | TBasicParamV2<string, 'string'>
    | TBasicParamV2<number, 'int32'>
    | TBasicParamV2<string, 'int64'>
    | TBasicParamV2<number, 'float64'>
    | TBasicParamV2<string, 'decimal'>
    | TBasicParamV2<boolean, 'bool'>
    | TBasicParamV2<number, 'datetime'>;

export type IParameterV2 = UBasicParam | DurationParamV2.IParam;

function upDefaultValue<T, T2>(
    selectionType: UBasicParam['selectionType'],
    prev: ISelectedValuesV1_1<T>,
    upValue: (value: T) => T2
): BasicParamV2.ValueSelection<T2> {
    if (prev.selectAll === 'explicit') {
        return { kind: 'all' };
    }

    if (selectionType !== 'multi') {
        return { kind: 'value', value: upValue(prev.values[0].value) };
    }

    return { kind: 'values', values: prev.values.map((p) => upValue(p.value)) };
}

function upDataSource<T, T2>(
    prev: PrimitiveParameterStaticOptionsV1<T> | PrimitiveParameterQueryOptionsV1_3,
    upValue: (value: T) => T2
): BasicParamV2.DropdownDataSource<T2> {
    if (prev.type === 'static') {
        return {
            kind: 'static',
            values: prev.options.map((v) => ({
                value: upValue(v.value),
                displayText: v.displayText,
            })),
        };
    }
    return {
        kind: 'query',
        consumedVariables: prev.activeParentParameters,
        query: prev.query,
        dataSourceId: prev.dataSourceId,
        columns: prev.columns ?? {},
    };
}

function upBasic<T, K extends PrimitiveParameterTypeV1, T2, K2>(
    prev: IPrimitiveParameterV1_3<K, T>,
    kind: K2,
    upValue: (value: T) => T2
): TBasicParamV2<T2, K2> {
    const defaultValue = upDefaultValue<T, T2>(prev.selectionType, prev.defaultValue, upValue);

    if (prev.selectionType === 'freetext') {
        return {
            kind,
            id: prev.id,
            selectionType: 'freetext',
            variableName: prev.variableName,
            displayName: prev.displayName,
            defaultValue,
        };
    }

    return {
        kind,
        id: prev.id,
        selectionType: prev.selectionType,
        variableName: prev.variableName,
        displayName: prev.displayName,
        defaultValue,
        dataSource: upDataSource(prev.dataSource, upValue),
    };
}

export function up(prev: IParameterV1_3): IParameterV2 {
    if (prev.type === 'duration') {
        const range = prev.defaultValue.values[0].value;
        return {
            kind: 'duration',
            id: prev.id,
            displayName: prev.displayName,
            beginVariableName: prev.beginVariableName,
            endVariableName: prev.endVariableName,
            defaultValue:
                range.type === 'dynamic'
                    ? {
                          kind: range.type,
                          count: range.range.count,
                          unit: range.range.unit,
                      }
                    : {
                          kind: range.type,
                          start: new Date(range.range.start).valueOf(),
                          end: new Date(range.range.end).valueOf(),
                      },
        };
    }

    // The `schema` property broke the previous types
    const fixed = prev as
        | IPrimitiveParameterV1_3<'string', string>
        | IPrimitiveParameterV1_3<'int', number>
        | IPrimitiveParameterV1_3<'double', number>
        | IPrimitiveParameterV1_3<'long', string>
        | IPrimitiveParameterV1_3<'decimal', string>
        | IPrimitiveParameterV1_3<'bool', boolean>
        | IPrimitiveParameterV1_3<'datetime', string>;

    switch (fixed.type) {
        case 'string':
            return upBasic(fixed, 'string', (v) => v);
        case 'int':
            return upBasic(fixed, 'int32', (v) => v);
        case 'double':
            return upBasic(fixed, 'float64', (v) => v);
        case 'long':
            return upBasic(fixed, 'int64', (v) => v);
        case 'decimal':
            return upBasic(fixed, 'decimal', (v) => v);
        case 'bool':
            return upBasic(fixed, 'bool', (v) => v);
        case 'datetime':
            return upBasic(fixed, 'datetime', (v) => new Date(v).valueOf());
        default:
            assertNever(fixed);
    }
}
