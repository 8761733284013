import { ConditionalFormattingConfig, ExtendedVisualizationOptions } from 'utils/visualization';
import { colorValues, ConditionalFormattingOptions, colorRulesIconsLabels } from './types';
import { Theme, ColumnType } from '@kusto/common';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { isEmpty } from 'lodash';
// import { classNamesFunction } from '@uifabric/utilities';
import { IStyle } from 'office-ui-fabric-react/lib/Styling';
import { mergeStyles } from '@uifabric/merge-styles';

export const colorRulesColorLabels = {
    red: 'Critical',
    yellow: 'Warning',
    green: 'Healthy',
    blue: 'Normal',
};

/**
 * Colors are from:
 * https://www.figma.com/file/oqmKKJVwc4rZgivntf568F/ADX-Dashboards-BUILD-Handoff?node-id=2350%3A32748
 */

const bothLightAndBold = {
    light: {
        green: { color: '#107C10', invertText: true },
        blue: { color: '#005A9E', invertText: true },
        red: { color: '#A80000', invertText: true },
    },

    dark: {
        green: { color: '#92C353', invertText: true },
        blue: { color: '#6CB8F6', invertText: true },
        red: { color: '#FF8282', invertText: true },
        yellow: { color: '#FFC83E', invertText: true },
    },
};

export const conditionalFormattingColors: colorValues = {
    light: {
        light: { ...bothLightAndBold.light, yellow: { color: '#EC952E' } },
        bold: { ...bothLightAndBold.light, yellow: { color: '#FFB900' } },
    },
    dark: { light: bothLightAndBold.dark, bold: bothLightAndBold.dark },
};

export const getConditionalFormattingColors = (
    conditionalFormattingOptions: ConditionalFormattingOptions,
    theme: keyof colorValues
) => {
    const colorName = conditionalFormattingOptions.color;
    const colorStyle = conditionalFormattingOptions.colorStyle;
    const colorValue = colorStyle && colorName && conditionalFormattingColors[theme][colorStyle][colorName];
    const invertedTextColors = {
        dark: '#323130',
        light: 'white',
    };
    let textColor;
    let backgroundColor;
    let headerColor;
    let labelColor;
    if (colorValue) {
        if (colorStyle === 'light') {
            textColor = colorValue.color;
        } else {
            textColor = colorValue.invertText ? invertedTextColors[theme] : undefined;
            backgroundColor = colorValue.color;
            headerColor = textColor;
            labelColor = textColor;
        }
    }
    return {
        textColor,
        backgroundColor,
        headerColor,
        labelColor,
    };
};

/**
 * @param baseCell - Base cell renderer to wrap with conditionalFormatting
 * @param params - The renderer params
 */
export const wrapCellWithConditionalFormatting = (baseCell: (p: any) => string) => {
    return (params: ICellRendererParams & { theme: Theme; visualizationOptions?: ExtendedVisualizationOptions }) => {
        const conditionalFormattingConfig: ConditionalFormattingConfig | undefined =
            params.visualizationOptions?.ColumnFormatting?.ConditionalFormattingConfig;
        const useConditionalFormatting = conditionalFormattingConfig?.colorRulesDisabled === false;
        if (!useConditionalFormatting) {
            return baseCell(params);
        }
        const columnName = params.colDef.headerName || '';
        const columnType = params.colDef.cellRendererParams?.columnType;
        const options = getConditionalFormattingOptions(
            { [columnName]: params.value },
            columnType,
            conditionalFormattingConfig
        );
        const colOptions = options[columnName] || {};

        const wrapperCell = (
            <div className="cf-wrapper">
                <span className="cf-content">
                    <Icon iconName={colOptions.icon} className="cf-icon" />
                    <span dangerouslySetInnerHTML={{ __html: baseCell(params) }}></span>
                </span>
                {!isEmpty(colOptions.subLabel?.trim()) && <span className="cf-sub-label">{colOptions.subLabel}</span>}
            </div>
        );
        return ReactDOMServer.renderToString(wrapperCell);
    };
};

// Generates a class name according to the theme, style and color name.
export const generateCFClassName = ({
    theme,
    colorStyle,
    colorName,
    excludeCFClassName,
}: {
    theme?: string;
    colorStyle?: string;
    colorName?: string;
    excludeCFClassName?: boolean;
}) => {
    if (!theme || !colorStyle || !colorName) {
        return '';
    }
    let className = `${theme}-${colorStyle}-${colorName}`.toLocaleLowerCase();
    className = excludeCFClassName ? className : `${cfClassName} ${className}`;
    return className.toLowerCase();
};

export const getConditionlFormattingClassName = () => {
    return mergeStyles(getConditionalFormattingStyles());
};

const getCFStyles = ({ textColor, backgroundColor }: { textColor?: string; backgroundColor?: string }) => {
    const ellipsisStyles: IStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };
    const wrapperStyles: IStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: textColor,
    };
    const iconStyles: IStyle = {
        color: textColor,
        marginRight: 4,
        verticalAlign: 'bottom',
        selectors: {
            // Sometimes we use SVGs if the icon is not in the Fabric icons library. we need to alter their CSS spesifically
            svg: {
                height: 26,
                width: 12,
                verticalAlign: 'bottom',
            },
            path: {
                fill: textColor,
            },
        },
    };
    const contentStyles: IStyle = {
        ...ellipsisStyles,
    };
    const subLabelStyles: IStyle = {
        marginLeft: 8,
        lineHeight: '16px',
        display: 'inline-block',
        color: textColor,
        border: `1px ${textColor ?? ''} solid`,
        padding: '0 4px',
        borderRadius: 2,
        ...ellipsisStyles,
    };

    return {
        backgroundColor,
        wrapperStyles,
        contentStyles,
        subLabelStyles,
        iconStyles,
    };
};

export const getConditionalFormattingStyles = (): IStyle => {
    const colorSelectors: { [key: string]: IStyle } = {};
    // Generate all possible ombinations of classNames. (theme-style-color)
    Object.entries(conditionalFormattingColors).forEach(([theme, themeColors]) => {
        Object.entries(themeColors).forEach(([colorStyle, colors]) => {
            Object.keys(colors).forEach((colorName) => {
                const className = generateCFClassName({ theme, colorStyle, colorName, excludeCFClassName: true });
                const colors = getConditionalFormattingColors(
                    { color: colorName, colorStyle: colorStyle } as ConditionalFormattingOptions,
                    theme as keyof colorValues
                );
                const { wrapperStyles, iconStyles, contentStyles, subLabelStyles, backgroundColor } = getCFStyles({
                    textColor: colors.textColor,
                    backgroundColor: colors.backgroundColor,
                });
                colorSelectors[`&.${className}`] = {
                    backgroundColor,
                    selectors: {
                        [`&.${className}.kusto-alignment-right`]: {
                            selectors: {
                                '.cf-wrapper': {
                                    justifyContent: 'flex-end',
                                },
                            },
                        },
                        '.cf-wrapper': {
                            ...wrapperStyles,
                            selectors: {
                                '.cf-content': {
                                    ...contentStyles,
                                    selectors: {
                                        '.cf-icon': {
                                            ...iconStyles,
                                        },
                                    },
                                },
                                '.cf-sub-label': {
                                    ...subLabelStyles,
                                },
                                a: {
                                    color: wrapperStyles.color + ' !important',
                                },
                            },
                        },
                    },
                };
            });
        });
    });

    return {
        selectors: colorSelectors,
    };
};

// Base className from mergeStyles
export const cfClassName = getConditionlFormattingClassName();

// Returns a single formatting option for a single card. Gives priority to the column that's displayed as the value.
export const getSingleStatConditionalFormattingOptions = (
    options: { [colName: string]: ConditionalFormattingOptions },
    valueColumnName: string
): ConditionalFormattingOptions => {
    if (options[valueColumnName]) {
        return options[valueColumnName];
    }
    const formattedColumns = Object.keys(options);
    return formattedColumns.length > 0 ? options[formattedColumns[0]] : {};
};

// Returns a map between column name to its formatting options
export const getConditionalFormattingOptions = (
    row: { [colName: string]: number | string | undefined | null },
    columnType: string = 'dynamic',
    conditionalFormattingOptions?: ConditionalFormattingConfig,
    inferredColumnName?: string
): { [colName: string]: ConditionalFormattingOptions } => {
    if (!conditionalFormattingOptions || conditionalFormattingOptions.colorRulesDisabled || !row) {
        return {};
    }
    const formattingPerColumn: { [colName: string]: ConditionalFormattingOptions } = {};
    const { colorRules, colorStyle } = conditionalFormattingOptions;
    colorRules.forEach((rule) => {
        const columnName = rule.column.type === 'infer' ? inferredColumnName : rule.column.value;
        const value = columnName !== undefined ? row[columnName] : undefined;
        if (columnName === undefined || value === null || value === undefined) {
            return;
        }
        const convertedValue = convertValueToColumnType(value.toString(), columnType as ColumnType);
        if (convertedValue === undefined) {
            return;
        }
        const badConditionIndex = rule.conditions.findIndex((condition) => {
            const convertedConditionValue = convertValueToColumnType(
                condition.value.toString(),
                columnType as ColumnType
            );
            if (convertedConditionValue === undefined) {
                return false;
            }
            let conditionMatches = true;
            switch (condition.operator) {
                case '>':
                    conditionMatches = convertedValue > convertedConditionValue;
                    break;
                case '>=':
                    conditionMatches = convertedValue >= convertedConditionValue;
                    break;
                case '<':
                    conditionMatches = convertedValue < convertedConditionValue;
                    break;
                case '<=':
                    conditionMatches = convertedValue <= convertedConditionValue;
                    break;
                case '==':
                    conditionMatches = convertedValue === convertedConditionValue;
                    break;
            }
            return conditionMatches === false;
        });
        if (badConditionIndex === -1) {
            // If the label is of type "infer" we take the color rule default label
            let icon: ConditionalFormattingOptions['icon'];
            let subLabel;
            switch (rule.indicator.kind) {
                case 'text':
                    subLabel =
                        rule.indicator.text.type === 'infer'
                            ? colorRulesColorLabels[rule.color]
                            : rule.indicator.text.value;
                    break;
                case 'icon':
                    icon = rule.indicator.icon;
                    subLabel =
                        rule.indicator.label.type === 'infer'
                            ? colorRulesIconsLabels[rule.indicator.icon]
                            : rule.indicator.label.value;
            }
            formattingPerColumn[columnName] = {
                icon,
                color: rule.color,
                subLabel,
                colorStyle,
            };
        }
    });

    return formattingPerColumn;
};

const convertValueToColumnType = (value: string, columnType: ColumnType) => {
    // If columnType is dynamic, at least try to convert to a number
    if (columnType === 'dynamic') {
        if (!isNaN(parseFloat(value))) {
            return parseFloat(value);
        }
    }
    if (columnType === 'int' || columnType === 'real' || columnType === 'long' || columnType === 'decimal') {
        return parseFloat(value);
    }
    if (columnType === 'datetime') {
        const date = new Date(value);
        return date instanceof Date && !isNaN(date.getTime()) ? date.getTime() : undefined;
    }
    return value;
};
