import * as React from 'react';
import { useRef, useState, useCallback, useMemo } from 'react';
import {
    PrimaryButton,
    DefaultButton,
    Stack,
    PanelType,
    IPanelProps,
    DialogFooter,
    IDialogContentProps,
} from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

import { QueryEditor, IQueryEditor, RTDPanel, RTDDialog, ParameterSelectorList } from '../../../../../../components';
import { UKustoDataSource } from '../../../../../../core/domain';
import { useCore } from '../../../../../../core';
import { APP_STRINGS } from '../../../../../../res';
import {
    QueryProvider,
    ControlBar,
    RunButton,
    MaybeQuery,
    MonacoResizeContainer,
    DataSourceDropdown,
} from '../../../../../../components/QueryEditing';
import { err, ok, usePullState } from '../../../../../../common';
import { DashboardLoaded } from '../../../../../../store/dashboard';
import { newKustoQuery } from '../../../../../../domain';
import { buildSampleKustoQuery } from '../../../../../editTile/lib';

import { VisualPane } from './VisualPane';

import styles from './QueryPanel.module.scss';

export interface IQueryPanelProps {
    initialQueryText?: string;
    initialActiveParameters?: Set<string>;
    currentParameterId: string;
    dataSource: UKustoDataSource;
    telemetryComponentName: string;
    dashboardLoaded: DashboardLoaded;
    onDone(query: string, activeParameters: Set<string>): void;
    onCancel: () => void;
}

const panelStyles: IPanelProps['styles'] = {
    header: { padding: '0 16px' },
    content: {
        height: '100%',
        boxShadow: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    scrollableContent: { height: '100%', overflowY: 'hidden' },
    contentInner: { height: '100%' },
    /**
     * Without this the panel's width will still be it's custom defined width when
     * the container becomes smaller
     */
    main: { maxWidth: '100%' },
};

const noop = () => {};

interface ActionBarProps extends Pick<IQueryPanelProps, 'onDone' | 'onCancel'> {
    queryText: React.MutableRefObject<string>;
    getActiveParameters(): Set<string>;
    queryEditorRef: React.RefObject<IQueryEditor>;
}

const ActionBar: React.FC<ActionBarProps> = ({ onDone, onCancel, queryText, getActiveParameters, queryEditorRef }) => {
    const innerOnDone = useCallback(async () => {
        await queryEditorRef.current?.forceUpdate();
        onDone(queryText.current, getActiveParameters());
    }, [queryText, getActiveParameters, queryEditorRef, onDone]);

    return (
        <Stack horizontal tokens={{ childrenGap: 16, padding: 16 }}>
            <PrimaryButton onClick={innerOnDone}>
                {APP_STRINGS.editParameterPage.queryPanel.completeButtonText}
            </PrimaryButton>
            <DefaultButton onClick={onCancel}>
                {APP_STRINGS.editParameterPage.queryPanel.cancelButtonText}
            </DefaultButton>
        </Stack>
    );
};

const editorDidMount = (onEscape: () => void) => (editor: monaco.editor.IStandaloneCodeEditor) => {
    editor.focus();

    // Suppress escape keypress on parent node and handle escape ourselves when it
    // is pressed and the suggest widget is not visible.
    //
    // The latest version of the monaco editor should suppress escape key presses
    // that it uses automatically. This _may_ only be needed because we're
    // using an older version.
    //
    // Related github issue: https://github.com/microsoft/monaco-editor/issues/1836
    //
    // This is very much a bit of a hack and may not work in all situations. The
    // only specific situations where it won't work right now is if the monaco
    // editor should be capturing the esc key for other situations besides
    // the suggestion thing.  Other than that, it's unknown unknown because I'm
    // not super familiar with the monaco editor codebase. This seems like not the
    // suggested way to deal with the problem we are having, so I'm nervous it may
    // not be very well supported in non-obvious ways.
    //
    // TODO: If/when we update the monaco editor we should see if we can remove this
    //
    editor.addCommand(monaco.KeyCode.Escape, () => onEscape(), '!suggestWidgetVisible');
    // DOM node is found through editor dome node because ReactMonacoEditor doesn't
    // allow us to pass though properties to it's wrapper div
    editor.getDomNode()?.parentNode?.addEventListener('keydown', (event) => {
        if (event instanceof KeyboardEvent && event.key === 'Escape') {
            event.stopPropagation();
        }
    });
};

const dialogContentProps: IDialogContentProps = {
    title: APP_STRINGS.editParameterPage.queryPanel.confirmCloseDialog.title,
    subText: APP_STRINGS.editParameterPage.queryPanel.confirmCloseDialog.subText,
};

export const QueryPanel: React.FC<IQueryPanelProps> = observer(function QueryPanel({
    initialQueryText,
    initialActiveParameters,
    dataSource,
    dashboardLoaded,
    currentParameterId,
    onCancel,
    onDone,
    telemetryComponentName,
}) {
    const core = useCore();

    const parametersOmittingSelf = useMemo(
        () => dashboardLoaded.parameters.filter((p) => p.id !== currentParameterId),
        [dashboardLoaded.parameters, currentParameterId]
    );

    const [parameterSelections] = useState(() => runInAction(() => dashboardLoaded.selectedParameters.clone()));

    const [activeParameters, setActiveParameters, getActiveParameters] = usePullState(
        () => initialActiveParameters ?? new Set<string>()
    );

    const [renderedQuery, setRenderedQuery] = useState<MaybeQuery>(() => {
        if (initialQueryText === undefined) {
            return err({
                title: APP_STRINGS.editParameterPage.queryPanel.noQueryMessage,
            });
        }

        return runInAction(() => {
            const paramValues = parameterSelections.resolveParameterValues(activeParameters);
            if (paramValues.kind === 'err') {
                return paramValues;
            }
            return ok(newKustoQuery(initialQueryText, dataSource, currentParameterId, paramValues.value));
        });
    });

    const initialQueryWithDefault = useMemo(
        () => initialQueryText ?? buildSampleKustoQuery(parametersOmittingSelf),
        [initialQueryText, parametersOmittingSelf]
    );

    const queryEditorRef = useRef<IQueryEditor>(null);
    const queryText = useRef(initialQueryWithDefault);
    const [confirmClose, setConfirmClose] = useState(false);

    const setQuery = useCallback((text: string) => {
        queryText.current = text;
    }, []);

    const hasChanges = useCallback(() => queryText.current !== initialQueryWithDefault, [initialQueryWithDefault]);

    const runQuery = useCallback(async () => {
        await queryEditorRef.current?.forceUpdate();

        runInAction(() => {
            const paramValues = parameterSelections.resolveParameterValues(activeParameters);

            setRenderedQuery(
                paramValues.kind === 'err'
                    ? paramValues
                    : ok(newKustoQuery(queryText.current, dataSource, currentParameterId, paramValues.value))
            );
        });
    }, [activeParameters, currentParameterId, dataSource, parameterSelections]);

    const tryClose = useCallback(() => {
        if (hasChanges()) {
            setConfirmClose(true);
        } else {
            onCancel();
        }
    }, [onCancel, hasChanges]);

    const cancelClose = useCallback(() => setConfirmClose(false), []);

    React.useEffect(() => {
        return core.history.block(() => {
            if (hasChanges()) {
                return APP_STRINGS.editParameterPage.preventNavigationWarning;
            }
            return;
        });
    }, [core.history, hasChanges]);

    return (
        <>
            <RTDPanel
                isOpen
                isLightDismiss
                type={PanelType.custom}
                headerText={APP_STRINGS.editParameterPage.queryPanel.title}
                customWidth="900px"
                onDismiss={tryClose}
                closeButtonAriaLabel={APP_STRINGS.editParameterPage.queryPanel.cancelButtonText}
                styles={panelStyles}
            >
                <QueryProvider query={renderedQuery}>
                    <MonacoResizeContainer
                        className={styles.resizeContainer}
                        topPaneClassName={styles.resizeContainerTopPane}
                        queryEditorRef={queryEditorRef}
                        topChildren={
                            <>
                                {parametersOmittingSelf.length > 0 && (
                                    <ParameterSelectorList
                                        parameters={parametersOmittingSelf}
                                        selectedParameters={parameterSelections}
                                        activeVariables={activeParameters}
                                        displayVariables={true}
                                    />
                                )}
                                <ControlBar>
                                    <DataSourceDropdown dataSourceId={dataSource.id} disabled onChange={noop} />
                                    <RunButton onRun={runQuery} disabled={false} />
                                </ControlBar>
                                <QueryEditor
                                    className={styles.queryEditor}
                                    ref={queryEditorRef}
                                    isMarkdown={false}
                                    initialValue={initialQueryWithDefault}
                                    dataSource={dataSource}
                                    telemetryComponentName={telemetryComponentName}
                                    parameters={parametersOmittingSelf}
                                    onParameterChange={setActiveParameters}
                                    onChange={setQuery}
                                    runQuery={runQuery}
                                    editorDidMount={editorDidMount(tryClose)}
                                />
                            </>
                        }
                        bottomChildren={<VisualPane />}
                    />
                </QueryProvider>
                <ActionBar
                    queryText={queryText}
                    queryEditorRef={queryEditorRef}
                    getActiveParameters={getActiveParameters}
                    onDone={onDone}
                    onCancel={tryClose}
                />
            </RTDPanel>
            {confirmClose && (
                <RTDDialog isOpen onDismiss={cancelClose} dialogContentProps={dialogContentProps}>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={onCancel}
                            text={APP_STRINGS.editParameterPage.queryPanel.confirmCloseDialog.agree}
                        />
                        <DefaultButton
                            onClick={cancelClose}
                            text={APP_STRINGS.editParameterPage.queryPanel.confirmCloseDialog.cancel}
                        />
                    </DialogFooter>
                </RTDDialog>
            )}
        </>
    );
});
