import { UserSettingsV1 } from './1';

type Infer = { readonly type: 'infer' };
type Specified<T> = { readonly type: 'specified'; readonly value: T };
type CanInfer<T> = Infer | Specified<T>;

export interface UserSettingsV2 {
    $schema: '2';
    theme: 'light' | 'dark' | 'automatic';
    language: CanInfer<string>;
    regionalFormat: CanInfer<string>;
    enableChartPerformanceMode: boolean;
    enableAccessabilityOptimizationMode: boolean;
    favoriteDashboards: string[];
    pageNavOpen: boolean;
    editTilePagePreviewExpanded: boolean;
    editTilePageDividerPositionPercentage: number;
}

export function up(settings: UserSettingsV1): UserSettingsV2 {
    return {
        ...settings,
        $schema: '2',
        pageNavOpen: false,
        editTilePagePreviewExpanded: true,
        editTilePageDividerPositionPercentage: 50,
    };
}
