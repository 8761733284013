import * as React from 'react';
import { DirectionalHint, Link } from 'office-ui-fabric-react';
import { formatLiterals } from '../../utils/string';
import { ActionButtonWithTooltip } from './ActionButtonWithTooltip';

export interface Strings {
    /** Beginning of tooltip. Every `${timeZone}` will be replaced with props.timeZone */
    timeZoneIndicatorToolTipPart1: string;
    /** End of tooltip. Will also be clickable and trigger openSettingsClicked */
    timeZoneIndicatorToolTipPart2: string;
}

export interface TimeZoneLabelProps {
    timeZone: string;
    openSettingsClicked?: () => void;
    strings?: Strings;
}

export const TimeZoneLabel = (props: TimeZoneLabelProps) => {
    const tooltipStart = formatLiterals(props.strings?.timeZoneIndicatorToolTipPart1 ?? '', {
        timeZone: props.timeZone,
    });
    const tooltipEnd = props.strings?.timeZoneIndicatorToolTipPart2;

    return (
        <>
            <ActionButtonWithTooltip
                tooltipProps={{
                    content: (tooltipStart || tooltipEnd) && (
                        <span>
                            {tooltipStart}
                            {tooltipStart && tooltipEnd && ' '}
                            {tooltipEnd && (
                                <Link
                                    onClick={props.openSettingsClicked}
                                    underline="true"
                                    style={{ cursor: 'pointer' }}
                                >
                                    {tooltipEnd}
                                </Link>
                            )}
                        </span>
                    ),
                    directionalHint: DirectionalHint.topCenter,
                }}
                key="TimeZoneIndicator"
                iconProps={{ iconName: 'Clock' }}
                text={props.timeZone}
                onClick={props.openSettingsClicked}
            />
        </>
    );
};
