import React from 'react';
import { TooltipWrapper, TooltipWrapperProps } from './TooltipWrapper';
import { ActionButton } from 'office-ui-fabric-react/lib/Button';

export interface ActionButtonWithTooltipProps extends React.ComponentProps<typeof ActionButton> {
    tooltipProps: TooltipWrapperProps;
}

export const ActionButtonWithTooltip = ({
    title,
    tooltipProps,
    ...actionButtonProps
}: ActionButtonWithTooltipProps) => {
    return (
        <TooltipWrapper content={title} {...tooltipProps}>
            <ActionButton {...actionButtonProps}></ActionButton>
        </TooltipWrapper>
    );
};
