import { dataSource } from '../dataSource';
import { parameter } from '../parameter';
import { IDashboardV1_2 } from './1-2';

export interface IDashboardV2 extends Omit<IDashboardV1_2, '$schema' | 'dataSources' | 'parameters'> {
    $schema: '2';
    dataSources: dataSource.v1.IDataSourceV1[];
    parameters: parameter.v1_3.IParameterV1_3[];
}

export function up(prev: IDashboardV1_2): IDashboardV2 {
    const parameters = prev.parameters.map((p) => {
        const v1_1 = p.$schema === '1.0' ? parameter.v1_1.up(p) : p;
        const v1_2 = v1_1.$schema === '1.1' ? parameter.v1_2.up(v1_1) : v1_1;
        const v1_3 = v1_2.$schema === '1.2' ? parameter.v1_3.up(v1_2) : v1_2;
        return v1_3;
    });

    const prevDataSources: Array<dataSource.v0.IDataSourceV0 | dataSource.v1.IDataSourceV1> = prev.dataSources;

    const dataSources = prevDataSources.map((d) => {
        if (d.$schema === '0') {
            return dataSource.v1.up(d);
        }
        return d;
    });

    return {
        ...prev,
        $schema: '2',
        dataSources,
        parameters,
    };
}
