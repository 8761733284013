/// <reference path="../../node_modules/monaco-editor/monaco.d.ts" />
// Keep the reference in order to avoid importing of the library
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import {
    AllModules,
    CellEvent,
    CellFocusedEvent,
    CellRangeParams,
    ColDef,
    Column,
    ColumnApi,
    Events,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererComp,
    ICellRendererParams,
    NavigateToNextCellParams,
    RangeSelectionChangedEvent,
    RowNode,
    SuppressKeyboardEventParams,
} from '@ag-grid-enterprise/all-modules';
import { wrapCellWithConditionalFormatting } from '../utils/conditionalFormatting/conditionalFormatting';
import { GridWithSearchProps } from './GridWithSearch.types';
import { KeyCodes } from '@uifabric/utilities';
import _ from 'lodash';
import memoize from 'memoize-one';
import { FontIcon } from 'office-ui-fabric-react';
import React from 'react';
import ReactDOM from 'react-dom';
import Pane from 'react-split-pane/lib/Pane';
import { Theme } from '@kusto/common';
import { StyledSplitPane } from '../components/StyledReactSplitPane';
import './agGridExpandView.scss';
import { isMacOs } from 'react-device-detect';

export const closableCell = (
    params: ICellRendererParams & {
        highlightUrl?: boolean | undefined;
        theme: Theme;
        strings: ExpandGridStrings;
    }
) => {
    const value = params.valueFormatted || params.value;
    const ariaLabel = params.strings ? params.strings.expanded : '';
    const closeIcon = `<i aria-label="${ariaLabel}" class="ms-Icon ms-Icon--Cancel  ms-Icon--s expand-close">&#xd7</i>`;
    if (value === undefined || value === null) {
        return closeIcon;
    }

    const escapedValue = _.escape(value);

    const element =
        params.highlightUrl && typeof value === 'string' && value.startsWith('https://')
            ? `<a class="highlighted-url" style="">${escapedValue}</a>`
            : escapedValue;

    return element + closeIcon;
};

const ExpandCellRenderer = (props: ICellRendererParams) => {
    if (props.node.expanded) {
        return <FontIcon iconName="ChevronDown" style={{ width: 11, fontSize: 10, float: 'none' }} />;
    } else {
        return (
            <FontIcon
                iconName="ChevronRight"
                style={{
                    fontSize: 10,
                    width: 11,
                }}
            />
        );
    }
};

// #region HyperlinkCellRenderer

// Currently size calculation is broken for the framework component, so commenting it out.
// export const ClosableCellRenderer = (props: ICellRendererParams & { highlightUrl?: boolean | undefined }) => {
//     const val: string | undefined | null = props.valueFormatted ?? props.value;
//     return (
//         <>
//             {props.highlightUrl && val && val.startsWith('https://') ? (
//                 <Link target="_blank" href={val}>
//                     {val}
//                 </Link>
//             ) : (
//                 val
//             )}
//             <FontIcon iconName="Cancel" className="expand-close" />
//         </>
//     );
// };

// #endregion

class DetailedCellRender implements ICellRendererComp {
    private eGui?: HTMLSpanElement;

    init(params: ICellRendererParams): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.eGui = (params as any).expandPortal;
    }

    refresh(params: ICellRendererParams): boolean {
        this.init(params);
        return true;
    }
    getGui(): HTMLElement {
        return this.eGui!;
    }
}

const MONACO_ACTION_ID_PREFIX = 'editor.action.detailsView.';
export const EXPAND_ROW_COL_ID = '__expandRowColID';
const MONACO_INITIAL_CURSOR_STATE: monaco.editor.ICursorState = {
    inSelectionMode: false,
    position: { lineNumber: 1, column: 1 },
    selectionStart: { lineNumber: 1, column: 1 },
};

export enum DetailsViewType {
    InGrid = 0,
    BelowGrid,
    ExternalPanel,
}

interface State {
    expanded: boolean;
}

interface ExpandGridStrings {
    backToTable: string;
    closeDetailsView: string;
    expandRow: string;
    querySearchResults$closeButton: string;
    expanded: string;
}

export interface GridExpandView<T extends HTMLElement = HTMLElement> {
    onReady: (container: T | null) => void;
    onDismissed: () => void;
}

export interface GridWithExpandProps extends GridWithSearchProps {
    openExternalView?: (expandView: GridExpandView) => void;
    closeExternalView?: (expandView: GridExpandView) => void;
    onEditorLoaded?: (editor: monaco.editor.IStandaloneCodeEditor) => void;
    onEditorOpen?: (internal: boolean) => void;
    onEditorClose?: () => void;
    onExpandTypeChange?: (expandType: DetailsViewType) => void;
    /**
     * Called when a user clicks a link.
     * @param url A url to the clicked link
     * @param ctrlOrCommandPressed return true if, in addition to clicking the link, the user also pressed the ctrl key (on windows) or the command key (on MacOS)
     * */
    onLinkClicked?: (url: string, ctrlOrCommandPressed: boolean) => void;
    hideEmptyCells?: boolean;
    expandType?: DetailsViewType;
    closeExpandViewOnClick?: boolean;
    mouseWheelZoom?: boolean;
    strings: ExpandGridStrings;
    renderEditor: (
        editorDidMount: (editor: monaco.editor.IStandaloneCodeEditor, _monaco: typeof monaco) => void,
        options: monaco.editor.IStandaloneEditorConstructionOptions
    ) => JSX.Element;
}

export class GridWithExpand extends React.Component<GridWithExpandProps, State> {
    static defaultProps = {
        onExpandTypeChange: () => {
            /** */
        },
        expandType: DetailsViewType.InGrid,
    };

    private gridApi?: GridApi;
    private columnApi?: ColumnApi;

    private lastExpandCell: { rowId?: string; column?: Column } = {};
    private lastDetailedRowHeight?: number;
    private lastDoubleClickedTimestamp = 0;
    private lastClickedTimestamp = 0;

    private expandPortal: HTMLElement = document.createElement('span');
    private detailedViewSelectionPortal: HTMLElement = document.createElement('div');
    private containerRef = React.createRef<HTMLDivElement>();
    private belowGridRef = React.createRef<HTMLDivElement>();
    private externalViewContainer: HTMLElement | null = null;
    private updateValueQueue: {
        inProgress: boolean;
        updateWaiting: boolean;
    } = {
        inProgress: false,
        updateWaiting: false,
    };
    private ExpandViewControl: GridExpandView = {
        onReady: (container: HTMLElement | null) => {
            this.externalViewContainer = container;
            if (this.expandPortal) {
                this.appendDetailsViewToExternalPanel();
            }
        },
        onDismissed: () => {
            if (this.expandPortal) {
                this.closeDetails();
            }
            this.externalViewContainer = null;
        },
    };

    private mainEditor?: monaco.editor.IStandaloneCodeEditor;
    private shadowEditor?: monaco.editor.ICodeEditor;
    private readonly monacoOptions: monaco.editor.IEditorConstructionOptions = {
        formatOnType: true,
        folding: true,
        links: true,
        hover: { enabled: false },
        automaticLayout: true,
        minimap: { enabled: false },
        showFoldingControls: 'always',
        readOnly: true,
        wordWrap: 'on',
        wrappingIndent: 'deepIndent',
        renderLineHighlight: 'none',
        selectionHighlight: false,
        scrollBeyondLastLine: false,
        foldingStrategy: 'auto',
    };

    constructor(props: GridWithExpandProps) {
        super(props);
        this.state = { expanded: false };
    }

    componentDidUpdate(prevProps: GridWithExpandProps) {
        if (prevProps.searchFocusedCell !== this.props.searchFocusedCell) {
            const cellPosition = this.props.searchFocusedCell;
            if (this.gridApi && this.state.expanded && cellPosition?.column) {
                const row = this.gridApi.getDisplayedRowAtIndex(cellPosition.rowIndex);
                this.updateExpand(row, cellPosition?.column);
            }
        }
        const { rowId, column } = this.lastExpandCell;
        if (
            !this.state.expanded ||
            prevProps.expandType === this.props.expandType ||
            rowId === undefined ||
            !column ||
            !this.mainEditor
        ) {
            return;
        }
        this.hideExpandView(rowId, prevProps.expandType!);
        this.setupDetailsView(rowId, column, undefined);
        this.mainEditor.focus();
    }

    UNSAFE_componentWillMount() {
        this.expandPortal.addEventListener('keydown', this.keyboardBlocker);
    }

    componentWillUnmount() {
        this.expandPortal.removeEventListener('keydown', this.keyboardBlocker);
        if (this.shadowEditor) {
            const shadowEditorToDispose = this.shadowEditor;
            // delay the disposal of shadow editor until all json formatting timers are done
            setTimeout(() => shadowEditorToDispose.dispose(), 1000);
            this.shadowEditor = undefined;
            this.mainEditor = undefined;
        }
    }

    render() {
        const { defaultColDef, columnDefs, gridOptions, hideEmptyCells, expandType, ...props } = this.props;
        const mergeOptions = this.memoizeCalcMergedOptions(defaultColDef, columnDefs, gridOptions);

        const isBelowGridExpanded = expandType === DetailsViewType.BelowGrid && this.state.expanded;

        return (
            <div
                ref={this.containerRef}
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <StyledSplitPane
                    split="horizontal"
                    allowResize={isBelowGridExpanded}
                    onChange={(sizes) => {
                        if (isBelowGridExpanded) {
                            const height = Number.parseFloat(sizes[1].replace('px', ''));
                            this.lastDetailedRowHeight = height;
                        }
                    }}
                >
                    <Pane minSize="10px">
                        {this.memoizeGridRender({
                            ...props,
                            columnDefs: mergeOptions.columnDefs,
                            gridOptions: mergeOptions,
                        })}
                    </Pane>
                    <Pane
                        key="expandPaneBelow"
                        size={(isBelowGridExpanded ? this.getDetailedHeight() : 0) + 'px'}
                        minSize={isBelowGridExpanded ? '15px' : '0px'}
                    >
                        <div ref={this.belowGridRef} />
                    </Pane>
                </StyledSplitPane>
                {this.renderExpand()}
            </div>
        );
    }
    private renderExpand = () => {
        const maxHeight = DetailsViewType.ExternalPanel !== this.props.expandType ? '10000px' : undefined;
        const enable = DetailsViewType.InGrid === this.props.expandType && this.state.expanded;
        const expand = (
            <div
                style={{ height: enable ? '100vh' : '100%', width: '100%' }}
                onContextMenu={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.preventDefault()}
                role="none"
            >
                <StyledSplitPane
                    split="horizontal"
                    allowResize={enable}
                    onChange={(sizes) => {
                        if (enable) {
                            const height = Number.parseFloat(sizes[0].replace('px', ''));
                            this.lastDetailedRowHeight = height;
                            if (this.gridApi && this.lastExpandCell.rowId !== undefined) {
                                const row = this.gridApi.getRowNode(this.lastExpandCell.rowId);
                                if (row && row.detailNode) {
                                    row.detailNode.setRowHeight(height);
                                    this.debounceGridOnRowHeightChanged();
                                }
                            }
                        }
                    }}
                >
                    <Pane size={enable ? this.getDetailedHeight() + 'px' : '100%'} maxSize={maxHeight} minSize="10px">
                        {this.props.renderEditor(this.expandDidMount, this.monacoOptions)}
                    </Pane>
                    <div />
                </StyledSplitPane>
            </div>
        );
        return ReactDOM.createPortal(expand, this.expandPortal);
    };

    private memoizeGridRender = memoize(
        (props: AgGridReactProps) => <AgGridReact modules={AllModules} {...props} />,
        (a, b) =>
            _.isEqualWith(a, b, (value, other, indexOrKey) => (indexOrKey === 'rowData' ? value === other : undefined))
    );

    private doNothing = () => {
        /* do nothing */
    };

    private memoizeCalcMergedOptions = memoize(
        (defaultColDef?: ColDef, propsColumnDef?: ColDef[], gridOptions?: GridOptions) => {
            const mergeDefaultCol: ColDef = _.merge(
                {},
                gridOptions!.defaultColDef,
                defaultColDef,
                this.gridOptions.defaultColDef
            );
            const mergeOptions: GridOptions = _.merge({}, this.props.gridOptions, this.gridOptions);
            mergeOptions.onGridReady = this.onGridRead;
            if (mergeOptions.columnDefs && mergeOptions.columnDefs.length > 0) {
                mergeOptions.columnDefs = [
                    {
                        headerName: this.props.strings.expandRow,
                        colId: EXPAND_ROW_COL_ID,
                        onCellContextMenu: this.doNothing,
                        cellClass: 'ms-Icon rowExpandIndicator',
                        cellRendererFramework: ExpandCellRenderer,
                        width: 21,
                        maxWidth: 21,
                        enableRowGroup: false,
                        enablePivot: false,
                        enableValue: false,
                        suppressFiltersToolPanel: true,
                        suppressColumnsToolPanel: true,
                        suppressMovable: true,
                        suppressMenu: true,
                        cellRenderer: undefined,
                    },
                    ...(propsColumnDef || mergeOptions.columnDefs),
                ];
            }
            mergeOptions.defaultColDef = mergeDefaultCol;

            return mergeOptions;
        },
        _.isEqual
    );

    private onGridRead = (params: GridReadyEvent) => {
        if (params.api) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi || undefined;

            this.gridApi.addEventListener(
                Events.EVENT_RANGE_SELECTION_CHANGED,
                this.debouncedRemoveExpandRowAndColFromSelection
            );
            this.gridApi.addEventListener(Events.EVENT_DISPLAYED_COLUMNS_CHANGED, this.closeDetails);
        }
        if (this.props.gridOptions && this.props.gridOptions.onGridReady) {
            this.props.gridOptions.onGridReady(params);
        }
    };

    private hideExpandView = (rowToClose: string, viewType: DetailsViewType, dontChangeFocus?: boolean) => {
        switch (viewType) {
            case DetailsViewType.InGrid:
                if (this.gridApi && rowToClose !== undefined) {
                    const row = this.gridApi.getRowNode(rowToClose);
                    row.setExpanded(false);
                    // // Re- get the rowNode, because setExpand update it
                    // // DON'T !! optimize getDisplayedRowAtInIndex(last)
                    console.assert(
                        row === this.gridApi.getRowNode(rowToClose),
                        'not good',
                        row,
                        this.gridApi.getRowNode(rowToClose)
                    );
                    this.gridApi.redrawRows({ rowNodes: [row] });
                    if (!dontChangeFocus) {
                        this.verifyFocusOnCell(undefined /* rowToClose*/);
                        this.gridApi.clearRangeSelection();
                    }
                }
                break;
            default:
                this.verifyFocusOnCell();
        }
    };

    private closeDetails = (dontChangeFocus?: boolean) => {
        if (this.state.expanded && this.lastExpandCell.rowId !== undefined && this.mainEditor) {
            const rowToClose = this.lastExpandCell.rowId;

            this.lastExpandCell.rowId = undefined;
            this.hideExpandView(rowToClose, this.props.expandType!, dontChangeFocus);
            this.mainEditor.setValue('');
            this.setState({ expanded: false });
            if (this.props.onEditorClose) {
                this.props.onEditorClose();
            }
        }
        this.closeExternalView();
    };
    private verifyFocusOnCell = (forceRowId?: string) => {
        if (this.gridApi) {
            const focusCell = this.gridApi.getFocusedCell();
            if (focusCell) {
                let rowIndex = focusCell.rowIndex;
                if (forceRowId !== undefined) {
                    const row = this.gridApi.getRowNode(forceRowId);
                    // is there a better way to do it?
                    if (row === this.gridApi.getDisplayedRowAtIndex(row.rowIndex)) {
                        rowIndex = row.rowIndex;
                    }
                }

                this.gridApi!.setFocusedCell(rowIndex, focusCell.column || this.lastExpandCell.column);
            }
        }
    };
    private updateGridExpand = (row: RowNode, _column: Column, prevRow?: string) => {
        if (row.id === prevRow || !this.gridApi) {
            return;
        }

        // const fixFocusRowIndex = (prevRow !== undefined && row.rowIndex > prevRow);
        // const expandIndexFixer = fixFocusRowIndex ? 1 : 0;
        // const newExpandedIndex = row.rowIndex - expandIndexFixer;

        const rowToRedraw = [row];
        this.lastExpandCell.rowId = row.id;

        if (prevRow !== undefined) {
            const lastRow = this.gridApi!.getRowNode(prevRow);
            lastRow.expanded = false;
            rowToRedraw.push(lastRow);
        }

        if (row.detailNode) {
            row.detailNode.rowHeight = this.getDetailedHeight();
        }

        row.setExpanded(true);
        this.gridApi.ensureIndexVisible(row.rowIndex + 1);
        this.gridApi.ensureIndexVisible(row.rowIndex);

        // this is a react component that needs to be re-rendered.
        //this.gridApi.redrawRows({ rowNodes: [row] });

        this.verifyFocusOnCell(row.id);
        this.gridApi!.clearRangeSelection();

        // redraw without losing focus
        const cell = this.gridApi?.getFocusedCell();
        this.gridApi?.redrawRows({ rowNodes: rowToRedraw });
        this.gridApi?.setFocusedCell(cell.rowIndex, cell.column);
    };

    private closeExternalView() {
        if (this.externalViewContainer) {
            this.externalViewContainer = null;
            if (this.props.closeExternalView) {
                this.props.closeExternalView(this.ExpandViewControl);
            }
        }
    }
    private appendDetailsViewToExternalPanel() {
        if (this.props.expandType === DetailsViewType.ExternalPanel && this.lastExpandCell.rowId !== undefined) {
            if (this.externalViewContainer && this.expandPortal) {
                if (this.expandPortal.parentElement !== this.externalViewContainer) {
                    this.externalViewContainer.appendChild(this.expandPortal);
                }
            } else if (this.props.openExternalView) {
                this.props.openExternalView(this.ExpandViewControl);
            }
            return true;
        }
        return false;
    }

    private setupDetailsView(row: RowNode | string, column: Column, prevRow?: string) {
        if (this.props.expandType === DetailsViewType.BelowGrid && this.belowGridRef.current) {
            const belowRef = this.belowGridRef.current;
            (belowRef.parentElement as HTMLElement).insertBefore(this.expandPortal, belowRef);
        } else if (this.appendDetailsViewToExternalPanel()) {
            return;
        } else if (this.props.expandType === DetailsViewType.InGrid && this.gridApi) {
            const rowNode = typeof row === 'string' ? this.gridApi.getRowNode(row) : (row as RowNode);
            this.updateGridExpand(rowNode, column, prevRow);
        }
        this.closeExternalView();
    }

    private updateExpand = (row: RowNode, column: Column) => {
        if (!this.mainEditor) {
            // don't open the expand if monaco editor not ready
            return;
        }
        if (row.group || column.getColDef().type === 'autoColumn') {
            this.closeDetails(true);
            return;
        }
        if (this.lastExpandCell.rowId === row.id && this.lastExpandCell.column === column) {
            return;
        }

        const opening = this.lastExpandCell.rowId === undefined;
        if (opening) {
            this.updateValueQueue = { inProgress: false, updateWaiting: false };
            this.setState({ expanded: true });
            if (this.props.onEditorOpen) {
                this.props.onEditorOpen!(true);
            }
        }
        const prevRow = this.lastExpandCell.rowId;
        this.lastExpandCell = { rowId: row.id, column };
        this.setupDetailsView(row, column, prevRow);

        this.updateValue();
    };

    private onDoubleClick = (params: CellEvent) => {
        this.lastDoubleClickedTimestamp = Date.now();
        if (!this.isExpandRow(params.column)) {
            this.updateExpand(params.node, params.column);
        }
    };
    private onCellClick = (params: CellEvent) => {
        if (params.event) {
            params.event.stopPropagation();
        }
        if (params.node && params.node.detail) {
            return;
        }
        const mEvent = params.event as MouseEvent;
        const target = mEvent.target as HTMLElement;
        const targetClasses = mEvent && target && target.classList;
        if (
            this.props.onLinkClicked &&
            mEvent instanceof MouseEvent &&
            target &&
            targetClasses &&
            targetClasses.contains('highlighted-url')
        ) {
            const columnId = params.column.getColId();
            const url = (params.data && params.data[columnId]) || target.innerText;
            this.props.onLinkClicked(url, isMacOs ? mEvent.metaKey : mEvent.ctrlKey);
            return;
        }
        if (
            mEvent instanceof MouseEvent &&
            (mEvent.button !== 0 || (targetClasses && targetClasses.contains('expand-close')))
        ) {
            this.closeDetails(true);
            return;
        }
        this.lastClickedTimestamp = Date.now();

        this.delayedClickHandler(params);
    };
    private delayedClickHandler = _.debounce(
        (params: CellEvent) => {
            const t = Date.now();
            const used = this.lastDoubleClickedTimestamp + 300 > t;

            if (this.isExpandRow(params.column)) {
                const { rowId, column } = this.lastExpandCell;
                if (rowId === undefined || rowId !== params.node.id || column !== params.column) {
                    this.updateExpand(params.node, params.column);
                } else {
                    this.closeDetails();
                }
            } else if (this.props.closeExpandViewOnClick && !used) {
                this.closeDetails();
            }
        },
        200,
        { maxWait: 450 }
    );

    private delayedFocusSet = _.debounce(
        (params?: CellFocusedEvent) => {
            const t = Date.now();
            const notUsed = this.lastClickedTimestamp + 150 < t;
            if (!this.gridApi) {
                return;
            }

            const data = this.gridApi.getFocusedCell();
            const shouldWaitForClick = this.props.closeExpandViewOnClick || (data && this.isExpandRow(data.column));

            if (!notUsed && shouldWaitForClick) {
                return;
            }

            if (this.lastExpandCell.rowId !== undefined && (!this.mainEditor || !this.mainEditor.hasWidgetFocus())) {
                if (!data) {
                    this.closeDetails();
                } else {
                    const row = this.gridApi.getDisplayedRowAtIndex(data.rowIndex);
                    if (row.id !== this.lastExpandCell.rowId || data.column !== this.lastExpandCell.column) {
                        if (row.detail) {
                            return;
                        }
                        this.updateExpand(row, data.column);
                        this.verifyFocusOnCell();
                    }
                }
            }

            if (params && this.props.gridOptions && this.props.gridOptions.onCellFocused) {
                this.props.gridOptions.onCellFocused(params);
            }
        },
        30,
        { maxWait: 70 }
    );

    private keyboardHandler = (data: SuppressKeyboardEventParams) => {
        const { keyCode } = data.event;
        if (keyCode === KeyCodes.enter && data.node !== null) {
            this.updateExpand(data.node, data.column);
            return true;
        }
        if (keyCode === KeyCodes.escape) {
            this.closeDetails();
            return true;
        }
        if (keyCode === KeyCodes.tab && this.lastExpandCell.rowId !== undefined && this.gridApi) {
            if (data.node.detail) {
                const row = this.gridApi.getRowNode(this.lastExpandCell.rowId);
                this.gridApi.setFocusedCell(row.rowIndex, this.lastExpandCell.column!);
            } else if (this.mainEditor) {
                this.mainEditor.focus();
            }
            data.event.stopPropagation();
            data.event.preventDefault();
            return true;
        }
        return false;
    };

    private navigationHandler = (params: NavigateToNextCellParams) => {
        if (params.nextCellPosition && this.gridApi && this.lastExpandCell.rowId !== undefined) {
            const rowIndex = params.nextCellPosition.rowIndex;
            if (rowIndex !== params.previousCellPosition.rowIndex) {
                const nextFocusRow = this.gridApi.getDisplayedRowAtIndex(rowIndex);

                let next = rowIndex;
                if (nextFocusRow.detail) {
                    const movingDirection = rowIndex - params.previousCellPosition.rowIndex;
                    next = rowIndex + movingDirection;

                    if (next < 0 || next >= this.gridApi.getDisplayedRowCount()) {
                        return params.previousCellPosition;
                    }
                }
                this.updateExpand(this.gridApi.getDisplayedRowAtIndex(next), params.nextCellPosition.column);
                params.nextCellPosition.rowIndex = nextFocusRow.rowIndex;
            }
        }
        return params.nextCellPosition;
    };

    private getDetailedHeight = (): number => {
        if (!this.state.expanded) {
            return 0;
        }
        if (this.lastDetailedRowHeight === undefined) {
            const container = this.containerRef.current;

            this.lastDetailedRowHeight = Math.max((container && container.offsetHeight / 3) || 300, 50);
        }
        return this.lastDetailedRowHeight;
    };

    private postValueUpdate = () => {
        const { updateWaiting } = this.updateValueQueue;
        this.updateValueQueue = { inProgress: false, updateWaiting: false };
        if (updateWaiting) {
            this.updateValue();
        }
    };

    private updateValue = () => {
        if (!this.shadowEditor || !this.mainEditor || this.updateValueQueue.inProgress) {
            this.updateValueQueue.updateWaiting = true;
            return;
        }
        this.shadowEditor.setValue('');
        const model = this.shadowEditor!.getModel();
        const { column, rowId } = this.lastExpandCell;
        if (!rowId || !this.gridApi || !column || !model) {
            this.mainEditor.setValue('');
            return;
        }
        const row = this.gridApi!.getRowNode(rowId);
        const columns = this.isExpandRow(column) ? this.columnApi!.getAllColumns() : [column];

        let value = '';
        columns.forEach((col) => {
            const { field, headerName, cellRendererParams } = col.getColDef();
            if (!field) {
                return;
            }
            let val = row.data[field];
            let pureStringValue = false;
            if (val === undefined || val === null || val === '') {
                if (this.props.hideEmptyCells) {
                    return;
                }
                val = '';
            } else if (cellRendererParams && cellRendererParams.columnType === 'dynamic') {
                try {
                    val = JSON.stringify(JSON.parse(val), null, val.length > 20 ? '\t' : undefined);
                } catch (e) {
                    pureStringValue = true;
                    // use the original value
                }
            } else {
                val = val.toString();
            }

            if (columns.length > 1) {
                if (pureStringValue) {
                    const linesArr = val.split(/\r?\n/);
                    val = linesArr.join(model.getEOL() + '\t');
                }
                value = value + `"${headerName}": ${val},` + model.getEOL();
            } else {
                value = val;
            }
        });

        this.shadowEditor.setValue(value);
        const foldAction = this.shadowEditor.getAction('editor.foldAll');
        const unFoldAction = this.shadowEditor.getAction('editor.unfold');
        if (foldAction && unFoldAction) {
            this.updateValueQueue.inProgress = true;
            foldAction
                .run()
                .then(() => unFoldAction.run(), this.postValueUpdate)
                .then(() => {
                    if (this.mainEditor && this.shadowEditor) {
                        const viewState = this.mainEditor.saveViewState();
                        const shadowViewState = this.shadowEditor.saveViewState();
                        if (viewState && shadowViewState) {
                            viewState.contributionsState = shadowViewState.contributionsState;
                            viewState.viewState.scrollTop = 0;
                            viewState.viewState.scrollLeft = 0;
                            viewState.cursorState = [MONACO_INITIAL_CURSOR_STATE];
                            this.mainEditor.setValue(this.shadowEditor.getValue());
                            this.mainEditor.restoreViewState(viewState);
                        } else {
                            this.mainEditor.setValue(this.shadowEditor.getValue());
                        }
                    }
                    this.postValueUpdate();
                }, this.postValueUpdate);
        }
    };

    private debounceGridOnRowHeightChanged = _.debounce(
        () => {
            if (this.gridApi) {
                this.gridApi.onRowHeightChanged();
            }
        },
        30,
        { maxWait: 50 }
    );

    // Ag Grid is getting the keyboard before the expand portal
    // So, we have to block them
    private keyboardBlocker = (e: KeyboardEvent) => {
        e.stopPropagation();
    };

    private isExpandRow = (column: Column) => column && column.getColDef().colId === EXPAND_ROW_COL_ID;

    private readonly gridOptions: GridOptions = {
        onCellDoubleClicked: this.onDoubleClick,
        animateRows: false,
        onCellFocused: this.delayedFocusSet,
        onCellClicked: this.onCellClick,
        navigateToNextCell: (params) => this.navigationHandler(params),
        detailCellRenderer: 'detailCellRenderer',
        components: {
            detailCellRenderer: DetailedCellRender,
        },
        detailCellRendererParams: {
            expandPortal: this.expandPortal,
        },
        rowClassRules: {
            expanded: (params: { node: RowNode }) => params.node.detailNode && params.node.expanded,
            'cell-expand': (params: { node: RowNode }) => params.node.id === this.lastExpandCell.rowId,
        },
        getRowClass: (params: { node: RowNode }) => {
            let classes: string[] = [];

            if (this.props.gridOptions && this.props.gridOptions.getRowClass) {
                classes = _.concat(classes, this.props.gridOptions.getRowClass(params));
            }

            if (params.node.detail) {
                classes.push('detailed-row');
            }

            return classes;
        },
        getRowHeight: (params: { node: RowNode }) => {
            if (params.node.detail) {
                return this.getDetailedHeight();
            }
            return 25; // AgGrid Default ;-(
        },
        masterDetail: true,
        defaultColDef: {
            suppressKeyboardEvent: (params) => this.keyboardHandler(params),
            editable: false,
            cellRenderer: wrapCellWithConditionalFormatting(closableCell),
        },
        autoGroupColumnDef: {
            type: 'autoColumn',
        },
    };

    private expandDidMount = (editor: monaco.editor.IStandaloneCodeEditor, _monaco: typeof monaco) => {
        if (typeof _monaco !== 'undefined' && _monaco.languages?.json?.jsonDefaults?.diagnosticsOptions?.validate) {
            _monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: false,
            });
        }
        this.mainEditor = editor;
        this.shadowEditor = _monaco.editor.create(document.createElement('div'), {
            ...this.monacoOptions,
            language: 'json',
            mouseWheelZoom: this.props.mouseWheelZoom !== false,
            readOnly: false,
        });
        editor.addAction({
            label: this.props.strings.closeDetailsView,
            keybindings: [monaco.KeyCode.Escape],
            id: MONACO_ACTION_ID_PREFIX + 'close',
            run: () => this.closeDetails(),
            precondition: '!findWidgetVisible',
            contextMenuGroupId: 'navigation',
            contextMenuOrder: 1,
        });
        editor.addAction({
            label: this.props.strings.backToTable,
            id: MONACO_ACTION_ID_PREFIX + 'backToGrid',
            // tslint:disable-next-line:no-bitwise
            keybindings: [monaco.KeyCode.Tab, monaco.KeyCode.Tab | monaco.KeyMod.Shift],
            run: () => this.verifyFocusOnCell(this.lastExpandCell.rowId),
            contextMenuGroupId: 'navigation',
            contextMenuOrder: 2,
        });
        editor.addAction({
            label: 'Details view in table',
            id: MONACO_ACTION_ID_PREFIX + 'inGrid',
            // tslint:disable-next-line:no-bitwise
            keybindings: [monaco.KeyCode.KEY_1 | monaco.KeyMod.Alt],
            run: () => this.props.onExpandTypeChange!(DetailsViewType.InGrid),
            contextMenuGroupId: 'navigation2',
            contextMenuOrder: 100,
        });
        editor.addAction({
            label: 'Details view below table',
            id: MONACO_ACTION_ID_PREFIX + 'belowGrid',
            // tslint:disable-next-line:no-bitwise
            keybindings: [monaco.KeyCode.KEY_2 | monaco.KeyMod.Alt],
            run: () => this.props.onExpandTypeChange!(DetailsViewType.BelowGrid),
            contextMenuGroupId: 'navigation2',
            contextMenuOrder: 101,
        });
        editor.addAction({
            label: 'Details view in external Panel',
            id: MONACO_ACTION_ID_PREFIX + 'external',
            // tslint:disable-next-line:no-bitwise
            keybindings: [monaco.KeyCode.KEY_3 | monaco.KeyMod.Alt],
            run: () => this.props.onExpandTypeChange!(DetailsViewType.ExternalPanel),
            contextMenuGroupId: 'navigation2',
            contextMenuOrder: 101,
        });

        editor.addOverlayWidget({
            getId: () => 'KustoWeb.DetailsView',
            getDomNode: () => this.detailedViewSelectionPortal,
            getPosition: () => null,
        });
    };

    private debouncedRemoveExpandRowAndColFromSelection = _.debounce((params: RangeSelectionChangedEvent) => {
        if (!this.gridApi || !params.finished || this.lastExpandCell.rowId === undefined) {
            return;
        }

        const origSelection = this.gridApi.getCellRanges();

        let changed = false;
        const row = this.gridApi.getRowNode(this.lastExpandCell.rowId);
        if (!row || !row.detailNode) {
            return;
        }

        const rowIndex = row.detailNode.rowIndex;

        const cleanSelections =
            origSelection &&
            origSelection.reduce<CellRangeParams[]>((clean, range) => {
                const shouldRemoveFirstCol =
                    range.columns &&
                    range.columns.length > 0 &&
                    range.columns[0].getColDef().colId === EXPAND_ROW_COL_ID;

                const cleanColumn = shouldRemoveFirstCol ? range.columns.slice(1) : range.columns;
                if (cleanColumn.length === 0) {
                    changed = true;
                    return clean;
                }

                // ag grid supports selecting all rows in which case both start and end will be undefined.
                // TOOD not sure if that's the right logic in this case.
                if (range.startRow === undefined || range.endRow === undefined) {
                    return clean;
                }

                const rowsIndex = [range.startRow.rowIndex, range.endRow.rowIndex];
                const start = Math.min(...rowsIndex);
                const end = Math.max(...rowsIndex);
                const detailsInRange = rowIndex !== undefined && rowIndex >= start && rowIndex <= end;
                const cleanRows: { start: number; end: number }[] = detailsInRange
                    ? [
                          { start, end: rowIndex - 1 },
                          { start: rowIndex + 1, end },
                      ]
                    : [{ start, end }];
                cleanRows
                    .filter((crow) => crow.start <= crow.end)
                    .forEach((rows) =>
                        clean.push({
                            rowStartIndex: rows.start,
                            rowEndIndex: rows.end,
                            columnStart: cleanColumn[0],
                            columnEnd: cleanColumn[cleanColumn.length - 1],
                        })
                    );
                changed = changed || shouldRemoveFirstCol || detailsInRange;
                return clean;
            }, []);

        if (changed && cleanSelections) {
            setImmediate(() => {
                if (this.gridApi) {
                    this.gridApi.clearRangeSelection();
                    cleanSelections.forEach((range) => this.gridApi!.addCellRange(range));
                }
            });
        }
    }, 70);
}
