import { Resource } from '../../resource/Resource';
import type { ResourceMetadata } from '../../resource/metadata/ResourceMetadata';
import type { ResourceCache } from '../../resource/ResourceCache';
import { ResourceErrors } from '../../resource/errors/ResourceErrors';

import { FeatureFlag, RtdAccount, UserSettings } from '../domain';
import {
    convertUserSettingsFromBackend,
    convertUserSettingsToBackend,
    UserSettingsResponse,
} from '../../migration/userSettings';
import { InterfaceFor } from '../../common';

import { RestApiClient, getJson } from './RestApiClient';

export interface ITenantResponse {
    featureFlags: FeatureFlag[];
}

export type IUserService = InterfaceFor<UserService>;

export class UserService {
    private readonly getSettingsResource: Resource<[], UserSettingsResponse>;
    private readonly updateSettingsResource: Resource<[UserSettingsResponse], UserSettingsResponse>;

    constructor(
        private readonly resourceMetadata: ResourceMetadata,
        private readonly resourceCache: ResourceCache,
        private readonly resourceErrors: ResourceErrors,
        private readonly restApi: RestApiClient,
        private readonly account?: RtdAccount
    ) {
        this.getSettingsResource = new Resource<[], UserSettingsResponse>(
            this.resourceMetadata,
            this.resourceCache,
            this.resourceErrors,
            () => this.restApi.httpGet(`/user`).then((resp) => getJson<UserSettingsResponse>(resp)),
            undefined,
            'getSettings'
        );

        this.updateSettingsResource = new Resource<[UserSettingsResponse], UserSettingsResponse>(
            this.resourceMetadata,
            this.resourceCache,
            this.resourceErrors,
            (settings: UserSettingsResponse) =>
                this.restApi.httpPut(`/user`, settings).then((resp) => getJson<UserSettingsResponse>(resp)),
            undefined,
            'updateSettings'
        );
    }

    public getCurrentUser(): RtdAccount | undefined {
        return this.account;
    }

    public getTenantProfile(): Promise<ITenantResponse> {
        return this.restApi.httpGet('/tenant').then((resp) => getJson<ITenantResponse>(resp));
    }

    public getSettings(invalidate = false): Promise<UserSettings> {
        return (
            this.getSettingsResource
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .readWithInvalidate(invalidate as any)
                .then(convertUserSettingsFromBackend)
        );
    }

    public async updateSettings(settings: UserSettings): Promise<UserSettings> {
        const serializedSettings = convertUserSettingsToBackend(settings);
        const res = await this.updateSettingsResource.read(serializedSettings);
        return convertUserSettingsFromBackend(res);
    }
}
