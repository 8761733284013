import { IButtonStyles } from 'office-ui-fabric-react';
import { cssVariableNames } from '../../../../../domain/theming/themes';

export const errorButtonStyles = (): Partial<IButtonStyles> => ({
    rootHovered: {
        color: cssVariableNames.neutralDark,
        backgroundColor: cssVariableNames.neutralLighter,
    },
    rootPressed: {
        color: cssVariableNames.neutralDark,
        backgroundColor: cssVariableNames.neutralLighter,
    },
    iconPressed: {
        color: cssVariableNames.themePrimary,
    },
});
