// This file was created to fix the accessibility for the side panel (columns)
// it extends ag-grid side panel (https://www.ag-grid.com/javascript-grid-side-bar/)

// 1. Known Issues:
// 1.1. ag-bugs:
// 1.1.1. Problem: onToolPanelVisibleChanged is not triggered (AG-2234 https://www.ag-grid.com/ag-grid-pipeline/).
// We cannot use this event to add the accessibility features.
// Solution: we manually add onclick to the columns button.
// 1.1.2. Destroy method is not being called on class ColumnToolPanel.
// Solution: TBD. Currently the function is written but not being called.
// We need to decide how to free the event listeners.

// 1.2. Screen reader (Narrator) on scan-mode issues:
// 1.2.1. Narrator doesn't work well on non Microsoft browsers
// 1.2.2. There isn't a way to check if a screen reader is being used.
// 1.2.3. Sometimes the screen reader looses focus or just stops reading and there's a need to
// restart the reader or Microsoft Edge.
// 1.2.4. Does not move :focus when arrows keys are used. This is by design. no mouse events are triggered catch it.
// 1.2.5. Have the tenancy to focus on text element. this is the Narrator focus, you cannot catch the
// screen reader's focus or know when it happens

// 2. Testing
// Warning: if you want to make changes to this file, please run the following set of manual
// tests to verify that there are no accessibility regressions
// Tip: CTRL+M on the editor will trigger TAB key focusing
// Tip: CAPS+SPACE will toggle scan-mode
// Use arrows keys to navigate (up/down). right/left works differently when scan-mode is ON.
// Use ENTER or SPACE to check/uncheck a checkbox.
// 2.1. Turn screen reader OFF and check that you can navigate with keyboard and mouse.
// Test that the side bar works with both input devices.
// 2.2. Turn ON the screen reader make sure that scan-mode is OFF. Navigate with keyboard
// like before. The screen reader should read [status (checked/unchecked) -> type (checkbox) -> label].
// 2.3. Turn ON scan-mode and retest.

// 3. Technical:
// A line with draggable time in the column sidebar is built like this:
// <div>
// ____<span wrapper>
// ________<span checked />
// ________<span unchecked />
// ________<span mixed />
// ________<span empty-label />
// ____</span wrapper>
// ____<span draggable />
// ____<span real-label />
// </div>

// 4. Solution:
// We use role="checkbox" on the wrapper so it would encapsulate the states and hide the draggable span
// and the labels from the narrator.
// All the aria-checked logic is done by adding event listeners.

// 5. Solution - Known Issues:
// When scan-mode is ON:
// 5.1. The checkboxes are not being focused. scan mode up and down arrows don't actually move the focus
// That's by design of the screen readers. Thus screen reader won't change scrolling when moving around
// npm between checkboxes in scan mode. This OK since screen reader users don't use visual scrolling.
// and works like a regular checkbox list inside a scroll with no text.
// 5.2. Using the UP arrow, narrator outlines the drag element rather than the checkbox.
// This is a quirk that looks like a narrator bug, but it has no impact on user since the focus
// is actually on the checkbox.

// 6. Alternative solution:
// Hide the checkboxes from the narrator (by giving them presentation role or aria-hidden) and use the real-label
// as the focusable elements
// the below bug only happen when scan-mode is ON, even then, doesn't seem to happen all the time(?)
// The Narrator will use its focus on the text when using the arrow keys, but clicking
// on ENTER/SPACE sometimes moves the screen-reader-focus and it surrounds the clickable text.
// Pressing on ENTER/SPACE again will just add a :focus on the element, another press will trigger the onclick event.
// Basically you will need to click twice to active the onclick.

import { Events } from '@ag-grid-enterprise/all-modules';
import {
    ColumnToolPanel,
    ToolPanelColumnCompParams,
} from '@ag-grid-enterprise/column-tool-panel/dist/es6/columnToolPanel/columnToolPanel';
import { AccessibilitySelectorFixer } from './AccessibilitySelectorFixer';
export const ColumnsButtonSelector = '.ag-side-bar .ag-side-buttons button';
export const ColumnsPanelFocusSelector = '.ag-column-select-header-filter-wrapper input[type="text"]';

export class AccessibilityColumnsFixer extends AccessibilitySelectorFixer {
    constructor(
        getComponentRoot: () => HTMLElement | null,
        private onClose?: () => void,
        private onKeydownCallback?: (e: KeyboardEvent) => void
    ) {
        super(
            [
                {
                    // This is needed to auto focus the filter text input when
                    // the columns toolbar was expanded and visible.
                    selector: ColumnsPanelFocusSelector,
                    fixer: (elem) => {
                        elem.focus();
                        elem.setAttribute('data-is-kusto-panel', 'true');
                    },
                    when: ['focus', 'enableEscape'],
                },
                {
                    // This is needed to close the column toolbar on Escape keydown
                    selector: 'me',
                    fixer: (elem) => {
                        elem.addEventListener('keydown', this.panelKeyboardClick);
                    },
                    destroyer: (elem) => {
                        elem.removeEventListener('keydown', this.panelKeyboardClick);
                    },
                    once: true,
                    when: ['enableEscape'],
                },
            ],
            getComponentRoot
        );
    }

    private panelKeyboardClick = (e: KeyboardEvent) => {
        if (e.currentTarget != null && this.onClose) {
            switch (e.key) {
                case 'Escape':
                    this.onClose();
                    e.preventDefault();
                    e.stopPropagation();
                    break;
                default:
                    if (this.onKeydownCallback) {
                        this.onKeydownCallback(e);
                    }
            }
        }
    };
}

export class AccessibilityColumnsPanel extends ColumnToolPanel {
    private sideBarParams:
        | (ToolPanelColumnCompParams & {
              onKeydown?: (e: KeyboardEvent) => boolean;
          })
        | null = null;
    private fixer: AccessibilityColumnsFixer;

    constructor() {
        super();
        this.fixer = new AccessibilityColumnsFixer(() => this.getGui(), this.closePanel, this.onKeydown);
    }

    init(params: ToolPanelColumnCompParams) {
        super.init(params);
        this.sideBarParams = params;
        params.api.addEventListener(Events.EVENT_GRID_READY, this.eventGridReady);
    }
    // not being called due to ag-bug
    destroy() {
        if (this.sideBarParams) {
            this.sideBarParams.api.removeEventListener(Events.EVENT_GRID_READY, this.eventGridReady);
        }
        this.fixer.destroy();
        const colBtn = document.querySelector(ColumnsButtonSelector);
        if (colBtn) {
            colBtn.removeEventListener('click', this.columnsPanelOpening);
        }
    }
    private eventGridReady = (_e: Event) => {
        // ColumnSideBar component is created before the rest of the grid is
        // ready (including) the "Columns" main button
        // register for notification on opening the sidebar - unfortunately ag-grid doesn't provide notification
        const colBtn = document.querySelector(ColumnsButtonSelector);
        if (colBtn) {
            colBtn.addEventListener('click', this.columnsPanelOpening);
        }
        // In order to make sure we clean - try twice on destroy and when grid ready fired
        if (this.sideBarParams) {
            this.sideBarParams.api.removeEventListener(Events.EVENT_GRID_READY, this.eventGridReady);
        }
    };

    private columnsPanelOpening = (_e: Event) => {
        if (!this.sideBarParams || !this.sideBarParams.api.isToolPanelShowing()) {
            return;
        }
        this.fixer.debounceFix('enableEscape');
    };

    private onKeydown = (e: KeyboardEvent) => {
        if (this.sideBarParams && this.sideBarParams.onKeydown) {
            this.sideBarParams.onKeydown(e);
        }
    };
    private closePanel = () => {
        if (this.sideBarParams) {
            this.sideBarParams.api.closeToolPanel();
            document.querySelector<HTMLElement>(ColumnsButtonSelector)!.focus();
        }
    };
}
