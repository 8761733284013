import React from 'react';
import {
    PrimaryButton,
    DefaultButton,
    TextField,
    Checkbox,
    Label,
    Separator,
    IChoiceGroupOption,
    IDropdownOption,
    TooltipHost,
    Spinner,
    SpinnerSize,
} from 'office-ui-fabric-react';
import { useState, useMemo, useEffect, useCallback, useLayoutEffect } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import { APP_STRINGS, APP_CONSTANTS } from '../../res';
import { useThemeState, useVisualConfig, validateDataSourceName, VisualOptions } from '../../domain';
import { UDataSource, IDashboardListItem, IDashboardDocument, IManualKustoDataSource } from '../../core/domain';
import { RTDBackingDropdown, CustomElementDropdown, PanelWithFooter, RTDDropdownOption } from '../../components/fabric';
import {
    useThunkReducer,
    compactMap,
    Result,
    parseException,
    err,
    ok,
    useAbortController,
    usePullState,
    isAbortError,
} from '../../common';
import { CreateDashboardNameField } from '../../components/Forms/dashboard/CreateDashboardDialog';
import { RadioPivot } from '../../components/fabric/pivot/RadioPivot';
import { TextFieldWithControlledError } from '../../components/fabric/textField/TextFieldWithControlledError';
import { RtdCoreSuspense } from '../../core/react';
import { RootErrorBoundary } from '../../components/errors';
import { IRtdDashboardsCore } from '../../core';
import { useCore } from '../../core/react';
import { formatMigrationError } from '../../components/errors/formatMigrationError';
import { WarningsList } from '../../components/errors/WarningsList';
import { RtdHeader } from '../../components';
import { useDashboardStore } from '../../store';
import { dashboardsCoreContext } from '../../core/react/context';

import { queryImporterReducerFunc, initialState, QueryImporterReducerState } from './queryImporterReducer';
import { importQuery, QueryImportResult } from './importQuery';
import { dataSourcesEqual } from './lib';

import styles from './QueryImporter.module.scss';

function useDashboardList(core: IRtdDashboardsCore) {
    const [dashboardsList, setDashboardsList] = useState<Result<IDashboardListItem[]> | undefined>();

    React.useEffect(() => {
        const abortController = new AbortController();
        core.dashboardService
            .getDashboards(abortController.signal)
            .then((res) => setDashboardsList(ok(res)))
            .catch((e) => {
                if (isAbortError(e)) return;

                setDashboardsList(err(parseException(e)));
            });
        return () => abortController.abort();
    }, [core]);

    return dashboardsList;
}

type UseDashboardState = undefined | Result<{ data: IDashboardDocument; warnings: string[] }>;

function useDashboard(core: IRtdDashboardsCore, id: string | undefined): UseDashboardState {
    const [dashboard, setDashboard] = useState<Result<{ data: IDashboardDocument; warnings: string[] }> | undefined>();

    React.useEffect(() => {
        // Clear last dashboard
        setDashboard(undefined);
        if (id === undefined) {
            setDashboard(undefined);
            return;
        }
        const abortController = new AbortController();
        core.dashboardService
            .getDashboard(id, abortController.signal)
            .then((res) => {
                if (res.kind === 'err') {
                    setDashboard(err(formatMigrationError(res.err)));
                } else {
                    setDashboard(res);
                }
            })
            .catch((e) => {
                if (isAbortError(e)) {
                    return;
                }
                setDashboard(err(parseException(e)));
            });
        return () => abortController.abort();
    }, [id, core]);

    return dashboard;
}

/**
 * @property cardTitle Default card title
 */
export interface IQueryImporterProps {
    isOpen: boolean;

    panelTitle: string;
    query: string;
    dataSource: Omit<IManualKustoDataSource, 'id'>;
    onClose: () => void;

    visualOptions?: Partial<VisualOptions>;
    visualType?: string;
    cardTitle?: string;
    /**
     * @param dashboardId Will be the string `'new'` when creating a new dashboard
     */
    onStartSave?: (dashboardId: string) => void;
    onCompletedSave?: (result: QueryImportResult) => void;
}

interface IInnerQueryImporterProps extends Omit<IQueryImporterProps, 'isOpen' | 'panelTitle'> {
    setFooter: (footer: JSX.Element) => void;
    setOnDismiss: (onDismiss: () => void) => void;
}

const emptyOption: RTDDropdownOption = {
    key: APP_CONSTANTS.emptySelection,
    text: APP_STRINGS.domain.parameter.selection.none,
    hidden: true,
};

const backingDropdown: RTDBackingDropdown = {
    type: 'standard',
};

const useDataSourceNameError = (
    { dataSourceName, defaultDataSourceName, dedupeDataSource }: QueryImporterReducerState,
    dataSource: Omit<IManualKustoDataSource, 'id'>,
    dataSources: IManualKustoDataSource[]
): string | undefined => {
    const hasMatchingDataSource = useMemo(
        () => dataSources.some((d) => d.kind === 'manual-kusto' && dataSourcesEqual(d, dataSource)),
        [dataSources, dataSource]
    );

    const existingDataSourceNames = useMemo(
        () => new Set(dataSources.map((d) => d.name.trim().toLowerCase())),
        [dataSources]
    );

    const combinedDataSourceName = dataSourceName ?? defaultDataSourceName;

    return useMemo(() => {
        if (dedupeDataSource && hasMatchingDataSource) {
            return undefined;
        }
        return validateDataSourceName(combinedDataSourceName, existingDataSourceNames);
    }, [combinedDataSourceName, dedupeDataSource, existingDataSourceNames, hasMatchingDataSource]);
};

const validationErrorFromState = (
    state: QueryImporterReducerState,
    selectedDashboardId: string | undefined,
    dataSourceNameError: string | undefined,
    dataSources: Array<UDataSource | undefined>
): string | undefined => {
    if (dataSourceNameError) {
        return dataSourceNameError;
    }
    if (state.tileTitle.trim() === '') {
        return APP_STRINGS.hostLib.queryImporter.errors.queryNameMissing;
    }

    if (state.dashboard.type === 'new') {
        if (state.dashboard.name.trim() === '') {
            return APP_STRINGS.hostLib.queryImporter.errors.dashboardNameMissing;
        }
    } else {
        if (selectedDashboardId === undefined || selectedDashboardId === APP_CONSTANTS.emptySelection) {
            return APP_STRINGS.hostLib.queryImporter.errors.noDashboardSelected;
        }
    }

    if (state.dashboard.type !== 'new' && state.dashboard.id !== undefined && dataSources === undefined) {
        return APP_STRINGS.hostLib.queryImporter.errors.loadingSelectedDashboard;
    }

    return undefined;
};

/**
 * Error handling in this component has correctness issues related to error
 * handling being async. It also probably has performance issues. Once we
 * improve the data modeling in this app we should improve these things. Data
 * modeling discussion is on WI #7394324
 */
const InnerQueryImporter: React.FC<IInnerQueryImporterProps> = ({
    query,
    cardTitle: defaultTileTitle,
    dataSource,
    visualOptions,
    visualType,
    onClose,
    onStartSave,
    onCompletedSave,
    setFooter,
    setOnDismiss,
}) => {
    const abortController = useAbortController();
    const core = useCore();
    const dashboardStore = useDashboardStore();
    const visualConfig = useVisualConfig();
    const dashboardList = useDashboardList(core);

    const [saving, setSaving, getSaving] = usePullState(false);
    const [state, localDispatch, getLocalState] = useThunkReducer(queryImporterReducerFunc, initialState);

    const dashboard = useDashboard(core, state.dashboard.type === 'new' ? undefined : state.dashboard.id);

    const dataSources = useMemo(() => {
        return dashboard?.value === undefined
            ? []
            : (Object.values(dashboard.value.data.dataSources).filter(
                  (d) => d.kind === 'manual-kusto'
              ) as IManualKustoDataSource[]);
    }, [dashboard]);

    const dashboardListOptions = useMemo(() => {
        const sortedOptions = dashboardList?.value
            ? compactMap(dashboardList.value, (d) => ({
                  key: d.id,
                  text: d.title,
                  data: d,
              })).sort((a, b) => a.text.localeCompare(b.text))
            : undefined;

        return [emptyOption, ...(sortedOptions ? sortedOptions : [])];
    }, [dashboardList]);

    const selectedDashboardId =
        state.dashboard.type === 'existing'
            ? state.dashboard.id ?? APP_CONSTANTS.emptySelection
            : APP_CONSTANTS.emptySelection;

    const onPivotChange = useCallback(
        (option: IChoiceGroupOption) =>
            localDispatch({
                type: 'setSelectedDashboardType',
                dashboardType: option.key as 'new' | 'existing',
            }),
        [localDispatch]
    );

    const onChangeDashboardName = useCallback(
        (_event: unknown, name: string | undefined) =>
            localDispatch({
                type: 'updateProperties',
                properties: {
                    dashboard: {
                        type: 'new',
                        name: name ?? '',
                    },
                },
            }),
        [localDispatch]
    );

    const onChangeTileTitle = useMemo(
        () =>
            debounce(
                (_event: unknown, value: string | undefined) =>
                    localDispatch({
                        type: 'updateProperties',
                        properties: {
                            tileTitle: value,
                        },
                    }),
                200
            ),
        [localDispatch]
    );

    const onChangeDataSourceName = useMemo(
        () =>
            debounce(
                (_event: unknown, value: string | undefined) =>
                    localDispatch({
                        type: 'updateProperties',
                        properties: {
                            dataSourceName: value,
                        },
                    }),
                200
            ),
        [localDispatch]
    );

    const onToggleVisitDashboard = useCallback(
        (_event: unknown, value: boolean | undefined) =>
            localDispatch({
                type: 'updateProperties',
                properties: {
                    visitDashboard: value,
                },
            }),
        [localDispatch]
    );

    const onToggleDedupeDataSource = useCallback(
        (_event: unknown, value: boolean | undefined) =>
            localDispatch({
                type: 'updateProperties',
                properties: {
                    dedupeDataSource: value,
                },
            }),
        [localDispatch]
    );

    const onChangeDashboardId = useCallback(
        (_event: unknown, option: IDropdownOption | undefined) =>
            localDispatch({
                type: 'updateProperties',
                properties: {
                    dashboard: {
                        type: 'existing',
                        id: option?.key as string,
                    },
                },
            }),
        [localDispatch]
    );

    const dataSourceNameError = useDataSourceNameError(state, dataSource, dataSources);
    const validationError = validationErrorFromState(state, selectedDashboardId, dataSourceNameError, dataSources);
    const isValid = validationError === undefined;
    const isNew = state.dashboard.type === 'new';

    const handleSubmit = React.useCallback(() => {
        if (!isValid || getSaving()) {
            return;
        }

        const onStart = (dashboardId: string) => {
            setSaving(true);
            onStartSave?.(dashboardId);
        };

        const onComplete = (result: QueryImportResult) => {
            setSaving(false);
            onClose();
            onCompletedSave?.(result);
        };

        importQuery(
            core,
            dashboardStore,
            visualConfig,
            query,
            dataSource,
            dashboard?.value?.data,
            visualOptions,
            visualType,
            getLocalState,
            abortController.signal,
            onStart,
            onComplete
        );
    }, [
        isValid,
        getSaving,
        setSaving,
        onClose,
        core,
        dashboardStore,
        visualConfig,
        query,
        dataSource,
        dashboard?.value?.data,
        visualOptions,
        visualType,
        getLocalState,
        abortController,
        onStartSave,
        onCompletedSave,
    ]);

    // setOnDismiss is provided with a lambda, as otherwise onClose is treated as a reducer
    useEffect(() => setOnDismiss(() => onClose), [onClose, setOnDismiss]);

    useLayoutEffect(() => {
        setFooter(
            // TODO: It would be better if we showed the users all the errors, and not
            // just once of them in the tooltip
            <>
                <TooltipHost content={validationError}>
                    <PrimaryButton onClick={handleSubmit} disabled={validationError !== undefined || saving}>
                        {APP_STRINGS.hostLib.queryImporter.pin}
                    </PrimaryButton>
                </TooltipHost>
                <DefaultButton onClick={onClose}>{APP_STRINGS.utilButtons.cancel}</DefaultButton>
            </>
        );
    }, [validationError, handleSubmit, onClose, setFooter, saving]);

    useEffect(
        () =>
            localDispatch({
                type: 'initialize',
                defaultTileTitle,
                defaultDataSourceName: dataSource.name,
            }),
        [defaultTileTitle, dataSource.name, localDispatch]
    );

    return (
        <>
            <TextField
                label={APP_STRINGS.hostLib.queryImporter.name}
                placeholder={state.defaultTileTitle ?? APP_STRINGS.hostLib.queryImporter.titleTitlePlaceholder}
                onChange={onChangeTileTitle}
                required={state.defaultTileTitle === undefined}
                disabled={saving}
            />
            <TextFieldWithControlledError
                label={APP_STRINGS.forms.dataSource.name}
                placeholder={state.defaultDataSourceName ?? APP_STRINGS.forms.dataSource.namePlaceholder}
                onChange={onChangeDataSourceName}
                required={state.defaultDataSourceName === undefined}
                error={dataSourceNameError}
                disabled={saving}
            />
            <br />
            <Checkbox
                label={APP_STRINGS.hostLib.queryImporter.dedupeDataSource}
                disabled={isNew || saving}
                checked={state.dedupeDataSource}
                onChange={onToggleDedupeDataSource}
            />
            <Label className={styles.dashboardLabel}>{APP_STRINGS.dashboardPage.dashboard}</Label>
            <Separator className={styles.separator} />
            <RadioPivot
                items={[
                    {
                        key: 'existing',
                        title: APP_STRINGS.hostLib.queryImporter.existingDashboard,

                        content: (
                            <CustomElementDropdown
                                label={APP_STRINGS.hostLib.queryImporter.selectDashboard}
                                options={dashboardListOptions}
                                selectedKey={selectedDashboardId}
                                onChange={onChangeDashboardId}
                                noSelectionTitle={APP_STRINGS.hostLib.queryImporter.dashboardNoSelection}
                                calloutProps={{ calloutMaxHeight: 500 }}
                                required={true}
                                backingDropdown={backingDropdown}
                                // _should_ be impossible for both errors to appear at once
                                errorMessage={dashboard?.err ?? dashboardList?.err}
                                disabled={saving}
                            />
                        ),
                    },
                    {
                        key: 'new',
                        title: APP_STRINGS.hostLib.queryImporter.newDashboard,
                        content: (
                            <CreateDashboardNameField
                                // Should always be 'new'
                                value={state.dashboard.type === 'new' ? state.dashboard.name : ''}
                                onChange={onChangeDashboardName}
                                required={true}
                                disabled={saving}
                            />
                        ),
                    },
                ]}
                selectedKey={state.dashboard.type}
                onChange={onPivotChange}
            />
            <br />
            <Checkbox
                label={APP_STRINGS.hostLib.queryImporter.viewDashboardAfterCreation}
                defaultChecked={state.visitDashboard}
                onChange={onToggleVisitDashboard}
                disabled={saving}
            />
            {dashboard?.value && dashboard.value.warnings.length !== 0 && (
                <>
                    <br />
                    <RtdHeader level={3}>{APP_STRINGS.migration.warnings.onLoadWithWarningsTitle}</RtdHeader>
                    <WarningsList warnings={dashboard.value.warnings} />
                </>
            )}
        </>
    );
};

function onRenderLoading() {
    return <Spinner size={SpinnerSize.large} />;
}

export const QueryImporter: React.FC<IQueryImporterProps> = ({ isOpen, panelTitle, ...props }) => {
    const core = React.useContext(dashboardsCoreContext);
    const theme = useThemeState();
    // Due to the need to keep the Panel mounted, but defer loading of RTD core, we need some way to emit
    // footer and onDismiss callbacks using the results of the deferred hooks. Hence useState
    const [footer, setFooter] = useState<JSX.Element>();
    const [onDismiss, setOnDismiss] = useState<() => void>();

    const content = <InnerQueryImporter {...props} setFooter={setFooter} setOnDismiss={setOnDismiss} />;
    return (
        <PanelWithFooter
            className={classNames(theme.classNames, { [styles.initializing]: core.status === 'deferred' })}
            headerText={panelTitle}
            isOpen={isOpen}
            isLightDismiss={true}
            // Footer is not optional
            footer={footer ?? <></>}
            isFooterAtBottom={true}
            onDismiss={onDismiss}
            // Likely hosted outside of RTD, so disable global loading
            disableGlobalLoading={true}
        >
            {isOpen && (
                <RtdCoreSuspense onRenderLoading={onRenderLoading}>
                    <RootErrorBoundary>{content}</RootErrorBoundary>
                </RtdCoreSuspense>
            )}
        </PanelWithFooter>
    );
};
