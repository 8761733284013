import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useEffect } from 'react';
import { History } from 'history';
import { reaction, runInAction } from 'mobx';
import { useHistory } from 'react-router-dom';

import { useGlobalDispatch } from '../../store/redux/context';
import { IRtdDashboardsCore, useCore } from '../../core';
import { DashboardStore, GlobalAction, useDashboardStore } from '../../store';

export interface IRouteSideEffectsProps extends RouteComponentProps {
    baseUrl?: string;
}

function useClearFormsOnNav(history: History, dispatch: React.Dispatch<GlobalAction>) {
    useEffect(() => {
        let prevLocation = history.location;

        return history.listen((newLocation) => {
            if (newLocation.pathname !== prevLocation.pathname) {
                // Only clear if path changes
                dispatch({ type: 'clearAllForms' });
            }

            prevLocation = newLocation;
        });
    }, [history, dispatch]);
}

function useClearErrorsOnDashboardChange(core: IRtdDashboardsCore, store: DashboardStore) {
    useEffect(
        () =>
            reaction(
                () => store.state?.document,
                () => core.resourceErrors.clearAllErrors()
            ),
        [core, store]
    );
}

export const RouteSideEffects: React.FC = () => {
    const core = useCore();
    const dashboardStore = useDashboardStore();
    const [dispatch] = useGlobalDispatch();
    const history = useHistory();

    useClearErrorsOnDashboardChange(core, dashboardStore);

    useClearFormsOnNav(history, dispatch);

    useEffect(() => {
        return runInAction(() => {
            core.history.h.set(history);
            return () => runInAction(() => core.history.h.set(undefined));
        });
    }, [core.history, history]);

    return null;
};
