/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { types, getRoot, tryReference } from 'mobx-state-tree';
import _ from 'lodash';

import { QueryCompletionInfo } from './queryCompletionInfo';
import { RootStore } from './rootStore';
import { dependencies } from '../dependencies';

export const ResultCache = types
    .model('ResultCache', {
        executions: types.map(QueryCompletionInfo),
    })
    .views((self) => ({
        get latestExecutionByQuery() {
            const groups = _(
                Array.from(self.executions.values()).filter(
                    (execution) =>
                        execution.request && execution.request.timeStarted && execution.request.normalizedQueryText
                )
            )
                .sortBy((execution) => execution.request!.timeStarted)
                .keyBy((execution) => execution.request!.normalizedQueryText);

            return groups.value();
        },
    }))
    .actions((self) => {
        // It's only 'desired' because if will never delete a result from the metadata if it's currently displayed on one
        // of the tabs.thus if we have more than 50 open tabs with the results, we'll persist all of their metadata.
        const desiredMaxMetadataCacheSize = 50;

        function getReferencedIds() {
            return new Set(
                (getRoot(self) as RootStore).tabs.tabs
                    .filter((tab) => {
                        const completionInfo = tryReference(() => tab.completionInfo);
                        return completionInfo && completionInfo.id;
                    })
                    .map((tab) => tab.completionInfo!.id!)
            );
        }

        function put(completionInfo: QueryCompletionInfo) {
            self.executions.put(completionInfo);
        }

        function remove(completionInfo: QueryCompletionInfo) {
            self.executions.delete(completionInfo.id!.toString());
        }

        /**
         * Prune the least recently executed execution from metadata and data. Do it only if size is too big.
         */
        function pruneOne() {
            // if the executions metadata isn't too large, we return now.
            if (self.executions.size <= desiredMaxMetadataCacheSize) {
                return;
            }

            // Find least recently executed and remove it (as long as it's not displayed in any tab)
            const referencedIds = getReferencedIds();
            const candidatesForRemoval = Array.from(self.executions.values()).filter(
                (execution) => execution.id && execution.timeEnded && !referencedIds.has(execution.id)
            );
            if (candidatesForRemoval.length === 0) {
                return;
            }

            const lruId = candidatesForRemoval.reduce((prev, next) => (next.timeEnded < prev.timeEnded ? next : prev))
                .id!;

            // remove from metadata
            self.executions.delete(lruId.toString());

            // remove execution data (fire and forget).
            dependencies.queryResultStore.removeResultsAsync(lruId);
        }

        /**
         * prune old execution metadata. preserve all executions that some tab shows, plus most recently executed
         * ones, until you reach some limit N. (we don't want to break the tabs).
         * Note that after pruning the data structure can be bigger than N - in case we reference more than N executions
         * in the tabs results. (which means we have at least N tabs open).
         * If this is the first run of this code (older versions there was no limit on result cache length)
         * the number of elements to sift through and sort can be large.
         * This means that the sorting can take a while.
         * hopefully it's a rare case and it won't take too long.
         */
        function pruneAll() {
            // get all ids referenced by tabs.
            const referencedIds = getReferencedIds();
            const allExecutions = Array.from<QueryCompletionInfo>(self.executions.values());

            // we want to delete all but maxSize items. The nubmer will be negative or 0 if we don't want to delete anything
            const numberOfItemsToDelete = allExecutions.length - desiredMaxMetadataCacheSize;

            if (numberOfItemsToDelete <= 0) {
                return;
            }

            const notReferencedExecutions = allExecutions.filter((execution) => !referencedIds.has(execution.id!));
            const referencedExecutions = allExecutions.filter((execution) => referencedIds.has(execution.id!));
            const endTimeComparer = (a: QueryCompletionInfo, b: QueryCompletionInfo) =>
                a.timeEnded.getTime() - b.timeEnded.getTime();
            notReferencedExecutions.sort(endTimeComparer);

            // numberOfItemsToDelete can be larger than the idsToDelete (in which case splice will delete all executions).
            // this can happen if we have so many tabs open with results that they take up all of the recall
            // slots.
            const idsToDelete = notReferencedExecutions
                .splice(0, numberOfItemsToDelete)
                .map((execution) => execution.id!);

            // notReferencedExecutions now only contains the latest executions to keep after the splicing
            const itemsToKeep = notReferencedExecutions.concat(referencedExecutions);

            // create a right-sized map
            const limitedSizeMap = itemsToKeep.reduce((map: { [key: string]: QueryCompletionInfo }, val) => {
                map[val.id!.toString()] = val;
                return map;
            }, {});

            // replace the existing map with thhe right-sized map.
            self.executions.replace(limitedSizeMap);

            // remove the actual results in indexeddb cache
            idsToDelete.map((id) => dependencies.queryResultStore.removeResultsAsync(id));
        }

        return {
            pruneAll,
            pruneOne,
            put,
            remove,
        };
    });

export type ResultCache = typeof ResultCache.Type;
