import { reaction, makeObservable, observable, action, runInAction } from 'mobx';

import { ReadonlyRecord } from '../../common';
import type { IPage } from '../../core/domain';

export class PagesNavState {
    public layout: string[];
    public renamePage:
        | {
              pageId: string;
              /**
               * Required. Use this to re-focus after the form closes.
               */
              onFormClosed: () => void;
          }
        | undefined;

    public readonly dispose: () => void;

    constructor(pages: { pages: readonly IPage[]; pagesRecord: ReadonlyRecord<string, IPage> }) {
        this.layout = runInAction(() => pages.pages.map((p) => p.id));

        // TODO: Make incremental
        this.dispose = reaction(
            () => {
                void pages.pages;
                void pages.pagesRecord;
                return {};
            },
            () => {
                this.layout = this.layout.filter((pageId) => pageId in pages.pagesRecord);
                for (const page of pages.pages) {
                    if (!this.layout.includes(page.id)) {
                        this.layout.push(page.id);
                    }
                }
            }
        );

        makeObservable(this, {
            layout: observable,
            renamePage: observable.ref,
            changePageIndex: action,
        });
    }

    get pageIndexById() {
        const record: Record<string, number> = {};

        for (let i = 0; i < this.layout.length; i++) {
            record[this.layout[i]] = i;
        }
        return record;
    }

    changePageIndex(pageId: string, index: number) {
        const currentIndex = this.layout.findIndex((id) => id === pageId);

        if (currentIndex < index) {
            for (let i = currentIndex; i < index; i++) {
                this.layout[i] = this.layout[i + 1];
            }
        } else {
            for (let i = currentIndex; i > index; i--) {
                this.layout[i] = this.layout[i - 1];
            }
        }

        this.layout[index] = pageId;
    }
}
