import { data as mapData, Map } from 'azure-maps-control';

/**
 * Some of this code was adapted from azure maps doc site.
 * https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/master/AzureMapsCodeSamples/Custom%20Modules/Bring%20Data%20Into%20View%20Control/BringDataIntoViewControl.ts
 * @param map the Map object
 */
export const bringDataIntoView = (featureCollection: mapData.FeatureCollection, map: Map) => {
    const bounds = mapData.BoundingBox.fromData(featureCollection);

    const w = mapData.BoundingBox.getWidth(bounds);
    const h = mapData.BoundingBox.getHeight(bounds);
    //If the bounding box is really small, likely a single point, use center/zoom.
    if (w < 0.000001 || h < 0.000001) {
        map.setCamera({
            center: mapData.BoundingBox.getCenter(bounds),
            zoom: 6,
        });
    } else {
        map.setCamera({
            bounds: bounds,
            padding: 80,
        });
    }
};
