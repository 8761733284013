import * as React from 'react';
import { IconButton, assertNever } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../res';
import { useTransitionHelper } from '../../../../components/lib/Slideout/useTransitionHelper';

import { ETPState, ETPAction, useETPSelector, useETPDispatch, useETPSelectors } from '../../../../store/editTile';
import { NoVisualSelected } from './NoVisualSelected';
import { SectionsContainer } from './ConfigSections/Container';

import styles from './VisualConfig.module.scss';

// The animation code in this file causes many renders. Memo the sections container
// so the extra renders wont affect it. Might also be mitigated by passing the
// sections container to all of this as a child.
const MemoSectionsContainer = React.memo(SectionsContainer);

const optionsListSelectors = {
    stateIsNotQueryType: (state: ETPState) => state.type !== 'query',
    noVisualSelected: (state: ETPState) => state.type !== 'query' || state.visualPane !== 'visual' || !state.visual,
    open: (state: ETPState) => state.type === 'query' && (state.visual?.configurationOpen ?? false),
};

const EmptyStatesWrapper: React.FC = () => {
    const { stateIsNotQueryType, noVisualSelected, open } = useETPSelectors(optionsListSelectors);

    const { status, target, onTransitionEnd } = useTransitionHelper(open);

    if (stateIsNotQueryType) {
        return null;
    }

    if (noVisualSelected) {
        return NoVisualSelected;
    }

    const visibility = status === 'done' && target === 'closed' ? 'hidden' : ('visible' as const);

    return (
        <div
            className={styles.optionsList}
            // `visibility: none` elements don't show up to screen readers and cannot be selected with the keyboard
            style={{ visibility }}
            onTransitionEnd={onTransitionEnd}
        >
            <MemoSectionsContainer />
        </div>
    );
};

const toggleOpenAction: ETPAction = { type: 'toggleVisualConfigurationOpen' };

const visualConfigOpenClassSelector = (state: ETPState): string => {
    switch (state.type) {
        case 'loading':
        case 'error':
            return styles.open;
        case 'markdown':
            return styles.hidden;
        case 'query':
            if (state.visual === undefined) {
                return styles.hidden;
            }
            return state.visual.configurationOpen ? styles.open : styles.closed;
        default:
            assertNever(state);
    }
};

const toggleOpenButtonIconProps = { iconName: 'DoubleChevronLeft' };

const VisualOptionsContainer: React.FC = ({ children }) => {
    const [dispatch] = useETPDispatch();
    const visibilityClass = useETPSelector(visualConfigOpenClassSelector);

    const open = visibilityClass !== styles.hidden;

    const { status, target, onTransitionEnd } = useTransitionHelper(open);

    /**
     * Use `useTransitionHelper` to correct visibility class so that it can't
     * change directly from hidden to an open state, so we have a frame with no
     * "fullyClosedStyle".
     *
     * When we are trying to open the visual config _and_ we were previously
     * "display: hidden", we need to ensure that we don't immediately apply the new
     * class.
     *
     * This is a little ugly, if we do end up using the transition helper more places
     * it may be worth it to invest time in finding better patterns.
     */
    const transitionHelperCorrectedVisibilityClass =
        target === 'open' && status === 'pre-animation' ? styles.hidden : visibilityClass;
    const fullyClosedStyle = target === 'closed' && status === 'done' ? { display: 'none' } : undefined;
    const toggleOpen = () => dispatch(toggleOpenAction);

    return (
        <section
            style={fullyClosedStyle}
            className={`${styles.visualConfig} ${transitionHelperCorrectedVisibilityClass}`}
            onTransitionEnd={onTransitionEnd}
        >
            <IconButton
                iconProps={toggleOpenButtonIconProps}
                onClick={toggleOpen}
                className={styles.openButton}
                title={
                    visibilityClass === styles.open
                        ? APP_STRINGS.editTilePage.visualConfig.closeButtonAria
                        : APP_STRINGS.editTilePage.visualConfig.openButtonAria
                }
            />
            {children}
        </section>
    );
};

export const VisualConfig: React.FC = () => (
    <VisualOptionsContainer>
        <EmptyStatesWrapper />
    </VisualOptionsContainer>
);
