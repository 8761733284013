import { tile } from '../tile';
import { ITileV4 } from '../tile/versions/4';

import { IDashboardV5 } from './5';

export interface IDashboardV6 extends Omit<IDashboardV5, '$schema' | 'tiles'> {
    $schema: '6';
    tiles: ITileV4[];
}

/**
 * Updates tiles to v4
 */
export function up(prev: IDashboardV5): IDashboardV6 {
    return {
        ...prev,
        $schema: '6',
        tiles: prev.tiles.map((t) => tile.v4.up(t)),
    };
}
