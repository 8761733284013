import { IRtdAuthenticationProvider } from '../config';
import { RestApiError, NoRouteError } from '../domain/RestApiError';

/**
 * The REST API client for the own backend with support for authentication.
 */
export class RestApiClient {
    private readonly serviceEndpoint: string;
    private readonly serviceScopes: string[];
    private readonly authProvider: IRtdAuthenticationProvider;

    constructor(serviceEndpoint: string, serviceScopes: string[], authProvider: IRtdAuthenticationProvider) {
        this.serviceEndpoint = serviceEndpoint;
        this.serviceScopes = serviceScopes;
        this.authProvider = authProvider;
    }

    public async httpGet(path: string, options?: RequestInit): Promise<Response> {
        const requestUrl = this.serviceEndpoint + path;

        return this.executeFetch((authToken: string) =>
            fetch(requestUrl, {
                ...options,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
        );
    }

    public async httpPost(
        path: string,
        requestBody: unknown,
        options?: RequestInit,
        demoDataSourceAuth?: string
    ): Promise<Response> {
        const requestUrl = this.serviceEndpoint + path;

        return this.executeFetch((authToken: string) =>
            fetch(requestUrl, {
                ...options,
                method: 'POST',
                body: JSON.stringify(requestBody),
                // Demo workspace requires X-Api-Key and no Bearer token.
                headers: {
                    'Content-Type': 'application/json',
                    ...(demoDataSourceAuth === undefined
                        ? { Authorization: `Bearer ${authToken}` }
                        : { 'X-Api-Key': demoDataSourceAuth }),
                },
            })
        );
    }

    public async httpPut(path: string, requestBody: unknown, options?: RequestInit): Promise<Response> {
        const requestUrl = this.serviceEndpoint + path;

        return this.executeFetch((authToken) =>
            fetch(requestUrl, {
                ...options,
                method: 'PUT',
                body: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            })
        );
    }

    public async httpPatch(path: string, requestBody: unknown, options?: RequestInit): Promise<Response> {
        const requestUrl = this.serviceEndpoint + path;

        return this.executeFetch((authToken: string) =>
            fetch(requestUrl, {
                ...options,
                method: 'PATCH',
                body: JSON.stringify(requestBody),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            })
        );
    }

    public async httpDelete(path: string, options?: RequestInit): Promise<Response> {
        const requestUrl = this.serviceEndpoint + path;

        return this.executeFetch((authToken: string) =>
            fetch(requestUrl, {
                ...options,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
        );
    }

    private async executeFetch(fetchFunc: (token: string) => Promise<Response>): Promise<Response> {
        let forceRefresh = false;

        while (true) {
            let response: Response;
            const authToken = await this.authProvider.getToken(this.serviceScopes, forceRefresh);
            try {
                response = await fetchFunc(authToken);
            } catch (_) {
                throw new NoRouteError();
            }

            if (!response.ok) {
                if (response.status === 401 && !forceRefresh) {
                    forceRefresh = true;
                    continue;
                }

                let message: string;

                const responseBody = await response.text();

                try {
                    const json = JSON.parse(responseBody) as null | {
                        error?: { message?: string };
                    };
                    message = json?.error?.message ?? responseBody;
                } catch {
                    // If the responseBody isn't JSON (unlikely but possible)
                    // JSON.parse will throw. In that case the text of the response
                    // body can be our message.
                    message = responseBody;
                }

                throw new RestApiError(response.status, message);
            }

            return response;
        }
    }
}

export function getJson<T>(resp: Response): Promise<T> {
    return resp.json();
}
