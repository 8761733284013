// Copied from: https://msazure.visualstudio.com/DefaultCollection/One/_git/Azure-Kusto-WebUX?version=GCce9adf1ecac656014ac3029d76e3eeaaf20d7cf1&path=%2Fkustoweb%2Fsrc%2Fcomponents%2FcommonComponents%2FKustoEditor.tsx

/* eslint-disable */
// @ts-nocheck

/// <reference path="../../../../../kustoweb/node_modules/monaco-editor/monaco.d.ts" />
/// <reference path="../../../../../kustoweb/public/monaco-editor/min/vs/language/kusto/monaco.d.ts" />

import React from 'react';
import { ReactMonacoEditor, ReactMonacoEditorProps } from './ReactMonacoEditor';

import './KustoEditor.scss';

// monaco has their own module loader which should be on the window.
declare const window: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    require(a: any, b?: any): any;
} & Window;

export interface Props extends ReactMonacoEditorProps {
    isDarkTheme?: boolean;
}

/**
 * The monaco component is responsible to all behaviors related to monaco editor window.
 * this includes triggering schema change, parsing, codelens ETC.
 */
export class KustoEditor extends React.Component<Props> {
    public static defaultProps = {
        language: 'kusto',
    };

    editorWillMount = (monacoModule: typeof monaco) => {
        // const script = document.createElement('script');
        window.require(['vs/language/kusto/monaco.contribution']);
        // tslint:disable-next-line: no-any
        monaco.languages.kusto = (monacoModule.languages as any).kusto;
        if (this.props.editorWillMount) {
            this.props.editorWillMount(monacoModule);
        }
    };

    /** Initializing stuff. amongst others setting the schema when context changes.  */
    editorDidMount = (editor: monaco.editor.IStandaloneCodeEditor, monacoModule: typeof monaco) => {
        KustoEditor.initWorker(editor);
        if (this.props.editorDidMount) {
            this.props.editorDidMount(editor, monacoModule);
        }
    };

    render() {
        const { isDarkTheme, theme, ...props } = this.props;
        const themeName = isDarkTheme !== undefined ? (isDarkTheme ? 'kusto-dark2' : 'kusto-light') : theme;

        return (
            <ReactMonacoEditor
                {...props}
                theme={themeName}
                editorDidMount={this.editorDidMount}
                editorWillMount={this.editorWillMount}
            />
        );
    }

    static initWorker = async (editor: monaco.editor.IStandaloneCodeEditor) => {
        const model = editor.getModel();
        if (!model) {
            return;
        }
        return await KustoEditor.initWorkerFromModel(model);
    };

    static initWorkerFromModel = async (model: monaco.editor.IModel) => {
        const workerAccessor = await monaco.languages.kusto.getKustoWorker();
        return workerAccessor ? workerAccessor(model.uri) : undefined;
    };
}
