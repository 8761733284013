import * as dateFns from 'date-fns';
import { utcToZonedTime, toDate } from 'date-fns-tz';
import * as React from 'react';
import { useCallback } from 'react';
import { DatePicker as FabricDatePicker, IComboBoxOption, IDatePickerProps, Text } from 'office-ui-fabric-react';

import { useTimeZone } from '../../../../domain';
import styles from '../styles.module.scss';

import { TimePicker } from './TimePicker';

const datePickerFormat = 'yyyy-MM-dd';
const timePickerFormat = 'HH:mm';
const nowDate = new Date();

interface DatePickerProps extends Omit<IDatePickerProps, 'onSelectDate' | 'value'> {
    readonly value: string;
    onSelectDate: (newDateStr: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ value: valueProp, onSelectDate: onSelectDateProp, ...props }) => {
    const value = dateFns.parse(valueProp, datePickerFormat, nowDate);

    const onSelectDate = useCallback(
        (datePickerDate: Date | null | undefined) => {
            if (!datePickerDate) return;

            const date = dateFns.format(datePickerDate, datePickerFormat);
            onSelectDateProp(date);
        },
        [onSelectDateProp]
    );

    return <FabricDatePicker {...props} value={value} onSelectDate={onSelectDate} />;
};

export interface IDateTimePickerProps {
    /**
     * We expect this to be a Date zoned to UTC
     * and we will handle zoning for the target timeZone setting
     * that's configured by the user as we display it
     */
    value: Date;
    /**
     * Applied as a legend to both the time and date pickers
     */
    label: string;
    /**
     * The ARIA label for the date picker
     */
    datePickerAriaLabel?: string;
    /**
     * @param date Expect this to be a zoned date so make sure to use `zonedTimeToUtc` if you need a UTC date
     */
    onChange(date: Date): void;
}

export const DateTimePicker: React.FC<IDateTimePickerProps> = ({ onChange, value, label, datePickerAriaLabel }) => {
    const timeZone = useTimeZone();

    const onSelectDate = useCallback(
        (dateStr: string | undefined) => {
            if (dateStr === undefined) {
                return;
            }

            /**
             * We need the zoned hour/min/sec as the input value for
             * `toDate`.
             *
             * Example:
             * 1. Select "Thu Feb 13 2020 02:00:00" in UTC time zone
             * 2. Change time zone to `Pacific/Midway` (-11:00 hrs)
             * 3. Observe the displayed date and time gets zoned to "Wed Feb 12 2020 15:00:00"
             * 4. Change the selected date to "Wed Feb 05 2020"
             * 5. Observe the new selected Datetime is "Wed Feb 05 2020 15:00:00" (in UTC = "Thu Feb 06 2020 02:00:00")
             *
             * Expanding on Step 4, if we were to generate an input string for `toDate` from
             * the `value` prop then it would
             * look like this: "2020-02-05T02:00:00" (bad input)
             * Why? Because the `value` prop is a zoned for UTC and NOT for `Pacific/Midway`.
             *
             * The input string for `toDate` we want is actually: "2020-02-05T15:00:00" (good input)
             * So when we pass the good input to the `toDate` function it will spit out a date
             * that is zoned properly for the user: "Wed Feb 05 2020 15:00:00" (in UTC = "Thu Feb 06 2020 02:00:00")
             */
            const zonedValue = utcToZonedTime(value, timeZone);

            /**
             * We don't need to use dateFnsTz.format because we aren't utilizing
             * any timezone patterns like `xxx`
             */
            const zonedHourMinSec = dateFns.format(zonedValue, 'HH:mm:ss');

            const toDateInput = `${dateStr}T${zonedHourMinSec}`;
            const date = toDate(toDateInput, { timeZone });

            // TODO: Log this
            if (isNaN(date.valueOf())) {
                return;
            }

            onChange(date);
        },
        [value, onChange, timeZone]
    );

    const onTimeChange = useCallback(
        (_event: unknown, item?: IComboBoxOption, _index?: unknown, freeText?: string) => {
            const text = item?.text ?? freeText;

            if (text === undefined) {
                return;
            }

            /**
             * We zone the value for the same reason up in the `onSelectDate` function.
             *
             * Example:
             * 1. Select "Thu Feb 13 2020 02:00:00" in UTC time zone
             * 2. Change time zone to `Pacific/Midway` (-11:00 hrs)
             * 3. Observe the displayed date and time gets zoned to "Wed Feb 12 2020 15:00:00" (in UTC = "Thu Feb 13 2020 02:00:00"")
             * 4. Change the selected time to "10:00"
             * 5. Observe the new selected Datetime is "Wed Feb 12 2020 10:00:00" (in UTC = "Wed Feb 12 2020 21:00:00"")
             *
             * Expanding, if we just used the 'yyyy-MM-dd' from the `value` prop then the input
             * for `toDate` would look like: "2020-02-13T10:00:00" (bad input)
             * Why? The `value` is in UTC so the year/month/date is "2020-02-13"
             *
             * We need the zoned year/month/date such that the input for `toDate`
             * would be: "2020-02-12T10:00:00" (good input)
             * This way `toDate` will spit out a proper date for the
             * target timezone: "Wed Feb 12 2020 10:00:00" (in UTC = "Wed Feb 12 2020 21:00:00"")
             */
            const zonedValue = utcToZonedTime(value, timeZone);

            /**
             * We don't need to use dateFnsTz.format because we aren't utilizing
             * any timezone patterns like `xxx`
             */
            const zonedYearMonthDay = dateFns.format(zonedValue, 'yyyy-MM-dd');

            /**
             * We need to add `:00` to the HH:mm so that
             * it follows the HH:mm:ss expected format
             */
            const timeAsDate = dateFns.parse(`${text}:00`, 'HH:mm:ss', value);

            /**
             * Need to ensure the text input is a valid date
             * or else the `toDate` fn will throw an error if
             * it isn't.
             *
             * Guards against the user trying to input the
             * seconds in the free text (`ss`) which is
             * not supported at the moment.
             */
            if (!dateFns.isValid(timeAsDate)) {
                return;
            }

            const time = dateFns.format(timeAsDate, 'HH:mm:ss');
            const toDateInput = `${zonedYearMonthDay}T${time}`;
            const date = toDate(toDateInput, { timeZone });

            // TODO: Log this
            if (isNaN(date.valueOf())) {
                return;
            }

            onChange(date);
        },
        [value, onChange, timeZone]
    );

    /**
     * We need this to ensure the selected date appears to
     * the user as if it's zoned to the configured KWE timezone
     */
    const zonedDate = utcToZonedTime(value, timeZone);
    const datePickerValue = dateFns.format(zonedDate, datePickerFormat);
    const timePickerValue = dateFns.format(zonedDate, timePickerFormat);

    return (
        <fieldset className={styles.dateTimePicker}>
            <Text as="legend">{label}</Text>
            <DatePicker onSelectDate={onSelectDate} value={datePickerValue} ariaLabel={datePickerAriaLabel} />
            <TimePicker selectedDateTime={timePickerValue} onChange={onTimeChange} />
        </fieldset>
    );
};
