import * as React from 'react';

import styles from './NoDataTile.module.scss';

export const NoDataTileIcon: React.FC = () => (
    <svg
        className={styles.graphic}
        width="420"
        height="210"
        viewBox="0 0 420 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path className={styles.gray30} d="M47.1319 188.188V10.1875H46.1875V189.188H373.188V188.188H47.1319Z" />
        <path className={styles.themeLighter} d="M125.812 83.75H62.9062V188.438H125.812V83.75Z" />
        <path className={styles.themeLighter} d="M276.562 52.3438H213.656V188.438H276.562V52.3438Z" />
        <path className={styles.themeLighterAlt} d="M188.719 20.9375H125.812V188.438H188.719V20.9375Z" />
        <path className={styles.themeLighterAlt} d="M339.469 83.75H276.562V188.438H339.469V83.75Z" />
    </svg>
);
