import { infer } from '../domain/tile/constants';
import { DashboardPermission } from '../core/domain/membership';
import type { AutoRefreshUpdateInterval } from '../core/domain/dashboard';
import type { EnvironmentType, EnvironmentConfig } from '../core/config';
import { VisualOptions, VisualOptionKey, Duration, RtdValue, BasicParam } from '../domain';
import { dynamicDuration } from '../domain/value/lib';
import { basicValueDataTypes } from '../domain/value/constants';

import { env } from './env';
import type { APP_STRINGS } from './App.strings';

const dashboardPageGrid = {
    columnCount: 24,
    rowHeight: 36,
    columnWidth: 38,

    tilePadding: 8,
    containerVerticalPadding: 8,
    containerHorizontalPadding: 16,
};

const durationPickerDynamicOptions: Array<[keyof typeof APP_STRINGS['duration']['dynamicOptions'], Duration.Dynamic]> =
    [
        ['15minutes', dynamicDuration(15, 'minutes')],
        ['30minutes', dynamicDuration(30, 'minutes')],
        ['1hour', dynamicDuration(1, 'hours')],
        ['3hours', dynamicDuration(3, 'hours')],
        ['6hours', dynamicDuration(6, 'hours')],
        ['12hours', dynamicDuration(12, 'hours')],
        ['24hours', dynamicDuration(24, 'hours')],
        ['2days', dynamicDuration(2, 'days')],
        ['7days', dynamicDuration(7, 'days')],
        ['14days', dynamicDuration(14, 'days')],
        ['30days', dynamicDuration(30, 'days')],
        ['60days', dynamicDuration(60, 'days')],
        ['90days', dynamicDuration(90, 'days')],
        ['180days', dynamicDuration(180, 'days')],
        ['365days', dynamicDuration(365, 'days')],
        ['730days', dynamicDuration(730, 'days')],
        ['1095days', dynamicDuration(1095, 'days')],
    ];

const sharedEnvironmentUrls: {
    [key in EnvironmentType]: EnvironmentConfig;
} = {
    production: {
        persistenceApiUrl: 'https://api.rtd.azure.net',
        persistenceApiResourceId: 'https://rtd-metadata.azurewebsites.net',
        dataServicesUrl: 'https://data.rtd.azure.net',
    },
    staging: {
        persistenceApiUrl: 'https://api.rtd-dev.azure.net',
        persistenceApiResourceId: 'https://microsoft.onmicrosoft.com/693a2101-b1fb-4f74-93d7-42ed940fa106',
        dataServicesUrl: 'https://data.rtd-dev.azure.net',
    },
    development: {
        persistenceApiUrl: 'https://api.rtd-dev.azure.net',
        persistenceApiResourceId: 'https://microsoft.onmicrosoft.com/693a2101-b1fb-4f74-93d7-42ed940fa106',
        dataServicesUrl: 'https://data.rtd-dev.azure.net',
    },
    test: {
        persistenceApiUrl: 'https://persistence',
        persistenceApiResourceId: 'https://microsoft.onmicrosoft.com/693a2101-b1fb-4f74-93d7-42ed940fa106',
        dataServicesUrl: 'https://data',
    },
};

/**
 * ID for the base Y axis when multiple y axes is configured
 */
export const BASE_Y_AXIS_ID = '-1';

const defaultVisualOptions: VisualOptions = {
    xColumn: infer,
    yColumn: infer,
    yColumns: infer,
    seriesColumns: infer,

    hideLegend: false,
    hideDataLabels: false,
    hideTileTitle: false,
    yAxisRight: false,

    xColumnTitle: '',
    yColumnTitle: '',

    yAxisMinimumValue: infer,
    yAxisMaximumValue: infer,

    multipleYAxes: {
        base: {
            id: BASE_Y_AXIS_ID,
            label: '',
            columns: [],
            yAxisMaximumValue: null,
            yAxisMinimumValue: null,
            yAxisScale: 'linear',
            horizontalLine: '',
        },
        additional: [],
    },

    horizontalLine: '',
    verticalLine: '',

    xAxisScale: 'linear',
    yAxisScale: 'linear',
    legendLocation: 'right',

    colorRulesDisabled: false,
    colorRules: [],
    colorStyle: 'light',

    crossFilterDisabled: true,
    crossFilter: null,

    line__hidePinpointTooltips: false,

    map__bubbleFormat: 'dot',
    map__minBubbleSizeColumn: infer,
    map__latitudeColumn: infer,
    map__longitudeColumn: infer,

    multiStat__displayOrientation: 'horizontal',
    multiStat__textSize: 'auto',
    multiStat__labelColumn: infer,
    multiStat__valueColumn: infer,
    multiStat__slot: { width: 4, height: 2 },

    heatMap__dataColumn: infer,

    table__enableRenderLinks: true,
    table__renderLinksForColumns: [],
};

const parameterSelectionTypes: Record<BasicParam.SelectionKind, readonly RtdValue.BasicType[]> = {
    scalar: basicValueDataTypes,
    'scalar-null': basicValueDataTypes,
    'array-null': basicValueDataTypes,
    freetext: ['string', 'int32', 'float64', 'int64', 'decimal'],
};

const supportedAutoRefreshIntervals: Record<
    AutoRefreshUpdateInterval,
    {
        text: string;
        interval: number;
    }
> = {
    '30s': { text: '30 secs', interval: 30 },
    '1m': { text: '1 min', interval: 60 },
    '5m': { text: '5 mins', interval: 60 * 5 },
    '15m': { text: '15 mins', interval: 60 * 15 },
    '30m': { text: '30 mins', interval: 60 * 30 },
    '1h': { text: '1 hr', interval: 60 * 60 },
    '2h': { text: '2 hrs', interval: 60 * 60 * 2 },
    '1d': { text: '1 day', interval: 60 * 60 * 24 },
};

const orderedAutoRefreshIntervals: AutoRefreshUpdateInterval[] = ['30s', '1m', '5m', '15m', '30m', '1h', '2h', '1d'];

export const APP_CONSTANTS = {
    /**
     * Place user experience (deign/colors/etc) values here
     */
    ux: {
        /**
         * Dialog default width is too thin for paragraph text. When we expect
         * text to wrap use this width.
         */
        dialogWithTextWidth: 480,
        fabric: {
            dropdown: {
                // Default Fabric dropdown row height. Always used unless changed via CSS
                rowHeight: 36,
                minFilterSize: 10,
            },
        },
    },
    mobxConfiguration: {
        isolateGlobalState: true,
        computedRequiresReaction: true,
        // Some components conditionally access mobx state, which generates false positives
        reactionRequiresObservable: false,
        observableRequiresReaction: true,
    } as const,
    sharedEnvironmentUrls,
    tile: {
        unknownTypeMinimumSize: { width: 2, height: 2 },
        unknownTypeEditingSize: { width: 9, height: 7 },
        markdownConfiguration: {
            defaultSize: { width: 9, height: 7 },
            minimumSize: { width: 2, height: 1 },
        },
    },
    localStorageKeys: {
        debugFlags: 'debugFlags',
        featureFlagOverride: 'rtd--feature-flag-override',
    },
    urls: {
        catalogPage: '/',
    },
    agGridThemes: {
        light: 'ag-theme-balham',
        dark: 'ag-theme-balham-dark',
    },
    durationPicker: {
        dynamicOptions: durationPickerDynamicOptions,
        format: {
            dateTooltip: 'MMM d, yyyy, H:mm:ss',
            dateWithTime: `MM/dd/yy 'at' HH:mm:ss`,
            dateWithTimeNoSeconds: `MM/dd/yy 'at' HH:mm`,
            date: 'MM/dd/yy',
            time: 'HH:mm:ss',
            timeWithNoSeconds: 'HH:mm',
        },
    },
    dashboardTitleMaxLength: 90,
    defaultPickerDuration: durationPickerDynamicOptions[2][1],
    dashboardPage: {
        newDashboardId: 'new',
        // One hour in milliseconds
        dashboardStaleBackgroundTime: 1000 * 60 * 60,
        dashboardEntryRefetchTime: 1000 * 60,
        grid: {
            ...dashboardPageGrid,
            minimumGridWidth:
                dashboardPageGrid.columnWidth * dashboardPageGrid.columnCount +
                dashboardPageGrid.tilePadding * (dashboardPageGrid.columnCount - 1),
        },
        draggableTitleHeaderClassName: 'draggableTileHeader',
        notDraggableTitleHeaderClassName: 'notDraggableTileHeader',
    },
    permissionsOptions: [
        { key: DashboardPermission.editor, text: 'Can edit' },
        { key: DashboardPermission.viewer, text: 'Can view' },
    ],
    monaco: {
        requireConfig: {
            paths: {
                // This only works in the project consuming this package defines
                // process.env.PUBLIC_URL  /monaco-editor/min/vs works as long as we
                // deploy to the root, and not in a nested folder. WI #7395674
                vs: `./monaco-editor/min/vs`,
            },
        },
        defaultOptions: () =>
            env.isStorybookStory
                ? ({
                      // Disable Monaco's blinking cursor when running Storybook tests
                      readOnly: true,
                      scrollbar: {
                          // Force scrollbar to be hidden to eliminate screenshot inconsistencies
                          vertical: 'hidden',
                      },
                  } as const)
                : undefined,
    },
    rtdInternalPrefix: '__rtd_internal',
    emptySelection: '__rtd_internal_empty_selection',
    addSelection: '__rtd_internal_add_selection',
    divider: '__rtd_internal_divider',
    parameter: {
        allSelection: '__rtd_internal_all_selection' as const,
        selectionTypes: parameterSelectionTypes,
    },
    editTilePage: {
        visualOptionsTextDebounce: 1000,
        newMarkdownTileId: 'new-markdown',
        newQueryTileId: 'new-query',
        visualizationThrottleTime: 250,
        markdownDocumentationLink: 'https://rexxars.github.io/react-markdown/',
        /**
         * When a user does not add a visual when creating a new query and it is
         * added to the dashboard this visual type will be used
         */
        noVisualAddedDefault: 'table' as const,
    },
    visuals: {
        defaultVisualOptions: defaultVisualOptions,
        defaultVisualType: 'table' as const,
        visualOptionKeys: Object.keys(defaultVisualOptions) as VisualOptionKey[],
        seriesNameLengthMax: 40,
        standardVisualTypes: [
            'area',
            'bar',
            'card',
            'column',
            'line',
            'pie',
            'scatter',
            'stackedarea',
            'stackedbar',
            'stackedcolumn',
            'table',
            'timechart',
        ] as const,
        slotMaxHeight: 5,
        slotMaxWidth: 5,
    },
    editParameterPage: {
        queryVisualMaxRecordsShown: 5,
    },
    orderedAutoRefreshIntervals,
    supportedAutoRefreshIntervals,
    tenants: {
        microsoft: '72f988bf-86f1-41af-91ab-2d7cd011db47',
        msa: '9188040d-6c67-4c5b-b112-36a304b66dad',
        firstPartyGlobal: 'f8cdef31-a31e-4b4a-93e4-5f571e91255a',
    },
    debounce: {
        standard: 300,
        high: 500,
    },
    maxRecents: 5,
};
