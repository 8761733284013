import * as React from 'react';
import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import { useCore } from '../../../../core';
import { Tile, Markdown } from '../../../../components';
import { ETPState, useDashboardStore, useETPSelectors } from '../../../../store';
import { InvalidLayoutError } from '../../../../components/tile/InvalidLayoutError';
import { APP_CONSTANTS } from '../../../../res';
import { useVisualConfig } from '../../../../domain';

import { getTileLayout, TileSizeWrapper } from './TileSizeWrapper';
import { TilePreviewExpandedToggle } from './ExpandedToggle';

import styles from './MarkdownPane.module.scss';

const markdownSelectors = {
    title: (s: ETPState) => (s.type === 'markdown' ? s.title : undefined),
    text: (s: ETPState) => (s.type === 'markdown' ? s.query : undefined),
};

export const MarkdownPane: React.FC<{ tileId?: string }> = observer(function MarkdownPane({ tileId }) {
    const { title, text } = useETPSelectors(markdownSelectors);
    const store = useDashboardStore();
    const visualConfig = useVisualConfig();
    const layout = getTileLayout(store, tileId, 'markdownCard', visualConfig);
    const { editTilePagePreviewExpanded } = useCore().userSettingsState.userSettings;

    let visual: ReactNode;

    /**
     * Layout errors don't apply when expanded, because the expanded view doesn't
     * use the dashboard layout.
     */
    if (!editTilePagePreviewExpanded && layout?.valid === false) {
        visual = <InvalidLayoutError layout={layout} />;
    } else {
        visual = (
            <Tile pageState={store.state} tileId="etp" title={title}>
                <Markdown markdownText={text} />
            </Tile>
        );
    }

    return (
        <>
            <div className={styles.rightItems}>
                <TilePreviewExpandedToggle />
            </div>
            <TileSizeWrapper
                minimumSize={APP_CONSTANTS.tile.markdownConfiguration.minimumSize}
                className={styles.tileContainer}
                defaultSize={APP_CONSTANTS.tile.markdownConfiguration.defaultSize}
                // Tab index required because this div may have a scroll bar. See link below.
                // https://www.chromestatus.com/feature/5231964663578624
                tabIndex={0}
            >
                {visual}
            </TileSizeWrapper>
        </>
    );
});
