/* eslint-disable import/first */
// logging has to be setup before anything else since imports may try to log stuff.
import { setupAppInsights, setUserIdInAppInsight } from './utils/telemetry';
import { getTelemetryClient } from './utils/telemetryClient';
setupAppInsights();

import { KustoDomains } from '@kusto/common';
const subDomains = JSON.parse(process.env.REACT_APP_ADDITIONAL_KUSTO_DOMAIN || '[]');
KustoDomains.configure({
    subDomains: Array.isArray(subDomains) ? subDomains : [],
    defaultDomainPostfix: process.env.REACT_APP_DEFAULT_DOMAIN_POSTFIX || '',
});

// logging has to be setup before anything else since imports may try to log stuff.
import { ErrorSnackbar, formatLiterals } from '@kusto/common';
import * as React from 'react';
import { Dependencies, initDependencies /** do not import dependencies */ } from './dependencies';
import ReactDOM from 'react-dom';

/**
 * This is the entry point to the application.
 * Here we make the most high level decisions. For example:
 * 1. Do we redirect for authentication or not (not in Ibiza)
 * 2. Where to we try to get cached state from (in Ibiza we have state per cluster)
 */

const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);

const loadWebsite = async (dependencies: Dependencies): Promise<void> => {
    await setUserIdInTelemetry(dependencies);
    require('./index.authenticated');
};

const setUserIdInTelemetry = async (dependencies: Dependencies): Promise<void> => {
    const user = await dependencies.authProvider.getUser();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userId = (user?.profile as any)?.oid as string;
    // userID in AI MUST be oid (and not hashing the user's email for example) since the Geneva Agent
    // that handles DSR Export (a GDPR Requirement) depends on it.
    if (userId) {
        setUserIdInAppInsight(userId);
    }
};

// Ibiza handles tokens for us. do not redirect to login if we're in ibiza.
const isIbizaPortal = params.get('ibizaPortal') === 'true';
const iFrameAuth = params.get('IFrameAuth') === 'true';

const tracer = getTelemetryClient({ flow: 'load', component: 'index' });

tracer.trackPageView('index', {
    referrer: document.referrer,
    isIbiza: isIbizaPortal.toString(),
    iFrameAuth: iFrameAuth.toString(),
});

const loginAndLoadSite = (dependencies: Dependencies) => {
    // by default it's configured for ADAL.
    const authProvider = dependencies.authProvider;

    // React-adal will go into an infinite redirect loop in case of an error obtaining token.
    // This piece of code tries to stop this lunacy.
    const loginError = authProvider.getLoginError();
    if (loginError) {
        tracer.trackException(
            new Error(loginError),
            undefined,
            {
                loginError,
            },
            undefined,
            3
        );

        // Display an error message in case of a login error
        ReactDOM.render(
            <ErrorSnackbar
                message={`${loginError}
${formatLiterals(dependencies.strings.appVersion, {
    appVersion: process.env.REACT_APP_VERSION as string,
})}
${formatLiterals(dependencies.strings.clientID, {
    clientID: process.env.REACT_APP_AAD_CLIENT as string,
})}`}
            />,
            document.getElementById('root') as HTMLElement
        );

        return;
    }
    // We're not in ibiza and there's no login error, so do authentication.
    authProvider.authenticateAndRun(() => loadWebsite(dependencies));
};

const dependencies = initDependencies(isIbizaPortal);
if (isIbizaPortal) {
    loadWebsite(dependencies);
} else {
    if (iFrameAuth) {
        loadWebsite(dependencies);
    } else {
        loginAndLoadSite(dependencies);
    }
}
