/**
 * The unique id for a given query execution instance
 */
export type QueryExecutionId = string;

export interface StreamLoadingStatusDone {
    kind: 'done';
    startTime: Date;
    endTime: Date;
    /**
     * The unique identifier for this query execution instance
     *
     * Can be `undefined` in instances where the result was never actually executed (such as through a cache fetch)
     */
    executionId: QueryExecutionId | undefined;
}

export interface StreamLoadingStatusLoading {
    kind: 'loading';
    startTime: Date;
    lastCompleted?: Date;
    /**
     * The unique identifier for this query execution instance
     */
    executionId: QueryExecutionId;
}

export type StreamLoadingStatus = StreamLoadingStatusDone | StreamLoadingStatusLoading;

export interface StreamResultSuccess<T> {
    kind: 'success';
    result: T;
    status: StreamLoadingStatus;
}

export type StreamResultError = StreamResultCannotRunError | StreamResultDidRunError;

export type StreamResult<T> = StreamResultSuccess<T> | StreamResultError | StreamResultNotRun;

export interface StreamErrorReason {
    title: string;
    body?: string;
}

export interface StreamResultCannotRunError {
    kind: 'err';
    result?: undefined;
    didRun: false;
    reason: StreamErrorReason;
    status?: StreamLoadingStatus;
}

export interface StreamResultDidRunError {
    kind: 'err';
    result?: undefined;
    didRun: true;
    reason: StreamErrorReason;
    receivedTime: Date;
    status: StreamLoadingStatus;
}

export interface StreamResultNotRun {
    result?: undefined;
    kind: 'notRun';
    status?: StreamLoadingStatus;
}

export function streamResultOk<T>(result: T, status: StreamLoadingStatus): StreamResultSuccess<T> {
    return { kind: 'success', result, status };
}

export function streamResultDidRunError(
    reason: StreamErrorReason,
    receivedTime: Date,
    status: StreamLoadingStatus
): StreamResultDidRunError {
    return { kind: 'err', receivedTime, status, didRun: true, reason };
}

export function streamResultCannotRun(
    reason: StreamErrorReason,
    status?: StreamLoadingStatus
): StreamResultCannotRunError {
    return { kind: 'err', didRun: false, reason, status };
}

export const streamResultNotRun: StreamResultNotRun = { kind: 'notRun' };
