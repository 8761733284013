import { useEffect } from 'react';
import { useGlobalSelector } from '../../store/redux/context';

export const useSynchronizedInterval = (interval: number, callback: () => void) => {
    const synchronizedIntervalManager = useGlobalSelector((s) => s.shared.synchronizedIntervalManager);

    useEffect(() => {
        const subscription = synchronizedIntervalManager.subscribe(interval, callback);

        return () => subscription.unsubscribe();
    }, [interval, callback, synchronizedIntervalManager]);
};
