import React from 'react';
import * as mobx from 'mobx';
import * as H from 'history';
import { PrimaryButton } from 'office-ui-fabric-react';

import { IRtdDashboardsCore } from '../../../core';
import { isAbortError, parseException } from '../../../common';
import { IDashboardDocument } from '../../../core/domain';
import { createTelemetry } from '../../../domain/telemetry';
import { DashboardStore } from '../../../store/dashboard/DashboardStore';
import { dashboardStoreToDocument } from '../../../store/dashboard';
import { GlobalAction } from '../../../store';
import { createPromptAction, rtdPrompt } from '../../../components';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { createDashboardDialogAction } from '../../../components/Forms';
import { serializeDashboard } from '../../../migration/dashboard';
import { MigrationResult } from '../../../migration';
import { WarningsList } from '../../../components/errors/WarningsList';

export async function saveDashboard(
    core: IRtdDashboardsCore,
    dashboardStore: DashboardStore,
    history: H.History,
    dispatch: React.Dispatch<GlobalAction>,
    telemetryComponentName?: string
): Promise<void> {
    const dashboardLoaded = mobx.runInAction(() => dashboardStore.state);

    if (!dashboardLoaded || mobx.runInAction(() => dashboardLoaded.disposeCurrentSave !== undefined)) {
        return;
    }

    const trackEvent = createTelemetry(telemetryComponentName, core.telemetryService, dashboardStore);

    const controller = new AbortController();

    const dispose = mobx.action(() => {
        if (!controller.signal.aborted) {
            dashboardLoaded.disposeCurrentSave = undefined;
            controller.abort();
        }
    });

    mobx.runInAction(() => {
        dashboardLoaded.disposeCurrentSave = dispose;
    });

    trackEvent('StartSaving');

    const dashboardDocument = mobx.runInAction(() => dashboardStoreToDocument(dashboardLoaded));

    const serialized = serializeDashboard(dashboardDocument);

    const loadResult = mobx.action((result: MigrationResult<IDashboardDocument>) => {
        dashboardStore.loadMigrationResult(result, dispatch, {
            kind: 'map',
            params: { ...dashboardStore.state?.selectedParameters.selections },
        });
    });

    if (dashboardLoaded.migrationWarnings.length !== 0) {
        const res = await rtdPrompt(dispatch, APP_STRINGS.dashboardPage.warnings.saveWithMigrationWarningsDialogTitle, {
            children: <WarningsList warnings={dashboardLoaded.migrationWarnings} />,
            acceptText: APP_STRINGS.utilButtons.save,
            dialogProps: { maxWidth: APP_CONSTANTS.ux.dialogWithTextWidth },
        });
        if (controller.signal.aborted) {
            return;
        }
        if (res === false) {
            dispose();
            return;
        }
    }

    try {
        if (dashboardDocument.meta === undefined) {
            const result = await core.dashboardService.createDashboard(serialized, controller.signal);
            loadResult(result);
            if (result.kind === 'ok') {
                history.replace({
                    ...history.location,
                    pathname: core.navUtil.path.dashboard(result.value.data.meta.id),
                });
            }
        } else {
            const result = await core.dashboardService.updateDashboard(serialized, controller.signal);
            loadResult(result);
        }

        trackEvent('SaveCompleted');
    } catch (e) {
        if (isAbortError(e)) {
            return;
        }
        // TODO: Track exception
        // eslint-disable-next-line no-console
        console.error(e);

        const footer =
            e instanceof Error && e.message.includes('cannot be updated due to conflicting changes') ? (
                <PrimaryButton
                    onClick={() => {
                        dispatch(createPromptAction.close);
                        dispatch(
                            createDashboardDialogAction({
                                template: serialized,
                                dashboardTitle: serialized.title,
                            })
                        );
                    }}
                >
                    {APP_STRINGS.utilButtons.saveCopy}
                </PrimaryButton>
            ) : undefined;

        dispatch(
            createPromptAction({
                title: APP_STRINGS.dashboardPage.errors.dashboardNotSaved,
                subText: parseException(e),
                footer,
            })
        );
    } finally {
        dispose();
    }
}
