import * as React from 'react';

import { Slideout } from '../lib';

import styles from './ControlBar.module.scss';

export type ControlBarProps = {
    open?: boolean;
};

export const ControlBar: React.FC<ControlBarProps> = ({ children, open = true }) => (
    <Slideout className={styles.controlBarOuter} open={open}>
        <div className={styles.controlBarInner}>{children}</div>
    </Slideout>
);
