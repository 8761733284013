import { observer } from 'mobx-react-lite';
import {
    DefaultButton,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    TextField,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useMemo, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import {
    Result,
    checkForNumberedDuplicateName,
    extractNumberedBaseName,
    ok,
    err,
    usePullState,
} from '../../../../common';
import { IScope, useCore } from '../../../../core';
import type { UDataSource } from '../../../../core/domain';
import { validateDataSourceName } from '../../../../domain/dataSource';
import { APP_STRINGS } from '../../../../res';
import { DashboardLoaded } from '../../../../store/dashboard';
import { RTDDropdown } from '../../../fabric/dropdown';
import { RTDDropdownOption } from '../../../fabric/dropdown/components/customListDropdown/types';
import { PanelFooter } from '../../../fabric/panel/PanelFooter';

import styles from './DataSourceEditor.module.scss';

function createUniqueName(name: string, otherNames: Set<string>) {
    const placeholderTitles = new Set([extractNumberedBaseName(name.toLowerCase())]);

    let highestMatch = 0;

    for (const [title] of otherNames.entries()) {
        const titleNumber = checkForNumberedDuplicateName(placeholderTitles, title);

        if (titleNumber > highestMatch) {
            highestMatch = titleNumber;
        }
    }

    if (highestMatch === 0) {
        // No duplicate, no need to add suffix
        return name;
    }

    return `${name}-${highestMatch + 1}`;
}

export interface IDataSourceEditorProps {
    dataSource: undefined | UDataSource;
    onApply: (dataSource: UDataSource) => void;
    onCancel: () => void;
    dashboardState: DashboardLoaded;
}

interface DataSourceEditorState {
    editorError?: { err?: string };
    name: string;
    defaultName: string;
}

export const DataSourceEditor: React.FC<IDataSourceEditorProps> = observer(function DataSourceEditor({
    onApply,
    dataSource,
    ...props
}) {
    const [{ editorError, defaultName, name }, setState, getState] = usePullState<DataSourceEditorState>(() => ({
        name: dataSource?.name ?? '',
        defaultName: '',
    }));
    const dataSourceRef = useRef<undefined | UDataSource>(dataSource);

    const core = useCore();
    const [scope, setScope] = useState<Result<IScope>>(() => {
        if (!dataSource) {
            if (core.queryService.scopes.length === 0) {
                return err(APP_STRINGS.query.errors.noScopes);
            }
            return ok(core.queryService.scopes[0]);
        }
        return core.queryService.getScope(dataSource.scopeId);
    });

    const dataSources = props.dashboardState.dataSources;
    const otherDataSourceTitles = useMemo(() => {
        const filtered = dataSource ? dataSources.filter((d) => d.id !== dataSource?.id) : dataSources;
        return new Set(filtered.map((d) => d.name));
    }, [dataSources, dataSource]);

    const combinedTitle = name || defaultName;
    const nameError = useMemo(
        () => validateDataSourceName(combinedTitle, otherDataSourceTitles),
        [combinedTitle, otherDataSourceTitles]
    );

    const { onScopeChange, updateDataSourceName, onScopeOptionsChange, innerOnApply, scopeOptions } =
        React.useMemo(() => {
            const innerScopeOptions: RTDDropdownOption[] = [];

            for (const s of Object.values(core.queryService.scopes)) {
                if (!s) {
                    // Actually should be impossible
                    continue;
                }
                innerScopeOptions.push({
                    data: s,
                    key: s.id,
                    text: s.dataSourceTypeDisplayName,
                });
            }
            return {
                onScopeChange: (_: unknown, option: IDropdownOption | undefined) =>
                    setScope(ok(option?.data as IScope)),
                updateDataSourceName: (_: unknown, newName = '') => setState((s) => ({ ...s, name: newName })),
                innerOnApply: (event: React.FormEvent) => {
                    event.preventDefault();
                    const latestDataSource = dataSourceRef.current;
                    if (!latestDataSource || scope.kind === 'err') {
                        return;
                    }
                    const state = getState();

                    const newDataSource: UDataSource = {
                        ...latestDataSource,
                        id: dataSource?.id ?? uuid(),
                        name: state.name || state.defaultName,
                        scopeId: scope.value.id,
                    };
                    onApply(newDataSource);
                },
                onScopeOptionsChange: (result: Result<UDataSource>) => {
                    if (result.kind === 'ok') {
                        dataSourceRef.current = result.value;
                        setState((s) => ({ ...s, error: undefined }));
                    } else {
                        dataSourceRef.current = undefined;
                        setState((s) => ({ ...s, error: result }));
                    }
                },
                scopeOptions: innerScopeOptions,
            };
        }, [core, setState, getState, dataSource?.id, scope, onApply]);

    const onDefaultNameChange = React.useCallback(
        (newDefaultTitle: string) =>
            setState((s) => ({
                ...s,
                defaultName: createUniqueName(newDefaultTitle, otherDataSourceTitles),
            })),
        [otherDataSourceTitles, setState]
    );

    return (
        <form className={styles.dataSourceEditor} onSubmit={innerOnApply}>
            <div className={styles.main}>
                {scopeOptions.length > 1 && (
                    <RTDDropdown
                        label={APP_STRINGS.forms.dataSource.type}
                        options={scopeOptions}
                        onChange={onScopeChange}
                        selectedKey={scope.value?.id}
                    />
                )}
                <TextField
                    className={styles.name}
                    data-automation-id="data-source-title"
                    value={name}
                    label={APP_STRINGS.forms.dataSource.name}
                    placeholder={defaultName ?? APP_STRINGS.forms.dataSource.namePlaceholder}
                    onChange={updateDataSourceName}
                />
                {scope.value ? (
                    <scope.value.DataSourceForm
                        dataSource={dataSource}
                        onChange={onScopeOptionsChange}
                        setDefaultTitle={onDefaultNameChange}
                    />
                ) : (
                    <MessageBar messageBarType={MessageBarType.error}>{scope.err}</MessageBar>
                )}
                {editorError?.err && <MessageBar messageBarType={MessageBarType.error}>{editorError.err}</MessageBar>}
            </div>
            <PanelFooter className={styles.footer}>
                <PrimaryButton type="submit" disabled={editorError !== undefined || nameError !== undefined}>
                    {APP_STRINGS.utilButtons.apply}
                </PrimaryButton>
                <DefaultButton onClick={props.onCancel}>{APP_STRINGS.utilButtons.cancel}</DefaultButton>
            </PanelFooter>
        </form>
    );
});
