import * as H from 'history';
import { autorun, IReactionDisposer, observable } from 'mobx';
import { InterfaceFor } from '../../common';

export type BlockFunc = (...args: [H.Location, H.Action] | [undefined, undefined]) => string | false | undefined | void;

export type IHistoryService = InterfaceFor<HistoryService>;

/**
 * The history library only supports 1 block at a time. This class allows us
 * to block in as many places as we'd like.
 *
 * history v5 blocks page unload for us (as of writing we're on v4), which will
 * allow us to simplify this.
 */
export class HistoryService {
    public readonly h = observable.box<undefined | H.History>(undefined, {
        deep: false,
    });

    private readonly blocks: Set<BlockFunc> = new Set();
    private readonly disposeHistoryObserver: IReactionDisposer;

    constructor() {
        window.addEventListener('beforeunload', this.onBrowserPageUnload);

        let disposeBlock: undefined | (() => void) = undefined;
        this.disposeHistoryObserver = autorun(() => {
            disposeBlock?.();
            disposeBlock = this.h.get()?.block(this.blockFunc);
        });
    }

    public dispose() {
        this.disposeHistoryObserver();
        window.removeEventListener('beforeunload', this.onBrowserPageUnload);
    }

    public block = (fnc: BlockFunc): (() => void) => {
        this.blocks.add(fnc);
        return () => this.blocks.delete(fnc);
    };

    private onBrowserPageUnload = (event: Event) => {
        if (this.blockFunc(undefined, undefined) !== undefined) {
            event.preventDefault();
            event.returnValue = true;
        }
    };

    private blockFunc: BlockFunc = (...args) => {
        for (const blocker of this.blocks) {
            const res = blocker(...args);
            if (typeof res === 'string') {
                return res;
            }
        }
        return;
    };

    /**
     *
     * @returns `string` when leaving is blocked, `undefined` otherwise
     */
    public leaveDashboardsBlocked = (): undefined | string => {
        const res = this.blockFunc(undefined, undefined);
        if (typeof res === 'string') {
            return res;
        }
        return;
    };
}
