export type FeatureFlag = typeof featureFlags[number];

export const featureFlags = [
    'ShowShareMenu',
    'ShowConnectionButtons',
    'ShowOpenNewWindowButton',
    'ShowFileMenu',
    'ShowToS',
    'ShowPersona',
    'IFrameAuth',
    'PersistAfterEachRun',
    'ShowSmoothIngestion',
    'RefreshConnection',
    'ShowPageHeader',
    'HideConnectionPane',
    'SkipMonacoFocusOnInit',
    'HighchartsSupport',
    'UseMeControl',
    /** Block access to dashboards and hides the left vertical navigation bar (which also includes One-Click ingestion) */
    'EnableDashboards',
    /**
     * Block access to dashboards (removes the dashboards icon from the left navigation bar
     * and all dashboards features from KWE, like pin-to-dashboards)
     * */
    'HideDashboardsOnly',
    'Msal',
    'QueryResultsSearch',
    'SyncBrowserTabs',
    'EnableEventHubSource',
    'EnableCreateExternalTable',
    'HighlightUrlColumns',
    'Timezone',
    'BrowseSource',
    'EnableDashboardsDevMenu',
    'UndoLatestClosedTabs',
    /**
     * Requires ShowShareMenu and IFrameAuth also be set.
     * Requires `origin` query param (i.e. `origin=https://portal.microsoftgeneva.com/`) to verify trusted source
     * The Share Menu will be displayed as a single Share button
     * that uses `postMessage` to send relevant query details to the parent hosting the IFRAME.
     */
    'UseIFrameSharing',
    'Msalv2',
    'Msalv2Logs',
] as const;

export type FeatureFlagMap = { [key in FeatureFlag]?: boolean };

export function mergeFeatureFlags(defaultMap: FeatureFlagMap, overrideMap: FeatureFlagMap): FeatureFlagMap {
    return { ...defaultMap, ...overrideMap };
}
