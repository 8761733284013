import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { Fragment } from 'react';

import { RTDDropdownProps, RTDBackingDropdown } from '../fabric';

import { DropdownPill, DropdownPillErrorButtonProps } from './pill/DropdownPill';

import styles from './ParameterDropdown.module.scss';

export type ParameterDropdownProps = ParameterExtendedDropdownProps | ParameterDropdownPillProps;

interface ParameterDropdownBaseProps extends RTDDropdownProps {
    name: string;
    isSelectAll: boolean;
    containsSelectAll: boolean;
    variables?: string[];
    showVariablesInPill?: boolean;

    onRenderHeader?: () => JSX.Element | null;
}

interface ParameterDropdownPillProps extends ParameterDropdownBaseProps {
    isPill: true;

    loading?: boolean;
    error?: React.ReactNode;
    errorButtonProps?: DropdownPillErrorButtonProps;
}

interface ParameterExtendedDropdownProps extends ParameterDropdownBaseProps {
    isPill: false;
    isEmpty?: {
        noSelectionTitle: string;
    };
}

const backingDropdown: RTDBackingDropdown = { type: 'standard' };

export const ParameterDropdown: React.FC<ParameterDropdownProps> = (props) => {
    const { isSelectAll, containsSelectAll, variables, showVariablesInPill, onRenderHeader, name } = props;

    const [variablesOptionsChild, onRenderCustomTooltipChildren] = React.useMemo(() => {
        const child = variables ? <ParameterDropdownVariables variables={variables} /> : undefined;

        return [showVariablesInPill ? child : undefined, child ? () => child : undefined];
    }, [showVariablesInPill, variables]);

    return (
        <DropdownPill
            backingDropdown={backingDropdown}
            {...props}
            customOptionsChild={variablesOptionsChild}
            forceDisplayedSelectionCount={isSelectAll ? 'all' : undefined}
            containsSelectAll={containsSelectAll}
            onRenderCustomTooltipChildren={onRenderCustomTooltipChildren}
            onRenderHeader={onRenderHeader}
            aria-label={name}
        />
    );
};

export const ParameterDropdownVariables: React.FC<{ variables: string[] }> = ({ variables }) => (
    <>
        {variables.map((variable, index) => (
            <Fragment key={variable}>
                <Icon className={styles.icon} iconName="Parameter" />
                <span className={styles.variable}>{index !== variables.length - 1 ? `${variable}, ` : variable}</span>
            </Fragment>
        ))}
    </>
);
