import { getTheme, loadTheme as fabricLoadtheme } from 'office-ui-fabric-react/lib/Styling';
import { DarkCustomizations } from '@uifabric/theme-samples';
import { RootStore } from './rootStore';
import { IReactionDisposer } from 'mobx/lib/core/reaction';
import { autorun } from 'mobx';
import { IFrameMessage } from '../utils/iFrameMessage';
import { Theme } from '@kusto/common';
import { ITheme } from 'office-ui-fabric-react';

type IbizaTheme = 'dark' | 'light' | 'blue' | 'azure';

/** ThemeHandler is Responsible for:
 * 1. Updating theme in data-store in response to external inputs (query params, iframe events)
 * 2. Loading the actual styles for office fabric components.
 *
 * Note: Loading styles for non-fabric components (monaco, ag-grid) are _not_ handled here.
 *    instead they are handled in their respective components.
 */
export class ThemeHandler {
    public disposer: IReactionDisposer;

    private loadedTheme = Theme.Light;
    private defaultTheme = getTheme();
    private darkTheme: ITheme;

    constructor(public rootStore: RootStore, themeFromQueryParam?: string) {
        this.darkTheme = { ...DarkCustomizations.settings.theme };
        this.darkTheme.palette.whiteTranslucent40 = 'rgba(27,26,25,.4)';
        this.darkTheme.semanticColors.bodySubtext = this.darkTheme.palette.neutralSecondary;
        this.handleThemeChangeMessage = this.handleThemeChangeMessage.bind(this);

        // We support getting the initial theme in query parameters (used by Ibiza for example)
        if (themeFromQueryParam) {
            if (themeFromQueryParam === 'dark' && rootStore.settings.theme !== 'Dark') {
                rootStore.settings.setTheme(Theme.Dark);
            }

            if (themeFromQueryParam !== 'dark' && rootStore.settings.theme === 'Dark') {
                rootStore.settings.setTheme(Theme.Light);
            }
        }

        // Register for messages from hosting iframe
        // (theme can be  changed in ibiza portal for example and we need to update accordingly)
        window.addEventListener('message', this.handleThemeChangeMessage, false);

        // loadTheme will load the actual CSS (opposed to setTheme that only sets the desired value in our store).
        // By default we're loading a light theme, so if the settings has dark theme let's load it now
        if (rootStore && rootStore.settings && rootStore.settings.theme === Theme.Dark) {
            this.loadTheme(Theme.Dark);
        }

        // Every time we change the theme store from now on we'll load it again.
        this.disposer = autorun(() => {
            if (rootStore && rootStore.settings) {
                if (rootStore.settings.theme === Theme.Dark && this.loadedTheme !== Theme.Dark) {
                    this.loadTheme(Theme.Dark);
                } else if (rootStore.settings.theme === Theme.Light && this.loadedTheme !== Theme.Light) {
                    this.loadTheme(Theme.Light);
                }
            }
        });
    }

    private loadTheme(theme: Theme) {
        if (this.loadedTheme !== theme) {
            fabricLoadtheme(theme === Theme.Dark ? this.darkTheme : this.defaultTheme);
            this.loadedTheme = theme;
        }
    }

    /**
     * Sets the correct theme in store in response to theme change message from hosting iframe (usually Ibiza portal).
     * @param event the iframe message event
     */
    private handleThemeChangeMessage(event: MessageEvent) {
        const eventData: IFrameMessage = event.data;
        switch (eventData.type) {
            case 'theme':
                const theme = eventData.message.name as IbizaTheme;
                this.rootStore.settings.setTheme(theme === 'dark' ? Theme.Dark : Theme.Light);
                break;
            default:
                break;
        }
    }
}
