import { Client } from '@microsoft/microsoft-graph-client';

import { APP_CONSTANTS } from '../res';
import { ResourceCache } from '../resource/ResourceCache';
import { ResourceMetadata } from '../resource/metadata/ResourceMetadata';
import { ResourceErrors } from '../resource/errors/ResourceErrors';

import { PersistentQueryCache, PersistentUserSessionCache, initIndexedDB } from './indexedDB';
import { RestApiClient } from './backend/RestApiClient';
import { TelemetryService } from './backend/TelemetryService';
import { QueryService } from './query/QueryService';
import { DashboardService } from './backend/DashboardService';
import { UserService } from './backend/UserService';
import { GraphService } from './graph/GraphService';
import { DevGraphService } from './graph/DevGraphService';
import type { ITelemetryService } from './backend';
import { MembershipService } from './backend/MembershipService';
import { isMicrosoftInternalAccount } from './domain';
import { GraphAuthProvider } from './graph/GraphAuthProvider';
import { UserSettingsState } from './react/UserSettingsState';
import type { IRtdDashboardsCore } from './types';
import { RtdHostConfig } from './config';
import { IGraphService } from './graph/IGraphService';
import { NavUtil } from './util/NavUtil';
import { HistoryService } from './react/HistoryService';
import { FeatureFlags } from './util/FeatureFlags';

export async function dashboardsCoreInit(config: RtdHostConfig): Promise<IRtdDashboardsCore> {
    const environmentConfig = APP_CONSTANTS.sharedEnvironmentUrls[config.environmentType];

    const telemetryService: ITelemetryService = new TelemetryService(
        config.appInsights,
        config.supportEmailAddress,
        config.userAccount
    );

    const metadataApiScopes = [environmentConfig.persistenceApiResourceId + '/user_impersonation'];
    const metadataApiClient = new RestApiClient(
        environmentConfig.persistenceApiUrl,
        metadataApiScopes,
        config.authenticationProvider
    );

    let graphService: IGraphService;

    if (config.environmentType === 'production' || !isMicrosoftInternalAccount(config.userAccount)) {
        const authProvider = new GraphAuthProvider(config.authenticationProvider, [
            // Related users
            'People.Read',
            // User images, search
            'User.ReadBasic.All',
            // Group search
            config.avoidGroupMemberReadAllPermission ? 'Group.Read.All' : 'GroupMember.Read.All',
        ]);
        const graphClient = Client.initWithMiddleware({
            authProvider,
        });

        graphService = new GraphService(telemetryService, graphClient);
        // If not prod, use dev GraphService
    } else {
        graphService = new DevGraphService();
    }

    const dashboardService = new DashboardService(metadataApiClient);

    const indexedDB = initIndexedDB();

    const queryCache = new PersistentQueryCache(indexedDB);
    queryCache.clearOldQueries();
    const userSessionCache = new PersistentUserSessionCache(indexedDB);

    const queryService = new QueryService(queryCache, telemetryService, config.scopes);

    const resourceErrors = new ResourceErrors(telemetryService);
    const resourceCache = new ResourceCache();
    const resourceMetadata = new ResourceMetadata();

    const userService = new UserService(
        resourceMetadata,
        resourceCache,
        resourceErrors,
        metadataApiClient,
        config.userAccount
    );
    const membershipService = new MembershipService(metadataApiClient);

    const [tenantProfile, userSettingsState] = await Promise.all([
        await userService.getTenantProfile(),
        await UserSettingsState.init(userService, telemetryService, config),
    ]);

    const featureFlags = new FeatureFlags(tenantProfile.featureFlags);

    const dashboardTemplates =
        typeof config.dashboardTemplates === 'function'
            ? config.dashboardTemplates(featureFlags)
            : config.dashboardTemplates;

    const historyService = new HistoryService();

    const core: IRtdDashboardsCore = {
        hostConfig: {
            dashboardTemplates,
            initialHeadingLevel: config.initialHeadingLevel,
            environmentType: config.environmentType,
            requireJsUrl: config.requireJsUrl,
            supportEmailAddress: config.supportEmailAddress,
            disableLegacyBreadcrumbs: config.disableLegacyBreadcrumbs,
            exploreAction: config.exploreAction,
            baseUrl: config.baseUrl,
            enableDevMenu: config.enableDevMenu,
            openTimeZoneSettings: config.openTimeZoneSettings,
        },
        authenticationProvider: config.authenticationProvider,
        telemetryService,
        dashboardService,
        queryService,
        userService,
        userSessionCache,
        membershipService,
        featureFlags,
        graphService,
        userSettingsState,
        history: historyService,
        dispose() {
            queryService.dispose();
            historyService.dispose();
            featureFlags.dispose();
        },
        resourceCache,
        resourceMetadata,
        resourceErrors,
        navUtil: new NavUtil(config.baseUrl),
    };
    return core;
}
