import * as React from 'react';
import { useMemo } from 'react';
import { useThemeState } from '../../../domain/theming';
import { buildShadowContentBoxShadow } from './util';

import styles from './ShadowHr.module.scss';

export const ShadowHr: React.FC<{
    className?: string;
    style?: React.CSSProperties;
}> = ({ className, style }) => {
    const { isDark } = useThemeState();

    const finalStyles = useMemo(
        (): React.CSSProperties => ({
            ...style,
            boxShadow: buildShadowContentBoxShadow(isDark),
            borderColor: 'transparent',
        }),
        [style, isDark]
    );

    return <hr className={className ? `${styles.shadowHr} ${className}` : className} style={finalStyles} />;
};
