export const combineReducers = <TState extends unknown>(
    reducers: {
        // Union with undefined to allow for default/initial states
        // I don't think there is a way to strongly type actions here
        [key in keyof TState]: (
            state: TState[key] | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action: any
        ) => TState[key];
    },
    debug?: boolean
) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (state: TState | undefined, action: any): TState => {
        const newState: TState = {} as TState;

        let newStateProduced = false;

        for (const reducerKey of Object.keys(reducers) as Array<keyof TState>) {
            const reducer = reducers[reducerKey];
            const existingReducerState = state ? state[reducerKey] : undefined;

            const newReducerState = reducer(existingReducerState, action);

            // Check if new state was produced
            if (!Object.is(existingReducerState, newReducerState)) {
                newStateProduced = true;
            }

            newState[reducerKey] = newReducerState;
        }

        if (debug) {
            // eslint-disable-next-line no-console
            console.log(`Received: ${action.type}, payload: ${JSON.stringify(action)}`);
        }

        // If we produced new state, return new state object
        return (newStateProduced ? newState : state) as TState;
    };
};
