import * as React from 'react';
import { useMemo } from 'react';
import { Text } from 'office-ui-fabric-react';

import { DropdownPillProps } from './DropdownPill';

import styles from './DropdownPill.module.scss';

export const useOnRenderTooltipContent = ({ name, onRenderCustomTooltipChildren }: DropdownPillProps) => {
    return useMemo(() => {
        if (!onRenderCustomTooltipChildren) {
            return undefined;
        }

        return () => (
            <div className={styles.tooltip}>
                <Text>{name}</Text>
                <div className={styles.tooltipChildren}>{onRenderCustomTooltipChildren()}</div>
            </div>
        );
    }, [name, onRenderCustomTooltipChildren]);
};
