import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { TextField, IDropdownOption, DirectionalHint } from 'office-ui-fabric-react';
import { useETPSelector } from '../../../../../../../store/editTile';
import { RTDDropdown } from '../../../../../../../components/fabric';
import { YAxisConfig } from '../../../../../../../domain';
import { useThunkReducer } from '../../../../../../../common';
import { LabelWithInfo } from '../../../../../../../components/fabric/label/LabelWithInfo';
import { APP_STRINGS, BASE_Y_AXIS_ID } from '../../../../../../../res';
import { visualOptionsSelectorBuilder } from '../../../../../lib';
import { ConfigurationItemCallout } from '../../configurationList/ConfigurationListCallout/ConfigurationItemCallout';
import styles from '../../configurationList/ConfigurationListCallout/ConfigurationItemCallout.module.scss';
import { columnDropdownOption, columnOptionKey, ETPColumnOption, useSchemaDerived } from '../../columnDropdowns/utils';
import { multipleYAxesReducer } from '../reducer';
import { axisScaleOptions } from '../../../../../constants';

const appStringsLocal = APP_STRINGS.editTilePage.visualConfig.multipleYAxes;

const yColumnsSelector = visualOptionsSelectorBuilder((s) => s.yColumns);

const yColumnsDropdownTooltipProps = {
    id: 'multiple-yaxis--ycolumn-description',
};

const onRenderColumnLabel = () => (
    <LabelWithInfo
        text={appStringsLocal.columnDropdownLabel}
        infoText={appStringsLocal.yColumnDropdownInfo}
        tooltipProps={yColumnsDropdownTooltipProps}
    />
);

interface YAxisColumnsDropdownProps {
    selectedColumns: string[];
    onChange: (_: unknown, option?: IDropdownOption) => void;
    baseYAxis: boolean;
}

const YAxisColumnsDropdown: React.FC<YAxisColumnsDropdownProps> = ({ selectedColumns, onChange, baseYAxis }) => {
    const yColumns = useETPSelector(yColumnsSelector);
    const schemaDerived = useSchemaDerived();

    const columnOptions = useMemo(() => {
        return yColumns.type === 'infer'
            ? schemaDerived.options
            : yColumns.value.map((val) => columnDropdownOption(val));
    }, [yColumns, schemaDerived.options]);

    const { selectedKey, selectedKeys } = useMemo(() => {
        let selectedKeysMemo: string[] = [];
        let selectedKeyMemo = '';
        if (!baseYAxis) {
            selectedKeyMemo = columnOptionKey(selectedColumns[0]);
        } else {
            selectedKeysMemo = selectedColumns.map((op) => columnOptionKey(op));
        }
        return { selectedKey: selectedKeyMemo, selectedKeys: selectedKeysMemo };
    }, [selectedColumns, baseYAxis]);

    const {
        options,
        invalidSelection,
    }: {
        options: ETPColumnOption[];
        invalidSelection?: boolean;
    } = useMemo(() => {
        if (!baseYAxis && !columnOptions.some((e) => e.key === selectedKey)) {
            return {
                options: [columnDropdownOption(selectedColumns[0]), ...columnOptions],
                invalidSelection: true,
            };
        }
        return {
            options: [...columnOptions],
        };
    }, [selectedColumns, columnOptions, baseYAxis, selectedKey]);

    const isMultiSelect = baseYAxis;

    return (
        <RTDDropdown
            className={styles.fullWidth}
            onRenderLabel={onRenderColumnLabel}
            options={options}
            selectedKey={isMultiSelect ? undefined : selectedKey}
            selectedKeys={isMultiSelect ? selectedKeys : undefined}
            onChange={onChange}
            ariaLabel={appStringsLocal.columnDropdownLabel}
            aria-describedby={yColumnsDropdownTooltipProps.id}
            // combobox only displays one item so that Narrator can announce it.
            role="combobox"
            errorMessage={invalidSelection ? appStringsLocal.yColumnInvalidSelection : ''}
            disabled={baseYAxis}
            multiSelect={isMultiSelect}
        />
    );
};

export interface MultipleYAxesCalloutProps {
    yAxis: YAxisConfig;
    onClose: () => void;
    onSave: (yAxis: YAxisConfig) => void;
    targetRef: React.RefObject<Element>;
    baseYAxisColumns: string[];
}

export const MultipleYAxesCallout: React.FC<MultipleYAxesCalloutProps> = ({
    yAxis,
    onClose,
    onSave,
    targetRef,
    baseYAxisColumns,
}) => {
    const [state, dispatch, getState] = useThunkReducer(multipleYAxesReducer, yAxis);

    const { onLabelChange, onColumnChange, onMinValueChange, onMaxValueChange, onYAxisScaleChange } = useMemo(() => {
        const onLabelChangeMemo = (_: unknown, value?: string) => dispatch({ type: 'setLabel', label: value ?? '' });

        const onColumnChangeMemo = (_: unknown, option?: IDropdownOption) => {
            if (option && option.data) {
                dispatch({ type: 'setColumn', column: option.data.value });
            }
        };

        const onMinValueChangeMemo = (_: unknown, value?: string) =>
            dispatch({
                type: 'updateYAxisLimit',
                property: 'yAxisMinimumValue',
                value: value ?? '',
            });

        const onMaxValueChangeMemo = (_: unknown, value?: string) =>
            dispatch({
                type: 'updateYAxisLimit',
                property: 'yAxisMaximumValue',
                value: value ?? '',
            });

        const onYAxisScaleChangeMemo = (_: unknown, option?: IDropdownOption) => {
            dispatch({
                type: 'setYAxisScale',
                yAxisScale: option?.key as YAxisConfig['yAxisScale'],
            });
        };

        return {
            onLabelChange: onLabelChangeMemo,
            onColumnChange: onColumnChangeMemo,
            onMinValueChange: onMinValueChangeMemo,
            onMaxValueChange: onMaxValueChangeMemo,
            onYAxisScaleChange: onYAxisScaleChangeMemo,
        };
    }, [dispatch]);

    const innerOnSave = useCallback(() => onSave(getState()), [onSave, getState]);

    return (
        <ConfigurationItemCallout
            title={appStringsLocal.multipleYAxesCalloutTitle}
            onSave={innerOnSave}
            onClose={onClose}
            targetRef={targetRef}
            directionalHint={DirectionalHint.leftCenter}
        >
            <>
                <YAxisColumnsDropdown
                    selectedColumns={yAxis.id === BASE_Y_AXIS_ID ? baseYAxisColumns : state.columns}
                    onChange={onColumnChange}
                    baseYAxis={yAxis.id === BASE_Y_AXIS_ID}
                />
                <TextField
                    onChange={onLabelChange}
                    value={state.label}
                    className={styles.fullWidth}
                    label={appStringsLocal.yAxisLabelInputLabel}
                    autoComplete="off"
                />
                <TextField
                    className={styles.fullWidth}
                    placeholder={APP_STRINGS.editTilePage.visualConfig.textInputInferPlaceholder}
                    value={state.yAxisMaximumValue?.toString()}
                    label={APP_STRINGS.editTilePage.visualConfig.yAxisMaximumValue.inputLabel}
                    type="number"
                    onChange={onMaxValueChange}
                />
                <TextField
                    className={styles.fullWidth}
                    placeholder={APP_STRINGS.editTilePage.visualConfig.textInputInferPlaceholder}
                    value={state.yAxisMinimumValue?.toString() ?? ''}
                    label={APP_STRINGS.editTilePage.visualConfig.yAxisMinimumValue.inputLabel}
                    type="number"
                    onChange={onMinValueChange}
                />
                <RTDDropdown
                    className={styles.fullWidth}
                    selectedKey={state.yAxisScale}
                    options={axisScaleOptions}
                    onChange={onYAxisScaleChange}
                    label={APP_STRINGS.editTilePage.visualConfig.axisScale.label.yAxisScale}
                />
            </>
        </ConfigurationItemCallout>
    );
};
