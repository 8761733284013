import * as React from 'react';
import { IButtonProps, Callout, BaseButton, Button, ICalloutProps, DirectionalHint } from 'office-ui-fabric-react';
import { useRef, useState, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';

import { useCurrent } from '../../../common';

import styles from './ButtonWithCallout.module.scss';

export interface ButtonWithCalloutProps extends IButtonProps {
    component: React.ComponentClass<IButtonProps>;
    calloutContent: JSX.Element | string;
    activationType?: 'click' | 'callback';
    timeout?: number;
    calloutProps?: ICalloutProps;
}

export interface ButtonWithCalloutRef {
    open: () => void;
}

export const ButtonWithCallout = forwardRef<ButtonWithCalloutRef, ButtonWithCalloutProps>(
    (
        {
            component: Component,
            calloutContent,
            activationType = 'click',
            onClick,
            timeout = 2000,
            calloutProps,
            ...props
        },
        ref
    ) => {
        const buttonRef = useRef<HTMLDivElement>(null);

        const [calloutTimeoutId, setCalloutTimeoutId] = useState<number | undefined>(undefined);
        const isCalloutOpen = calloutTimeoutId !== undefined;
        const currentCalloutTimeoutId = useCurrent(calloutTimeoutId);

        const handleTimeout = useCallback(() => {
            if (isCalloutOpen && currentCalloutTimeoutId.current !== undefined) {
                clearTimeout(currentCalloutTimeoutId.current);
            }

            const timeoutId = window.setTimeout(() => setCalloutTimeoutId(undefined), timeout);
            setCalloutTimeoutId(timeoutId);
        }, [isCalloutOpen, currentCalloutTimeoutId, timeout]);

        useImperativeHandle(
            ref,
            (): ButtonWithCalloutRef => ({
                open: () => handleTimeout(),
            })
        );

        const innerOnClick = useMemo(
            () =>
                activationType === 'click'
                    ? (
                          event: React.MouseEvent<
                              | HTMLAnchorElement
                              | HTMLButtonElement
                              | HTMLDivElement
                              | BaseButton
                              | Button
                              | HTMLSpanElement,
                              MouseEvent
                          >
                      ) => {
                          onClick?.(event);
                          handleTimeout();
                      }
                    : undefined,
            [onClick, handleTimeout, activationType]
        );

        return (
            <>
                <div ref={buttonRef} className={styles.wrapper}>
                    <Component {...props} onClick={innerOnClick} />
                </div>
                <Callout
                    directionalHint={DirectionalHint.topCenter}
                    {...calloutProps}
                    target={buttonRef}
                    hidden={!isCalloutOpen}
                    // Justification: didn't add this, just updated lint config
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role="alertDialog"
                >
                    <div className={styles.content}>{calloutContent}</div>
                </Callout>
            </>
        );
    }
);
