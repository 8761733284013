import * as React from 'react';
import { assertNever, Toggle } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { APP_STRINGS } from '../../../../res';
import { ETPState, useDashboardStore, useETPSelectors } from '../../../../store';
import { useCore } from '../../../../core';

import styles from './ExpandedToggle.module.scss';

function expandedToggleDisabledSelector(state: ETPState): boolean {
    switch (state.type) {
        case 'error':
        case 'loading':
            return true;
        case 'markdown':
            return false;
        case 'query':
            if (state.visual === undefined) {
                return true;
            }
            return false;
        default:
            assertNever(state);
    }
}

function forceTilePreviewExpandedToggleOffSelector(state: ETPState): boolean {
    switch (state.type) {
        case 'error':
        case 'loading':
            return true;
        case 'markdown':
            return false;
        case 'query':
            return state.visual === undefined;
        default:
            assertNever(state);
    }
}

const tilePreviewSelectors = {
    disabled: expandedToggleDisabledSelector,
    forceTilePreviewExpandedToggleOff: forceTilePreviewExpandedToggleOffSelector,
};

export const TilePreviewExpandedToggle: React.FC = observer(function TilePreviewExpandedToggle() {
    const userSettings = useCore().userSettingsState;
    const store = useDashboardStore();
    const { disabled, forceTilePreviewExpandedToggleOff } = useETPSelectors(tilePreviewSelectors);

    const onChange = (_: unknown, checked = false) =>
        userSettings.updateUserSetting('editTilePagePreviewExpanded', checked, store);

    return (
        <Toggle
            className={styles.tilePreviewExpandedToggle}
            disabled={disabled}
            checked={forceTilePreviewExpandedToggleOff ? false : userSettings.userSettings.editTilePagePreviewExpanded}
            label={APP_STRINGS.editTilePage.expandVisualToggleLabel}
            onChange={onChange}
        />
    );
});
