import * as React from 'react';
import { useMemo, useCallback } from 'react';
import { FocusTrapZone, TextField, KeyCodes, Text } from 'office-ui-fabric-react';

import { APP_STRINGS, APP_CONSTANTS } from '../../../res';
import { NoData } from '../../noData';

import { CustomListDropdown, CustomListDropdownProps } from './components/customListDropdown/CustomListDropdown';
import {
    CustomListDropdownMenu,
    CustomListDropdownMenuProps,
} from './components/customListDropdown/CustomListDropdownMenu';
import { CustomListDropdownHeaderProps, RTDDropdownOption } from './components/customListDropdown/types';
import { useCustomListDropdownDispatch } from './components/customListDropdown/CustomListDropdownMenuContext';

import standardStyles from './components/customListDropdown/CustomListDropdownMenu.module.scss';
import styles from './InputDropdown.module.scss';

export interface InputDropdownAdditionalProps {
    inputType?: string;

    placeholder?: string;
    validationError?: string;
    onInputChange:
        | ((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void)
        | undefined;
    onEnter: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
    onEscape: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
}

export type InputDropdownProps = CustomListDropdownProps & InputDropdownAdditionalProps;

export const InputDropdown: React.FC<InputDropdownProps> = ({ selectedKey, ...props }) => {
    // Options cannot be apart of the above spread because it is used in onRenderMenu
    const externalOptions = props.options;

    const activeSelectionKey = selectedKey ? `${selectedKey}-selected` : undefined;

    const options = useMemo(
        (): RTDDropdownOption[] => [
            {
                key: activeSelectionKey ? activeSelectionKey : APP_CONSTANTS.emptySelection,
                text:
                    selectedKey === APP_CONSTANTS.parameter.allSelection
                        ? APP_STRINGS.domain.parameter.selection.allTitle
                        : selectedKey ?? APP_CONSTANTS.emptySelection,
                hidden: true,
            },
            ...externalOptions,
        ],
        [activeSelectionKey, selectedKey, externalOptions]
    );

    return (
        <CustomListDropdown
            {...props}
            selectedKey={activeSelectionKey}
            options={options}
            // Unfortunately, there doesn't seem to be a nice way to arrange the types so that this cast is unnecessary
            onRenderMenu={onRenderMenu as (p: CustomListDropdownMenuProps) => JSX.Element | null}
        />
    );
};

const InputDropdownMenu: React.FC<InputDropdownProps & CustomListDropdownMenuProps> = (props) => {
    const {
        inputType,
        selectedKey,
        options,
        ariaLabel,
        placeholder,
        validationError,
        setIsOpen,
        onEnter,
        onEscape,
        onInputChange,
        onRenderHeaderPrefix,
    } = props;

    const defaultOption = useMemo(
        () => (selectedKey ? options.find((o) => o.key === selectedKey) : undefined),
        [selectedKey, options]
    );

    const onRenderHeader = (innerProps: CustomListDropdownHeaderProps) => (
        <InputDropdownMenuHeader
            {...innerProps}
            inputType={inputType}
            ariaLabel={ariaLabel}
            placeholder={placeholder}
            defaultValue={
                defaultOption?.key === `${APP_CONSTANTS.parameter.allSelection}-selected` ? '' : defaultOption?.text
            }
            validationError={validationError}
            onInputChange={onInputChange}
            onEnter={onEnter}
            onEscape={onEscape}
            setIsOpen={setIsOpen}
        />
    );

    return (
        <CustomListDropdownMenu
            {...props}
            onRenderHeaderPrefix={onRenderHeaderPrefix}
            onRenderHeader={onRenderHeader}
            noData={noRecentsComponent}
            // The first element is fake, so we can have 1 item and still have no data
            noDataCount={1}
        />
    );
};

const noRecentsComponent = (
    <NoData className={standardStyles.noResults} message={APP_STRINGS.parameterDropdown.noRecents} />
);

const InputDropdownMenuHeader: React.FC<
    CustomListDropdownHeaderProps & {
        inputType: string | undefined;

        ariaLabel: string | undefined;
        placeholder: string | undefined;
        defaultValue: string | undefined;
        validationError: string | undefined;

        onInputChange:
            | ((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void)
            | undefined;
        onEnter: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
        onEscape: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
        setIsOpen: ((open: boolean) => void) | undefined;
    }
> = ({
    inputType,
    ariaLabel,
    placeholder,
    defaultValue,
    validationError,
    onInputKeyDown,
    onInputChange: externalOnInputChange,
    onEnter,
    onEscape,
    setIsOpen,
    inputRef,
}) => {
    const [dispatch, getState] = useCustomListDropdownDispatch();

    const onInputChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            externalOnInputChange?.(event, newValue);

            // Typed into textbox, clear activeIndex
            dispatch({
                type: 'setActiveIndex',
                index: undefined,
            });
        },
        [externalOnInputChange, dispatch]
    );

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            switch (event.keyCode) {
                case KeyCodes.enter: {
                    if (onEnter && getState().activeIndex === undefined) {
                        onEnter(event);

                        setIsOpen?.(false);
                        return;
                    }

                    break;
                }
                case KeyCodes.escape: {
                    if (onEscape) {
                        onEscape(event);

                        setIsOpen?.(false);
                        return;
                    }

                    break;
                }
                default: {
                    break;
                }
            }

            // If the event wasn't handled above (by returning), continue running the event execution chain
            onInputKeyDown(event);
        },
        [onEnter, onEscape, onInputKeyDown, setIsOpen, getState]
    );

    return (
        <>
            <FocusTrapZone isClickableOutsideFocusTrap={true}>
                <TextField
                    componentRef={inputRef}
                    className={standardStyles.search}
                    type={inputType}
                    autoComplete="off"
                    onChange={onInputChange}
                    onKeyDown={onKeyDown}
                    ariaLabel={ariaLabel}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    errorMessage={validationError}
                />
            </FocusTrapZone>
            <Text className={styles.recentHeader}>{APP_STRINGS.parameterDropdown.recent}</Text>
        </>
    );
};

const onRenderMenu = (props: InputDropdownProps & CustomListDropdownMenuProps) => <InputDropdownMenu {...props} />;
