import { dependencies } from '../dependencies';

/**
 * List of origins that are trusted for privileged iframe communication
 */
const trustedIframeOrigins: readonly string[] = [
    'https://local.microsoftgeneva.com:44301',
    'https://portal.microsoftgeneva.com',
    'https://portal-east.microsoftgeneva.com',
    'https://portal-int.microsoftgeneva.com',
    'https://portal-east-int.microsoftgeneva.com',
    'https://metrics-uxob.dc.ad.msft.net:44301',
    'https://jarvis-west.dc.ad.msft.net',
    'https://jarvis-east.dc.ad.msft.net',
    'https://jarvis-west-int.cloudapp.net',
    'https://jarvis-east-int.cloudapp.net',
    'https://jarvis-int-west.microsoftgeneva.com',
    'https://jarvis-int-east.microsoftgeneva.com',
    'https://jarvis.core.eaglex.ic.gov',
    'https://jarvis-west.core.eaglex.ic.gov',
    'https://jarvis-east.core.eaglex.ic.gov',
    'https://jarvis-west.cloudapp.eaglex.ic.gov',
    'https://jarvis-east.cloudapp.eaglex.ic.gov',
    'https://jarvis.core.microsoft.scloud',
    'https://jarvis-west.core.microsoft.scloud',
    'https://jarvis-east.core.microsoft.scloud',
    'https://jarvis-west.cloudapp.microsoft.scloud',
    'https://jarvis-east.cloudapp.microsoft.scloud',
];

/**
 * Pass a query context message to a trusted iframe origin
 * @param message Message data to pass to parent window
 */
export const postSharedQueryContext = (message: unknown): void => {
    const origin = dependencies.deepLinkProperties.origin || '';
    if (!isTrustedOrigin({ origin } as Partial<MessageEvent>)) {
        console.error(`'${origin}' is not trusted.`);
        return;
    }
    window.parent?.postMessage(message, origin);
};

/**
 * Request an authentication token from the parent window
 */
export const postGetToken = (): void => {
    window.parent?.postMessage(
        {
            signature: 'queryExplorer',
            type: 'getToken',
        },
        '*'
    );
};

/**
 * Verify a passed message came from a whitelisted origin.
 * @param event The message received from the origin window
 * @returns true if the origin is whitelisted
 */
export const isTrustedOrigin = (event: Partial<MessageEvent>): boolean => {
    return trustedIframeOrigins.indexOf(event.origin ?? '') > -1;
};
