import * as React from 'react';

import { cssVariableNames, useThemeState } from '../../../domain/theming';

import { ShadowContentProps, ShadowContent } from './ShadowContent';

const colorForElevation = (elevation: Elevation): string => {
    switch (elevation) {
        case 0:
            return cssVariableNames.white;
        case 1:
            return cssVariableNames.gray20;
        case 2:
            return cssVariableNames.gray40;
        case 3:
            return cssVariableNames.gray60;
    }
};

type Elevation = 0 | 1 | 2 | 3;

interface WhiteShadowContentProps extends ShadowContentProps {
    elevation?: Elevation;
}

/**
 * A div with a "white" themed background and associated box shadow
 *
 * `elevation` is used in dark theme to determine the "height" of the element in question. Higher numbers are higher
 * elevation, which translates into lighter background colors. This value should be incremented if there is no contrast
 * between this elements background and its parent in dark theme
 */
export const WhiteShadowContent: React.FC<WhiteShadowContentProps> = ({ elevation = 0, ...props }) => {
    const { isDark } = useThemeState();

    const finalStyles: React.CSSProperties = {
        ...props.style,
        // Don't change elevation color if non-dark
        backgroundColor: colorForElevation(isDark ? elevation : 0),
    };

    return <ShadowContent {...props} style={finalStyles} />;
};
