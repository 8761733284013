import React from 'react';
import mobx from 'mobx';
import H from 'history';

import { IRtdDashboardsCore } from '../../../core';
import { DashboardStore } from '../../../store/dashboard';
import { IDashboardDocument, RestApiError } from '../../../core/domain';
import { RouteState } from '../../../domain';
import { APP_STRINGS } from '../../../res';
import { GlobalAction } from '../../../store';
import { MigrationResult } from '../../../migration';

export function useLoadDashboardFromUrl(
    core: IRtdDashboardsCore,
    dispatch: React.Dispatch<GlobalAction>,
    store: DashboardStore,
    history: H.History,
    urlDashboardId: string,
    routeState: RouteState
) {
    // Clear state on route exit
    React.useEffect(() => () => store.startLoading(), [store]);

    const { dashboardTemplate: template, edit } = routeState ?? {};

    // The order of these two hooks is important so that the second one won't
    // cause a re-direct if the first one is going to load a template

    // Saving a reference to the template that was used to create the dashboard
    // was considered, but this would make re-applying a template do nothing,
    // which is not desirable.
    React.useEffect(
        () => {
            if (template !== undefined) {
                store.loadTemplate(template).startEditing();
            }
        },
        // Be careful with the dependencies on this hook. It only works if the
        // only _real_ dependency is "template". We can't memo based on template
        // because that would stop us from loading the same template twice.
        [template, store]
    );

    React.useEffect(
        () =>
            mobx.runInAction(() => {
                if (urlDashboardId === 'new') {
                    // If no template has been loaded
                    if (store.state === undefined) {
                        history.replace(core.navUtil.path.catalog);
                    }
                }
                if (store.state?.meta.id === urlDashboardId) {
                    return;
                }
                store.startLoading();
                const controller = new AbortController();

                core.dashboardService
                    .getDashboard(urlDashboardId, controller.signal)
                    .then((res: MigrationResult<IDashboardDocument>) => {
                        if (controller.signal.aborted) {
                            return;
                        }
                        const loaded = store.loadMigrationResult(res, dispatch, {
                            kind: 'url',
                            url: history.location.search,
                        });
                        if (edit) {
                            loaded?.startEditing();
                        }
                    })
                    .catch((error) => {
                        if (controller.signal.aborted) {
                            return;
                        }
                        // eslint-disable-next-line no-console
                        console.error(error);
                        const isClientError =
                            error instanceof RestApiError && error.statusCode >= 400 && error.statusCode < 500;

                        store.setErrorMessage(
                            APP_STRINGS.dashboardPage.dashboardLoadErrorMessage[
                                isClientError ? 'clientError' : 'serverError'
                            ]
                        );
                    });

                return () => controller.abort();
            }),
        [urlDashboardId, edit, store, history, core, dispatch]
    );
}
