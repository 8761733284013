import { Dispatch, GlobalAction } from '../../store/redux';
import { VisitUrl } from '../../domain/navigation';

import { IErrorTypeConfig, IErrorMessage, IErrorTypeConfigItem } from './types';

export const configErrorsConfig = {
    appConfig: {
        allowedCount: 1,
        isGlobalPopup: true,
        message: 'Could not load application configuration.',
    },
    tenantConfig: {
        allowedCount: 1,
        isGlobalPopup: true,
        message: 'Could not load tenant configuration.',
    },
};

export const dashboardTypeErrorsConfig = {
    getDashboards: {
        allowedCount: 1,
        isGlobalPopup: true,
        message: 'Could not load the list of dashboards.',
    },
    getDashboard: {
        allowedCount: 2,
        isGlobalPopup: true,
        message: 'Could not load the selected dashboard.',
        onDismiss: (_dispatch: Dispatch<GlobalAction>, visitUrl: VisitUrl) => {
            visitUrl('/');
        },
    },
    createDashboard: {
        allowedCount: 1,
        isGlobalPopup: true,
        message: 'Could not add new dashboard.',
    },
    patchDashboard: {
        allowedCount: 5,
        isGlobalPopup: true,
        message: 'Could not save dashboard changes.',
    },
    deleteDashboard: {
        allowedCount: 5,
        isGlobalPopup: true,
        message: 'Could not delete dashboard.',
    },
    forgetDashboard: {
        allowedCount: 5,
        isGlobalPopup: true,
        message: 'Could not forget dashboard.',
    },
    validateSecurityGroups: {
        allowedCount: 2,
        isGlobalPopup: false,
        message: 'Could not validate security groups.',
    },
};

export const settingsTypeErrorsConfig = {
    getSettings: {
        allowedCount: 1,
        isGlobalPopup: true,
        message: 'Could not load user settings.',
    },
    updateSettings: {
        allowedCount: 2,
        isGlobalPopup: true,
        message: 'Could not save user settings.',
    },
};

export const errorsTypeConfig: IErrorTypeConfig = {
    ...configErrorsConfig,
    ...dashboardTypeErrorsConfig,
    ...settingsTypeErrorsConfig,
};

const baseNetworkMessageErrorConfig: IErrorTypeConfigItem = {
    allowedCount: 1,
    isGlobalPopup: true,
    // Please note, this is not a todo here, but only where used
    message: 'TODO: Override me',
};

export const reportMessageString = 'Please report this issue to the Dashboards team.';

export const unknownErrorMessageString = `An unknown error occurred. ${reportMessageString}`;

// TODO: Fix capital "Please" in reportMessageString
export const noRouteErrorMessage: IErrorMessage = {
    // Not a real HTTP status code
    statusCode: 1000,
    message: `No connection to server. If you are connected to the internet and can reach other services, ${reportMessageString}`,
};

export const unknownErrorMessage: IErrorMessage = {
    // Not a real HTTP status code
    statusCode: 1001,
    message: `An unknown error occurred. ${reportMessageString}`,
};

export const networkErrorMessages: {
    [statusCode: number]: IErrorMessage;
} = {
    400: {
        statusCode: 400,
        message: `Bad Request. ${reportMessageString}`,
    },
    401: {
        statusCode: 401,
        message: 'You are not authorized to access this resource.',
    },
    403: {
        statusCode: 403,
        message: 'You are not authorized to access this resource.',
    },
    404: {
        statusCode: 404,
        message: 'Resource could not be found.',
    },
    409: {
        statusCode: 409,
        message: `The saved version of this resource is newer than the version you are editing and cannot be overridden. Please refresh the page and try again.`,
    },
    500: {
        ...unknownErrorMessage,
        statusCode: 500,
    },
    [noRouteErrorMessage.statusCode]: noRouteErrorMessage,
    [unknownErrorMessage.statusCode]: unknownErrorMessage,
};

// NOTE: Status code 0 is reserved for "normal" errors
export const messageCodeErrorConfig: {
    [statusCode: number]: IErrorTypeConfigItem;
} = (() => {
    const config: {
        [statusCode: number]: IErrorTypeConfigItem;
    } = {};

    for (const statusCode of Object.keys(networkErrorMessages)) {
        config[parseInt(statusCode, 10)] = baseNetworkMessageErrorConfig;
    }

    return config;
})();
