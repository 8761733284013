import { assertNever } from '@uifabric/utilities';

import { KustoEditor } from '../../components/Editors/KustoEditor';
import type { IQueryService } from '../../core/query/QueryService';
import type { UKustoDataSource } from '../../core/domain';
import type { CreateTelemetry } from '../telemetry';

import { KustoSchema } from './types';

export interface IKustoInternalParameter {
    name: string;
    type: string;
}

export interface KustoLanguageServerSchema {
    Databases: KustoSchema;
    Plugins: unknown[];
}

const emptySchema: KustoLanguageServerSchema = {
    Databases: {},
    Plugins: [],
};

function dataSourceToKustoStrings(
    dataSource: undefined | UKustoDataSource
): [clusterConnectionString: string, databaseInContextName: string] {
    if (!dataSource) {
        return ['', ''];
    }

    switch (dataSource.kind) {
        case 'app-insights':
            return [`${dataSource.subscription};${dataSource.resourceGroup}`, dataSource.application];
        case 'log-analytics':
            return [`${dataSource.subscription};${dataSource.resourceGroup}`, dataSource.workspace];
        case 'manual-kusto':
            return [dataSource.clusterUri, dataSource.database];
        default:
            assertNever(dataSource);
    }
}

export async function updateMonacoSchema(
    editor: monaco.editor.IStandaloneCodeEditor,
    queryService: IQueryService,
    trackException: CreateTelemetry<Error>,
    parameters: IKustoInternalParameter[],
    dataSource: undefined | UKustoDataSource,
    dashboardId: string
): Promise<void> {
    const [worker, schema] = await Promise.all([
        KustoEditor.initWorker(editor),
        // if schema fetch fails, log the error (could be a busted data source / temporary issue).
        dataSource &&
            queryService
                .getDataSourceSchema(dataSource, dashboardId)
                .then((s) => {
                    if (s.err) {
                        throw new Error(s.err);
                    }
                    return s.value;
                })
                .catch((e) => {
                    trackException(e);
                    return undefined;
                }),
    ] as const);

    if (worker) {
        await worker.setSchemaFromShowSchema(
            schema ?? emptySchema,
            ...dataSourceToKustoStrings(dataSource),
            parameters
        );
    }
}

export async function updateActiveParameters(
    editor: monaco.editor.IStandaloneCodeEditor,
    allParameters: IKustoInternalParameter[]
): Promise<IKustoInternalParameter[]> {
    const model = editor.getModel();
    const position = editor.getPosition();

    if (!model) {
        throw new Error('Monaco has no available model');
    }

    const uri = model.uri.toString();

    const worker = await KustoEditor.initWorker(editor);

    const offset = position ? model.getOffsetAt(position) : 0;

    if (!worker) {
        throw new Error('Monaco has no available model');
    }

    // Update parameters, just in case.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await (worker as any).setParameters(allParameters, offset);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (worker as any).getReferencedGlobalParams(uri, offset);
}
