import * as React from 'react';
import { Label, TooltipHost, FontIcon, ILabelProps, ITooltipProps } from 'office-ui-fabric-react';

export interface LabelWithInfoProps extends ILabelProps {
    text: string;
    infoText: string;
    onRenderInfo?: () => JSX.Element;
    tooltipProps?: ITooltipProps;
}

export const LabelWithInfo: React.FC<LabelWithInfoProps> = ({
    text,
    infoText,
    onRenderInfo,
    tooltipProps,
    ...props
}) => (
    <Label {...props}>
        {text}{' '}
        {!props.disabled ? (
            <TooltipHost
                calloutProps={{ gapSpace: 0 }}
                tooltipProps={{
                    ...tooltipProps,
                    onRenderContent: onRenderInfo ? onRenderInfo : () => <>{infoText}</>,
                }}
            >
                <FontIcon iconName="Info" />
            </TooltipHost>
        ) : (
            <FontIcon iconName="Info" />
        )}
    </Label>
);
