/**
 * Enumerates the supported themes in KustoWeb
 */
export enum Theme {
    Light = 'Light',
    Dark = 'Dark',
}

export type VisualizationType =
    | 'anomalychart'
    | 'areachart'
    | 'barchart'
    | 'columnchart'
    | 'ladderchart'
    | 'linechart'
    | 'piechart'
    | 'pivotchart'
    | 'scatterchart'
    | 'stackedareachart'
    | 'timechart'
    | 'table'
    | 'timeline'
    | 'timepivot'
    | 'card'
    | null;
export type LegendVisibility = 'visible' | 'hidden' | null;
export type Scale = 'linear' | 'log' | null;
export type YSplit = 'none' | 'axes' | 'panels' | null;
export type Kind = 'default' | 'unstacked' | 'stacked' | 'stacked100' | 'map' | null;

export type NumericColumnType = 'int' | 'long' | 'real' | 'decimal';

export type ColumnType = NumericColumnType | 'bool' | 'datetime' | 'dynamic' | 'guid' | 'string' | 'timespan';

export interface VisualizationOptions {
    Visualization: VisualizationType;
    Title: string | null;
    XColumn: string | null;
    Series: string[] | null;
    YColumns: string[] | null;
    XTitle: string | null;
    YTitle: string | null;
    XAxis: Scale;
    YAxis: Scale;
    Legend: LegendVisibility;
    YSplit: YSplit;
    Accumulate: boolean;
    IsQuerySorted: boolean;
    Kind: Kind;
    AnomalyColumns: string[] | null;
    Ymin: number | 'NaN';
    Ymax: number | 'NaN';
}

export interface TaggedKustoValue {
    dataType: ColumnType;
    value: unknown;
}
