import { ColorRulePreSchema } from './preSchema';
import { ITileV1, CanInferV1, IVisualOptionsV1 } from './1';

export interface ColorRuleV2 {
    readonly id: string;
    readonly column: CanInferV1<string>;
    readonly conditions: ColorRulePreSchema.Condition[];
    readonly color: ColorRulePreSchema.Color;
    /**
     * When undefined there is no indicator
     */
    readonly indicator:
        | { readonly kind: 'none' }
        | {
              readonly kind: 'text';
              readonly text: CanInferV1<string>;
          }
        | {
              readonly kind: 'icon';
              readonly icon: ColorRulePreSchema.Icon;
              readonly label: CanInferV1<string>;
          };
}

export interface IVisualOptionsV2 extends Omit<IVisualOptionsV1, 'colorRules'> {
    colorRules: ColorRuleV2[];
}

export interface ITileV2 extends Omit<ITileV1, 'visualOptions' | '$schema'> {
    visualOptions: Partial<IVisualOptionsV2>;
}

function colorRuleMigration(prev: ColorRulePreSchema): ColorRuleV2 {
    const { icon, label, ...shared } = prev;

    const indicator: ColorRuleV2['indicator'] = icon ? { kind: 'icon', icon, label } : { kind: 'text', text: label };

    return { ...shared, indicator };
}

/**
 * Removes $schema and updates color rules type
 */
export function up(prev: ITileV1): ITileV2 {
    const { $schema: _, ...shared } = prev;
    const { colorRules, ...noMigrateVisualOptions } = prev.visualOptions;

    const visualOptions: Partial<IVisualOptionsV2> = noMigrateVisualOptions;

    if (colorRules) {
        visualOptions.colorRules = colorRules.map(colorRuleMigration);
    }

    return { ...shared, visualOptions };
}
