import { parameter } from '../parameter';
import { IParameterV2 } from '../parameter/versions/2';

import { IDashboardV8 } from './8';

export interface IDashboardV9 extends Omit<IDashboardV8, '$schema' | 'parameters'> {
    $schema: '9';
    parameters: IParameterV2[];
}

export function up(prev: IDashboardV8): IDashboardV9 {
    return {
        ...prev,
        $schema: '9',
        parameters: prev.parameters.map(parameter.v2.up),
    };
}
