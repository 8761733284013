// re-use canvas object for better performance
const canvas = document.createElement<'canvas'>('canvas');
const defaultHorizontalWidth = 144;
/**
 * Since Recharts isn't smart enough to do this on its own, we're measuring how much pixels we need for ticks.
 * This function is using the canvas capability to measure text size in pixels.
 * @param text The text to measure width for
 */
export function getTextWidth(text: string, fontSize: number) {
    var context = canvas.getContext('2d');
    const font = `${fontSize}px "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`;
    if (!context) {
        return defaultHorizontalWidth;
    }
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
}

export function hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (_m: string, r: string, g: string, b: string) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : undefined;
}

export function isInteger(value: any) {
    return typeof value === 'number' && value % 1 === 0;
}

export function isFloat(value: any) {
    return typeof value === 'number' && value % 1 !== 0;
}
