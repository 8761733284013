import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import * as React from 'react';
import { assertNever } from '../../common';
import { TileHtmlErrorId, TileMessageProps } from '../../domain';
import { RtdHeader } from '../RtdHeader';

import style from './TileMessage.module.scss';

function getType(level: TileMessageProps['level']) {
    switch (level) {
        case 'warning':
            return MessageBarType.warning;
        case 'error':
            return MessageBarType.error;
        default:
            assertNever(level);
    }
}

export interface InnerTileMessageProps extends TileMessageProps {
    htmlErrorId?: TileHtmlErrorId;
}

export const TileMessage: React.FC<InnerTileMessageProps> = ({ title, message, level, htmlErrorId, children }) => {
    if (title && !message) {
        message = title;
        title = undefined;
    }
    return (
        <MessageBar id={htmlErrorId} className={style.tileMessage} messageBarType={getType(level)} isMultiline>
            {title && <RtdHeader level={3}> {title} </RtdHeader>}
            {message && <p>{message}</p>}
            {children}
        </MessageBar>
    );
};
