import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';

import { err, ok } from '../../../common';
import { APP_STRINGS } from '../../../res';
import type { TimeZone } from '../../timezone';

import { buildValueImpl } from '../valueImpl';

/**
 * ISO 8601 without timezone
 */
const EDIT_STRING_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

/**
 * ISO 8601 with ":" replaced by "-" so url-search encoding doesn't change
 * anything. The "Z" is fine because we're always formatting in UTC.
 */
const URL_STRING_FORMAT = "yyyy-MM-dd'T'HH-mm-ssX";

export const datetimeValueImpl = buildValueImpl<number, 'datetime'>({
    dataType: 'datetime',
    urlStringToValue(value: string) {
        const date = dateFns.parse(value, URL_STRING_FORMAT, new Date()).valueOf();

        if (isNaN(date)) {
            return err(APP_STRINGS.domain.parameter.errors.error);
        }

        return ok(date);
    },
    editStringToValue(value: string, timeZone: TimeZone) {
        const date = dateFnsTz.toDate(value, { timeZone }).valueOf();

        if (isNaN(date)) {
            return err(APP_STRINGS.domain.parameter.errors.failedToParseDatetime);
        }

        return ok(date);
    },
    valueToUrlString(value: number) {
        return dateFnsTz.format(dateFnsTz.utcToZonedTime(value, 'UTC'), URL_STRING_FORMAT, { timeZone: 'UTC' });
    },
    valueToEditString(value: number, timeZone: TimeZone) {
        return dateFns.format(dateFnsTz.utcToZonedTime(value, timeZone), EDIT_STRING_FORMAT);
    },
    valueToDisplayString(value: number, timeZone: TimeZone) {
        return new Date(value).toLocaleString(undefined, { timeZone });
    },
    tryNarrowValue(value) {
        if (value.tag === 'datetime') {
            return value;
        }
        return undefined;
    },
    isValue(value) {
        return typeof value === 'number' && !isNaN(new Date(value).valueOf());
    },
});
