import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react';
import { useState } from 'react';

import styles from './RadioPivot.module.scss';

export interface IRadioPivotItem {
    key: string;
    title: string;
    content: JSX.Element;
}

export interface IRadioPivotProps {
    items: IRadioPivotItem[];
    selectedKey?: string;

    onChange?: (option: IChoiceGroupOption) => void;

    className?: string;
}

export const RadioPivot: React.FC<IRadioPivotProps> = ({ items, selectedKey, onChange, className }) => {
    const [selectedIndex, setSelectedIndex] = useState(selectedKey ? items.findIndex((i) => i.key === selectedKey) : 0);

    return (
        <div className={className}>
            <ChoiceGroup
                className={styles.choiceGroup}
                selectedKey={items.length > 0 ? items[selectedIndex].key : undefined}
                options={items.map((item) => ({
                    key: item.key,
                    text: item.title,
                }))}
                onChange={(_, option) => {
                    if (!option) {
                        return;
                    }

                    setSelectedIndex(items.findIndex((i) => i.key === option.key));

                    if (onChange) {
                        onChange(option);
                    }
                }}
                styles={{
                    flexContainer: {
                        display: 'flex',
                        selectors: {
                            '> :nth-child(n + 2)': {
                                marginLeft: '16px',
                            },
                        },
                    },
                }}
            />
            {items.length > 0 && items[selectedIndex].content}
        </div>
    );
};
