import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { IDropdownOption, Label, TooltipHost } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../../../res';
import { infer } from '../../../../../../domain';
import { compactMap, assertUnreachable, CanInfer } from '../../../../../../common';
import { RTDDropdown, RTDDropdownProps } from '../../../../../../components';

import { useETPDispatch, useETPSelector } from '../../../../../../store/editTile';
import { visualOptionsSelectorBuilder } from '../../../../lib';
import { ETPManagedConfigKeyForType } from '../../../../types';

import { ManagedConfigComponent } from '../../types';
import styles from '../styles.module.scss';

import { ETPColumnOption, columnOptionKey, useSchemaDerived, columnDropdownOption } from './utils';

// TODO: This really should have a divider under it, but it doesn't look like
// RTDDropdown supports that right now
// Somewhat relates to #8092204
const multiSelectInferOption: ETPColumnOption = {
    key: 'infer',
    text: APP_STRINGS.editTilePage.visualConfig.dropdownInputInferOption,
    data: infer,
};

interface MultipleColumnDropdownProps {
    id: string;
    selected: CanInfer<string[]>;
    onChange: RTDDropdownProps['onChange'];
    label: string;
    disabled?: boolean;
    /**
     * Note: A more general version of "addInferChoice" might be "add reset option"
     */
    addInferChoice?: boolean;
    placeholder?: string;
}

const MultipleColumnDropdown: React.FC<MultipleColumnDropdownProps> = ({
    id,
    label,
    disabled,
    selected,
    onChange,
    addInferChoice,
    placeholder,
}) => {
    const schemaDerived = useSchemaDerived();

    const selectedKeys = useMemo(
        () => (selected.type === 'infer' ? ['infer'] : selected.value.map(columnOptionKey)),
        [selected]
    );

    // Code here is a little complex because we need to ensure that selected choices
    // are shown in the dropdown, even if they are no longer available.
    //
    // TODO: Choices that are selected but no longer available should look different
    const options = useMemo((): ETPColumnOption[] => {
        if (selected.type === 'infer') {
            return [multiSelectInferOption, ...schemaDerived.options];
        }
        const selectedValues = selected.value;

        return [
            ...(addInferChoice ? [multiSelectInferOption] : []),
            ...compactMap(selectedValues, (columnName) => {
                if (schemaDerived.optionsKeys.has(columnOptionKey(columnName))) {
                    return undefined;
                }
                return columnDropdownOption(columnName);
            }),
            ...schemaDerived.options,
        ];
    }, [selected, addInferChoice, schemaDerived]);

    const dropdownId = `visual-options--${id}`;

    return (
        <div className={styles.basicInput}>
            <Label htmlFor={dropdownId}>{label}</Label>
            <TooltipHost content={schemaDerived.disabledReason}>
                <RTDDropdown
                    multiSelect={true}
                    id={dropdownId}
                    selectedKeys={selectedKeys}
                    options={options}
                    onChange={onChange}
                    disabled={disabled || schemaDerived.disabledReason !== undefined}
                    placeholder={placeholder}
                />
            </TooltipHost>
        </div>
    );
};

export const createMultipleColumnOption = (
    key: ETPManagedConfigKeyForType<string[]>,
    label: string
): ManagedConfigComponent => {
    const currentValueSelector = visualOptionsSelectorBuilder((s) => ({
        type: 'specified' as const,
        value: s[key],
    }));

    return ({ disabled }) => {
        const [dispatch] = useETPDispatch();
        const value = useETPSelector(currentValueSelector);

        const onChange = useCallback(
            (_: unknown, option?: IDropdownOption) => {
                if (!option) {
                    return;
                }
                if (option.data.type === multiSelectInferOption.data.type) {
                    assertUnreachable('"infer" option type should not be pick-able');
                }
                const column: string = option.data.value;
                const selected = option.selected;
                dispatch((innerDispatch, getState) => {
                    const state = getState();
                    const columns = state.type === 'query' && state.visual?.options[key];
                    if (!columns) {
                        return;
                    }
                    let newColumns: string[] | undefined;
                    if (selected) {
                        if (!columns.includes(column)) {
                            newColumns = [...columns, column];
                        }
                    } else if (columns.includes(column)) {
                        newColumns = columns.filter((c) => c !== column);
                    }
                    if (newColumns) {
                        innerDispatch({
                            type: 'updateVisualOptions',
                            options: { [key]: newColumns },
                        });
                    }
                });
            },
            [dispatch]
        );

        return (
            <MultipleColumnDropdown
                selected={value}
                onChange={onChange}
                disabled={disabled}
                id={key}
                label={label}
                placeholder={APP_STRINGS.domain.parameter.selection.none}
            />
        );
    };
};

export const applyInferableColumnSelectionToState = (
    current: CanInfer<string[]>,
    choice: CanInfer<string>,
    select: boolean
): CanInfer<string[]> | undefined => {
    if (choice.type === 'infer') {
        if (current.type !== 'infer' && select) {
            return infer;
        }
        return;
    }

    if (current.type === 'infer') {
        if (select) {
            return { type: 'specified', value: [choice.value] };
        }
        return;
    }

    const currentColumns = current.value;
    const alreadySelected = currentColumns.includes(choice.value);

    let newColumns: string[];

    if (select) {
        if (alreadySelected) {
            return;
        } else {
            newColumns = [...currentColumns, choice.value];
        }
    } else {
        if (alreadySelected) {
            if (currentColumns.length === 1) {
                return infer;
            }
            newColumns = currentColumns.filter((e) => e !== choice.value);
        } else {
            return;
        }
    }

    return { type: 'specified', value: newColumns };
};

export const createInferableMultipleColumnOption = (
    key: ETPManagedConfigKeyForType<CanInfer<string[]>>,
    label: string
): ManagedConfigComponent => {
    const currentValueSelector = visualOptionsSelectorBuilder((s) => s[key]);

    return ({ disabled }) => {
        const [dispatch] = useETPDispatch();
        const selected = useETPSelector(currentValueSelector);

        const onChange = useCallback(
            (_: unknown, option?: IDropdownOption) => {
                if (option === undefined) {
                    throw new Error();
                }

                dispatch((innerDispatch, getState) => {
                    const state = getState();
                    if (state.type !== 'query') {
                        return;
                    }
                    const visualState = state.visual;
                    if (!visualState) {
                        return;
                    }

                    const newValue = applyInferableColumnSelectionToState(
                        visualState.options[key],
                        option.data,
                        !!option.selected
                    );
                    if (newValue) {
                        innerDispatch({
                            type: 'updateVisualOptions',
                            options: { [key]: newValue },
                        });
                    }
                });
            },
            [dispatch]
        );

        return (
            <MultipleColumnDropdown
                selected={selected}
                onChange={onChange}
                disabled={disabled}
                id={key}
                label={label}
                addInferChoice
            />
        );
    };
};
