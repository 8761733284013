import type { Layout } from 'react-grid-layout';
import { sortLayoutItems } from 'react-grid-layout/build/utils';

import { IPage } from '../core/domain';
import { APP_CONSTANTS } from '../res';
import type { DashboardLayout } from '../store/dashboard';

import type { ITile, VisualOptions } from './tile';
import type { TileSize, VisualConfig } from './visualConfig';

export interface RGLLayout extends Layout {
    i: string;
    minW: number;
    minH: number;
    w: number;
    h: number;
    x: number;
    y: number;
    /**
     * Should be false when minW > w or minH > h
     */
    valid: boolean;
}

/**
 * Reorders the ids of tiles to have consistent left-right tab order
 */
function buildTileTabOrder(layout: RGLLayout[]): RGLLayout[] {
    return layout.slice().sort((a, b) => {
        // Order tiles by y, then x
        // This allows you to tab nicely from left to right, then down a row, through all of the grid
        if (a.y > b.y) {
            return 1;
        } else if (a.y < b.y) {
            return -1;
        } else if (a.x > b.x) {
            return 1;
        } else if (a.x < b.x) {
            return -1;
        } else {
            return 0;
        }
    });
}

export function getMinimumTileSize(
    visualType: string,
    visualConfig: VisualConfig,
    visualOptions: Partial<VisualOptions> = {}
): TileSize {
    if (visualType === 'markdownCard') {
        return APP_CONSTANTS.tile.markdownConfiguration.minimumSize;
    }

    const config = visualConfig.visualTypes[visualType];

    if (config === undefined) {
        return APP_CONSTANTS.tile.unknownTypeMinimumSize;
    }

    const { minimumSize } = config;

    if (typeof minimumSize === 'function') {
        return minimumSize(visualOptions);
    }

    return minimumSize;
}

export function updateLayoutValid(
    layout: RGLLayout,
    visualType: string,
    visualConfig: VisualConfig,
    visualOptions?: Partial<VisualOptions>
): RGLLayout {
    const minSize = getMinimumTileSize(visualType, visualConfig, visualOptions);
    return {
        ...layout,
        minW: minSize.width,
        minH: minSize.height,
        valid: minSize.width <= layout.w && minSize.height <= layout.h,
    };
}

// TODO: Too slow, needs to be incremental
export function updateLayoutWithTiles(
    visualConfig: VisualConfig,
    tiles: readonly ITile[],
    pages: readonly IPage[],
    previousLayout: DashboardLayout = {}
): DashboardLayout {
    const layoutById = new Map(
        Object.entries(previousLayout)
            .map(([pageId, pageLayout]) => pageLayout.map((l) => [l.i, { ...l, pageId }] as const))
            .flat()
    );
    const newLayout: Record<string, RGLLayout[]> = {};

    for (const page of pages) {
        newLayout[page.id] = [];
    }

    for (const tile of tiles) {
        const oldLayout = layoutById.get(tile.id);
        let tileLayout: RGLLayout;
        // If a layout already exists for this tile on this page, only update minW,
        // and minH. As of writing, those are the only things that can change outside
        // of the dashboard page.
        if (oldLayout && oldLayout.pageId === tile.pageId) {
            tileLayout = updateLayoutValid(oldLayout, tile.visualType, visualConfig, tile.visualOptions);
        } else {
            tileLayout = generateLayout(tile, visualConfig);
        }
        const pageLayout = newLayout[tile.pageId];

        if (pageLayout !== undefined) {
            pageLayout.push(tileLayout);
        } else {
            // TODO: After we validate pages on load this should be replaced with an
            // assert.
            // eslint-disable-next-line no-console
            console.error(`Tile ${tile.id} has invalid page id ${tile.pageId}`);
        }
    }

    for (const pageId of Object.keys(newLayout)) {
        newLayout[pageId] = buildTileTabOrder(newLayout[pageId]);
    }

    return newLayout;
}

/**
 * Find open positions to add new tiles, preferring moving left, then down.
 *
 * Basic n^2 algorithm, but likely will not significantly benefit from further optimization
 */
export const findNextOpenTilePosition = (
    pageLayout: readonly RGLLayout[],
    width: number,
    height: number,
    columns: number
): { x: number; y: number } => {
    const sortedLayout = sortLayoutItems(pageLayout, 'vertical');

    let x = 0;
    let y = 0;

    if (sortedLayout.length > 0) {
        const maxLayoutY = sortedLayout[sortedLayout.length - 1].y;
        while (y <= maxLayoutY) {
            // Next layout is on a larger y. Try positioning anywhere on this y
            while (x + width <= columns) {
                const collidingLayout = checkLayoutCollision(sortedLayout, 0, x, y, width, height);

                if (!collidingLayout) {
                    // Fits within bounds
                    return {
                        x,
                        y,
                    };
                }

                // Try after colliding layout
                x = collidingLayout.x + collidingLayout.w;
            }

            // Could not match on this y
            x = 0;
            y += 1;
        }
    }

    // Could not find match. Add to absolute bottom left
    let maxY = 0;
    for (const layout of sortedLayout) {
        const topY = layout.y + layout.h;

        if (topY > maxY) {
            maxY = topY;
        }
    }

    return {
        x: 0,
        // Specifically use the highest position in the grid, rather than Infinity (relying on RGL to reconcile)
        y: maxY,
    };
};

const checkLayoutCollision = (
    layouts: RGLLayout[],
    startIndex: number,
    x: number,
    y: number,
    width: number,
    height: number
): RGLLayout | undefined => {
    const maxX = x + width;
    const maxY = y + height;

    for (let j = startIndex; j < layouts.length; j++) {
        const layout = layouts[j];

        if (layout.y >= maxY || layout.y + layout.h <= y || layout.x >= maxX || layout.x + layout.w <= x) {
            // Outside of check bounds
            continue;
        }

        return layout;
    }

    return undefined;
};

export const generateLayout = (tile: ITile, visualConfig: VisualConfig): RGLLayout => {
    const layout = tile.layout;
    const minSize = getMinimumTileSize(tile.visualType, visualConfig, tile.visualOptions);
    return {
        i: tile.id,
        w: layout.width,
        h: layout.height,
        x: layout.x,
        y: layout.y,
        minW: minSize.width,
        minH: minSize.height,
        valid: minSize.width <= layout.width && minSize.height <= layout.height,
    };
};

const calculateTileYPixel = (tile: ITile | undefined): number =>
    (tile?.layout.y ?? 0) * (APP_CONSTANTS.dashboardPage.grid.rowHeight + APP_CONSTANTS.dashboardPage.grid.tilePadding);

export const calculateTileYScrollPosition = (tile: ITile | undefined, maxHeight: number): number => {
    const yPosition = calculateTileYPixel(tile);
    const discreteYPosition = yPosition !== Infinity ? yPosition : maxHeight;
    // Scroll to position - 100 (arbitrary) to make scrolling less robotic and make it clear that there is content above
    // the selected tile
    return Math.max(0, discreteYPosition - 100);
};
