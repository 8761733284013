import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';

export interface ErrorSnackbarProps {
    id?: string;
    message: string;
    level?: MessageBarType;
}

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({ id, message, level }) => {
    return (
        <MessageBar
            id={id}
            className="error-snackbar"
            styles={{
                root: {
                    minHeight: 48,
                    maxHeight: 144,
                    overflow: 'auto',
                    whiteSpace: 'pre-wrap',
                },
            }}
            messageBarType={level || MessageBarType.error}
        >
            {message}
        </MessageBar>
    );
};
