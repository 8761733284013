import * as React from 'react';

import { CustomListDropdown, CustomListDropdownProps } from './components/customListDropdown/CustomListDropdown';
import { SearchDropdownMenu } from './components/searchDropdownMenu/SearchDropdownMenu';
import { CustomListDropdownMenuProps } from './components/customListDropdown/CustomListDropdownMenu';

export const SearchDropdown: React.FC<CustomListDropdownProps> = ({ onRenderHeader, ...props }) => (
    <CustomListDropdown {...props} onRenderHeaderPrefix={onRenderHeader} onRenderMenu={onRenderMenu} />
);
const onRenderMenu = (innerProps: CustomListDropdownMenuProps) => <SearchDropdownMenu {...innerProps} />;
