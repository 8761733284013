import { Err, err, ok } from '../../../common';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';

import { buildValueImpl } from '../valueImpl';

export function isStringRestricted(str: string): undefined | Err {
    if (str === APP_CONSTANTS.parameter.allSelection) {
        return err(APP_STRINGS.domain.parameter.errors.allReserved);
    }

    if (str === APP_CONSTANTS.addSelection || str === APP_CONSTANTS.emptySelection) {
        return err(`"${str}" ${APP_STRINGS.domain.parameter.errors.restrictedString}`);
    }
    return undefined;
}

export const stringValueImpl = buildValueImpl<string, 'string'>({
    dataType: 'string',
    urlStringToValue(selection: string) {
        const restrictedErr = isStringRestricted(selection);
        if (restrictedErr) {
            return restrictedErr;
        }

        return ok(selection);
    },
    valueToUrlString(value: string) {
        return value;
    },
    valueToDisplayString(value: string) {
        return value;
    },
    tryNarrowValue(value) {
        if (value.tag === 'string') {
            return value;
        }
        return undefined;
    },
    isValue(value) {
        return typeof value === 'string' && !isStringRestricted(value);
    },
});
