import { assertNever } from 'office-ui-fabric-react';

import {
    DurationParamConfig,
    ParameterConfig,
    ValueImpl,
    RtdValue,
    BasicParam,
    valueImplFor,
    rtdNullValue,
    tagScalar,
    BasicParamConfig,
    isValidKustoVariableName,
    TimeZone,
    tagArray,
} from '../../../domain';
import { APP_STRINGS } from '../../../res';

import type {
    EppErrorState,
    EppParameterError,
    EppReducerState,
    EppBasicParamOptions,
    EppStaticOption,
    EppQueryDropdownOptions,
} from './reducer';

/**
 * Each array item maps to the user input of the same index
 */
export interface DropdownOptionsErrors {
    options: Array<undefined | string>;
    defaultValue: undefined | string;
}

export type DropdownRawOptions = Array<{ value: string; displayText?: string }>;

export interface TryDropdownOptions {
    selectionKind: BasicParam.DropdownSelectionKind;
    variableName: string;
    timeZone: TimeZone;
}

interface TryDropdownOptionsResult<T, K> {
    options: BasicParam.DropdownOptions<T, K>;
    errors: Map<string, string>;
}

function innerTryStaticDropdownConfigFromUser<T, K>(
    input: ReadonlyMap<string, EppStaticOption>,
    impl: ValueImpl<T, K>,
    defaultValueIds: undefined | null | ReadonlySet<string>,
    { selectionKind, variableName, timeZone }: TryDropdownOptions
): TryDropdownOptionsResult<T, K> {
    const errors = new Map<string, string>();
    const seenValues = new Set<T>();
    const valuesById = new Map<string, T>();
    const dataSourceValues: Array<{ value: T; displayText: undefined | string }> = [];

    for (const val of input.values()) {
        const res = impl.editStringToValue(val.value, timeZone);

        if (res.kind === 'err') {
            errors.set(val.id, res.err);
        } else if (seenValues.has(res.value)) {
            errors.set(val.id, APP_STRINGS.editParameterPage.errors.duplicateValue);
        } else {
            seenValues.add(res.value);
            valuesById.set(val.id, res.value);
            dataSourceValues.push({ displayText: val.displayText, value: res.value });
        }
    }

    let defaultValue: undefined | RtdValue.Value<T, K>;

    if (defaultValueIds === undefined) {
        defaultValue = undefined;
    } else if (defaultValueIds === null) {
        defaultValue = rtdNullValue;
    } else {
        const values: T[] = [];
        for (const id of defaultValueIds) {
            const val = valuesById.get(id);
            if (val !== undefined) {
                values.push(val);
            }
        }
        if (values.length === 0) {
            defaultValue = undefined;
        } else if (selectionKind === 'array-null') {
            defaultValue = tagArray(impl.dataType, values);
        } else {
            defaultValue = tagScalar(impl.dataType, values[0]);
        }
    }

    return {
        options: {
            defaultValue,
            selectionKind,
            variableName,
            dataSource: { kind: 'static', values: dataSourceValues },
        },
        errors,
    };
}

function validateVariableNames(
    variableNames: string[],
    existingParameterVariableNames: Set<string>
): Array<string | undefined> | undefined {
    const variableNameErrors = new Array(variableNames.length);

    const usedVariableNames = new Set<string>();

    let didVariableNameError = false;
    for (let i = 0; i < variableNames.length; i++) {
        const variableName = variableNames[i];
        const trimmedVariableName = variableName.trim();

        if (!trimmedVariableName) {
            variableNameErrors[i] = APP_STRINGS.editParameterPage.errors.noVariableName;
            didVariableNameError = true;
        } else if (
            existingParameterVariableNames.has(trimmedVariableName) ||
            usedVariableNames.has(trimmedVariableName)
        ) {
            variableNameErrors[i] = APP_STRINGS.editParameterPage.errors.duplicateVariableName;
            didVariableNameError = true;
        } else if (!isValidKustoVariableName(trimmedVariableName)) {
            variableNameErrors[i] = APP_STRINGS.editParameterPage.errors.invalidCharacterVariableName;
            didVariableNameError = true;
        }

        usedVariableNames.add(trimmedVariableName);
    }

    return didVariableNameError ? variableNameErrors : undefined;
}

function buildFreeTextParam<T, K>(
    impl: ValueImpl<T, K>,
    rawDefaultValue: string,
    variableName: string,
    timeZone: TimeZone
): {
    userOptions: BasicParam.FreetextOptions<T, K>;
    defaultValueError: undefined | string;
} {
    let defaultValueError: undefined | string;
    let defaultValue: undefined | RtdValue.Value<T, K>;

    const trimmed = rawDefaultValue.trim();

    if (trimmed === '') {
        defaultValue = rtdNullValue;
    } else {
        const maybeValue = impl.editStringToValue(rawDefaultValue, timeZone);
        if (maybeValue.kind === 'err') {
            defaultValueError = maybeValue.err;
            defaultValue = undefined;
        } else {
            defaultValue = tagScalar(impl.dataType, maybeValue.value);
        }
    }

    const userOptions: BasicParam.FreetextOptions<T, K> = {
        selectionKind: 'freetext',
        variableName,
        defaultValue,
    };

    return { userOptions, defaultValueError };
}

function determineActiveError(errors: EppErrorState): EppParameterError | undefined {
    const { queryDataSourceErrors, ...standardErrors } = errors;
    if (Object.values(standardErrors).some((value) => value !== undefined)) {
        return { kind: 'error' };
    } else if (queryDataSourceErrors) {
        return queryDataSourceErrors;
    } else {
        return undefined;
    }
}

export function tryBuildQueryDataSource(options: Omit<EppQueryDropdownOptions, 'dataSource'>): {
    dataSource: BasicParam.QueryDataSource;
    errors: EppErrorState['queryDataSourceErrors'];
} {
    let errors: undefined | EppErrorState['queryDataSourceErrors'];

    if (options.dataSourceId === undefined || options.query === undefined || options.valueColumn === undefined) {
        errors = { kind: 'error' };
    } else if (options.valueColumnWarning || options.labelColumnWarning || !options.queryDidComplete) {
        errors = {
            kind: 'warning',
            valueColumnMissing: options.valueColumnWarning !== undefined,
            labelColumnMissing: options.labelColumnWarning !== undefined,
            incompleteQuery: !options.queryDidComplete,
        };
    }

    const dataSource: BasicParam.QueryDataSource = {
        kind: 'query',
        consumedVariables: options.consumedVariables,
        query: options.query ?? '',
        dataSourceId: options.dataSourceId,
        columns: {
            value: options.valueColumn,
            label: options.labelColumn,
        },
    };

    return { errors, dataSource };
}

/**
 * @param errors Mutate these directly
 */
export function tryBuildBasicParamConfig<T, K extends string>(
    errors: EppErrorState,
    state: EppReducerState,
    basicState: EppBasicParamOptions,
    impl: ValueImpl<T, K>
): BasicParamConfig<T, K> {
    let userOptions: undefined | BasicParam.Options<T, K>;
    const basicConfig = basicState.union;
    switch (basicConfig.kind) {
        case 'freetext':
            {
                const result = buildFreeTextParam<T, K>(
                    impl,
                    basicConfig.defaultValueEnteredText,
                    state.variableNames[0],
                    state.timeZone
                );

                errors.defaultValueError = result.defaultValueError;
                userOptions = result.userOptions;
            }
            break;
        case 'query':
            const dataSource = tryBuildQueryDataSource(basicConfig);
            errors.queryDataSourceErrors = dataSource.errors;

            userOptions = {
                selectionKind: basicConfig.selectionKind,
                variableName: state.variableNames[0],
                defaultValue:
                    basicConfig.defaultValue &&
                    (basicConfig.defaultValue.kind === 'null'
                        ? basicConfig.defaultValue
                        : impl.tryNarrowValue(basicConfig.defaultValue)),
                dataSource: dataSource.dataSource,
            };
            break;
        case 'static': {
            const maybeOptions = innerTryStaticDropdownConfigFromUser<T, K>(
                basicConfig.options,
                impl,
                basicConfig.defaultValue,
                {
                    selectionKind: basicConfig.selectionKind,
                    variableName: state.variableNames[0],
                    timeZone: state.timeZone,
                }
            );
            userOptions = maybeOptions.options;
            if ([...maybeOptions.errors.values()].some((v) => v !== undefined)) {
                errors.optionsErrors = maybeOptions.errors;
            }
            break;
        }
        default:
            assertNever(basicConfig);
    }
    return (
        userOptions &&
        new BasicParamConfig<T, K>(state.id, state.displayName || userOptions.variableName, userOptions, impl)
    );
}

export function tryBuildParameter(
    state: EppReducerState
): Pick<EppReducerState, 'parameter' | 'errors' | 'activeError'> {
    const errors: EppErrorState = {
        variableNameErrors: validateVariableNames(state.variableNames, state.existingParameterVariableNames),

        displayNameError: state.existingParameterDisplayNames.has(state.displayName.trim())
            ? APP_STRINGS.editParameterPage.errors.duplicateDisplayName
            : undefined,
    };

    let parameter: undefined | ParameterConfig;

    if (state.union.kind === 'duration') {
        parameter = new DurationParamConfig(
            state.id,
            state.variableNames[0],
            state.variableNames[1],
            state.displayName || state.variableNames[0],
            tagScalar('duration', state.union.defaultValue)
        );
    } else {
        const impl = valueImplFor(state.union.dataType);
        switch (impl.dataType) {
            case 'bool':
                parameter = tryBuildBasicParamConfig(errors, state, state.union, impl);
                break;
            case 'datetime':
                parameter = tryBuildBasicParamConfig(errors, state, state.union, impl);
                break;
            case 'decimal':
                parameter = tryBuildBasicParamConfig(errors, state, state.union, impl);
                break;
            case 'float64':
                parameter = tryBuildBasicParamConfig(errors, state, state.union, impl);
                break;
            case 'int32':
                parameter = tryBuildBasicParamConfig(errors, state, state.union, impl);
                break;
            case 'int64':
                parameter = tryBuildBasicParamConfig(errors, state, state.union, impl);
                break;
            case 'string':
                parameter = tryBuildBasicParamConfig(errors, state, state.union, impl);
                break;
            default:
                assertNever(impl);
        }

        if (parameter.defaultValue.data === undefined) {
            errors.defaultValueError = APP_STRINGS.editParameterPage.errors.noDefaultValue;
        }
    }

    return { parameter, activeError: determineActiveError(errors), errors };
}

export function tryApplyBuildParameter(state: EppReducerState): EppReducerState {
    return { ...state, ...tryBuildParameter(state) };
}
