import { assertNever } from 'office-ui-fabric-react';

import { assertUnreachable, ok, Result } from '../../../common';
import {
    RtdValue,
    ParameterConfig,
    BasicParam,
    UBasicParamConfig,
    BasicParamConfig,
    TDuration,
    TimeZone,
    arrValueAsGeneric,
    scalarValueAsGeneric,
} from '../../../domain';

import {
    convertQueryDefaultValueToSelectionType,
    eppSetBasicDataType,
    setParameterType,
} from './reducerSetSelectionType';
import { DropdownOptionsErrors, tryApplyBuildParameter, tryBuildQueryDataSource } from './tryBuildParameter';
import { initialStateStaticDropdownOptions } from './constants';
import { staticOption } from './util';

export interface EppDurationOptions {
    kind: 'duration';
    defaultValue: TDuration;
}

export type EppParameterType = 'scalar' | 'array-null' | 'freetext' | 'duration';

export type ParameterType = EppUnionOptions['kind'];

export interface EppStaticOption {
    /** Only exists on the edit paramter page */
    id: string;
    value: string;
    displayText: undefined | string;
}

export interface EppStaticDropdownOptions {
    kind: 'static';
    options: ReadonlyMap<string, EppStaticOption>;
    /**
     * undefined: not selected
     * null: null/all selected
     * Set<string>: ids from static options
     */
    defaultValue: undefined | null | ReadonlySet<string>;
    parsedConfig: Result<UBasicParamConfig['options'], DropdownOptionsErrors>;
    selectionKind: BasicParam.DropdownSelectionKind;
}

export interface EppQueryDropdownOptions {
    kind: 'query';

    dataSource: BasicParam.QueryDataSource;

    // User Config
    query: undefined | string;
    defaultValue?: RtdValue.UBasic;
    dataSourceId?: string;
    valueColumn?: string;
    labelColumn?: string;
    consumedVariables: Set<string>;
    valueColumnWarning?: string;
    labelColumnWarning?: string;
    queryDidComplete: boolean;
    selectionKind: BasicParam.DropdownSelectionKind;
}

export interface EppFreeTextOptions {
    kind: 'freetext';
    defaultValueEnteredText: string;
}

export type EppUnionOptions = EppDurationOptions | EppBasicParamOptions;

export interface EppBasicParamOptions {
    kind: 'basic';
    dataType: RtdValue.BasicType;
    union: EppStaticDropdownOptions | EppQueryDropdownOptions | EppFreeTextOptions;
}

export type EppParameterError =
    | { kind: 'error' }
    | {
          kind: 'warning';
          valueColumnMissing: boolean;
          labelColumnMissing: boolean;
          incompleteQuery: boolean;
      };

export interface EppErrorState {
    displayNameError?: string;
    defaultValueError?: string;
    variableNameErrors?: Array<string | undefined>;
    optionsErrors?: Map<string, string>;
    queryDataSourceErrors?: EppParameterError;
}

export interface EppReducerState {
    // state  and user input
    id: string;
    shouldPin: boolean;
    variableNames: string[];
    displayName: string;
    union: EppUnionOptions;

    // metadata
    existingParameterDisplayNames: Set<string>;
    existingParameterVariableNames: Set<string>;
    timeZone: TimeZone;

    // Results
    parameter?: ParameterConfig;
    activeError?: EppParameterError;
    errors: EppErrorState;
}

export type EppAction =
    | {
          type: 'initializeIncomingParameter';
          parameter: ParameterConfig;
          isPinned: boolean;
      }
    | { type: 'setTimeZone'; timeZone: TimeZone }
    | { type: 'setExistingParameters'; parameters: readonly ParameterConfig[] }
    | { type: 'shouldPin'; shouldPin: boolean }
    | { type: 'setParameterType'; paramType: EppParameterType }
    | { type: 'singleSelectAllowSelectAll'; allowAllSelection: boolean }
    | { type: 'setBasicDataType'; dataType: RtdValue.BasicType }
    | { type: 'setDisplayName'; displayName: string }
    | { type: 'setQueryDropdownDefaultValue'; value: RtdValue.UBasic }
    | { type: 'selectStaticDropdownDefaultValueOption'; selected: null | string }
    | { type: 'setDurationDefaultValue'; value: TDuration }
    | { type: 'setFreeTextDefaultValue'; text: string }
    | { type: 'setVariableName'; index: number; name: string }
    | { type: 'addDataSourceOption' }
    | { type: 'removeStaticDropdownOption'; key: string }
    | {
          type: 'updateDropdownStaticOption';
          value: EppStaticOption;
      }
    | { type: 'changeToStaticOptions' }
    | { type: 'changeToQueryOptions'; defaultDataSourceId: string | undefined }
    | {
          type: 'updateQueryDataSource';
          changes: Partial<EppQueryDropdownOptions>;
      };

function createInitialStaticDropdownOptions<T>(
    config: BasicParamConfig<T, string>,
    timeZone: TimeZone
): Map<string, EppStaticOption> {
    if (config.options.selectionKind === 'freetext' || config.options.dataSource.kind !== 'static') {
        // Should be unreachable;
        return assertUnreachable('Only accepts static dropdown parameter configs');
    }
    return new Map(
        config.options.dataSource.values.map((value): [string, EppStaticOption] => {
            const option = staticOption(config.impl.valueToEditString(value.value, timeZone), value.displayText);
            return [option.id, option];
        })
    );
}

export const editParameterPageReducerFunc = (state: EppReducerState, action: EppAction): EppReducerState => {
    switch (action.type) {
        case 'initializeIncomingParameter': {
            const parameter = action.parameter;

            const variableNames = parameter.variableNames;

            let union: EppUnionOptions;

            if (parameter.kind === 'duration') {
                union = {
                    kind: 'duration',
                    defaultValue: parameter.defaultValue.data.value,
                };
            } else if (parameter.options.selectionKind === 'freetext') {
                let defaultValueEnteredText: string;

                // `'array'` and `undefined` _should_ be impossible to reach
                // if the dashboard was created with the client. Could be
                // created with import
                switch (parameter.defaultValue.data?.kind) {
                    case undefined:
                    case 'null':
                        defaultValueEnteredText = '';
                        break;
                    case 'scalar':
                        defaultValueEnteredText = scalarValueAsGeneric(parameter.defaultValue.data, (value, impl) =>
                            impl.valueToEditString(value.value, state.timeZone)
                        );
                        break;
                    case 'array':
                        defaultValueEnteredText = arrValueAsGeneric(parameter.defaultValue.data, (value, impl) =>
                            impl.valueToEditString(value.values[0], state.timeZone)
                        );
                        break;
                }
                union = {
                    kind: 'basic',
                    dataType: parameter.dataType,
                    union: {
                        kind: 'freetext',
                        defaultValueEnteredText,
                    },
                };
            } else if (parameter.options.dataSource.kind === 'static') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const options = createInitialStaticDropdownOptions<any>(parameter, state.timeZone);

                function getEditStringId(editStr: string): string {
                    for (const option of options.values()) {
                        if (option.value === editStr) {
                            return option.id;
                        }
                    }
                    // If the default option isn't in the options list, add it.
                    // If this code path ends up being taken frequently we could
                    // change the default values to represent unavailable values
                    // differently.
                    const option = staticOption(editStr);
                    options.set(option.id, option);
                    return option.id;
                }

                let defaultValue: EppStaticDropdownOptions['defaultValue'];

                switch (parameter.options.defaultValue?.kind) {
                    case undefined:
                        defaultValue = undefined;
                        break;
                    case 'array':
                        defaultValue = new Set(
                            arrValueAsGeneric(parameter.options.defaultValue, (value, impl) =>
                                value.values.map((v) => getEditStringId(impl.valueToEditString(v, state.timeZone)))
                            )
                        );
                        break;
                    case 'scalar':
                        defaultValue = new Set(
                            scalarValueAsGeneric(parameter.options.defaultValue, (value, impl) =>
                                getEditStringId(impl.valueToEditString(value.value, state.timeZone))
                            )
                        );
                        break;
                    case 'null':
                        defaultValue = null;
                        break;
                    default:
                        assertNever(parameter.options.defaultValue);
                }

                union = {
                    kind: 'basic',
                    dataType: parameter.dataType,
                    union: {
                        kind: 'static',
                        options,
                        defaultValue,
                        selectionKind: parameter.options.selectionKind,
                        parsedConfig: ok(parameter.options),
                    },
                };
            } else {
                const dataSource = parameter.options.dataSource;
                union = {
                    kind: 'basic',
                    dataType: parameter.dataType,
                    union: {
                        kind: 'query',
                        dataSource,
                        defaultValue: parameter.options.defaultValue,
                        query: dataSource.query,
                        dataSourceId: dataSource.dataSourceId,
                        valueColumn: dataSource.columns?.value,
                        labelColumn: dataSource.columns?.value,
                        queryDidComplete: false,
                        selectionKind: parameter.options.selectionKind,
                        consumedVariables: dataSource.consumedVariables,
                    },
                };
            }

            return tryApplyBuildParameter({
                ...state,
                id: parameter.id,
                shouldPin: action.isPinned,
                displayName: parameter.displayName,
                variableNames,
                union: union,
            });
        }
        case 'setTimeZone':
            return {
                ...state,
                timeZone: action.timeZone,
            };
        case 'setExistingParameters': {
            const existingParameterDisplayNames = new Set<string>();
            const existingParameterVariableNames = new Set<string>();

            for (const parameter of action.parameters) {
                if (parameter.id === state.id) {
                    // Ignore the current parameter
                    continue;
                }

                existingParameterDisplayNames.add(parameter.displayName);

                const variables = parameter.variableNames;

                for (const variableName of variables) {
                    existingParameterVariableNames.add(variableName);
                }
            }

            return tryApplyBuildParameter({
                ...state,
                existingParameterDisplayNames,
                existingParameterVariableNames,
            });
        }
        case 'shouldPin':
            return { ...state, shouldPin: action.shouldPin };
        case 'setParameterType': {
            const union = setParameterType(action.paramType, state);
            if (union !== state.union) {
                let variableNames: string[];
                if (union.kind === 'duration') {
                    variableNames = [state.variableNames[0], ''];
                } else if (state.union.kind === 'duration') {
                    variableNames = state.variableNames.slice(0, 1);
                } else {
                    variableNames = state.variableNames;
                }
                return tryApplyBuildParameter({ ...state, variableNames, union });
            }
            return state;
        }
        case 'setBasicDataType': {
            if (state.union.kind === 'duration' || action.dataType === state.union.dataType) {
                // Short circuit
                return state;
            }

            return tryApplyBuildParameter({
                ...state,
                union: eppSetBasicDataType(action.dataType, state.union),
            });
        }
        case 'singleSelectAllowSelectAll': {
            const selectionKind = action.allowAllSelection ? 'scalar-null' : ('scalar' as const);
            if (
                state.union.kind === 'duration' ||
                state.union.union.kind === 'freetext' ||
                state.union.union.selectionKind === 'array-null' ||
                state.union.union.selectionKind === selectionKind
            ) {
                return state;
            }

            if (state.union.union.kind === 'query') {
                return tryApplyBuildParameter({
                    ...state,
                    union: {
                        ...state.union,
                        union: {
                            ...state.union.union,
                            selectionKind,
                            defaultValue: convertQueryDefaultValueToSelectionType(
                                selectionKind,
                                state.union.union.defaultValue
                            ),
                        },
                    },
                });
            }

            let defaultValue = state.union.union.defaultValue;

            if (defaultValue === null && selectionKind === 'scalar') {
                defaultValue = undefined;
            }

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...state.union.union,
                        selectionKind,
                        defaultValue,
                    },
                },
            });
        }
        case 'setDisplayName': {
            return tryApplyBuildParameter({
                ...state,
                displayName: action.displayName,
            });
        }
        case 'setQueryDropdownDefaultValue': {
            if (state.union.kind === 'duration' || state.union.union.kind !== 'query') {
                return state;
            }

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...state.union.union,
                        defaultValue: action.value,
                    },
                },
            });
        }
        case 'selectStaticDropdownDefaultValueOption': {
            if (state.union.kind === 'duration' || state.union.union.kind !== 'static') {
                return state;
            }

            let defaultValue: null | Set<string>;

            if (action.selected === null) {
                if (state.union.union.selectionKind === 'scalar') {
                    return state;
                }
                defaultValue = null;
            } else if (state.union.union.selectionKind === 'array-null') {
                defaultValue = new Set(state.union.union.defaultValue);
                if (defaultValue.has(action.selected)) {
                    defaultValue.delete(action.selected);
                } else {
                    defaultValue.add(action.selected);
                }
            } else {
                defaultValue = new Set([action.selected]);
            }

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...state.union.union,
                        defaultValue,
                    },
                },
            });
        }
        case 'setDurationDefaultValue':
            if (state.union.kind !== 'duration') {
                return state;
            }
            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    defaultValue: action.value,
                },
            });
        case 'setFreeTextDefaultValue': {
            if (state.union.kind === 'duration' || state.union.union.kind !== 'freetext') {
                return state;
            }

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...state.union.union,
                        defaultValueEnteredText: action.text,
                    },
                },
            });
        }
        case 'setVariableName': {
            const variableNames = [...state.variableNames];
            // JavaScript allows setting indexes out of range, so there should be no danger here
            variableNames[action.index] = action.name;

            return tryApplyBuildParameter({
                ...state,
                variableNames,
            });
        }
        case 'addDataSourceOption': {
            if (state.union.kind === 'duration' || state.union.union.kind !== 'static') {
                return state;
            }

            const options = new Map(state.union.union.options);
            const newOption = staticOption('');
            options.set(newOption.id, newOption);

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...state.union.union,
                        options,
                    },
                },
            });
        }
        case 'removeStaticDropdownOption': {
            if (
                state.union.kind === 'duration' ||
                state.union.union.kind !== 'static' ||
                !state.union.union.options.has(action.key)
            ) {
                return state;
            }

            const options = new Map(state.union.union.options);
            options.delete(action.key);

            // Always have at least 1 option
            if (options.size === 0) {
                const option = staticOption('');
                options.set(option.id, option);
            }

            let defaultValue = state.union.union.defaultValue;

            if (defaultValue && defaultValue.has(action.key)) {
                if (defaultValue.size === 1) {
                    defaultValue = undefined;
                } else {
                    const newValue = new Set(defaultValue);
                    newValue.delete(action.key);
                    defaultValue = newValue;
                }
            }

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...state.union.union,
                        options,
                        defaultValue,
                    },
                },
            });
        }

        case 'updateDropdownStaticOption': {
            if (state.union.kind === 'duration' || state.union.union.kind !== 'static') {
                return state;
            }

            const options = new Map(state.union.union.options);
            if (!options.has(action.value.id)) {
                return state;
            }

            options.set(action.value.id, action.value);

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...state.union.union,
                        options,
                    },
                },
            });
        }

        case 'changeToStaticOptions':
            if (state.union.kind === 'duration' || state.union.union.kind !== 'query') {
                return state;
            }

            return tryApplyBuildParameter({
                ...state,
                union: {
                    ...state.union,
                    union: {
                        ...initialStateStaticDropdownOptions,
                        selectionKind: state.union.union.selectionKind,
                    },
                },
            });

        case 'changeToQueryOptions': {
            if (state.union.kind !== 'basic' || state.union.union.kind !== 'static') {
                return state;
            }

            const union: Omit<EppQueryDropdownOptions, 'dataSource'> = {
                kind: 'query',
                query: undefined,
                defaultValue: undefined,
                dataSourceId: action.defaultDataSourceId,
                valueColumn: undefined,
                labelColumn: undefined,
                consumedVariables: new Set(),
                queryDidComplete: false,
                selectionKind: state.union.union.selectionKind,
            };
            const dataSource = tryBuildQueryDataSource(union).dataSource;

            return tryApplyBuildParameter({
                ...state,
                union: { ...state.union, union: { ...union, dataSource } },
            });
        }
        case 'updateQueryDataSource': {
            if (state.union.kind !== 'basic' || state.union.union.kind !== 'query') {
                return state;
            }

            const lastQueryConfig = state.union.union;

            const queryConfig: EppQueryDropdownOptions = {
                ...lastQueryConfig,
                ...action.changes,
                // Reset errors if the column was changed
                // Pass through new error if it exists
                valueColumnWarning:
                    'valueColumnWarning' in action.changes
                        ? action.changes.valueColumnWarning
                        : // If changes doesn't have a column, or the column is identical to the one already set, return the previous error
                        // We check if the key exists, rather than falsy, as the column can be set to `undefined` to infer value for the label column
                        !('valueColumn' in action.changes) || action.changes.valueColumn === lastQueryConfig.valueColumn
                        ? lastQueryConfig.valueColumnWarning
                        : undefined,
                labelColumnWarning:
                    'labelColumnWarning' in action.changes
                        ? action.changes.labelColumnWarning
                        : !('labelColumn' in action.changes) ||
                          action.changes.labelColumn === lastQueryConfig.labelColumn
                        ? lastQueryConfig.labelColumnWarning
                        : undefined,
            };

            // If currently on single, and there are some activeParentParameters, force upgrade to single-all
            queryConfig.selectionKind =
                queryConfig.selectionKind === 'scalar' && queryConfig.consumedVariables.size > 0
                    ? 'scalar-null'
                    : queryConfig.selectionKind;

            return tryApplyBuildParameter({
                ...state,
                union: { ...state.union, union: queryConfig },
            });
        }
    }
};
