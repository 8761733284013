import { sleep } from '../../common';

import { databaseVersion } from './constants';

interface RtdWindow extends Omit<Window, 'BroadcastChannel'> {
    // Not available on safari
    BroadcastChannel?: BroadcastChannel;
}

declare const window: RtdWindow;

function tryMakeGetVersionsFunc(): () => Promise<Set<number>> {
    // FIXME: If we end up with a lot of safari power users then this might be worth
    // fixing. Possible solution: https://github.com/pubkey/broadcast-channel
    if (window.BroadcastChannel === undefined) {
        return () => Promise.resolve(new Set<number>());
    }

    const requestChannel = new BroadcastChannel('request-channel');
    const versionsChannel = new BroadcastChannel('versions-channel');

    requestChannel.onmessage = () => versionsChannel.postMessage(databaseVersion);

    return async function getOpenIndexedDBVersions(): Promise<Set<number>> {
        if (versionsChannel.onmessage !== null) {
            throw new Error('Simultaneous getOpenIndexedDBVersions function calls not supported');
        }

        const versions = new Set<number>();

        versionsChannel.onmessage = (event: MessageEvent<number>) => {
            versions.add(event.data);
        };

        requestChannel.postMessage(null);

        await sleep(1000);

        versionsChannel.onmessage = null;

        return versions;
    };
}

/**
 * Best effort only, this cannot be relied upon to be exhaustive.
 * See [./initIndexedDB.ts](./initIndexedDB.ts) for more information
 */
export const getOpenIndexedDBVersions = tryMakeGetVersionsFunc();
