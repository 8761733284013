import * as React from 'react';
import { useCallback } from 'react';
import { PivotItem, Pivot, CommandBarButton, MessageBar } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../res';
import { useQueryResult, StatusIndicators } from '../../../../components/QueryEditing';

import { ETPState, ETPAction, useETPSelector, useETPSelectors, useETPDispatch } from '../../../../store/editTile';
import { IParameterSelections } from '../../../../store';
import { useFlush } from '../../lib';

import styles from './QueryPane.module.scss';

import { Visual } from './Query';
import { TilePreviewExpandedToggle } from './ExpandedToggle';

const queryNotUpdatedSelectors = {
    renderedQuery: (s: ETPState) => (s.type === 'query' ? s.renderedQuery : undefined),
    parameterSelections: (s: ETPState) => (s.type === 'query' ? s.parameterSelections : undefined),
    dataSourceId: (s: ETPState) => (s.type === 'query' ? s.dataSourceId : undefined),
};

const QueryNotUpdatedMessages: React.FC = () => {
    const { renderedQuery, parameterSelections, dataSourceId } = useETPSelectors(queryNotUpdatedSelectors);

    if (!renderedQuery) {
        return null;
    }

    const messages = [];
    if (parameterSelections && parameterSelections !== renderedQuery.parameterSelections) {
        messages.push({
            key: 'parameterNotApplied',
            message: APP_STRINGS.editTilePage.parameterNotApplied,
        });
    }
    if (dataSourceId && dataSourceId !== renderedQuery.dataSourceId) {
        messages.push({
            key: 'dataSourceNotApplied',
            message: APP_STRINGS.editTilePage.dataSourceNotApplied,
        });
    }
    if (messages.length === 0) {
        return null;
    }
    return (
        <div className={styles.inlineMessage}>
            {messages.map(({ key, message }) => (
                <MessageBar key={key}>{message}</MessageBar>
            ))}
        </div>
    );
};

const addVisualAction: ETPAction = { type: 'addVisual' };

const addVisualIconProps = { iconName: 'Add' };

const addVisualButtonStatusSelector = (s: ETPState): 'hide' | 'enabled' | 'disabled' => {
    if (s.type !== 'query' || s.visual) {
        return 'hide';
    }
    if (!s.renderedQuery) {
        return 'disabled';
    }
    return 'enabled';
};

const AddVisualButton: React.FC = () => {
    const buttonStatus = useETPSelector(addVisualButtonStatusSelector);
    const [dispatch] = useETPDispatch();

    const onAddVisual = useCallback(() => dispatch(addVisualAction), [dispatch]);
    if (buttonStatus === 'hide') {
        return null;
    }

    return (
        <CommandBarButton disabled={buttonStatus === 'disabled'} iconProps={addVisualIconProps} onClick={onAddVisual}>
            {APP_STRINGS.editTilePage.addVisual}
        </CommandBarButton>
    );
};

const runQueryAction: ETPAction = { type: 'runQuery' };

const queryTileSelectors = {
    hasVisualOptions: (s: ETPState) => s.type === 'query' && s.visual !== undefined,
    selectedKey: (s: ETPState) => (s.type === 'query' ? s.visualPane : null),
};

export interface QueryPaneProps {
    tileId?: string;
    crossFilterTarget: undefined | IParameterSelections;
}

export const QueryPane: React.FC<QueryPaneProps> = ({ tileId, crossFilterTarget }) => {
    const [dispatch] = useETPDispatch();
    const [queryStatus, query] = useQueryResult();
    const { hasVisualOptions, selectedKey } = useETPSelectors(queryTileSelectors);
    const flush = useFlush();

    const { forceReRun, onLinkClick } = React.useMemo(
        () => ({
            async forceReRun() {
                await flush();
                dispatch(runQueryAction);
            },
            onLinkClick(item: PivotItem | undefined) {
                if (item) {
                    dispatch({
                        type: 'setVisualPane',
                        pane: item.props.itemKey as 'results' | 'visual',
                    });
                }
            },
        }),
        [dispatch, flush]
    );

    return (
        <>
            <div className={styles.menubar}>
                <div className={styles.leftItems}>
                    <Pivot selectedKey={selectedKey} onLinkClick={onLinkClick} className={styles.pivot}>
                        <PivotItem
                            itemKey="results"
                            headerText={APP_STRINGS.visuals.resultsTabTitle}
                            itemIcon="table"
                        />
                        {hasVisualOptions && (
                            <PivotItem
                                itemKey="visual"
                                headerText={APP_STRINGS.visuals.visualTabTitle}
                                itemIcon="BarChartVertical"
                            />
                        )}
                    </Pivot>
                    <AddVisualButton />
                </div>
                <div className={styles.rightItems}>
                    <TilePreviewExpandedToggle />
                    <StatusIndicators result={queryStatus} />
                </div>
            </div>
            <QueryNotUpdatedMessages />
            <Visual
                tileId={tileId}
                crossFilterTarget={crossFilterTarget}
                queryStatus={queryStatus}
                queryHash={query?.queryHash}
                onRefreshClick={forceReRun}
            />
        </>
    );
};
