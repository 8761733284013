import * as React from 'react';

import { TextFieldWithControlledError } from '../../../components/fabric/textField/TextFieldWithControlledError';
import { APP_STRINGS } from '../../../res';
import { EditParameterPageDispatch, IEditParameterPageComponentProps } from '../types';

interface IParameterVariableNameTextFieldsProps extends IEditParameterPageComponentProps {
    variableNames: string[];
    className?: string;
    disabled?: boolean;
    dispatch: EditParameterPageDispatch;
    errors?: Array<string | undefined>;
}

export const ParameterVariableNameTextFields: React.FC<IParameterVariableNameTextFieldsProps> = ({
    className,
    variableNames,
    errors,
    dispatch,
}) => {
    const { onChangeFirst, onChangeSecond } = React.useMemo(
        () => ({
            onChangeFirst: (_: unknown, value = '') =>
                dispatch({
                    type: 'setVariableName',
                    index: 0,
                    name: value,
                }),
            onChangeSecond: (_: unknown, value = '') =>
                dispatch({
                    type: 'setVariableName',
                    index: 1,
                    name: value,
                }),
        }),
        [dispatch]
    );

    return variableNames.length < 2 ? (
        <TextFieldWithControlledError
            className={className}
            label={APP_STRINGS.editParameterPage.variableName}
            required={true}
            onChange={onChangeFirst}
            value={variableNames[0] ?? ''}
            error={errors?.[0]}
        />
    ) : (
        // This case only currently exists for duration parameters
        <>
            <TextFieldWithControlledError
                className={className}
                label={APP_STRINGS.editParameterPage.startVariableName}
                required={true}
                onChange={onChangeFirst}
                value={variableNames[0] ?? ''}
                error={errors?.[0]}
            />
            <TextFieldWithControlledError
                className={className}
                label={APP_STRINGS.editParameterPage.endVariableName}
                required={true}
                onChange={onChangeSecond}
                value={variableNames[1] ?? ''}
                error={errors?.[1]}
            />
        </>
    );
};
