import {
    PrimitiveParameterStaticOptionsV1,
    PrimitiveParameterQueryOptionsV1,
    PrimitiveParameterTypeV1,
    PrimitiveParameterTypesV1,
} from './1-0';
import { IParameterBaseV1_1, IPrimitiveParameterV1_1, DurationParamV1_1 } from './1-1';
import { IParameterV1_2, IPrimitiveFreeTextParameterV1_2 } from './1-2';

export interface PrimitiveParameterQueryOptionsV1_3 extends PrimitiveParameterQueryOptionsV1 {
    /**
     * The names of the parent parameters of this parameter. Cannot contain cycles
     */
    activeParentParameters: string[];
}

export interface IPrimitiveSelectedParameterV1_3<
    K extends PrimitiveParameterTypeV1 = PrimitiveParameterTypeV1,
    D = PrimitiveParameterTypesV1[K]
> extends IParameterBaseV1_1<K, D> {
    /**
     * PrimitiveParameterMap[K]he name of the parameter to be referenced in a query
     */
    variableName: string;

    dataSource: PrimitiveParameterStaticOptionsV1<D> | PrimitiveParameterQueryOptionsV1_3;

    selectionType: 'single' | 'single-all' | 'multi';
}

export type IPrimitiveParameterV1_3<
    K extends PrimitiveParameterTypeV1 = PrimitiveParameterTypeV1,
    D = PrimitiveParameterTypesV1[K]
> = IPrimitiveFreeTextParameterV1_2<K, D> | IPrimitiveSelectedParameterV1_3<K, D>;

export type IParameterV1_3 = {
    $schema: '1.3';
} & (IPrimitiveParameterV1_3 | DurationParamV1_1);

function upPrimitive<T extends PrimitiveParameterTypeV1>(prev: IPrimitiveParameterV1_1<T>): IPrimitiveParameterV1_3<T> {
    switch (prev.dataSource.type) {
        case 'query':
            return {
                ...prev,
                dataSource: { ...prev.dataSource, activeParentParameters: [] },
            };
        case 'static':
            return { ...prev, dataSource: prev.dataSource };
    }
}

/**
 * Adds active parent parameters to parameters with query sourced data sources
 */
export function up(prev: IParameterV1_2): IParameterV1_3 {
    if (prev.type === 'duration' || prev.selectionType === 'freetext') {
        return { ...prev, $schema: '1.3' };
    }
    return { ...upPrimitive(prev), $schema: '1.3' };
}
