import { action, makeObservable, observable, observe } from 'mobx';

import { FeatureFlag } from '../domain';
import { APP_CONSTANTS } from '../../res';
import { InterfaceFor } from '../../common';

function getLocalStorageFlags(): Partial<Record<FeatureFlag, 'on' | 'off'>> {
    const str = localStorage.getItem(APP_CONSTANTS.localStorageKeys.featureFlagOverride);

    if (str === null) {
        return {};
    }

    return JSON.parse(str);
}

export type IFeatureFlags = InterfaceFor<FeatureFlags>;

export class FeatureFlags {
    readonly override = observable(getLocalStorageFlags());
    readonly dispose: () => void;

    constructor(readonly tenantFlags: FeatureFlag[]) {
        makeObservable(this, { set: action });

        this.dispose = observe(this.override, () =>
            localStorage.setItem(APP_CONSTANTS.localStorageKeys.featureFlagOverride, JSON.stringify(this.override))
        );
    }

    has(flag: FeatureFlag) {
        const value = this.override[flag];
        if (value) {
            return value === 'on';
        }
        return this.tenantFlags.includes(flag);
    }

    set(flag: FeatureFlag, value: 'on' | 'off' | 'tenant') {
        if (value === 'tenant') {
            delete this.override[flag];
        } else {
            this.override[flag] = value;
        }
    }
}
