import { PrimitiveParameterTypesV1, PrimitiveParameterTypeV1 } from './1-0';
import { DurationParamV1_1, IParameterBaseV1_1, IParameterV1_1, IPrimitiveParameterV1_1 } from './1-1';

export interface IPrimitiveFreeTextParameterV1_2<K extends PrimitiveParameterTypeV1, D = PrimitiveParameterTypesV1[K]>
    extends IParameterBaseV1_1<K, D> {
    /**
     * The name of the parameter to be referenced in a query
     */
    variableName: string;

    selectionType: 'freetext';
}

export type IPrimitiveParameterV1_2<K extends PrimitiveParameterTypeV1 = PrimitiveParameterTypeV1> =
    | IPrimitiveFreeTextParameterV1_2<K>
    | IPrimitiveParameterV1_1<K>;

export type IParameterV1_2 = { $schema: '1.2' } & (IPrimitiveParameterV1_2 | DurationParamV1_1);

/**
 * Adds freetext paramter type, no changes needed to existing parameters.
 */
export function up(prev: IParameterV1_1): IParameterV1_2 {
    return { ...prev, $schema: '1.2' };
}
