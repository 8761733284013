import * as React from 'react';
import { useHeaderTag } from './useHeaderTag';

type HeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadElement>, HTMLHeadElement>;

export interface RtdHeaderProps extends HeaderProps {
    level: 1 | 2 | 3 | 4;
}

export const RtdHeader: React.FC<RtdHeaderProps> = ({ level, children, ...props }) => {
    const tag = useHeaderTag(level);
    return React.createElement(tag, props, children);
};
