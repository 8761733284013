import * as React from 'react';
import {
    Callout,
    PrimaryButton,
    DefaultButton,
    DialogFooter,
    DialogContent,
    DirectionalHint,
} from 'office-ui-fabric-react';
import { APP_STRINGS } from '../../../../../../../res';

import styles from './ConfigurationItemCallout.module.scss';

export interface ConfigurationItemCalloutProps {
    onClose: () => void;
    onSave: () => void;
    targetRef: React.RefObject<Element>;
    title: string;
    directionalHint?: DirectionalHint;
}

export const ConfigurationItemCallout: React.FC<ConfigurationItemCalloutProps> = ({
    onClose,
    onSave,
    targetRef,
    title,
    children,
    directionalHint,
}) => {
    return (
        <Callout onDismiss={onClose} target={targetRef} directionalHint={directionalHint}>
            <DialogContent title={title} className={styles.dialogContent} onDismiss={onClose} showCloseButton>
                {children}
                <DialogFooter className={styles.fullWidth}>
                    <PrimaryButton onClick={onSave}>{APP_STRINGS.utilButtons.apply}</PrimaryButton>
                    <DefaultButton onClick={onClose}>{APP_STRINGS.utilButtons.cancel}</DefaultButton>
                </DialogFooter>
            </DialogContent>
        </Callout>
    );
};
