import { IDataSourceV0 } from './0';

export interface IDataSourceV1 {
    $schema: '1.0';
    id: string;
    type: string;
    title: string;
    connectionFields: string[];
    database: string;
}

export function up({ id, title, databaseType, database, connectionString }: IDataSourceV0): IDataSourceV1 {
    return {
        $schema: '1.0',
        id,
        type: databaseType,
        title,
        database,
        connectionFields: [connectionString],
    };
}
