import { assertNever } from '@uifabric/utilities';

import type { ParameterConfig } from '../parameter';
import type { KustoQueryResultColumn } from '../query';
import type { RtdColumnSchema, RtdValue } from '../value';
import type { KustoDataTypeExtended, KustoScalarTypeExtended } from './types';

import { KustoVariableOutput } from './variableOutput';

// Taken from https://docs.microsoft.com/en-us/azure/kusto/query/schema-entities/entity-names
const allowedVariableCharacters = /[^a-zA-Z0-9._-]/;

export const isValidKustoVariableName = (value: string): boolean => !allowedVariableCharacters.test(value);

export function kustoScalarTypeFromRtdScalarType(dataType: RtdValue.BasicType): KustoVariableOutput.ScalarDataType {
    switch (dataType) {
        case 'string':
        case 'decimal':
        case 'bool':
        case 'datetime':
            return dataType;
        case 'int32':
            return 'int';
        case 'int64':
            return 'long';
        case 'float64':
            return 'double';
        default:
            assertNever(dataType);
    }
}

export function rtdDataTypeFromKustoDataType(dataType: KustoDataTypeExtended): RtdValue.BasicType {
    if (dataType === 'dynamic') {
        return 'string';
    }
    return rtdScalarTypeFromKustoScalarDataType(dataType);
}

export function rtdScalarTypeFromKustoScalarDataType(dataType: KustoScalarTypeExtended): RtdValue.BasicType {
    switch (dataType) {
        case 'datetime':
        case 'date':
            return 'datetime';
        case 'bool':
        case 'boolean':
            return 'bool';
        case 'string':
        case 'decimal':
            return dataType;
        case 'timespan':
        case 'time':
        case 'guid':
            return 'string';
        case 'int':
            return 'int32';
        case 'long':
            return 'int64';
        case 'double':
        case 'real':
            return 'float64';
        default:
            assertNever(dataType);
    }
}

export function kustoColumnsToDashboardColumns(columns: KustoQueryResultColumn[]): RtdColumnSchema[] {
    return columns.map((c) => ({
        name: c.name,
        type: [rtdDataTypeFromKustoDataType(c.type)],
    }));
}

export function variablesAndKustoTypesFromParameter(
    parameter: ParameterConfig
): Array<{ name: string; type: KustoVariableOutput.DataType }> {
    const variables = parameter.variableNames;

    let type: KustoVariableOutput.DataType;

    switch (parameter.kind) {
        case 'basic':
            if (parameter.options.selectionKind === 'array-null') {
                type = 'dynamic';
            } else {
                type = kustoScalarTypeFromRtdScalarType(parameter.dataType);
            }
            break;
        case 'duration':
            type = 'datetime';
            break;
        default:
            assertNever(parameter);
    }
    return variables.map((name) => ({ name, type }));
}
