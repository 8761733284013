/* eslint-disable @typescript-eslint/no-redeclare */
import { types } from 'mobx-state-tree';

const MAX_CACHE_SIZE = 200;
const CachedItem = types
    .model('CachedItem', {
        id: types.identifierNumber,
        // eslint-disable-next-line @typescript-eslint/ban-types
        data: types.maybe(types.frozen<{}>()),
        timestamp: types.optional(types.number, () => new Date().getTime()),
    })
    .actions((self) => ({
        updateUsageTimestamp: () => {
            self.timestamp = new Date().getTime();
        },
    }));

// Store Visual grid state per column hash
// Up to 200 items
// if the cache size is 20% more then that reduce the size
export const GridStateCache = types
    .model('GridStateCache', {
        states: types.map(CachedItem),
    })
    .views((self) => ({
        retrieveGridState<T>(columnHash: number) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const item = self.states.get(columnHash as any);
            if (!item) {
                return undefined;
            }
            item.updateUsageTimestamp();

            return item.data as T;
        },
    }))
    .actions((self) => ({
        storeGridState<T>(columnHash: number, data: T) {
            const item = CachedItem.create({ id: columnHash, data });
            self.states.put(item);

            if (self.states.size > MAX_CACHE_SIZE * 1.2) {
                const reducedCache = Array.from(self.states.values())
                    .sort((itemA, itemB) => itemB.timestamp - itemA.timestamp)
                    .slice(0, MAX_CACHE_SIZE)
                    .reduce((map, item) => {
                        map[item.id.toString()] = item;
                        return map;
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    }, {} as any);
                self.states.replace(reducedCache);
            }
        },
    }));
// eslint-disable-next-line no-redeclare
export type GridStateCache = typeof GridStateCache.Type;
