import * as React from 'react';
import { useState, useCallback, useLayoutEffect, useMemo, useReducer, useRef, useEffect } from 'react';
import { IconButton, ActionButton } from 'office-ui-fabric-react';
import { withSize, SizeMeProps } from 'react-sizeme';
import { observer } from 'mobx-react-lite';

import { APP_STRINGS } from '../../../res';
import { ParameterConfig } from '../../../domain/parameter';
import { useIsTruncated } from '../../../common';
import { UnsafeFocusZone, IUnsafeFocusZone } from '../../fabric/focusZone';
import { IParameterSelections } from '../../../store';

import { ParameterSelector } from '../ParameterSelector';

import { parameterSelectorListReducer } from './reducer';
import { TemporalErrors } from './TemporalErrors';

import styles from './ParameterSelectorList.module.scss';

const SINGLE_ROW_HEIGHT = 48;
const SINGLE_ROW_MARGIN = 4;

const EXPANSION_DISABLED_HEIGHT = 0;

const resetIconProps = { iconName: 'dashboards-Reset' };
const expandIconProps = { iconName: 'DoubleChevronDown' };

/**
 * Renders empty if parameters or selectedParameters are missing
 */
export interface ParameterSelectorListProps {
    parameters: undefined | readonly ParameterConfig[];
    // Undefined while loading
    selectedParameters: undefined | IParameterSelections;
    activeVariables?: Set<string>;
    displayVariables?: boolean;

    telemetryComponentName?: string;
}

interface InnerParameterSelectorListProps extends ParameterSelectorListProps {
    containerWidth: number | null;
}

const InnerParameterSelectorList: React.FC<InnerParameterSelectorListProps> = observer(
    function InnerParameterSelectorList({
        parameters,
        selectedParameters,
        activeVariables,
        displayVariables = false,
        telemetryComponentName,
        containerWidth,
    }) {
        const focusZoneRef = useRef<IUnsafeFocusZone | null>(null);

        const [{ isOpen, isAnimating }, localDispatch] = useReducer(parameterSelectorListReducer, {
            isOpen: false,
            isAnimating: false,
        });
        const [expandedHeight, setExpandedHeight] = useState(EXPANSION_DISABLED_HEIGHT);
        const hasSelectionChanges = selectedParameters?.changed;
        const inactiveParameters = React.useMemo<Set<string>>(() => {
            if (parameters === undefined || activeVariables === undefined) {
                return new Set();
            }

            return new Set(
                parameters.filter((p) => !p.variableNames.some((v) => activeVariables.has(v))).map((p) => p.id)
            );
        }, [activeVariables, parameters]);

        const style = useMemo(() => (isOpen ? { height: expandedHeight } : {}), [isOpen, expandedHeight]);

        const toggleIsOpen = useCallback(() => localDispatch({ type: 'toggleIsOpen' }), []);

        const finishAnimation = useCallback(() => localDispatch({ type: 'finishAnimation' }), []);

        // 8px height margin on parent
        const { isTruncated, parentRef, childRef } = useIsTruncated(
            'height',
            [containerWidth, isOpen, selectedParameters, parameters],
            {
                parentDimensionMargin: -8,
                // Don't measure while expanded or animating expansion
                disable: isOpen || isAnimating,
            }
        );

        useLayoutEffect(() => {
            if (isAnimating) {
                return;
            }

            const height = childRef.current?.getBoundingClientRect().height ?? EXPANSION_DISABLED_HEIGHT;
            const heightWithMargins = height + SINGLE_ROW_MARGIN * 2;

            // Height of single row list - margins
            const newExpandedHeight =
                heightWithMargins > SINGLE_ROW_HEIGHT ? heightWithMargins : EXPANSION_DISABLED_HEIGHT;

            setExpandedHeight(newExpandedHeight);

            if (newExpandedHeight === EXPANSION_DISABLED_HEIGHT) {
                // Ensure list isn't expanded
                localDispatch({ type: 'setIsOpen', isOpen: false });
            }
            // Recalculate when containerWidth changes, even though it's not consumed in JS
        }, [containerWidth, isAnimating, selectedParameters, parameters, childRef]);

        useLayoutEffect(() => localDispatch({ type: 'setIsOpen', isOpen: false }), [containerWidth]);

        /*
         * Whenever width/isOpen changes, clear the active selection.
         *
         * Once FocusZone loses focus, it keeps track of the last focused element. If that element no longer exists or is no longer
         * focusable (no visibility, `display: hidden`, etc..), the FocusZone is no longer focusable by keyboard.
         * We circumvent this limitation by resetting the stored selection whenever the elements may disappear/reappear
         */
        useLayoutEffect(() => focusZoneRef.current?.clearActiveSelection(), [isOpen, containerWidth]);

        const resetButton = (
            <ActionButton
                iconProps={resetIconProps}
                disabled={
                    parameters === undefined || inactiveParameters.size === parameters.length || !hasSelectionChanges
                }
                onClick={selectedParameters?.resetSelections}
            >
                {APP_STRINGS.utilButtons.reset}
            </ActionButton>
        );

        const [firstRowParameterIds, setFirstRowParameterIds] = React.useState(new Set<string>());

        useEffect(() => {
            if (!childRef.current || !isTruncated) {
                return;
            }

            const firstRow = new Set<string>();

            for (const el of childRef.current.children) {
                if (!(el instanceof HTMLElement) || el.dataset.parameter_id === undefined) {
                    // TODO: Log
                    // eslint-disable-next-line no-console
                    console.error('Unexpected child element', el);
                    continue;
                }

                // .items > div applies a 6px top margin, so each pill that has an offset of 6px offset is in the first row.
                // We have seen a use case where a user zoomed in and margin-top changed to 7px, so to be on the safe side
                // if offsetTop is smaller then 10 px, consider it a first row.
                if (el.offsetTop <= 10) {
                    firstRow.add(el.dataset.parameter_id);
                }
            }

            setFirstRowParameterIds(firstRow);
        }, [childRef, isOpen, isTruncated]);

        return (
            // Wrapping div is required for withSize HOC. Otherwise, it adds a measuring div to our flexbox
            <div>
                {selectedParameters && (
                    <TemporalErrors
                        isOpen={isOpen}
                        firstRowParameterIds={firstRowParameterIds}
                        selectedParameters={selectedParameters}
                    />
                )}
                <div
                    ref={parentRef}
                    className={
                        isOpen && isTruncated ? `${styles.selectorList} ${styles.expanded}` : styles.selectorList
                    }
                    style={style}
                    onTransitionEnd={finishAnimation}
                    data-no-horizontal-wrap={!isOpen}
                    data-no-vertical-wrap={!isOpen}
                >
                    <UnsafeFocusZone
                        componentRef={focusZoneRef}
                        className={styles.itemsWrapper}
                        checkForNoWrap={true}
                        shouldResetActiveElementWhenTabFromZone={true}
                    >
                        <div ref={childRef} className={styles.items}>
                            {selectedParameters &&
                                parameters?.map((parameter) => (
                                    <PillHiddenWrapper
                                        key={parameter.id}
                                        parameterId={parameter.id}
                                        isHidden={
                                            !firstRowParameterIds.has(parameter.id) &&
                                            !isOpen &&
                                            !!isTruncated &&
                                            !isAnimating
                                        }
                                    >
                                        <ParameterSelector
                                            parameterConfig={parameter}
                                            selections={selectedParameters}
                                            isActive={!inactiveParameters.has(parameter.id)}
                                            displayVariables={displayVariables}
                                            telemetryComponentName={telemetryComponentName}
                                        />
                                    </PillHiddenWrapper>
                                ))}
                            {!isTruncated && !isOpen && resetButton}
                        </div>
                    </UnsafeFocusZone>
                    <div className={styles.controls}>
                        {(isOpen || isTruncated) && (
                            <>
                                {resetButton}
                                {/* We will become truncated prior to actually having an expandedHeight, so hide expand toggle until we do have a height */}
                                {expandedHeight !== EXPANSION_DISABLED_HEIGHT && (
                                    <IconButton
                                        className={styles.expandButton}
                                        iconProps={expandIconProps}
                                        onClick={toggleIsOpen}
                                        styles={disabledExpandButtonStyles}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

interface PillHiddenWrapperProps {
    isHidden: boolean;
    parameterId: string;
}

const PillHiddenWrapper: React.FC<PillHiddenWrapperProps> = ({ isHidden, parameterId, children }) => {
    return (
        <div
            data-parameter_id={parameterId}
            className={styles.itemWrapper}
            // If the item is not in the first row and the list is collapsed, stop rendering it, but keep it in the DOM and
            // keep its positioning (`display: none` will not preserve position). Position must be kept stable to prevent
            // `useIsTruncated` from recalculating and breaking the expand/contract functionality
            style={isHidden ? { visibility: 'hidden' } : undefined}
        >
            {children}
        </div>
    );
};

const disabledExpandButtonStyles = {
    rootDisabled: { backgroundColor: 'unset' },
};

const MemoedInnerParameterSelectorList = React.memo(InnerParameterSelectorList);

interface PropsWithSizeMe extends ParameterSelectorListProps, SizeMeProps {}

const InnerParameterSelectorListWithSize: React.FC<PropsWithSizeMe> = (props) => {
    const { size: _, ...sharedProps } = props;
    return (
        // size changes often even though there are no changes to width or height. Grab the scalar and memoize it
        <MemoedInnerParameterSelectorList {...sharedProps} containerWidth={props.size.width} />
    );
};

export const ParameterSelectorList = withSize({
    // Without this, withSize temporarily sets the root div to height: 100%, width: 100%
    noPlaceholder: true,
})(InnerParameterSelectorListWithSize);
