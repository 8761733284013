import { computed, observable, action, runInAction, observe } from 'mobx';
import isEqualWith from 'lodash/isEqualWith';
import type { IsEqualCustomizer } from 'lodash';

import { ReadonlyRecord } from '../../common';
import { IItemDiffMap } from './DashboardChanges';

/**
 * lodash.isEqual fails when methods don't have quality. Methods bound in the constructor will cause this.
 *
 * For example, this:
 * ```
 * this.method = this.method.bind(this);
 * ```
 * will cause `isEqual` to fail
 */
const isEqualCustomization: IsEqualCustomizer = (value, other) => {
    if (typeof value === 'function' && typeof other === 'function') {
        return true;
    }
    return;
};

function updateValue<T>(
    res: Record<string, T>,
    id: string,
    changes: undefined | IItemDiffMap<T>,
    baseValues: Map<string, T>
) {
    const setItem = action((value: T) => {
        const prev = res[id];

        if (!isEqualWith(prev, value, isEqualCustomization)) {
            res[id] = value;
        }
    });

    if (changes) {
        if (changes.delete.has(id)) {
            delete res[id];
            return;
        }
        const value = changes.create[id];
        if (value) {
            setItem(value);
            return;
        }
    }

    // If no changes (additions/deletions) try to reset to base value
    const value = baseValues.get(id);
    if (value) {
        setItem(value);
        return;
    }
    delete res[id];
}

/**
 * Creates an mobx record and keeps it up to date with mobx changes to values
 * and changes. Arguments are functions so that mobx will track dependencies
 * when getting them.
 *
 * Note: Do NOT use any regular observers here. (reaction, autorun, etc.)
 * "observe" is used exclusively because we must not respect transactions,
 * otherwise the resulting collection can become stale. This does mean that on
 * many small changes this may run more than necessary.
 *
 * Note: It turns out making this run inside of actions 100% of the time is
 * _very_ tricky. It likely doesn't right now, and might need to be re-written
 * to use atoms to do so.
 *
 * TODO: Should likely be refactored to manage disposers
 *
 * @param getValues Get base values
 * @param getChanges Get changes to base values
 * @returns mobx observable record
 */
export function diffAppliedRecord<T extends { id: string }>(
    getValues: () => readonly T[],
    getChanges: () => undefined | IItemDiffMap<T>
): ReadonlyRecord<string, T> {
    const res = observable.object<Record<string, T>>({}, undefined, { deep: false });

    const baseValues = computed(() => {
        const values = getValues();
        return {
            arr: values,
            map: new Map(values.map((v) => [v.id, v])),
        };
    });

    function onBaseValuesUpdate() {
        const ids = new Set([...baseValues.get().arr.map((v) => v.id), ...Object.keys(res)]);
        for (const id of ids) {
            updateValue(res, id, getChanges(), baseValues.get().map);
        }
    }
    // observe respects actions for it's first run when "fireImmediately" is
    // set. We needs to both run now so that the result hash can be read, _and_
    // after the transaction to apply any changes that are added between here
    // and before the transaction ends.
    runInAction(() => onBaseValuesUpdate());
    observe(baseValues, onBaseValuesUpdate, true);

    const changesComputed = computed(getChanges);

    // ids of values that have a change (deleted or created);
    const changed = new Set<string>();

    function onChangesUpdate() {
        const changes = changesComputed.get();
        if (changes) {
            const onValueChange = (id: string, type: 'add' | 'update' | 'remove') => {
                if (type === 'remove') {
                    changed.delete(id);
                } else {
                    changed.add(id);
                }
                updateValue(res, id, changes, baseValues.get().map);
            };

            observe(changes.delete, (change) => {
                onValueChange(change.type === 'remove' ? change.oldValue : change.newValue, change.type);
            });

            observe(changes.create, (change) => {
                onValueChange(change.name as string, change.type);
            });
        } else {
            for (const id of changed) {
                updateValue(res, id, undefined, baseValues.get().map);
            }
            changed.clear();
        }
    }

    // observe respects actions for it's first run when "fireImmediately" is
    // set. We needs to both run now so that the result hash can be read, _and_
    // after the transaction to apply any changes that are added between here
    // and before the transaction ends.
    runInAction(() => onChangesUpdate());
    observe(changesComputed, onChangesUpdate, true);

    return res;
}
