import { useEffect, useState } from 'react';
import { filter, map } from 'rxjs/operators';

import { useCurrent } from '../../common';
import { useCore } from '../../core/react';

import { IResourceMetadataStore, IResourceMetadataNotification } from '../metadata/types';
import { ResourceTypes } from '../types';

/**
 * Gets the current metadata of the requested types, and subscribes to future changes of that metadata
 *
 * @param types The types of resource metadata to return. **Note:** As a performance optimization, types is only used as an initial value, and cannot be changed
 */
export const useResourceMetadata = <T extends ResourceTypes>(types: T[]): IResourceMetadataStore<T> => {
    const { resourceMetadata } = useCore();
    const [typesSet] = useState(() => new Set<string>(types));
    const [state, setState] = useState<IResourceMetadataStore<T>>({});

    const currentState = useCurrent(state);

    // Subscribes to the metadata Observable and filters by selected types
    useEffect(() => {
        const subscription = resourceMetadata.subject
            .pipe(
                filter((v) => typesSet.has(v.type)),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((v): IResourceMetadataNotification<T> => v as any)
            )
            .subscribe({
                next: ({ type }) =>
                    // When data is received, grab all metadata for the relevant type
                    setState({
                        ...currentState.current,
                        [type]: resourceMetadata.getType(type),
                    }),
            });

        return () => subscription.unsubscribe();
        // Only ever runs once (on component mount), so checking dependencies doesn't do any good
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return state;
};
