import { tile } from '../tile';
import { IDashboardV9 } from './9';

export interface IDashboardV10 extends Omit<IDashboardV9, '$schema' | 'tiles'> {
    $schema: '10';
    tiles: tile.v6.ITileV6[];
}

export function up(prev: IDashboardV9): IDashboardV10 {
    return {
        ...prev,
        $schema: '10',
        tiles: prev.tiles.map((t) => tile.v6.up(t)),
    };
}

export function down(prev: IDashboardV10, _warnings: string[]): IDashboardV9 {
    return {
        ...prev,
        $schema: '9',
        tiles: prev.tiles.map((t) => tile.v6.down(t)),
    };
}
