import React from 'react';
import { IDropdownOption, IRenderFunction } from 'office-ui-fabric-react';

import { APP_CONSTANTS, APP_STRINGS } from '../../../../../res';
import { RTDDropdownOption, RTDDropdown } from '../../../../../components';
import { useCore } from '../../../../../core';
import {
    applyDropdownSelection,
    useParameterOptions,
    RtdValue,
    useTimeZone,
    convertToParameterResult,
    ValueImpl,
} from '../../../../../domain';
import { StreamQueryResult } from '../../../../../domain/query/types';
import { EppQueryDropdownOptions, EppReducerState } from '../../../reducer';

import pageStyles from '../../../EditParameterPage.module.scss';

const onRenderTitle: IRenderFunction<IDropdownOption[]> = (options, defaultRender) => {
    if (options?.some((o) => o.key === APP_CONSTANTS.parameter.allSelection)) {
        return <>{APP_STRINGS.domain.parameter.selection.all}</>;
    }
    return (options && defaultRender?.(options)) ?? null;
};

export interface DefaultValueDropdownProps<T, K extends string> {
    pageOptions: EppReducerState;
    getState: () => EppReducerState;
    queryOptions: EppQueryDropdownOptions;
    queryStatus: StreamQueryResult;
    impl: ValueImpl<T, K>;
    /** This is taken instead of dispatch so we can respond with a generic value instead of a union value */
    onChange: (value: RtdValue.Value<T, K>) => void;
}

export const DefaultValueDropdown = <T extends string | number | boolean, K extends RtdValue.BasicType>({
    queryStatus,
    queryOptions,
    getState,
    impl,
    onChange,
}: DefaultValueDropdownProps<T, K>) => {
    const core = useCore();
    const timeZone = useTimeZone();

    const multiSelect = queryOptions.selectionKind === 'array-null';
    const enableSelectAll = queryOptions.selectionKind !== 'scalar';

    let defaultValue: undefined | RtdValue.Value<T, K>;

    if (queryOptions.defaultValue) {
        if (queryOptions.defaultValue.kind === 'null') {
            defaultValue = queryOptions.defaultValue;
        } else {
            defaultValue = impl.tryNarrowValue(queryOptions.defaultValue);
        }
    } else {
        defaultValue = undefined;
    }

    const dataSource = queryOptions.dataSource;

    const parsedOptions = React.useMemo(() => {
        // Query errors are displayed in the result preview
        if (queryStatus.kind !== 'success') {
            return undefined;
        }

        return convertToParameterResult<T>(impl.dataType, dataSource, queryStatus.result, core.queryService, timeZone);
    }, [core, dataSource, impl, queryStatus, timeZone]);

    const { options, selectedKeys } = useParameterOptions<T>({
        valueToDisplayString: impl.valueToDisplayString,
        expectedOptions: parsedOptions?.value,
        selected: defaultValue,
        includeAll: enableSelectAll,
        timeZone,
    });

    const onDropdownChange = React.useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (!option) {
                return;
            }

            const selectOption = option as RTDDropdownOption<typeof APP_CONSTANTS.parameter.allSelection | T>;

            const dataType = impl.dataType;
            const data = selectOption.data;

            if (data === undefined) {
                throw new Error(`Data is unexpectedly missing for key ${selectOption.key}`);
            }

            const state = getState();
            if (state.union.kind !== 'basic' || state.union.union.kind !== 'query') {
                return;
            }
            const prevDefaultValue = state.union.union.defaultValue;
            let prevValue: undefined | RtdValue.Value<T, K>;
            if (prevDefaultValue?.kind === 'scalar' || prevDefaultValue?.kind === 'array') {
                prevValue = impl.tryNarrowValue(prevDefaultValue);
            } else {
                prevValue = prevDefaultValue;
            }

            const newValue = applyDropdownSelection(prevValue, dataType, multiSelect, data, options);
            onChange(newValue);
        },
        [impl, getState, multiSelect, options, onChange]
    );

    return (
        <RTDDropdown
            onRenderTitle={onRenderTitle}
            disabled={queryOptions.valueColumn === undefined || options.length === 0}
            className={pageStyles.input}
            required
            label={APP_STRINGS.editParameterPage.defaultValue}
            options={options}
            multiSelect={multiSelect}
            placeholder={APP_STRINGS.editParameterPage.selection.noDefault}
            {...(multiSelect
                ? { selectedKeys }
                : {
                      selectedKey: selectedKeys[0],
                  })}
            onChange={onDropdownChange}
            errorMessage={parsedOptions?.err}
        />
    );
};
