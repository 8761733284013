import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { withTelemetry } from '../../core/react';
import { useDashboardStore } from '../../store/dashboard/context';

import { EditParameterPage } from '../editParameter/EditParameterPage';

import { Dashboard } from './Dashboard/Dashboard';
import { useUrlParametersSync } from './useUrlParametersSync';

import styles from './DashboardPage.module.scss';

export const DashboardPage = withTelemetry(
    observer(function DashboardPage() {
        const store = useDashboardStore();
        const history = useHistory();

        useUrlParametersSync(store, history);

        if (store.state?.changes?.editParameter) {
            return (
                <EditParameterPage
                    dashboardState={store.state}
                    dashboardChanges={store.state.changes}
                    incomingParameterId={store.state.changes.editParameter}
                />
            );
        }

        return <Dashboard />;
    }),
    'DashboardPage',
    styles.main
);
