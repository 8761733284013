import * as React from 'react';
import { useEffect } from 'react';
import { Switch, Route, RouteComponentProps, useHistory, useRouteMatch, Redirect, useLocation } from 'react-router-dom';
import { useGlobalDispatch } from '../../../store';

import { useDashboardStore } from '../../../store/dashboard';
import { IRtdDashboardsCore, useCore } from '../../../core';
import { APP_STRINGS } from '../../../res';
import { DashboardPage } from '../../dashboard';
import { EditTilePage } from '../../editTile/EditTilePage';
import { RouteState } from '../../../domain';

import { useLoadDashboardFromUrl } from './useLoadDashboardFromUrl';
import { ErrorLoadingDashboard } from './ErrorLoadingDashboard';

/**
 * When we leave the dashboard route, remove all queries on un-mount and cancel
 * timers.
 *
 * TODO: Using this to clear the query service means that we leak memory for
 * deleted queries while we remain on the dashboard page
 */
function useRouteQuerySideEffects(core: IRtdDashboardsCore, urlDashboardId: string) {
    // First effect clears queries when the dashboard we are viewing changes
    React.useEffect(() => {
        if (urlDashboardId !== 'new') {
            return () => {
                core.queryService.deregisterAllQueries();
                core.queryService.cancelTimer();
            };
        }
        return;
    }, [urlDashboardId, core]);

    // Second effect clears queries when we stop viewing or editing any dashboard
    React.useEffect(
        () => () => {
            core.queryService.deregisterAllQueries();
            core.queryService.cancelTimer();
        },
        [core]
    );
}

export type IDashboardRouteParams = RouteComponentProps<{
    dashboardId?: string;
}>;

export const DashboardRoute: React.FC = () => {
    const core = useCore();
    const store = useDashboardStore();
    const [globalDispatch] = useGlobalDispatch();
    const history = useHistory();
    const location = useLocation<RouteState>();
    const urlDashboardId = useRouteMatch<{ dashboardId: string }>().params.dashboardId;

    useLoadDashboardFromUrl(core, globalDispatch, store, history, urlDashboardId, location.state);
    useRouteQuerySideEffects(core, urlDashboardId);

    useEffect(() => {
        return core.history.block((nextLocation, _) => {
            if (
                (nextLocation === undefined ||
                    !nextLocation?.pathname.startsWith(core.navUtil.path.dashboard(urlDashboardId))) &&
                store.changed()
            ) {
                return APP_STRINGS.edits.unsavedChanges;
            }
            return;
        });
    }, [core, store, urlDashboardId]);

    return (
        <>
            <ErrorLoadingDashboard />
            <Switch>
                <Route path={core.navUtil.path.editTile(':dashboardId', ':tileId')} exact>
                    <EditTilePage />
                </Route>
                <Route path={core.navUtil.path.dashboard(':dashboardId')} exact>
                    <DashboardPage />
                </Route>
                <Route path="*">
                    <Redirect to={core.navUtil.path.catalog} />
                </Route>
            </Switch>
        </>
    );
};
