export interface DashboardMember {
    id: string;
    email?: string;
    type: 'user' | 'group';
    permission: DashboardPermission;
    edited?: boolean;
}

export interface DashboardPermissions {
    members: DashboardMember[];
    permission: DashboardPermission;
}

// eslint-disable-next-line no-shadow
export enum MembershipType {
    user = 'user',
    group = 'group',
}

// eslint-disable-next-line no-shadow
export enum DashboardPermission {
    viewer = 'viewer',
    editor = 'editor',
    none = 'none',
}
