import * as React from 'react';
import { Stack, Icon, Text } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../../../res';
import {
    useQueryResult,
    StatusIndicators,
    QueryNotRunVisual,
    StatusInfoText,
} from '../../../../../../components/QueryEditing';
import { DataVisual } from '../../../../../../components/tile/DataVisual';

import styles from './VisualPane.module.scss';

const stackTokens = { childrenGap: 24, padding: '12px 24px' };

export const VisualPane: React.FC = () => {
    const [queryStatus] = useQueryResult();

    return (
        <>
            <Stack horizontal tokens={stackTokens} className={styles.status}>
                <StatusInfoText>
                    <Icon iconName="table" />
                    <Text className={styles.title}>{APP_STRINGS.visuals.resultsTabTitle}</Text>
                </StatusInfoText>
                <div className={styles.spacer}></div>
                <StatusIndicators result={queryStatus} />
            </Stack>

            {queryStatus.kind === 'notRun' || (queryStatus.kind === 'err' && !queryStatus.didRun) ? (
                <QueryNotRunVisual queryStatus={queryStatus} className={styles.queryNotRun} />
            ) : (
                <div className={styles.table}>
                    <DataVisual queryResult={queryStatus} visualType="table" />
                </div>
            )}
        </>
    );
};
