import * as Highcharts from 'highcharts';
import * as React from 'react';
import Boost from 'highcharts/modules/boost';
import Accessibility from 'highcharts/modules/accessibility';
import ReactResizeDetector from 'react-resize-detector';
import HighchartsReact from 'highcharts-react-official';
import { useEffect } from 'react';

import { convertToHighchart, setLocale } from './utils';
import { usePrevious } from '../utils/hooks';
import { InternalChartProps } from '../@types/chart.types';

Boost(Highcharts);
Accessibility(Highcharts);

/**
 * A component that shows a highchart given ChartProps.
 */
export const Highchart: React.FC<InternalChartProps> = (props) => {
    useEffect(() => {
        if (props.locale) {
            setLocale(props.locale);
        }
    }, [props.locale]);
    const options = convertToHighchart(props);

    const prevDarkTheme = usePrevious(props.isDarkTheme);
    const prevVisualizationOptions = usePrevious(props.visualizationOptions);

    // Delay loading of chart resources in case there's no data to show.
    // This is done since map loading is heavy and it puts in the request queue actual query requests.
    if (!props.dataItems || props.dataItems.length === 0) {
        return null;
    }

    // highcharts does not know how to switch themes when updating the chart object, only when recreating it.
    // Thus whenever we're changing themes, we're signaling highcharts to recreate the object by (ab)using the immutable flag.
    // also: highcharts doesn't handle switching between chart types very well (for example - it might switch the axes).
    // Thus recreate the chart whenever we're changing chart type
    const shouldRecreateChartObject =
        (prevDarkTheme !== undefined && prevDarkTheme !== props.isDarkTheme) ||
        prevVisualizationOptions?.Visualization !== props.visualizationOptions.Visualization ||
        prevVisualizationOptions?.YAxis !== props.visualizationOptions.YAxis;

    return (
        <>
            <ReactResizeDetector
                handleHeight={true}
                handleWidth={true}
                refreshMode="debounce"
                refreshRate={100}
                onResize={() => {
                    Highcharts.charts.map((chart) => chart && chart.reflow());
                }}
            />
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                immutable={shouldRecreateChartObject}
                containerProps={{ style: { height: '100%' } }}
            />
        </>
    );
};
