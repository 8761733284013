import * as React from 'react';
import { useRef, useLayoutEffect, useState } from 'react';
import { assertNever } from 'office-ui-fabric-react';

import { useTransitionHelper, TransitionHelperState } from './useTransitionHelper';

import styles from './Slideout.module.scss';

const selectStyle = (state: TransitionHelperState, childHeight: number | undefined): React.CSSProperties => {
    switch (state.status) {
        case 'done':
            if (state.target === 'open') {
                return { height: 'auto' };
            } else {
                return { display: 'none' };
            }
        case 'animating':
            if (state.target === 'open') {
                return { height: childHeight ?? 'auto' };
            } else {
                return { height: 0 };
            }
        case 'pre-animation':
            if (state.target === 'open') {
                return { height: 0 };
            } else {
                return { height: childHeight ?? 'auto' };
            }
        default:
            assertNever(state.status);
    }
};

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface SlideoutProps extends DivProps {
    open: boolean;
}

/**
 * Gets the height of the starting animation from the size of it's first child
 * element. To avoid any  jumps at animation end or start the size of the first
 * child element should be the same as this elements auto size.
 *
 * When open and not animating the height will be auto. When closed and not
 * animating will be "display: none".
 */
export const Slideout: React.FC<SlideoutProps> = ({ open, ...props }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [childHeight, setChildHeight] = useState<number | undefined>(undefined);

    const { onTransitionEnd, ...state } = useTransitionHelper(open);

    const style = selectStyle(state, childHeight);

    useLayoutEffect(() => {
        if (style.display === 'hidden') {
            return;
        }
        const height = ref.current?.firstElementChild?.getBoundingClientRect().height;
        if (height === undefined) {
            return;
        }
        setChildHeight(height);
    }, [open, style.display]);

    return (
        <div
            {...props}
            ref={ref}
            style={{ ...style, ...props.style }}
            className={props.className ? `${styles.slideout} ${props.className}` : styles.slideout}
            onTransitionEnd={onTransitionEnd}
        />
    );
};
