import { Theme } from '@kusto/common';
import { isEmpty } from 'lodash';
import { dependencies } from '../dependencies';

const { workspaceName } = dependencies.deepLinkProperties;
export const isEmptyWorkspaceParam = workspaceName && new RegExp('volatile|empty', 'i').test(workspaceName);
export const useWorkspace = !isEmptyWorkspaceParam && workspaceName && workspaceName.length > 0;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const localStorageKey = useWorkspace ? workspaceName! : dependencies.localStorageKey;
export const mainStorageKey = localStorageKey;
export const connectionsStorageKey = `${localStorageKey}_connections`;
export const gridCacheStorageKey = `${localStorageKey}_gridCache`;

/** Read theme from storage. Use this method if theme is needed before the mobx-state-tree model is loaded. */
export function readSettingsFromStorage(): { theme: Theme; languageCode?: string } {
    const mainStorageJson = localStorage.getItem(mainStorageKey);
    if (!mainStorageJson) {
        return { theme: Theme.Light, languageCode: undefined };
    }
    const mainStorage = JSON.parse(mainStorageJson);

    return {
        theme: mainStorage.settings.theme === 'Dark' ? Theme.Dark : Theme.Light,
        languageCode: mainStorage.settings.language,
    };
}

export function isVolatileWorkspace(emptyWorkspaceOnDeepLinkQuery: boolean | undefined): boolean {
    if (isEmptyWorkspaceParam) {
        return true;
    }

    if (
        emptyWorkspaceOnDeepLinkQuery &&
        (!isEmpty(dependencies.deepLinkProperties.query) || !isEmpty(dependencies.deepLinkProperties.querySrc))
    ) {
        return true;
    }

    return false;
}
