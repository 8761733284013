import { tile } from '../tile';
import { IDashboardV11 } from './11';

export interface IDashboardV12 extends Omit<IDashboardV11, '$schema'> {
    $schema: '12';
}

/**
 * This 👇 interface was getting mixed into the visual options
 * ```typescript
 * export interface ETPVisual {
 *   readonly options: ETPVisualOptions;
 *   readonly visualType: string;
 *   readonly configurationOpen: boolean;
 * }
 * ```
 * This function deletes the extra properties
 */
function correctTileCorruption(t: tile.v7.ITileV7): tile.v7.ITileV7 {
    const options: Partial<Record<string, unknown>> = t.visualOptions;

    delete options['options'];
    delete options['visualType'];
    delete options['configurationOpen'];

    return t;
}

export function up(prev: IDashboardV11): IDashboardV12 {
    return {
        ...prev,
        tiles: prev.tiles.map(correctTileCorruption),
        $schema: '12',
    };
}

export function down(prev: IDashboardV12, _warnings: string[]): IDashboardV11 {
    return { ...prev, $schema: '11' };
}
