import * as React from 'react';
import { useCallback, useReducer, useEffect, useMemo } from 'react';
import { Toggle, DefaultButton, PrimaryButton, IDropdownOption, IDropdownProps } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { action, autorun } from 'mobx';
import { APP_STRINGS, APP_CONSTANTS } from '../../../../res';
import { RTDDropdown, RTDDropdownOption, PanelWithFooter } from '../../../../components/fabric';
import type { AutoRefreshUpdateInterval } from '../../../../core/domain';
import { LabelWithInfo } from '../../../../components/fabric/label/LabelWithInfo';
import { TFormComponent, formActionMacro } from '../../../../common';
import { useDashboardStore } from '../../../../store/dashboard';

import { autoRefreshReducer } from './reducer';

import styles from './AutoRefreshForm.module.scss';

const noop = () => {};

const autoRefreshTooltipProps = {
    id: 'auto-refresh-form--description',
};

export const AutoRefreshForm: TFormComponent = observer(function AutoRefreshForm({ onClose }) {
    const [state, localDispatch] = useReducer(autoRefreshReducer, {
        config: {
            enabled: true,
            defaultInterval: '1d',
        },
        availableOptions: APP_CONSTANTS.orderedAutoRefreshIntervals,
    });
    const { enabled, minInterval, defaultInterval } = state.config;

    const store = useDashboardStore();

    const minOptions: RTDDropdownOption[] = [
        {
            key: APP_CONSTANTS.emptySelection,
            text: APP_STRINGS.forms.autoRefresh.noInterval,
        },
        ...APP_CONSTANTS.orderedAutoRefreshIntervals.map(
            (interval): RTDDropdownOption => ({
                key: interval,
                text: APP_CONSTANTS.supportedAutoRefreshIntervals[interval].text,
            })
        ),
    ];

    const availableOptions: RTDDropdownOption[] = [
        {
            key: APP_CONSTANTS.emptySelection,
            text: APP_STRINGS.autoRefresh.off,
        },
        ...state.availableOptions.map(
            (interval): RTDDropdownOption => ({
                key: interval,
                text: APP_CONSTANTS.supportedAutoRefreshIntervals[interval].text,
            })
        ),
    ];

    const setEnabled = useCallback(
        (_: unknown, checked: boolean | undefined) =>
            localDispatch({
                type: 'setEnabled',
                enabled: !!checked,
            }),
        [localDispatch]
    );

    const setMinInterval = useCallback(
        (_: unknown, option: IDropdownOption | undefined) => {
            if (!option) {
                return;
            }

            localDispatch({
                type: 'setMinInterval',
                minInterval:
                    option.key !== APP_CONSTANTS.emptySelection ? (option.key as AutoRefreshUpdateInterval) : undefined,
            });
        },
        [localDispatch]
    );

    const setDefaultInterval = useCallback(
        (_: unknown, option: IDropdownOption | undefined) => {
            if (!option) {
                return;
            }

            localDispatch({
                type: 'setDefaultInterval',
                defaultInterval:
                    option.key !== APP_CONSTANTS.emptySelection ? (option.key as AutoRefreshUpdateInterval) : undefined,
            });
        },
        [localDispatch]
    );

    const onApply = useMemo(
        () =>
            action(() => {
                if (store.state?.changes) {
                    store.state.currentAutoRefreshRate = state.config.defaultInterval;
                    store.state.changes.autoRefresh = state.config;
                    onClose();
                }
            }),
        [state.config, onClose, store]
    );

    useEffect(
        () =>
            autorun(() => {
                if (store.state?.changes !== undefined) {
                    localDispatch({
                        type: 'initialize',
                        config: store.state.changes.autoRefresh,
                    });
                }
            }),
        [localDispatch, store]
    );

    return (
        <PanelWithFooter
            isOpen={true}
            headerText={APP_STRINGS.forms.autoRefresh.title}
            isFooterAtBottom={true}
            isLightDismiss={true}
            // Panel bug workaround: https://github.com/microsoft/fluentui/issues/6476
            onOuterClick={noop}
            footer={
                <>
                    <PrimaryButton onClick={onApply}>{APP_STRINGS.utilButtons.apply}</PrimaryButton>
                    <DefaultButton onClick={onClose}>{APP_STRINGS.utilButtons.cancel}</DefaultButton>
                </>
            }
            onDismiss={onClose}
            closeButtonAriaLabel={APP_STRINGS.forms.autoRefresh.closePanelAriaLabel}
        >
            <div className={styles.autoRefresh}>
                <Toggle
                    label={APP_STRINGS.forms.autoRefresh.title}
                    checked={enabled}
                    onChange={setEnabled}
                    onText={APP_STRINGS.utilButtons.enable}
                    offText={APP_STRINGS.utilButtons.disable}
                />
                <RTDDropdown
                    onRenderLabel={onRenderMinIntervalLabel}
                    options={minOptions}
                    selectedKey={minInterval ?? null}
                    placeholder={APP_STRINGS.forms.autoRefresh.noInterval}
                    onChange={setMinInterval}
                    disabled={!enabled}
                    ariaLabel={APP_STRINGS.forms.autoRefresh.minInterval}
                    aria-describedby={autoRefreshTooltipProps.id}
                    // combobox only displays one item so that Narrator can announce it.
                    role="combobox"
                />
                <RTDDropdown
                    label={APP_STRINGS.forms.autoRefresh.default}
                    options={availableOptions}
                    selectedKey={defaultInterval ?? null}
                    placeholder={APP_STRINGS.autoRefresh.off}
                    onChange={setDefaultInterval}
                    disabled={!enabled}
                />
            </div>
        </PanelWithFooter>
    );
});

const onRenderMinIntervalLabel = (props: IDropdownProps | undefined) => (
    <LabelWithInfo
        text={APP_STRINGS.forms.autoRefresh.minInterval}
        infoText={APP_STRINGS.forms.autoRefresh.minIntervalInfo}
        disabled={props?.disabled}
        tooltipProps={autoRefreshTooltipProps}
    />
);

export const manageAutoRefreshAction = formActionMacro(AutoRefreshForm, 'autoRefresh');
