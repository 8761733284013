import * as React from 'react';
import { ITextFieldProps, TextField } from 'office-ui-fabric-react';
import { useState, useCallback, useEffect } from 'react';

interface ITextFieldWithControlledErrorProps
    extends Omit<
        ITextFieldProps,
        // Prevent overriding validation settings
        | 'errorMessage'
        | 'onGetErrorMessage'
        | 'validateOnFocusOut'
        | 'validateOnFocusIn'
        | 'validateOnLoad'
        | 'noValidate'
    > {
    error?: string;
}

export const TextFieldWithControlledError: React.FC<ITextFieldWithControlledErrorProps> = (props) => {
    const [showError, setShowError] = useState(false);

    const didValidate = useCallback(() => setShowError(true), []);

    useEffect(() => {
        if (props.value) {
            setShowError(true);
        }
    }, [props.value]);

    return (
        <TextField
            {...props}
            errorMessage={showError ? props.error : undefined}
            validateOnLoad={false}
            onNotifyValidationResult={didValidate}
        />
    );
};
