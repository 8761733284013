import { caseInsensitiveComparer } from '@kusto/common';
import { useEffect, useMemo, useRef } from 'react';

export function usePrevious<T>(value: T) {
    const ref = useRef<T | undefined>(undefined);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useMemoListToSortedDropdownItems(list: any[] = [], idKey: string, textKey: string) {
    return useMemo(() => {
        return list
            .map((item) => ({
                key: item[idKey],
                text: item[textKey],
            }))
            .sort((a, b) => caseInsensitiveComparer(a.text, b.text));
    }, [list, idKey, textKey]);
}
