import * as React from 'react';
import { useMemo, useCallback } from 'react';
import { DropdownMenuItemType, IDropdownOption } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { useDashboardStore } from '../../store/dashboard';
import { CustomElementDropdown, ICustomElementDropdownProps, RTDBackingDropdown, RTDDropdownOption } from '../fabric';
import { APP_CONSTANTS, APP_STRINGS } from '../../res';
import { useGlobalDispatch } from '../../store/redux/context';
import { promptNewDataSource } from '../../store/redux/actions';
import { UDataSource } from '../../core/domain';

import styles from './DataSourceDropdown.module.scss';

type DataSourceDropdownProps = Omit<
    ICustomElementDropdownProps,
    'options' | 'onChange' | 'selectedKey' | 'noSelectionTitle'
> & {
    dataSourceId?: string;
    onChange: (dataSourceId: string | undefined) => void;
};

const emptyOption: RTDDropdownOption<UDataSource> = {
    key: APP_CONSTANTS.emptySelection,
    text: APP_STRINGS.domain.parameter.selection.none,
    data: undefined,
};

const backingDropdown: RTDBackingDropdown = {
    type: 'standard',
};

/**
 * Disabled if dashboard props is undefined
 */
export const DataSourceDropdown: React.FC<DataSourceDropdownProps> = observer(function DataSourceDropdown({
    dataSourceId,
    onChange,
    disabled,
    ...passthroughProps
}) {
    const [formsDispatch] = useGlobalDispatch();
    const store = useDashboardStore();

    const dataSources = store.state?.dataSources;
    const options = useMemo(
        () => [
            emptyOption,
            ...(dataSources === undefined
                ? []
                : dataSources
                      .map((d) => ({ key: d.id, text: d.name, data: d }))
                      .sort((a, b) => a.text.localeCompare(b.text))),
            {
                key: APP_CONSTANTS.divider,
                text: '-',
                itemType: DropdownMenuItemType.Divider,
            },
            {
                key: APP_CONSTANTS.addSelection,
                text: APP_STRINGS.forms.dataSource.new,
            },
        ],
        [dataSources]
    );

    const onDropdownSelection = useCallback(
        async (_event: unknown, option?: IDropdownOption) => {
            const newId = option?.key as string;

            if (newId === APP_CONSTANTS.addSelection) {
                const newDataSource = store.state && (await promptNewDataSource(store.state, formsDispatch));
                if (newDataSource) {
                    onChange(newDataSource.id);
                }
            } else if (newId === APP_CONSTANTS.emptySelection) {
                onChange(undefined);
            } else {
                onChange(newId);
            }
        },
        [formsDispatch, onChange, store]
    );

    return (
        <CustomElementDropdown
            data-automation-id="data-source"
            id="dataSource"
            aria-label={APP_STRINGS.queryEditing.dataSourceDropdown.ariaLabel}
            className={styles.dataSourceDropdown}
            options={options}
            onChange={onDropdownSelection}
            disabled={store.state === undefined || disabled}
            selectedKey={dataSourceId ?? null}
            noSelectionTitle=""
            placeholder={APP_STRINGS.queryEditing.dataSourceDropdown.noSelectionTitle}
            backingDropdown={backingDropdown}
            {...passthroughProps}
        />
    );
});
