import { IDropdownStyles, IPalette } from 'office-ui-fabric-react';
import { cssVariableNames } from '../../../domain/theming/themes';

export const PILL_MAX_WIDTH = 256;
export const PILL_TITLE_PADDING_WIDTH = 8;
export const FONT_SIZE = 13;

export const dropdownStyles: Partial<IDropdownStyles> = {
    root: {
        selectors: {
            '.is-disabled': {
                selectors: {
                    // Required to prevent interaction states appearing on a small area where root is visible
                    ':hover .ms-Dropdown-title': {
                        borderColor: 'unset',
                    },
                    ':focus .ms-Dropdown-title': {
                        borderColor: 'unset',
                    },
                    ':active .ms-Dropdown-title': {
                        borderColor: 'unset',
                    },
                    '.ms-Dropdown-title': {
                        borderStyle: 'solid',
                        borderWidth: 1,
                        selectors: {
                            ':hover': {
                                borderColor: 'unset',
                            },
                            ':focus': {
                                borderColor: 'unset',
                            },
                            ':active': {
                                borderColor: 'unset',
                            },
                        },
                    },
                },
            },
        },
    },
    dropdown: {
        selectors: {
            ':focus::after': {
                borderRadius: 14,
            },
        },
    },
    title: {
        height: 24,
        lineHeight: 24,
        // Half of height
        borderRadius: 12,
        borderColor: cssVariableNames.gray60,
        paddingLeft: PILL_TITLE_PADDING_WIDTH,
        paddingRight: PILL_TITLE_PADDING_WIDTH,
        fontSize: FONT_SIZE,
    },
};

export const limitedWidthDropdownStyles: Partial<IDropdownStyles> = {
    root: {
        maxWidth: PILL_MAX_WIDTH,
    },
};

export const errorDropdownStyles = (palette?: IPalette): Partial<IDropdownStyles> => ({
    title: {
        borderColor: palette?.redDark,
    },
    dropdown: {
        selectors: {
            ':focus::after': {
                borderColor: palette?.redDark,
            },
        },
    },
});

export const optionsStyles = {
    root: {
        fontWeight: 600 as const,
        fontSize: FONT_SIZE,
    },
};
