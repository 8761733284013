import * as React from 'react';
import { useEffect, useContext, useState } from 'react';
import { assertUnreachable } from '../../common';

/**
 * This context is for keeping track of open modals so we know to either render
 * the global loading indicator on top of the nav or below it. If we were to
 * render the global loading indicator inside the nav while a modal is open, it
 * would be behind that modal.
 */

const globalLoadingRequests = React.createContext<[number, (_action: (_prev: number) => number) => void]>([
    0,
    () => {
        assertUnreachable('default context');
    },
]);

export const RequestGlobalLoadingProvider: React.FC = (props) => {
    const count = useState(0);
    return <globalLoadingRequests.Provider value={count}>{props.children}</globalLoadingRequests.Provider>;
};

export const useGlobalLoading = (enabled = true) => {
    const [, setCount] = useContext(globalLoadingRequests);
    useEffect(() => {
        if (!enabled) {
            return;
        }
        setCount((c) => c + 1);
        return () => setCount((c) => c - 1);
    }, [enabled, setCount]);
};

export const useGlobalLoadingStatus = () => {
    const [count] = useContext(globalLoadingRequests);
    return count !== 0;
};
