import * as React from 'react';
import { Stack, Icon, IDropdownStyles } from 'office-ui-fabric-react';

import { APP_CONSTANTS } from '../../../res';
import { APP_STRINGS } from '../../../res';

import { RTDDropdown, RTDDropdownProps } from './RTDDropdown';

import styles from './CustomElementDropdown.module.scss';

export interface ICustomElementDropdownProps extends RTDDropdownProps {
    noSelectionTitle: string;
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdownItems: {
        selectors: {
            '.ms-Button-flexContainer': {
                overflow: 'hidden',
            },
        },
    },
};

export const CustomElementDropdown: React.FC<ICustomElementDropdownProps> = ({ noSelectionTitle, ...props }) => {
    return (
        <RTDDropdown
            {...props}
            styles={dropdownStyles}
            onRenderOption={(option) => {
                if (!option || option.hidden) {
                    return null;
                }

                if (option.key === APP_CONSTANTS.emptySelection) {
                    return <span className={`${styles.text} ${styles.emptySelectionText}`}>{option.text}</span>;
                } else if (option.key === APP_CONSTANTS.addSelection) {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Icon iconName="Add" className={styles.addIcon} />
                            <span className={styles.text}>{option.text}</span>
                        </Stack>
                    );
                }

                return <span className={styles.text}>{option.text}</span>;
            }}
            onRenderTitle={(options) => {
                if (!options || options.length < 1) {
                    return null;
                }

                const option = options[0];

                if (option.key === APP_CONSTANTS.emptySelection) {
                    return <span className={styles.emptySelectionText}>{noSelectionTitle}</span>;
                } else if (option.key === APP_CONSTANTS.parameter.allSelection) {
                    return <>{APP_STRINGS.domain.parameter.selection.allTitle}</>;
                }

                return <>{options.map((o) => o.text).join(', ')}</>;
            }}
        />
    );
};
