import { assertNever } from 'office-ui-fabric-react';

import type { ParameterValue } from '../parameter';
import type { UQuery, QueryHash } from '../query';

import { IHashBuilder, XXHashBuilder } from './hashBuilder';

export const newQueryHash = (query: Omit<UQuery, 'queryHash'>, usedParameterValues: ParameterValue[]): QueryHash => {
    const hash: IHashBuilder = new XXHashBuilder();

    hash.add(query.query);

    hashDataSource(hash, query);
    if (usedParameterValues) {
        hashQueryParameters(hash, usedParameterValues);
    }

    return hash.toString();
};

const hashDataSource = (hash: IHashBuilder, query: Omit<UQuery, 'queryHash'>): void => {
    const dataSource = query.dataSource;
    hash.add(dataSource.kind);
    switch (dataSource.kind) {
        case 'manual-kusto':
            hash.add(dataSource.clusterUri);
            hash.add(dataSource.database);
            break;
        case 'log-analytics':
            hash.add(dataSource.subscription);
            hash.add(dataSource.resourceGroup);
            hash.add(dataSource.workspace);
            break;
        case 'app-insights':
            hash.add(dataSource.subscription);
            hash.add(dataSource.resourceGroup);
            hash.add(dataSource.application);
            break;
        default:
            assertNever(dataSource);
    }
};

const hashQueryParameters = (hash: IHashBuilder, parameters: ParameterValue[]): void => {
    const sorted = [...parameters];
    sorted.sort((a, b) => a.config.id.localeCompare(b.config.id));
    hash.add(sorted.map((p) => p.config.id));
    for (const parameter of sorted) {
        hash.add(JSON.stringify(parameter.data));
    }
};
