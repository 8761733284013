import { createContext } from 'react';

import type { IRtdDashboardsCore } from '../types';

export type DashboardCoreContextState =
    | {
          status: 'deferred';
          startInit: () => void;
      }
    | {
          status: 'ready';
          appContext: IRtdDashboardsCore;
      }
    | {
          status: 'error';
          error: Error;
      };

const uninitialized: DashboardCoreContextState = {
    status: 'error',
    error: new Error('RTD dashboards core provider not mounted'),
};

export const dashboardsCoreContext = createContext<DashboardCoreContextState>(uninitialized);
