import { IResourceError } from '../../resource/errors/types';
import { messageCodeErrorConfig, errorsTypeConfig } from '../../resource/errors/errorsConfig';
import { isResourceError } from '../../resource/errors/ResourceError';

export const isGlobalPopup = (resourceError: IResourceError<unknown>): boolean => {
    const typeConfig = errorsTypeConfig[resourceError.type];

    if (typeConfig.isGlobalPopup) {
        return true;
    }

    const config = messageCodeErrorConfig[resourceError.statusCode];

    if (config) {
        return config.isGlobalPopup;
    }

    return false;
};

export const buildErrorMessage = (resourceError: IResourceError<unknown>): string => {
    let errorMessage = '';

    let currentError: IResourceError<unknown> | Error | undefined = resourceError;
    while (currentError) {
        errorMessage += `${currentError.message}\n`;

        currentError = isResourceError(currentError) ? currentError.error : undefined;
    }

    return errorMessage;
};
