import React from 'react';
import { IDropdownOption } from 'office-ui-fabric-react';

import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { RTDDropdown } from '../../../components';
import { IEditParameterPageComponentProps } from '../types';
import { EppBasicParamOptions } from '../reducer';
import { BasicParam, RtdValue } from '../../../domain';

export interface ParameterDataTypeDropdownProps extends IEditParameterPageComponentProps {
    state: EppBasicParamOptions;
}

export const ParameterDataTypeDropdown: React.FC<ParameterDataTypeDropdownProps> = ({
    className,
    state,
    disabled,
    dispatch,
}) => {
    const onChange = React.useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (!option) {
                return;
            }

            dispatch({
                type: 'setBasicDataType',
                dataType: option.key as RtdValue.BasicType,
            });
        },
        [dispatch]
    );

    let selectionKind: BasicParam.SelectionKind;

    if (state.union.kind === 'freetext') {
        selectionKind = 'freetext';
    } else {
        selectionKind = state.union.selectionKind;
    }
    const supportedOptions = React.useMemo(() => {
        const supportedTypes = APP_CONSTANTS.parameter.selectionTypes[selectionKind];
        return Object.entries(APP_STRINGS.editParameterPage.basicValueDataDisplayText)
            .filter(([key]) => supportedTypes.includes(key as RtdValue.BasicType))
            .map(([key, text]) => ({ key, text }));
    }, [selectionKind]);

    if (supportedOptions.length < 1) {
        return null;
    }

    return (
        <RTDDropdown
            className={className}
            label={APP_STRINGS.editParameterPage.dataType}
            required={true}
            options={supportedOptions}
            selectedKey={state.dataType}
            onChange={onChange}
            disabled={disabled}
        />
    );
};
