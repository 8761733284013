import * as React from 'react';
import { useMemo } from 'react';

import { DropdownIconOption } from '../../../../../components/fabric/dropdown/dropdownIconRenderProps';
import { useVisualConfig } from '../../../../../domain';
import { assertUnreachable } from '../../../../../common';
import { APP_STRINGS } from '../../../../../res';

import { useETPSelector } from '../../../../../store/editTile';
import { visualAddedSelectorBuilder } from '../../../lib';

import { VisualTypePicker } from '../configUtils/VisualTypePicker';
import { ManagedConfigComponent } from '../types';

import styles from '../configUtils/styles.module.scss';

const visualTypeSelector = visualAddedSelectorBuilder((o) => o.visualType);

export const VisualTypeVariant: ManagedConfigComponent = ({ disabled }) => {
    const visualConfig = useVisualConfig();
    const visualType = useETPSelector(visualTypeSelector);

    const optionsMap = useMemo(
        () =>
            visualConfig.layout.reduce<Record<string, DropdownIconOption[]>>((acc, option) => {
                if (typeof option === 'object') {
                    const options = option.variants.map((variant) => {
                        const config = visualConfig.visualTypes[variant];
                        return {
                            key: variant,
                            text: config.label,
                            icon: config.iconName,
                        };
                    });
                    for (const variant of option.variants) {
                        acc[variant] = options;
                    }
                }
                return acc;
            }, {}),
        [visualConfig]
    );

    const options = optionsMap[visualType];

    if (options === undefined) {
        // This component should not get called if there are no variants of a visual
        // type. It should be filtered out along with unsupported visual options.
        assertUnreachable('No visual type variant options');
    }

    return (
        <VisualTypePicker
            className={styles.basicInput}
            selectedKey={visualType}
            options={options}
            label={APP_STRINGS.editTilePage.visualConfig.visualTypeVariableDropdownLabel}
            disabled={disabled}
        />
    );
};
