import React from 'react';
import { TooltipWrapper } from './TooltipWrapper';
import { ContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';

export interface ContextualMenuItemWithTooltipProps extends React.ComponentProps<typeof ContextualMenuItem> {}

export const ContextualMenuItemWithTooltip = ({ title, ...props }: ContextualMenuItemWithTooltipProps) => {
    return (
        <TooltipWrapper content={title} {...props.item.tooltipProps}>
            <ContextualMenuItem {...props}></ContextualMenuItem>
        </TooltipWrapper>
    );
};
