import type { Person, User, Group } from '@microsoft/microsoft-graph-types';
import {
    Client,
    ResponseType,
    BatchRequestContent,
    BatchRequestStep,
    BatchResponseContent,
    GraphError,
} from '@microsoft/microsoft-graph-client';
import LRUCache from 'lru-cache';
import { omit } from 'lodash';

import type { ITelemetryService } from '../backend';

import type { IGraphService, GraphImageSize, UserOrGroupResponse, TypedGraphEntityId } from './IGraphService';

interface GraphResponse<T> {
    '@odata.context': string;
    '@odata.nextLink': string;
    value: T;
}

export class GraphService implements IGraphService {
    private readonly imageCache: LRUCache<string, string>;
    private readonly userGroupCache: LRUCache<string, Person | User | Group>;

    constructor(private readonly telemetryService: ITelemetryService, private readonly graphClient: Client) {
        this.imageCache = new LRUCache({
            max: 1000000,
            length: (data) => data.length,
            maxAge: 1000 * 60 * 60,
            dispose: (_key, value) => window.URL.revokeObjectURL(value),
        });

        this.userGroupCache = new LRUCache({
            // Cache 1000 unique users/groups
            max: 1000,
            length: () => 1,
            maxAge: 1000 * 60 * 60,
        });
    }

    private createUserSelect(limit: number): string {
        return `$select=id,displayName,givenName,surname,userPrincipalName&$top=${limit}`;
    }

    private createUserSearchFilter(searchText: string, limit: number): string {
        const nameSearchString = `$filter=startswith(displayName,'${searchText}') or startswith(givenName,'${searchText}') or startswith(surname,'${searchText}') or startswith(mail,'${searchText}') or startswith(userPrincipalName,'${searchText}')`;

        return `${nameSearchString}&${this.createUserSelect(limit)}`;
    }

    private createGroupsSearchFilter(searchText: string, limit: number): string {
        const groupsSearchString = `$filter=startswith(displayName,'${searchText}') or startswith(mail,'${searchText}') or startswith(mailNickname,'${searchText}')`;
        const selectString = '$select=id,displayName,mail';
        const limitString = `$top=${limit}`;

        return `${groupsSearchString}&${selectString}&${limitString}`;
    }

    private cacheEntities(entities: Array<Person | User | Group>): void {
        for (const entity of entities) {
            if (entity.id) {
                this.userGroupCache.set(entity.id, entity);
            }
        }
    }

    async getRelatedPeople(limit = 20): Promise<Person[]> {
        const requestUrl = '/me/people';

        const filterString = `personType/class eq 'Person'`;
        const selectString = 'id,displayName,givenName,surname,userPrincipalName,personType';

        try {
            const response = (await this.graphClient
                .api(requestUrl)
                .filter(filterString)
                // We include personType, as the "person" could be a group
                .select(selectString)
                .top(limit)
                .get()) as GraphResponse<Person[]>;

            this.cacheEntities(response.value);
            return response.value;
        } catch (error) {
            this.logGraphRequestError(error, requestUrl, `$${filterString}&$${selectString}`);

            throw error;
        }
    }

    async searchRelatedPeople(searchText: string, limit = 20): Promise<Person[]> {
        if (searchText.trim().length < 1) {
            return [];
        }

        const requestUrl = '/me/people';

        const filterString = this.createUserSelect(limit);

        try {
            const response = (await this.graphClient
                .api(requestUrl)
                .search(searchText)
                .query(filterString)
                .get()) as GraphResponse<Person[]>;

            this.cacheEntities(response.value);
            return response.value;
        } catch (error) {
            this.logGraphRequestError(error, requestUrl, `$search=${searchText}&${filterString}`);

            throw error;
        }
    }

    async getUsersOrGroups(ids: TypedGraphEntityId[]): Promise<UserOrGroupResponse> {
        const userOrGroupResponses: UserOrGroupResponse = {};

        const batchRequestContent = new BatchRequestContent();

        const cachedItems = new Set<string>();
        // Get unique type ids so that we don't add requests and consume response for duplicate ids.
        // Making a request with duplicate id's will cause the api to respond with an error, and trying to
        // generate a response twice for an id will create an error.
        const indexed = new Set();
        const uniqueIds = ids.filter((id) => {
            const key = id.id + id.type;
            if (indexed.has(key)) {
                return false;
            }
            indexed.add(key);
            return true;
        });
        for (const typedId of uniqueIds) {
            const { id, type } = typedId;
            const cachedUserGroup = this.userGroupCache.get(id);

            if (cachedUserGroup) {
                cachedItems.add(id);

                userOrGroupResponses[id] = cachedUserGroup;

                continue;
            }

            if (type === 'user') {
                const searchUsersStep: BatchRequestStep = {
                    id: `${id}-users`,
                    request: new Request(`/users/${id}`, { method: 'GET' }),
                };

                batchRequestContent.addRequest(searchUsersStep);
            } else if (type === 'group') {
                const searchGroupsStep: BatchRequestStep = {
                    id: `${id}-groups`,
                    request: new Request(`/groups/${id}`, { method: 'GET' }),
                };

                batchRequestContent.addRequest(searchGroupsStep);
            }
        }

        if (batchRequestContent.requests.size < 1) {
            // BatchRequestContent throws if there are no requests. Short circuit to prevent this
            return userOrGroupResponses;
        }

        const requestUrl = '/$batch';

        const content = await batchRequestContent.getContent();
        const batchResponse = await this.graphClient.api(requestUrl).post(content);
        const batchResponseContent = new BatchResponseContent(batchResponse);

        const responses = uniqueIds.map(async (typedId) => {
            const { id, type } = typedId;
            if (cachedItems.has(id)) {
                return undefined;
            }

            let response: Response | undefined;

            switch (type) {
                case 'user': {
                    response = batchResponseContent.getResponseById(`${id}-users`);
                    break;
                }
                case 'group': {
                    response = batchResponseContent.getResponseById(`${id}-groups`);
                    break;
                }
            }

            if (response?.status !== 200) {
                // Not thrown, just logged
                await this.logBatchResponseError(requestUrl, `Could not fetch ${type}`, response);
                return undefined;
            }

            return await response.json();
        });

        const results = await Promise.all(responses);

        for (let i = 0; i < uniqueIds.length; i++) {
            const typedId = uniqueIds[i];
            const id = typedId.id;

            if (cachedItems.has(id)) {
                continue;
            }

            const response = results[i];

            userOrGroupResponses[id] = response;

            if (response) {
                this.userGroupCache.set(id, response);
            }
        }

        return userOrGroupResponses;
    }

    async searchUsers(searchText: string, limit = 20): Promise<User[]> {
        if (searchText.trim().length < 1) {
            return [];
        }

        const filterString = this.createUserSearchFilter(searchText, limit);

        const requestUrl = '/users';

        try {
            const response = (await this.graphClient.api('/users').query(filterString).get()) as GraphResponse<User[]>;

            this.cacheEntities(response.value);
            return response.value;
        } catch (error) {
            this.logGraphRequestError(error, requestUrl, filterString);

            throw error;
        }
    }

    async searchGroups(searchText: string, limit = 20): Promise<Group[]> {
        if (searchText.trim().length < 1) {
            return [];
        }

        const filterString = this.createGroupsSearchFilter(searchText, limit);

        const requestUrl = '/groups';

        try {
            const response = (await this.graphClient.api(requestUrl).query(filterString).get()) as GraphResponse<
                Group[]
            >;

            this.cacheEntities(response.value);
            return response.value;
        } catch (error) {
            this.logGraphRequestError(error, requestUrl, filterString);

            throw error;
        }
    }

    async searchUsersAndGroups(searchText: string, userLimit = 15, groupLimit = 5): Promise<Array<Person | Group>> {
        if (searchText.trim().length < 1) {
            return [];
        }

        const searchPeopleStep: BatchRequestStep = {
            id: 'people',
            request: new Request(`/me/people?$search="${searchText}"&${this.createUserSelect(userLimit)}`, {
                method: 'GET',
            }),
        };

        const searchUsersStep: BatchRequestStep = {
            id: 'users',
            request: new Request(`/users?${this.createUserSearchFilter(searchText, userLimit)}`, { method: 'GET' }),
        };

        const searchGroupsStep: BatchRequestStep = {
            id: 'groups',
            request: new Request(`/groups?${this.createGroupsSearchFilter(searchText, groupLimit)}`, { method: 'GET' }),
        };

        const batchRequestContent = new BatchRequestContent([searchPeopleStep, searchUsersStep, searchGroupsStep]);

        const requestUrl = '/$batch';

        let people: GraphResponse<Person[]> | undefined;
        let users: GraphResponse<User[]> | undefined;
        let groups: GraphResponse<Group[]> | undefined;

        try {
            const content = await batchRequestContent.getContent();
            const response = await this.graphClient.api(requestUrl).post(content);
            const batchResponseContent = new BatchResponseContent(response);

            [people, users, groups] = await Promise.all(
                ['people', 'users', 'groups'].map(async (responseId) => {
                    const res = batchResponseContent.getResponseById(responseId);

                    if (res.status !== 200) {
                        return undefined;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let json: GraphResponse<any[]> | undefined;

                    try {
                        json = await res.json();
                    } catch (e) {
                        if (e instanceof SyntaxError) {
                            // Below is a fix for Microsoft graph returning a `null`
                            // body when trying to list people. Looking at this docs, this
                            // appears to be invalid, so we're tracking the fix to
                            // allow it to be removed in the future if we believe it's no
                            // longer necessary.
                            //
                            // TODO: This should be a trace, not an event.
                            this.telemetryService.trackEvent(
                                'Bad microsoft graph list people response body',
                                {},
                                { requestUrl, response: res, error: e }
                            );
                            json = undefined;
                        } else {
                            // Should be impossible
                            throw e;
                        }
                    }

                    if (json === undefined) {
                        await this.logBatchResponseError(requestUrl, `Could not fetch ${responseId}`, res);
                    }

                    return json;
                })
            );

            if (people === undefined && users === undefined && groups === undefined) {
                // Only throw if no data was received
                throw new Error('Could not search people, users, or groups');
            }
        } catch (error) {
            this.logRequestError(error, requestUrl);

            throw error;
        }

        const peopleIds = new Set(people?.value.map((p) => p.id));

        const usersWithoutPeople = users?.value.filter((u) => !peopleIds.has(u.id)) ?? [];

        const results = [...(people?.value ?? []), ...usersWithoutPeople, ...(groups?.value ?? [])];

        this.cacheEntities(results);

        return results;
    }

    async getUserImage(pid: string, size: GraphImageSize = '48x48'): Promise<string> {
        const cachedImage = this.imageCache.get(pid);

        if (cachedImage) {
            return cachedImage;
        }

        const requestUrl = `users/${pid}/photos/${size}/$value`;

        try {
            const imageBlob: string = await this.graphClient
                .api(requestUrl)
                .header('Cache-Control', 'no-cache')
                .responseType(ResponseType.BLOB)
                .get();

            const imageUrl = window.URL.createObjectURL(imageBlob);

            this.imageCache.set(pid, imageUrl);

            return imageUrl;
        } catch (error) {
            this.logGraphRequestError(error, requestUrl);

            throw error;
        }
    }

    private async logBatchResponseError(requestUrl: string, message: string, response: Response) {
        const statusCode = response.status;
        if (statusCode === 200 || statusCode === 404) {
            // We don't care about success or not found
            return;
        }

        this.logRequestError(new Error(message), requestUrl, undefined, {
            statusCode,
            body: await response.body,
        });
    }

    private logGraphRequestError(error: unknown, requestUrl: string, queryString?: string | undefined) {
        if (!isGraphError(error)) {
            // Not GraphError. Log normally
            this.logRequestError(error as Error, requestUrl, queryString);
            return;
        }

        const graphError = error as GraphError;

        if (graphError.statusCode === 200 || graphError.statusCode === 404) {
            // We don't care about success or not found
            return;
        }

        // GraphError isn't a real error, so wrap it in a real error
        this.logRequestError(
            new Error(graphError.message !== null ? graphError.message : undefined),
            requestUrl,
            queryString,
            // Drop `message` since it's present in the error itself
            omit(graphError, ['message'])
        );
    }

    private logRequestError(
        error: Error,
        requestUrl: string,
        queryString?: string,
        additionalPayload?: Record<string, unknown>
    ) {
        this.telemetryService.trackException(
            error,
            {},
            {
                ...additionalPayload,
                requestUrl,
                queryString,
            }
        );
    }
}

const isGraphError = (error: unknown): error is GraphError => (error as GraphError).statusCode !== undefined;
