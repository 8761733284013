import * as React from 'react';
import { Dispatch, useState } from 'react';
import { Icon, Text, Stack, Link } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';

import { DataSourceDropdown } from '../../../../../../components/QueryEditing';
import { APP_CONSTANTS, APP_STRINGS } from '../../../../../../res';
import { useDashboardStore } from '../../../../../../store/dashboard';
import { RtdValue, StreamQueryResult } from '../../../../../../domain';
import { ParameterFilteredByList } from '../../../../../../components/parameter/components/ParameterFilteredByList';
import { EppQueryDropdownOptions, EppAction } from '../../../../reducer';

import { QueryPanel } from '../QueryPanel/QueryPanel';

import pageStyles from '../../../../EditParameterPage.module.scss';

import { LabelColumnDropdown, ValueColumnDropdown } from './ColumnSelectors';
import { DisplayQueryResult } from './DisplayQueryResult';

import styles from './QueryDataSourceConfig.module.scss';

const formatResultCounts = (displayed: number, total: number) => {
    const strings = APP_STRINGS.editParameterPage.recordTicker.result;
    return `${strings.start} ${displayed} ${strings.middle} ${total} ${strings.end}`;
};

const RecordTicker: React.FC<{ result: StreamQueryResult }> = ({ result }) => {
    const display =
        result.kind === 'success' && result.result.rows.length !== 0
            ? {
                  type: 'results' as const,
                  displayedRows: Math.min(
                      result.result.rows.length,
                      APP_CONSTANTS.editParameterPage.queryVisualMaxRecordsShown
                  ),
                  totalRows: result.result.rows.length,
              }
            : { type: 'noResults' as const };
    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Icon
                className={display.type === 'noResults' ? styles.noResults : styles.iconResults}
                iconName="NumberField"
            />
            <Text className={display.type === 'noResults' ? styles.noResults : undefined}>
                {display.type === 'results'
                    ? formatResultCounts(display.displayedRows, display.totalRows)
                    : APP_STRINGS.editParameterPage.recordTicker.noRecords}
            </Text>
        </Stack>
    );
};

interface ParameterQueryValueFormProps {
    dataSource: EppQueryDropdownOptions;
    parameterId: string;
    queryStatus: StreamQueryResult;
    parameterType: RtdValue.BasicType;

    dispatch: Dispatch<EppAction>;
}

export const ParameterQueryValueForm: React.FC<ParameterQueryValueFormProps> = observer(function ParameterValueForm({
    dataSource,
    queryStatus,
    parameterId,
    parameterType,
    dispatch,
}) {
    const [queryPanelOpen, setQueryPanelOpen] = useState(false);

    const dashboardStore = useDashboardStore();

    const { dataSourceId } = dataSource;
    const selectedDataSource = dataSourceId && dashboardStore.state?.dataSourcesRecord?.[dataSourceId];

    const { onQueryPanelCancel, onQueryPanelDone, openQueryPanel, onDataSourceChange } = React.useMemo(
        () => ({
            onQueryPanelCancel: () => setQueryPanelOpen(false),
            onQueryPanelDone: (query: string, activeParameters: Set<string>) => {
                setQueryPanelOpen(false);
                dispatch({
                    type: 'updateQueryDataSource',
                    changes: { query, consumedVariables: activeParameters },
                });
            },
            openQueryPanel: () => setQueryPanelOpen(true),

            onDataSourceChange: (newDataSourceId: string | undefined) =>
                dispatch({
                    type: 'updateQueryDataSource',
                    changes: { dataSourceId: newDataSourceId },
                }),
        }),
        [dispatch]
    );

    return (
        <>
            {queryPanelOpen && selectedDataSource && dashboardStore.state && (
                <QueryPanel
                    telemetryComponentName="EditParameterPage"
                    dashboardLoaded={dashboardStore.state}
                    initialQueryText={dataSource.query}
                    initialActiveParameters={dataSource.consumedVariables}
                    dataSource={selectedDataSource}
                    currentParameterId={parameterId}
                    onDone={onQueryPanelDone}
                    onCancel={onQueryPanelCancel}
                />
            )}
            <div className={pageStyles.input}>
                <DataSourceDropdown
                    required
                    label={APP_STRINGS.editParameterPage.queryDataSourceDropdownLabel}
                    dataSourceId={dataSourceId}
                    onChange={onDataSourceChange}
                />
            </div>
            <Stack className={pageStyles.input} tokens={{ childrenGap: 8 }}>
                <Stack className={styles.queryResultsLabel} horizontal tokens={{ childrenGap: 8 }}>
                    <Text>{APP_STRINGS.editParameterPage.queryResultsLabel}</Text>
                    <Link onClick={openQueryPanel} disabled={dataSource.dataSourceId === undefined}>
                        {dataSource.query === undefined
                            ? APP_STRINGS.editParameterPage.buttons.addQuery
                            : APP_STRINGS.editParameterPage.buttons.editQuery}
                    </Link>
                </Stack>
                <ParameterFilteredByList activeParentParameters={dataSource.consumedVariables} />
                <DisplayQueryResult result={queryStatus} />
                <RecordTicker result={queryStatus} />
            </Stack>
            <ValueColumnDropdown
                dispatch={dispatch}
                result={queryStatus}
                dataSource={dataSource}
                parameterType={parameterType}
            />
            <LabelColumnDropdown dispatch={dispatch} result={queryStatus} dataSource={dataSource} />
        </>
    );
});
