import * as React from 'react';
import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { assertNever } from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';
import { action, runInAction } from 'mobx';
import { useHistory } from 'react-router-dom';

import { PageContent, IPageContentProps } from '../../../components';
import { APP_STRINGS } from '../../../res';
import {
    useDashboardBreadcrumbs,
    useVisualConfig,
    ITile,
    createCommandBarShareItems,
    createCommandBarRefreshItem,
    RouteState,
    VisualConfig,
} from '../../../domain';
import { useGlobalDispatch } from '../../../store/redux';
import { IRtdDashboardsCore, useCore } from '../../../core';
import { ETPState, useETPDispatch, useETPSelectors } from '../../../store/editTile';
import { DashboardStore, useDashboardStore } from '../../../store/dashboard';

import { applyStateToExistingTile, createNewTileFromState, useFlush } from '../lib';
import { FilteredTileId } from '../types';

import styles from '../styles.module.scss';

function useETPBlockNav(
    core: IRtdDashboardsCore,
    dashboardStore: DashboardStore,
    visualConfig: VisualConfig,
    tileId: undefined | string,
    getState: () => ETPState
) {
    React.useEffect(() => {
        return core.history.block(() =>
            runInAction((): void | string => {
                const innerState = getState();
                const dashboardState = dashboardStore.state;
                if (innerState.type === 'error' || innerState.type === 'loading' || dashboardState === undefined) {
                    return;
                }
                // Tile is new
                if (tileId === undefined) {
                    return APP_STRINGS.edits.unsavedChanges;
                }
                const originalTile = dashboardState.tilesRecord[tileId];
                // If the original tile cannot be found then the tile has been deleted
                //   from the store
                if (originalTile === undefined) {
                    return;
                }
                const newTile = applyStateToExistingTile(innerState, originalTile, visualConfig.visualTypes);
                return tilesHaveDifferences(newTile, originalTile) ? APP_STRINGS.edits.unsavedChanges : undefined;
            })
        );
    }, [core, dashboardStore, getState, tileId, visualConfig]);
}

const tilesHaveDifferences = (a: ITile, b: ITile) => !isEqual(a, b);

interface ETPPageContentProps {
    dashboardId: string;
    tileId: FilteredTileId;
}

const pageContentSelectors = {
    doneDisabled: (state: ETPState) => {
        switch (state.type) {
            case 'query':
                return state.title.trim() === '' || state.query.trim() === '' || state.dataSourceId === undefined;
            case 'markdown':
                return !state.title.trim();
            case 'loading':
            case 'error':
                return true;
            default:
                assertNever(state);
        }
    },

    title: (state: ETPState) => {
        switch (state.type) {
            case 'markdown':
            case 'query':
                return state.title;
            case 'loading':
            case 'error':
                return APP_STRINGS.editTilePage.pageContent.titleUnknown;
            default:
                assertNever(state);
        }
    },
};

export const ETPPageContent: React.FC<ETPPageContentProps> = observer(function ETPPageContent({
    children,
    dashboardId,
    tileId,
}) {
    const core = useCore();
    const history = useHistory<RouteState>();
    const visualConfig = useVisualConfig();
    const [reduxDispatch] = useGlobalDispatch();
    const flush = useFlush();
    const [dispatch, getState] = useETPDispatch();
    const store = useDashboardStore();
    const { doneDisabled, title } = useETPSelectors(pageContentSelectors);

    const breadcrumb = useDashboardBreadcrumbs({
        store,
        dashboardId,
        allowEditingLastItem: {
            onChange: (newTitle) => dispatch({ type: 'updateTitle', title: newTitle }),
            inputTitle: APP_STRINGS.editTilePage.pageContent.tileTitleInputTitle,
        },
        additionalItems: [{ key: 'tile', text: title }],
    });

    const dashboardTitle = store.state?.title ?? '';

    const commandBar: IPageContentProps['commandBar'] = useMemo(() => {
        const onLeaveEditTilePage = action((focusTile?: ITile) => {
            // Reset dashboard page so we don't get unsaved changes prompt
            dispatch({ type: 'reset' });
            if (focusTile) {
                history.push(`${core.navUtil.path.dashboard(dashboardId)}#${focusTile.pageId}`);
                store.pendingScrollToTile = focusTile.id;
            } else {
                history.push(core.navUtil.path.dashboard(dashboardId));
            }
        });
        // We want to exit, but we may have a changes in monaco editor that aren't propagated to editor state yet.
        // Thus we're flushing any 'dirty' changes to query editor, and using a thunk to pull this value (since we don't
        // have ticks to wait for state to get applied in the proper channels through the reducer).
        //
        // The async function is wrapped so we don't return a promise to make
        // office-ui-fabric types happy
        const onDone = () => {
            (async () => {
                const innerPageState = getState();
                const dashboardState = runInAction(() => store.state);
                if (
                    innerPageState.type === 'loading' ||
                    innerPageState.type === 'error' ||
                    dashboardState === undefined
                ) {
                    return;
                }
                await flush();

                const scrollToTile = runInAction((): ITile | undefined => {
                    if (innerPageState.type === 'query' && innerPageState.initialQueryHash) {
                        core.queryService.deregisterQuery(innerPageState.initialQueryHash);
                    }

                    if (tileId === undefined) {
                        let pageId = history.location.state?.newTilePageId;
                        if (pageId === undefined) {
                            // TODO #7180081: Update to use logging/error service
                            // eslint-disable-next-line no-console
                            console.warn('Expected newTilePageId in route state but got undefined');
                            pageId = dashboardState.pagesNav.layout[0];
                        }

                        const tile = createNewTileFromState(
                            innerPageState,
                            visualConfig,
                            dashboardState.tilesLayout[pageId],
                            pageId
                        );
                        dashboardState.addItem('tiles', tile);
                        return tile;
                    } else {
                        const originalTile = dashboardState.tilesRecord[tileId];
                        // If the original tile cannot be found then the tile has been deleted
                        //   from the store
                        if (originalTile) {
                            const tile = applyStateToExistingTile(
                                innerPageState,
                                originalTile,
                                visualConfig.visualTypes
                            );
                            if (tilesHaveDifferences(tile, originalTile)) {
                                if (store.state !== undefined) {
                                    store.state.addItem('tiles', tile);
                                    return tile;
                                }
                            }
                        }
                        return undefined;
                    }
                });

                onLeaveEditTilePage(scrollToTile);
            })();
        };

        return {
            items: [],
            farItems: [
                {
                    key: 'save',
                    name: APP_STRINGS.editTilePage.pageContent.doneEditing,
                    iconProps: {
                        iconName: 'Save',
                    },
                    onClick: onDone,
                    disabled: doneDisabled,
                    role: 'button',
                },
                {
                    key: 'discard',
                    name: APP_STRINGS.utilButtons.discardChanges,
                    iconProps: { iconName: 'Cancel' },
                    onClick: () => onLeaveEditTilePage(),
                    role: 'button',
                },
                // Null coalesce is safe, as we disable if the dashboard does not exist
                ...createCommandBarShareItems(dashboardId, dashboardTitle, !dashboardId, reduxDispatch),
                createCommandBarRefreshItem(false, () => dispatch({ type: 'runQuery' })),
            ],
        };
    }, [
        doneDisabled,
        dashboardId,
        dashboardTitle,
        reduxDispatch,
        getState,
        flush,
        tileId,
        store,
        core,
        visualConfig,
        dispatch,
        history,
    ]);

    useETPBlockNav(core, store, visualConfig, tileId, getState);

    return (
        <div className={styles.pageWrapper}>
            <PageContent
                breadcrumbs={breadcrumb}
                commandBar={commandBar}
                contentWrapperClassName={styles.contentWrapper}
                breadcrumbClassName={styles.pageContentBreadcrumb}
            >
                {children}
            </PageContent>
        </div>
    );
},
{});
