import React from 'react';
import { matchPath } from 'react-router-dom';
import * as mobx from 'mobx';
import * as H from 'history';

import { APP_STRINGS } from '../res';
import { ETPState, useDashboardStore, useETPDispatch, useETPSelector } from '../store';
import { dashboardsCoreContext } from '../core/react/context';

export interface DashboardsBreadcrumb {
    key: string;
    href: string;
    /**
     * Loading while undefined
     */
    text?: string;
    /**
     * Not editable if undefined
     */
    onChange?: (text: string) => void;
}

export interface BreadcrumbContextValue {
    breadcrumbs: DashboardsBreadcrumb[];
    history: undefined | H.History;
}

function tileNameSelector(s: ETPState) {
    if (s.type === 'loading' || s.type === 'error') {
        return undefined;
    }
    return s.title;
}

export const useDashboardsBreadcrumbInfo = (): BreadcrumbContextValue => {
    const coreContext = React.useContext(dashboardsCoreContext);
    const core = coreContext.status === 'ready' ? coreContext.appContext : undefined;
    const dashboardStore = useDashboardStore();
    const tileName = useETPSelector(tileNameSelector);
    const [etpDispatch] = useETPDispatch();
    // History and location pulled from dashboards core because we may not be
    // inside of the dashboards react-router context
    const [history, setHistory] = React.useState(() => mobx.runInAction(() => core?.history.h.get()));
    const [location, setLocation] = React.useState(history?.location);
    const [dashboardTitle, setDashboardTitle] = React.useState(() =>
        mobx.runInAction(() => dashboardStore.state?.title)
    );
    const [editingDashboard, setEditingDashboard] = React.useState(() =>
        mobx.runInAction(() => dashboardStore.state?.changes !== undefined)
    );

    React.useEffect(() => {
        const titleDispose = mobx.autorun(() => setDashboardTitle(dashboardStore.state?.title));
        const editingDispose = mobx.autorun(() => setEditingDashboard(dashboardStore.state?.changes !== undefined));

        return () => {
            titleDispose();
            editingDispose();
        };
    }, [dashboardStore]);

    React.useEffect(() => {
        let hDispose: undefined | H.UnregisterCallback;
        const mDispose = mobx.autorun(() => {
            const h = core?.history.h.get();
            setHistory(h);
            setLocation(h?.location);
            hDispose?.();
            hDispose = h?.listen(setLocation);
        });
        return () => {
            hDispose?.();
            mDispose();
        };
    }, [core]);

    const breadcrumbs = React.useMemo(() => {
        if (!core) {
            return [];
        }
        const breadcrumb: DashboardsBreadcrumb[] = [
            {
                key: 'allDashboards',
                href: core.navUtil.path.catalog,
                text: APP_STRINGS.breadcrumbs.catalog,
            },
        ];
        if (!history || !location) {
            return breadcrumb;
        }

        const dashboardsPath = matchPath<{ dashboardId: string; tileId: string }>(location.pathname, {
            path: core.navUtil.path.dashboard(':dashboardId'),
            strict: true,
        });

        const dashboardId = dashboardsPath?.params.dashboardId;

        const tilePath = matchPath<{ tileId: string }>(location.pathname, {
            path: core.navUtil.path.editTile(':dashboardId', ':tileId'),
            strict: true,
        });

        const tileId = tilePath?.params.tileId;

        if (!dashboardId) {
            return breadcrumb;
        }

        const dashboardBreadcrumb = {
            key: 'dashboard',
            href: history.createHref(
                H.createLocation({
                    ...location,
                    pathname: core.navUtil.path.dashboard(dashboardId),
                })
            ),
            text: dashboardTitle,
            onChange:
                !editingDashboard || tileId
                    ? undefined
                    : mobx.action((title: string) => {
                          if (dashboardStore.state?.changes) {
                              dashboardStore.state.changes.title = title;
                          }
                      }),
        };

        breadcrumb.push(dashboardBreadcrumb);

        // If dashboard breadcrumb is still loading, don't add the tile breadcrumb
        if (dashboardBreadcrumb.text === undefined) {
            return breadcrumb;
        }

        if (!tileId) {
            return breadcrumb;
        }

        breadcrumb.push({
            key: 'tile',
            href: history.createHref(
                H.createLocation({
                    ...location,
                    pathname: core.navUtil.path.editTile(dashboardId, tileId),
                })
            ),
            text: tileName,
            onChange: (title: string) => etpDispatch({ type: 'updateTitle', title }),
        });

        return breadcrumb;
    }, [core, history, location, dashboardTitle, editingDashboard, tileName, dashboardStore, etpDispatch]);

    return { breadcrumbs, history };
};
