import { RowDataType } from '../../../stores/connectionPane/RowDataType';
import { EntityType, Action, Icon } from '../../../common/types';
import { Database, Cluster } from '../../../stores/cluster';

export abstract class RowDataTypeBase implements RowDataType {
    public id: string;
    public pathFromRoot: string[];
    public entityType: EntityType;
    public baseData: Cluster | Database;
    public icon: Icon;
    public actions: Action[] = [];
    public details?: string;
    public info?: string;
    public isFavorite?: boolean;

    public childrenLoader?: (base: RowDataType) => RowDataType[];

    constructor(id: string, pathFromRoot: string[], entityType: EntityType, baseData: Cluster | Database, icon: Icon) {
        this.id = id;
        this.pathFromRoot = pathFromRoot;
        this.entityType = entityType;
        this.baseData = baseData;
        this.icon = icon;
    }
}
