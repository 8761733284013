import { ArgumentColumnType, DataItem, enhanceDataWithAnomalyDataFromColumns } from '@kusto/charting';
import * as Highcharts from 'highcharts';
import { orderBy, sortBy } from 'lodash';
import { assertNever } from 'office-ui-fabric-react/lib/Utilities';
import { YAxisConfig } from '@rtd/dashboard/src/domain';

import { InternalChartProps } from '../@types/chart.types';
import { convertXAxisToNumber, formatMillisecondsToTimeString, isArgumentNumeric } from '../utils/charting';
import { darkTheme } from './theme';

const referenceLinesColor = '#57A300';

interface PointOptionsObject extends Highcharts.PointOptionsObject {
    x?: number;
    y: number;
    name?: string;
    custom: { dataItem: DataItem };
}

export const setLocale = (locale: string) => {
    const parts = new Intl.NumberFormat(locale).formatToParts(1000.1);
    const decimalPoint = parts.find((p) => p.type === 'decimal')?.value;
    const thousandsSep = parts.find((p) => p.type === 'group')?.value;
    if (decimalPoint && thousandsSep) {
        // This does not get properly applied when it's mixed in with the options below
        Highcharts.setOptions({ lang: { decimalPoint, thousandsSep } });
    }
};

/**
 * Convert Kusto chart props to highcharts options format.
 * @param props Chart props
 */
export const convertToHighchart = (props: InternalChartProps): Highcharts.Options => {
    const { onClickPoint, onClickLegend } = props;
    const { Visualization } = props.visualizationOptions;

    let dataItems = props.dataItems;

    // Sort by x axis if it's numeric.
    const numericArgument = isArgumentNumeric(props.argumentType!);
    const shouldSortNumericData =
        !props.visualizationOptions.IsQuerySorted || // IsQuerySorted - ignore original sort as it might include labels and other props except the time
        Visualization === 'timechart' ||
        Visualization === 'anomalychart';
    if (numericArgument && shouldSortNumericData) {
        dataItems = sortBy(dataItems, (item) => convertXAxisToNumber(item, props.argumentType!));
    }

    // if this is an anomaly chart we need to massage the data a bit.
    if (Visualization === 'anomalychart' && props.visualizationOptions.AnomalyColumns) {
        dataItems = enhanceDataWithAnomalyDataFromColumns(dataItems, props.visualizationOptions.AnomalyColumns);
    }

    // We do not support all visualization options currently.
    if (
        Visualization === null ||
        Visualization === 'ladderchart' ||
        Visualization === 'timeline' ||
        Visualization === 'pivotchart' ||
        Visualization === 'card' ||
        Visualization === 'table' ||
        Visualization === 'timepivot' ||
        Visualization === 'multistat'
    ) {
        throw new Error('unsupported visualization type ' + Visualization);
    }

    // Highcharts likes its data grouped by series rather than a flat array of data points.
    // We also take this opportunity to figure out which Argument variable to access  based on chart / data type.
    const groupBySeries = (sortBy<DataItem[]>(dataItems, (item) => (item as DataItem).SeriesName) as DataItem[]).reduce<
        Record<string, PointOptionsObject[]>
    >((prev, dataItem) => {
        let x: undefined | number;
        let y: number;
        let name: undefined | string;
        switch (props.argumentType) {
            case ArgumentColumnType.TimeSpan:
                const milliseconds = (dataItem.ArgumentDateTime as any).ticks.div(1e4).toNumber();
                const timeString = formatMillisecondsToTimeString(milliseconds);
                x = timeString as unknown as number;
                y = dataItem.ValueData;
                break;
            case ArgumentColumnType.DateTime:
                x = (dataItem.ArgumentDateTime as any).getTime();
                y = dataItem.ValueData;
                break;
            case ArgumentColumnType.Numeric:
                x = dataItem.ArgumentNumeric;
                y = dataItem.ValueData;
                break;
            default:
                y = dataItem.ValueData;
                name = dataItem.ArgumentData ?? '';
        }

        const seriesName = dataItem.SeriesName ?? '';
        let seriesList = prev[seriesName];
        if (seriesList === undefined) {
            seriesList = [];
            prev[seriesName] = seriesList;
        }

        seriesList.push({ x, name, y, custom: { dataItem } });

        return prev;
    }, {});

    const seriesNames = Object.keys(groupBySeries);

    let chartType: 'bar' | 'column' | 'line' | 'pie' | 'area' | 'scatter' = 'bar';
    switch (Visualization) {
        case 'areachart':
        case 'stackedareachart':
            chartType = 'area';
            break;
        case 'barchart':
            chartType = 'bar';
            break;
        case 'columnchart':
            chartType = 'column';
            break;
        case 'piechart':
            chartType = 'pie';
            break;
        case 'scatterchart':
            chartType = 'scatter';
            break;
        case 'timechart':
        case 'linechart':
        case 'anomalychart':
            chartType = 'line';
            break;
        default:
            assertNever(Visualization);
    }

    const pieChartDataWithColors = calculatePieChartDataWithColors(chartType, seriesNames, groupBySeries, props.colors);

    const xAxisType: Highcharts.AxisTypeValue =
        props.visualizationOptions.XAxis === 'log'
            ? 'logarithmic'
            : props.argumentType === ArgumentColumnType.DateTime
            ? 'datetime'
            : props.argumentType === ArgumentColumnType.Numeric
            ? 'linear'
            : 'category';

    const mainYAxis = props.visualizationOptions.MultipleYAxes?.base;
    const additionalYAxes = props.visualizationOptions.MultipleYAxes?.additional;

    const verticalLineValue = getVerticalLineValue(props.visualizationOptions.VerticalLine, props.argumentType);
    const horizontalLineValue = parseFloat(mainYAxis?.horizontalLine ?? '');
    const yAxisPlotLinesConfig = getYAxisPlotLinesConfig(
        horizontalLineValue,
        props.visualizationOptions.HorizontalLine
    );
    const xAxisPlotLinesConfig = getXAxisPlotLinesConfig(verticalLineValue, props.visualizationOptions.VerticalLine);

    const mainYAxisConfig: Highcharts.YAxisOptions = {
        ...getYAxisChartingConfig(props, mainYAxis),
        opposite: false,
        title: { text: mainYAxis?.label } ?? undefined,
        plotLines: yAxisPlotLinesConfig,
    };
    // Filter out yAxes that are not selected in YColumns, and filter out the base yaxis
    const filteredMultipleYAxes =
        additionalYAxes && props.visualizationOptions.YColumns
            ? additionalYAxes.filter((yAxis) => {
                  if (yAxis.columns.length > 0) {
                      // Currently supporting 1 column for each axis
                      const yAxisColumn = yAxis.columns[0];
                      // Check if the column in the yaxis i also selected in the y columns data
                      const isYColumnSelected =
                          props.visualizationOptions.YColumns!.findIndex((colName) => colName === yAxisColumn) > -1;
                      return isYColumnSelected;
                  }
                  return false;
              })
            : [];

    // Mapping an axis to its column name.
    const mappedYAxesColumnNames = mapYAxesToColumnName(filteredMultipleYAxes);

    const additionalYAxesConfig: Highcharts.YAxisOptions[] = filteredMultipleYAxes.map((yAxis) => {
        const text = yAxis.label === '' ? mappedYAxesColumnNames[yAxis.id] : yAxis.label;
        return {
            ...getYAxisChartingConfig(props, yAxis),
            title: { text },
            opposite: true,
        };
    });

    // The order is important! the first yAxisConfig should remain the main one.
    const allYAxisConfig: Highcharts.YAxisOptions[] = props.visualizationOptions.YSplit
        ? buildYSplitAxesConfig(seriesNames, props)
        : [mainYAxisConfig, ...additionalYAxesConfig].map((axis) =>
              Highcharts.merge(axis, props.isDarkTheme ? darkTheme.yAxis : undefined)
          );

    // building the actual object now that we converted all the relevant parts.
    const options: Highcharts.Options = {
        boost: { enabled: !!props.highPerformanceMode, seriesThreshold: 1 },
        chart: {
            backgroundColor: 'none',
            style: {
                fontFamily: 'Segoe UI',
            },
        },
        plotOptions: {
            pie: {
                dataLabels: { enabled: true, format: '{point.name}<br>{point.percentage:.1f} %' },
                colors: pieChartDataWithColors?.map((x) => x.color),
            },
            series: {
                allowPointSelect: false,
                turboThreshold: 5000,
                animation:
                    props.animationDuration === 0
                        ? false
                        : {
                              duration: props.animationDuration,
                          },
                stacking:
                    props.visualizationOptions.Kind === 'stacked'
                        ? 'normal'
                        : props.visualizationOptions.Kind === 'stacked100'
                        ? 'percent'
                        : undefined,
                events: {
                    legendItemClick:
                        onClickLegend &&
                        ((event) => {
                            event.preventDefault();
                            onClickLegend((event.target.data[0].options as PointOptionsObject).custom.dataItem);
                        }),
                    click:
                        onClickPoint &&
                        ((event: Highcharts.SeriesClickEventObject) =>
                            onClickPoint(event.point.options.custom!.dataItem)),
                    ...(allYAxisConfig.length > 1 && {
                        mouseOver: function () {
                            this.yAxis.update({
                                labels: {
                                    style: {
                                        fontWeight: 'bold',
                                        color: props.isDarkTheme ? 'white' : 'black',
                                    },
                                },
                                title: {
                                    style: { fontWeight: 'bold', color: props.isDarkTheme ? 'white' : 'black' },
                                },
                            });
                        },
                        mouseOut: function () {
                            this.yAxis.update({
                                labels: {
                                    style: {
                                        fontWeight: 'normal',
                                        color: props.isDarkTheme ? '#A19F9D' : '#666666',
                                    },
                                },
                                title: {
                                    style: {
                                        fontWeight: 'normal',
                                        color: props.isDarkTheme ? '#A19F9D' : '#666666',
                                    },
                                },
                            });
                        },
                    }),
                },
            },
        },
        series: seriesNames.map((seriesName, index) => {
            // anomalies should be shown as markers on an existing series thus they should be a scatter plot.
            const isAnomalyColumn = seriesName.includes('(anomaly)');
            // Add 1 to the yAxisIndex because the first index in the "allYAxisConfig" is the main one.
            const yAxisIndex =
                additionalYAxesConfig.length > 0
                    ? getYAxisIndex(filteredMultipleYAxes, mappedYAxesColumnNames, seriesName) + 1
                    : 0;
            return {
                name: seriesName,
                type: isAnomalyColumn ? 'scatter' : chartType,
                yAxis: props.visualizationOptions.YSplit === 'axes' ? index : yAxisIndex,
                data:
                    // For pie charts, we want to show largest slice thus we need to sort the data by value.
                    // if there's only one series (which today is the only supported option), it's already sorted in pieChartDataWithColors
                    chartType === 'pie'
                        ? pieChartDataWithColors
                            ? pieChartDataWithColors.map((x) => x.item)
                            : sortBy(groupBySeries[seriesName], (item) => item.x)
                        : groupBySeries[seriesName],
                color: isAnomalyColumn ? 'red' : undefined,
            } as Highcharts.SeriesOptionsType;
        }),
        xAxis: {
            type: xAxisType,
            title: { text: props.visualizationOptions.XTitle } ?? undefined,
            plotLines: xAxisPlotLinesConfig,
        },
        yAxis: allYAxisConfig,
        colors: props.colors,
        legend: {
            ...(props.hideLegend || props.visualizationOptions.Legend === 'hidden'
                ? { enabled: false }
                : props.legendPosition === 'right'
                ? { enabled: true, layout: 'vertical', align: 'right', verticalAlign: 'top' }
                : { enabled: true, layout: 'horizontal', align: 'center', verticalAlign: 'bottom', maxHeight: 60 }),
        },
        title: { text: props.visualizationOptions.Title ?? undefined },
        credits: { enabled: false },
        tooltip: { shared: props.showAllSeriesOnHover },
        time: { timezone: props.timezone },
    };

    return Highcharts.merge(options, props.isDarkTheme ? darkTheme : undefined);
};

const getVerticalLineValue = (value?: string, type?: ArgumentColumnType) => {
    if (value === undefined || type === undefined) {
        return undefined;
    }
    if (type === ArgumentColumnType.DateTime) {
        const valueAsDate = new Date(value);
        return valueAsDate instanceof Date && !isNaN(valueAsDate.getTime()) ? valueAsDate.getTime() : undefined;
    }
    if (isArgumentNumeric(type)) {
        const valueAsNumber = parseFloat(value);
        return !isNaN(valueAsNumber) ? valueAsNumber : undefined;
    }
    return undefined;
};

const getYAxisPlotLinesConfig: (value?: number, label?: string) => Highcharts.YAxisPlotLinesOptions[] = (
    value,
    label
) => {
    if (value === undefined) {
        return [];
    }

    return [
        {
            value,
            width: 1.5,
            color: referenceLinesColor,
            label: {
                style: { display: 'none', color: referenceLinesColor },
                text: label,
                align: 'right',
                x: 0,
            },
            zIndex: 3,
            events: {
                mouseover: function () {
                    this.label.element.style.display = 'block';
                },
                mouseout: function () {
                    this.label.element.style.display = 'none';
                },
            },
        },
    ];
};

const getXAxisPlotLinesConfig: (value?: number, label?: string) => Highcharts.XAxisPlotLinesOptions[] = (
    value,
    label
) => {
    if (value === undefined) {
        return [];
    }

    return [
        {
            value,
            width: 1.5,
            color: referenceLinesColor,
            label: {
                style: { display: 'none', color: referenceLinesColor },
                text: label,
                rotation: 0,
                textAlign: 'center',
                y: -1,
            },
            zIndex: 3,
            events: {
                mouseover: function () {
                    this.label.element.style.display = 'block';
                },
                mouseout: function () {
                    this.label.element.style.display = 'none';
                },
            },
        },
    ];
};

const getYColumnNameFromSeries = (seriesName: string) => {
    const seriesNameParts = seriesName.split(':');
    return seriesNameParts.length ? seriesNameParts[seriesNameParts.length - 1] : undefined;
};

const getYAxisIndex = (
    additionalYAxes: YAxisConfig[],
    mappedYAxesColumnNames: { [id: string]: string },
    seriesName: string
) => {
    const yColumnName = getYColumnNameFromSeries(seriesName);
    const index = additionalYAxes.findIndex((config) => {
        const columnName = mappedYAxesColumnNames[config.id];
        return columnName === yColumnName;
    });
    return index ?? 0;
};

const mapYAxesToColumnName = (additionalYAxes: YAxisConfig[]) => {
    const mappedAxes: { [id: string]: string } = {};
    additionalYAxes.forEach((yAxis) => {
        if (yAxis.columns.length > 0) {
            mappedAxes[yAxis.id] = yAxis.columns[0];
        }
    });
    return mappedAxes;
};

const buildYSplitAxesConfig = (
    seriesNames: string[],
    visualizationProps: InternalChartProps
): Highcharts.YAxisOptions[] => {
    const visualizationOptions = visualizationProps.visualizationOptions;
    return seriesNames.map<Highcharts.YAxisOptions>((_name, index) => ({
        min: visualizationOptions.Ymin !== 'NaN' ? visualizationOptions.Ymin : null,
        max: visualizationOptions.Ymax !== 'NaN' ? visualizationOptions.Ymax : null,
        type: visualizationOptions.YAxis === 'log' ? 'logarithmic' : undefined,
        // If user specified a specific min and max value, we want to respect it and not start on ticks.
        startOnTick: visualizationOptions.Ymin === 'NaN',
        endOnTick: visualizationOptions.Ymax === 'NaN',
        title: { text: undefined },
        opposite: index % 2 !== 0,
    }));
};

const getYAxisChartingConfig = (
    visualizationProps: InternalChartProps,
    yAxis?: YAxisConfig
): Highcharts.YAxisOptions => {
    const visualizationOptions = visualizationProps.visualizationOptions;
    return yAxis
        ? {
              min: yAxis.yAxisMinimumValue,
              max: yAxis.yAxisMaximumValue,
              type: yAxis.yAxisScale === 'log' ? 'logarithmic' : undefined,
              // If user specified a specific min and max value, we want to respect it and not start on ticks.
              startOnTick: yAxis.yAxisMinimumValue === null,
              endOnTick: yAxis.yAxisMaximumValue === null,
          }
        : {
              min: visualizationOptions.Ymin !== 'NaN' ? visualizationOptions.Ymin : null,
              max: visualizationOptions.Ymax !== 'NaN' ? visualizationOptions.Ymax : null,
              type: visualizationOptions.YAxis === 'log' ? 'logarithmic' : undefined,
              // If user specified a specific min and max value, we want to respect it and not start on ticks.
              startOnTick: visualizationOptions.Ymin === 'NaN',
              endOnTick: visualizationOptions.Ymax === 'NaN',
          };
};

/**
 * Pie charts differ from other visualizations in that the colors are per data point rather than per series.
 * We're assigning colors to data points by alphabetic order so that slices have consistent colors in multiple query runs.
 * so colors are assigned by alphabetical order of the category name, but the actual data needs to be sorted by value
 * such that the pie will be have largest slice first (and the smallest slice last).
 * */
function calculatePieChartDataWithColors(
    chartType: string,
    seriesNames: string[],
    groupBySeries: Record<string, PointOptionsObject[]>,
    colors: string[]
) {
    if (chartType !== 'pie' || seriesNames.length !== 1 || !groupBySeries[seriesNames[0]]) {
        return undefined;
    }

    const pieChartData = sortBy(groupBySeries[seriesNames[0]], (item) => item.x ?? item.name ?? '');

    // add color to pie chart data per the alphabetic order of the category.
    let pieChartDataWithColor = pieChartData.map((item, i) => ({
        color: colors[i % colors.length],
        item,
    }));

    // now sort the list be the value of the data point.
    pieChartDataWithColor = orderBy(pieChartDataWithColor, (item) => item.item.y, 'asc');

    return pieChartDataWithColor;
}
