import * as React from 'react';
import { useState, useCallback, useMemo } from 'react';
import { IconButton, Icon, DialogContent, DirectionalHint, Text, Callout } from 'office-ui-fabric-react';
import classNames from 'classnames';

import {
    ColorRule,
    useVisualConfig,
    formatColorRuleConditions,
    colorRulesIconToIcon,
    VisualOptions,
    ColorResolution,
    VisualDisplayedResultsCounts,
} from '../../../domain';
import { APP_STRINGS } from '../../../res';
import { useThemeState } from '../../../domain/theming';

import styles from './TileInfoCallout.module.scss';

const localAppStrings = APP_STRINGS.components.tile.infoCallout;

const iconProps = { iconName: 'Info' };

const ColorRuleRow: React.FC<{
    rule: ColorRule;
    colorResolution: ColorResolution;
}> = ({ rule, colorResolution }) => (
    <>
        <div
            className={
                colorResolution.invertText ? `${styles.colorRuleIcon} ${styles.invertText}` : styles.colorRuleIcon
            }
            style={{ backgroundColor: colorResolution.color }}
        >
            {rule.indicator.kind === 'icon' && <Icon iconName={colorRulesIconToIcon[rule.indicator.icon]} />}
        </div>
        <div className={styles.condition}>{formatColorRuleConditions(rule.conditions)}</div>
    </>
);

const ColorRules: React.FC<{
    visualOptions: VisualOptions;
    onClose: () => void;
}> = ({ visualOptions, onClose }) => {
    const visualConfig = useVisualConfig();
    const { isDark } = useThemeState();

    const colors = visualConfig.colorValues[isDark ? 'dark' : 'light'][visualOptions.colorStyle];

    return (
        <DialogContent
            className={styles.colorRulesDialogContent}
            title={APP_STRINGS.editTilePage.visualConfigSections.colorRulesTitle}
            onDismiss={onClose}
        >
            {visualOptions.colorRules.map((rule) => (
                <ColorRuleRow key={rule.id} rule={rule} colorResolution={colors[rule.color]} />
            ))}
        </DialogContent>
    );
};

const formatResultCounts = (counts: VisualDisplayedResultsCounts) =>
    `${localAppStrings.resultCountText.start} ${counts.displayedResults}/${counts.totalResults} ${localAppStrings.resultCountText.end}`;

const ResultCounts: React.FC<{
    resultCounts: VisualDisplayedResultsCounts;
    onClose: () => void;
}> = ({ resultCounts, onClose }) => (
    <DialogContent
        className={styles.resultCountsDialogContent}
        title={APP_STRINGS.components.tile.infoCallout.resultCountsSectionHeader}
        onDismiss={onClose}
    >
        <Text>{formatResultCounts(resultCounts)}</Text>
    </DialogContent>
);

export interface TileInfoCalloutProps {
    tileId: string;
    visualOptions: VisualOptions;
    resultCounts?: VisualDisplayedResultsCounts;
    showColorRulesInfo: boolean;
    className: string;
}

export const TileInfoCallout: React.FC<TileInfoCalloutProps> = ({
    tileId,
    visualOptions,
    resultCounts,
    showColorRulesInfo,
    className,
}) => {
    const theme = useThemeState();
    const [open, setOpen] = useState(false);

    const onClose = useCallback(() => setOpen(false), [setOpen]);
    const onOpen = useCallback(() => setOpen(true), [setOpen]);

    const resultsHidden = resultCounts && resultCounts.displayedResults < resultCounts.totalResults;

    const buttonId = useMemo(() => `tile-${tileId.replace(/-/g, '')}`, [tileId]);

    return (
        <IconButton
            className={classNames(styles.button, className, { [styles.resultsHidden]: resultsHidden })}
            id={buttonId}
            onClick={onOpen}
            iconProps={iconProps}
            title={APP_STRINGS.components.tile.infoCalloutButton}
        >
            {/* Callout is inside of the button so that the extra element is creates does
      not mess with host component layout */}
            {open && (
                // TODO: Using RTDCallout here causes the callout to not be closeable
                // #8140433
                <Callout
                    className={classNames(styles.callout, theme.classNames)}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    target={`#${buttonId}`}
                    onDismiss={onClose}
                >
                    {showColorRulesInfo && <ColorRules visualOptions={visualOptions} onClose={onClose} />}
                    {resultCounts && <ResultCounts resultCounts={resultCounts} onClose={onClose} />}
                </Callout>
            )}
        </IconButton>
    );
};
