import { getTelemetryClient } from './telemetryClient';
import { KustoClient as KustoClientBase } from '@kusto/common';
import { KustoClientEvents } from '@kusto/common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AuthenticationProvider } from '../OpenSource/AuthenticationProviders';

const { trackTrace, trackException } = getTelemetryClient({
    component: 'kustoClient',
    flow: 'execute',
});

class Events implements KustoClientEvents {
    constructor(private onTokenFetched?: () => void) {}

    tokenExpired(message: string) {
        trackTrace('token expired', SeverityLevel.Information, { message });
    }

    tokenFetched(
        clientActivityId: string,
        applicationUrl: string,
        url: string,
        dbName: string,
        queryOrCommand: string,
        isQuery: boolean
    ) {
        trackTrace('token fetched', SeverityLevel.Information, {
            applicationUrl,
            url,
            dbName: dbName || 'N/A',
            queryOrCommand,
            isQuery: isQuery ? 'true' : 'false',
            clientActivityId: clientActivityId,
        });

        if (this.onTokenFetched) {
            this.onTokenFetched();
        }
    }

    onUnauthorizedError(
        clientActivityId: string,
        retrying: boolean,
        refreshTokenSupported: boolean,
        retryCount: number,
        error: Error
    ) {
        trackTrace('Unauthorized Error (401)', SeverityLevel.Information, {
            clientActivityId: clientActivityId,
            retryCount: `${retryCount}`,
            refreshTokenSupported: `${refreshTokenSupported}`,
            retrying: `${retrying}`,
        });

        if (retrying && !refreshTokenSupported) {
            trackException(error, undefined, { clientActivityId });
        }
    }

    responseParsed(type: string, duration: number, length?: number) {
        trackTrace('response parsed', SeverityLevel.Information, {
            type,
            length: `${length}`,
            duration: `${duration}`,
        });
    }
}

export class KustoClient extends KustoClientBase {
    constructor(authenticationProvider: AuthenticationProvider, onTokenFetched?: () => void) {
        super(authenticationProvider, new Events(onTokenFetched));
    }
}
