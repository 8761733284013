import * as React from 'react';
import { TextField } from 'office-ui-fabric-react';
import { APP_STRINGS } from '../../../../../res';
import { useETPDispatch, useETPSelector } from '../../../../../store/editTile';

import styles from './TileTitleField.module.scss';

export const TileTitleField: React.FC = () => {
    const [dispatch] = useETPDispatch();
    const value = useETPSelector((state) => {
        if (state.type === 'loading' || state.type === 'error') {
            return '';
        }

        return state.title;
    });

    const onChange = React.useCallback(
        (_: unknown, newTitle?: string) => {
            if (newTitle === undefined) return;

            dispatch({
                type: 'updateTitle',
                title: newTitle,
            });
        },
        [dispatch]
    );

    return (
        <TextField
            data-automation-id="dashboard-visual-config-tile-title-input"
            id="dashboard-visual-config-tile-title"
            className={styles.tileTitleField}
            onChange={onChange}
            value={value}
            label={APP_STRINGS.editTilePage.visualConfig.tileName.label}
            placeholder={APP_STRINGS.editTilePage.visualConfig.tileName.placeholder}
            autoComplete="off"
        />
    );
};
