import * as React from 'react';
import { isEmpty } from 'lodash';
import { ColorStyle, ConditionalFormattingOptions } from '../utils/conditionalFormatting/types';
import { Icon, TooltipHost } from 'office-ui-fabric-react';
import { getTextWidth } from '@kusto/common';
import { IRawStyle } from '@uifabric/merge-styles';
import { IStyle, ITheme } from 'office-ui-fabric-react/lib/Styling';
import { classNamesFunction, IStyleFunctionOrObject, styled } from '@uifabric/utilities';
import { getConditionalFormattingColors } from '../utils/conditionalFormatting/conditionalFormatting';
import ReactResizeDetector from 'react-resize-detector';
import { abbreviate } from '../openSource/numberAbbreviate';

const getClassNames = classNamesFunction<StatCardStyleProps, StatCardStyles>();

type TextSize = 'small' | 'auto' | 'large';

export type StatCardStyleProps = Required<Pick<StatCardProps, 'theme' | 'textSize' | 'header'>> &
    Pick<StatCardProps, 'colorStyle' | 'color'>;

export interface StatCardProps {
    /**
     * The value to be displayed in the stat
     */
    value: number | string | undefined | null;

    /**
     * Size of text to display
     */
    textSize: TextSize;

    /**
     * Name of locale (to localize numeric data)
     */
    locale: string;

    /**
     * Fluent UI theme for the component.
     */
    theme?: ITheme;

    /**
     * optional style customizations.
     */
    styles?: IStyleFunctionOrObject<StatCardStyleProps, StatCardStyles>;
    header?: string;
    icon?: ConditionalFormattingOptions['icon'];
    subLabel?: string;
    colorStyle?: ColorStyle;
    color?: ConditionalFormattingOptions['color'];
}

export interface StatCardStyles {
    root: IStyle;
    value: IStyle;
    label: IStyle;
    subLabel: IStyle;
    header: IStyle;
    footer: IStyle;
    icon: IStyle;
    valueContainer: IStyle;
    invisibleCounterBalance: IStyle;
}

const textSizeToStyle: { [index in TextSize]: IRawStyle } = {
    small: { fontSize: 22, lineHeight: 30 },
    auto: { fontSize: 30, lineHeight: 40 },
    large: { fontSize: 42, lineHeight: 54 },
};

const getFontSize = (textSize: TextSize): number => textSizeToStyle[textSize].fontSize as number;

const getCardStyles = (props: StatCardStyleProps): StatCardStyles => {
    const style = textSizeToStyle[props.textSize];
    const fontSize = style.fontSize as number;
    const lineHeight = style.lineHeight as number;
    const spaceBelow = lineHeight - fontSize;
    const {
        textColor = props.theme!.palette.neutralPrimary,
        headerColor = props.theme!.palette.neutralPrimary,
        labelColor = props.theme!.palette.neutralPrimary,
        backgroundColor,
    } = getConditionalFormattingColors(props, props.theme.isInverted ? 'dark' : 'light');
    // We want 12 pixel diff between value and label. if let's say font size is 30, line height is 40, we have a space of 10 so we need 2 more.
    const marginTop = 12 - spaceBelow;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: 'auto',
            boxSizing: 'border-box',
            // so that grid item won't exceed container length (in multistat)
            minWidth: 0,
            backgroundColor,
        },
        value: {
            ...textSizeToStyle[props.textSize],
            color: textColor,
            // Without maxWidth long text is allowed to exceed container size and ellipsis is not shown.
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flexShrink: 1,
        },
        label: {
            fontSize: 10,
            lineHeight: '12px',
            color: labelColor,
            marginTop,
            flexShrink: 1,
        },
        header: {
            alignSelf: 'start',
            color: headerColor,
            paddingLeft: '12px',
            paddingTop: '12px',
            // That's the way to make sure header stays on top (marginBottom will consume all space downwards)
            marginBottom: 'auto',
            maxWidth: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        // since header is using marginBottom to stay on top, in order for the label to stay in center,
        // we need a 'counter-balance' on the other side of label that will eat up the margin from bottom to top.
        invisibleCounterBalance: {
            marginTop: 'auto',
            visibility: 'hidden',
        },
        subLabel: {
            lineHeight: 12,
            border: `1px ${textColor} solid`,
            color: textColor,
            padding: '0 4px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            fontSize: 10,
        },
        footer: {
            display: 'flex',
            alignSelf: 'flex-end',
            boxSizing: 'border-box',
            height: 22,
            padding: 4,
            maxWidth: '100%',
        },
        icon: {
            ...textSizeToStyle[props.textSize],
            color: textColor,
            marginRight: 4,
            selectors: {
                // Sometimes we use SVGs if the icon is not in the Fabric icons library. we need to alter their CSS spesifically
                svg: {
                    height: textSizeToStyle[props.textSize].lineHeight,
                    width: textSizeToStyle[props.textSize].fontSize,
                    verticalAlign: 'bottom',
                },
                path: {
                    fill: textColor,
                },
            },
        },
        valueContainer: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '100%',
            flexGrow: 1,
        },
    };
};

const localize = (value: string | number | undefined | null, locale: string) =>
    typeof value === 'undefined' || value === null ? '' : value.toLocaleString(locale);

const StatCardBase = ({
    value,
    styles,
    theme,
    textSize,
    header,
    color,
    colorStyle,
    subLabel,
    icon,
    locale,
}: StatCardProps) => {
    const classNames = getClassNames(styles, {
        theme: theme!,
        textSize: textSize ?? 'auto',
        header: header ?? '',
        color,
        colorStyle,
    });
    const ref = React.useRef(null);
    const [shouldAbbreviate, setShouldAbbreviate] = React.useState<boolean>(false);
    const localizedValue = localize(value, locale);

    // Abbreviation is shortening a number for display purposes. example:  1,000,000 => 1M
    const fontSize = getFontSize(textSize);
    const textWidth = getTextWidth(localizedValue, fontSize);
    const iconMargins = 4;
    const iconWidth = icon ? (textSizeToStyle[textSize ?? 'auto'].fontSize as number) + iconMargins : 0;

    // A label is displayed below the abbreviated number in smaller grayer font.
    // It displays the full number for reference (which should be localized as well).
    const label = shouldAbbreviate ? localizedValue : '';

    let mainValue = localizedValue;
    let units: string = '';
    if (shouldAbbreviate) {
        const abbreviated = abbreviate(value as number, 1);
        mainValue = localize(abbreviated.number, locale);
        units = abbreviated.unit ?? '';
    }

    const onResize = (width: number) => {
        setShouldAbbreviate(typeof value === 'number' && !!width && width < textWidth + iconWidth);
    };

    return (
        <div ref={ref} className={classNames.root}>
            {header && <div className={classNames.header}>{header}</div>}
            <ReactResizeDetector handleWidth={true} onResize={onResize} refreshMode="throttle" refreshRate={300} />
            <div className={classNames.valueContainer}>
                <Icon className={classNames.icon} iconName={icon} />
                <TooltipHost
                    content={localizedValue}
                    styles={{ root: { alignSelf: typeof value === 'string' ? 'stretch' : 'center', minWidth: 0 } }}
                >
                    <div className={classNames.value}>{mainValue + units}</div>
                </TooltipHost>
            </div>
            <div className={classNames.label}>{label}</div>
            {header && <div className={classNames.invisibleCounterBalance}></div>}
            <div className={classNames.footer}>
                {!isEmpty(subLabel?.trim()) && (
                    <TooltipHost content={subLabel} styles={{ root: { minWidth: 0 } }}>
                        <div className={classNames.subLabel}>{subLabel}</div>
                    </TooltipHost>
                )}
            </div>
        </div>
    );
};

/**
 * A component that displays a single stat (usually numeric, but can be textual).
 * Usually displayed on small rectangular surfaces as part of a dashboard.
 */
export const StatCard = styled(StatCardBase, getCardStyles, undefined, { scope: 'statCard' });
