import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import History from 'history';
import { useUrl } from './hooks/useUrl';

export type INavigationLinkProps<S = History.LocationState> = LinkProps<S>;

/**
 * @deprecated Use react-router link directly
 */
export const NavigationLink: React.FC<INavigationLinkProps> = (props) => {
    // TODO: This cast shouldn't be necessary
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const url = useUrl(props.to as any);

    return <Link {...props} to={url} />;
};
