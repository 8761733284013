import { checkForNumberedDuplicateName, extractNumberedBaseName } from '../../common';
import { BasicParamConfig, DurationParamConfig, ParameterConfig } from './models';

function buildUniqueParameterNameAndVariablesSuffix(
    existingParameter: ParameterConfig,
    parameters: readonly ParameterConfig[]
) {
    let highestMatch = 1;

    const existingParameterDisplayNames = new Set([extractNumberedBaseName(existingParameter.displayName)]);
    const existingVariables = new Set(existingParameter.variableNames.map(extractNumberedBaseName));

    for (const otherParameter of parameters) {
        if (!otherParameter) {
            continue;
        }

        // Check displayName
        const displayNameNumber = checkForNumberedDuplicateName(
            existingParameterDisplayNames,
            otherParameter.displayName
        );

        if (displayNameNumber > highestMatch) {
            highestMatch = displayNameNumber;
        }

        const otherVariables = otherParameter.variableNames;

        for (const variable of otherVariables) {
            const variableNamesNumber = checkForNumberedDuplicateName(existingVariables, variable);

            if (variableNamesNumber > highestMatch) {
                highestMatch = variableNamesNumber;
            }
        }
    }

    return `${highestMatch + 1}`;
}

export function parameterWithAppendedSuffixVariables(
    id: string,
    displayName: string,
    parameter: ParameterConfig,
    suffix: string
): ParameterConfig {
    if (parameter.kind === 'basic') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new BasicParamConfig<any, any>(
            id,
            displayName,
            {
                ...parameter.options,
                variableName: extractNumberedBaseName(parameter.options.variableName) + suffix,
            },
            parameter.impl
        );
    }

    return new DurationParamConfig(
        id,
        extractNumberedBaseName(parameter.beginVariableName) + suffix,
        extractNumberedBaseName(parameter.endVariableName) + suffix,
        displayName,
        parameter.defaultValue.data
    );
}

export function buildUniquelyNamedParameter(
    newId: string,
    existingParameter: ParameterConfig,
    parameters: readonly ParameterConfig[]
): ParameterConfig {
    const suffix = buildUniqueParameterNameAndVariablesSuffix(existingParameter, parameters);

    // Underscore for variables, hyphen for names
    return parameterWithAppendedSuffixVariables(
        newId,
        `${extractNumberedBaseName(existingParameter.displayName)}-${suffix}`,
        existingParameter,
        `_${suffix}`
    );
}
