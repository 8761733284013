import { Err, err, ok } from '../../../common';
import { APP_STRINGS } from '../../../res';

import { buildValueImpl } from '../valueImpl';

const int32Max = 2 ** 31;

// From https://stackoverflow.com/a/21505954
const intRegex = /^([+-]?[1-9]\d*|0)$/;

function validateIntSize(int: number): undefined | Err<string> {
    const maxValue = int32Max - 1;

    //  2^31 - 1
    if (int > maxValue) {
        // Too large
        return err(`${APP_STRINGS.domain.parameter.errors.int32.tooLarge}${maxValue}`);
    }

    // -2^31
    const minValue = -int32Max;

    if (int < minValue) {
        // Too large
        return err(`${APP_STRINGS.domain.parameter.errors.int32.tooLarge}${minValue}`);
    }

    return;
}

export const int32ValueImpl = buildValueImpl<number, 'int32'>({
    dataType: 'int32',
    urlStringToValue(value: string) {
        if (!value.match(intRegex)) {
            return err(APP_STRINGS.domain.parameter.errors.error);
        }
        const int = parseInt(value, 10);

        const maybeError = validateIntSize(int);
        if (maybeError) {
            return maybeError;
        }

        return ok(int);
    },
    valueToUrlString(value: number) {
        return value.toString();
    },

    valueToDisplayString(value: number) {
        return value.toLocaleString();
    },
    tryNarrowValue(value) {
        if (value.tag === 'int32') {
            return value;
        }
        return undefined;
    },
    isValue(value) {
        return typeof value === 'number' && validateIntSize(value) === undefined;
    },
});
