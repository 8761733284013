import * as React from 'react';
import { useMemo } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { APP_STRINGS } from '../../../../../res';
import { useExecuteQuery, newKustoQuery, StreamResult, KustoQueryResult, valueImplFor } from '../../../../../domain';
import { MaybeQuery } from '../../../../../components/QueryEditing';
import { DashboardStore, useDashboardStore } from '../../../../../store/dashboard';
import { err, ok } from '../../../../../common';
import { useSyncQueryResults } from '../../../hooks/useSyncQueryResults';

import { EditParameterPageDispatch, IEditParameterPageComponentProps } from '../../../types';
import { EppReducerState, EppBasicParamOptions, EppQueryDropdownOptions } from '../../../reducer';

import { DropdownParamConfig } from '../DropdownParamConfig/DropdownParamConfig';

import { ParameterQueryValueForm } from './QueryDataSourceConfig/QueryDataSourceConfig';
import { DefaultValueDropdown } from './DefaultValueDropdown';

const noResultsError = err({
    title: APP_STRINGS.editParameterPage.recordTicker.noRecords,
});

const missingDataSourceError = err({
    title: APP_STRINGS.editParameterPage.recordTicker.missingDataSource,
});

function useEppRunQuery(
    store: DashboardStore,
    state: EppReducerState,
    queryOptions: EppQueryDropdownOptions,
    dispatch: EditParameterPageDispatch
): StreamResult<KustoQueryResult> {
    const usedParameterValues = React.useMemo(
        () =>
            computed(() => {
                const parameterSelections = store.state?.selectedParameters;
                if (!parameterSelections) {
                    return noResultsError;
                }

                return parameterSelections.resolveParameterValues(queryOptions.consumedVariables);
            }),
        [store, queryOptions.consumedVariables]
    ).get();

    const dataSource = queryOptions?.dataSourceId && store.state?.dataSourcesRecord[queryOptions.dataSourceId];

    const query: MaybeQuery = useMemo(() => {
        if (!dataSource) {
            return missingDataSourceError;
        }

        if (queryOptions?.query === undefined) {
            return noResultsError;
        }
        if (usedParameterValues.kind === 'err') {
            return usedParameterValues;
        }

        return ok(newKustoQuery(queryOptions.query, dataSource, state.id, usedParameterValues.value));
    }, [queryOptions?.query, dataSource, state.id, usedParameterValues]);
    const queryStatus = useExecuteQuery(store, query);

    useSyncQueryResults(queryStatus, dispatch);

    return queryStatus;
}

export interface QueryDropdownParamConfigProps extends IEditParameterPageComponentProps {
    state: EppReducerState;
    getState: () => EppReducerState;
    basicParamOptions: EppBasicParamOptions;
    dropdownOptions: EppQueryDropdownOptions;
}

export const QueryDropdownParamConfig: React.FC<QueryDropdownParamConfigProps> = observer(
    function QueryDropdownDefaultForm(props) {
        const store = useDashboardStore();
        const queryStatus = useEppRunQuery(store, props.state, props.dropdownOptions, props.dispatch);

        return (
            <DropdownParamConfig
                {...props}
                dropdownOptionsForm={
                    <ParameterQueryValueForm
                        parameterType={props.basicParamOptions.dataType}
                        parameterId={props.state.id}
                        queryStatus={queryStatus}
                        dataSource={props.dropdownOptions}
                        dispatch={props.dispatch}
                    />
                }
                configureDefaultValue={
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    <DefaultValueDropdown<any, any>
                        impl={valueImplFor(props.basicParamOptions.dataType)}
                        queryStatus={queryStatus}
                        pageOptions={props.state}
                        queryOptions={props.dropdownOptions}
                        getState={props.getState}
                        onChange={(value) => props.dispatch({ type: 'setQueryDropdownDefaultValue', value })}
                    />
                }
            />
        );
    }
);
