import React from 'react';
import { DialogFooter, PrimaryButton } from 'office-ui-fabric-react';
import { useHistory } from 'react-router';

import { useCore } from '../../../core';
import { hrefCapturing } from '../../../common/link';
import { RTDDialog } from '../../../components/fabric';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { useETPSelector, ETPState } from '../../../store/editTile';
import { WarningsList } from '../../../components/errors/WarningsList';

interface IErrorDialogProps {
    message: React.ReactNode;
    dashboardId: string;
}

const ErrorDialog: React.FC<IErrorDialogProps> = (props) => {
    const core = useCore();
    const history = useHistory();

    const dashboardPath = core.navUtil.path.dashboard(props.dashboardId);

    return (
        <RTDDialog
            hidden={false}
            dialogContentProps={{ title: APP_STRINGS.editTilePage.loadingErrorDialog.title }}
            maxWidth={APP_CONSTANTS.ux.dialogWithTextWidth}
        >
            <WarningsList level="error" warnings={[props.message]}></WarningsList>
            <DialogFooter>
                <PrimaryButton
                    text={APP_STRINGS.editTilePage.loadingErrorDialog.dashboardNavButton}
                    href={dashboardPath}
                    onClick={hrefCapturing(() => history.push(dashboardPath, { edit: true }))}
                />
            </DialogFooter>
        </RTDDialog>
    );
};

const messageSelector = (state: ETPState) => {
    if (state.type !== 'error') {
        return undefined;
    }
    return state.message;
};

export interface TileLoadingErrorProps {
    dashboardId: string;
}

export const TileLoadingError: React.FC<TileLoadingErrorProps> = ({ dashboardId }) => {
    const etpError = useETPSelector(messageSelector);

    if (etpError) {
        return <ErrorDialog dashboardId={dashboardId} message={etpError} />;
    }
    return null;
};
