import type { DashboardsIndexedDBSchema, DashboardsIndexedDB, DBValue } from './types';

/**
 * An in-memory cache abstraction for accessing IndexedDB data of type `T`
 * The memory cache will be hit first before contacting the DB.
 */
export class IndexedDBMemCache<S extends keyof DashboardsIndexedDBSchema> {
    private readonly memoryCache = new Map<string, DBValue<S>>();

    constructor(
        private readonly store: S,
        /**
         * No backing indexedDB is supported so we can support contexts where
         * indexedDB access is blocked.
         */
        private readonly db: DashboardsIndexedDB
    ) {}

    async get(key: string): Promise<DBValue<S> | undefined> {
        // Only retrieve from the db if the memory cache doesn't have a cached result
        return this.memoryCache.get(key) ?? (await this.db.db)?.get(this.store, key);
    }

    async set(key: string, data: DBValue<S>) {
        this.memoryCache.set(key, data);

        // We are not returning the promise, because, we never want to wait until
        // the data being committed to database
        (await this.db.db)?.put(this.store, data, key);
    }

    async delete(key: string) {
        this.memoryCache.delete(key);
        (await this.db.db)?.delete(this.store, key);
    }
}
