import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { TextField } from 'office-ui-fabric-react';

import { APP_CONSTANTS } from '../../res';
import { IStaticBreadcrumbItem, dashboardsBreadcrumb } from '../../components/PageContent';
import { hrefCapturing } from '../../common';
import { useUrl, useVisitUrl } from '../navigation';
import { DashboardStore } from '../../store/dashboard';

const saveLastItem = debounce(
    (onSaveLastItemText: (text: string) => void, lastItemText: string) => onSaveLastItemText(lastItemText),
    APP_CONSTANTS.debounce.standard
);

const noop = () => {};

export interface IUseDashboardBreadcrumb {
    store: DashboardStore;
    dashboardId?: string;
    additionalItems?: IStaticBreadcrumbItem[];
    customOnDashboardClick?: () => void;
    allowEditingLastItem?: {
        onChange: (text: string) => void;
        inputTitle: string;
    };
}

/**
 * @deprecated
 */
export const useDashboardBreadcrumbs = ({
    store,
    dashboardId,
    additionalItems,
    customOnDashboardClick,
    allowEditingLastItem,
}: IUseDashboardBreadcrumb): IStaticBreadcrumbItem[] => {
    const catalogUrl = useUrl('/');
    const dashboardUrl = useUrl(`/${dashboardId}`);
    const visitUrl = useVisitUrl();

    const [lastItemText, setLastItemText] = useState('');

    const onChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue = '') => {
        setLastItemText(newValue);
        saveLastItem(allowEditingLastItem?.onChange ?? noop, newValue);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onCatalogClick = useCallback(
        hrefCapturing(() => visitUrl(APP_CONSTANTS.urls.catalogPage)),
        [visitUrl]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onDashboardClick = useCallback(
        hrefCapturing(!!customOnDashboardClick ? customOnDashboardClick : () => visitUrl(`/${dashboardId}`)),
        [dashboardId, customOnDashboardClick, visitUrl]
    );

    const hasAdditionalItems = additionalItems && additionalItems.length > 0;

    const standardItems: IStaticBreadcrumbItem[] = [
        {
            key: 'dashboards',
            onClick: onCatalogClick,
            href: catalogUrl,
            ...dashboardsBreadcrumb,
        },
        {
            key: 'dashboard',
            // Fixes #24135433: Either dashboard must be the last item, or the title must be non-empty
            text: hasAdditionalItems ? store.state?.title ?? 'Unknown' : store.state?.title ?? '',
            onClick: onDashboardClick,
            href: dashboardUrl,
        },
    ];

    const items =
        additionalItems && additionalItems.length > 0 ? [...standardItems, ...additionalItems] : standardItems;

    if (allowEditingLastItem && items.length > 0) {
        const lastItem = items[items.length - 1];

        items[items.length - 1] = {
            ...lastItem,
            onClick: undefined,
            href: undefined,
            onRenderItem: () => (
                <TextField value={lastItemText} onChange={onChange} title={allowEditingLastItem.inputTitle} />
            ),
        };
    }

    const lastProvidedItemText = allowEditingLastItem && items.length > 0 ? items[items.length - 1].text : undefined;

    useEffect(() => {
        if (lastProvidedItemText === undefined) {
            return;
        }

        setLastItemText(lastProvidedItemText);
    }, [lastProvidedItemText]);

    return items;
};
