import * as React from 'react';

const TimeZoneContext = React.createContext<string | undefined>(undefined);

/**
 * Time Zone string like "America/Los_Angeles" or "Asia/Tokyo" or "UTC"
 * that follows the IANA format
 *
 * @see https://www.iana.org/time-zones
 *
 * @see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export type TimeZone = string;

export interface ITimeZoneProviderProps {
    timeZone: TimeZone;
}

export const TimeZoneProvider: React.FC<ITimeZoneProviderProps> = ({ timeZone, children }) => {
    return <TimeZoneContext.Provider value={timeZone}>{children}</TimeZoneContext.Provider>;
};

export function useTimeZone(): TimeZone {
    const timeZone = React.useContext(TimeZoneContext);

    if (timeZone === undefined) {
        throw new Error('useTimeZone must be used within a TimeZoneContext Provider');
    }

    return timeZone;
}
