import { useCallback, Dispatch, MutableRefObject, RefObject } from 'react';
import { KeyCodes, IList, ITextField } from 'office-ui-fabric-react';

import { RTDDropdownOption, KeyToIndex } from './types';
import { CustomListDropdownMenuAction } from './reducer';
import { buildRowHeightFunc } from './util';

export const useOnInputKeyDown = (
    filteredOptions: RTDDropdownOption[],
    orderedFilteredKeys: string[] | undefined,
    optionKeys: KeyToIndex,
    multiSelect: boolean,
    setSelectedIndex: ((event: React.FormEvent<HTMLDivElement>, index: number) => void) | undefined,
    setIsOpen: ((open: boolean) => void) | undefined,
    currentActiveIndex: MutableRefObject<number | undefined>,
    listRef: IList | undefined,
    inputRef: RefObject<ITextField>,
    dispatch: Dispatch<CustomListDropdownMenuAction>
) =>
    useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (filteredOptions.length < 1) {
                // Do nothing
                return;
            }

            const incrementIndex = (direction: 'up' | 'down') => {
                event.stopPropagation();
                event.preventDefault();

                let newFilteredIndex = currentActiveIndex.current ?? -1;
                const stepIndex = direction === 'up' ? -1 : 1;

                newFilteredIndex += stepIndex;

                if (newFilteredIndex >= filteredOptions.length) {
                    newFilteredIndex = 0;
                } else if (newFilteredIndex < 0) {
                    newFilteredIndex = filteredOptions.length - 1;
                }

                let extraSteps = 0;
                while (filteredOptions[newFilteredIndex].disabled || filteredOptions[newFilteredIndex].hidden) {
                    // If current value is disabled or hidden, continue moving until a valid option is found
                    if (extraSteps >= filteredOptions.length) {
                        // Iterated through whole list. Give up
                        dispatch({
                            type: 'setActiveIndex',
                            index: undefined,
                        });

                        return;
                    }

                    if (newFilteredIndex + stepIndex >= filteredOptions.length) {
                        // Indexes are off by one, as they're about to be immediately incremented
                        newFilteredIndex = -1;
                    } else if (newFilteredIndex + stepIndex < 0) {
                        newFilteredIndex = filteredOptions.length;
                    }

                    newFilteredIndex += stepIndex;

                    extraSteps++;
                }

                dispatch({
                    type: 'setActiveIndex',
                    index: newFilteredIndex,
                });

                // Immediately scroll to this index, rather than waiting for the next render tick
                listRef?.scrollToIndex(newFilteredIndex, buildRowHeightFunc(filteredOptions));
            };

            switch (event.which) {
                case KeyCodes.up:
                    return incrementIndex('up');
                case KeyCodes.down:
                    return incrementIndex('down');
                case KeyCodes.enter: {
                    event.stopPropagation();
                    event.preventDefault();

                    // If no active selection, and filtering, set 0 as the index to select
                    const index =
                        currentActiveIndex.current ??
                        (orderedFilteredKeys && orderedFilteredKeys.length > 0 ? 0 : undefined);

                    if (index !== undefined) {
                        const newKey = filteredOptions[index].key;

                        const newGlobalIndex = optionKeys[newKey];

                        setSelectedIndex?.(event, newGlobalIndex);
                    }

                    if (!multiSelect) {
                        setIsOpen?.(false);
                    }

                    return;
                }
                // Home and end are captured by the Callout, so we must manually handle them
                case KeyCodes.home: {
                    event.preventDefault();
                    event.stopPropagation();

                    inputRef.current?.setSelectionRange(0, 0);

                    return;
                }
                case KeyCodes.end: {
                    event.preventDefault();
                    event.stopPropagation();

                    const endIndex = inputRef.current?.value?.length ?? 0;

                    inputRef.current?.setSelectionRange(endIndex, endIndex);

                    return;
                }
            }
        },
        [
            filteredOptions,
            orderedFilteredKeys,
            optionKeys,
            multiSelect,
            setSelectedIndex,
            setIsOpen,
            currentActiveIndex,
            listRef,
            inputRef,
            dispatch,
        ]
    );
