import * as React from 'react';

import { Slot } from '../../../../../../domain';
import { APP_STRINGS } from '../../../../../../res';

export interface SlotLabel {
    text: string;
    node: React.ReactNode;
}

export const createSlotLabel = (slot: Slot): SlotLabel => {
    const strings = APP_STRINGS.editTilePage.visualConfig.multiState__slot.formatSlot;

    const dimensionsText = `${slot.width} ${strings.columns} x ${slot.height} ${strings.rows}`;

    const area = slot.width * slot.height;
    const areaText = `${area} ${area === 1 ? strings.slot : strings.slotPlural}`;

    return {
        text: `${dimensionsText} (${areaText})`,
        node: (
            <>
                {dimensionsText}
                <br />({areaText})
            </>
        ),
    };
};
