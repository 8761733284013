import { IObservableValue, observable } from 'mobx';

// This code needs to avoid creating mobx state until `getDarkThemeEnabled` is
// called. mobx-utils' lazy-observable creates state immediately, and thus
// cannot be used.

let darkThemeObservable: IObservableValue<boolean> | undefined;

function createDarkThemeObservable(): IObservableValue<boolean> {
    const osDarkThemeEnabledMedia = window.matchMedia('(prefers-color-scheme: dark)');

    const obs = observable.box<boolean>(osDarkThemeEnabledMedia.matches, {
        deep: false,
    });

    /**
     * `addEventListener` is only supported on Safari versions 14+.
     */
    osDarkThemeEnabledMedia.addListener((e) => {
        obs.set(e.matches);
    });

    darkThemeObservable = obs;

    return darkThemeObservable;
}

/**
 * Gets the dark theme status from a mobx observable, creating it if it doesn't
 * exist yet.
 */
export function osDarkThemeEnabled(): boolean {
    return (darkThemeObservable ?? createDarkThemeObservable()).get();
}
