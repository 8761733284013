import * as React from 'react';
import { useState, useEffect } from 'react';
import { Icon, Spinner, SpinnerSize, Text } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../res';
import { StreamQueryResult } from '../../domain';

import styles from './StatusIndicators.module.scss';

const formatElapsedTime = (start: Date, end: Date) =>
    `(${((end.valueOf() - start.valueOf()) / 1000).toFixed(3)} ${
        APP_STRINGS.editTilePage.queryState.formatElapsedTimeSecondsUnit
    })`;

const LoadingTime: React.FC<{ startTime: Date }> = ({ startTime }) => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => setCurrentTime(new Date()), 100);
        return () => clearInterval(intervalId);
    }, [setCurrentTime]);

    // aria-hidden so it doesn't read excessive time updates while the query is running
    return <span aria-hidden="true">{formatElapsedTime(startTime, currentTime)}</span>;
};

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export const StatusInfoText: React.FC<DivProps> = (props) => (
    <div className={styles.infoText} {...props} aria-live="polite" />
);

export const StatusIndicators: React.FC<{ result: StreamQueryResult }> = ({ result }) => {
    if (result.status?.kind === 'loading') {
        return (
            <StatusInfoText>
                <Spinner size={SpinnerSize.xSmall} className={styles.spinner} />
                <Text>{APP_STRINGS.editTilePage.queryState.loading}</Text>{' '}
                <LoadingTime startTime={result.status.startTime} />
            </StatusInfoText>
        );
    }

    switch (result.kind) {
        case 'notRun':
            return null;
        case 'err':
            if (!result.didRun) {
                return null;
            }

            return (
                <StatusInfoText>
                    <Icon iconName="StatusErrorFull" className={styles.errorIcon}></Icon>
                    <Text>
                        {`${APP_STRINGS.editTilePage.queryState.error} ${formatElapsedTime(
                            result.status.startTime,
                            result.status.endTime
                        )}`}
                    </Text>
                </StatusInfoText>
            );
        case 'success':
            return (
                <>
                    <StatusInfoText>
                        <Icon iconName="SkypeCircleCheck" className={styles.checkIcon} />
                        <Text>
                            {APP_STRINGS.editTilePage.queryState.done}{' '}
                            {formatElapsedTime(result.status.startTime, result.result.receivedTime)}
                        </Text>
                    </StatusInfoText>
                    <StatusInfoText>
                        <Icon iconName="NumberField" className={styles.rowCountIcon} />
                        <Text>
                            {result.result.rows.length} {APP_STRINGS.editTilePage.queryState.rowCount}
                        </Text>
                    </StatusInfoText>
                </>
            );
    }
};
