import { createBrowserHistory } from 'history';
import { basePathName } from './utils/url';

/**
 * A singleton history instance to be shared by all history manipulators / observes.
 * For example - routers (that modify history) and application insights (that log history).
 */
export const history = createBrowserHistory({
    basename: basePathName,
});
