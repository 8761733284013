import { ITileV4 } from './4';
import { IVisualOptionsV3 } from './4';
import { CanInferV1 } from './1';

export interface YAxisConfigV5 {
    readonly id: string;
    readonly column: CanInferV1<string>;
    readonly label: string;
}

export interface IVisualOptionsV4 extends IVisualOptionsV3 {
    multipleYAxis: YAxisConfigV5;
}

export interface ITileV5 extends Omit<ITileV4, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV4>;
}

export function up(prev: ITileV4): ITileV5 {
    return prev;
}
