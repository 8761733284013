import { FeatureFlag } from '../../FeatureFlags';

const tenants = {
    microsoft: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    /**
     * ### Public Management Environment
     * A Microsoft production identity environment that provides access to
     * online services outside of the Azure Management Environment scope.
     */
    pme: '975f013f-7f24-47e8-a7d3-abc4752bf346',
    /**
     * ubs.com
     */
    ubs: 'fb6ea403-7cf1-4905-810a-fe5547e98204',
    torus: 'cdc5aeea-15c5-4db6-b079-fcadd2505dc2',
};

const domains = {
    preview: 'preview.dataexplorer.azure.com',
};

const tenantFeatureFlags: Partial<Record<string, Partial<Record<FeatureFlag, boolean>>>> = {
    [tenants.ubs]: { HideDashboardsOnly: true },
    [tenants.microsoft]: { EnableDashboardsDevMenu: true },
    [tenants.pme]: { EnableDashboardsDevMenu: true },
    [tenants.torus]: { EnableDashboardsDevMenu: true },
};

export const KWE_CONSTANTS = {
    tenants,
    tenantFeatureFlags,
    dashboards: {
        /**
         * Referenced inside of dashboard json data sources, so cannot be changed.
         */
        manualKustoScopeId: 'kusto',
        prefixClientRequestId: 'RTD',
        controlCommandAppName: 'KusWeb',
    },
    domainFeatureFlags: {
        [domains.preview]: { Msalv2: true },
    },
};
