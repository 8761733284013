import React from 'react';
import { Checkbox, Label, Text } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../../res';
import { IEditParameterPageComponentProps } from '../../../types';
import { EppReducerState, EppQueryDropdownOptions, EppStaticDropdownOptions } from '../../../reducer';

import { CodeSample } from '../../codeSample/CodeSample';
import { SingleSelectCodeSample } from '../../codeSample/examples/SingleSelectCodeSample';
import { MultiSelectCodeSample } from '../../codeSample/examples/MultiSelectCodeSample';

import pageStyles from '../../../EditParameterPage.module.scss';

import { DataSourceTypeSelector } from './DataSourceTypeSelector';

import styles from './DropdownParamConfig.module.scss';

const noneOptionId = 'paramter-value-form--select-all';

const onRenderAddNoneOptionLabel = () => <div>{APP_STRINGS.editParameterPage.addNoneOptionLabelRight}</div>;

const ParameterCodeSample: React.FC<{
    multiselect: boolean;
    variableName: string;
}> = ({ multiselect, variableName }) => {
    if (variableName === '') {
        // TODO: APP_STRINGS
        variableName = 'variableName';
    }

    if (multiselect) {
        return (
            <MultiSelectCodeSample
                tableName={APP_STRINGS.editTilePage.querySamples.tableName}
                columnName={`<${APP_STRINGS.editTilePage.querySamples.queryBuilding.columnNamePlaceholder}>`}
                variableName={variableName}
            />
        );
    }

    return (
        <SingleSelectCodeSample
            tableName={APP_STRINGS.editTilePage.querySamples.tableName}
            columnName={`<${APP_STRINGS.editTilePage.querySamples.queryBuilding.columnNamePlaceholder}>`}
            variableName={variableName}
        />
    );
};

export interface DropdownParamConfigProps extends IEditParameterPageComponentProps {
    state: EppReducerState;
    dropdownOptions: EppStaticDropdownOptions | EppQueryDropdownOptions;
    dropdownOptionsForm: React.ReactNode;
    configureDefaultValue: React.ReactNode;
}

export const DropdownParamConfig: React.FC<DropdownParamConfigProps> = ({
    dropdownOptions,
    state,
    dispatch,
    dropdownOptionsForm,
    configureDefaultValue,
}) => {
    const onSelectAll = React.useCallback(
        (_: unknown, checked?: boolean) =>
            dispatch({
                type: 'singleSelectAllowSelectAll',
                allowAllSelection: !!checked,
            }),
        [dispatch]
    );

    const isSelectAllDisabled = dropdownOptions.selectionKind === 'array-null';

    return (
        <>
            <DataSourceTypeSelector dispatch={dispatch} dataSourceType={dropdownOptions.kind} />
            {dropdownOptionsForm}
            <div className={pageStyles.input}>
                <Label htmlFor={noneOptionId}>{APP_STRINGS.editParameterPage.addNoneOptionLabelTop}</Label>
                <Checkbox
                    id={noneOptionId}
                    onRenderLabel={onRenderAddNoneOptionLabel}
                    checked={dropdownOptions.selectionKind !== 'scalar'}
                    onChange={onSelectAll}
                    disabled={isSelectAllDisabled}
                />
                <Text className={styles.sampleDescription} variant="medium">
                    {APP_STRINGS.editParameterPage.addNoneOptionSampleDescription}
                </Text>
                <CodeSample
                    className={styles.sampleDescription}
                    content={
                        <ParameterCodeSample
                            multiselect={dropdownOptions.selectionKind === 'array-null'}
                            variableName={state.variableNames[0]}
                        />
                    }
                />
            </div>
            {configureDefaultValue}
        </>
    );
};
