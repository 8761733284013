import * as React from 'react';

import { ETPState, useETPSelector } from '../../../../store/editTile';
import { IParameterSelections } from '../../../../store';

import { MarkdownPane } from './MarkdownPane';
import { QueryPane } from './QueryPane';

import styles from './BottomPane.module.scss';

const stateTypeSelector = (s: ETPState) => s.type;

const PaneLayout: React.FC = ({ children }) => <div className={styles.bottomPaneLayout}>{children}</div>;

interface BottomPaneProps {
    tileId?: string;
    crossFilterTarget: undefined | IParameterSelections;
}

export const BottomPane: React.FC<BottomPaneProps> = ({ tileId, crossFilterTarget }) => {
    const stateType = useETPSelector(stateTypeSelector);

    switch (stateType) {
        case 'query':
            return (
                <PaneLayout>
                    <QueryPane crossFilterTarget={crossFilterTarget} tileId={tileId} />
                </PaneLayout>
            );
        case 'markdown':
            return (
                <PaneLayout>
                    <MarkdownPane tileId={tileId} />
                </PaneLayout>
            );
        case 'loading':
        case 'error':
            return null;
    }
};
