import React from 'react';

/**
 * Creates an abort controller that aborts when the current component un-mounts.
 *
 * Things that should be disposed along with a component include callbacks, and
 * async operations as a result of callbacks.
 *
 * Things called via `useEffect` should _not_ use this controller, and should
 * instead create a controller for each instance of the effect, aborting it in
 * the effect disposer.
 *
 * `React.useEffect` + `AbortController` example:
 * ```typescript
 * React.useEffect(() => {
 *   const controller = new AbortController();
 *
 *   // Do async stuff
 *   fetch(`<url>${id}`, { signal: controller.signal });
 *
 *   // Controller is aborted along with each effect, so changing the id aborts
 *   // the previous async operation
 *   return () => controller.abort();
 * }, [id]);
 * ```
 */
export function useAbortController() {
    const [controller] = React.useState(() => new AbortController());

    React.useEffect(() => () => controller.abort(), [controller]);

    return controller;
}
