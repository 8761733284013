import * as React from 'react';
import {
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Text,
    IDialogContentProps,
    IModalProps,
} from 'office-ui-fabric-react';
import { observer } from 'mobx-react-lite';
import { useMemo, useCallback } from 'react';

import { RTDDialog } from '../../../../fabric/panelsWrapped';
import { APP_STRINGS } from '../../../../../res';
import { TFormComponent, formActionMacro, compactMap } from '../../../../../common';
import { ITile } from '../../../../../domain/tile/types/tile';
import { useDashboardStore } from '../../../../../store/dashboard';
import { ParameterConfig } from '../../../../../domain';

const dialogContentProps: IDialogContentProps = {
    showCloseButton: true,
};

const modalProps: IModalProps = {
    isBlocking: true,
};

export const DeleteParameterDialog: TFormComponent<{
    parameterName: string;
    usedTileIds: string[] | undefined;
    usedParameterIds: string[] | undefined;
    onConfirm: () => void;
}> = observer(function DeleteParameterDialog({
    onClose,
    parameterName,
    usedTileIds = [],
    usedParameterIds = [],
    onConfirm,
}) {
    const store = useDashboardStore();
    const parametersRecord = store.state?.parametersRecord;
    const tilesRecord = store.state?.tilesRecord;

    const { tiles, parameters } = useMemo(
        () => ({
            tiles: tilesRecord === undefined ? [] : usedTileIds.map((id) => tilesRecord[id]),
            parameters: parametersRecord === undefined ? [] : usedParameterIds.map((id) => parametersRecord[id]),
        }),
        [tilesRecord, usedTileIds, parametersRecord, usedParameterIds]
    );

    const innerOnConfirm = useCallback(() => {
        onConfirm();
        onClose();
    }, [onConfirm, onClose]);

    return (
        // TODO #8283090: Fabric issue https://github.com/microsoft/fluentui/issues/15025 causes clicks on this dialog to dismiss the Manage panel
        <RTDDialog
            hidden={false}
            title={APP_STRINGS.forms.parameter.delete.title}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            onDismiss={onClose}
        >
            <Text>{formatDescription(parameterName, tiles, parameters)}</Text>
            <br />
            <br />
            <Text>{APP_STRINGS.forms.parameter.delete.confirmationMessage}</Text>
            <DialogFooter>
                <PrimaryButton onClick={innerOnConfirm}>{APP_STRINGS.utilButtons.delete}</PrimaryButton>
                <DefaultButton onClick={onClose} text={APP_STRINGS.utilButtons.cancel} />
            </DialogFooter>
        </RTDDialog>
    );
});

const formatDescription = (parameterName: string, tiles: Array<ITile | undefined>, parameters: ParameterConfig[]) => {
    const strings = APP_STRINGS.forms.parameter.delete;
    const componentTile = APP_STRINGS.components.tile;

    const entities: string[] = [];

    if (tiles.length > 0) {
        entities.push(formatEntities(tiles, 'title', componentTile.tilePlural, componentTile.tileSingular));
    }

    if (parameters.length > 0) {
        entities.push(formatEntities(parameters, 'displayName', strings.parameterPlural, strings.parameterSingular));
    }

    return `${strings.parameterPrefix}${parameterName}${strings.parameterSuffix} ${entities.join(
        ` ${strings.and} `
    )}. ${strings.sideEffectExplanation}`;
};

const formatEntities = <T,>(
    entities: Array<T | undefined>,
    titleKey: keyof T,
    plural: string,
    singular: string
): string =>
    `${entities.length !== 1 ? `${entities.length} ${plural}` : `1 ${singular}`}${
        entities.length > 0 ? ` (${compactMap(entities, (e) => e?.[titleKey]).join(', ')})` : ''
    }`;

export const deleteParameterDialogAction = formActionMacro(DeleteParameterDialog, 'deleteParameter');
