import { Duration, RtdValue } from './types';

export const rtdNullValue: RtdValue.Null = { kind: 'null' };

export function tagArray<T, K>(dataType: K, values: readonly T[]): RtdValue.RtdArray<T, K> {
    return { kind: 'array', tag: dataType, values };
}

export function tagScalar<T, K>(dataType: K, value: T): RtdValue.Scalar<T, K> {
    return { kind: 'scalar', tag: dataType, value };
}

export function fixedDuration(start: Date, end: Date): Duration.Fixed {
    return { kind: 'fixed', start, end };
}

export function taggedFixedDuration(start: Date, end: Date): RtdValue.Scalar<Duration.Fixed, 'duration'> {
    return tagScalar('duration', fixedDuration(start, end));
}

export function dynamicDuration(count: number, unit: Duration.TimeUnit): Duration.Dynamic {
    return { kind: 'dynamic', count, unit };
}

export function taggedDynamicDuration(
    count: number,
    unit: Duration.TimeUnit
): RtdValue.Scalar<Duration.Dynamic, 'duration'> {
    return tagScalar('duration', dynamicDuration(count, unit));
}
