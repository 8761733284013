import { ITileV5 } from './5';
import { IVisualOptionsV4 } from './5';

const BASE_Y_AXIS_ID = '-1';

export interface YAxisConfigV6 {
    readonly id: string;
    // "columns" property is only for the multiple y axes. for the base y axis this property is empty array.
    readonly columns: string[];
    readonly label: string;
    readonly yAxisMinimumValue: number | null;
    readonly yAxisMaximumValue: number | null;
    readonly yAxisScale: 'linear' | 'log';
}

export interface BaseYAxisConfigV6 extends YAxisConfigV6 {
    readonly horizontalLine: string;
}

export interface MultipleYAxesConfigV6 {
    base: BaseYAxisConfigV6;
    additional: YAxisConfigV6[];
}

export interface IVisualOptionsV6 extends Omit<IVisualOptionsV4, 'multipleYAxis'> {
    multipleYAxes: {
        base: BaseYAxisConfigV6;
        additional: YAxisConfigV6[];
    };
}

export interface ITileV6 extends Omit<ITileV5, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV6>;
}

export function up(prev: ITileV5): ITileV6 {
    const visualOptions: Partial<IVisualOptionsV6> = { ...prev.visualOptions };

    const { yColumnTitle, yAxisMinimumValue, yAxisMaximumValue, yAxisScale, horizontalLine } = visualOptions;

    // The following migration is migrating the old flat options of the y axis to nested options inside multipleYAxes.
    // This might happen even if not necessary (if multipleYAxes is not configured for this tile's visual), or might not happen at all if none of the previous flat values were configured.
    if (yColumnTitle || yAxisMinimumValue || yAxisMaximumValue || yAxisScale || horizontalLine) {
        const baseYAxis: BaseYAxisConfigV6 = {
            id: BASE_Y_AXIS_ID,
            columns: [],
            label: yColumnTitle ?? '',
            yAxisMinimumValue: yAxisMinimumValue?.type === 'specified' ? yAxisMinimumValue.value : null,
            yAxisMaximumValue: yAxisMaximumValue?.type === 'specified' ? yAxisMaximumValue.value : null,
            yAxisScale: yAxisScale || 'linear',
            horizontalLine: horizontalLine ?? '',
        };

        visualOptions.multipleYAxes = {
            base: baseYAxis,
            additional: [],
        };
    }

    return { ...prev, visualOptions };
}

export function down(prev: ITileV6): ITileV5 {
    // eslint-disable-next-line prefer-const
    let { multipleYAxes, ...visualOptions } = prev.visualOptions;

    // If a tile has "multipleYAxis" either it hasn't been saved since the
    // tileV6 up migration and this will do nothing, or it's been saved and the
    // visual supports multipleYAxis, otherwise it would have been removed.
    if (multipleYAxes) {
        const base = multipleYAxes.base;
        visualOptions = {
            ...visualOptions,
            yColumnTitle: base.label,
            yAxisMinimumValue:
                base.yAxisMinimumValue === null
                    ? { type: 'infer' }
                    : { type: 'specified', value: base.yAxisMinimumValue },
            yAxisMaximumValue:
                base.yAxisMaximumValue === null
                    ? { type: 'infer' }
                    : { type: 'specified', value: base.yAxisMaximumValue },
            yAxisScale: base.yAxisScale,
            horizontalLine: base.horizontalLine,
        };
    }

    return { ...prev, visualOptions };
}
