import type { Migrations } from '../../migrate';

import * as preSchema from './preSchema';
import * as v0 from './0';
import * as v1 from './1';
import * as v2 from './2';

export type UserSettingsVersioned = v0.UserSettingsV0 | v1.UserSettingsV1 | v2.UserSettingsV2;

export type UserSettingsResponse = preSchema.UserSettingsPreSchema | UserSettingsVersioned;

export type UserSettingsLatestVersion = v2.UserSettingsV2;

export const userSettingMigrations: Migrations<UserSettingsVersioned> = {
    migrations: {
        '0': { up: v1.up },
        '1': { up: v2.up },
        '2': {},
    },
    order: ['0', '1', '2'],
};
