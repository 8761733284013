import { Path, LocationDescriptorObject, LocationState } from 'history';
import { useCallback } from 'react';

import { useCore } from '../../../core';

/**
 * @deprecated This is overly complicated. Build urls manually for now. If/when
 *   that becomes a problem we can deal with it then. Maybe make some route specific
 *   helpers.
 *
 * Creates a url with the applied baseUrl, if applicable
 * @param url A Path (string) or LocationDescriptorObject to append the baseUrl to
 * @returns The modified url object with appended baseUrl
 */
export const useUrl = <A extends Path | LocationDescriptorObject<T>, T = LocationState>(url: A) => {
    return useUrlCallback<A, T>()(url);
};

/**
 * Creates a url with the applied baseUrl, if applicable. This differs from useUrl in that it allows for deferred execution
 * @param url A Path (string) or LocationDescriptorObject to append the baseUrl to
 * @returns A callback for deferred calculation of the modified url object with appended baseUrl
 */
export const useUrlCallback = <A extends Path | LocationDescriptorObject<T>, T = LocationState>() => {
    const baseUrl = useCore().hostConfig.baseUrl;

    // Unfortunately TypeScript is not smart enough to understand the ternary's requirements, hence the casts to any
    return useCallback(
        (url: A, stripBaseUrl = false): A extends Path ? Path : LocationDescriptorObject<T> => {
            if (typeof url === 'string') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return createPathnameForPath(url, baseUrl, stripBaseUrl) as any;
            }

            return createPathnameForLocationDescriptor(
                url as LocationDescriptorObject<T>,
                baseUrl,
                stripBaseUrl
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any;
        },
        [baseUrl]
    );
};

/**
 * Creates a baseUrl applied path using a Path string
 * @param path The path to append baseUrl to
 * @param baseUrl The baseUrl of the application
 * @param stripBaseUrl If true, attempt to remove the baseUrl from the beginning of path
 */
const createPathnameForPath = (path: Path, baseUrl: string | undefined, stripBaseUrl = false): string => {
    const pathname = stripBaseUrl ? pathnameWithoutBaseURL(path, baseUrl) : path;
    // Guaranteed to be non-undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return pathnameWithBaseUrl(pathname, baseUrl)!;
};

/**
 * Creates a baseUrl applied path using a LocationDescriptor
 * @param descriptor The LocationDescriptor to append baseUrl to
 * @param baseUrl The baseUrl of the application
 * @param stripBaseUrl If true, attempt to remove the baseUrl from the beginning of descriptor's pathname
 */
const createPathnameForLocationDescriptor = <T>(
    descriptor: LocationDescriptorObject<T>,
    baseUrl: string | undefined,
    stripBaseUrl = false
): LocationDescriptorObject<T> => {
    let pathname = descriptor.pathname;

    // Need to remove baseUrl before adding it, in some scenarios
    if (stripBaseUrl) {
        pathname = pathname ? pathnameWithoutBaseURL(pathname, baseUrl) : undefined;
    }

    return {
        ...descriptor,
        pathname: pathnameWithBaseUrl(pathname, baseUrl),
    };
};

/**
 * Strips baseUrl from the provided pathname
 * @param pathname The pathname to strip baseUrl from
 * @param baseUrl The baseUrl to strip
 */
const pathnameWithoutBaseURL = (pathname: string, baseUrl: string | undefined): string => {
    if (!baseUrl) {
        return pathname;
    }

    if (pathname.startsWith(baseUrl)) {
        return pathname.slice(baseUrl.length, pathname.length);
    }

    return pathname;
};

/**
 * Applies the baseUrl, if provided, to the provided pathname
 * @param pathname The pathname to apply baseUrl to
 * @param baseUrl The baseUrl to append
 */
const pathnameWithBaseUrl = (pathname: string | undefined, baseUrl: string | undefined): string | undefined => {
    if (!baseUrl) {
        return pathname;
    }

    return pathname !== undefined ? `${baseUrl}${pathname}` : undefined;
};
