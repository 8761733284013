/* eslint-disable @typescript-eslint/no-redeclare */
import { types } from 'mobx-state-tree';
import { DetailsViewType } from '@kusto/visualizations';
import { getTelemetryClient } from '../utils/telemetryClient';

const { trackEvent } = getTelemetryClient({ component: 'layoutStore', flow: '' });

export const connectionPaneMinWidthInPixels = 100;
export const connectionPaneDefaultWidth = '228px';

const WindowSizeType = types.enumeration('WindowSizeType', ['XXSmall', 'XSmall', 'Small', 'Medium', 'Large']);

// eslint-disable-next-line no-redeclare
export type WindowSizeType = typeof WindowSizeType.Type;

const WINDOW_WIDTH_TYPE_TO_RANGE: Record<WindowSizeType, [number, number]> = {
    XXSmall: [0, 320],
    XSmall: [320, 480],
    Small: [480, 624],
    Medium: [624, 860],
    Large: [860, Number.MAX_SAFE_INTEGER],
};

const WINDOW_HEIGHT_TYPE_TO_RANGE: Partial<Record<WindowSizeType, [number, number]>> = {
    Small: [0, 250],
    Medium: [250, 640],
    Large: [640, Number.MAX_SAFE_INTEGER],
};

const calcWindowSizeType: (dimension: 'height' | 'width') => WindowSizeType = (dimension) => {
    const WINDOW_SIZE_TO_RANGE = dimension === 'width' ? WINDOW_WIDTH_TYPE_TO_RANGE : WINDOW_HEIGHT_TYPE_TO_RANGE;
    const windowSizePixels = dimension === 'width' ? window.innerWidth : window.innerHeight;
    for (const [key, value] of Object.entries(WINDOW_SIZE_TO_RANGE)) {
        if (value && windowSizePixels > value[0] && windowSizePixels <= value[1]) {
            return key as WindowSizeType;
        }
    }
    return 'Large';
};

export const Layout = types
    .model('Layout', {
        /// left pane here is the connection pane - and not navigation pane.
        // couldn't change the nomenclature because of backward compat.
        leftPaneSize: types.maybe(types.string),
        isLeftPaneCollapsed: types.optional(types.boolean, false),
        isNavigationPaneCollapsed: types.optional(types.boolean, false),
        isMonacoEditorCollapsed: types.optional(types.boolean, false),
        windowWidthSizeType: types.maybe(WindowSizeType),
        expandViewLayout: types.optional(types.integer, DetailsViewType.InGrid),
        windowHeightSizeType: types.maybe(WindowSizeType),
    })
    .volatile(() => ({
        isSettingsPanelCollapsed: true,
        feedbackDialog: { name: '', open: false },
    }))
    .views((self) => ({
        get displayedExpandViewLayout() {
            return self.windowHeightSizeType === 'Small' ? DetailsViewType.InGrid : self.expandViewLayout;
        },
    }))
    .actions((self) => ({
        setExpandViewLayout: (layout: DetailsViewType) => (self.expandViewLayout = layout),
        setIsSettingsPanelCollapsed(isCollapsed: boolean) {
            self.isSettingsPanelCollapsed = isCollapsed;
        },
        setFeedbackDialog(feedbackDialog: { name: string; open: boolean }) {
            self.feedbackDialog = feedbackDialog;
        },
        saveLeftPaneSize(size: string) {
            trackEvent('Layout.SaveLeftPaneSize', {
                flow: 'Layout.SaveLeftPaneSize',
                size,
            });
            self.leftPaneSize = size;
        },
        setWindowSizeType(dimension: 'width' | 'height', sizeType: WindowSizeType) {
            if (dimension === 'width') {
                self.windowWidthSizeType = sizeType;
            } else {
                self.windowHeightSizeType = sizeType;
            }
        },
        toggleIsLeftPaneCollapse(isCollapsed?: boolean) {
            const newValue = isCollapsed ?? !self.isLeftPaneCollapsed;
            trackEvent('Layout.toggleIsLeftPaneCollapse', {
                flow: 'Layout.toggleIsLeftPaneCollapse',
                newValue: newValue.toString(),
            });

            self.isLeftPaneCollapsed = newValue;

            if (!self.isLeftPaneCollapsed) {
                if (self.leftPaneSize?.includes('px')) {
                    const pixels = parseFloat(self.leftPaneSize);
                    if (!isNaN(pixels) && pixels < connectionPaneMinWidthInPixels) {
                        self.leftPaneSize = connectionPaneDefaultWidth;
                    }
                }
            }
        },
        toggleNavigationPaneCollapsed() {
            const newValue = !self.isNavigationPaneCollapsed;
            trackEvent('Layout.toggleIsNavigationPaneCollapsed', {
                flow: 'Layout.toggleIsNavigationPaneCollapsed',
                newValue: newValue.toString(),
            });

            self.isNavigationPaneCollapsed = newValue;
        },
        setIsMonacoEditorCollapsed(isCollapsed: boolean) {
            self.isMonacoEditorCollapsed = isCollapsed;
        },
        handleWindowResize() {
            const windowWidthSizeType = calcWindowSizeType('width');
            const windowHeightSizeType = calcWindowSizeType('height');
            if (self.windowWidthSizeType !== windowWidthSizeType) {
                this.setWindowSizeType('width', windowWidthSizeType);
            }
            if (self.windowHeightSizeType !== windowHeightSizeType) {
                this.setWindowSizeType('height', windowHeightSizeType);
            }
        },
        afterCreate() {
            if (self.leftPaneSize === undefined || self.leftPaneSize.includes('%')) {
                // move away from percentage to pixels;
                self.leftPaneSize = connectionPaneDefaultWidth;
            }
            this.handleWindowResize();
            window.addEventListener('resize', this.handleWindowResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
    }));
// eslint-disable-next-line no-redeclare
export type Layout = typeof Layout.Type;
