export class ResourceCache {
    private cache: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [id: string]: Promise<any>;
    } = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public set(cacheId: string, data: Promise<any>) {
        this.cache[cacheId] = data;
    }

    public invalidate(cacheId: string) {
        delete this.cache[cacheId];
    }

    public get<T>(cacheId: string): Promise<T> | undefined {
        return this.cache[cacheId] as Promise<T>;
    }

    public getAllKeys(): string[] {
        return Object.keys(this.cache);
    }
}
