import { Dispatch } from 'react';

import { UDataSource } from '../../core/domain/dataSource';
import { manageDataSourcesAction } from '../../components/Forms/manage/ManageDataSources';
import { DashboardLoaded } from '../dashboard';

import { GlobalAction } from './types';

export const promptNewDataSource = (dashboardState: DashboardLoaded, dispatch: Dispatch<GlobalAction>) =>
    new Promise<UDataSource | undefined>((resolve) => {
        dispatch(
            manageDataSourcesAction({
                exclusiveSingleAdd: true,
                onComplete: (newDataSource) => resolve(newDataSource),
                dashboardState,
            })
        );
    });
