import { IDashboardDocument, IDashboardListItem } from '../domain';
import { InterfaceFor } from '../../common';
import {
    parseDashboardServerResponse,
    MigrationResult,
    DashboardServerResponse,
    DashboardSerialized,
    DashboardsServerMetaData,
} from '../../migration';

import { RestApiClient } from './RestApiClient';

export type IDashboardService = InterfaceFor<DashboardService>;

export class DashboardService {
    private readonly restApi: RestApiClient;

    constructor(metadataApiClient: RestApiClient) {
        this.restApi = metadataApiClient;
    }

    public getDashboards = (signal: AbortSignal): Promise<IDashboardListItem[]> =>
        this.restApi.httpGet('/dashboards', { signal }).then((response) => response.json());

    public getDashboard = (
        dashboardId: string,
        signal: AbortSignal
    ): Promise<MigrationResult<IDashboardDocument<DashboardsServerMetaData>>> =>
        this.restApi
            .httpGet(`/dashboards/${dashboardId}`, { signal })
            .then((response): Promise<DashboardServerResponse> => response.json())
            .then(parseDashboardServerResponse);

    public createDashboard = (
        dashboard: DashboardSerialized,
        signal: AbortSignal
    ): Promise<MigrationResult<IDashboardDocument<DashboardsServerMetaData>>> =>
        this.restApi
            .httpPost('/dashboards', dashboard, { signal })
            .then((response) => response.json())
            .then(parseDashboardServerResponse);

    public async forgetDashboard(dashboardId: string): Promise<void> {
        await this.restApi.httpPost(`/dashboards/${dashboardId}/forget`, dashboardId);
    }

    public updateDashboard = (
        dashboard: DashboardSerialized,
        signal: AbortSignal
    ): Promise<MigrationResult<IDashboardDocument<DashboardsServerMetaData>>> =>
        this.restApi
            .httpPut(`/dashboards/${dashboard.id}`, dashboard, { signal })
            .then((response): Promise<DashboardServerResponse> => response.json())
            .then(parseDashboardServerResponse);

    public async deleteDashboard(dashboardId: string, signal: AbortSignal): Promise<void> {
        await this.restApi.httpDelete(`/dashboards/${dashboardId}`, { signal });
    }
}
