import { computed, makeObservable, autorun, action, runInAction, makeAutoObservable, toJS } from 'mobx';
import { loadTheme } from 'office-ui-fabric-react';

import { debouncePromise } from '../../core';
import { osDarkThemeEnabled, lightTheme, darkTheme } from '../../domain/theming';
import { createTelemetry, createTelemetryException } from '../../domain/telemetry';
import type { UserSettings } from '../domain';
import type { IUserService, ITelemetryService } from '../backend';

import type { RtdHostConfig } from '../config';
import { DashboardStore } from '../../store';

export const defaultUserSettings: UserSettings = {
    theme: 'automatic',
    language: { type: 'infer' },
    regionalFormat: { type: 'infer' },
    enableChartPerformanceMode: false,
    enableAccessabilityOptimizationMode: true,
    favoriteDashboards: new Set(),
    pageNavOpen: false,
    editTilePageDividerPositionPercentage: 50,
    editTilePagePreviewExpanded: true,
};

export interface IUserSettingsState {
    readonly userSettings: Readonly<UserSettings>;
    readonly darkThemeEnabled: boolean;

    updateUserSetting<T extends keyof UserSettings>(
        key: T,
        value: UserSettings[T],
        dashboardStore: DashboardStore
    ): Promise<void>;
    toggleDashboardFavoriteStatus(dashboardId: string, dashboardStore: DashboardStore): Promise<void>;
}

export class UserSettingsState implements IUserSettingsState {
    private constructor(
        readonly userSettings: UserSettings,
        readonly userService: IUserService,
        readonly telemetryService: ITelemetryService,
        readonly hostStaticConfig: RtdHostConfig
    ) {
        // TODO: Triggers "observableRequiresReaction" warning for some reason. Added
        // "runInAction" to suppress
        runInAction(() => makeAutoObservable(this.userSettings));

        makeObservable(this, {
            updateUserSetting: action,
            toggleDashboardFavoriteStatus: action,
            darkThemeEnabled: computed,
        });

        this.updateUserSetting = this.updateUserSetting.bind(this);

        if (!this.hostStaticConfig.disableUiFabricThemeAutoLoad) {
            autorun(() => {
                loadTheme(this.darkThemeEnabled ? darkTheme.uiFabricTheme : lightTheme.uiFabricTheme);
            });
        }
    }

    static async init(
        userSettingsBackend: IUserService,
        telemetryService: ITelemetryService,
        config: RtdHostConfig
    ): Promise<IUserSettingsState> {
        return new UserSettingsState(
            // Intentionally block core loading on retrieval of user settings
            await userSettingsBackend.getSettings(),
            userSettingsBackend,
            telemetryService,
            config
        );
    }

    // TODO: This should be and observer, but the `getState` call is getting passed
    // in from the calling component
    saveUserSettings = debouncePromise(
        action(async (store: DashboardStore) => {
            const trackEvent = createTelemetry(undefined, this.telemetryService, store);
            trackEvent('StartSavingSettings');

            try {
                // TODO: resolve case where there are multiple tabs open at the same time
                // and some userSettings sync issues that would arise from that. Imagine
                // someone with two tabs open and editing favorites in both. As they
                // switch between tabs and update things it'd be very easy for them to
                // squash their own changes! Here's a work item for us to address this
                // case: https://msazure.visualstudio.com/One/_workitems/edit/9184880
                await this.userService.updateSettings(toJS(this.userSettings));
                trackEvent('SaveCompletedSettings');
            } catch (error) {
                // TODO: it would be nice to give the user some sort of indication that
                // something didn't work as expected here... what exactly that looks like
                // I'm not entirely sure. Here's a related work item: https://msazure.visualstudio.com/One/_workitems/edit/7180081
                const trackException = createTelemetryException(undefined, this.telemetryService, store);
                trackException(error);
            }
        }),
        1000
    );

    async updateUserSetting<T extends keyof UserSettings>(key: T, value: UserSettings[T], store: DashboardStore) {
        this.userSettings[key] = value;
        await this.saveUserSettings(store);
    }

    async toggleDashboardFavoriteStatus(dashboardId: string, store: DashboardStore) {
        const favorites = this.userSettings.favoriteDashboards;
        if (favorites.has(dashboardId)) {
            favorites.delete(dashboardId);
        } else {
            favorites.add(dashboardId);
        }
        await this.saveUserSettings(store);
    }

    /**
     * Standalone specific. Dashboards should use the value passed to it as an
     * argument
     */
    get darkThemeEnabled(): boolean {
        const theme = this.userSettings?.theme ?? 'automatic';
        if (theme !== 'automatic') {
            return theme === 'dark';
        }

        return osDarkThemeEnabled();
    }
}
