import { NotificationActions, ResourceTypes } from '../types';

export type IResourceMetadataStore<TResourceTypes extends ResourceTypes = ResourceTypes> = {
    [T in TResourceTypes]?: IResourceMetadataMap<T>;
};

export interface IResourceMetadataMap<T extends ResourceTypes = ResourceTypes> {
    [id: string]: IResourceMetadata<T>;
}

// eslint-disable-next-line no-shadow
export enum IResourceState {
    unstarted,
    loading,
    complete,
    error,
}

export interface IResourceMetadata<T extends ResourceTypes = ResourceTypes> {
    type: T;
    id: string;
    status: IResourceState;
}

export interface IResourceMetadataNotification<T extends ResourceTypes = ResourceTypes> {
    type: T;
    metadata: IResourceMetadata<T>;
    action: NotificationActions;
}

export type IResourceMetadataData<T extends ResourceTypes = ResourceTypes> = Omit<IResourceMetadata<T>, 'type' | 'id'>;
