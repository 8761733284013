import * as React from 'react';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { assertNever } from 'office-ui-fabric-react';

import { BasicParamValue, ParameterConfig, ParameterValue } from '../../domain';
import { IParameterSelections } from '../../store';

import { IParameterSelectorBaseProps, IParameterSelectorProps2 } from './selectors/types';
import { DropdownParameterSelector } from './selectors/DropdownParameterSelector';
import { DurationParameterSelector } from './selectors/DurationParameterSelector';
import { FreeTextParameterSelector } from './selectors/FreeTextParameterSelector';

export interface IParameterSelectorWrapperProps extends IParameterSelectorBaseProps {
    selections: IParameterSelections;
    parameterConfig: ParameterConfig;
    displayVariables: boolean;
}

export const ParameterSelector: React.FC<IParameterSelectorWrapperProps> = observer(function ParameterSelector({
    selections,
    displayVariables,
    parameterConfig,
    ...shared
}) {
    const variableNames = useMemo(
        () => (displayVariables && parameterConfig ? parameterConfig.variableNames : undefined),
        [parameterConfig, displayVariables]
    );

    const parameterValue = selections.selections[parameterConfig.id];

    if (!parameterValue) {
        return null;
    }

    return (
        <InnerParameterSelector
            {...shared}
            selections={selections}
            onChange={selections.setParameterValue}
            parameterValue={parameterValue}
            variableNames={variableNames}
        />
    );
});

export interface IParameterSelectorProps extends IParameterSelectorProps2 {
    parameterValue: ParameterValue;
    selections: IParameterSelections;
    onChange(paramValue: ParameterValue): void;
}

const InnerParameterSelector: React.FC<IParameterSelectorProps> = ({ parameterValue, selections, ...props }) => {
    switch (parameterValue.kind) {
        case 'basic': {
            if (parameterValue.config.options.selectionKind === 'freetext') {
                return (
                    <FreeTextParameterSelector
                        {...props}
                        // If generic arguments change, component state needs to be cleared
                        key={parameterValue.dataType}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        parameterValue={parameterValue as BasicParamValue<any, any>}
                    />
                );
            } else {
                return (
                    <DropdownParameterSelector
                        {...props}
                        // If generic arguments change, component state needs to be cleared
                        key={parameterValue.dataType}
                        selections={selections}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        parameterValue={parameterValue as BasicParamValue<any, any>}
                    />
                );
            }
        }
        case 'duration':
            return <DurationParameterSelector {...props} parameterValue={parameterValue} />;
        default:
            assertNever(parameterValue);
    }
};
