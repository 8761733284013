import type * as H from 'history';

import { RouteState } from '../../domain';
import { APP_CONSTANTS } from '../../res';

export class NavUtil {
    readonly path: {
        readonly catalog: string;
        readonly newDashboard: string;
        dashboard(dashboardId: string): string;
        editTile(dashboardId: string, tileId: string): string;
    };

    constructor(public readonly baseUrl: string) {
        const dashboard = (dashboardId: string) => `${this.baseUrl}/${dashboardId}`;
        this.path = {
            catalog: `${this.baseUrl}/`,
            newDashboard: dashboard('new'),
            dashboard,
            editTile: (dashboardId: string, tileId: string) => `${this.path.dashboard(dashboardId)}/edit/${tileId}`,
        };
    }

    newTile(dashboardId: string, type: 'markdown' | 'query', pageId: string): H.LocationDescriptor<RouteState> {
        return {
            pathname: this.path.editTile(
                dashboardId,
                type === 'markdown'
                    ? APP_CONSTANTS.editTilePage.newMarkdownTileId
                    : APP_CONSTANTS.editTilePage.newQueryTileId
            ),
            state: { newTilePageId: pageId },
        };
    }
}
