import type { UDataSource } from '../../../core/domain';
import {
    RGLLayout,
    infer,
    VisualOptions,
    ITile,
    VisualConfig,
    FallibleVisualTypeConfig,
    createTile,
    newKustoQuery,
    newSampleQuery,
    ParameterConfig,
    CrossFilterConfig,
} from '../../../domain';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import type { Mutable, CanInfer } from '../../../common';
import type { ETPQueryState, ETPMarkdownState, ETPState, ETPVisual } from '../../../store/editTile';
import { DashboardLoaded } from '../../../store';

import { inferableETPVisualOptions } from '../constants';
import { ETPVisualOptions } from '../types';

const resetDefaultOptionsToStaticValues = (newOptions: Mutable<ETPVisualOptions>) => {
    for (const option of inferableETPVisualOptions) {
        if (newOptions[option].type === 'infer') {
            newOptions[option] = infer;
        }
    }
};

/**
 * Updates property types, and replaces non-primitive options that match the
 * default option with that option.
 */
export function convertToEditPageVisualOptions(visualOptions: VisualOptions): ETPVisualOptions {
    const options: Mutable<ETPVisualOptions> = {
        ...visualOptions,
        crossFilter: visualOptions.crossFilter ?? {},
        yAxisMinimumValue: convertNumAxisToString(visualOptions.yAxisMinimumValue),
        yAxisMaximumValue: convertNumAxisToString(visualOptions.yAxisMaximumValue),
    };

    resetDefaultOptionsToStaticValues(options);

    return options;
}

/**
 * The tile may have been saved with a different visual matrix, causing options
 * to be present that are not available for the current visualType. We need to
 * remove those choices so if the visualType is switched new choices are set to
 * their default value.
 */
export const resetUnusedVisualOptions = (
    visualOptions: Partial<VisualOptions>,
    visualType: string,
    visualConfig: FallibleVisualTypeConfig
): Partial<VisualOptions> => {
    const config = visualConfig[visualType];
    if (!config) {
        return visualOptions;
    }
    const optionsNotInMatrix = APP_CONSTANTS.visuals.visualOptionKeys.filter(
        (o) => !config.configurationCompatibility.includes(o)
    );

    const newOptions = { ...visualOptions };
    for (const o of optionsNotInMatrix) {
        delete newOptions[o];
    }
    return newOptions;
};

export function convertNumAxisToString(value: CanInfer<number>): string {
    if (value.type === 'infer') {
        return '';
    }
    return value.value.toString();
}

export function convertStringAxisToNum(value: string): CanInfer<number> {
    if (value === '') {
        return infer;
    }
    const num = parseFloat(value);

    if (Number.isNaN(num)) {
        return infer;
    }

    return { type: 'specified', value: num };
}

function convertCrossFilter({ dimensionId, parameterId }: Partial<CrossFilterConfig>): null | CrossFilterConfig {
    if (dimensionId && parameterId) {
        return { dimensionId, parameterId };
    }
    return null;
}

export function convertFromEditVisualOptions(options: ETPVisualOptions): VisualOptions {
    return {
        ...options,
        crossFilter: convertCrossFilter(options.crossFilter),
        yAxisMaximumValue: convertStringAxisToNum(options.yAxisMaximumValue),
        yAxisMinimumValue: convertStringAxisToNum(options.yAxisMinimumValue),
    };
}

export const convertStringToNumber = (value?: string): number | null => {
    if (value === '' || value === undefined) {
        return null;
    }
    const num = parseFloat(value);

    if (Number.isNaN(num)) {
        return null;
    }

    return num;
};

/**
 * Creates a tile by combining an existing tile and the edit tile page reducer
 * state
 */
export function applyStateToExistingTile(
    state: ETPQueryState | ETPMarkdownState,
    originalTile: ITile,
    visualConfig: FallibleVisualTypeConfig
): ITile {
    let visualType: string;
    let visualOptions: Partial<VisualOptions>;

    if (state.type === 'markdown') {
        visualType = 'markdownCard';
        visualOptions = {};
    } else if (state.visual === undefined) {
        visualType = 'table';
        visualOptions = APP_CONSTANTS.visuals.defaultVisualOptions;
    } else {
        visualType = state.visual.visualType;
        visualOptions = convertFromEditVisualOptions(state.visual.options);
    }

    visualOptions = resetUnusedVisualOptions(visualOptions, visualType, visualConfig);

    /**
     * If the tiles type has changes, reset all unused options. Otherwise,
     * preserve the original tiles options for choices we don't support
     */
    if (visualType === originalTile.visualType) {
        visualOptions = { ...originalTile.visualOptions, ...visualOptions };
    }

    return {
        ...originalTile,
        visualType,
        title: state.title,
        query: state.query,
        usedVariables: state.type === 'query' ? state.usedVariables : new Set(),
        dataSourceId: state.type === 'query' ? state.dataSourceId : undefined,
        visualOptions,
    };
}

/**
 * Creates a tile from the edit tile page reducer state without referencing
 * and existing tile
 */
export function createNewTileFromState<T extends string>(
    state: ETPQueryState | ETPMarkdownState,
    visualConfig: VisualConfig<T>,
    layout: readonly RGLLayout[],
    pageId: string
) {
    let visualOptions: Partial<VisualOptions>;
    let visualType: string;
    let dataSourceId: string | undefined = undefined;
    let usedParamVariables: Set<string> | undefined = undefined;

    switch (state.type) {
        case 'markdown':
            visualType = 'markdownCard';
            visualOptions = {};
            break;
        case 'query':
            dataSourceId = state.dataSourceId;
            usedParamVariables = state.usedVariables;
            if (state.visual) {
                visualType = state.visual.visualType;
                visualOptions = resetUnusedVisualOptions(
                    convertFromEditVisualOptions(state.visual.options),
                    state.visual.visualType,
                    visualConfig.visualTypes
                );
            } else {
                visualType = APP_CONSTANTS.editTilePage.noVisualAddedDefault;
                visualOptions = resetUnusedVisualOptions(
                    APP_CONSTANTS.visuals.defaultVisualOptions,
                    visualType,
                    visualConfig.visualTypes
                );
            }
            break;
    }

    return createTile({
        title: state.title,
        query: state.query,
        dataSourceId,
        visualConfig: visualConfig.visualTypes,
        pageId,
        pageLayout: layout,
        id: state.type === 'query' ? state.tileId : undefined,
        visualOptions,
        visualType,
        usedVariables: usedParamVariables,
    });
}

/**
 * Throws if a visual hasn't been added
 */
export function visualAddedSelectorBuilder<T>(selector: (state: ETPVisual) => T) {
    return (state: ETPState): T => {
        if (state.type !== 'query' || state.visual === undefined) {
            throw new Error();
        }
        return selector(state.visual);
    };
}

export function visualOptionsSelectorBuilder<T>(selector: (options: ETPVisualOptions) => T) {
    return visualAddedSelectorBuilder((state) => selector(state.options));
}

/**
 * Build a query with representative parameter values for purposes of obtaining the hash
 */
export function buildQueryHashFromTile(
    tile: ITile,
    state: DashboardLoaded,
    dataSource: UDataSource
): undefined | string {
    const paramValues = state.selectedParameters.resolveParameterValues(tile.usedVariables);
    if (paramValues.kind === 'err') {
        return undefined;
    }
    return newKustoQuery(tile.query, dataSource, tile.id, paramValues.value).queryHash;
}

export function buildSampleKustoQuery(parameters: readonly ParameterConfig[]): string {
    return newSampleQuery(APP_STRINGS.editTilePage.querySamples.tableName, parameters, {
        commentOut: true,
        prefix: APP_STRINGS.editTilePage.querySamples.prefix,
        addTrailingNewline: true,
        includeDocComments: true,
    });
}
