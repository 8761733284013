import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    CommandBarButton,
    ContextualMenu,
    DirectionalHint,
    IButtonProps,
    IContextualMenuProps,
    ITextField,
    TextField,
    IContextualMenuItem,
    focusAsync,
} from 'office-ui-fabric-react';
import * as React from 'react';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { rtdPrompt } from '../../../../components';
import { hrefCapturing } from '../../../../common';
import { IPage } from '../../../../core/domain/dashboard';
import { APP_STRINGS } from '../../../../res';
import { DashboardLoaded, GlobalAction, useGlobalDispatch } from '../../../../store';

import styles from './PagesNav.module.scss';

const appStringsLocal = APP_STRINGS.dashboardPage.pagesNav;

const pageRowHeight = 36;
/**
 * Amount button must be moved before dragging starts
 */
const clickDragCliff = 10;

async function deletePage(pageState: DashboardLoaded, dispatch: React.Dispatch<GlobalAction>, pageId: string) {
    if (runInAction(() => pageState.pages).length === 1) {
        return;
    }

    const pageLayout = runInAction(() => pageState.tilesLayout[pageId]);

    const strings = APP_STRINGS.dashboardPage.pagesNav.deletePagePrompt;
    const componentTile = APP_STRINGS.components.tile;

    if (
        pageLayout.length !== 0 &&
        !(await rtdPrompt(
            dispatch,
            <>
                {strings.title} <i>{runInAction(() => pageState.pagesRecord[pageId]).name}</i>
            </>,
            {
                subText: `${strings.subText1} ${pageLayout.length} ${
                    pageLayout.length === 1 ? componentTile.tileSingular : componentTile.tilePlural
                } ${strings.subtext2}`,
                acceptText: APP_STRINGS.utilButtons.delete,
            }
        ))
    ) {
        return;
    }

    for (const layout of pageLayout) {
        pageState.deleteItem('tiles', layout.i);
    }
    pageState.deleteItem('pages', pageId);
}

function useRowMouseDown(pageState: DashboardLoaded, pageId: string, setDragging: (dragging: boolean) => void) {
    const [draggingBasedOffset, setDraggingBasedOffset] = useState<undefined | number>(undefined);

    const clearEventListeners = useRef<undefined | (() => void)>(undefined);

    useEffect(
        () => () => {
            clearEventListeners.current?.();
        },
        []
    );

    function onRowMouseDown(mouseDown: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (runInAction(() => pageState.changes === undefined)) {
            return;
        }

        const initialClientY = mouseDown.clientY;
        const containerElement = mouseDown.currentTarget.parentElement;
        if (containerElement === null) {
            return;
        }
        const nonNullContainer = containerElement;
        const initialContainerTop = nonNullContainer.getBoundingClientRect().top;

        let scrollChange = 0;
        let mouseChange = 0;

        function updateScrollChange() {
            scrollChange = initialContainerTop - nonNullContainer.getBoundingClientRect().top;
        }

        function updateMouseChange(mouseMove: MouseEvent) {
            mouseChange = mouseMove.clientY - initialClientY;
        }

        {
            function onMouseMove(mouseMove: MouseEvent) {
                updateMouseChange(mouseMove);
                dragTriggerListener();
            }

            function onScroll() {
                updateScrollChange();
                dragTriggerListener();
            }

            function dragTriggerListener() {
                const change = scrollChange + mouseChange;
                if (Math.abs(change) > clickDragCliff) {
                    startDragging();
                }
            }

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('scroll', onScroll, true);
            document.addEventListener('mouseup', cleanup);

            function cleanup() {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('scroll', onScroll, true);
                document.removeEventListener('mouseup', cleanup);
            }

            clearEventListeners.current?.();
            clearEventListeners.current = cleanup;
        }

        const startDragging = action(() => {
            setDragging(true);
            document.body.classList.add(styles.documentDragging);

            const initialPosition = pageState.pagesNav.pageIndexById[pageId];
            const baseOffset = pageRowHeight * initialPosition;
            const maxOffset = (pageState.pagesNav.layout.length - 1) * pageRowHeight;
            let currentPosition = initialPosition;

            applyChanges();

            function applyChanges() {
                const newOffset = Math.min(Math.max(baseOffset + scrollChange + mouseChange, 0), maxOffset);
                const newPosition = Math.round(newOffset / pageRowHeight);
                setDraggingBasedOffset(newOffset);
                if (newPosition !== currentPosition) {
                    currentPosition = newPosition;
                    pageState.pagesNav.changePageIndex(pageId, newPosition);
                }
            }

            function onMouseMove(event: MouseEvent) {
                updateMouseChange(event);
                applyChanges();
            }

            let scrollUpdateAnimationFrameHandle = 0;

            function onScroll() {
                if (scrollUpdateAnimationFrameHandle) {
                    return;
                }
                scrollUpdateAnimationFrameHandle = requestAnimationFrame(() => {
                    updateScrollChange();
                    applyChanges();
                    scrollUpdateAnimationFrameHandle = 0;
                });
            }

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('scroll', onScroll, true);
            document.addEventListener('mouseup', cleanup);

            function cleanup() {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('scroll', onScroll, true);
                document.removeEventListener('mouseup', cleanup);
                cancelAnimationFrame(scrollUpdateAnimationFrameHandle);
                setDraggingBasedOffset(undefined);
                clearEventListeners.current = undefined;
                document.body.classList.remove(styles.documentDragging);
                setDragging(false);
            }

            clearEventListeners.current?.();
            clearEventListeners.current = cleanup;
        });
    }

    return { onRowMouseDown, draggingBasedOffset };
}

const onRenderMoveItemOption: IContextualMenuItem['onRenderContent'] = (props) => (
    <span className={props.classNames.label}>
        {appStringsLocal.reorderPagePageOptionPrefix} <i>{props.item.data}</i>
    </span>
);

interface PageMenuProps extends Omit<IContextualMenuProps, 'items'> {
    pageState: DashboardLoaded;
    pageId: string;
}

const PageMenu: React.FC<PageMenuProps> = observer(function CPageMenu({ pageState, pageId, ...props }) {
    const [dispatch] = useGlobalDispatch();
    const pageIndex = pageState.pagesNav.pageIndexById[pageId];

    const layout = pageState.pagesNav.layout;
    const pagesRecord = pageState.pagesRecord;

    const reorderPageMenuItems: IContextualMenuItem[] = [];

    for (let i = 0; i < pageState.pages.length; i++) {
        const newIndex = pageIndex < i ? i - 1 : i;

        if (pageIndex !== newIndex) {
            reorderPageMenuItems.push({
                data: pagesRecord[layout[i]].name,
                key: i.toString(),
                onClick: () => {
                    pageState.pagesNav.changePageIndex(pageId, newIndex);
                },
                onRenderContent: onRenderMoveItemOption,
            });
        }
    }

    reorderPageMenuItems.push({
        key: 'bottom',
        text: appStringsLocal.reorderPageBottomOptionText,
        disabled: pageIndex === layout.length - 1,
        onClick: () =>
            pageState.pagesNav.changePageIndex(
                pageId,
                runInAction(() => pageState.pages.length - 1)
            ),
    });

    const onRenamePage = action(() => {
        pageState.pagesNav.renamePage = {
            pageId,
            onFormClosed: () => {
                setTimeout(() => {
                    const element = document.querySelector(`[data-page-menu="${pageId}"]`);
                    if (!(element instanceof HTMLElement)) {
                        throw new Error('Unable to find next page to focus after deleting active page');
                    }
                    focusAsync(element);
                }, 0);
            },
        };
    });

    const onDeletePage = () => {
        const currentPageIndex = pageState.pagesNav.pageIndexById[pageId];
        let targetPageIndex = currentPageIndex + 1;

        if (targetPageIndex > pageState.pagesNav.layout.length - 1) {
            // there is no "next" page so just choose the previous one
            targetPageIndex = currentPageIndex - 1 < 0 ? 0 : currentPageIndex - 1;
        }

        const targetPageId = pageState.pagesNav.layout[targetPageIndex];
        const element = document.querySelector(`[data-page="${targetPageId}"]`);

        deletePage(pageState, dispatch, pageId);

        /**
         * We need to manually focus the next page menu item
         * or else after deleting the user will lose focus
         * (almost as if it's focusing a non-existent element)
         * and they must tab again to focus the next page menu item
         */
        if (element instanceof HTMLElement) {
            focusAsync(element);
        }
    };

    return (
        <ContextualMenu
            {...props}
            items={[
                {
                    key: 'renamePage',
                    text: appStringsLocal.renamePageButtonText,
                    onClick: onRenamePage,
                },
                {
                    key: 'reorderPage',
                    text: appStringsLocal.reorderPageButtonText,
                    subMenuProps: { items: reorderPageMenuItems },
                },
                {
                    key: 'deletePage',
                    text: appStringsLocal.deletePageButtonText,
                    disabled: pageState.pages.length === 1,
                    onClick: onDeletePage,
                },
            ]}
        />
    );
});

const noopPageMenuProps: IContextualMenuProps = { items: [{ key: 'noop' }] };
const menuItemProps = { iconName: 'more' };

const preventDefault: IButtonProps['onDragStart'] = (event) => event.preventDefault();

interface PageRowProps {
    page: IPage;
    pageState: DashboardLoaded;
    onMenuOpen: () => void;
    onMenuDismiss: () => void;
    setDragging: (dragging: boolean) => void;
    dragging: boolean;
    position: number;
    selected: boolean;
}

const PageRow: React.FC<PageRowProps> = observer(function PageRow({
    page,
    pageState,
    onMenuOpen,
    onMenuDismiss,
    dragging,
    setDragging,
    position,
    selected,
}) {
    const history = useHistory();
    // NOTE: Creating the callback on the pages component and not individual pages
    // might increase perf due to only storing related state once, and will
    // fixed multiple items getting dragged at once, if we ever have that problem.
    const { draggingBasedOffset, onRowMouseDown } = useRowMouseDown(pageState, page.id, setDragging);
    const offset = draggingBasedOffset ?? pageRowHeight * position;

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div
            className={styles.pageRow}
            data-selected={selected ? true : undefined}
            data-dragging={draggingBasedOffset === undefined ? undefined : true}
            style={{ transform: `translateY(${offset}px)` }}
            role="group"
            onMouseDown={onRowMouseDown}
        >
            <CommandBarButton
                className={styles.switchPageButton}
                href={`#${page.id}`}
                // Without this link dragging interrupts our dragging code
                onDragStart={preventDefault}
                onClick={hrefCapturing(() => history.push({ ...history.location, hash: page.id }))}
                disabled={dragging}
                data-page={page.id}
            >
                {page.name}
            </CommandBarButton>
            {pageState.changes && (
                <CommandBarButton
                    disabled={dragging}
                    className={styles.pageMenu}
                    data-page-menu={page.id}
                    menuIconProps={menuItemProps}
                    ariaLabel={APP_STRINGS.dashboardPage.pagesNav.morePageActions}
                    menuAs={(props) => (
                        // Render menu with a separate component so items evaluation is lazy
                        <PageMenu
                            {...props}
                            pageId={page.id}
                            pageState={pageState}
                            onMenuOpened={onMenuOpen}
                            onMenuDismissed={onMenuDismiss}
                            directionalHint={DirectionalHint.rightTopEdge}
                        />
                    )}
                    menuProps={noopPageMenuProps}
                />
            )}
        </div>
    );
});

interface PageNameEditorProps {
    page: IPage;
    pageState: DashboardLoaded;
    onFormClosed: () => void;
    position: number;
}

const PageNameEditor: React.FC<PageNameEditorProps> = observer(function PageNameEditor({
    page,
    pageState,
    onFormClosed,
    position,
}) {
    const [name, setName] = useState(page.name);
    const valid = name !== '';
    const ref = useRef<ITextField>(null);
    useLayoutEffect(() => {
        ref.current?.select();
    }, []);
    useLayoutEffect(() => onFormClosed, [onFormClosed]);

    return (
        <form
            className={styles.pageNameForm}
            onSubmit={(event) => {
                event.preventDefault();
                if (valid) {
                    runInAction(() => {
                        pageState.pagesNav.renamePage = undefined;
                        pageState.addItem('pages', { ...page, name });
                    });
                }
            }}
            style={{
                transform: `translateY(${pageRowHeight * position}px)`,
            }}
        >
            <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                componentRef={ref}
                onBlur={() =>
                    runInAction(() => {
                        pageState.pagesNav.renamePage = undefined;
                        if (valid) {
                            pageState.addItem('pages', { ...page, name });
                        }
                    })
                }
                autoComplete="off"
                value={name}
                onChange={(_event: unknown, value) => {
                    if (value !== undefined) {
                        setName(value);
                    }
                }}
            />
        </form>
    );
});

interface PagesProps {
    pageState: DashboardLoaded;
    onMenuOpen: () => void;
    onMenuDismiss: () => void;
}

const pagesStyle = { flexBasis: '0px' };

export const Pages: React.FC<PagesProps> = observer(function Pages({ pageState, onMenuOpen, onMenuDismiss }) {
    const [dragging, setDragging] = useState(false);
    const pagesNav = pageState.pagesNav;
    const selectedPageId = useLocation().hash.slice(1);

    return (
        <nav
            className={styles.pages}
            // CRA is removing the '0px' flex basis in the css. Applying it here
            // to get around that.
            style={pagesStyle}
            data-dragging-row={dragging ? true : undefined}
        >
            <div
                // absolutely positioned elements don't respect the padding. Explicitly
                // adding content box height to ensure bottom padding is respected
                style={{ height: pagesNav.layout.length * pageRowHeight }}
            >
                {/* Important that we _don't_ take position into account when rendering
          the pages. Changes the order in the dom will break animations */}
                {pageState.pages.map((page) => {
                    const position = pagesNav.pageIndexById[page.id];
                    if (page.id === pagesNav.renamePage?.pageId) {
                        return (
                            <PageNameEditor
                                key={page.id}
                                page={page}
                                pageState={pageState}
                                onFormClosed={pagesNav.renamePage.onFormClosed}
                                position={position}
                            />
                        );
                    }
                    return (
                        <PageRow
                            key={page.id}
                            page={page}
                            pageState={pageState}
                            onMenuOpen={onMenuOpen}
                            onMenuDismiss={onMenuDismiss}
                            dragging={dragging}
                            setDragging={setDragging}
                            position={position}
                            selected={selectedPageId === page.id}
                        />
                    );
                })}
            </div>
        </nav>
    );
});
