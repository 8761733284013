import * as React from 'react';
import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { reaction } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';

import { APP_CONSTANTS } from '../../../res';
import { useDashboardStore } from '../../../store';
import { ShadowHr } from '../../../components';
import { useCore } from '../../../core';
import { RouteState } from '../../../domain';

import { DashboardTiles, IDashboardTilesProps } from './DashboardTiles';
import { DashboardPageContent } from './DashboardPageContent';
import { DashboardParameters } from './DashboardParameters';
import { PagesNav } from './PagesNav';

import styles from './Dashboard.module.scss';

const useSyncAutoRefresh = () => {
    const core = useCore();
    const store = useDashboardStore();

    useEffect(
        () =>
            reaction(
                () => {
                    if (store.state === undefined) {
                        return { kind: 'loading' as const };
                    }
                    if (store.state.changes) {
                        return { kind: 'editing' as const };
                    }
                    if (store.state.currentAutoRefreshRate) {
                        return {
                            kind: 'enabled' as const,
                            rate: store.state.currentAutoRefreshRate,
                            dashboardId: store.state.meta.id,
                        };
                    }
                    return { kind: 'disabled' as const };
                },
                (res) => {
                    switch (res.kind) {
                        case 'loading':
                            core.queryService.setTimerBackground(true);
                            break;
                        case 'disabled':
                        case 'editing':
                            core.queryService.cancelTimer();
                            break;
                        default:
                            core.queryService.setTimerBackground(false);
                            core.queryService.startTimer(
                                res.dashboardId,
                                APP_CONSTANTS.supportedAutoRefreshIntervals[res.rate].interval
                            );
                    }
                }
            ),
        [store, core]
    );
};

type TilesWithPageSelectedProps = Omit<IDashboardTilesProps, 'selectedPageId' | 'scrollToTile'>;

// Memoize so that changes to query or hash don't affect this
const MemoDashboardTiles = React.memo(DashboardTiles);

const TilesWithLocationInfo: React.FC<TilesWithPageSelectedProps> = observer(function (props) {
    const history = useHistory();
    const location = useLocation<RouteState>();

    const hashLocation = location.hash.slice(1);

    const selectedPageId = hashLocation in props.pageState.pagesRecord ? hashLocation : props.pageState.pages[0].id;

    // If page id in hash was corrected, update location to match
    useEffect(() => {
        if (hashLocation !== selectedPageId) {
            history.replace({
                ...history.location,
                hash: selectedPageId,
            });
        }
    }, [selectedPageId, hashLocation, history]);

    return <MemoDashboardTiles selectedPageId={selectedPageId} {...props} />;
});

export const Dashboard: React.FC = observer(function Dashboard() {
    useSyncAutoRefresh();
    const store = useDashboardStore();

    const dashboardTilesScrollRef = useRef<HTMLDivElement | null>(null);

    return (
        <DashboardPageContent>
            <div className={styles.header}>
                <DashboardParameters />
                <ShadowHr className={styles.headerBottom} />
            </div>
            {store.state && <PagesNav state={store.state} />}
            <div ref={dashboardTilesScrollRef} className={styles.tiles} data-automation-id="dashboard-tiles">
                {store.state && (
                    <TilesWithLocationInfo scrollContainer={dashboardTilesScrollRef} pageState={store.state} />
                )}
            </div>
        </DashboardPageContent>
    );
});
