import { Subject } from 'rxjs';

import type { ITelemetryService } from '../../core/backend';

import {
    IResourceErrorStore,
    ErrorConfigDataTypeMap,
    IResourceError,
    IResourceErrorResult,
    ErrorTypes,
    IResourceErrorNotification,
} from './types';
import { errorsTypeConfig } from './errorsConfig';

export class ResourceErrors {
    private readonly errors: IResourceErrorStore = {};
    public readonly subject = new Subject<IResourceErrorNotification>();

    constructor(public readonly telemetryService?: ITelemetryService) {}

    public push<T extends ErrorTypes>(error: IResourceError<ErrorConfigDataTypeMap[T], T>) {
        if (this.telemetryService) {
            this.telemetryService.trackResourceError(error, {});
        }

        const type = error.type;
        const configEntry = errorsTypeConfig[type];

        // Cast to make sure generic is understood
        let currentErrors = this.errors[type] as Array<IResourceError<ErrorConfigDataTypeMap[T], T>> | undefined;

        if (!currentErrors) {
            currentErrors = [];
            this.errors[type] = currentErrors;
        }

        const length = currentErrors.length;

        const newErrors =
            length + 1 > configEntry.allowedCount
                ? // New array will be too long
                  // Remove first element and add new error
                  [...currentErrors.slice(1, currentErrors.length), error]
                : [...currentErrors, error];

        this.errors[type] = newErrors;

        // eslint-disable-next-line no-console
        console.error(type, error, 'push');

        this.subject.next({
            type,
            error,
            action: 'push',
        });
    }

    public dismiss<T extends ErrorTypes>(error: IResourceError<ErrorConfigDataTypeMap[T], T>) {
        const type = error.type;

        // Cast to make sure generic is understood
        const currentErrors = this.errors[type] as Array<IResourceError<ErrorConfigDataTypeMap[T], T>> | undefined;

        if (!currentErrors) {
            return;
        }

        let newErrors: Array<IResourceError<ErrorConfigDataTypeMap[T], T>> | undefined = currentErrors.filter(
            (item) => item !== error
        );

        if (newErrors.length === currentErrors.length) {
            // Error was not removed
            return;
        }

        if (newErrors.length < 1) {
            newErrors = undefined;
        }

        this.errors[type] = newErrors;

        this.subject.next({
            type,
            error,
            action: 'remove',
        });
    }

    public get<T extends ErrorTypes>(type: T): IResourceErrorResult<T> {
        return this.errors[type] as IResourceErrorResult<T>;
    }

    public getAllActiveTypes(): ErrorTypes[] {
        return Object.keys(this.errors) as ErrorTypes[];
    }

    public clearAllErrors() {
        const activeTypes = this.getAllActiveTypes();
        for (const type of activeTypes) {
            const errors = this.get(type);

            if (!errors) {
                continue;
            }

            for (const error of errors) {
                this.dismiss(error);
            }
        }
    }
}
