// import { useContext } from 'react';
// import { TelemetryContext } from '../components/Telemetry'

export interface ErrorDescription {
    code?: string;
    errorMessage: string;
    line?: number;
    pos?: number;
    token?: string;
}

// get Error message
const getErrorMessage = (error: any) =>
    error?.['@errorMessage'] ||
    error?.['@message'] ||
    // Aria/proxy error messages -
    // {"name":"SyntaxError","message":"Unexpected 'I'","at":1,"text":"Invalid database or tenant."}
    (error?.at && error?.text) ||
    error?.Message ||
    error?.message ||
    undefined;

// Try to find the deeper error object as exception/error might be wrapped multiple times (proxy cluster)
// Steps:
//   1. error obj might be stringify, try to parse
//   2. Recursively check if the root cause error is in one of the "known" parameters (error, innererror, message)
//      if we found the deeper error return it
//   3. check if error is eligible error obj - have error message & error code

const tryToLocateRootCauseError = (error: any): any => {
    if (!error) {
        return undefined;
    }
    try {
        // Sometime the error obj is serialized
        const errorObj = typeof error === 'string' ? JSON.parse(error) : error;

        // first
        const deeperError =
            tryToLocateRootCauseError(errorObj.error) ||
            tryToLocateRootCauseError(errorObj.innererror) ||
            tryToLocateRootCauseError(error.message);

        if (deeperError) {
            return deeperError;
        }

        return getErrorMessage(errorObj) && errorObj?.code ? errorObj : undefined;
    } catch (e) {
        // parsing of string error message failed, its not error obj
    }
    return undefined;
};

// Collect good sampling of error result from different sources
export const extractErrorDescriptionAndTrace = (exception: any) => {
    // TODO: move to kustoweb
    // const getTelemetryClient = useContext(TelemetryContext);
    // const { trackEvent } = getTelemetryClient({ component: 'errorParser', flow: '' });

    const error = extractErrorDescription(exception);
    // trackEvent('extractErrorDescription', {
    //     originalError: JSON.stringify(exception),
    //     resultError: JSON.stringify(error)
    // });
    return error;
};

export const extractErrorDescription = (exception: any): ErrorDescription => {
    let rootError = tryToLocateRootCauseError(exception);

    let errorMessage = rootError ? getErrorMessage(rootError) : getErrorMessage(exception);

    // Newlines are esacped in the string, we unescape them here.
    if (errorMessage && typeof errorMessage == 'string') {
        errorMessage = (errorMessage as string).replace(/\\r/g, '\r').replace(/\\n/g, '\n');
    }

    const line =
        (rootError?.['line'] && parseInt(rootError['line'], 10)) ??
        (rootError?.['@line'] && parseInt(rootError['@line'], 10)) ??
        undefined;
    const pos =
        (rootError?.['pos'] && parseInt(rootError['pos'], 10)) ??
        (rootError?.['@pos'] && parseInt(rootError['@pos'], 10)) ??
        undefined;
    const token = rootError?.['token'] ?? rootError?.['@token'];
    const code = rootError?.code;

    return { errorMessage, line, pos, token, code };
};

export const buildErrorString = (description: ErrorDescription): string => {
    const codeToText: { [error: string]: string } = {
        LimitsExceeded: 'Query Limits Exceeded',
        SEM0001: 'Semantic Error',
        SYN0002: 'Syntax Error',
    };

    let message;
    const { token, pos, line, errorMessage, code } = description;
    let errorType = codeToText[code!];
    errorType = errorType || 'Error';
    if (token) {
        // tslint:disable-next-line:max-line-length
        message = `${errorType} \n\n ${errorMessage} \n Token: ${token} \n\n Line: ${line}, Position: ${pos}`;
    } else {
        message = `${errorType} \n\n ${errorMessage}`;
    }

    return message;
};

// Some error examples
// =======================

//////////////// Applicationinsights Proxy Examples

// cluster: https://ade.applicationinsights.io/subscriptions/f3101802-8c4f-4e6e-819c-a3b5794d33dd/resourcegroups/kustoweb/providers/microsoft.insights/components/kustoweb
// browserTimings
// | where timestamp  >ago(3d)
// | summarize count() by bin(3d, timestamp)
// {
//     {
//     error: {
//         code: 'FailedToQueryDraft',
//         message: 'failed to query Draft resource name: kustoweb, resource id: 4471c4f6-c89e-4d0c-b4ee-55351868040d',
//         innererror: {
//             code: 'Bad Request',
//             message:
//                 '{"error":{"message":"The request had some invalid properties","code":"BadArgumentError","innererror":{"code":"SemanticError","message":"A semantic error occurred.","innererror":{"code":"SEM0210","message":"bin(): argument #2 must be a positive constant timespan (1 tick or higher)"}}}}',
//             statusCode: 400
//         },
//         '@type': 'adxproxy.Exceptions.FailedToQueryDraft',
//         '@message': 'failed to query Draft resource name: kustoweb, resource id: 4471c4f6-c89e-4d0c-b4ee-55351868040d',
//         '@context': {
//             timestamp: '2020-05-06T10:16:53.134Z',
//             serviceAlias: 'AI-s-f3101802-8c4f-4e6e-819c-a3b5794d33dd-g-kustoweb-r-kustoweb',
//             machineName: 'prod-ade-proxy-ai-65979cfd45-4f2fp',
//             processName: 'adxproxy',
//             processId: 1,
//             appDomainName: 'DevExp-Proxy',
//             clientRequestd: 'KustoWebV2;b0971ea3-2fe3-4fb2-89d2-82bac3567920',
//             activityId: 'e4243021-41a1-4192-a39f-f54d3d73f200',
//             activityType: 'query',
//             version: '0.2.407',
//             productVersion: 'AdxproxyOverAI_2020.04.08.1',
//             buildTime: '2020-04-08T00:19:49.761Z',
//             environmentName: 'prod-ai-eau',
//             instanceResourceGroup: 'draft-prod-eau-v2',
//             instanceCluster: 'draft-prod-eau-v2',
//             instanceStamp: 'prod-eau',
//             instanceRegion: 'australiaeast',
//             instanceGeography: 'australia'
//         },
//         '@url':
//             '/subscriptions/f3101802-8c4f-4e6e-819c-a3b5794d33dd/resourcegroups/kustoweb/providers/microsoft.insights/components/kustoweb/v1/rest/query',
//         '@text': 'browserTimings\n| where timestamp  >ago(3d) \n| summarize count() by bin(3d, timestamp)',
//         '@database': 'kustoweb',
//         '@cluster':
//             '/subscriptions/f3101802-8c4f-4e6e-819c-a3b5794d33dd/resourcegroups/kustoweb/providers/microsoft.insights/components/kustoweb',
//         '@permanent': true
//     }
// };

// =======================
// cluster: https://ade.applicationinsights.io/subscriptions/f3101802-8c4f-4e6e-819c-a3b5794d33dd/resourcegroups/kustoweb/providers/microsoft.insights/components/kustoweb
// let a=1
// browserTimings
// | where ksdf == 2
//  {
//     error: {
//         code: 'FailedToQueryDraft',
//         message: 'failed to query Draft resource name: kustoweb, resource id: 4471c4f6-c89e-4d0c-b4ee-55351868040d',
//         innererror: {
//             code: 'Bad Request',
//             message:
//                 '{"error":{"message":"The request had some invalid properties","code":"BadArgumentError","innererror":{"code":"SyntaxError","message":"A recognition error occurred in the query.","innererror":{"code":"SYN0002","message":"Query could not be parsed at \'browserTimings\' on line [2,0]","line":2,"pos":0,"token":"browserTimings"}}}}',
//             statusCode: 400
//         },
//         '@type': 'adxproxy.Exceptions.FailedToQueryDraft',
//         '@message': 'failed to query Draft resource name: kustoweb, resource id: 4471c4f6-c89e-4d0c-b4ee-55351868040d',
//         '@context': {
//             timestamp: '2020-05-06T10:27:47.715Z',
//             serviceAlias: 'AI-s-f3101802-8c4f-4e6e-819c-a3b5794d33dd-g-kustoweb-r-kustoweb',
//             machineName: 'prod-ade-proxy-ai-65979cfd45-4f2fp',
//             processName: 'adxproxy',
//             processId: 1,
//             appDomainName: 'DevExp-Proxy',
//             clientRequestd: 'KustoWebV2;a4c4e638-12aa-4a60-8fab-d3c00774ae94',
//             activityId: '657c12a8-4e23-4af3-ae28-9034407d824f',
//             activityType: 'query',
//             version: '0.2.407',
//             productVersion: 'AdxproxyOverAI_2020.04.08.1',
//             buildTime: '2020-04-08T00:19:49.761Z',
//             environmentName: 'prod-ai-eau',
//             instanceResourceGroup: 'draft-prod-eau-v2',
//             instanceCluster: 'draft-prod-eau-v2',
//             instanceStamp: 'prod-eau',
//             instanceRegion: 'australiaeast',
//             instanceGeography: 'australia'
//         },
//         '@url':
//             '/subscriptions/f3101802-8c4f-4e6e-819c-a3b5794d33dd/resourcegroups/kustoweb/providers/microsoft.insights/components/kustoweb/v1/rest/query',
//         '@text': 'let a=1\nbrowserTimings\n| where ksdf == 2',
//         '@database': 'kustoweb',
//         '@cluster':
//             '/subscriptions/f3101802-8c4f-4e6e-819c-a3b5794d33dd/resourcegroups/kustoweb/providers/microsoft.insights/components/kustoweb',
//         '@permanent': true
//     }
// };

///////////////// Aria proxy examples And where no proxy (direct call)
//
//  aria/909a8a3b12c04351b0312cf79f98b46d
// let a=1
// SynopsisPageViews
// | where ksdf == 2
// {
//     "error": {
//         "code": "General_BadRequest",
//         "message": "Request is invalid and cannot be executed.",
//         "@type": "Kusto.Data.Exceptions.KustoBadRequestException",
//         "@message": "Syntax error: Query could not be parsed: SYN0002: A recognition error occurred. [line:position=2:0]. Query: 'let a=1\nSynopsisPageViews\n| where ksdf == 2'",
//         "@context": {
//             "timestamp": "2020-05-06T10:39:11.6495012Z",
//             "serviceAlias": "ARIA",
//             "machineName": "KEngine000001",
//             "processName": "Kusto.WinSvc.Svc",
//             "processId": 8988,
//             "threadId": 11116,
//             "appDomainName": "Kusto.WinSvc.Svc.exe",
//             "clientRequestId": "KustoWebV2;11144bc7-fa72-4229-af9b-b3303d2f53b7",
//             "activityId": "a4049e50-a422-4b8b-8500-0014166617a9",
//             "subActivityId": "f0524a86-65a0-4c7a-b051-b562d2f8a2d3",
//             "activityType": "DN.FE.ExecuteQuery",
//             "parentActivityId": "3a5d1b0c-f6ee-4307-9a7b-7d72e5ad3c02",
//             "activityStack": "(Activity stack: CRID=KustoWebV2;11144bc7-fa72-4229-af9b-b3303d2f53b7 ARID=a4049e50-a422-4b8b-8500-0014166617a9 > KD.Query.Client.ExecuteQueryAsKustoDataStream/f924bd2a-7785-4185-a797-17db48d85c6f > P.WCF.Service.ExecuteQueryInternalAsKustoDataStream..IClientServiceCommunicationContract/3a5d1b0c-f6ee-4307-9a7b-7d72e5ad3c02 > DN.FE.ExecuteQuery/f0524a86-65a0-4c7a-b051-b562d2f8a2d3)"
//         },
//         "@permanent": true,
//         "@text": "let a=1\nSynopsisPageViews\n| where ksdf == 2",
//         "@database": "909a8a3b12c04351b0312cf79f98b46d",
//         "@ClientRequestLogger": "",
//         "innererror": {
//             "code": "SYN0002",
//             "message": "A recognition error occurred.",
//             "@type": "Kusto.Data.Exceptions.SyntaxException",
//             "@message": "Syntax error: Query could not be parsed: SYN0002: A recognition error occurred. [line:position=2:0]. Query: 'let a=1\nSynopsisPageViews\n| where ksdf == 2'",
//             "@context": {
//                 "timestamp": "2020-05-06T10:39:11.6495012Z",
//                 "serviceAlias": "ARIA",
//                 "machineName": "KEngine000001",
//                 "processName": "Kusto.WinSvc.Svc",
//                 "processId": 8988,
//                 "threadId": 11116,
//                 "appDomainName": "Kusto.WinSvc.Svc.exe",
//                 "clientRequestId": "KustoWebV2;11144bc7-fa72-4229-af9b-b3303d2f53b7",
//                 "activityId": "a4049e50-a422-4b8b-8500-0014166617a9",
//                 "subActivityId": "f0524a86-65a0-4c7a-b051-b562d2f8a2d3",
//                 "activityType": "DN.FE.ExecuteQuery",
//                 "parentActivityId": "3a5d1b0c-f6ee-4307-9a7b-7d72e5ad3c02",
//                 "activityStack": "(Activity stack: CRID=KustoWebV2;11144bc7-fa72-4229-af9b-b3303d2f53b7 ARID=a4049e50-a422-4b8b-8500-0014166617a9 > KD.Query.Client.ExecuteQueryAsKustoDataStream/f924bd2a-7785-4185-a797-17db48d85c6f > P.WCF.Service.ExecuteQueryInternalAsKustoDataStream..IClientServiceCommunicationContract/3a5d1b0c-f6ee-4307-9a7b-7d72e5ad3c02 > DN.FE.ExecuteQuery/f0524a86-65a0-4c7a-b051-b562d2f8a2d3)"
//             },
//             "@permanent": true,
//             "@line": "2",
//             "@pos": "0",
//             "@errorCode": "SYN0002",
//             "@errorMessage": "A recognition error occurred.",
//             "@token": "SynopsisPageViews"
//         }
//     }
// }
// ------------------------------------------------------------------
//  aria/909a8a3b12c04351b0312cf79f98b46d
// let a=1
// SynopsisPageViews
// | where ksdf == 2
// {
//     "error": {
//         "code": "General_BadRequest",
//         "message": "Request is invalid and cannot be executed.",
//         "@type": "Kusto.Data.Exceptions.KustoBadRequestException",
//         "@message": "Semantic error: 'SynopsisPageViews\n| where ksdf == 2' has the following semantic error: SEM0100: 'where' operator: Failed to resolve column or scalar expression named 'ksdf'.",
//         "@context": {
//             "timestamp": "2020-05-06T10:39:21.8229341Z",
//             "serviceAlias": "ARIA",
//             "machineName": "KEngine000001",
//             "processName": "Kusto.WinSvc.Svc",
//             "processId": 8988,
//             "threadId": 14592,
//             "appDomainName": "Kusto.WinSvc.Svc.exe",
//             "clientRequestId": "KustoWebV2;8e6e483d-8d5e-4d2a-9992-2e4c683bdc38",
//             "activityId": "5bc7cd1c-2f43-4d6e-a714-0bb38111419b",
//             "subActivityId": "67609c4f-f323-48d7-8f0e-22ca25bf939e",
//             "activityType": "DN.FE.ExecuteQuery",
//             "parentActivityId": "c969efdf-a06b-41e0-8054-dc33c0b73916",
//             "activityStack": "(Activity stack: CRID=KustoWebV2;8e6e483d-8d5e-4d2a-9992-2e4c683bdc38 ARID=5bc7cd1c-2f43-4d6e-a714-0bb38111419b > KD.Query.Client.ExecuteQueryAsKustoDataStream/6139f99a-b918-4921-94a3-e6e61cbf5ea5 > P.WCF.Service.ExecuteQueryInternalAsKustoDataStream..IClientServiceCommunicationContract/c969efdf-a06b-41e0-8054-dc33c0b73916 > DN.FE.ExecuteQuery/67609c4f-f323-48d7-8f0e-22ca25bf939e)"
//         },
//         "@permanent": true,
//         "@text": "SynopsisPageViews\n| where ksdf == 2",
//         "@database": "909a8a3b12c04351b0312cf79f98b46d",
//         "@ClientRequestLogger": "",
//         "innererror": {
//             "code": "SEM0100",
//             "message": "'where' operator: Failed to resolve column or scalar expression named 'ksdf'",
//             "@type": "Kusto.Data.Exceptions.SemanticException",
//             "@message": "Semantic error: 'SynopsisPageViews\n| where ksdf == 2' has the following semantic error: SEM0100: 'where' operator: Failed to resolve column or scalar expression named 'ksdf'.",
//             "@context": {
//                 "timestamp": "2020-05-06T10:39:21.8229341Z",
//                 "serviceAlias": "ARIA",
//                 "machineName": "KEngine000001",
//                 "processName": "Kusto.WinSvc.Svc",
//                 "processId": 8988,
//                 "threadId": 14592,
//                 "appDomainName": "Kusto.WinSvc.Svc.exe",
//                 "clientRequestId": "KustoWebV2;8e6e483d-8d5e-4d2a-9992-2e4c683bdc38",
//                 "activityId": "5bc7cd1c-2f43-4d6e-a714-0bb38111419b",
//                 "subActivityId": "67609c4f-f323-48d7-8f0e-22ca25bf939e",
//                 "activityType": "DN.FE.ExecuteQuery",
//                 "parentActivityId": "c969efdf-a06b-41e0-8054-dc33c0b73916",
//                 "activityStack": "(Activity stack: CRID=KustoWebV2;8e6e483d-8d5e-4d2a-9992-2e4c683bdc38 ARID=5bc7cd1c-2f43-4d6e-a714-0bb38111419b > KD.Query.Client.ExecuteQueryAsKustoDataStream/6139f99a-b918-4921-94a3-e6e61cbf5ea5 > P.WCF.Service.ExecuteQueryInternalAsKustoDataStream..IClientServiceCommunicationContract/c969efdf-a06b-41e0-8054-dc33c0b73916 > DN.FE.ExecuteQuery/67609c4f-f323-48d7-8f0e-22ca25bf939e)"
//             },
//             "@permanent": true,
//             "@errorCode": "SEM0100",
//             "@errorMessage": "'where' operator: Failed to resolve column or scalar expression named 'ksdf'"
//         }
//     }
// }
//
