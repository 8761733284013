/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-redeclare */
import * as localforage from 'localforage';
import { types, getRoot, applySnapshot } from 'mobx-state-tree';
import { emptySnapshot, RootStore } from './rootStore';
import { Theme } from '@kusto/common';
import { getTelemetryClient } from '../utils/telemetryClient';

const { trackEvent } = getTelemetryClient({ component: 'settings', flow: '' });
export const themes = Object.keys(Theme).map((k) => Theme[k as keyof typeof Theme]);

export const Settings = types
    .model('Settings', {
        enableCodelens: types.optional(types.boolean, false),
        apiV2: types.optional(types.boolean, true),
        intellisenseV2: types.optional(types.boolean, true),
        useSemanticColorization: types.optional(types.boolean, true),
        formatResultData: types.optional(types.boolean, true),
        theme: types.optional(types.enumeration(themes), Theme.Light),
        timezone: types.optional(types.string, 'UTC'),
        hideEmptyCellInRowExpand: types.optional(types.boolean, false),
        displayGridLevelColoring: types.optional(types.boolean, true),
        language: types.optional(types.maybe(types.string), undefined),
        timeoutInMinutes: types.optional(types.integer, 4),
        weakConsistency: types.optional(types.boolean, false),
        mouseWheelZoom: types.optional(types.boolean, true),
        emptyWorkspaceOnDeepLinkQuery: types.optional(types.boolean, false),
        closeExpandViewOnClick: types.optional(types.boolean, false),
        alignRightResultNumbers: types.optional(types.boolean, true),
        preferredTenant: types.optional(types.string, ''),
        useBoost: types.optional(types.boolean, false),
        showAllSeriesOnHover: types.optional(types.boolean, false),
        exportToNativePowerBi: types.optional(types.maybe(types.boolean), undefined),
        clickableLinks: types.optional(types.boolean, true),
        showUnknownLinkConfirmationDialog: types.optional(types.boolean, true),
    })
    .actions((self) => ({
        setEnableCodeLens: (enable: boolean) => {
            self.enableCodelens = enable;
        },
        clearLocalStorage: () => {
            const rootStore = getRoot(self) as RootStore;
            applySnapshot(rootStore, emptySnapshot);
            localStorage.clear();
            localforage.clear();
        },
        setApiV2: (isV2: boolean) => {
            self.apiV2 = isV2;
        },
        setIntellisenseV2: (isV2: boolean) => {
            self.intellisenseV2 = isV2;
        },
        setSemanticColorization: (val: boolean) => {
            self.useSemanticColorization = val;
        },
        setFormatResultData: (val: boolean) => {
            self.formatResultData = val;
        },
        setTheme: (val: Theme) => {
            self.theme = val;
        },
        setTimezone: (val: string) => {
            self.timezone = val;
            trackEvent('TimeZoneChanged', { timezone: `${val}` });
        },
        setHideEmptyCell: (hide: boolean) => (self.hideEmptyCellInRowExpand = hide),
        setLevelColoring: (show: boolean) => (self.displayGridLevelColoring = show),
        setLanguage: (language?: string) => (self.language = language),
        setTimeoutInMinutes: (timeout: number) => (self.timeoutInMinutes = timeout),
        setWeakConsistency: (weak: boolean) => (self.weakConsistency = weak),
        setMouseWheelZoom: (wheel: boolean) => (self.mouseWheelZoom = wheel),
        setEmptyWorkspaceOnDeepLinkQuery: (empty: boolean) => (self.emptyWorkspaceOnDeepLinkQuery = empty),
        setCloseExpandViewOnClick: (val: boolean) => (self.closeExpandViewOnClick = val),
        setAlignRightResultNumbers: (val: boolean) => (self.alignRightResultNumbers = val),
        setPreferredTenant: (tenant: string) => (self.preferredTenant = tenant),
        setUseBoost: (val: boolean) => (self.useBoost = val),
        setShowAllSeriesOnHover: (val: boolean) => (self.showAllSeriesOnHover = val),
        setExportToNativePowerBi: (val: boolean) => (self.exportToNativePowerBi = val),
        setClickableLinks: (val: boolean) => (self.clickableLinks = val),
        setShowUnknownLinkConfirmationDialog: (showDialog: boolean) => {
            self.showUnknownLinkConfirmationDialog = showDialog;
        },
    }));
// eslint-disable-next-line no-redeclare
export type Settings = typeof Settings.Type;
