import React from 'react';
import { TooltipWrapper, TooltipWrapperProps } from './TooltipWrapper';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

export interface IconButtonWithTooltipProps extends React.ComponentProps<typeof IconButton> {
    tooltipProps?: TooltipWrapperProps;
}

export const IconButtonWithTooltip = ({ title, tooltipProps, ...iconButtonProps }: IconButtonWithTooltipProps) => {
    return (
        <TooltipWrapper content={title} {...tooltipProps}>
            <IconButton {...iconButtonProps}></IconButton>
        </TooltipWrapper>
    );
};
