import React from 'react';
import { TooltipWrapper, TooltipWrapperProps } from './TooltipWrapper';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

export interface IconWithTooltipProps extends React.ComponentProps<typeof Icon> {
    tooltipProps?: TooltipWrapperProps;
}

export const IconWithTooltip = ({ title, tooltipProps, ...iconProps }: IconWithTooltipProps) => {
    return (
        <TooltipWrapper content={title} {...tooltipProps}>
            <Icon {...iconProps}></Icon>
        </TooltipWrapper>
    );
};
