import * as React from 'react';
import { useState } from 'react';

import { IRtdDashboardsCore } from '../types';
import { dashboardsCoreContext, DashboardCoreContextState } from './context';

const noop = () => {};

export interface IRtdCoreProps {
    /**
     * If a function is passed, it won't be called until we need to initialize core
     */
    core: (() => Promise<IRtdDashboardsCore>) | Promise<IRtdDashboardsCore> | IRtdDashboardsCore;
}

/**
 * Configures RTD core for nested React components.
 */
export const RtdCoreProvider: React.FC<IRtdCoreProps> = ({ core, children }) => {
    const [value, setValue] = useState<DashboardCoreContextState>(() => {
        async function tryAwaitCore(promise: Promise<IRtdDashboardsCore> | IRtdDashboardsCore) {
            try {
                setValue({ status: 'ready', appContext: await promise });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                setValue({ status: 'error', error });
            }
        }

        if (typeof core === 'function') {
            let loading = false;
            const startInit = () => {
                if (loading === false) {
                    loading = true;
                    tryAwaitCore(core());
                }
            };
            return { status: 'deferred', startInit };
        }

        if (core instanceof Promise) {
            tryAwaitCore(core);
            return { status: 'deferred', startInit: noop };
        }

        return { status: 'ready', appContext: core };
    });

    return <dashboardsCoreContext.Provider value={value}>{children}</dashboardsCoreContext.Provider>;
};
