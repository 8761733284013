import * as React from 'react';
import { IDropdownProps, ICalloutProps, DirectionalHint } from 'office-ui-fabric-react';
import { useMemo } from 'react';

import { APP_CONSTANTS } from '../../../res';

import { SearchDropdown } from './SearchDropdown';
import { RTDDropdownOption } from './types';
import { InputDropdownAdditionalProps, InputDropdown } from './InputDropdown';
import { UnsafeHeaderDropdown } from './components/HeaderDropdown';

const baseCalloutProps: ICalloutProps = {
    calloutMaxHeight: 470,
    directionalHint: DirectionalHint.bottomCenter,
};

export type RTDBackingDropdown =
    | {
          /**
           * Dropdown that dynamically switches between fabric and filterable
           */
          type: 'standard';

          /**
           * The minimum size at which to switch to the virtualized SearchDropdown. If not provided, will switch at 10 items
           */
          minFilterSize?: number;
      }
    | {
          /**
           * **Default**
           *
           * Dropdown that is only a traditional Fabric dropdown
           */
          type: 'force-fabric';
      }
    | {
          /**
           * Dropdown that is only a virtualized+filterable dropdown
           */
          type: 'force-filterable';
      }
    | ({
          /**
           * Dropdown that contains an input box
           */
          type: 'input';
          /**
           * The input's `type` prop
           */
          inputType?: string;
      } & InputDropdownAdditionalProps);

export interface RTDDropdownProps extends IDropdownProps {
    options: RTDDropdownOption[];

    onRenderHeader?: () => JSX.Element | null;

    /**
     * Defaults to `force-fabric`
     */
    backingDropdown?: RTDBackingDropdown;

    defaultSelectedKey?: string;
    defaultSelectedKeys?: string[];
    selectedKey?: string | null;
    selectedKeys?: string[] | null;
}

export const RTDDropdown: React.FC<RTDDropdownProps> = (props) =>
    props.calloutProps ? <RTDDropdownWithCalloutProps {...props} /> : <RTDDropdownWithoutCalloutProps {...props} />;

// We explicitly want to optimize for scenarios where there are no calloutProps, so we avoid running a useMemo unnecessarily
const RTDDropdownWithoutCalloutProps: React.FC<Omit<RTDDropdownProps, 'calloutProps'>> = (props) =>
    DropdownComponent(props, baseCalloutProps);

const RTDDropdownWithCalloutProps: React.FC<RTDDropdownProps> = (props) => {
    const memoedCalloutProps = useMemo(() => ({ ...baseCalloutProps, ...props.calloutProps }), [props.calloutProps]);

    return DropdownComponent(props, memoedCalloutProps);
};

const DropdownComponent = (props: RTDDropdownProps, calloutProps: ICalloutProps) => {
    if (!props.backingDropdown || props.backingDropdown.type === 'force-fabric') {
        return <UnsafeHeaderDropdown {...props} calloutProps={calloutProps} />;
    }

    switch (props.backingDropdown.type) {
        case 'standard': {
            const minFilterSize = props.backingDropdown.minFilterSize ?? APP_CONSTANTS.ux.fabric.dropdown.minFilterSize;

            const Component =
                minFilterSize !== undefined && props.options.length >= minFilterSize
                    ? SearchDropdown
                    : UnsafeHeaderDropdown;
            return <Component {...props} calloutProps={calloutProps} />;
        }
        case 'force-filterable':
            return <SearchDropdown {...props} calloutProps={calloutProps} />;
        case 'input':
            return <InputDropdown {...props} calloutProps={calloutProps} {...props.backingDropdown} />;
    }
};
