import * as React from 'react';
import {
    IconButton,
    OverflowSet,
    IOverflowSetItemProps,
    IButtonProps,
    IContextualMenuItem,
    IOverflowSetProps,
    IButtonStyles,
} from 'office-ui-fabric-react';
import { useCallback } from 'react';

import { APP_STRINGS } from '../../../../../res';
import { cssVariableNames } from '../../../../../domain/theming/themes';

import styles from './OverflowMenu.module.scss';

export type IOverflowMenuItem = IOverflowSetItemProps & IButtonProps;

const renderItem = (item: IOverflowMenuItem) => <IconButton {...item} />;

const menuIconProps = { iconName: 'More' };

const overflowButtonStyles: IButtonStyles = {
    root: {
        color: cssVariableNames.neutralPrimary,
    },
    rootHovered: {
        color: cssVariableNames.neutralPrimary,
    },
    rootPressed: {
        color: cssVariableNames.neutralPrimary,
    },
    rootExpanded: {
        color: cssVariableNames.neutralPrimary,
    },
    rootExpandedHovered: {
        color: cssVariableNames.neutralPrimary,
    },
};

interface IOverflowMenuProps extends Omit<IOverflowSetProps, 'onRenderItem' | 'onRenderOverflowButton'> {
    overflowButtonProps?: IButtonProps;
}

export const OverflowMenu: React.FC<IOverflowMenuProps> = ({ overflowButtonProps, ...props }) => {
    const renderOverflowButton = useCallback(
        (overflowButtonItems: IContextualMenuItem[] | undefined) => {
            if (!overflowButtonItems) {
                return null;
            }

            const className = overflowButtonProps?.className;

            return (
                <IconButton
                    {...overflowButtonProps}
                    className={className ? `${styles.overflow} ${className}` : styles.overflow}
                    // Cannot use a styled function
                    styles={overflowButtonStyles}
                    menuIconProps={menuIconProps}
                    menuProps={{ items: overflowButtonItems }}
                    role="menu"
                    title={
                        overflowButtonProps?.ariaLabel
                            ? overflowButtonProps.ariaLabel
                            : APP_STRINGS.manage.additionalActionsButtonTitle
                    }
                />
            );
        },
        [overflowButtonProps]
    );

    return <OverflowSet {...props} onRenderOverflowButton={renderOverflowButton} onRenderItem={renderItem} />;
};
