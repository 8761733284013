import * as React from 'react';
import { Label, ActionButton } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../../res';
import { ValueImpl } from '../../../../../domain';

import { EppStaticDropdownOptions, EppStaticOption } from '../../../reducer';
import { IEditParameterPageComponentProps } from '../../../types';

import pageStyles from '../../../EditParameterPage.module.scss';

import { ParameterValueOptionRow } from './ParameterValueOptionRow';
import styles from './StaticOptions.module.scss';

interface IParameterStaticValueFormProps<T, K extends string> extends IEditParameterPageComponentProps {
    dataSource: EppStaticDropdownOptions;
    impl: ValueImpl<T, K>;
    displayTextOnly?: boolean;
    optionErrors?: ReadonlyMap<string, string>;
}

export const ParameterStaticValueForm = <T, K extends string>({
    dataSource,
    impl,
    displayTextOnly,
    optionErrors,
    dispatch,
}: IParameterStaticValueFormProps<T, K>) => {
    const { onAdd, onChangeValue, onDelete } = React.useMemo(
        () => ({
            onAdd: () => dispatch({ type: 'addDataSourceOption' }),
            onChangeValue: (value: EppStaticOption) => dispatch({ type: 'updateDropdownStaticOption', value }),
            onDelete: (key: string) => dispatch({ type: 'removeStaticDropdownOption', key }),
        }),
        [dispatch]
    );

    return (
        <div className={pageStyles.input}>
            <div className={styles.header}>
                <Label required={true}>{APP_STRINGS.editParameterPage.value}</Label>
                <Label>{APP_STRINGS.editParameterPage.displayName}</Label>
            </div>
            <div className={styles.table}>
                {[...dataSource.options.values()].map((value) => (
                    <ParameterValueOptionRow
                        key={value.id}
                        className={styles.row}
                        textFieldClassName={styles.textFieldWrapper}
                        value={value}
                        impl={impl}
                        error={optionErrors?.get(value.id)}
                        displayTextOnly={displayTextOnly}
                        onChange={onChangeValue}
                        onDelete={onDelete}
                    />
                ))}
            </div>
            <ActionButton
                iconProps={{ iconName: 'Add' }}
                onClick={onAdd}
                disabled={displayTextOnly || shouldDisableAddOption(dataSource)}
            >
                {APP_STRINGS.editParameterPage.buttons.addOption}
            </ActionButton>
        </div>
    );
};

const shouldDisableAddOption = (dataSource: EppStaticDropdownOptions): boolean => {
    const length = dataSource.options.size;

    return length > 0 && [...dataSource.options.values()][length - 1].value.trim() === '';
};
