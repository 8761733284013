export interface ColorResolution {
    /**
     * The actual color values that the color should resolve to
     */
    color: string;
    /**
     * Whether text presented on this color should be inverted or not
     */
    invertText?: boolean;
}

export declare type VisualOptionsColor = typeof visualOptionsColors[number];

export declare type ColorResolutionSet = Record<VisualOptionsColor, ColorResolution>;

export declare type colorValues = Record<'light' | 'dark', Record<ColorStyle, ColorResolutionSet>>;

export declare const colorRulesIconToIcon: {
    readonly critical: 'Error';
    readonly warning: 'Warning';
    readonly completed: 'Completed';
    readonly tentative: 'TentativeBooking';
    readonly happy: 'Happy';
    readonly sad: 'Sad';
    readonly emojiNeutral: 'EmojiNeutral';
    readonly circle: 'CircleRing';
    readonly circleFill: 'CircleFill';
    readonly contrast: 'Contrast';
    readonly square: 'SquareShape';
    readonly squareSolid: 'SquareShapeSolid';
    readonly diamond: 'Diamond2Solid';
    readonly octagon: 'Octagon';
    readonly cloud: 'Cloud';
    readonly tvMonitor: 'TvMonitor';
    readonly globe: 'Globe';
    readonly strength: 'Strength';
    readonly eatDrink: 'EatDrink';
    readonly cocktail: 'Cocktails';
    readonly apple: 'DietTrackerApple';
    readonly repair: 'Repair';
    readonly circleDollar: 'CircleDollar';
    readonly rate: 'Rate';
    readonly arrowUp: 'ArrowUp8';
    readonly arrowDown: 'ArrowDown8';
    readonly ferry: 'Ferry';
    readonly car: 'Car';
    readonly plane: 'Airplane';
    readonly baseball: 'Baseball';
    readonly soccer: 'Soccer';
    readonly basketball: 'Basketball';
    readonly football: 'CollegeFootball';
    readonly tennis: 'Tennis';
    readonly golf: 'Golf';
    readonly world: 'World';
    readonly heart: 'Heart';
    readonly insights: 'Insights';
};

export const colorRulesIconsLabels: Record<keyof typeof colorRulesIconToIcon, string> = {
    critical: 'Critical',
    warning: 'Warning',
    completed: 'Completed',
    tentative: 'Tentative',
    happy: 'Happy',
    sad: 'Sad',
    emojiNeutral: 'Emoji neutral',
    circle: 'Circle',
    circleFill: 'Circle fill',
    contrast: 'Contrast',
    square: 'Square',
    squareSolid: 'Square solid',
    diamond: 'Diamond solid',
    octagon: 'Octagon',
    cloud: 'Cloud',
    tvMonitor: 'TV monitor',
    globe: 'Globe',
    strength: 'Strength',
    eatDrink: 'Eat/drink',
    cocktail: 'Cocktail',
    apple: 'Apple',
    repair: 'Repair',
    circleDollar: 'Circle dollar',
    rate: 'Rate',
    arrowUp: 'Up arrow',
    arrowDown: 'Down arrow',
    ferry: 'Ferry',
    car: 'Car',
    plane: 'Plane',
    baseball: 'Baseball',
    soccer: 'Soccer',
    basketball: 'Basketball',
    football: 'Football',
    tennis: 'Tennis',
    golf: 'Golf',
    world: 'World',
    heart: 'Heart',
    insights: 'Insights',
};

export declare type Infer = {
    readonly type: 'infer';
};
export declare type Specified<T> = {
    readonly type: 'specified';
    readonly value: T;
};
export declare type CanInfer<T> = Infer | Specified<T>;

export declare const colorRulesOperators: readonly ['>', '>=', '<', '<=', '=='];

export declare type ColorRuleOperator = typeof colorRulesOperators[number];

export interface ColorRuleCondition {
    readonly operator: ColorRuleOperator;
    readonly value: string;
}
export interface ColorRule {
    readonly id: string;
    readonly column: CanInfer<string>;
    readonly conditions: ColorRuleCondition[];
    readonly color: VisualOptionsColor;
    /**
     * When undefined there is no indicator
     */
    readonly indicator:
        | { readonly kind: 'none' }
        | { readonly kind: 'text'; readonly text: CanInfer<string> }
        | {
              readonly kind: 'icon';
              readonly icon: keyof typeof colorRulesIconToIcon;
              readonly label: CanInfer<string>;
          };
}

export declare const visualOptionsColors: readonly ['red', 'yellow', 'green', 'blue'];

export declare type ColorStyle = 'light' | 'bold';

export type ConditionalFormattingOptions = {
    icon?: keyof typeof colorRulesIconToIcon;
    color?: VisualOptionsColor;
    subLabel?: string;
    colorStyle?: ColorStyle;
};
