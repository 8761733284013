import moment from 'moment';
import React from 'react';
const compileStrTemplate = require('es6-template-strings/compile');
const resolveStrTemplate = require('es6-template-strings/resolve-to-string');

export function normalizeSpaces(text: string) {
    return text.replace(/\s\s+/g, ' ').trim();
}

export function formatToSeconds(milliseconds: number | null | undefined, precision: number = 3): string {
    if (!milliseconds) {
        return '';
    }

    return (milliseconds / 1000).toFixed(precision);
}

const momentFormat = (val: number, timeFormatter: string) =>
    moment(val)
        .utc()
        .format(val % 1000 === 0 ? timeFormatter : timeFormatter + '.SSS');

export function formatMillisecondsToTimeString(ms: number | null | undefined) {
    if (ms == null) {
        return '';
    }

    const absVal = Math.abs(ms);
    const hours = (absVal / (1000 * 60 * 60)) >> 0;
    const rest = momentFormat(absVal, 'mm:ss');
    return hours ? hours + ':' + rest : rest;
}

export function isFalsyOrWhitespace(str: string | null | undefined) {
    return !str || str.trim() === '';
}

export function isWhitespace(str: string) {
    return str.trim() === '';
}

// Dictionary for literals (Functions) to be used as caching in formatLiterals()
const LiteralsDictionary: Map<string, Function> = new Map<string, Function>();

/**
 *
 * Decorates a string with the following rules:
 * Text to be decorated should start and end with "*"
 * Italic decoration should start and end with "%"
 *
 * Warning: this function removes "*" if comes in start and end of a phrase
 */
export const decorateString = (text: string) => {
    const DECORATION_SYMBOL = '*';
    const ITALIC_SYMBOL = '%';
    const splitText = text.split(DECORATION_SYMBOL);
    const splitJSX: React.ReactNode[] = splitText.map<React.ReactNode>((item: string, index) => {
        if (item.startsWith(ITALIC_SYMBOL) && item.endsWith(ITALIC_SYMBOL)) {
            return <i key={index}>{item.substring(1, item.length - 1)}</i>;
        } else {
            return <span key={index}>{item}</span>;
        }
    });
    return splitJSX.reduce((prev, curr) => [prev, ' ', curr]);
};

/** use this function to convert regular string to string literals
 * string literals are saved in the string.json as regular strings and we need to use them as literals.
 * eval is not allowed so we convert them to literals with a Function.
 * example: formatLiterals('Welcome ${firstName} ${lastName}', {firstName: "John", lastName: "Doe"})
 * result: 'Welcome John Doe'
 * tslint:disable-next-line: no-any
 */
export const formatLiterals = (text: string, params: any): string => {
    let compiledTemplate = undefined;
    let result = '';
    // new Function() is used for injecting template literals for a regular string.
    // this is basically eval, a heavy operation and cached using the text as key.
    // when we change the lang, the text will be changed and new function will be created and cached
    compiledTemplate = LiteralsDictionary.get(text);
    if (compiledTemplate === undefined) {
        // eslint-disable-next-line no-new-func
        compiledTemplate = compileStrTemplate(text);
        LiteralsDictionary.set(text, compiledTemplate);
    }
    // try/catch in case the string replacement fails, like when the $(param) had a typo
    try {
        result = resolveStrTemplate(compiledTemplate, params);
    } catch {
        result = text;
    }
    return result;
};

// tslint:disable: max-line-length
// Reference in Kusto-service : https://msazure.visualstudio.com/One/_git/Azure-Kusto-Service/commit/c3d252b74bd611b7a0d424380cbf5305d901436d?refName=refs%2Fheads%2Fdev
// file: https://msazure.visualstudio.com/One/_git/Azure-Kusto-Service?path=%2FSrc%2FCommon%2FKusto.Cloud.Platform%2FText%2FExtendedString.cs&version=GBdev
// tslint:enable: max-line-length
const AlternativeSpaceChars = [
    '\u00a0',
    '\u1680',
    '\u180E',
    '\u2000',
    '\u2001',
    '\u2002',
    '\u2003',
    '\u2004',
    '\u2005',
    '\u2006',
    '\u2007',
    '\u2008',
    '\u2009',
    '\u200a',
    '\u200b',
    '\u202f',
    '\u205f',
    '\u3000',
    '\ufeff',
];
export const unifySpaces = (str: string) => {
    const spacesRegExp = new RegExp(AlternativeSpaceChars.join('|'), 'g');
    return str.replace(spacesRegExp, ' ');
};
const AlternativeNewlineChars = ['\v', '\r\n?'];
export const unifyNewLines = (str: string) => {
    const newLineRegExp = new RegExp(AlternativeNewlineChars.join('|'), 'g');
    return str.replace(newLineRegExp, '\n');
};

export const unifyWhiteSpaces = (str: string) => unifySpaces(unifyNewLines(str || ''));

export function caseInsensitiveComparer(a: string, b: string) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a === b) {
        return 0;
    }

    return a < b ? -1 : 1;
}
