/* eslint-disable @typescript-eslint/no-redeclare */
// The following import fixes an infinite loop in later versions of mobx-react.
// further info here: https://github.com/mobxjs/mobx-react-lite/#observer-batching
import { autorun } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';
import { flow, getRoot, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree';
import { observable } from 'mobx';
import { v4 } from 'uuid';
import { AppPages } from '../common/types';
import { dependencies } from '../dependencies';
import { history } from '../history';
import { getTelemetryClient } from '../utils/telemetryClient';
import { Cluster, Database } from './cluster';
import { ConnectionPane } from './connectionPane';
import { getRelativeUrlForEntity, handleDeepLink } from './deepLinkHandler';
import { GridStateCache } from './gridStateCache';
import { ResultCache } from './resultCache';
import { Settings } from './settings';
import { Layout } from './layout';
import { initRootStore } from './statePersistenceHandler';
import { Tab } from './tab';

const id = v4();

const { trackEvent, trackException } = getTelemetryClient({ component: 'rootStore', flow: '' });

export const Tabs = types
    .model('Tabs', {
        tabs: types.optional(types.array(Tab), [{ text: '', id }]),
        tabInContext: types.optional(types.reference(Tab), id),
    })
    .volatile(() => ({
        recentlyCloseTabs: observable([] as Tab[]),
    }))
    .views((self) => ({
        get tabInContextIndex() {
            // TODO: o(n) might be too much when there are lots of tabs.
            return self.tabs
                .map((tab, index) => ({ tab, index }))
                .filter((tabAndIndex) => tabAndIndex.tab.id === self.tabInContext.id)[0].index;
        },
    }))
    .actions((self) => ({
        setTabInContext(tab: Tab) {
            trackEvent('Tabs.SetTabInContext', {
                flow: 'Tabs.setTabInContext',
                tabId: tab.id,
                entityInContextId: tab.entityInContext ? tab.entityInContext.id : 'undefined',
                openTabCount: self.tabs.length.toString(),
            });

            self.tabInContext = tab;
            const root = getRoot(self) as RootStore;
            root.connectionPane.setEntityInContextByObject(tab.entityInContext || undefined);
        },
    }))
    .actions((self) => ({
        undoTabRemoval() {
            const latestCloseTabSnapshot = self.recentlyCloseTabs.pop();
            if (latestCloseTabSnapshot) {
                // Build a new tab from the tab's snapshot.
                const newTab = Tab.create({ id: v4() });

                // set text
                newTab.setText(latestCloseTabSnapshot.text ?? '');

                // set title
                newTab.setTitle(latestCloseTabSnapshot.title ?? '');

                // set entityInContext
                if (typeof latestCloseTabSnapshot.entityInContext === 'string') {
                    const entityInContextId = latestCloseTabSnapshot.entityInContext as string;
                    const root = getRoot(self) as RootStore;
                    const entityInContext = root.connectionPane.getEntityFromId(entityInContextId) as
                        | Cluster
                        | Database
                        | undefined;
                    if (entityInContext) {
                        newTab.setEntityInContext(entityInContext);
                        root.connectionPane.setEntityInContextByObject(entityInContext);
                    }
                }

                // add restored tab to the tabs list.
                self.tabs.push(newTab);
                self.setTabInContext(newTab);
            }
        },
    }))
    .actions((self) => ({
        addTabFromSnapshot(tab: TabSnapshot) {
            self.tabs.push(tab);
        },
        addTab(entityInContext?: Cluster | Database, text?: string) {
            trackEvent('Tabs.AddTab', {
                flow: 'Tabs.addTab',
                entityInContextId: entityInContext ? entityInContext.id : 'undefined',
                textLength: text ? text.length.toString() : 'undefined',
                openTabCount: self.tabs.length.toString(),
            });

            const newId = v4();
            if (!entityInContext) {
                entityInContext = (getRoot(self) as RootStore).connectionPane.entityInContext;
            }

            const newTab: Tab = Tab.create({
                id: newId,
                // can't set the reference before the newTab is added to rootStore -
                // the reference is invalid until added
                // entityInContext: castToReferenceSnapshot(entityInContext)
            });
            if (text) {
                newTab.setText(text);
            }

            self.tabs.push(newTab);
            newTab.setEntityInContext(entityInContext || null);
            self.tabInContext = newTab;
            return newTab;
        },
        setTabs(tabIds: string[]) {
            const order: Record<string, number> = {};

            tabIds.forEach((tabId: string, index: number) => (order[tabId] = index));

            self.tabs.replace(self.tabs.slice().sort((a, b) => order[a.id] - order[b.id]));
        },
        removeTab(tab: Tab) {
            trackEvent('Tabs.RemoveTab', {
                flow: 'Tabs.removeTab',
                tabId: tab ? tab.id : 'EmptyProbablyFF',
                tabEntityInContext: tab && tab.entityInContext ? tab.entityInContext.id : 'undefined',
                openTabCount: self.tabs.length.toString(),
            });

            // Don't close if this is the only tab.
            if (!tab || self.tabs.length === 1) {
                return;
            }

            // if we're removing current tab - change current to next tab before removing.
            if (tab.id === self.tabInContext.id) {
                const tabIndex: number = self.tabInContextIndex;
                const lastIndex = self.tabs.length - 1;

                const newTabInContextIndex = self.tabInContextIndex === lastIndex ? tabIndex - 1 : tabIndex + 1;
                self.setTabInContext(self.tabs[newTabInContextIndex]);
            }

            if (dependencies.featureFlags.UndoLatestClosedTabs === true) {
                if (self.recentlyCloseTabs.length >= 5) {
                    self.recentlyCloseTabs.shift();
                }

                self.recentlyCloseTabs.push(getSnapshot<Tab>(tab));
            }
            self.tabs.remove(tab);
        },
    }))
    .actions((self) => ({
        switchTab(direction: 'left' | 'right') {
            const tabCount = self.tabs.length;
            const newIndex = (self.tabInContextIndex + tabCount + (direction === 'left' ? -1 : 1)) % tabCount;
            const tab = self.tabs[newIndex];
            return self.setTabInContext(tab);
        },
    }));
// eslint-disable-next-line no-redeclare
export type Tabs = typeof Tabs.Type;

export enum NotificationType {
    Success = 'Success',
    Error = 'Error',
}

export interface NotificationBase {
    title: string;
    content: JSX.Element | string;
    fadeOut: boolean;
}
export interface Notification extends NotificationBase {
    type: NotificationType;
}
export interface ActionNotification extends NotificationBase {
    id: string;
    onActionClick?: () => void;
}

/**
 * Use this global state to save simple states.
 */
export const GlobalStateCache = types
    .model('GlobalStateCache', {
        ctrlNeededDialogShown: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        setCtrlNeededDialogShown(value: boolean) {
            self.ctrlNeededDialogShown = value;
        },
    }));
// eslint-disable-next-line no-redeclare
export type GlobalStateCache = Instance<typeof GlobalStateCache>;

export const RootStore = types
    .model('RootStore', {
        connectionPane: ConnectionPane,
        tabs: types.optional(Tabs, {}),
        resultCache: types.optional(ResultCache, { executions: {} }),
        settings: types.optional(Settings, {}),
        layout: types.optional(Layout, {}),
        gridStateCache: types.optional(GridStateCache, {}),
        globalStateCache: types.optional(GlobalStateCache, {}),
        eTag: types.maybe(types.string),
        showEventNotification: types.maybe(types.boolean),
        callToActionLastShowMap: types.map(types.number),
    })
    .volatile(() => ({
        // for now at least we do not want to preserve notifications.
        notification: undefined as Notification | undefined,
        callToAction: undefined as ActionNotification | undefined,
        appPage: dependencies.deepLinkProperties.appPage || AppPages.Explorer,
        deeplinkProcessed: false,
        stopPersistency: false,
    }))
    .actions((self) => ({
        setShowEventNotification: (showEventNotification: boolean | undefined) => {
            self.showEventNotification = showEventNotification;
        },
        afterCreate: flow(function* () {
            self.resultCache.pruneAll();
            const prevTenant = self.settings.preferredTenant;
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const tenant = params?.get('tenant');
            const tenantToSet = tenant || prevTenant;

            // side-effect - update the url whenever entity in context changes.
            autorun(
                () => {
                    const entityInContext = self.tabs.tabInContext.entityInContext;

                    if (!entityInContext) {
                        return;
                    }

                    const relativeUrl = getRelativeUrlForEntity(entityInContext);

                    if (self.appPage === undefined || self.appPage === AppPages.Explorer) {
                        history.replace(relativeUrl);
                    }
                },
                {
                    name: 'UpdateBrowserUrl',
                }
            );

            // try to set the tenant
            try {
                if (!dependencies.featureFlags.IFrameAuth && tenantToSet) {
                    yield dependencies.authProvider.switchTenant(tenantToSet);
                    self.settings.setPreferredTenant(tenantToSet);
                }
            } catch (e) {
                self.notification = {
                    title: dependencies.strings.setTenantNotificationTitle,
                    type: NotificationType.Error,
                    fadeOut: true,
                    content: dependencies.strings.setTenantErrorMessage,
                };
                self.settings.setPreferredTenant(dependencies.authProvider.getTenant());
                trackException(e, 'rootStore.afterCreate', { flow: 'Loading' });
            }

            try {
                yield handleDeepLink(
                    self.connectionPane,
                    self.tabs,
                    dependencies.deepLinkProperties,
                    false,
                    dependencies.deepLinkProperties.featureFlags.RefreshConnection || false,
                    self.settings.emptyWorkspaceOnDeepLinkQuery
                );
                self.connectionPane.refetchSchemaForDatabaseInContextIfNeeded();
            } catch (e) {
                trackException(e, 'handleDeepLink', { flow: 'Loading' });
            }

            self.deeplinkProcessed = true;
        }),
        setNotification(notification: Notification) {
            self.notification = notification;
        },
        dismissNotification() {
            self.notification = undefined;
        },
        setCallToAction(callToAction: ActionNotification) {
            self.callToAction = callToAction;
            self.callToActionLastShowMap.set(callToAction.id, Date.now());
        },
        getCallToActionLastShow(callToActionId: string): Date | undefined {
            let lastShowDate: Date | undefined;
            const lastShowValue: number | undefined = self.callToActionLastShowMap.get(callToActionId);
            try {
                lastShowDate = lastShowValue ? new Date(lastShowValue) : undefined;
            } catch (e) {}
            return lastShowDate;
        },
        dismissCallToAction() {
            self.callToAction = undefined;
        },
        setAppPage(page: AppPages) {
            trackEvent('RootStore.setAppPage', { name: page });
            if (dependencies.featureFlags.HideDashboardsOnly && page === AppPages.Dashboards) {
                self.appPage = AppPages.Explorer;
                return;
            }
            self.appPage = page;
        },
        setMainETag(eTag: string) {
            self.eTag = eTag;
        },
        setStopPersistency(shouldStopPersistency: boolean) {
            self.stopPersistency = shouldStopPersistency;
        },
    }));
// eslint-disable-next-line no-redeclare
export type RootStore = Instance<typeof RootStore>;
export type RootStoreSnapshot = typeof RootStore.SnapshotType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptySnapshot: any = {
    connectionPane: {
        connections: {},
    },
    resultCache: ResultCache.create({ executions: {} }),
};

export const rootStorePromise: Promise<RootStore> = initRootStore();
export type TabSnapshot = SnapshotOut<typeof Tab>;
