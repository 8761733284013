import { Observable } from 'rxjs';

import { newKustoQuery } from '../query/query';
import { ok, Result, err } from '../../common';

import type { UKustoDataSource, UDataSource } from '../../core/domain';
import type { IQueryService } from '../../core/query/QueryService';
import type {
    BasicParam,
    RtdValue,
    ParameterValue,
    KustoQueryResultColumn,
    KustoQueryResult,
    StreamResult,
    UQuery,
    TimeZone,
    KustoQueryResultRowObject,
    StreamQueryResult,
} from '..';
import { rtdDataTypeFromKustoDataType } from '../kusto';
import { APP_STRINGS } from '../../res';

import { ValueImpl, valueImpl } from '../value';

const localAppStrings = APP_STRINGS.domain.parameter.errors.query;

/**
 * Given a `QueryResult` from running a parameter query, build the
 * `IParameterValue`s that will actually be consumed by the dropdown
 */
export function buildParameterOptionsFromResult<T>(
    queryResult: KustoQueryResult,
    valueColumn: KustoQueryResultColumn,
    labelColumn: undefined | KustoQueryResultColumn,
    queryService: IQueryService,
    timeZone: TimeZone
): Result<BasicParam.StaticDataSourceValues<T>> {
    const seenDataValues = new Set<unknown>();
    const resultRow: BasicParam.StaticDataSourceValues<T> = [];

    const formatLabelValue = (() => {
        if (!labelColumn) return;
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const labelImpl = valueImpl[rtdDataTypeFromKustoDataType(labelColumn.type)] as ValueImpl<any, any>;

        /* eslint-enable @typescript-eslint/no-explicit-any */
        return (row: KustoQueryResultRowObject): undefined | Result<string> => {
            const column = queryService.deserializeColumn(queryResult.scopeId, labelColumn.type, [
                row[labelColumn.name],
            ]);
            if (column.kind === 'err') {
                return column;
            }
            const value = column.value[0];

            if (value === undefined) {
                return undefined;
            }

            return ok(labelImpl.valueToDisplayString(value, timeZone));
        };
    })();

    for (const kustoRow of queryResult.rows) {
        const value = kustoRow[valueColumn.name];

        if (seenDataValues.has(value)) {
            continue;
        }

        seenDataValues.add(value);
        const res = queryService.deserializeColumn(queryResult.scopeId, valueColumn.type, [value]);

        if (res.kind === 'err') {
            return res;
        }

        const [deserializedValue] = res.value;

        if (deserializedValue !== undefined) {
            const maybeDisplayText = formatLabelValue?.(kustoRow);
            if (maybeDisplayText?.kind === 'err') {
                return maybeDisplayText;
            }
            resultRow.push({
                value: deserializedValue,
                displayText: maybeDisplayText?.value,
            });
        }
    }

    return ok(resultRow);
}

export type StreamParameterResult<T> = StreamResult<BasicParam.StaticDataSourceValues<T>>;

/**
 * Requests the latest data from a query derived from the given parameter
 */
export function requestQueryParameterUpdate(
    queryService: IQueryService,
    parameterId: string,
    parameterDataSource: BasicParam.QueryDataSource,
    dataSource: UDataSource,
    usedParameterValues: ParameterValue[]
): void {
    const query = newKustoQuery(parameterDataSource.query, dataSource, parameterId, usedParameterValues);

    return queryService.requestQueryUpdate(query.queryHash);
}

function getColumns(
    { columns: configuredColumns }: BasicParam.QueryDataSource,
    columnTypes: KustoQueryResultColumn[],
    expectedDataType: RtdValue.BasicType
): Result<{ label?: string; value: string }> {
    if (configuredColumns.value) {
        const { value, label } = configuredColumns;

        const valueColumn = columnTypes.find((c) => c.name === value);

        if (!valueColumn) {
            return err(`${localAppStrings.valueColumnMissing} "${value}"`);
        } else {
            const type = rtdDataTypeFromKustoDataType(valueColumn.type);
            if (type !== expectedDataType) {
                return err(
                    `${localAppStrings.wrongColumnType[0]} "${type}" ${localAppStrings.wrongColumnType[1]} "${expectedDataType}"`
                );
            }
        }
        if (label !== undefined && !columnTypes.some((c) => c.name === label)) {
            return err(`${localAppStrings.labelColumnMissing} "${label}"`);
        }
        return ok({ value, label: label });
    }

    // Code path for legacy query parameters with no configured value column
    if (columnTypes.length < 1) {
        return err(localAppStrings.mustReturnAtLeastOneColumn);
    }
    return ok({ value: columnTypes[0].name, label: columnTypes[columnTypes.length - 1].name });
}

export function convertToParameterResult<T>(
    expectedDataType: RtdValue.BasicType,
    parameterDataSource: BasicParam.QueryDataSource,
    queryResult: KustoQueryResult,
    queryService: IQueryService,
    timeZone: TimeZone
): Result<BasicParam.StaticDataSourceValues<T>> {
    const columns = getColumns(parameterDataSource, queryResult.columns, expectedDataType);

    if (columns.kind === 'err') {
        return columns;
    }

    const valueColumn = queryResult.columns.find((c) => c.name === columns.value.value);
    const labelColumn =
        columns.value.label !== undefined ? queryResult.columns.find((c) => c.name === columns.value.label) : undefined;

    if (!valueColumn) {
        return err(`${localAppStrings.valueColumnMissing} "${columns.value.value}"`);
    }
    return buildParameterOptionsFromResult<T>(queryResult, valueColumn, labelColumn, queryService, timeZone);
}

/**
 * Observes a query derived from the given parameter
 */
export function observeQueryParameterOptions(
    queryService: IQueryService,
    parameterId: string,
    dashboardId: string,
    parameterDataSource: BasicParam.QueryDataSource,
    dataSource: UKustoDataSource,
    usedParameterValues: ParameterValue[],
    previousSource:
        | undefined
        | {
              parameterDataSource: BasicParam.QueryDataSource;
              dataSource: UKustoDataSource;
              usedParameterValues: ParameterValue[];
          }
): Observable<StreamQueryResult> {
    const query = newKustoQuery(parameterDataSource.query, dataSource, parameterId, usedParameterValues);

    const previousQuery: UQuery | undefined =
        previousSource &&
        newKustoQuery(
            previousSource.parameterDataSource.query,
            previousSource.dataSource,
            parameterId,
            previousSource.usedParameterValues
        );

    return queryService.registerAndObserveQuery(query, previousQuery?.queryHash, dashboardId);
}
