import { assertNever } from 'office-ui-fabric-react';

import { UKustoDataSource } from '../../core/domain';
import { Mutable } from '../../common';
import { APP_STRINGS } from '../../res';
import { ParameterValue, ParameterConfig, DurationParamConfig, UBasicParamConfig } from '../parameter';

import { newQueryHash } from './hash';
import { QuerySourceId, IKustoQuery } from './types';

export function newKustoQuery(
    tileQuery: string,
    dataSource: UKustoDataSource,
    sourceId: QuerySourceId,
    usedParameterValues: ParameterValue[]
): IKustoQuery {
    const queryWithoutHash: Mutable<Omit<IKustoQuery, 'queryHash'>> = {
        sourceId,
        query: tileQuery,
        dataSource,
        parameters: usedParameterValues,
    };

    return {
        ...queryWithoutHash,
        queryHash: newQueryHash(queryWithoutHash, usedParameterValues),
    };
}

const sampleQueryBuilding = (() => {
    const appendComment = (query: string, comment: string, includeComment: boolean) =>
        includeComment ? `${query} // ${comment}` : query;

    const { queryBuilding } = APP_STRINGS.editTilePage.querySamples;
    return {
        formatDurationParameter: (beginVariableName: string, endVariableName: string, includeComment: boolean) =>
            appendComment(
                `| where <${queryBuilding.datetimeColumnPlaceholder}> between (['${beginVariableName}'] .. ['${endVariableName}'])`,
                queryBuilding.DurationParamComment,
                includeComment
            ),
        formatSingleSelectParameter: (variableName: string, includeComment: boolean) =>
            appendComment(
                `| where isempty(['${variableName}']) or <${queryBuilding.columnNamePlaceholder}> == ['${variableName}']`,
                queryBuilding.singleSelectParameterComment,
                includeComment
            ),
        formatMultiSelectParameter: (variableName: string, includeComment: boolean) =>
            appendComment(
                `| where isempty(['${variableName}']) or <${queryBuilding.columnNamePlaceholder}> in (['${variableName}'])`,
                queryBuilding.multiSelectorParameterComment,
                includeComment
            ),
        formatFreeTextParameter: (variableName: string, includeComment: boolean) =>
            appendComment(
                `| where isempty(['${variableName}']) or <${queryBuilding.columnNamePlaceholder}> == ['${variableName}']`,
                queryBuilding.freeTextParameterComment,
                includeComment
            ),
        suffix: '| take 100',
    };
})();

export interface NewSampleQueryOptions {
    commentOut?: boolean;
    prefix?: string;
    addTrailingNewline?: boolean;
    includeDocComments?: boolean;
}

/**
 * Creates a sample query for KQL
 * @param tableName The name of the table to query
 * @param parameters The parameters to include in the query. They will be included in the order provided, grouped by duration, single, and multi-select types
 * @param additionalOptions Additional customization of query text generation
 */
export function newSampleQuery(
    tableName: string | undefined,
    parameters: readonly ParameterConfig[],
    options?: NewSampleQueryOptions
): string {
    const { commentOut, prefix, addTrailingNewline, includeDocComments = false } = options ?? {};

    let query = '';

    if (prefix) {
        query += `${APP_STRINGS.editTilePage.querySamples.prefix}\n`;
    }

    query += `${tableName ?? APP_STRINGS.editTilePage.querySamples.tableName}`;

    const durationParameters: DurationParamConfig[] = [];
    const singleSelectParameters: UBasicParamConfig[] = [];
    const multiselectSelectParameters: UBasicParamConfig[] = [];
    const freeTextParameters: UBasicParamConfig[] = [];

    for (const parameter of parameters) {
        switch (parameter.kind) {
            case 'duration':
                durationParameters.push(parameter);
                break;
            case 'basic':
                switch (parameter.options.selectionKind) {
                    case 'array-null': {
                        multiselectSelectParameters.push(parameter);
                        break;
                    }
                    case 'freetext':
                        freeTextParameters.push(parameter);
                        break;
                    case 'scalar':
                    case 'scalar-null': {
                        singleSelectParameters.push(parameter);
                        break;
                    }
                }
                break;
            default:
                assertNever(parameter);
        }
    }

    for (const parameter of durationParameters) {
        query += `\n${sampleQueryBuilding.formatDurationParameter(
            parameter.beginVariableName,
            parameter.endVariableName,
            includeDocComments
        )}`;
    }

    for (const parameter of singleSelectParameters) {
        query += `\n${sampleQueryBuilding.formatSingleSelectParameter(
            parameter.options.variableName,
            includeDocComments
        )}`;
    }

    for (const parameter of multiselectSelectParameters) {
        query += `\n${sampleQueryBuilding.formatMultiSelectParameter(
            parameter.options.variableName,
            includeDocComments
        )}`;
    }

    for (const parameter of freeTextParameters) {
        query += `\n${sampleQueryBuilding.formatFreeTextParameter(parameter.options.variableName, includeDocComments)}`;
    }

    query += `\n${sampleQueryBuilding.suffix}`;

    if (commentOut) {
        // Comment out first line as well
        query = `// ${query.replace(/\n/g, '\n// ')}`;
    }

    return addTrailingNewline ? `${query}\n` : query;
}
