// eslint-disable-next-line no-shadow
export enum IDashboardListPermission {
    viewer = 0,
    editor = 1,
    none = 2,
}

export interface IDashboardListItem {
    id: string;
    title: string;
    openedAt: number;
    permission: IDashboardListPermission;
    createdBy: string;
    /*
     * createdAt: 0 is a placeholder time that should be treated as null/nonexistent
     */
    createdAt: number;
}
