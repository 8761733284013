import { assertNever } from 'office-ui-fabric-react';

import { CanInfer } from '../../../../../../../common';
import { ColorRule, colorRulesIcons, infer } from '../../../../../../../domain';

export type ColorRuleCalloutActions =
    | { type: 'setColor'; color: ColorRule.Color }
    | { type: 'setIcon'; icon: ColorRule.Icon }
    | {
          type: 'setConditionOperatorAtIndex';
          index: 0 | 1;
          operator: ColorRule.Operator;
      }
    | { type: 'setConditionValueAtIndex'; index: 0 | 1; value: string }
    | { type: 'removeSecondCondition' }
    | { type: 'setColumn'; column: CanInfer<string> }
    | { type: 'setLabel'; label: string }
    | { type: 'setIndicatorType'; indicatorType: 'none' | 'icon' | 'text' };

export const colorRuleCalloutReducer = (state: ColorRule, action: ColorRuleCalloutActions): ColorRule => {
    switch (action.type) {
        case 'setColor':
            return { ...state, color: action.color };
        case 'setIcon':
            if (state.indicator?.kind !== 'icon') {
                return state;
            }
            return {
                ...state,
                indicator: { ...state.indicator, icon: action.icon },
            };
        case 'setConditionOperatorAtIndex':
            return {
                ...state,
                conditions: [
                    ...state.conditions.slice(0, action.index),
                    {
                        value: state.conditions[action.index]?.value ?? '',
                        operator: action.operator,
                    },
                    ...state.conditions.slice(action.index + 1),
                ],
            };
        case 'setConditionValueAtIndex': {
            const operator = state.conditions[action.index]?.operator;
            if (!operator) {
                return state;
            }
            return {
                ...state,
                conditions: [
                    ...state.conditions.slice(0, action.index),
                    { operator, value: action.value },
                    ...state.conditions.slice(action.index + 1),
                ],
            };
        }
        case 'removeSecondCondition':
            return { ...state, conditions: state.conditions.slice(0, 1) };
        case 'setColumn':
            return { ...state, column: action.column };
        case 'setLabel': {
            if (state.indicator.kind === 'none') {
                return state;
            }
            const label: CanInfer<string> = action.label ? { type: 'specified', value: action.label } : infer;

            const indicator = { ...state.indicator };
            if (indicator.kind === 'icon') {
                indicator.label = label;
            } else {
                indicator.text = label;
            }

            return { ...state, indicator };
        }
        case 'setIndicatorType': {
            switch (action.indicatorType) {
                case 'none':
                    return { ...state, indicator: { kind: 'none' } };
                case 'icon': {
                    if (state.indicator.kind === 'icon') {
                        return state;
                    }
                    const label = state.indicator.kind === 'text' ? state.indicator.text : infer;
                    return {
                        ...state,
                        indicator: {
                            kind: 'icon',
                            icon: colorRulesIcons[0],
                            label,
                        },
                    };
                }
                case 'text': {
                    if (state.indicator.kind === 'text') {
                        return state;
                    }
                    const text = state.indicator.kind === 'icon' ? state.indicator.label : infer;
                    return { ...state, indicator: { kind: 'text', text } };
                }
            }
        }
    }
    assertNever(action);
};
