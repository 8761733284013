import * as React from 'react';
import { useRef } from 'react';
import {
    ICommandBarItemProps,
    IComponentAs,
    CommandBarButton,
    IButtonProps,
    IContextualMenuProps,
    IContextualMenuItem,
} from 'office-ui-fabric-react';

import { APP_STRINGS, APP_CONSTANTS } from '../../res';
import { ButtonWithCallout, ButtonWithCalloutRef } from '../../components/fabric/button';
import { createLongSplitCommandBarButton } from '../../components/fabric/button/LongSplitCommandBarButton';
import type { AutoRefreshUpdateInterval } from '../../core/domain';
import { updatePermissionsPanelAction } from '../../components/Forms/permissions/DashboardPermissions';
import { copyToClipboard } from '../../common';
import { GlobalAction } from '../../store';

export const createCommandBarShareItems = (
    dashboardId: string | undefined,
    dashboardTitle: string,
    disabled: boolean,
    dispatch: React.Dispatch<GlobalAction>
): ICommandBarItemProps[] => {
    const ShareCommandBarWrapper: IComponentAs<ICommandBarItemProps> = (props) => {
        const { defaultRender, ...buttonProps } = props;
        const ButtonComponent = defaultRender || CommandBarButton;

        const buttonRef = useRef<ButtonWithCalloutRef>(null);

        const subMenuProps: IContextualMenuProps = {
            items: [
                {
                    key: 'copyLink',
                    name: APP_STRINGS.utilButtons.copyLink,
                    iconProps: { iconName: 'Link' },
                    onClick: (event) => {
                        event?.preventDefault();

                        copyToClipboard(window.location.href);

                        buttonRef.current?.open();
                    },
                    href: window.location.href,
                },
                {
                    key: 'permissions',
                    name: APP_STRINGS.manage.permissions,
                    iconProps: { iconName: 'Permissions' },
                    onClick: () => {
                        if (dashboardId) {
                            dispatch(updatePermissionsPanelAction({ dashboardId, dashboardTitle }));
                        }
                    },
                },
            ],
        };

        const completeButtonProps = {
            ...buttonProps,
            menuProps: subMenuProps,
        };

        return (
            <>
                {/* Cast is necessary due to componentRef type issues */}
                <ButtonWithCallout
                    ref={buttonRef}
                    component={ButtonComponent as React.ComponentClass<IButtonProps>}
                    calloutContent={APP_STRINGS.utilButtons.copyLinkSuccess}
                    {...(completeButtonProps as IButtonProps)}
                />
            </>
        );
    };

    return [
        {
            key: 'share',
            name: APP_STRINGS.utilButtons.share,
            iconProps: { iconName: 'Share' },
            disabled,
            commandBarButtonAs: ShareCommandBarWrapper,
            role: 'button',
        },
    ];
};

export const createCommandBarRefreshItem = (disabled: boolean, onRefresh: () => void): ICommandBarItemProps => ({
    key: 'refresh',
    name: APP_STRINGS.utilButtons.refresh,
    iconProps: { iconName: 'Refresh' },
    onClick: onRefresh,
    disabled,
    //  This fixes for the Narrator. Currently it says "menuitem" on the buttons.
    role: 'button',
});

export const createCommandBarAutoRefreshItem = ({
    autoRefreshInterval,
    minAutoRefreshInterval,
    autoRefreshEnabled,
    disabled,
    onRefresh,
    onSelectRefreshInterval,
}: {
    autoRefreshInterval: AutoRefreshUpdateInterval | undefined;
    minAutoRefreshInterval: AutoRefreshUpdateInterval | undefined;
    autoRefreshEnabled: boolean;

    disabled: boolean;
    onRefresh: () => void;
    onSelectRefreshInterval: (interval: AutoRefreshUpdateInterval | undefined) => void;
}): ICommandBarItemProps => {
    let startIndex = minAutoRefreshInterval
        ? APP_CONSTANTS.orderedAutoRefreshIntervals.indexOf(minAutoRefreshInterval)
        : 0;

    if (startIndex < 0) {
        startIndex = 0;
    }

    const items: IContextualMenuItem[] = [
        {
            key: 'off',
            text: APP_STRINGS.autoRefresh.disable,
            onClick: () => onSelectRefreshInterval(undefined),
        },
        ...APP_CONSTANTS.orderedAutoRefreshIntervals.slice(startIndex).map(
            (interval): IContextualMenuItem => ({
                key: interval,
                text: APP_CONSTANTS.supportedAutoRefreshIntervals[interval].text,
                onClick: () => onSelectRefreshInterval(interval),
            })
        ),
    ];

    const commandBarRefreshItem = createCommandBarRefreshItem(disabled, onRefresh);

    if (!autoRefreshEnabled) {
        // Auto refresh is completely disabled, hide the split entirely
        return commandBarRefreshItem;
    }

    if (!autoRefreshInterval) {
        // Auto refresh isn't on, hide the extended split text
        return { ...commandBarRefreshItem, split: true, subMenuProps: { items } };
    }

    const currentItem = APP_CONSTANTS.supportedAutoRefreshIntervals[autoRefreshInterval];

    return createLongSplitCommandBarButton(currentItem.text, {
        ...commandBarRefreshItem,
        subMenuProps: { items },
    });
};
