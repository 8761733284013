import * as React from 'react';
import { useCallback } from 'react';
import { CommandBarButton, IIconProps } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../res';
import { useCore } from '../../core';
import { useCurrent } from '../../common';

import { useQueryResult } from './QueryProvider';

import styles from './RunButton.module.scss';

export type RunButtonProps = { onRun: () => void; disabled: boolean };
const runIconProps: IIconProps = { iconName: 'Play' };
const cancelIconProps: IIconProps = { iconName: 'Stop' };

export const RunButton: React.FC<RunButtonProps> = ({ onRun, disabled }) => {
    const { queryService } = useCore();
    const [result, query] = useQueryResult();

    const executionId = result.status?.executionId;

    const currentQuery = useCurrent(query);

    const onCancel = useCallback(() => {
        if (!executionId || !currentQuery.current) {
            return;
        }

        queryService.cancelQuery(currentQuery.current, executionId);
    }, [executionId, queryService, currentQuery]);

    return result.status?.kind === 'loading' ? (
        <CommandBarButton key="cancel" className={styles.runButton} iconProps={cancelIconProps} onClick={onCancel}>
            {APP_STRINGS.utilButtons.cancel}
        </CommandBarButton>
    ) : (
        <CommandBarButton
            key="run"
            className={styles.runButton}
            iconProps={runIconProps}
            onClick={onRun}
            disabled={disabled}
        >
            {APP_STRINGS.utilButtons.run}
        </CommandBarButton>
    );
};
