import { Column, PostProcessPopupParams } from '@ag-grid-enterprise/all-modules';
import { AccessibilityColumnsFixer } from './AccessibilityColumnsPanel';
import { AccessibilitySelectorFixer } from './AccessibilitySelectorFixer';
import { AccessibleStrings } from './types';

export class AccessibleColumnMenu {
    private popElement: HTMLElement | null = null;
    private originColumn: Column | null | undefined;
    private mutationObserver: MutationObserver;

    private fixer: AccessibilitySelectorFixer;
    private gridFixer: AccessibilitySelectorFixer;
    private columnFixer: AccessibilityColumnsFixer;

    constructor(private getGridElement: () => HTMLDivElement | null, strings: AccessibleStrings) {
        // Handle close of popup to return focus to original element
        this.mutationObserver = new MutationObserver(this.onClosed);
        this.gridFixer = new AccessibilitySelectorFixer(
            [
                {
                    selector: '.ag-root-wrapper',
                    fixer: (elem) => {
                        this.mutationObserver.observe(elem, {
                            childList: true,
                        });
                    },
                    destroyer: (_elem) => {
                        this.mutationObserver.disconnect();
                    },
                    once: true,
                },
            ],
            this.getGridElement
        );

        // Menu element to updates
        this.fixer = new AccessibilitySelectorFixer(
            [
                {
                    // General
                    selector: 'me',
                    fixer: (elem) => {
                        elem.addEventListener('keydown', this.onKeydown);
                    },
                    destroyer: (elem) => {
                        elem.removeEventListener('keydown', this.onKeydown);
                        this.mutationObserver.disconnect();
                    },
                    once: true,
                },
                {
                    // menu tabs
                    ///////////////////////////////////////////
                    selector: '.ag-tab-header',
                    fixer: (elem) => {
                        elem.setAttribute('role', 'tablist');
                        elem.addEventListener('click', this.fixWithFocus);
                    },
                    destroyer: (elem) => {
                        elem.removeEventListener('click', this.fixWithFocus);
                    },
                    once: true,
                },
                {
                    selector: '.ag-tab-header .ag-tab',
                    fixer: (elem, index) => {
                        elem.setAttribute('role', 'tab');
                        elem.setAttribute('tabIndex', '-1');
                        elem.setAttribute('data-is-focusable', 'true');
                        elem.setAttribute('aria-label', strings.ariaTabsLabels[index]);
                    },
                    once: true,
                },
                {
                    selector: '.ag-tab-header .ag-tab',
                    fixer: (elem, index) => {
                        if (elem.classList.contains('ag-tab-selected')) {
                            elem.setAttribute('aria-selected', 'true');
                            elem.id = 'selected-tab-header';
                        } else {
                            elem.setAttribute('aria-selected', 'false');
                            elem.id = 'tab-header-' + index;
                        }
                    },
                },
                {
                    selector: '.ag-tab-header .ag-tab.ag-tab-selected',
                    fixer: (elem) => {
                        elem.focus();
                    },
                    when: ['tabFocus', 'focus'],
                },
                {
                    selector: '.ag-tab-body .ag-tab-body',
                    fixer: (elem, _index) => {
                        elem.setAttribute('role', 'tabPanel');
                        elem.setAttribute('aria-labelledby', 'selected-tab-header');
                    },
                },
                {
                    // Menu tab - action list
                    ////////////////////////////////////
                    selector: '.ag-tab-body .ag-menu-list',
                    fixer: (elem, _index) => {
                        elem.setAttribute('role', 'menu');
                        elem.setAttribute('aria-label', strings.columnContextMenu);
                    },
                },
                {
                    selector: '.ag-tab-body .ag-menu-option',
                    fixer: (elem, index) => {
                        elem.setAttribute('role', 'menuItem');
                        elem.setAttribute('tabIndex', '-1');
                        elem.setAttribute('data-is-focusable', 'true');
                        if (index === 0) {
                            elem.focus();
                        }
                    },
                    when: ['focus'],
                },
                {
                    // Menu tab - Filter
                    ////////////////////////////////////
                    selector: '.ag-filter .ag-filter-select',
                    fixer: (elem, _index) => {
                        elem.setAttribute('role', 'combobox');
                        elem.setAttribute('tabIndex', '-1');
                        elem.setAttribute('data-is-focusable', 'true');
                        elem.setAttribute('aria-haspopup', 'listbox');
                        elem.setAttribute('aria-label', strings.filterOperator);
                    },
                },
                {
                    selector: '.ag-filter .ag-filter-select option',
                    fixer: (elem, _index) => {
                        elem.setAttribute('role', 'option');
                        elem.setAttribute('tabIndex', '-1');
                        elem.setAttribute('data-is-focusable', 'true');
                        elem.setAttribute('aria-label', elem.getAttribute('value') || '');
                    },
                },
            ],
            this.getGui
        );
        this.columnFixer = new AccessibilityColumnsFixer(this.getGui);
    }

    init = (params: PostProcessPopupParams) => {
        this.destroy(); // Cleanup old listener before we start again
        if (params.type !== 'columnMenu' || !params.ePopup) {
            return;
        }
        this.popElement = params.ePopup;
        this.originColumn = params.column;
        this.fixWithFocus();
        this.gridFixer.fix();
    };

    destroy = () => {
        this.fixer.destroy();
        this.columnFixer.destroy();
        this.gridFixer.destroy();
        this.popElement = null;
    };

    private getGui = () => {
        return this.popElement;
    };

    private fixWithFocus = () => {
        this.fixer.debounceFix('focus');
        this.columnFixer.debounceFix('focus');
    };

    private onKeydown = (e: KeyboardEvent) => {
        const targetRole = e.target instanceof HTMLElement && (e.target as HTMLElement).getAttribute('role');
        if (e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            if (targetRole === 'tab') {
                this.fixWithFocus();
            } else {
                this.fixer.debounceFix('tabFocus');
            }
        }
    };

    private onClosed = () => {
        let elem: HTMLElement | null = null;
        this.destroy();
        if (!this.getGridElement()) {
            return;
        }
        if (this.originColumn) {
            elem = this.getGridElement()!.querySelector<HTMLElement>(
                '.ag-header-cell[col-id="' + this.originColumn.getId() + '"] .ag-cell-label-container'
            );
        }

        if (elem) {
            elem.focus();
        }
    };
}
