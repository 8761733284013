import { AppPages } from '../common/types';

declare global {
    interface Window {
        /**
         * window.firstDirectoryInURL should be set by index.html.
         * If the URL is https://a.b.com/PR12345678/dir2/file, firstDirectoryInURL will be PR12345678.
         * firstDirectoryInURL will only be set if the first directory is one of the following - PR<PR number> / v<sem-ver> / master.
         */
        firstDirectoryInURL: string;
    }
}

export const firstDirectoryInURL: string = window.firstDirectoryInURL;

function getBasePathName() {
    let _basePathName = '/';
    const startsWithDotRegex = /^\./;
    // publicURL without the leading dot. A dot represents a relative URL, but This method should return the base of the path name.
    const publicURL = (process.env.PUBLIC_URL ?? '').replace(startsWithDotRegex, '');

    if (firstDirectoryInURL && publicURL) {
        // both exist - concatenate.
        _basePathName = `${publicURL}/${firstDirectoryInURL}/`;
    } else if (!firstDirectoryInURL && !publicURL) {
        // both empty - return /
        _basePathName = `/`;
    } else if (firstDirectoryInURL) {
        // Only firstDirectoryInURL exist - return /firstDirectoryInURL/
        _basePathName = `/${firstDirectoryInURL}/`;
    } else if (publicURL) {
        _basePathName = `${publicURL}/`; // Only publicURL exist - return /publicURL/
    }

    return _basePathName;
}

/**
 * Returns the base path name. Always comes with leading and trailing slash.
 * If location.pathname returns /PR123456768/dashboards, then basePathName will be /PR123456768/
 * if location.pathname returns /dashboards, then basePathName will be /
 */
export const basePathName = getBasePathName(); // comes with leading and trailing slash

export const getPath = (page: AppPages) => `/${page}`;

/**
 * Appends a trailing slash to the end of the provided string if none is present
 */
export const appendSlashIfNecessary = (input: string): string => {
    if (input.length < 1) {
        return input;
    }

    return input[input.length - 1] !== '/' ? `${input}/` : input;
};
