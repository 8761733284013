import React from 'react';
import { isMacOs } from 'react-device-detect';
import { GlobalHotKeys as ReactGlobalHotKeys } from 'react-hotkeys';
import { useRootStore } from './stores/hooks';
import { getTelemetryClient } from './utils/telemetryClient';
import { configure } from 'react-hotkeys';

const { trackEvent } = getTelemetryClient({ component: 'GlobalHotKeys', flow: '' });

configure({
    /**
     * The level of logging of its own behaviour React HotKeys should perform.
     */
    logLevel: 'warn', // Change to verbose to debug key strokes
});

// For some reason on my mac the T is tracked as ˇ.
const keyTForMac = 'ˇ';

export const GlobalHotKeys: React.FC = () => {
    const store = useRootStore();

    return (
        <ReactGlobalHotKeys
            keyMap={{
                undoClosedTab: ['Control+Alt+Shift+T', 'Meta+Alt+Shift+' + keyTForMac],
                search: `${isMacOs ? 'Meta' : 'Control'}+Shift+F`,
            }}
            handlers={{
                undoClosedTab: () => {
                    trackEvent('onUndoTabRemoval', { trigger: 'shortcut', shortcut: 'command+ctrl+shift+t' });
                    store.tabs.undoTabRemoval();
                },
                search: () => {
                    trackEvent('onSearch', { trigger: 'shortcut', shortcut: 'ctrl+shift+f' });
                    store.tabs.tabInContext?.enableSearch(true);
                },
            }}
        />
    );
};
