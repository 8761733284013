import React from 'react';
import { Icon } from 'office-ui-fabric-react';
import classNames from 'classnames';

import styles from './WarningsList.module.scss';

export interface WarningsListProps {
    warnings: React.ReactNode[];
    level?: 'warning' | 'error';
}

export const WarningsList: React.FC<WarningsListProps> = ({ warnings, level = 'warning' }) => {
    return (
        <>
            {warnings.map((w, i) => (
                <div className={classNames(styles.item, styles[level])} key={i}>
                    <Icon iconName={level === 'warning' ? 'warning' : 'errorBadge'} />
                    <p>{w}</p>
                </div>
            ))}
        </>
    );
};
