import * as React from 'react';
import { Label, Toggle } from 'office-ui-fabric-react';

import { useETPDispatch, useETPSelector } from '../../../../../store/editTile';
import { visualOptionsSelectorBuilder } from '../../../lib';
import { SegmentToggleInfo } from '../../../constants';

import styles from './SectionToggle.module.scss';

export const SectionToggle: React.FC<SegmentToggleInfo> = ({ optionKey, titleText, labels, invert }) => {
    const [dispatch, getState] = useETPDispatch();

    const { checkedSelector, onChange } = React.useMemo(
        () => ({
            checkedSelector: visualOptionsSelectorBuilder((options) => options[optionKey]),
            onChange() {
                const state = getState();
                if (state.type !== 'query' || state.visual === undefined) {
                    return;
                }

                dispatch({
                    type: 'updateVisualOptions',
                    options: { [optionKey]: !state.visual.options[optionKey] },
                });
            },
        }),
        [dispatch, getState, optionKey]
    );

    let checked = useETPSelector(checkedSelector);

    if (invert) {
        checked = !checked;
    }

    const id = `visual-options--${optionKey}-toggle`;

    return (
        <div className={styles.farRightToggleWrapper}>
            <Label className={styles.farRightToggleLabel} htmlFor={id}>
                {checked ? labels.enabled : labels.disabled}
            </Label>
            <Toggle
                className={styles.farRightToggleToggle}
                id={id}
                checked={checked}
                onChange={onChange}
                title={titleText}
            />
        </div>
    );
};
