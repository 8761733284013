import React from 'react';
import { assertNever, IDropdownOption } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../res';
import { RTDDropdown, RTDDropdownOption } from '../../../components';
import { IEditParameterPageComponentProps } from '../types';
import { EppParameterType, EppUnionOptions } from '../reducer';

interface IParameterSelectionTypeDropdownProps extends IEditParameterPageComponentProps {
    state: EppUnionOptions;
}

const types: EppParameterType[] = ['scalar', 'array-null', 'freetext', 'duration'];

const options: RTDDropdownOption[] = types.map((key) => ({
    key,
    text: APP_STRINGS.domain.parameter.parameterTypes[key],
}));

export const ParameterSelectionTypeDropdown: React.FC<IParameterSelectionTypeDropdownProps> = ({
    className,
    disabled,
    state,
    dispatch,
}) => {
    const onChange = React.useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (!option) {
                return;
            }

            dispatch({
                type: 'setParameterType',
                paramType: option.key as EppParameterType,
            });
        },
        [dispatch]
    );

    let selectionType: EppParameterType;

    if (state.kind === 'duration') {
        selectionType = 'duration';
    } else if (state.union.kind === 'freetext') {
        selectionType = 'freetext';
    } else {
        switch (state.union.selectionKind) {
            case 'array-null':
                selectionType = 'array-null';
                break;
            case 'scalar':
            case 'scalar-null':
                selectionType = 'scalar';
                break;
            default:
                assertNever(state.union.selectionKind);
        }
    }

    return (
        <RTDDropdown
            className={className}
            label={APP_STRINGS.editParameterPage.selectionType}
            required={true}
            options={options}
            selectedKey={selectionType}
            onChange={onChange}
            disabled={disabled}
        />
    );
};
