import { runInAction } from 'mobx';
import React, { useRef, useEffect, useMemo, useCallback, RefObject, useImperativeHandle, forwardRef } from 'react';
import debounce from 'lodash/debounce';

import { APP_CONSTANTS } from '../../res';
import { useThemeState } from '../../domain/theming';
import {
    updateMonacoSchema,
    updateActiveParameters,
    IKustoInternalParameter,
    ParameterConfig,
    variablesAndKustoTypesFromParameter,
} from '../../domain';
import { useCurrent } from '../../common';
import { useTelemetryException } from '../../domain/telemetry';
import { useCore } from '../../core';
import { UDataSource } from '../../core/domain';
import { useDashboardStore } from '../../store';

import { ReactMonacoEditor, ReactMonacoEditorProps } from './ReactMonacoEditor';
import { KustoEditor } from './KustoEditor';

import styles from './QueryEditor.module.scss';

export interface IEditorProps {
    className?: string;
    /**
     * The initial value Monaco should be set to
     *
     * **NOTE:** Changing this value will result in an update to Monaco's state
     */
    initialValue: string | undefined;
    telemetryComponentName: string;

    isMarkdown?: boolean;
    width?: string;
    dataSource: UDataSource | undefined;
    parameters: undefined | readonly ParameterConfig[];

    onChange: (query: string) => void;
    onParameterChange?: (activeParameters: Set<string>) => void;
    runQuery?: () => void;
    editorDidMount?: (
        editor: monaco.editor.IStandaloneCodeEditor,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        monaco: any
    ) => void;
}

export interface IQueryEditor {
    /**
     * Forcibly sync any uncommitted changes
     */
    forceUpdate: () => Promise<void>;
    monacoEditor: RefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
}

const onParameterChangeUpdate = async (
    editorRef: RefObject<monaco.editor.IStandaloneCodeEditor | undefined>,
    onChangeRef: RefObject<((activeParameters: Set<string>) => void) | undefined>,
    parametersRef: RefObject<IKustoInternalParameter[]>
) => {
    if (!editorRef.current || !onChangeRef.current || parametersRef.current === null) {
        return;
    }

    const parameters = await updateActiveParameters(editorRef.current, parametersRef.current);

    if (!parameters) {
        return;
    }

    onChangeRef.current(new Set(parameters.map((p) => p.name)));
};

const debouncedOnParameterChangeUpdate = debounce(onParameterChangeUpdate, APP_CONSTANTS.debounce.standard, {
    leading: true,
});

export const QueryEditor = forwardRef<IQueryEditor, IEditorProps>(
    (
        {
            className,
            isMarkdown,
            initialValue,
            width,
            dataSource,
            telemetryComponentName,
            parameters,
            onChange,
            onParameterChange,
            runQuery,
            editorDidMount,
        },
        ref
    ) => {
        const { isDark } = useThemeState();
        const {
            queryService,
            hostConfig: { requireJsUrl },
        } = useCore();
        const dashboardStore = useDashboardStore();
        const trackException = useTelemetryException(telemetryComponentName);

        const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
        const currentOnParameterChange = useCurrent(onParameterChange);

        const intellisenseParameters = useMemo(
            () => (parameters ? parameters.flatMap(variablesAndKustoTypesFromParameter) : []),
            [parameters]
        );

        const currentIntellisenseParameters = useCurrent(intellisenseParameters);

        const augmentedOnChange = useCallback(
            (newValue: string) => {
                if (!isMarkdown) {
                    debouncedOnParameterChangeUpdate(
                        editorRef,
                        currentOnParameterChange,
                        currentIntellisenseParameters
                    );
                }

                onChange(newValue);
            },
            [isMarkdown, onChange, currentOnParameterChange, currentIntellisenseParameters]
        );

        const commonEditorProps: ReactMonacoEditorProps = {
            value: initialValue,
            requireConfig: {
                ...APP_CONSTANTS.monaco.requireConfig,
                url: requireJsUrl,
            },
            options: {
                automaticLayout: true,
                wordWrap: 'on',
                ...APP_CONSTANTS.monaco.defaultOptions(),
                minimap: {
                    enabled: false,
                },
            },
            width: width,
            onChange: augmentedOnChange,
            editorDidMount,
        };

        // Allow parent with ref to imperatively call forceUpdate
        useImperativeHandle(
            ref,
            (): IQueryEditor => ({
                async forceUpdate() {
                    if (isMarkdown) {
                        return;
                    }

                    await debouncedOnParameterChangeUpdate.flush();
                },
                monacoEditor: editorRef,
            }),
            [editorRef, isMarkdown]
        );

        useEffect(() => {
            if (isMarkdown || !editorRef.current) {
                return;
            }
            const dashboardId = runInAction(() => dashboardStore.state?.meta.id);
            if (dashboardId !== undefined) {
                updateMonacoSchema(
                    editorRef.current,
                    queryService,
                    trackException,
                    intellisenseParameters,
                    dataSource,
                    dashboardId
                );
            }
        }, [dashboardStore, dataSource, intellisenseParameters, isMarkdown, queryService, trackException]);

        useEffect(() => {
            // updates runQuery command for everyRender
            // same code as below for mounting the kustoEditor
            if (runQuery && editorRef.current) {
                editorRef.current.addCommand(monaco.KeyMod.Shift | monaco.KeyCode.Enter, () => runQuery(), '');
            }
        }, [runQuery, editorRef]);

        return (
            // Extra div added because `height: 100%` was not working on both of the
            // places with component was used on firefox when div was made smaller
            // than monaco editor height. Inner div is now absolutely positioned so it
            // does not prevent wrapper div from shrinking. `overflow: hidden;` cannot
            // be used because the monaco editor's menu's need to flow over.
            <div className={className ? `${styles.queryEditor} ${className}` : styles.queryEditorsNames}>
                {isMarkdown ? (
                    <ReactMonacoEditor
                        {...commonEditorProps}
                        height="100%"
                        language="markdown"
                        theme={isDark ? 'vs-dark' : 'vs'}
                        editorDidMount={(editor) => (editorRef.current = editor)}
                    />
                ) : (
                    <KustoEditor
                        {...commonEditorProps}
                        height="100%"
                        isDarkTheme={isDark}
                        editorDidMount={(editor, monaco) => {
                            editorRef.current = editor;
                            // Duplicate of useEffect insures the command is added on initialization
                            if (runQuery) {
                                editor.addCommand(monaco.KeyMod.Shift | monaco.KeyCode.Enter, () => runQuery(), '');
                            }

                            editorDidMount?.(editor, monaco);

                            const dashboardId = runInAction(() => dashboardStore.state?.meta.id);

                            if (dashboardId !== undefined) {
                                updateMonacoSchema(
                                    editorRef.current,
                                    queryService,
                                    trackException,
                                    intellisenseParameters,
                                    dataSource,
                                    dashboardId
                                );
                            }
                        }}
                    />
                )}
            </div>
        );
    }
);
