import React from 'react';
import { ActionButtonWithTooltip, ContextualMenuItemWithTooltip, formatLiterals } from '@kusto/common';
import { registerIcons } from 'office-ui-fabric-react/lib/Styling';
import { DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { DetailsViewType } from '../agGrid/GridWithExpand';
import * as Icons from './icons';

registerIcons({
    icons: {
        expandInline: <Icons.Inline />,
        expandBelow: <Icons.Below />,
        expandRight: <Icons.Right />,
    },
});

type ExpandSelectionStrings = {
    queryDetailedViewInlineLayoutLabel: string;
    queryDetailedViewInlineLayoutDescription: string;
    queryDetailedViewBelowLayoutLabel: string;
    queryDetailedViewBelowLayoutDescription: string;
    queryDetailedViewRightLayoutLabel: string;
    queryDetailedViewRightLayoutDescription: string;
    expandViewAriaLabel: string;
    expandViewToolTip: string;
    expandViewLabel: string;
};

export const ExpandSelectionButton: React.StatelessComponent<{
    selected: DetailsViewType;
    onSelected?: (selected: DetailsViewType) => void;
    strings: ExpandSelectionStrings;
    disableExpandRight?: boolean;
    hideExpandRight?: boolean;
    showLabel?: boolean;
}> = ({ selected, onSelected, strings, disableExpandRight, hideExpandRight, showLabel }) => {
    const expandLayoutMapping: IContextualMenuItem[] = React.useMemo(
        () =>
            [
                {
                    data: DetailsViewType.InGrid,
                    label: strings.queryDetailedViewInlineLayoutLabel,
                    description: strings.queryDetailedViewInlineLayoutDescription,
                    iconName: 'ExpandInline',
                    isDisabled: false,
                },
                {
                    data: DetailsViewType.BelowGrid,
                    label: strings.queryDetailedViewBelowLayoutLabel,
                    description: strings.queryDetailedViewBelowLayoutDescription,
                    iconName: 'ExpandBelow',
                },
            ]
                .concat(
                    hideExpandRight
                        ? []
                        : [
                              {
                                  data: DetailsViewType.ExternalPanel,
                                  label: strings.queryDetailedViewRightLayoutLabel,
                                  description: strings.queryDetailedViewRightLayoutDescription,
                                  iconName: 'ExpandRight',
                                  isDisabled: disableExpandRight, // When there is no external panel provider
                              },
                          ]
                )
                .map((item) => ({
                    key: item.label,
                    data: item.data,
                    text: item.label,
                    disabled: item.isDisabled,
                    iconProps: { iconName: item.iconName },
                    tooltipProps: { content: item.description, directionalHint: DirectionalHint.leftCenter },
                })),
        [strings, disableExpandRight, hideExpandRight]
    );
    const selectedItem = expandLayoutMapping.find((option) => option.data === selected) || expandLayoutMapping[0];

    return (
        <ActionButtonWithTooltip
            key="expand layout"
            text={showLabel ? strings.expandViewLabel : undefined}
            ariaDescription={formatLiterals(strings.expandViewAriaLabel, {
                selected: selectedItem.label,
            })}
            iconProps={{
                iconName: selectedItem.iconProps?.iconName,
                ariaLabel: selectedItem.label,
            }}
            tooltipProps={{
                content: strings.expandViewToolTip,
                directionalHint: DirectionalHint.bottomCenter,
            }}
            menuProps={{
                onItemClick: (_e, item) => {
                    if (item && onSelected) {
                        onSelected(item.data as DetailsViewType);
                    }
                },
                items: expandLayoutMapping,
                contextualMenuItemAs: ContextualMenuItemWithTooltip,
            }}
        />
    );
};
