import axios, { AxiosResponse, AxiosRequestConfig, CancelTokenSource, CancelToken } from 'axios';
import { IKustoClientAuthProvider } from './authenticationProvider';
import { v4 } from 'uuid';
import { KustoDomains } from './kusto';
import { ColumnType, VisualizationOptions } from '../types';

export interface KustoClientEvents {
    /** Triggers when the token is expired just before it is re-fetched. */
    tokenExpired?: (message: string) => void;
    /** Triggers when a valid token has fetched successfully */
    tokenFetched?: (
        clientActivityId: string,
        applicationUrl: string,
        url: string,
        dbName: string,
        queryOrCommand: string,
        isQuery: boolean
    ) => void;
    /**
     * Triggers when the backend returned a Unauthorized error (401).
     * @param clientActivityId The activity ID that was passed to execute
     * @param retrying true when retrying due to unauthorized error (401)
     * @param refreshTokenSupported retry will only work if refresh token is supported in the authentication provider.
     * @param retryCount the retry number
     * @param error the 401 error thrown by axios.
     */
    onUnauthorizedError?: (
        clientActivityId: string,
        retrying: boolean,
        refreshTokenSupported: boolean,
        retryCount: number,
        error: Error
    ) => void;
    /** Triggers after content is parsed. Can be used for parsing benchmark. */
    responseParsed?: (type: string, duration: number, length?: number) => void;
}

//
// Json response from kusto service might include numbers that are bigger than javascript MAX_INT
// When using the default JSON.parse they will be converted to number and lose precision
// issue known as BigInt/BigInteger
//
// In order to overcome the issue, numbers will be kept as String and displayed as string as much as possible
const JSONbig = require('json-bigint')({ storeAsString: true, protoAction: 'preserve', constructorAction: 'preserve' });
const HttpCodes = {
    unauthorized: 401,
};
// Export axios's cancellation APIs, so callers won't need to import types from axios.
export type CancellationTokenSource = CancelTokenSource;
export type CancellationToken = CancelToken;
export const isCancelledError = axios.isCancel;

export interface KustoResult {
    Tables: {
        Columns: ColumnV1[];
        Rows: RowV1[];
        TableName: string;
    }[];
}

export interface DataSetHeader {
    FrameType: 'DataSetHeader';
    IsProgressive: boolean;
    Version: string;
}

export interface ColumnV1 {
    ColumnName: string;
    ColumnType: ColumnType;
    DataType: string;
}

export interface Column {
    ColumnName: string;
    ColumnType: ColumnType;
}

export type ValueRow = unknown[];

export interface ErrorRowV1 {
    Exceptions: string[];
}

export interface ErrorRow {
    OneApiErrors: OneApiError[];
}

export type RowV1 = ValueRow | ErrorRowV1;
export type Row = ValueRow | ErrorRow;

export type TableKind = 'QueryProperties' | 'PrimaryResult' | 'QueryCompletionInformation';

export interface DataTable {
    FrameType: 'DataTable';
    TableId: number;
    TableKind: TableKind;
    TableName: string;
    Columns: Column[];
    Rows: Row[];
}

export interface DataSetCompletion {
    FrameType: 'DataSetCompletion';
    HasErrors: boolean;
    Cancelled: boolean;
    OneApiErrors: OneApiError[];
}

export type Frame = DataSetHeader | DataTable | DataSetCompletion;

export type KustoResultV2 = Frame[];

export interface OneApiError {
    error: {
        code: string;
        message: string;
        '@type': string;
        '@message': string;
        '@context': OneApiErrorContext;
        '@permanent': boolean;
    };
}

export interface OneApiErrorContext {
    timestamp: string;
    serviceAlias: string;
    machineName: string;
    processName: string;
    processId: number;
    threadId: number;
    appDomainName: string;
    clientRequestId: string;
    activityId: string;
    subActivityId: string;
    activityType: string;
    parentActivityId: string;
    activityStack: string;
}

export interface ClientRequestOptions {
    query_language: 'sql' | 'csl';
    queryconsistency: 'strongconsistency' | 'weakconsistency';
    servertimeout: string;

    /** Request is read-only, however, may still have external side effects (http_requests or sql external commands). */
    request_readonly: boolean;

    /** Request is read-only, no external side effects. http_requests or sql external commands are blocked. */
    request_readonly_hardline: boolean;
}
export interface ClientRequestProperties {
    Options?: Partial<ClientRequestOptions>;
    Parameters?: { [key: string]: string };
}

export interface RequestHeaders {
    /** pass as x-ms-app. Default: `KusWeb`. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#application-x-ms-app */
    appName?: string;

    /** passed as x-ms-user (a.k.a x-ms-user-id). Default: IKustoClientAuthProvider.getUser().userName ?? 'unknown'.  @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#user-x-ms-user */
    userId?: string;

    /** passed as x-ms-client-request-id. Default: `KustoWebV2;<GUID>`. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestid-x-ms-client-request-id */
    clientRequestId?: string;
}

export interface ExecutionResult<T extends ApiVersion> {
    apiCallResult: T extends 'v1' ? KustoResult : KustoResultV2;
    httpRequestStartTime: Date;
}

export type ApiVersion = 'v1' | 'v2';

export const buildClientURL = (url: string, apiVersion: ApiVersion, isQuery: boolean): string =>
    `${url.replace(/\/$/, '')}/${apiVersion}/rest/${isQuery ? 'query' : 'mgmt'}`;

/**
 * A class to query kusto clusters.
 */
export class KustoClient {
    /**
     * @param authenticationProvider An auth provider that is used to get an auth token.
     * @param events Optional. Listen to different events raised when executing a query.
     */
    constructor(private authenticationProvider: IKustoClientAuthProvider, private events?: KustoClientEvents) {}

    async cancelQuery(
        url: string,
        clientActivityId: string,
        requestTimeout?: number,
        cancelClientActivityId?: string
    ): Promise<ExecutionResult<'v1'>> {
        return this.executeControlCommand(
            url,
            undefined,
            `.cancel query '${clientActivityId}'`,
            undefined,
            requestTimeout,
            { clientRequestId: cancelClientActivityId }
        );
    }

    createCancelToken(): CancellationTokenSource {
        return axios.CancelToken.source();
    }

    /**
     * Return the value for the HTTP header x-ms-user-id. Expects `this.authenticationProvider.getUser` to return a User.
     */
    private async getUserIDHeader(): Promise<string | undefined> {
        if (!this.authenticationProvider.getUser) {
            return undefined;
        }
        const noAsciiValuesRegex = /[^\x20-\x7E]+/g;
        const user = await this.authenticationProvider.getUser();
        const userNameAsciiOnly = user?.userName?.replace(' ', '_').replace(noAsciiValuesRegex, '');
        let uid = userNameAsciiOnly;

        // if user name contains ascii characters take the user name from the user's email (upn).
        if (!userNameAsciiOnly || user.userName.length !== userNameAsciiOnly.length) {
            const userEmail = user?.profile?.upn;
            const userAlias = userEmail?.split('@')[0].replace(noAsciiValuesRegex, '');
            if (userAlias) {
                uid = userAlias;
            }
        }
        return uid ?? undefined;
    }

    /**
     * Execute a query or a command query against a Kusto cluster
     * @param url Cluster URL
     * @param dbName The name of the database
     * @param queryOrCommand  The query or command to run. (commands starts with `.`, like `.show schema`)
     * @param isQuery is it a query or command
     * @param apiVersion Which API Version to use. Currently command queries are only supported by v1.
     * @param properties Provides client request properties that modify how the request is processed and its results. For more information, see client request properties. @see https://docs.microsoft.com/en-us/azure/data-explorer/kusto/api/netfx/request-properties#clientrequestproperties-options.
     * @param requestTimeout Timeout before the request is cancelled.
     * @param cancelToken Cancellation token created with createCancelToken.
     * @param retryCount On auth failure how many retries to perform. Default is one.
     */
    async execute<T extends ApiVersion>(
        url: string,
        dbName: string | undefined,
        queryOrCommand: string,
        isQuery: boolean,
        apiVersion: T,
        properties?: ClientRequestProperties,
        requestTimeout?: number,
        cancelToken?: CancellationToken,
        retryCount: number = 0,
        requestHeaders?: RequestHeaders
    ): Promise<T extends 'v1' ? ExecutionResult<'v1'> : ExecutionResult<'v2'>> {
        const uid = requestHeaders?.userId || (await this.getUserIDHeader()) || 'unknown';
        const isLocalDevCluster = KustoDomains.isLocalDevDomain(url);

        const token = isLocalDevCluster
            ? ''
            : await this.authenticationProvider.getToken(undefined, this.events?.tokenExpired);

        let cid = requestHeaders?.clientRequestId || `KustoWebV2;${v4()}`;

        if (this.events?.tokenFetched) {
            this.events?.tokenFetched(cid, window.location.href, url, dbName || 'N/A', queryOrCommand, isQuery);
        }

        const httpRequestStartTime = new Date();
        const urlWithQuery = buildClientURL(url, apiVersion, isQuery);
        const data = {
            db: dbName,
            csl: queryOrCommand,
            properties: properties ? properties : null,
        };
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'x-ms-app': requestHeaders?.appName ?? 'KusWeb',
                'x-ms-client-request-id': cid,
                'x-ms-user-id': uid,
                Accept: 'application/json',
            },
            transformResponse: [this.parseResponse],
            cancelToken: cancelToken,
            timeout: requestTimeout,
        };
        if (!isLocalDevCluster) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        let axiosResponse: AxiosResponse<any>;
        try {
            axiosResponse = await axios.post(urlWithQuery, data, config);
        } catch (e) {
            if (e?.response?.status === HttpCodes.unauthorized && retryCount === 0) {
                // authorization error
                if (this.authenticationProvider.refreshToken && this.authenticationProvider.refreshToken()) {
                    if (this.events?.onUnauthorizedError) {
                        this.events?.onUnauthorizedError(cid, true, true, retryCount + 1, e);
                    }
                    return this.execute<T>(
                        url,
                        dbName,
                        queryOrCommand,
                        isQuery,
                        apiVersion,
                        properties,
                        requestTimeout,
                        cancelToken,
                        retryCount + 1
                    );
                } else {
                    if (this.events?.onUnauthorizedError) {
                        this.events?.onUnauthorizedError(cid, false, false, retryCount, e);
                    }
                    throw e;
                }
            }

            if (e?.response?.status === HttpCodes.unauthorized && this.events?.onUnauthorizedError) {
                this.events?.onUnauthorizedError(cid, false, false, retryCount, e);
            }

            if (e?.response?.data) {
                throw e.response.data;
            } else {
                throw e;
            }
        }

        const result = {
            apiCallResult: axiosResponse?.data,
            httpRequestStartTime,
        } as T extends 'v1' ? ExecutionResult<'v1'> : ExecutionResult<'v2'>;
        return result;
    }

    private parseResponse(apiCallResult: any) {
        let responseType = typeof apiCallResult;
        let length: number | undefined;
        const start = performance.now();
        if (responseType === 'string') {
            length = apiCallResult ? apiCallResult.length : 0;
            const json = JSONbig.parse(apiCallResult);
            return json;
        }

        if (this.events?.responseParsed) {
            this.events?.responseParsed(responseType, performance.now() - start, length);
        }
    }

    executeControlCommand(
        url: string,
        dbName: string | undefined,
        command: string,
        properties?: ClientRequestProperties,
        requestTimeout?: number,
        requestHeaders?: RequestHeaders,
        cancelToken?: CancelToken
    ): Promise<ExecutionResult<'v1'>> {
        return this.execute(
            url,
            dbName,
            command,
            false,
            'v1',
            properties,
            requestTimeout,
            cancelToken,
            undefined,
            requestHeaders
        );
    }

    executeQuery(
        url: string,
        dbName: string,
        query: string,
        properties?: ClientRequestProperties,
        requestHeaders?: RequestHeaders,
        cancelToken?: CancelToken
    ): Promise<ExecutionResult<'v1'>> {
        return this.execute(
            url,
            dbName,
            query,
            true,
            'v1',
            properties,
            undefined,
            cancelToken,
            undefined,
            requestHeaders
        );
    }

    executeQueryV2(
        url: string,
        dbName: string,
        query: string,
        properties?: ClientRequestProperties,
        requestHeaders?: RequestHeaders,
        cancelToken?: CancelToken
    ): Promise<ExecutionResult<'v2'>> {
        return this.execute(
            url,
            dbName,
            query,
            true,
            'v2',
            properties,
            undefined,
            cancelToken,
            undefined,
            requestHeaders
        );
    }

    async executeV2(
        url: string,
        dbName: string,
        queryOrCommand: string,
        isQuery: boolean,
        requestHeaders?: RequestHeaders,
        cancelToken?: CancelToken
    ): Promise<ExecutionResult<'v2'>> {
        return this.execute(
            url,
            dbName,
            queryOrCommand,
            isQuery,
            'v2',
            undefined,
            undefined,
            cancelToken,
            undefined,
            requestHeaders
        );
    }
}

// TODO: some code duplication with getVisualizationOptions from tab.ts.
export const getVisualizationOptions = (queryResults: KustoResult): VisualizationOptions => {
    const kindIndex = 1; // the kind column in the TOC result table.

    // If there's only one table, this is probably a control command and it's the only primary result.
    // otherwise, the last table will be the TOC and we can extract the number of query results from it.
    const numberOfPrimaryResults =
        queryResults.Tables.length === 1
            ? 1
            : (queryResults.Tables[queryResults.Tables.length - 1].Rows as ValueRow[]).filter(
                  (row) => row[kindIndex] === 'QueryResult'
              ).length;
    // Extract visualization data. it is the first table after primary results
    // if we got a visualization table as a second result
    if (queryResults.Tables.length > numberOfPrimaryResults) {
        // it might be a control command without any visualization info and we're just reading the wrong field.
        try {
            // only a single cell with json string
            const visualizationDataString: string = (
                queryResults.Tables[numberOfPrimaryResults].Rows[0] as ValueRow
            )[0] as string;
            const visualizationDataObject = JSON.parse(visualizationDataString);
            return visualizationDataObject as VisualizationOptions;
        } catch {
            // do nothing
        }
    }

    return EmptyVisualizationOptionsSnapshot;
};

export const getVisualizationOptionsV2 = (queryProperties: DataTable[]): VisualizationOptions => {
    if (queryProperties.length > 0) {
        // it might be a control command without any visualization
        try {
            // only a single cell with json string
            const visualizationDataString: string = (queryProperties[0].Rows[0] as ValueRow)[2] as string;
            const visualizationDataObject = JSON.parse(visualizationDataString);
            return visualizationDataObject as VisualizationOptions;
        } catch {
            // do nothing
        }
    }

    return EmptyVisualizationOptionsSnapshot;
};

export const EmptyVisualizationOptionsSnapshot: VisualizationOptions = {
    Accumulate: false,
    IsQuerySorted: false,
    Kind: null,
    Legend: null,
    Series: null,
    Title: null,
    Visualization: null,
    XAxis: null,
    XColumn: null,
    XTitle: null,
    YAxis: null,
    YColumns: null,
    YSplit: null,
    YTitle: null,
    AnomalyColumns: null,
    Ymin: 'NaN',
    Ymax: 'NaN',
};
