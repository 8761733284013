import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import { dependencies } from '../dependencies';
import { getTelemetryClient } from '../utils/telemetryClient';
import { isVolatileWorkspace, mainStorageKey } from './localStorageHelper';
import { RootStore } from './rootStore';
import { Tab } from './tab';
import { SnapshotOut } from 'mobx-state-tree/dist/core/type/type';
import { isSecurityError } from '../common/error';

const { trackTrace, trackException } = getTelemetryClient({
    component: 'SyncBrowserTabs',
    flow: 'sync',
});

interface Props {
    /**
     * This will be used as a trigger for the sync operation.
     * Ideally this is set to true only when the browser tab with the window is focused again.
     * However, if it is set to true after window is already focused, it will just be ignored.
     * */
    hasFocus: boolean;

    /** The rootStore as loaded from storage after app starts. */
    rootStore: RootStore;
}

let intervalId: NodeJS.Timer | undefined;
const interval = 5000; // 5 secs
/**
 * This component only has effects but no UI.
 * The component is responsible for loading the latest data from storage.
 * Unlike, "hydrate" in StatePersistenceHandler, which loads all the rootStore from storage when page loads,
 * this component only loads:
 * 1. Tabs' text and title.
 * 2. Settings.
 * 3. Connections.
 * when page re-focus.
 * motivation: keep instances of KWE on different browser tabs in-sync, so a change in one tab won't override the data
 * in the storage that was set by another browser tab.
 * For example:
 * 1. KWE is opened in 2 browser tabs (T1 and T2) with a KWE tab (t1) that has one command (c1).
 * 2. User adds a new command (c2) in T1.t1.
 * 3. User opens T2 (T2.t1 only shows c1) and changes a any setting.
 * 4. user closes the browser and re-open it. c2 is now deleted.
 * why did it happen?
 * At step 3, T2 didn't have c2 in the rootStore, so when the rootStore got persisted after changing a setting
 * it overrode the rootStore persisted by T1 that included c2.
 * How this component fixes it?
 * Once user opens T2, the latest rootStore is loaded from storage. So now T2.t1 has both c1 and c2.
 */
export const SyncBrowserTabs = (props: Props) => {
    const { rootStore, hasFocus } = props;

    useEffect(() => {
        if (!intervalId) {
            intervalId = setInterval(() => sync(rootStore), interval);
        }

        if (hasFocus) {
            try {
                // wait for 200 ms before syncing.
                // Q: Why the extra wait?
                // A: To solve this edge case:
                // 1. Two browser tabs (T1,T2) are opened and T1 is in focus (both has a KWE tab t1 opened)
                // 2. User double click on the tab, enters edit mode and changes the title of T1.t1.
                // 3. Without leaving edit mode, user changes focus to T2.
                // 4. Bug: Title in T2.t1 is not updated.
                // The reason for the bug is that onFocus in T2 (which loads from storage) happens before onBlur in T1 (which persist the change).
                // The fix: Give 200 ms for T1 to persist the data.
                setTimeout(() => sync(rootStore), 200);
            } catch (e) {
                trackException(e, 'SyncBrowserTabs.sync');
            }
        }
    }, [rootStore, hasFocus]);

    return <></>;
};

const sync = (rootStore: RootStore) => {
    const contextID = v4();
    const trace = (message: string, properties?: { [name: string]: string }) =>
        trackTrace(message, undefined, { contextID, ...properties });
    if (!dependencies.featureFlags.SyncBrowserTabs) {
        trace('SyncBrowserTabs.Feature.Disabled');
        return;
    }

    let snapshotString: string | null = null;
    try {
        snapshotString = localStorage.getItem(mainStorageKey);
    } catch (e) {
        if (isSecurityError(e)) {
            return;
        }
        trackException(e, 'SyncBrowserTabs.sync');
        return;
    }

    if (!snapshotString) {
        trace('SyncBrowserTabs.Fetch.Main.NotFound');
        return undefined;
    }
    const storeSnapshot = JSON.parse(snapshotString) as SnapshotOut<RootStore>;
    if (!storeSnapshot.eTag) {
        trace('SyncBrowserTabs.Never.Persisted');
        return;
    }

    const snapshotTabIds: Set<string> = new Set<string>(storeSnapshot.tabs.tabs.map((tab) => tab.id));
    const rootStoreTabHash: { [id: string]: Tab } = rootStore.tabs.tabs.reduce((hash, tab) => {
        hash[tab.id] = tab;
        return hash;
    }, {} as { [id: string]: Tab });

    const volatileWorkspace: boolean = isVolatileWorkspace(rootStore.settings.emptyWorkspaceOnDeepLinkQuery);
    if (!volatileWorkspace && storeSnapshot.eTag !== rootStore.eTag) {
        rootStore.setStopPersistency(true);
        let countExistingTabs = 0;
        let countNewTabs = 0;
        let countRemovedTabs = 0;
        for (const snapshotTab of storeSnapshot.tabs.tabs) {
            const tab = rootStoreTabHash[snapshotTab.id];
            if (tab) {
                // existing tabs
                if (!tab.dirty) {
                    tab.setText(snapshotTab.text, false);
                    // setText will change tab to dirty, however, after tab sync it should not be dirty.
                    tab.setDirty(false);
                }
                tab.setTitle(snapshotTab.title ?? '');
                countExistingTabs++;
            } else {
                // new tabs
                rootStore.tabs.addTabFromSnapshot(snapshotTab);
                countNewTabs++;
            }
        }

        // deleted tabs
        const obsoleteTabs = rootStore.tabs.tabs.filter((tab) => !snapshotTabIds.has(tab.id));
        for (const tab of obsoleteTabs) {
            rootStore.tabs.removeTab(tab);
            countRemovedTabs++;
        }
        rootStore.setMainETag(storeSnapshot.eTag);
        trace('SyncBrowserTabs.Completed', {
            countExistingTabs: `${countExistingTabs}`,
            countNewTabs: `${countNewTabs}`,
            countRemovedTabs: `${countRemovedTabs}`,
        });
        rootStore.setStopPersistency(false);
    }
};
