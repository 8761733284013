import * as React from 'react';
import { useMemo } from 'react';
import { Breadcrumb, IBreadcrumbItem, IBreadcrumbProps, IRenderFunction, styled } from 'office-ui-fabric-react';
import { IStyleFunctionOrObject, IBreadcrumbStyleProps, IBreadcrumbStyles } from 'office-ui-fabric-react';
import { IsFocusVisibleClassName } from '@uifabric/utilities';

import { useDashboardsBreadcrumbInfo } from '../useDashboardsBreadcrumbInfo';
import { EditableBreadCrumbItem } from './EditableBreadcrumbItem';

export interface RtdBreadcrumbItem extends IBreadcrumbItem {
    Component?: React.ComponentType<RtdBreadcrumbItem>;
    onTextChange?: (text: string) => void;
}

const onRenderItem: IRenderFunction<RtdBreadcrumbItem> = (props, defaultRender) => {
    if (props?.Component) {
        return <props.Component {...props} />;
    }

    if (defaultRender) {
        return defaultRender(props);
    }

    return null;
};

/**
 * 'css' omitted here because standalone was complaining about it being required.
 * It doesn't appear to be required _here_, so it's not clear what's going on.
 */
export type RtdBreadcrumbsBaseProps = Omit<IBreadcrumbProps, 'items' | 'css'>;

const BaseRtdBreadcrumbs: React.FC<RtdBreadcrumbsBaseProps> = (props) => {
    const { history, breadcrumbs } = useDashboardsBreadcrumbInfo();
    const items = useMemo((): RtdBreadcrumbItem[] => {
        const breadcrumbItems: RtdBreadcrumbItem[] = [];
        for (let i = 0; i < breadcrumbs.length; i++) {
            const { key, href, text, onChange } = breadcrumbs[i];
            // Don't render loading breadcrumbs
            if (text === undefined) {
                break;
            }
            breadcrumbItems.push({
                key,
                href,
                isCurrentItem: i + 1 === breadcrumbs.length,
                text: text,
                onTextChange: onChange,
                onClick:
                    href === undefined
                        ? undefined
                        : (event) => {
                              event?.preventDefault();
                              history?.push(href);
                          },
                Component: onChange ? EditableBreadCrumbItem : undefined,
            });
        }
        return breadcrumbItems;
    }, [breadcrumbs, history]);

    return <Breadcrumb items={items} onRenderItem={onRenderItem} {...props} />;
};

const styles: IStyleFunctionOrObject<IBreadcrumbStyleProps, IBreadcrumbStyles> = (props) => {
    const onlyColor = {
        color: props.theme.palette.white,
    };
    const item = {
        color: props.theme.palette.white,
        selectors: {
            ':active': onlyColor,
            ':lastChild': onlyColor,
            ':hover': {
                color: props.theme.palette.white,
                backgroundColor: props.theme.palette.themeDarkAlt,
            },
            ':active:hover': {
                color: props.theme.palette.white,
                backgroundColor: props.theme.palette.themeDarkAlt,
            },
            ':focus': onlyColor,
            '&:last-child .ms-Breadcrumb-itemLink': onlyColor,
            '&:last-child .ms-Breadcrumb-item': onlyColor,
        },
    };
    return {
        root: {
            margin: 0,
            fontSize: 16,
        },
        item,
        itemLink: [
            {
                [`.${IsFocusVisibleClassName} &:focus:after`]: {
                    outline: 'none',
                },
                [`.${IsFocusVisibleClassName} &:focus`]: {
                    boxShadow: 'none',
                },
            },
            item,
        ],
        listItem: item,
        chevron: { color: props.theme.palette.white },
        overflowButton: {
            marginLeft: 2,
            height: 28,
            color: props.theme.palette.white,
            selectors: { ':hover': { color: props.theme.palette.themePrimary } },
        },
    };
};

export const RtdBreadcrumbs = styled<RtdBreadcrumbsBaseProps, IBreadcrumbStyleProps, IBreadcrumbStyles>(
    BaseRtdBreadcrumbs,
    styles
);
