/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { types, flow, getRoot, getSnapshot } from 'mobx-state-tree';
import * as _ from 'lodash';
import { v4 } from 'uuid';
import { FetchState } from './common';
import {
    KustoResult,
    KustoResultV2,
    ExecutionResult,
    ApiVersion,
    CancellationTokenSource,
    isCancelledError,
    extractErrorDescriptionAndTrace,
    buildErrorString,
    formatLiterals,
    unifyWhiteSpaces,
} from '@kusto/common';
import { kustoResultV1ToResultTable, kustoResultV2ToResultTable } from '@kusto/visualizations';
import { KustoClient } from '../utils/kustoClient';
import { RootStore } from './rootStore';
import { RequestInfo, QueryCompletionInfo, QueryResults, MultiTableResult } from './queryCompletionInfo';
import { Cluster, Database, getClusterAndDatabaseFromEntity } from './cluster';
import { parseStringLiteral, resolveKustoConnection, KustoConnection } from '../utils/platform';
import { handleDeepLink } from '../stores/deepLinkHandler';
import { dependencies } from '../dependencies';
import { parse } from 'url';
import { parseDeepLink } from '../utils/deepLinkParser';
import { getTelemetryClient } from '../utils/telemetryClient';
import { ResultCache } from './resultCache';
import { persistMain } from './statePersistenceHandler';
import { PasteLocation } from '../common/types';
import { pythonDebugResult } from '../utils/pythonDebugHelper';
import { ClusterOrDatabaseSafeReference } from './connectionPane';
import { isEmpty } from 'lodash';

/**
 * translate raw results from raw Kusto json to store model.
 * @param rawQueryResults raw query results from kusto endpoint.
 */
const toQueryCompletionInfo = (
    request: RequestInfo,
    rawQueryResults: KustoResult,
    clientRequestId: string
): {
    queryCompletionInfo: QueryCompletionInfo;
    queryResults: QueryResults;
} => {
    const results = kustoResultV1ToResultTable(rawQueryResults);

    const queryCompletionInfo = QueryCompletionInfo.create({
        id: request.hashCode,
        failureReason: undefined,
        isSuccess: true,
        timeEnded: new Date(),
        request,
        clientActivityId: clientRequestId,
    });

    const queryResults = QueryResults.create({ results });

    return { queryCompletionInfo, queryResults };
};

/**
 * translate raw results from raw Kusto json to store model.
 * @param rawQueryResults raw query results from kusto endpoint.
 */
// TODO: this logic should be in the kusto client, otherwise,
// any part running queries won't parse them properly for the grid
const toQueryCompletionInfoV2 = (
    request: RequestInfo,
    rawQueryResults: KustoResultV2,
    clientRequestId: string
): {
    queryCompletionInfo: QueryCompletionInfo;
    queryResults: QueryResults;
} => {
    const { results, errorDescription, queryResourceConsumption } = kustoResultV2ToResultTable(rawQueryResults);
    const queryCompletionInfo = QueryCompletionInfo.create({
        id: request.hashCode,
        failureReason: undefined,
        errorDescription: errorDescription,
        isSuccess: !errorDescription,
        timeEnded: new Date(),
        request,
        clientActivityId: clientRequestId,
        queryResourceConsumption,
    });

    const queryResults = QueryResults.create({ results });

    return { queryCompletionInfo, queryResults };
};

// eslint-disable-next-line @typescript-eslint/ban-types
const getEntityInContext = (node: {}) => (getRoot(node) as RootStore).connectionPane.entityInContext;
// eslint-disable-next-line @typescript-eslint/ban-types
const getConnectionInContext = (node: {}): Cluster | undefined => {
    const root = getRoot(node) as RootStore;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const entityInContext = root.connectionPane!.entityInContext;
    if (!entityInContext) {
        return undefined;
    }

    return getClusterAndDatabaseFromEntity(entityInContext).cluster;
};

export type RunRequestedType = 'ReadOnly' | 'Yes' | 'No';

export const CommandType = types.enumeration('CommandType', ['Unknown', 'AdminCommand', 'Query', 'ClientDirective']);
// eslint-disable-next-line no-redeclare
export type CommandType = typeof CommandType.Type;

const { trackEvent } = getTelemetryClient({ component: 'tab', flow: '' });
const recallTracer = getTelemetryClient({ component: 'tab', flow: 'recall' });
const runTracer = getTelemetryClient({ component: 'tab', flow: 'run' });
const cancelTracer = getTelemetryClient({ component: 'tab', flow: 'cancel' });
const searchTracer = getTelemetryClient({ component: 'tab', flow: 'search' });

/**
 * A Tab contains both an editor panel to edit text and a response panel to display either graphical or tabular results.
 * It also hold a context - which is mainly the cluster/database it is bound to.
 */
export const Tab = types
    .model('Tab', {
        id: types.identifier,
        title: types.maybe(types.string),
        text: types.optional(types.string, ''),
        commandInContext: types.maybeNull(types.string),
        executionStatus: types.optional(FetchState, 'notStarted'),
        clientRequestId: types.maybeNull(types.string),
        completionInfo: types.maybe(types.safeReference(QueryCompletionInfo)),
        entityInContext: types.maybeNull(ClusterOrDatabaseSafeReference),
        commandType: types.optional(CommandType, 'Unknown'),
        hideEmptyColumns: types.optional(types.boolean, false),
    })
    .views((self) => ({
        get currentCommandHash() {
            const request = createRequestInfo(self as Tab);
            return request.hashCode;
        },
        get errorMessage() {
            if (!self.completionInfo) {
                return 'Unknown Error';
            }

            let message = self.completionInfo.errorDescription
                ? buildErrorString(self.completionInfo.errorDescription)
                : undefined;

            // before there was errorDescription there was failureReason. maybe it's an old execution result...
            if (!message) {
                message = self.completionInfo.failureReason || '';
            }

            return message + `\n\n clientRequestId: ${self.completionInfo.clientActivityId}`;
        },
        get calcTitle(): string {
            if (self.title) {
                return self.title;
            }

            if (self.entityInContext) {
                const connection = getClusterAndDatabaseFromEntity(self.entityInContext).cluster;
                if (connection) {
                    const databaseName =
                        self.entityInContext.entityType === 'Database'
                            ? '.' + (self.entityInContext as Database).name
                            : '';

                    let clusterNameOrAlias = connection.alias;
                    if (isEmpty(clusterNameOrAlias)) {
                        clusterNameOrAlias = KustoConnection.fromConnectionString(connection.connectionString)!.cluster;
                    }
                    return clusterNameOrAlias + databaseName;
                }
            }
            return dependencies.strings.newTab;
        },
        getQueryInContextInfo() {
            const entityInContext = self.entityInContext;
            const currentConnection = entityInContext
                ? getClusterAndDatabaseFromEntity(entityInContext).cluster
                : undefined;

            return {
                url: currentConnection ? currentConnection.clusterUrl : undefined,
                dbname:
                    entityInContext && entityInContext.entityType === 'Database'
                        ? (entityInContext as Database).name
                        : undefined,
                query: self.commandInContext,
            };
        },
    }))
    .volatile((_self) => {
        return {
            isFetching: false,
            cancellationTokenSource: undefined as CancellationTokenSource | undefined,
            fetchStartTime: new Date().getTime(),
            fetchEndtime: new Date().getTime(),
            runRequest: 'No' as RunRequestedType,
            isRecallRequested: false,
            textToPaste: '',
            pasteLocation: 'cursorPosition' as PasteLocation,
            canvasToPaste: undefined as HTMLCanvasElement | undefined,
            csvExportRequested: false,
            results: undefined as QueryResults | undefined,
            renderingException: undefined as Error | undefined,
            searchEnabled: 0, // set to a number to allow refocusing when search already enabled.
            dirty: false,
            saveError: undefined as string | undefined,
        };
    })
    .actions((self) => ({
        setHideEmptyColumns(value: boolean) {
            self.hideEmptyColumns = value;
            trackEvent('HideEmptyColumn', { enabled: `${value}` });
        },
        setResults(results: QueryResults | undefined, executionStatus: FetchState) {
            self.executionStatus = executionStatus;
            self.results = results;
            self.renderingException = undefined;
        },
    }))
    .views((self) => ({
        get isResultDisplayed() {
            return (
                !self.isFetching &&
                (self.executionStatus === 'done' ||
                    self.executionStatus === 'gotFromCache' ||
                    self.executionStatus === 'failed') &&
                self.completionInfo &&
                self.results &&
                self.results.results &&
                self.results.results.length > 0
            );
        },
    }))
    .views((self) => ({
        get isChartDisplayed() {
            return (
                self.isResultDisplayed &&
                self.results &&
                self.completionInfo &&
                self.completionInfo.resultIndex < self.results.resultsToDisplay.length &&
                self.results.resultsToDisplay[self.completionInfo.resultIndex].isChart
            );
        },
        get resultRowCount() {
            if (
                !self.isResultDisplayed ||
                !self.completionInfo ||
                !self.results ||
                self.completionInfo.resultIndex >= self.results.resultsToDisplay.length
            ) {
                return undefined;
            }

            const index = self.completionInfo.resultIndex;
            const rows = self.results.resultsToDisplay[index].rows;

            if (!rows) {
                return undefined;
            }
            return rows.length;
        },
        get resultsCount() {
            return self.isResultDisplayed && self.results ? self.results.results.length : undefined;
        },
    }))
    .actions((self) => ({
        setIsFetching(isFetching: boolean) {
            if (self.isFetching === isFetching) {
                return;
            }

            self.isFetching = isFetching;
            if (isFetching) {
                self.fetchStartTime = new Date().getTime();
            } else {
                self.fetchEndtime = new Date().getTime();
            }
        },
        setCanvasToPaste(canvasToPaste: HTMLCanvasElement) {
            self.canvasToPaste = canvasToPaste;
        },
        setTitle(title: string) {
            self.title = title;
        },
    }))
    .actions((self) => {
        const kustoClient = new KustoClient(dependencies.authProvider, () => {
            if (self.executionStatus !== 'canceled') self.setIsFetching(true);
        });
        return {
            setEntityInContext: (entity: Cluster | Database | null) => {
                self.entityInContext = entity;
            },
            setDirty: (dirty: boolean) => {
                self.dirty = dirty;
            },
            setSaveError: (error: string | undefined) => {
                self.saveError = error;
            },
            setText: (text: string, shouldUnifyWhiteSpaces = true) => {
                const cleanText = shouldUnifyWhiteSpaces ? unifyWhiteSpaces(text) : text;
                if (self.text !== cleanText) {
                    self.text = cleanText;
                    self.dirty = true;
                }
            },
            setCommandInContext: (command: string, commandType: CommandType) => {
                self.commandInContext = command;
                self.commandType = commandType;
            },
            recall: flow(function* recall(flowType = 'recall') {
                recallTracer.trackEvent('Start ' + flowType);
                const rootStore = getRoot(self);
                // once introducing yield, started getting a circular type reference here when using RootStore.
                // so we have to remove type safely here (hence the any)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const completionInfo = ((rootStore as any).resultCache! as ResultCache).executions.get(
                    self.currentCommandHash.toString()
                );

                if (completionInfo === undefined || !completionInfo.haveCachedResults) {
                    self.isRecallRequested = false;
                    return;
                }

                recallTracer.trackEvent(flowType, {
                    clientActivityId: completionInfo ? completionInfo.clientActivityId : '',
                });

                const results = yield dependencies.queryResultStore.getResultsAsync(completionInfo.id);
                if (completionInfo && results) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    self.completionInfo = completionInfo;
                    self.setResults(results, 'gotFromCache');
                }
                recallTracer.trackEvent(flowType + ' ends', {
                    clientActivityId: completionInfo ? completionInfo.clientActivityId : '',
                    result: completionInfo && results ? 'ok' : 'notSet',
                });
                self.isRecallRequested = false;
            }),
            requestRun: (value: RunRequestedType) => {
                self.runRequest = value;
            },
            requestRecall: () => {
                self.isRecallRequested = true;
            },
            requestPaste: (text: string, pasteLocation?: PasteLocation) => {
                self.pasteLocation = pasteLocation || 'cursorPosition';
                self.textToPaste = unifyWhiteSpaces(text);
            },
            requestCsvExport: (val: boolean) => {
                self.csvExportRequested = val;
            },
            setRenderingException: (error: Error | undefined) => {
                self.renderingException = error;
            },
            run: flow(function* run() {
                runTracer.trackEvent('Start');
                self.executionStatus = 'notStarted';

                const rootStore: RootStore = getRoot(self) as RootStore;
                if (self.commandType === 'ClientDirective') {
                    const command = self.commandInContext!.trim().substring(1);
                    const indexOfFirstSpace = command.indexOf(' ');
                    const directive = command.substring(0, indexOfFirstSpace);
                    const directiveArg = command.substring(indexOfFirstSpace + 1);
                    switch (directive) {
                        case 'connect':
                            if (!dependencies.featureFlags.ShowConnectionButtons) {
                                throw new Error(dependencies.strings.cannotConnectClusters);
                            }
                            runTracer.trackEvent('Directive.Connect');
                            const argsTrimmed = directiveArg.trim();
                            const { isSuccess, literal } = parseStringLiteral(argsTrimmed);
                            const connectArg = isSuccess ? literal : argsTrimmed;
                            const root: RootStore = getRoot(self) as RootStore;
                            const resolvedConnection = resolveKustoConnection(
                                connectArg,
                                root.connectionPane.aliasesToNameMapping()
                            );
                            if (resolvedConnection) {
                                handleDeepLink(
                                    root.connectionPane!,
                                    root.tabs,
                                    parseDeepLink(
                                        parse(
                                            `https://contoso.com/${resolvedConnection.cluster}/${
                                                resolvedConnection.database ? resolvedConnection.database : ''
                                            }`
                                        )
                                    ),
                                    true,
                                    false
                                );
                            }
                            self.runRequest = 'No';
                            return;
                        default:
                            self.runRequest = 'No';
                            throw new Error(formatLiterals(dependencies.strings.unknownDirective, { directive }));
                    }
                }
                // TODO: a lot of the code below this part should go into KustoClient
                const request = createRequestInfo(self as Tab);
                self.clientRequestId = `KustoWebV2;${v4()}`;
                try {
                    if (
                        self.commandType === 'Query' &&
                        (!self.entityInContext || self.entityInContext.entityType !== 'Database')
                    ) {
                        throw new Error(dependencies.strings.selectDatabase);
                    }

                    const root = getRoot(self) as {
                        settings: {
                            apiV2: boolean;
                            timeoutInMinutes: number;
                            weakConsistency: boolean;
                        };
                    };
                    const apiVersion = root.settings.apiV2 ? 'v2' : 'v1';
                    const commandToExecute = (self.commandInContext && self.commandInContext.trim()) || self.text;
                    const isControlCommand = commandToExecute.trim().startsWith('.');
                    const isSql = /^\s*(--|select\b|explain\b)/i.test(commandToExecute.trim());
                    const timeoutInMinutes = root.settings.timeoutInMinutes;
                    const timeoutAsTimestampString =
                        timeoutInMinutes === 60
                            ? '01:00:00'
                            : `00:${_.padStart(timeoutInMinutes.toString(), 2, '0')}:00`;
                    const queryConsistency = root.settings.weakConsistency ? 'weakconsistency' : 'strongconsistency';
                    self.cancellationTokenSource = kustoClient.createCancelToken();
                    const runAsReadOnly = self.runRequest === 'ReadOnly';
                    const executionResult: ExecutionResult<ApiVersion> = yield kustoClient.execute(
                        request.url,
                        request.dbName,
                        commandToExecute,
                        !isControlCommand,
                        isControlCommand ? 'v1' : apiVersion,
                        {
                            Options: {
                                query_language: isSql ? 'sql' : 'csl',
                                servertimeout: timeoutAsTimestampString,
                                queryconsistency: queryConsistency,
                                request_readonly: runAsReadOnly,
                                request_readonly_hardline: runAsReadOnly,
                            },
                        },
                        undefined,
                        self.cancellationTokenSource.token,
                        undefined,
                        { clientRequestId: self.clientRequestId }
                    );

                    // We don't want to measure time from when the user requested execution - since it may involve
                    // acquiring an auth token (which is unfair to count as query execution time).
                    // during token acquisition time we're showing 'preparing to run' indication to the user.
                    // Once we get the actual time we started the http request, we'll update the relevant field in the
                    // request properties.
                    request.setTimeStarted(executionResult.httpRequestStartTime);

                    // query might have been cancelled in the meanwhile
                    if (!self.isFetching) {
                        return;
                    }

                    const isV1Response = (arg: KustoResult | KustoResultV2): arg is KustoResult => {
                        return !Array.isArray(arg) && 'Tables' in arg;
                    };

                    // call the right method to parse the results according to api v1 or v2.
                    let completionInfo: {
                        queryCompletionInfo: QueryCompletionInfo;
                        queryResults: QueryResults;
                    } | null = null;
                    if (isV1Response(executionResult.apiCallResult)) {
                        completionInfo = toQueryCompletionInfo(
                            request,
                            executionResult.apiCallResult,
                            self.clientRequestId
                        );
                    } else {
                        completionInfo = toQueryCompletionInfoV2(
                            request,
                            executionResult.apiCallResult as KustoResultV2,
                            self.clientRequestId
                        );
                    }

                    rootStore.resultCache!.put(completionInfo.queryCompletionInfo);
                    try {
                        yield dependencies.queryResultStore.setResultsAsync(
                            completionInfo.queryCompletionInfo.id!,
                            completionInfo.queryResults
                        );
                    } catch (e) {
                        // Query result ot big for to store locally
                        completionInfo.queryCompletionInfo.noCachedResults();
                        // maybe should send tracking ?!
                    }

                    // Bug fix: this assignment caused issues in case the same query was executed twice.
                    if (
                        !self.completionInfo ||
                        !self.completionInfo.id ||
                        self.completionInfo.id !== completionInfo.queryCompletionInfo.id
                    ) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (self.completionInfo as any) = completionInfo.queryCompletionInfo.id;
                    }

                    // Originally pruneOne was called as part of resultCache.put
                    // (which is invoked above), but this caused a subtle bug:
                    // only at this point do we know that the new execution is being referenced by the current tab
                    // since the previous statement sets the reference.
                    // Thus only now we can safely prune without deleting the current execution result from the
                    // cache by mistake.
                    // switching the order (i.e setting the self.completion info before resultCache.put) would have
                    // solved the issue, but would raise a another one : self.completionInfo is a _reference_ and
                    // we cannot set it until we have an item in the resultCache to refer to.
                    rootStore.resultCache!.pruneOne();

                    self.setResults(
                        completionInfo.queryResults,
                        completionInfo.queryCompletionInfo.isSuccess ? 'done' : 'failed'
                    );

                    self.setIsFetching(false);
                    self.runRequest = 'No';
                    const entityInContext = getEntityInContext(self);
                    // Refresh entity after control commands that may modify it.
                    if (entityInContext && isControlCommand && !commandToExecute.trim().startsWith('.show')) {
                        // If we created a database from the context of another database, we need to refresh the entire
                        // cluster (since the database list had changed and not only the current entity).
                        const shouldRefreshCluster = !!commandToExecute.trim().match(/(\.create|\.drop).*database.*/);
                        entityInContext.fetchCurrentSchema(true, shouldRefreshCluster);
                    }

                    if (completionInfo.queryCompletionInfo.isSuccess) {
                        pythonDebugResult(completionInfo.queryResults);
                    }
                } catch (exception) {
                    // query might have been cancelled in the meanwhile.
                    if (isCancelledError(exception)) {
                        return;
                    }

                    const errorDescription = extractErrorDescriptionAndTrace(exception);
                    const completionInfo: QueryCompletionInfo = QueryCompletionInfo.create({
                        id: request.hashCode,
                        failureReason: errorDescription.errorMessage,
                        errorDescription: errorDescription,
                        isSuccess: false,
                        timeEnded: new Date(),
                        request,
                        clientActivityId: self.clientRequestId,
                    });

                    rootStore.resultCache!.put(completionInfo);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (self.completionInfo as any) = completionInfo.id;
                    self.setResults(undefined, 'failed');
                    self.setIsFetching(false);
                    self.runRequest = 'No';
                } finally {
                    self.cancellationTokenSource = undefined;
                    // Since Ibiza is a SPA we don't get the beforeunload event and we don't know to persist the state.
                    // whenever a user clicks on another menu and navigates away from the query experience.
                    // Until this is fixed in some longer term solution we
                    // can at least trigger persistence whenever an important event happens
                    // (such as returning query results)
                    // In our external website this isn't a problem since we _do_ get the unload event.
                    if (dependencies.featureFlags.PersistAfterEachRun) {
                        persistMain(getSnapshot(getRoot(self)), 'IbizaPersistAfterRun');
                    }
                }
            }),
            cancel() {
                cancelTracer.trackEvent('Start', {
                    clientActivityId: self.clientRequestId ? self.clientRequestId : '',
                });
                const connectionInContext = getConnectionInContext(self);
                if (!connectionInContext) {
                    return;
                }
                const url: string = connectionInContext.clusterUrl;

                // We may have requested run but didn't actually issue the request yet.
                // In that case we don't have a client id yet.
                if (!self.clientRequestId && self.isFetching) {
                    throw new Error(dependencies.strings.clientRequestIdNotNull);
                }

                // We issue a cancel query only if request was already sent.
                // Otherwise we're just preparing for execution (getting current command, ETC) so nothing to cancel.
                if (self.clientRequestId && self.isFetching) {
                    kustoClient
                        .cancelQuery(url, self.clientRequestId, 15000)
                        .then(() => cancelTracer.trackEvent('cancelRequestSucceed'))
                        .catch((e) => cancelTracer.trackException(e, 'tab.cancel', { domain: new URL(url).hostname }));
                }

                // Query might have returned and finished before cancellation.
                if (!self.isFetching) {
                    return;
                }

                self.setResults(undefined, 'canceled');
                self.setIsFetching(false);
                self.runRequest = 'No';
                self.cancellationTokenSource?.cancel();
            },
        };
    })
    .actions((self) => ({
        // Reload the last query in context results
        afterAttach() {
            self.recall();
        },
    }))
    .volatile((_self) => ({
        // Store grid state for the latest results in context
        // ignore the state (and reset it) if related to different result set
        gridState: {
            forResults: undefined as QueryResults | undefined,
            // Keep state of each result table in the query
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            states: [] as any[],
        },
    }))
    .views((self) => ({
        getVisualState<T>(): T | undefined {
            const { completionInfo, gridState, results } = self;

            if (!completionInfo || !results || !results.resultsToDisplay[completionInfo.resultIndex]) {
                return;
            }
            if (gridState.forResults === results) {
                const state = gridState.states[completionInfo.resultIndex];
                if (state) {
                    return state as T;
                }
            }
            return undefined;
        },
        get isTableShown(): boolean {
            const {
                resultRowCount,
                isFetching,
                runRequest,
                isChartDisplayed,
                executionStatus,
                completionInfo,
                results,
            } = self;
            if (!resultRowCount) return false;

            const isQueryRunning = isFetching || runRequest === 'Yes';

            const { resultIndex, queryResourceConsumption: stats } = completionInfo!;
            const showingStats = stats && resultIndex >= results!.resultsToDisplay.length;

            return (
                (dependencies.featureFlags.QueryResultsSearch ?? false) &&
                !isQueryRunning &&
                resultRowCount > 0 &&
                !isChartDisplayed &&
                !showingStats &&
                (executionStatus === 'done' || executionStatus === 'gotFromCache')
            );
        },
    }))
    .actions((self) => ({
        enableSearch: (enabled: boolean) => {
            // do not enable (but allow disabling) when search in not supported.
            if (!self.isTableShown && self.searchEnabled === 0 && enabled) {
                searchTracer.trackEvent('enablingSearchWhenSearchNotSupported');
                return;
            }

            self.searchEnabled = enabled ? self.searchEnabled + 1 : 0;
        },
        setVisualState<T>(state: T, forResult: MultiTableResult) {
            // setting the visual state might happened after tab data change - like running new query
            if (
                !self.results ||
                self.results !== self.gridState.forResults ||
                !self.results.results.find((result) => result.rows === forResult.rows)
            ) {
                self.gridState = {
                    forResults: self.results,
                    states: [],
                };
            }
            self.gridState.states[forResult.queryIndex] = state;
        },
    }));

// eslint-disable-next-line no-redeclare
export type Tab = typeof Tab.Type;

export interface QueryContextInfo {
    url?: string;
    dbname?: string;
    query?: string | null;
}

/**
 * Create a request info object.
 * @param timeStarted if provided will inhabit the timeStarted property of RequestInfo. otherwise it will be now.
 */
function createRequestInfo(tab: Tab, timeStarted?: Date): RequestInfo {
    timeStarted = timeStarted || new Date();

    const { url, dbname, query } = tab.getQueryInContextInfo();

    return RequestInfo.create({
        url: url || '',
        dbName: dbname || 'N/A',
        queryText: query,
        timeStarted,
    });
}
