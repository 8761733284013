import { IBreadcrumbItem } from 'office-ui-fabric-react/lib/components/Breadcrumb';
import * as React from 'react';
import { TooltipHost, TooltipOverflowMode, ITooltipHostStyles } from 'office-ui-fabric-react/lib/components/Tooltip';
import { Stack } from 'office-ui-fabric-react/lib/components/Stack';
import { Icon } from 'office-ui-fabric-react/lib/components/Icon';
import { getRTL, IRenderFunction } from 'office-ui-fabric-react/lib/Utilities';
import { CommandBarButton, IButtonStyles } from 'office-ui-fabric-react/lib/components/Button';

import styles from './StaticBreadcrumb.module.scss';

export interface IStaticBreadcrumbItem extends IBreadcrumbItem {
    onRenderItem?: IRenderFunction<IStaticBreadcrumbItem>;
}

export interface IStaticBreadcrumbProps {
    items: IStaticBreadcrumbItem[];
    className?: string;
    farItems?: React.ReactNode;
}

const buttonStyle: IButtonStyles = {
    root: {
        backgroundColor: 'transparent',
        height: '100%',
        fontSize: '20px',
        fontWeight: 600,
        padding: 0,
    },
};

export const StaticBreadcrumb: React.FunctionComponent<IStaticBreadcrumbProps> = ({ items, className, farItems }) => {
    const lastItemIndex = items.length - 1;

    return (
        <div className={className ? `${styles.staticBreadcrumb} ${className}` : styles.staticBreadcrumb}>
            {items.map((item, index) => (
                <div key={item.key} className={index === lastItemIndex ? `${styles.last}` : styles.grid}>
                    {renderBreadcrumbItem(item)}
                    {index !== lastItemIndex && (
                        <Stack className={styles.divider} verticalAlign="center">
                            <Icon iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
                        </Stack>
                    )}
                </div>
            ))}
            {farItems}
        </div>
    );
};

const tooltipStyles: ITooltipHostStyles = {
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

// Roughly based on https://github.com/OfficeDev/office-ui-fabric-react/blob/master/packages/office-ui-fabric-react/src/components/Breadcrumb/Breadcrumb.base.tsx
const renderBreadcrumbItem = (item: IStaticBreadcrumbItem) => {
    if (item.onClick || item.href) {
        const onClick =
            item.onClick &&
            ((event: React.MouseEvent<HTMLButtonElement>) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                item.onClick!(event, item));

        return (
            <CommandBarButton
                href={item.href}
                aria-current={item.isCurrentItem ? 'page' : undefined}
                onClick={onClick}
                // Fixes #23944805: When running RTD locally, we get a different experience than the deployed version related to css changes.
                // In this case use styles over ClassName because once built, the javascript css takes precedence over the static css that we created for components
                styles={buttonStyle}
            >
                <TooltipHost content={item.text} overflowMode={TooltipOverflowMode.Parent} styles={tooltipStyles}>
                    {item.onRenderItem ? item.onRenderItem(item) : item.text}
                </TooltipHost>
            </CommandBarButton>
        );
    } else {
        return (
            <Stack className={styles.textItem} verticalAlign="center">
                <TooltipHost content={item.text} overflowMode={TooltipOverflowMode.Parent} styles={tooltipStyles}>
                    {item.onRenderItem ? item.onRenderItem(item) : item.text}
                </TooltipHost>
            </Stack>
        );
    }
};
