// TODO duplicated in monaco-kusto . export it there and consume it here.
export const dotnetTypeToKustoType: { [dotNetType: string]: string } = {
    'System.SByte': 'bool',
    'System.Byte': 'uint8',
    'System.Int16': 'int16',
    'System.UInt16': 'uint16',
    'System.Int32': 'int',
    'System.UInt32': 'uint',
    'System.Int64': 'long',
    'System.UInt64': 'ulong',
    'System.String': 'string',
    'System.Single': 'float',
    'System.Double': 'real',
    'System.DateTime': 'datetime',
    'System.TimeSpan': 'timespan',
    'System.Guid': 'guid',
    'System.Boolean': 'bool',
    'Newtonsoft.Json.Linq.JArray': 'dynamic',
    'Newtonsoft.Json.Linq.JObject': 'dynamic',
    'Newtonsoft.Json.Linq.JToken': 'dynamic',
    'System.Object': 'dynamic',
    'System.Data.SqlTypes.SqlDecimal': 'decimal',
};

/**
 * api V1 to api V2 type conversion
 */
export const v1TypeToKustoType: { [vType: string]: string } = {
    SByte: 'bool',
    Byte: 'uint8',
    Int16: 'int16',
    UInt16: 'uint16',
    Int32: 'int',
    UInt32: 'uint',
    Int64: 'long',
    UInt64: 'ulong',
    String: 'string',
    Single: 'float',
    Double: 'real',
    DateTime: 'datetime',
    TimeSpan: 'timespan',
    Guid: 'guid',
    Boolean: 'bool',
    JArray: 'dynamic',
    JObject: 'dynamic',
    JToken: 'dynamic',
    Object: 'dynamic',
};

export class KustoDomains {
    public static DefaultDomainPostfix = '';
    public static KustoSubDomain = 'kusto';
    public static get Default() {
        return '.' + KustoDomains.KustoSubDomain + '.' + KustoDomains.DefaultDomainPostfix;
    }

    private static AriaCluster = 'kusto.aria.microsoft.com';
    private static SynapseDomain = process.env.REACT_APP_SYNAPSE_POOL_POSTFIX || '';
    public static LocalDevDomain = 'localhost';

    private static KustoAdditionalSubDomains: RegExp[] = [];
    public static configure = (config: { subDomains: string[]; defaultDomainPostfix: string }) => {
        KustoDomains.KustoAdditionalSubDomains = config.subDomains.map((regexp) => new RegExp(regexp + '$', 'i'));
        KustoDomains.DefaultDomainPostfix = config.defaultDomainPostfix;
    };

    private static AriaClusterRegex = new RegExp('/?' + KustoDomains.AriaCluster + '/?$', 'i');
    private static PlayFabSubDomainRegex = new RegExp('(.+)\\.playfab(api)?\\.com$', 'i');
    private static AppInsightsSubDomainRegex = new RegExp('ade\\.applicationinsights\\.io$', 'i');
    private static LogAnalyticsSubDomainRegex = new RegExp('ade\\.loganalytics\\.io$', 'i');
    private static IdentityDiagnosticsPPESubDomainRegex = new RegExp('dxp-dev.aad.azure.com$', 'i');
    private static IdentityDiagnosticsSubDomainRegex = new RegExp('dxp.aad.azure.com$', 'i');
    private static LocalDevDomainRegex = new RegExp('/?' + KustoDomains.LocalDevDomain + '/?$', 'i');
    private static synpaseDomainRegex = new RegExp('kusto.azuresynapse.net$', 'i');
    private static synpaseDevDomainRegex = new RegExp('kustodev.azuresynapse-dogfood.net$', 'i');
    private static kustoDevDomainRegex = new RegExp('kustodev.windows.net$', 'i');

    private static get SubDomainRegex() {
        return new RegExp(
            '(https?://)?(.+)\\.' +
                KustoDomains.KustoSubDomain +
                '(mfa)?\\.' +
                KustoDomains.getSubDomainPostifxesRegex() +
                '/?$',
            'i'
        );
    }

    private static getSubDomainPostifxesRegex() {
        const postfixes = [KustoDomains.DefaultDomainPostfix, KustoDomains.SynapseDomain].map((postfix) =>
            postfix.replace(/\./g, '\\.')
        );
        return `(${postfixes.join('|')})`;
    }

    private static proxiesWithoutParameterSupport = [
        KustoDomains.PlayFabSubDomainRegex,
        KustoDomains.AppInsightsSubDomainRegex,
        KustoDomains.LogAnalyticsSubDomainRegex,
        KustoDomains.IdentityDiagnosticsPPESubDomainRegex,
        KustoDomains.IdentityDiagnosticsSubDomainRegex,
        KustoDomains.synpaseDomainRegex,
        KustoDomains.synpaseDevDomainRegex,
        KustoDomains.kustoDevDomainRegex,
    ];

    private static proxiesWithParameterSupport = [KustoDomains.AriaClusterRegex];

    private static proxyRegexes = [
        ...KustoDomains.proxiesWithoutParameterSupport,
        ...KustoDomains.proxiesWithParameterSupport,
    ];

    public static isKustoSubDomain(domain: string) {
        const notInKustoAdditionalSubDomains = this.KustoAdditionalSubDomains.every((regexp) => !regexp.test(domain));
        return (
            this.AriaClusterRegex.test(domain) ||
            this.PlayFabSubDomainRegex.test(domain) ||
            this.AppInsightsSubDomainRegex.test(domain) ||
            this.LogAnalyticsSubDomainRegex.test(domain) ||
            this.IdentityDiagnosticsPPESubDomainRegex.test(domain) ||
            this.IdentityDiagnosticsSubDomainRegex.test(domain) ||
            this.SubDomainRegex.test(domain) ||
            this.LocalDevDomainRegex.test(domain) ||
            this.synpaseDomainRegex.test(domain) ||
            this.synpaseDevDomainRegex.test(domain) ||
            this.kustoDevDomainRegex.test(domain) ||
            !notInKustoAdditionalSubDomains
        );
    }

    /**
     * @returns true if this domain belongs to a known kusto proxy.
     * @param domain the url
     */
    public static isKustoProxySubDomain(domain: string) {
        return KustoDomains.proxyRegexes.some((regex) => regex.test(domain));
    }

    /**
     * @returns true if parameters are NOT supported.
     * @param domain the url
     */
    public static isParametersNotSupported(domain: string) {
        return KustoDomains.proxiesWithoutParameterSupport.some((regex) => regex.test(domain));
    }

    public static getAlias(domain: string) {
        return this.AriaClusterRegex.test(domain)
            ? this.AriaCluster
            : domain.replace(this.SubDomainRegex, (_full, _protocol, alias) => alias);
    }

    public static isAriaDomain(domain: string) {
        return this.AriaClusterRegex.test(domain);
    }

    public static isLocalDevDomain(domain: string) {
        return this.LocalDevDomainRegex.test(domain);
    }
}

/**
 * returns the cluster name and url from user input that tries to identify a cluster
 * Note: Cluster name is the name shown before `.kusto.windows.net`, alias is a display name user gives to a cluster.
 * @param userInput user input meant to identify a cluster. supported formats are
 * kuskus
 * kuskus.eastus
 * kuskus.kusto.windows.net
 * kusto.aria.microsoft.com
 * https://kuskus.kusto.windows.net
 */
export const getClusterConnectionStringAndName = (
    userInput: string
): {
    clusterName: string;
    connectionString?: string;
    initialCatalog?: string;
} => {
    userInput = userInput.trim();

    let url: URL | undefined = undefined;
    let clusterName: string;
    let connectionString: string;

    const decodedInput = decodeURIComponent(userInput);
    const isInputEncoded = decodedInput !== userInput;

    // if this is a proper url
    try {
        url = new URL(decodedInput);
    } catch {
        // do nothing
    }

    try {
        let withInitialCatalog = false;
        if (!url) {
            const uri = encodeURI(
                'https://' +
                    (isInputEncoded || KustoDomains.isKustoSubDomain(userInput) || decodedInput.indexOf('/') > 0
                        ? decodedInput
                        : userInput + KustoDomains.Default)
            );
            url = new URL(uri);

            // Add initial catalog support only to Aria clusters (authorization issue)
            withInitialCatalog =
                KustoDomains.isAriaDomain(url.host) &&
                !!url.pathname &&
                url.pathname !== '/' &&
                url.pathname.length > 0;
        }

        // Validate that we're sending a token to an allowed domain.
        if (!KustoDomains.isKustoSubDomain(url.hostname)) {
            throw new Error(`${url.hostname} is Not an ADX domain`);
        }

        connectionString = withInitialCatalog ? url.origin : url.toString() + ';fed=true';
        clusterName = encodeURIComponent(
            // First decode url to avoid double encoding then encodeURIComponent
            // * URL to string return string which is uri encoded
            // * alias need to be uri component encoded (it used as part uri pathname )
            decodeURI(KustoDomains.getAlias(withInitialCatalog ? url.hostname : url.toString()))
        );
        return {
            connectionString,
            clusterName: clusterName,
            initialCatalog: withInitialCatalog ? url.pathname.substr(1) : undefined,
        };
    } catch {
        return {
            connectionString: undefined,
            clusterName: userInput,
        };
    }
};
