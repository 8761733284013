import { isFalsyOrWhitespace, KustoDomains } from '@kusto/common';

/**
 * TODO: handle escaping (javascript doesn't have a parallel to Regex.Unescape)
 * Parse kusto string literal
 */
export function parseStringLiteral(str: string): { isSuccess: boolean; literal: string } {
    const failure = {
        isSuccess: false,
        literal: str,
    };

    let literal = str;

    // We don't generally expect this to be true:
    if (isFalsyOrWhitespace(literal)) {
        return failure;
    }

    // String literals starting with an 'h' or 'H' denote literals
    // that should be hidden, but here we don't care.
    if (literal[0] === 'h' || literal[0] === 'H') {
        literal = literal.substr(1);
    }

    if (literal.startsWith('"')) {
        if (literal.length >= 2 && literal.endsWith('"')) {
            literal = literal.substr(1, literal.length - 2);
            return {
                isSuccess: true,
                literal,
            };
            // if (!ExtendedRegex.TryUnescape(literal, out literal))
            // {
            //     return false;
            // }
            // return true;
        }
    } else if (literal.startsWith(`'`)) {
        if (literal.length >= 2 && literal.endsWith(`'`)) {
            literal = literal.substr(1, literal.length - 2);
            return {
                isSuccess: true,
                literal,
            };
            // if (!ExtendedRegex.TryUnescape(literal, out literal))
            // {
            //     return false;
            // }
            // return true;
        }
    } else if (literal.startsWith(`@"`)) {
        if (literal.length >= 3 && literal.endsWith(`"`)) {
            const ret = literal.substr(2, literal.length - 3);
            literal = ret.replace(`""`, `"`); // "" -> "
            return {
                isSuccess: true,
                literal,
            };
        }
    } else if (literal.startsWith(`@'`)) {
        if (literal.length >= 3 && literal.endsWith(`'`)) {
            const ret = literal.substr(2, literal.length - 3);
            literal = ret.replace(`''`, `'`); // '' -> '
            return {
                isSuccess: true,
                literal,
            };
        }
    }

    return failure;
}

/**
 * Responsible for parsing different kinds of connection strings into a KustoConnection object that contains
 * context such as cluster and database names, auth method ETC.
 */
export class KustoConnection {
    private static clusterDatabaseRegex = /^Cluster\(([^)]+?)\)\.?(Database\(([^)]*)\))?/i;
    cluster: string | undefined;
    database: string | undefined;

    /**
     * Parse something like `https://kuskus.kusto.windows.net/Kuskus` into a KustoConnection
     */
    public static fromConnectionString(connectionString: string | null): KustoConnection | null {
        if (!connectionString) {
            return null;
        }

        connectionString = connectionString.split(';')[0];

        try {
            const url = new URL(connectionString);
            const hostname = url.hostname;

            // get the alias (everything before .kusto.windows.net)
            const cluster = KustoDomains.getAlias(hostname);

            const database = url.pathname.split('/').filter((x) => x)[0];

            if (!cluster && !database) {
                return null;
            }

            return {
                cluster,
                database,
            };
        } catch {
            return null;
        }
    }

    /**
     * Parse something like `cluster('kuskus').database('Kuskus')` into a KustoConnection
     */
    public static fromClusterAndDatabase(
        clusterAndDatabase: string,
        aliasesToNameMapping: { [alias: string]: string }
    ): KustoConnection | null {
        const matchResults = clusterAndDatabase.match(KustoConnection.clusterDatabaseRegex);
        if (!matchResults) {
            return null;
        }

        let cluster = parseStringLiteral(matchResults[1]).literal;
        let database = parseStringLiteral(matchResults[3]).literal;

        // a cluster might be either a cluster name, a cluster alias or a full connection string.
        if (cluster) {
            const clusterConnection = KustoConnection.fromConnectionString(cluster);
            if (clusterConnection && clusterConnection.cluster) {
                cluster = clusterConnection.cluster;
            } else {
                cluster = aliasesToNameMapping[cluster] ?? cluster;
            }
            if (clusterConnection && clusterConnection.database && !database) {
                database = clusterConnection.database;
            }
        }

        return {
            cluster,
            database,
        };
    }

    /**
     * parse something like `@kuskus/Kuskus` into a KustoConnection.
     * This notations should be equivalent to https://kuskus.kusto.windows.net/Kuskus.
     */
    public static fromShorthand(
        shorthand: string,
        aliasesToNameMapping: { [alias: string]: string }
    ): KustoConnection | null {
        if (!shorthand.startsWith('@') || shorthand.length === 1) {
            return null;
        }

        shorthand = shorthand.substring(shorthand.indexOf('@') + 1);
        let cluster = shorthand.substring(0, shorthand.indexOf('/'));
        const database = shorthand.substring(shorthand.indexOf('/') + 1);

        if (isFalsyOrWhitespace(cluster)) {
            return null;
        }

        cluster = cluster.trim();

        if (cluster.startsWith('net.tcp://') || cluster.startsWith('http://') || cluster.startsWith('https://')) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            cluster = KustoConnection.fromConnectionString(cluster)!.cluster!;
        } else {
            cluster = aliasesToNameMapping[cluster] ?? cluster;
        }

        return { cluster, database };
    }
}

/**
 * Translate the content of a #connect client directive to
 * a KustoConnection object. supports the following formats:
 *
 * #connect https://pst.kusto.windows.net
 *
 * #connect 'https://pst.kusto.windows.net'
 *
 * #connect 'cluster('pst').database('Test')'
 *
 * #connect cluster('pst').database('Prod')
 *
 * #connect cluster(pst).database(Prod)
 *
 * #connect @kuskus/Kuskus
 */
export function resolveKustoConnection(text: string, aliasesToNameMapping: { [alias: string]: string }) {
    let result = KustoConnection.fromClusterAndDatabase(text, aliasesToNameMapping);
    if (result) {
        return result;
    }

    result = KustoConnection.fromConnectionString(text);
    if (result) {
        return result;
    }

    result = KustoConnection.fromShorthand(text, aliasesToNameMapping);
    if (result) {
        return result;
    }

    return null;
}
