import * as React from 'react';
import { IChoiceGroupOption, ChoiceGroup } from 'office-ui-fabric-react';
import { action } from 'mobx';

import { APP_STRINGS } from '../../../../../res';
import { useDashboardStore } from '../../../../../store/dashboard';
import { BasicParam } from '../../../../../domain';

import { EppAction } from '../../../reducer';

import pageStyles from '../../../EditParameterPage.module.scss';

export type DataSourceTypeSelectorProps = {
    dispatch: React.Dispatch<EppAction>;
    dataSourceType: BasicParam.DataSource<unknown>['kind'];
};

const options: IChoiceGroupOption[] = [
    { key: 'static', text: APP_STRINGS.editParameterPage.dataSourceType.static },
    { key: 'query', text: APP_STRINGS.editParameterPage.dataSourceType.query },
];

export const DataSourceTypeSelector: React.FC<DataSourceTypeSelectorProps> = ({ dispatch, dataSourceType }) => {
    const store = useDashboardStore();

    const onChange = React.useMemo(
        () =>
            action((_: unknown, option?: IChoiceGroupOption | undefined) => {
                switch (option?.key) {
                    case 'static':
                        dispatch({ type: 'changeToStaticOptions' });
                        break;
                    case 'query': {
                        const defaultDataSourceId = store.state?.dataSources[0]?.id;
                        dispatch({ type: 'changeToQueryOptions', defaultDataSourceId });
                        break;
                    }
                }
            }),
        [dispatch, store]
    );

    return (
        <ChoiceGroup
            className={pageStyles.input}
            label={APP_STRINGS.editParameterPage.dataSourceTypeDropdownLabel}
            options={options}
            selectedKey={dataSourceType}
            onChange={onChange}
        />
    );
};
