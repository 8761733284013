import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { v4 } from 'uuid';

let appInsights: ApplicationInsights;
export const setAppInsights = (_appInsights: ApplicationInsights) => (appInsights = _appInsights);

export const SeverityLevels = SeverityLevel;

// An event should contain at least the component and the flow.
export interface FlowProperties {
    component: string;
    flow: string;
}

const sessionID = v4();

export function getTelemetryClient<propsType extends FlowProperties = FlowProperties>(constantProps: propsType) {
    const trackEvent = (
        name: string,
        properties?: ({ [name: string]: string } & Partial<propsType>) | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        measurements?: { [name: string]: number } | undefined
    ) => {
        // appInsights.trackEvent can actually be null (before calling loadAppInsights)
        appInsights?.trackEvent({
            name,
            properties: { ...constantProps, ...properties, sessionID },
            measurements,
        });
    };
    const trackEventWithContext = (
        name: string,
        context?: { [name: string]: string },
        properties?: ({ [name: string]: string } & Partial<propsType>) | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        measurements?: { [name: string]: number } | undefined
    ) => {
        appInsights?.trackEvent({
            name,
            properties: { ...constantProps, ...context, ...properties, sessionID },
            measurements,
        });
    };

    const trackTrace = (
        message: string,
        severityLevel?: SeverityLevel,
        properties?: ({ [name: string]: string } & Partial<propsType>) | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        measurements?: { [name: string]: number } | undefined
    ) => {
        appInsights?.trackTrace({
            message,
            properties: { ...constantProps, ...properties, sessionID },
            measurements,
            severityLevel,
        });
    };

    const trackTraceWithContext = (
        message: string,
        severityLevel?: SeverityLevel,
        context?: { [name: string]: string },
        properties?: ({ [name: string]: string } & Partial<propsType>) | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        measurements?: { [name: string]: number } | undefined
    ) => {
        appInsights?.trackTrace({
            message,
            properties: { ...constantProps, ...context, ...properties, sessionID },
            measurements,
            severityLevel,
        });
    };

    const trackException = (
        exception: Error,
        handledAt?: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number },
        severityLevel?: SeverityLevel
    ) => {
        appInsights?.trackException({
            exception,
            properties: { ...constantProps, properties, handledAt, sessionID },
            measurements,
            severityLevel,
        });
    };

    // This doesn't currently do anything (other than capture 'this').
    const startTrackEvent = (name: string) => {
        trackEvent(name);
        appInsights?.startTrackEvent(name);
    };

    const stopTrackEvent = (
        name: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }
    ) => {
        appInsights?.stopTrackEvent(name, { sessionID, ...constantProps, ...properties }, measurements);
    };

    const trackPageView = (
        name: string,
        properties?: { [key: string]: string },
        measurements?: { [name: string]: number }
    ) =>
        appInsights?.trackPageView({
            name,
            properties: { ...constantProps, ...properties, sessionID },
            measurements,
        });

    return {
        trackEvent,
        trackException,
        startTrackEvent,
        stopTrackEvent,
        trackEventWithContext,
        trackPageView,
        trackTraceWithContext,
        trackTrace,
    };
}
