import {
    IColumn,
    CheckboxVisibility,
    SelectionMode,
    IDetailsListStyles,
    ColumnActionsMode,
} from 'office-ui-fabric-react/lib/components/DetailsList';
import * as React from 'react';
import { useMemo, useCallback } from 'react';
import {
    TooltipHost,
    Stack,
    PrimaryButton,
    Spinner,
    assertNever,
    SpinnerSize,
    ITooltipHostStyles,
    DefaultButton,
} from 'office-ui-fabric-react';
import { unix } from 'moment';
import { observer } from 'mobx-react-lite';
import { validate } from 'uuid';

import { IDashboardListItem, IDashboardListPermission } from '../../../core/domain';
import { APP_STRINGS } from '../../../res';
import { useGlobalSelectors, useGlobalDispatch } from '../../../store/redux';
import { NavigationLink, useVisitUrl } from '../../../domain/navigation';
import { SortableDetailsList, NoData } from '../../../components';
import { compactMap } from '../../../common';
import { createDashboardDialogAction } from '../../../components/Forms';
import { IDashboardVersioned } from '../../../migration/dashboard/versions';
import { useCore } from '../../../core';

import { RowEditButton } from './RowEditButton';
import { FavoriteCell } from './RowFavoriteButton';
import { RowMenuButton } from './RowMenuButton';
import { CreateDashboardIcon } from './CreateDashboardIcon';

import styles from './Catalog.module.scss';

export interface ICatalogProps {
    dashboardList: IDashboardListItem[] | undefined;
    isLoading: boolean;
    isFiltering?: boolean;
    creatorFetchErrorMessage?: string;
}

/*
 * Some dashboards have this as "createdBy" which was used as a placeholder in a previous migration
 * FIXME: Remove these values from the dashboard on the backend
 */
const ZEROED_GUID = '00000000-0000-0000-0000-000000000000';

/*
 * 0 was filled if no createdAt or updatedAt time was available
 */
const INVALID_UNIX_TIME = 0;

/*
 * Line height 20px ensures text is vertically centered
 */
const toolTipHostStyles: Partial<ITooltipHostStyles> = {
    root: {
        display: 'inline-block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};

interface IAugmentedDashboardListItem extends IDashboardListItem {
    isFavorite: boolean;
    openedFromNow: number;
    hasPermissions: boolean;
    createdByError: string | undefined;
}

const CatalogListLoading: React.FC<{ loading: boolean }> = ({ loading }) => (
    <div className={loading ? `${styles.listLoadingOverlay} ${styles.loading}` : styles.listLoadingOverlay}>
        {loading && <Spinner className={styles.loadingSpinner} size={SpinnerSize.large} />}
    </div>
);

type ListStatus = 'loading' | 'noFilteredDashboards' | 'noDashboards' | 'showDashboards';

const ListEmptyStates: React.FC<{
    listStatus: ListStatus;
    sampleDemoDashboard?: IDashboardVersioned;
}> = ({ listStatus, sampleDemoDashboard }) => {
    const [dispatch] = useGlobalDispatch();

    const onAddDashboard = useCallback(() => dispatch(createDashboardDialogAction({})), [dispatch]);

    const onAddSampleDashboard = useCallback(
        () =>
            dispatch(
                createDashboardDialogAction({
                    template: sampleDemoDashboard,
                    title: sampleDemoDashboard?.title,
                })
            ),
        [dispatch, sampleDemoDashboard]
    );

    switch (listStatus) {
        case 'showDashboards':
        case 'loading':
            return null;
        case 'noFilteredDashboards':
            return <NoData message={APP_STRINGS.catalogPage.noDashboards} />;
        case 'noDashboards':
            return (
                <Stack className={styles.callToAction} horizontalAlign="center">
                    <CreateDashboardIcon />
                    <Stack horizontal={true}>
                        <PrimaryButton className={styles.button} onClick={onAddDashboard}>
                            {APP_STRINGS.catalogPage.callToAction}
                        </PrimaryButton>
                        {!!sampleDemoDashboard && (
                            <DefaultButton className={styles.button} onClick={onAddSampleDashboard}>
                                {APP_STRINGS.catalogPage.callToActionSample}
                            </DefaultButton>
                        )}
                    </Stack>
                </Stack>
            );
        default:
            assertNever(listStatus);
    }
};

const columns: IColumn[] = [
    {
        key: 'name',
        name: APP_STRINGS.catalogPage.dashboardsTableColumns.name.label,
        ariaLabel: APP_STRINGS.catalogPage.dashboardsTableColumns.name.ariaLabel,
        isRowHeader: true,
        minWidth: 200,
        fieldName: 'title',

        onRender: (dashboardItem: IAugmentedDashboardListItem) => (
            <NavigationLink
                title={APP_STRINGS.catalogPage.openDashboardButtonTitle}
                className={styles.link}
                to={`/${dashboardItem.id}`}
            >
                <div className={styles.title}>{dashboardItem.title} </div>
            </NavigationLink>
        ),
    },
    {
        key: 'openedAt',
        name: APP_STRINGS.catalogPage.dashboardsTableColumns.lastAccessed.label,
        ariaLabel: APP_STRINGS.catalogPage.dashboardsTableColumns.lastAccessed.ariaLabel,
        minWidth: 120,
        fieldName: 'openedFromNow',
        onRender: (dashboardItem: IAugmentedDashboardListItem) => {
            const parsedDate = dashboardItem.openedAt === INVALID_UNIX_TIME ? undefined : unix(dashboardItem.openedAt);

            return (
                <TooltipHost
                    setAriaDescribedBy={false}
                    styles={toolTipHostStyles}
                    content={
                        parsedDate
                            ? parsedDate.format(APP_STRINGS.catalogPage.lastAccessedTooltipDateFormat)
                            : APP_STRINGS.time.never
                    }
                >
                    {parsedDate ? parsedDate.fromNow() : APP_STRINGS.time.never}
                </TooltipHost>
            );
        },
    },
    {
        key: 'createdAt',
        name: APP_STRINGS.catalogPage.dashboardsTableColumns.createdAt.label,
        ariaLabel: APP_STRINGS.catalogPage.dashboardsTableColumns.createdAt.ariaLabel,
        minWidth: 120,
        fieldName: 'createdAt',
        onRender: (dashboardItem: IAugmentedDashboardListItem) => {
            const parsedDate =
                dashboardItem.createdAt > INVALID_UNIX_TIME
                    ? // convert unix seconds to ms
                      new Date(dashboardItem.createdAt * 1000).toLocaleDateString()
                    : undefined;

            return (
                <TooltipHost
                    setAriaDescribedBy={true}
                    styles={toolTipHostStyles}
                    content={parsedDate ?? APP_STRINGS.catalogPage.dashboardsTableColumns.createdAt.tooltip}
                >
                    {parsedDate ?? APP_STRINGS.catalogPage.dashboardsTableColumns.empty}
                </TooltipHost>
            );
        },
    },
    {
        key: 'createdBy',
        name: APP_STRINGS.catalogPage.dashboardsTableColumns.createdBy.label,
        ariaLabel: APP_STRINGS.catalogPage.dashboardsTableColumns.createdBy.ariaLabel,
        minWidth: 140,
        fieldName: 'createdBy',
        onRender: (dashboardItem: IAugmentedDashboardListItem) => (
            <>
                {dashboardItem.createdByError ? (
                    <TooltipHost styles={toolTipHostStyles} content={dashboardItem.createdByError}>
                        {dashboardItem.createdBy === ZEROED_GUID || dashboardItem.createdBy === undefined
                            ? APP_STRINGS.catalogPage.dashboardsTableColumns.empty
                            : dashboardItem.createdBy}
                    </TooltipHost>
                ) : (
                    dashboardItem.createdBy
                )}
            </>
        ),
    },
    {
        key: 'actions',
        name: '',

        ariaLabel: APP_STRINGS.catalogPage.dashboardsTableColumns.actions.ariaLabel,
        minWidth: 88,
        className: styles.actionsButtonsContainer,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (dashboardItem: IAugmentedDashboardListItem) => (
            <>
                <FavoriteCell dashboardItem={dashboardItem} />
                <RowEditButton dashboardItem={dashboardItem} />
                <RowMenuButton dashboardItem={dashboardItem} />
            </>
        ),
    },
];

const deriveListStatus = (
    loading: boolean,
    dashboardItems: IAugmentedDashboardListItem[],
    filtering: boolean
): ListStatus => {
    if (loading) {
        return 'loading';
    } else if (dashboardItems.length === 0) {
        if (filtering) {
            return 'noFilteredDashboards';
        } else {
            return 'noDashboards';
        }
    } else {
        return 'showDashboards';
    }
};

const catalogDetailsListStyles: Partial<IDetailsListStyles> = {
    headerWrapper: { position: 'sticky', top: 0, zIndex: 1 },
};

const sortDisabledColumnKeys = new Set(['actions', 'favorites']);

export const Catalog: React.FunctionComponent<ICatalogProps> = observer(function Catalog({
    dashboardList,
    isLoading,
    isFiltering,
    creatorFetchErrorMessage,
}) {
    const [dispatch] = useGlobalDispatch();
    const userSettings = useCore().userSettingsState.userSettings;
    const {
        hostConfig: { dashboardTemplates },
    } = useCore();
    const { catalogSortField, catalogSortDescending } = useGlobalSelectors({
        catalogSortField: (s) => s.catalogPage.catalogSortField,
        catalogSortDescending: (s) => s.catalogPage.catalogSortDescending,
    });
    const visitUrl = useVisitUrl();

    const onSortChange = useCallback(
        (sortField: string, sortDescending: boolean) =>
            dispatch({
                type: 'setCatalogSort',
                catalogSortField: sortField,
                catalogSortDescending: sortDescending,
            }),
        [dispatch]
    );

    const dashboardItems = useMemo(() => {
        const now = Date.now();
        return dashboardList
            ? compactMap(dashboardList, (dashboard): IAugmentedDashboardListItem | undefined => {
                  if (!dashboard) {
                      return undefined;
                  }

                  let createdByError = creatorFetchErrorMessage;
                  // This accounts for zeroedGuid that is saved on some dbds on the backend
                  if (dashboard.createdBy === ZEROED_GUID || dashboard.createdBy === undefined) {
                      createdByError = APP_STRINGS.catalogPage.dashboardsTableColumns.createdBy.noCreatedBySavedError;
                      // Show guid in tooltip if created by is not resolved
                  } else if (validate(dashboard.createdBy)) {
                      createdByError = `${dashboard.createdBy} ${createdByError ?? ''}`;
                  }

                  return {
                      ...dashboard,
                      isFavorite: userSettings.favoriteDashboards.has(dashboard.id),
                      openedFromNow: now - dashboard.openedAt,
                      hasPermissions: dashboard.permission !== IDashboardListPermission.none,
                      createdByError,
                  };
              })
            : [];
    }, [dashboardList, userSettings.favoriteDashboards, creatorFetchErrorMessage]);

    const listStatus = deriveListStatus(isLoading, dashboardItems, isFiltering ?? false);

    const onItemInvoked = useCallback(
        (dashboardItem: IAugmentedDashboardListItem) => visitUrl(`/${dashboardItem.id}`),
        [visitUrl]
    );

    return (
        // Outer div is loading overlay target, inner div has scrollbar
        <div className={styles.catalogWrapper}>
            <div>
                <SortableDetailsList
                    styles={catalogDetailsListStyles}
                    items={dashboardItems}
                    columns={columns}
                    sort={true}
                    onSortChange={onSortChange}
                    defaultSortFieldName={catalogSortField}
                    defaultSortDescending={catalogSortDescending}
                    sortDisabledColumnKeys={sortDisabledColumnKeys}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    selectionMode={SelectionMode.none}
                    onItemInvoked={onItemInvoked}
                />
                <ListEmptyStates listStatus={listStatus} sampleDemoDashboard={dashboardTemplates[0]?.template} />
                <CatalogListLoading loading={isLoading} />
            </div>
        </div>
    );
});
