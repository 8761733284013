import * as React from 'react';
import { useCallback } from 'react';
import { DialogFooter, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../res';
import { TFormComponent, formActionMacro } from '../../../common';
import { RTDDialog } from '../../fabric';

const dialogContentProps = {
    title: APP_STRINGS.forms.closePermissionsPanel.title,
    subText: APP_STRINGS.forms.closePermissionsPanel.subText,
};

export const ClosePermissionPanelDialog: TFormComponent<{
    onComplete?: () => void;
}> = ({ onClose, onComplete }) => {
    const handleOnClose = useCallback(() => {
        onClose();
        onComplete?.();
    }, [onClose, onComplete]);

    return (
        <RTDDialog hidden={false} onDismiss={onClose} dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <PrimaryButton
                    data-automation-id="close-panel"
                    onClick={handleOnClose}
                    text={APP_STRINGS.forms.closePermissionsPanel.closeButton}
                />
                <DefaultButton
                    data-automation-id="cancel-close-panel"
                    onClick={onClose}
                    text={APP_STRINGS.utilButtons.cancel}
                />
            </DialogFooter>
        </RTDDialog>
    );
};

export const createClosePermissionsAction = formActionMacro(ClosePermissionPanelDialog, 'closePermissionPanel');
