import { err, ok } from '../../../common';
import { APP_STRINGS } from '../../../res';

import { buildValueImpl } from '../valueImpl';

export const float64ValueImpl = buildValueImpl<number, 'float64'>({
    dataType: 'float64',
    urlStringToValue(value: string) {
        const result = parseFloat(value);

        if (isNaN(result)) {
            return err(APP_STRINGS.domain.parameter.errors.error);
        }
        return ok(result);
    },
    valueToUrlString(value: number) {
        return value.toString();
    },
    valueToDisplayString(value: number) {
        return value.toLocaleString();
    },
    tryNarrowValue(value) {
        if (value.tag === 'float64') {
            return value;
        }
        return undefined;
    },
    isValue(value) {
        return typeof value === 'number';
    },
});
