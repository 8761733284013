import * as React from 'react';
import { assertNever, IDropdownOption } from 'office-ui-fabric-react';
import { useMemo, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useTelemetry } from '../../../domain/telemetry';
import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { usePullState } from '../../../common';
import {
    BasicParam,
    BasicParamValue,
    RtdValue,
    useParameterOptions,
    useParentParameterValues,
    useExecuteParameter,
    applyDropdownSelection,
    useTimeZone,
} from '../../../domain';
import { RTDDropdownOption } from '../../fabric/dropdown';
import { IParameterSelections, useDashboardStore } from '../../../store';
import { useCore } from '../../../core';

import { ParameterDropdown } from '../ParameterDropdown';
import { ParameterFilteredByList } from '../components/ParameterFilteredByList';
import { IParameterSelectorProps2 } from './types';

interface IPrimitiveParameterSelectorProps<T, K extends string> extends IParameterSelectorProps2 {
    parameterValue: BasicParamValue<T, K>;
    selections: IParameterSelections;
    onChange(value: BasicParamValue<T, K>): void;
}

function doesAllowSelectAll(selectionKind: BasicParam.SelectionKind) {
    switch (selectionKind) {
        case 'array-null':
        case 'scalar-null':
            return true;
        case 'scalar':
        case 'freetext':
            return false;
        default:
            assertNever(selectionKind);
    }
}

export const DropdownParameterSelector = observer(function DropdownParameterSelector<
    T extends string | number | boolean,
    K extends RtdValue.BasicType
>({
    parameterValue,
    isActive,
    variableNames,
    telemetryComponentName,
    selections,
    onChange,
}: IPrimitiveParameterSelectorProps<T, K>) {
    const core = useCore();
    const dashboardStore = useDashboardStore();
    const timeZone = useTimeZone();

    const [localSelections, setLocalState, getLocalState] = usePullState<undefined | RtdValue.Value<T, K>>(undefined);

    useEffect(() => setLocalState(parameterValue.data), [parameterValue, setLocalState]);

    const usedParameterValues = useParentParameterValues(dashboardStore, selections, parameterValue.config);

    const [result, refresh] = useExecuteParameter(
        core,
        dashboardStore,
        parameterValue.config,
        usedParameterValues,
        timeZone
    );

    const insertSelectAll = parameterValue && doesAllowSelectAll(parameterValue.config.options.selectionKind);

    const trackEvent = useTelemetry(telemetryComponentName);

    const { options, selectedKeys } = useParameterOptions<T>({
        valueToDisplayString: parameterValue.config.impl.valueToDisplayString,
        expectedOptions: result.kind === 'success' ? result.result : undefined,
        selected: localSelections,
        includeAll: insertSelectAll,
        timeZone,
    });

    const parameterKeysArray = useMemo(() => [...selectedKeys], [selectedKeys]);

    const allowMultiselect = parameterValue.config.options.selectionKind === 'array-null';

    // handle buttons and onChange
    const onChangeParameterOption = useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
            if (!option) {
                return;
            }

            const selectOption = option as RTDDropdownOption<typeof APP_CONSTANTS.parameter.allSelection | T>;

            trackEvent('SelectParameter', { id: parameterValue.config.id });

            const dataType = parameterValue.config.dataType;
            const data = selectOption.data;

            if (data === undefined) {
                throw new Error(`Data is unexpectedly missing for key ${selectOption.key}`);
            }
            setLocalState((prev) =>
                applyDropdownSelection(
                    prev,
                    dataType,
                    parameterValue.config.options.selectionKind === 'array-null',
                    data,
                    options
                )
            );
        },
        [options, trackEvent, parameterValue.config, setLocalState]
    );

    const onDismiss = useCallback(() => {
        const value = getLocalState();

        if (value) {
            onChange(new BasicParamValue(parameterValue.config, value));
        }
    }, [parameterValue.config, getLocalState, onChange]);

    const onErrorRefreshClick = useCallback(() => {
        refresh();
        trackEvent('ManualParameterRefreshTriggered');
    }, [trackEvent, refresh]);

    const onRenderHeader = useMemo(() => {
        const userOptions = parameterValue.config.options;
        if (userOptions.selectionKind === 'freetext' || userOptions.dataSource.kind !== 'query') {
            return;
        }
        const dataSource = userOptions.dataSource;
        return () => (
            <ParameterFilteredByList activeParentParameters={dataSource.consumedVariables} withDivider={true} />
        );
    }, [parameterValue]);

    const isSelectAll =
        parameterValue.data?.kind === 'null' ||
        (parameterValue.data?.kind === 'array' && parameterValue.data.values.length === 0);

    return (
        <ParameterDropdown
            name={parameterValue.config.displayName}
            options={options}
            onChange={onChangeParameterOption}
            onDismiss={onDismiss}
            disabled={!isActive}
            multiSelect={allowMultiselect || undefined}
            {...(allowMultiselect
                ? { selectedKeys: parameterKeysArray }
                : {
                      selectedKey: isSelectAll ? APP_CONSTANTS.parameter.allSelection : parameterKeysArray[0],
                  })}
            isPill
            loading={result.kind === 'notRun' || result.status?.kind === 'loading'}
            error={
                result.kind === 'err' ? (
                    <>
                        <p>{APP_STRINGS.parameterDropdown.queryError}</p>
                        <p>{result.reason.title}</p>
                        {result.reason.body && <p>{result.reason.body}</p>}
                    </>
                ) : undefined
            }
            errorButtonProps={{
                iconName: 'Refresh',
                text: APP_STRINGS.utilButtons.refresh,
                onClick: onErrorRefreshClick,
            }}
            isSelectAll={isSelectAll}
            containsSelectAll={insertSelectAll}
            variables={variableNames}
            // Only show variables if not active
            showVariablesInPill={!isActive}
            onRenderHeader={onRenderHeader}
            data-automation-id="parameterDropdown"
        />
    );
});
