import React from 'react';

export type Thunk<S, A> = (dispatch: React.Dispatch<A | Thunk<S, A>>, getState: () => S) => void;

export function useThunkReducer<S, A, I>(
    reducer: React.Reducer<S, A>,
    initialArg: I,
    init: (a: I) => S
): [S, React.Dispatch<A | Thunk<S, A>>, () => S];

export function useThunkReducer<S, A>(
    reducer: React.Reducer<S, A>,
    initialArg: S,
    init?: undefined
): [S, React.Dispatch<A | Thunk<S, A>>, () => S];

export function useThunkReducer<S, A, I>(
    reducer: React.Reducer<S, A>,
    initialArg: I,
    init = (a: I): S => a as unknown as S
): [S, React.Dispatch<A | Thunk<S, A>>, () => S] {
    const [hookState, setHookState] = React.useState(init(initialArg));

    const state = React.useRef(hookState);

    const actions = React.useMemo(() => {
        const setState = (newState: S) => {
            state.current = newState;
            setHookState(newState);
        };

        const reduce = (action: A) => reducer(getState(), action);
        const getState = () => state.current;
        const dispatch = (action: A | Thunk<S, A>) =>
            typeof action === 'function' ? (action as Thunk<S, A>)(dispatch, getState) : setState(reduce(action));

        return [dispatch, getState] as const;
    }, [reducer]);

    return [hookState, ...actions];
}
