import { FragmentOperation, ExternalKustoQueryResult, QueryResultFragment } from '../../domain';

export function applyFragment(
    prevResult: ExternalKustoQueryResult,
    fragment: QueryResultFragment
): ExternalKustoQueryResult {
    if (fragment.operation === FragmentOperation.replace) {
        return fragment.data;
    }
    // TODO: Task 24529815: Merge result fragments based on query key
    return { ...fragment.data, rows: prevResult.rows.concat(fragment.data.rows) };
}
