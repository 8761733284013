import React from 'react';

import { parseException } from '../common';
import { rtdPrompt } from '../components/Forms/Prompt';
import { formatMigrationError } from '../components/errors/formatMigrationError';
import type { IRtdDashboardsCore } from '../core';
import { IDashboardDocument } from '../core/domain';
import type * as migration from '../migration';
import { serializeDashboard } from '../migration/dashboard';
import { APP_STRINGS } from '../res';
import { GlobalAction } from '../store';

export async function onExportToJson(
    dashboardId: string,
    core: IRtdDashboardsCore,
    dispatch: React.Dispatch<GlobalAction>,
    signal: AbortSignal
) {
    let dashboard: migration.MigrationResult<IDashboardDocument>;
    try {
        dashboard = await core.dashboardService.getDashboard(dashboardId, signal);
    } catch (e) {
        rtdPrompt(dispatch, APP_STRINGS.error.failedToLoadDashboard, { subText: parseException(e) });
        return;
    }
    if (dashboard.kind === 'err') {
        rtdPrompt(dispatch, APP_STRINGS.error.failedToLoadDashboard, {
            subText: formatMigrationError(dashboard.err),
        });
        return;
    }

    const serializedDashboard = serializeDashboard(dashboard.value.data);

    downloadDashboard(serializedDashboard);
}

const downloadDashboard = (dashboard: migration.DashboardSerialized) =>
    download(`dashboard-${dashboard.title}.json`, JSON.stringify(dashboard));

function download(filename: string, text: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
