import * as React from 'react';
import { useCallback } from 'react';

import { Slot } from '../../../../../../domain';

import { useETPSelector, useETPDispatch } from '../../../../../../store/editTile';
import { visualOptionsSelectorBuilder } from '../../../../lib';

import { ManagedConfigComponent } from '../../types';
import { SlotInput } from './SlotInput';

const slotSelector = visualOptionsSelectorBuilder((o) => o.multiStat__slot);

export const ETPSlotInput: ManagedConfigComponent = ({ disabled }) => {
    const [dispatch] = useETPDispatch();
    const slot = useETPSelector(slotSelector);
    const onChange = useCallback(
        (newSlot: Slot) =>
            dispatch({
                type: 'updateVisualOptions',
                options: { multiStat__slot: newSlot },
            }),
        [dispatch]
    );

    return <SlotInput id="etp--visual-configuration--slot" slot={slot} onChange={onChange} disabled={disabled} />;
};
