import { assertNever } from 'office-ui-fabric-react';

import { valueImpl } from './impl';
import { RtdValue } from './types';
import { ValueImpl } from './valueImpl';

export type UValueImpl = typeof valueImpl[RtdValue.BasicType];

export function arrValueAsGeneric<R>(
    value: RtdValue.UBasicArray,
    callback: <T, K>(v: RtdValue.RtdArray<T, K>, impl: ValueImpl<T, K>) => R
): R {
    switch (value.tag) {
        case 'bool':
            return callback(value, valueImpl.bool);
        case 'datetime':
            return callback(value, valueImpl.datetime);
        case 'decimal':
            return callback(value, valueImpl.decimal);
        case 'float64':
            return callback(value, valueImpl.float64);
        case 'int32':
            return callback(value, valueImpl.int32);
        case 'int64':
            return callback(value, valueImpl.int64);
        case 'string':
            return callback(value, valueImpl.string);
        default:
            assertNever(value);
    }
}

export function scalarValueAsGeneric<R>(
    value: RtdValue.UBasicScalar,
    callback: <T, K>(v: RtdValue.Scalar<T, K>, impl: ValueImpl<T, K>) => R
): R {
    switch (value.tag) {
        case 'bool':
            return callback(value, valueImpl.bool);
        case 'datetime':
            return callback(value, valueImpl.datetime);
        case 'decimal':
            return callback(value, valueImpl.decimal);
        case 'float64':
            return callback(value, valueImpl.float64);
        case 'int32':
            return callback(value, valueImpl.int32);
        case 'int64':
            return callback(value, valueImpl.int64);
        case 'string':
            return callback(value, valueImpl.string);
        default:
            assertNever(value);
    }
}

export function valueImplFor(dataType: RtdValue.BasicType): UValueImpl {
    switch (dataType) {
        case 'bool':
            return valueImpl.bool;
        case 'datetime':
            return valueImpl.datetime;
        case 'decimal':
            return valueImpl.decimal;
        case 'float64':
            return valueImpl.float64;
        case 'int32':
            return valueImpl.int32;
        case 'int64':
            return valueImpl.int64;
        case 'string':
            return valueImpl.string;
        default:
            assertNever(dataType);
    }
}
