import { APP_CONSTANTS } from '../../../../../res';

import { RTDDropdownOption } from './types';

export const buildRowHeightFunc = (options: RTDDropdownOption[]) => (index: number) => {
    const option = options[index];

    if (option.hidden) {
        return 0;
    }

    return APP_CONSTANTS.ux.fabric.dropdown.rowHeight;
};
