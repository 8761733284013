import { SyntheticEvent } from 'react';

const isModifiedEvent = (event: MouseEvent) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

/**
 * If first argument would trigger navigation, suppresses it and calls the
 * supplied function
 */
export const hrefCapturing =
    <A extends [SyntheticEvent<unknown> | undefined]>(continuation: (...args: A) => void) =>
    (...args: A) => {
        const event = args[0];

        // Derived from react-router-dom:
        // https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js#L35
        if (
            event === undefined ||
            event.defaultPrevented || // onClick prevented default
            (event instanceof MouseEvent &&
                (event.button !== 0 || // ignore everything but left clicks
                    isModifiedEvent(event))) // ignore clicks with modifier keys
        ) {
            return;
        }

        // Prevent navigating to the href
        event.preventDefault();

        return continuation(...args);
    };
