import * as React from 'react';
import { IList, List, ITextField } from 'office-ui-fabric-react';
import { useCallback, memo, useMemo, useRef } from 'react';

import { useCurrent } from '../../../../../common';
import { APP_STRINGS, APP_CONSTANTS } from '../../../../../res';

import { useOnInputKeyDown } from './hooks';
import {
    PrerenderedCustomListDropdownOption,
    KeyToIndex,
    RTDDropdownOption,
    CustomListDropdownHeaderProps,
} from './types';
import { useCustomListDropdownSelector, useCustomListDropdownDispatch } from './CustomListDropdownMenuContext';
import { CustomListDropdownMenuSelectionContext, useIsSelected } from './CustomListDropdownMenuSelectionContext';
import { CustomListDropdownMenuReducerState } from './reducer';

import styles from './CustomListDropdownMenu.module.scss';

export interface CustomListDropdownMenuBaseProps {
    onRenderHeader: (props: CustomListDropdownHeaderProps) => JSX.Element | null;
    onRenderHeaderPrefix?: () => JSX.Element | null;
    noData?: JSX.Element;

    /**
     * The number of items to count as no data
     */
    noDataCount?: number;

    options: RTDDropdownOption[];
    multiSelect: boolean;

    extendedOptionFunc?: (option: RTDDropdownOption) => Record<string, unknown>;
    renderedOptions?: JSX.Element[] | JSX.Element;
    defaultList?: JSX.Element | null;
    setSelectedIndex?: (event: React.FormEvent<HTMLDivElement>, index: number) => void;
    setIsOpen?: (open: boolean) => void;

    /**
     * Not used in this component; exposed for other components implementing these props
     */
    currentSelectedIndexes?: React.RefObject<Set<number> | undefined>;
    /**
     * Not used in this component; exposed for other components implementing these props
     */
    selectedKey?: string | null;
}

export type CustomListDropdownMenuProps = Omit<CustomListDropdownMenuBaseProps, 'onRenderHeader'>;

const selector = (state: CustomListDropdownMenuReducerState) => state;

export const CustomListDropdownMenu = ({
    onRenderHeader,
    onRenderHeaderPrefix,
    noData,
    noDataCount,
    options: externalOptions,
    multiSelect,
    extendedOptionFunc,
    renderedOptions,
    defaultList,
    setSelectedIndex,
    setIsOpen,
}: CustomListDropdownMenuBaseProps) => {
    const inputRef = useRef<ITextField | null>(null);
    const { listRef, orderedFilteredKeys, activeIndex } = useCustomListDropdownSelector(selector);
    const [dispatch] = useCustomListDropdownDispatch();

    const currentActiveIndex = useCurrent(activeIndex);

    const [options, keyToIndex] = useMemo((): [PrerenderedCustomListDropdownOption[], KeyToIndex] => {
        if (!renderedOptions || !Array.isArray(renderedOptions) || renderedOptions.length !== externalOptions.length) {
            return [[], {}];
        }

        const array: PrerenderedCustomListDropdownOption[] = new Array(externalOptions.length);
        const keys: KeyToIndex = {};

        for (let i = 0; i < externalOptions.length; i++) {
            const option = externalOptions[i];
            // We don't need to copy all fields
            const item: PrerenderedCustomListDropdownOption = {
                key: option.key,
                text: option.text,
                element: renderedOptions[i],
                disabled: option.disabled,
                hidden: option.hidden,
            };
            if (extendedOptionFunc) {
                array[i] = { ...item, ...extendedOptionFunc(option) };
            } else {
                array[i] = item as PrerenderedCustomListDropdownOption;
            }

            keys[option.key] = i;
        }

        return [array, keys];
    }, [externalOptions, renderedOptions, extendedOptionFunc]);

    const filteredOptions = useMemo(
        (): PrerenderedCustomListDropdownOption[] =>
            orderedFilteredKeys
                ? orderedFilteredKeys.map((key) => {
                      const newGlobalIndex = keyToIndex[key];

                      return options[newGlobalIndex];
                  })
                : options,
        [orderedFilteredKeys, options, keyToIndex]
    );

    const globalActiveIndex = activeIndex !== undefined ? keyToIndex[filteredOptions[activeIndex].key] : undefined;

    const setListRef = useCallback(
        (list: IList | null) =>
            dispatch({
                type: 'setListRef',
                listRef: list,
            }),
        [dispatch]
    );

    const onInputKeyDown = useOnInputKeyDown(
        filteredOptions,
        orderedFilteredKeys,
        keyToIndex,
        multiSelect,
        setSelectedIndex,
        setIsOpen,
        currentActiveIndex,
        listRef,
        inputRef,
        dispatch
    );

    const onRenderCell = useCallback(
        (item: PrerenderedCustomListDropdownOption | undefined, globalIndex?: number) => {
            const index = item && keyToIndex ? keyToIndex[item.key] : globalIndex ?? -1;

            return <ListCell item={item} index={index} setSelectedIndex={setSelectedIndex} />;
        },
        [keyToIndex, setSelectedIndex]
    );

    return (
        <div className={styles.gridWrapper}>
            {onRenderHeader({
                unfilteredOptions: options,
                onInputKeyDown,
                inputRef,
                onRenderHeaderPrefix,
            })}
            {/* Runtime check just in case a single object is sent through props, rather than an array. Apparently children
      can be a single element (maybe a Fabric bug), so we have to check to make sure. This is really only an issue
      because we're reaching into Fabric's normal render and pulling out the children it generates. */}
            {Array.isArray(renderedOptions) ? (
                <>
                    {filteredOptions.length < (noDataCount ?? 0) + 1 && noData}
                    <CustomListDropdownMenuSelectionContext.Provider value={globalActiveIndex}>
                        <List<PrerenderedCustomListDropdownOption>
                            componentRef={setListRef}
                            className={styles.itemsWrapper}
                            role="listbox"
                            aria-label={APP_STRINGS.aria.dropdownListLabel}
                            data-is-scrollable="true"
                            items={filteredOptions}
                            onRenderCell={onRenderCell}
                            getPageHeight={getPageHeight}
                        />
                    </CustomListDropdownMenuSelectionContext.Provider>
                </>
            ) : (
                <div className={styles.itemsWrapper}>{defaultList ?? null}</div>
            )}
        </div>
    );
};

/**
 * Fabric attempts to estimate the height of pages based on the first page, but somehow messes up. This sets all pages to a static height based on the number of rows
 *
 * If a page contains a hidden element, this isn't the exactly "correct" height, as hidden elements contribute zero height.
 * However, this number is only an estimate.
 */
const getPageHeight = (_: unknown, _2: unknown, itemCount = 0) =>
    itemCount * APP_CONSTANTS.ux.fabric.dropdown.rowHeight;

/**
 * Separated and wrapped in memo as the same button gets rendered many times, but typically without changes
 */
const ListCell: React.FC<{
    item: PrerenderedCustomListDropdownOption | undefined;

    /**
     * Absolute index of the ListCell in the *unfiltered* List
     */
    index: number;
    setSelectedIndex: ((event: React.FormEvent<HTMLDivElement>, index: number) => void) | undefined;
}> = memo(({ item, index, setSelectedIndex }) => {
    const element = item?.element ?? null;

    const isActive = useIsSelected(index);

    return isActive ? (
        // For some reason without this onClick, Checkboxes (multiselect scenario) will not receive the click event when active
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            className={styles.activeItem}
            onClick={setSelectedIndex ? (event) => setSelectedIndex(event, index) : undefined}
        >
            {element}
        </div>
    ) : (
        element
    );
});
