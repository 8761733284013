/* eslint-disable max-classes-per-file */
export class RestApiError extends Error {
    statusCode: number;

    constructor(statusCode: number, message: string) {
        super();

        this.statusCode = statusCode;
        this.message = message;
    }
}

// TODO #23865913: Add proper Error types for all network issues
export class NoRouteError extends RestApiError {
    constructor() {
        super(1000, 'No route error.');
    }
}

export const isRestApiError = (source: Error): source is RestApiError => source instanceof RestApiError;
