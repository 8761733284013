import React from 'react';
import { ICommandBarItemProps } from 'office-ui-fabric-react';
import * as mobx from 'mobx';
import { observer } from 'mobx-react-lite';
import * as H from 'history';
import { useHistory } from 'react-router-dom';

import { APP_STRINGS } from '../../../res';
import { PageContent } from '../../../components/PageContent';
import { GlobalAction, useGlobalDispatch } from '../../../store/redux';
import { IRtdDashboardsCore, useCore } from '../../../core';
import { AutoRefreshUpdateInterval } from '../../../core/domain';
import {
    createCommandBarShareItems,
    createCommandBarAutoRefreshItem,
    useTelemetry,
    onExportToJson,
    RouteState,
    cssVariableNames,
    useTimeZone,
} from '../../../domain';
import {
    manageParametersPanelAction,
    manageDataSourcesAction,
    removeDashboardDialogAction,
    createDashboardDialogAction,
} from '../../../components/Forms';
import { useDashboardBreadcrumbs } from '../../../domain/pages/useDashboardBreadcrumbs';
import { DashboardStore, useDashboardStore } from '../../../store';
import { rtdPrompt } from '../../../components/Forms/Prompt';
import { serializeDashboard } from '../../../migration';
import { useAbortController } from '../../../common';

import { saveDashboard } from './saveDashboard';
import { manageAutoRefreshAction } from './autoRefresh/AutoRefreshForm';

import styles from './Dashboard.module.scss';

const emptyCommandBarItems = { items: [], farItems: [], overflowItems: [] };

interface ActionHooksReturn {
    items: ICommandBarItemProps[];
    farItems: ICommandBarItemProps[];
}

const useEditingActions = (
    core: IRtdDashboardsCore,
    history: H.History<RouteState>,
    store: DashboardStore,
    dispatch: React.Dispatch<GlobalAction>
): ActionHooksReturn => {
    let discardDisabled: boolean;
    let saveDisabled: boolean;
    let maybeDashboardId: string | undefined;
    let dashboardTitle: string;
    let disabled: boolean;

    if (store.state) {
        const saving = store.state.disposeCurrentSave !== undefined;
        discardDisabled = saving;
        saveDisabled = store.state.title.trim() === '' || saving;
        maybeDashboardId = store.state.meta.id;
        dashboardTitle = store.state.title;
        disabled = !store.state.meta.isDashboardEditor;
    } else {
        discardDisabled = true;
        saveDisabled = true;
        maybeDashboardId = undefined;
        dashboardTitle = '';
        disabled = true;
    }

    const shareDisabled = store.state?.isNew || disabled;

    return React.useMemo(() => {
        if (!maybeDashboardId) {
            return emptyCommandBarItems;
        }

        const dashboardId = maybeDashboardId;

        return {
            items: [
                {
                    key: 'add-tile',
                    name: APP_STRINGS.addComponent.addQuery,
                    iconProps: { iconName: 'dashboards-NewQuery' },
                    disabled,
                    onClick: () =>
                        history.push(core.navUtil.newTile(dashboardId, 'query', history.location.hash.slice(1))),
                },
                {
                    key: 'add-markdown',
                    name: APP_STRINGS.addComponent.addMarkdown,
                    iconProps: { iconName: 'AddNotes' },
                    disabled,
                    onClick: () =>
                        history.push(core.navUtil.newTile(dashboardId, 'markdown', history.location.hash.slice(1))),
                },
                {
                    key: 'parameters',
                    name: APP_STRINGS.manage.parameter,
                    iconProps: { iconName: 'Parameter' },
                    onClick: () => dispatch(manageParametersPanelAction()),
                    disabled,
                },
                {
                    key: 'dataSource',
                    name: APP_STRINGS.manage.dataSource,
                    iconProps: { iconName: 'DataManagementSettings' },
                    onClick: mobx.action(() => {
                        if (store.state) {
                            dispatch(
                                manageDataSourcesAction({
                                    dashboardState: store.state,
                                    exclusiveSingleAdd: store.state.dataSources.length === 0,
                                })
                            );
                        }
                    }),
                    disabled,
                },
                {
                    key: 'autoRefresh',
                    name: APP_STRINGS.forms.autoRefresh.title,
                    iconProps: { iconName: 'dashboards-AutoRefresh' },
                    onClick: () => dispatch(manageAutoRefreshAction()),
                    disabled,
                },
            ],
            farItems: [
                {
                    key: 'save',
                    name: APP_STRINGS.utilButtons.saveChanges,
                    iconProps: { iconName: 'Save' },
                    disabled: saveDisabled,
                    onClick: () => {
                        saveDashboard(core, store, history, dispatch, 'DashboardPageContent');
                    },
                    role: 'button',
                },
                {
                    key: 'discard',
                    name: APP_STRINGS.utilButtons.discardChanges,
                    iconProps: { iconName: 'Cancel' },
                    disabled: discardDisabled,
                    onClick: () => {
                        (async () => {
                            if (
                                !mobx.runInAction(() => store.changed()) ||
                                (await rtdPrompt(dispatch, APP_STRINGS.forms.discardChanges.title, {
                                    subText: APP_STRINGS.forms.discardChanges.subtext,
                                    acceptText: APP_STRINGS.utilButtons.discard,
                                }))
                            ) {
                                if (mobx.runInAction(() => store.state?.isNew)) {
                                    history.push(core.navUtil.path.catalog);
                                } else {
                                    store.state?.stopEditing();
                                }
                            }
                        })();
                    },
                    role: 'button',
                },
                ...createCommandBarShareItems(dashboardId, dashboardTitle, shareDisabled, dispatch),
            ],
            overflowItems: [],
        };
    }, [
        maybeDashboardId,
        disabled,
        discardDisabled,
        saveDisabled,
        dashboardTitle,
        shareDisabled,
        dispatch,
        store,
        core,
        history,
    ]);
};

const useViewingActions = (
    core: IRtdDashboardsCore,
    store: DashboardStore,
    dispatch: React.Dispatch<GlobalAction>,
    componentAbortSignal: AbortSignal
): ActionHooksReturn => {
    let isEditor: boolean;
    let dashboardId: string | undefined;
    let dashboardTitle: string;
    let disabled: boolean;

    if (store.state !== undefined) {
        isEditor = store.state.meta.isDashboardEditor;
        dashboardId = store.state.meta.id;
        dashboardTitle = store.state.title;
        disabled = false;
    } else {
        isEditor = false;
        dashboardId = undefined;
        dashboardTitle = '';
        disabled = true;
    }

    const internalFeatureFlag = core.featureFlags.has('internal');

    return React.useMemo(
        () => ({
            items: [
                ...(isEditor
                    ? [
                          {
                              key: 'edit',
                              name: APP_STRINGS.utilButtons.edit,
                              iconProps: { iconName: 'Edit' },
                              onClick: mobx.action(() => {
                                  store.state?.startEditing();
                              }),
                              disabled,
                          },
                      ]
                    : []),
                ...createCommandBarShareItems(dashboardId, dashboardTitle, disabled, dispatch),
                {
                    key: 'duplicate',
                    name: APP_STRINGS.utilButtons.saveCopy,
                    iconProps: { iconName: 'SaveAll' },
                    onClick: mobx.action(() => {
                        if (store.state) {
                            dispatch(
                                createDashboardDialogAction({
                                    template: serializeDashboard(store.state.document),
                                    dashboardTitle,
                                })
                            );
                        }
                    }),
                    disabled,
                },
                ...(internalFeatureFlag
                    ? [
                          {
                              key: 'export',
                              name: APP_STRINGS.utilButtons.export,
                              iconProps: { iconName: 'Download' },
                              // Using curly braces to resolve the expected type to void
                              onClick: () => {
                                  if (dashboardId) {
                                      onExportToJson(dashboardId, core, dispatch, componentAbortSignal);
                                  }
                              },
                              disabled,
                          },
                      ]
                    : []),
                ...(isEditor
                    ? [
                          {
                              key: 'delete',
                              name: APP_STRINGS.utilButtons.delete,
                              iconProps: { iconName: 'Delete' },
                              onClick: () => {
                                  if (dashboardId) {
                                      dispatch(
                                          removeDashboardDialogAction({
                                              dashboardId,
                                              dashboardTitle,
                                          })
                                      );
                                  }
                              },
                              disabled,
                          },
                      ]
                    : []),
            ],
            farItems: [],
        }),
        [
            isEditor,
            disabled,
            dashboardId,
            dashboardTitle,
            dispatch,
            internalFeatureFlag,
            store,
            core,
            componentAbortSignal,
        ]
    );
};

function createCommandBarTimeZoneItem({
    timeZone,
    disabled,
    onOpenTimeZoneSettings,
}: {
    timeZone: string;
    disabled: boolean;
    onOpenTimeZoneSettings?: () => void;
}): ICommandBarItemProps {
    return {
        key: 'timeZone',
        name: timeZone,
        iconProps: { iconName: 'Clock' },
        onClick: onOpenTimeZoneSettings,
        disabled,
        //  This fixes for the Narrator. Currently it says "menuitem" on the buttons.
        role: 'button',
    };
}

export const DashboardPageContent: React.FC = observer(function DashboardPageContent({ children }) {
    const core = useCore();
    const store = useDashboardStore();
    const abortController = useAbortController();
    const history = useHistory<RouteState>();
    const trackEvent = useTelemetry('DashboardCommandBar');
    const timeZone = useTimeZone();

    const [dispatch] = useGlobalDispatch();

    const onRefresh = React.useCallback(() => {
        core.queryService.requestAllQueriesUpdate();
        trackEvent('ManualRefreshTriggered');
    }, [core, trackEvent]);

    const editingActions = useEditingActions(core, history, store, dispatch);
    const viewingActions = useViewingActions(core, store, dispatch, abortController.signal);

    const actions = store.isEditing ? editingActions : viewingActions;

    const onDashboardTitleChange = mobx.action((title: string) => {
        if (store.state !== undefined && store.state.changes) {
            store.state.changes.title = title;
        }
    });

    const breadcrumbs = useDashboardBreadcrumbs({
        store,
        dashboardId: store.state?.meta.id,
        allowEditingLastItem: store.isEditing
            ? {
                  onChange: onDashboardTitleChange,
                  inputTitle: APP_STRINGS.dashboardPage.pageContent.titleInputTitle,
              }
            : undefined,
    });

    const onSelectRefreshInterval = React.useMemo(
        () =>
            mobx.action((defaultInterval: AutoRefreshUpdateInterval | undefined) => {
                if (store.state) {
                    store.state.currentAutoRefreshRate = defaultInterval;
                    trackEvent('SelectRefreshInterval', {
                        interval: defaultInterval,
                    });
                }
            }),
        [store, trackEvent]
    );

    let autoRefreshInterval: AutoRefreshUpdateInterval | undefined;
    let minAutoRefreshInterval: AutoRefreshUpdateInterval | undefined;
    let autoRefreshEnabled: boolean;

    if (store.state !== undefined) {
        autoRefreshInterval = store.state.currentAutoRefreshRate;
        minAutoRefreshInterval = store.state.autoRefresh?.minInterval;
        autoRefreshEnabled = store.state.autoRefresh?.enabled ?? false;
    } else {
        autoRefreshInterval = undefined;
        minAutoRefreshInterval = undefined;
        autoRefreshEnabled = false;
    }

    const hasTimeZoneFeatureFlag = core.featureFlags.has('timezone');

    const farItems = React.useMemo(() => {
        const autoRefreshItem = createCommandBarAutoRefreshItem({
            autoRefreshInterval,
            minAutoRefreshInterval,
            autoRefreshEnabled,
            disabled: store.state === undefined,
            onRefresh,
            onSelectRefreshInterval,
        });

        if (hasTimeZoneFeatureFlag) {
            return [
                ...actions.farItems,
                createCommandBarTimeZoneItem({
                    timeZone,
                    disabled: store.state === undefined,
                    onOpenTimeZoneSettings: () => {
                        core.hostConfig.openTimeZoneSettings?.();
                        trackEvent('OpenTimeZoneSettings');
                    },
                }),
                autoRefreshItem,
            ];
        }

        return [...actions.farItems, autoRefreshItem];
    }, [
        hasTimeZoneFeatureFlag,
        core.hostConfig,
        actions.farItems,
        autoRefreshInterval,
        minAutoRefreshInterval,
        autoRefreshEnabled,
        store.state,
        timeZone,
        onRefresh,
        onSelectRefreshInterval,
        trackEvent,
    ]);

    return (
        <PageContent
            breadcrumbs={breadcrumbs}
            commandBar={{
                items: actions.items,
                farItems,
            }}
            breadcrumbBackgroundColor={cssVariableNames.white}
            contentWrapperClassName={styles.pageContentContainer}
        >
            {children}
        </PageContent>
    );
});
