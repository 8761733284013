/**
 * Changing this would require changes to `initIndexedDB` so it cleans
 * up databases with the new name _and_ the old one, so maybe don't bother.
 */
export const databaseName = 'rtd';

/**
 * Any changes to the types in the database stores should case a version bump
 */
export const databaseVersion = 4;

export const maxQueriesCachedPerSource = 5;

/**
 * One month in seconds
 */
export const cachedQueryMaxAge = 2678400;
