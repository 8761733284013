import { Result } from '../../common';
import { TimeZone } from '../timezone';
import { RtdValue, TRtdValue } from './types';

export interface ValueStaticOptions<T, K> {
    readonly dataType: K;
    urlStringToValue(selection: string): Result<T>;
    /**
     * If undefined `stringToValue` will be used instead. Pairs with
     * `valueToEditString`.
     */
    readonly editStringToValue?: (query: string, timeZone: TimeZone) => Result<T>;
    /**
     * If undefined `stringToValue` will be used instead.
     */
    valueToUrlString(value: T): string;
    /**
     * Use this function if you want a localized
     * string to show the user.
     */
    valueToDisplayString(value: T, timeZone: TimeZone): string;
    /**
     * Used for text inputs. If not present default to `valueToUrlString`
     */
    readonly valueToEditString?: (value: T, timeZone: TimeZone) => string;
    tryNarrowValue(value: Exclude<TRtdValue, RtdValue.Null>): undefined | RtdValue.ArrayOrScalar<T, K>;
    /**
     * As of writing this is used as an _extra_ runtime check, which is why it
     * doesn't return error messages right now. If we start using this is a
     * known-fallible flow we should update it with user facing errors.
     */
    isValue(value: unknown): boolean;
}

export type ValueImpl<T, K = string> = Required<ValueStaticOptions<T, K>>;

export function buildValueImpl<T, K>(impl: ValueStaticOptions<T, K>): ValueImpl<T, K> {
    const editStringToValue = impl.editStringToValue ?? impl.urlStringToValue;
    const valueToEditString = impl.valueToEditString ?? impl.valueToUrlString;

    return {
        ...impl,
        editStringToValue(value, timeZone) {
            return editStringToValue(value.trim(), timeZone);
        },
        valueToEditString,
    };
}
