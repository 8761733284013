import { ITelemetryItem, ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

import { IResourceError } from '../../resource/errors/types';
import { rtdAppInsightsReactPlugin } from '../../domain/telemetry/setupTelemetry';
import { buildErrorMessage } from '../../components/errors/util';
import { APP_CONSTANTS, env } from '../../res';

import { ICommonSettableInfo } from '../domain/telemetry';
import { RtdAccount } from '../domain';

import { ITelemetryService } from './ITelemetryService';

export class TelemetryService implements ITelemetryService {
    private readonly appInsights: ApplicationInsights;
    private readonly userAccount?: RtdAccount;
    private readonly reactPlugin: ReactPlugin;

    private readonly supportEmailAddress: string;

    constructor(appInsights: ApplicationInsights, supportEmailAddress: string, userAccount: RtdAccount) {
        this.appInsights = appInsights;
        this.userAccount = userAccount;
        this.reactPlugin = rtdAppInsightsReactPlugin;
        this.supportEmailAddress = supportEmailAddress;

        // Create plugin to deal with rtd flags
        this.appInsights.addTelemetryInitializer(this.rtdTelemetryInitializer);

        // A user might be counted more than once if they access from a different machine or browser, or if they delete cookies.
        // Get a more accurate count by setting the authenticated user ID
        if (userAccount.oid) {
            this.appInsights.setAuthenticatedUserContext(userAccount.oid);
        }

        // Override default App Insights React Plugin to add rtd flag
        const originalReactTrackMetric = this.reactPlugin.trackMetric.bind(this.reactPlugin);

        this.reactPlugin.trackMetric = (metric, customProperties) =>
            originalReactTrackMetric(metric, this.buildInternalProperties(customProperties as Record<string, unknown>));
    }

    /**
     * Adds proper cloud.role and cloud.version for RTD. Should always run
     * after any host initializers
     * @param item The telemetry item being sent
     */
    private rtdTelemetryInitializer = (item: ITelemetryItem) => {
        let rtdProperties: Record<string, unknown> | undefined =
            item.baseData && item.baseData.properties && item.baseData.properties;

        // No properties or no rtd flag
        if (!rtdProperties || (!rtdProperties['rtd'] && item.data)) {
            rtdProperties = item.data as Record<string, unknown> | undefined;
        }

        // No properties or no rtd flag
        if (!rtdProperties || !rtdProperties['rtd']) {
            // Must not be RTD-specific event
            return;
        }

        // RTD telemetry
        delete rtdProperties['rtd'];

        if (!item.tags) {
            item.tags = [];
        }

        item.tags['ai.cloud.role'] = 'rtd';
        item.tags['ai.cloud.version'] = env.rtdVersion;

        // Populate user specific information
        if (this.userAccount) {
            const tenant = this.userAccount.tenantId;
            if (tenant === APP_CONSTANTS.tenants.microsoft) {
                // Only log OID if this user is internal to Microsoft
                rtdProperties['userId'] = this.userAccount.oid;
            }
            rtdProperties['tenantId'] = tenant;
        }
    };

    trackEvent = (name: string, common?: ICommonSettableInfo, additionalProperties?: Record<string, unknown>) =>
        this.trackEventInternal(name, {
            ...common,
            ...additionalProperties,
        });

    trackException = (
        exception: Error | undefined,
        common?: ICommonSettableInfo,
        additionalProperties?: Record<string, unknown>
    ) =>
        this.trackExceptionInternal(exception, {
            ...common,
            ...additionalProperties,
        });

    trackResourceError = (
        error: IResourceError<unknown>,
        common?: ICommonSettableInfo,
        additionalProperties?: Record<string, unknown>
    ) => {
        this.trackException(error.error, common, {
            type: error.type,
            statusCode: error.statusCode,
            message: buildErrorMessage(error),
            ...additionalProperties,
        });
    };

    withTelemetry = <T>(component: React.ComponentType<T>, componentName: string, className?: string) =>
        withAITracking<T>(this.reactPlugin, component, componentName, className);

    generateExceptionEmailUrl = (cid: string): string =>
        `mailto:${this.supportEmailAddress}?subject=Dashboard%20Error%20${cid}&body=Please%20describe%20your%20actions%20leading%20up%20to%20this%20error`;

    /**
     * Adds required internal RTD properties to RTD-specific telemetry events
     * @param properties The properties to augment
     */
    private buildInternalProperties = (properties?: Record<string, unknown>): Record<string, unknown> => ({
        rtd: true,
        refUri: window.location.host,
        ...properties,
    });

    /**
     * Logs a user action or other occurrence, applying internal RTD properties.
     * **WARNING**: You probably shouldn't use this method, but instead should use
     * `useTelemetry` or `trackEvent`
     * @param name The name of the event to track
     * @param properties Additional properties to record
     */
    private trackEventInternal = (name: string, properties?: Record<string, unknown>) =>
        this.appInsights.trackEvent({
            name,
            properties: this.buildInternalProperties(properties),
        });

    /**
     * Logs a caught exception, applying internal RTD properties.
     * **WARNING**: You probably shouldn't use this method, but instead should use
     * `useTelemetryException` or `trackException`
     * @param exception The caught exception
     * @param properties Additional properties to record
     */
    private trackExceptionInternal = (exception?: Error, properties?: Record<string, unknown>) =>
        this.appInsights.trackException({
            exception,
            properties: this.buildInternalProperties(properties),
        });
}
