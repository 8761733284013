export const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

export const isSameDay = (a: Date, b: Date): boolean =>
    a.getUTCDate() === b.getUTCDate() &&
    a.getUTCMonth() === b.getUTCMonth() &&
    a.getUTCFullYear() === b.getUTCFullYear();

export const dateToString = (date: Date): string => `${date.toISOString().split('.')[0]}Z`;

/**
 * Converts the provided date to a Unix timestamp in seconds
 */
export const dateToUnixTimestamp = (date: Date): number => Math.floor(date.getTime() / 1000);
