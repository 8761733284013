import * as React from 'react';
import { useState } from 'react';
import { FocusTrapCallout, DialogContent, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import moment from 'moment';

import { Duration, TDuration } from '../../../../domain';
import { APP_STRINGS } from '../../../../res';

import styles from '../styles.module.scss';

import { DateTimePicker } from './DateTimePicker';

const convertRange = (range: Duration.Dynamic): Duration.Fixed => {
    const end = new Date();
    const start = moment(end).subtract(range.count, range.unit).toDate();
    return { kind: 'fixed', end, start };
};

const removeRangeSeconds = (range: Duration.Fixed): Duration.Fixed => {
    const newRange: Duration.Fixed = {
        kind: 'fixed',
        start: new Date(range.start),
        end: new Date(range.end),
    };

    newRange.start.setSeconds(0);
    newRange.end.setSeconds(0);

    return newRange;
};

export const adjustDateIfStartIsGreaterThanEnd = (
    currentRange: Duration.Fixed,
    date: Date,
    key: 'start' | 'end'
): Duration.Fixed => {
    const difference = currentRange.end.getTime() - currentRange.start.getTime();
    const range = { ...currentRange, [key]: date };

    // If the start is being updated to a value greater than the current range's end
    if (key === 'start' && date >= currentRange.end) {
        const end = moment(date);
        // Add the difference between the dates to the end to maintain the range size
        end.add(difference, 'milliseconds');
        range.end = end.toDate();
        // If the end is being updated to a value smaller than the current range's start
    } else if (key === 'end' && date <= currentRange.start) {
        const start = moment(date);
        // Subtract the difference between the dates to the start to maintain the range size
        start.subtract(difference, 'milliseconds');
        range.start = start.toDate();
    }
    return range;
};

export interface ICustomDurationCalloutProps {
    onClose: () => void;
    onApply: (duration: Duration.Fixed) => void;
    currentDuration: TDuration;
    calloutTarget: Element;
}

export const FixedDurationPicker: React.FC<ICustomDurationCalloutProps> = ({
    currentDuration,
    onApply,
    onClose,
    calloutTarget,
}) => {
    const [range, setDuration] = useState<Duration.Fixed>(
        removeRangeSeconds(currentDuration.kind === 'fixed' ? currentDuration : convertRange(currentDuration))
    );

    return (
        /**
         * FocusTrapCallout is required here because it
         * ensures keyboard navigation does not focus parameter options dropdown while this is open.
         * Without it (and if we were using a plain Callout component), you would never
         * be able to tab into the Callout to select your datetime.
         */
        <FocusTrapCallout
            calloutWidth={335}
            ariaLabelledBy="duration-picker"
            ariaDescribedBy="duration-picker"
            gapSpace={0}
            target={calloutTarget}
            isBeakVisible={false}
            onDismiss={onClose}
            focusTrapProps={{
                disableFirstFocus: true,
            }}
        >
            <DialogContent
                className={styles.dialogContent}
                onDismiss={onClose}
                title={APP_STRINGS.duration.fixedDurationPicker.title}
            >
                <DateTimePicker
                    onChange={(date) => setDuration(adjustDateIfStartIsGreaterThanEnd(range, date, 'start'))}
                    value={range.start}
                    label={APP_STRINGS.duration.fixedDurationPicker.start}
                    datePickerAriaLabel={APP_STRINGS.duration.fixedDurationPicker.startDateAriaLabel}
                />
                <DateTimePicker
                    onChange={(date) => setDuration(adjustDateIfStartIsGreaterThanEnd(range, date, 'end'))}
                    value={range.end}
                    label={APP_STRINGS.duration.fixedDurationPicker.end}
                    datePickerAriaLabel={APP_STRINGS.duration.fixedDurationPicker.endDateAriaLabel}
                />
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => onApply(range)}
                        text={APP_STRINGS.duration.fixedDurationPicker.apply}
                    />
                    <DefaultButton onClick={onClose} text={APP_STRINGS.duration.fixedDurationPicker.cancel} />
                </DialogFooter>
            </DialogContent>
        </FocusTrapCallout>
    );
};
