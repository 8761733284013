import { APP_STRINGS } from '../../../res';

import { ETPManagedConfigKey, ETPManagedConfigKeyForType } from '../types';

const localAppStrings = APP_STRINGS.editTilePage.visualConfigSections;

export interface SegmentToggleInfo {
    optionKey: ETPManagedConfigKeyForType<boolean>;
    labels: { enabled: string; disabled: string };
    titleText: string;
    /**
     * Section toggle state will be inverted compared to the visual options
     * property state
     */
    invert?: boolean;
}

/**
 * Determines what keyed controls show up in each visual config section
 *
 * @property {} sectionDisabled When present enables section show/hide toggle
 */
export interface ManagedConfigSegment {
    readonly displayName: string;
    readonly segmentToggleInfo?: SegmentToggleInfo;
    readonly configKeys: readonly ETPManagedConfigKey[];
    readonly hideReset?: boolean;
}

export interface ManagedConfigSection {
    readonly displayName: string;
    readonly segments: readonly ManagedConfigSegment[];
}

const visualSegments: readonly ManagedConfigSegment[] = [
    {
        displayName: localAppStrings.generalTitle,
        configKeys: [
            'visualTypeVariant',
            'hideDataLabels',
            'hideTileTitle',
            'line__hidePinpointTooltips',
            'map__bubbleFormat',
            'multiStat__displayOrientation',
            'multiStat__textSize',
        ],
    },
    {
        displayName: localAppStrings.layoutTitle,
        hideReset: true,
        configKeys: ['multiStat__slot'],
    },
    {
        displayName: localAppStrings.urlLinksTitle,
        segmentToggleInfo: {
            optionKey: 'table__enableRenderLinks',
            titleText: localAppStrings.urlLinksToggleTitleText,
            labels: localAppStrings.sectionToggleOnOffLabels,
        },
        configKeys: ['table__renderLinksForColumns'],
    },
    {
        displayName: localAppStrings.dataTitle,
        configKeys: [
            'yColumns',
            'yColumn',
            'xColumn',
            'seriesColumns',
            'map__latitudeColumn',
            'map__longitudeColumn',
            'map__minBubbleSizeColumn',
            'heatMap__dataColumn',
            'multiStat__labelColumn',
            'multiStat__valueColumn',
        ],
    },
    {
        displayName: localAppStrings.colorRulesTitle,
        segmentToggleInfo: {
            optionKey: 'colorRulesDisabled',
            titleText: localAppStrings.colorRulesToggleTitleText,
            labels: localAppStrings.sectionToggleShowHideLabels,
            invert: true,
        },
        hideReset: true,
        configKeys: ['colorRules', 'colorStyle'],
    },
    {
        displayName: localAppStrings.legendTitle,
        segmentToggleInfo: {
            optionKey: 'hideLegend',
            titleText: localAppStrings.legendToggleTitleText,
            labels: localAppStrings.sectionToggleShowHideLabels,
            invert: true,
        },
        configKeys: ['legendLocation'],
    },
    {
        displayName: localAppStrings.yAxisTitle,
        configKeys: [
            'yColumnTitle',
            'yAxisRight',
            'yAxisMaximumValue',
            'yAxisMinimumValue',
            'yAxisScale',
            'horizontalLine',
            'multipleYAxes',
        ],
    },
    {
        displayName: localAppStrings.xAxisTitle,
        configKeys: ['xColumnTitle', 'xAxisScale', 'verticalLine'],
    },
];

const filterSegments: readonly ManagedConfigSegment[] = [
    {
        displayName: 'Cross Filter',
        hideReset: false,
        segmentToggleInfo: {
            optionKey: 'crossFilterDisabled',
            titleText: localAppStrings.legendToggleTitleText,
            labels: localAppStrings.sectionToggleOnOffLabels,
            invert: true,
        },
        configKeys: ['crossFilter'],
    },
];

export const tileOptionsLayout: readonly ManagedConfigSection[] = [
    {
        displayName: APP_STRINGS.editTilePage.visualConfig.formattingSectionTitle,
        segments: visualSegments,
    },
    {
        displayName: APP_STRINGS.editTilePage.visualConfig.interactionsSectionTitle,
        segments: filterSegments,
    },
];
