/* eslint-disable no-template-curly-in-string */
import type { RtdValue, ColorRule, DurationParamConfig } from '../domain';

const dataTypes: Record<RtdValue.BasicType | DurationParamConfig['kind'], string> = {
    string: 'String',
    int32: 'Int',
    float64: 'Real',
    bool: 'Bool',
    datetime: 'Datetime',
    int64: 'Long',
    decimal: 'Decimal',
    duration: 'Time range',
};

const colorRulesIconsLabels: Record<ColorRule.Icon, string> = {
    critical: 'Critical',
    warning: 'Warning',
    completed: 'Completed',
    tentative: 'Tentative',
    happy: 'Happy',
    sad: 'Sad',
    emojiNeutral: 'Emoji neutral',
    circle: 'Circle',
    circleFill: 'Circle fill',
    contrast: 'Contrast',
    square: 'Square',
    squareSolid: 'Square solid',
    diamond: 'Diamond solid',
    octagon: 'Octagon',
    cloud: 'Cloud',
    tvMonitor: 'TV monitor',
    globe: 'Globe',
    strength: 'Strength',
    eatDrink: 'Eat/drink',
    cocktail: 'Cocktail',
    apple: 'Apple',
    repair: 'Repair',
    circleDollar: 'Circle dollar',
    rate: 'Rate',
    arrowUp: 'Up arrow',
    arrowDown: 'Down arrow',
    ferry: 'Ferry',
    car: 'Car',
    plane: 'Plane',
    baseball: 'Baseball',
    soccer: 'Soccer',
    basketball: 'Basketball',
    football: 'Football',
    tennis: 'Tennis',
    golf: 'Golf',
    world: 'World',
    heart: 'Heart',
    insights: 'Insights',
};

const colorRulesInferLabels: Record<ColorRule.Color, string> = {
    red: 'Critical',
    yellow: 'Warning',
    green: 'Healthy',
    blue: 'Normal',
};

const colorRulesColorLabels: Record<ColorRule.Color, string> = {
    red: 'Red',
    yellow: 'Yellow',
    green: 'Green',
    blue: 'Blue',
};

const basicValueDataDisplayText: Record<RtdValue.BasicType, string> = {
    string: 'String',
    int32: 'Int',
    int64: 'Long',
    float64: 'Double',
    decimal: 'Decimal',
    bool: 'Bool',
    datetime: 'Datetime',
};

/**
 * Avoid adding arrays to this, because we can't guarantee the length with be
 * correct. Tuples cannot be used because typescript won't infer tuple values
 * from json.
 */
export const APP_STRINGS = {
    error: {
        correlationIdLabelText: 'CorrelationID',
        unidentifiedErrorMessage: 'Unknown error occurred',
        failedToLoadDashboard: 'Failed to load dashboard',
    },
    domain: {
        value: {
            dataTypes,
        },
        dashboard: {
            defaultPageName: 'Page 1',
        },
        parameter: {
            parameterTypes: {
                scalar: 'SingleSelection',
                'array-null': 'Multiple Selection',
                freetext: 'Free text',
                duration: 'Time Range',
            },
            selection: {
                all: 'Select all',
                allTitle: 'All',
                none: 'None',
                noResults: 'No results',
            },
            errors: {
                notNullable: '"All" cannot be assigned to this',
                singleSelectionOnly: 'Array values cannot be assigned to this',
                crossFilter: {
                    applicationErrorPrefix: 'Error applying value',
                    corruptedValueError: 'Value is corrupted',
                    creationErrorPrefix: 'Error creating value',
                    parameterNotFound: 'Unable to apply value: parameter not found',
                },
                unableToResolveVariableNames: {
                    title: 'Missing parameters',
                    body: 'Missing parameters for expected variables: ${variableNames}. Query was saved while using these variables, which can no longer be found.',
                },
                duration: {
                    dynamicRangeUrlParseError: 'One or more dates are invalid',
                    failedToParseTimeAmount: 'Failed to parse time amount',

                    parseUrlMissingVariable: 'Missing variable',
                    unexpectedValue: 'Unexpected value of type "${type}"',
                },
                basic: {
                    unexpectedType: 'Unexpected type',
                    unexpectedTimeRange: 'Unexpected time range value',
                },
                restrictedString: 'is restricted. Please use a different value.',
                query: {
                    failedToResolveDataSource: 'Failed to resolve data source',
                    queryFailedPrefix: 'Parameter options query failed. ',
                    queryNotDefined: 'Parameter options query not defined.',
                    valueColumnMissing: 'Parameter options query unable to find configured value column',
                    labelColumnMissing: 'Parameter options query unable to find configured label column',

                    wrongColumnType: {
                        0: 'Parameter value column is of type',
                        1: 'expected type ',
                    },
                    mustReturnAtLeastOneColumn: 'Parameter options query expected to return at least one column.',
                },
                failedToParseDatetime: 'Must be in ISO 8601 format',
                error: 'Failed to parse',
                allReserved: 'Parameter value "all" is reserved for representing "select all"',
                boolError: 'Must be "true" or "false"',
                decimal: {
                    tooLarge: 'Too large, max decimal value is ',
                    tooSmall: 'Too small, minimum decimal value is ',
                },
                int32: {
                    tooLarge: 'Too large, max int value is ',
                    tooSmall: 'Too small, minimum int value is ',
                },
                int64: {
                    tooLarge: 'Too large, max long value is ',
                    tooSmall: 'Too small, minimum long value is ',
                },
            },
        },
    },
    breadcrumbs: {
        catalog: 'All dashboards',
        editableItem: 'Breadcrumb text field',
    },
    components: {
        tile: {
            refreshButtonTitle: 'Refresh tile',
            menuButtonTitle: 'Open tile menu',
            infoCalloutButton: 'Additional information',
            infoCallout: {
                conditionalFormattingSectionHeader: 'ConditionalFormatting',
                resultCountsSectionHeader: 'Data results',
                resultCountText: { start: 'Showing', end: 'results' },
            },
            tileSingular: 'tile',
            tilePlural: 'tiles',
        },
        rootErrorBoundary: {
            title: 'Something went wrong',
            subText: 'This incident has been reported.',
            cidFailed: 'Please report this issue to the Dashboards team.',
        },
    },
    queryEditing: {
        dataSourceDropdown: {
            ariaLabel: 'Data source dropdown',
            noSelectionTitle: 'Select a data source',
        },
    },
    previewTagText: 'Preview',
    addComponent: {
        addQuery: 'Add tile',
        addQueryDescription: 'Create a tile from a new query and visual',
        addMarkdown: 'Add text',
    },
    authentication: {
        msaSignedIn:
            'You are currently signed in with a personal Microsoft account, which is not currently supported in Azure Data Explorer Dashboards. Please sign in using a Microsoft Work or School account.',
    },
    manage: {
        dataSource: 'Data sources',
        parameter: 'Parameters',
        permissions: 'Manage permissions',
        additionalActionsButtonTitle: 'Additional options',
    },
    utilButtons: {
        add: 'Add',
        apply: 'Apply',
        back: 'Back',
        cancel: 'Cancel',
        connect: 'Connect',
        copyLink: 'Copy link',
        copyLinkSuccess: 'Link copied to clipboard',
        create: 'Create',
        delete: 'Delete',
        disable: 'Disabled',
        discard: 'Discard',
        discardChanges: 'Discard changes',
        done: 'Done',
        edit: 'Edit',
        enable: 'Enabled',
        export: 'Export to JSON',
        forget: 'Forget',
        ok: 'OK',
        refresh: 'Refresh',
        reset: 'Reset',
        run: 'Run',
        save: 'Save',
        saveChanges: 'Save changes',
        saveCopy: 'Save as copy',
        share: 'Share',
        update: 'Update',
        learnMore: 'Learn more',
        descending: 'descending',
        ascending: 'ascending',
    },
    breadcrumb: {
        dashboards: 'Dashboards',
    },
    catalogPage: {
        commandBar: {
            addDashboard: {
                text: 'New dashboard',
                splitButtonAriaLabel: 'Sample dashboards',
            },
        },
        filterDashboardsInput: {
            searchPlaceholder: 'Search your dashboards...',
            ariaLabel: 'Filter dashboards',
            filterCompleteNarratorMessage: 'results found',
        },
        dashboardsTableColumns: {
            empty: '--',
            name: {
                label: 'Name',
                ariaLabel: 'Sort dashboards by name',
            },
            lastAccessed: {
                label: 'Last accessed',
                ariaLabel: 'Sort dashboards by last accessed',
            },
            createdBy: {
                label: 'Created by',
                ariaLabel: 'Sort dashboards by creator',
                noCreatedBySavedError: 'Creator not saved',
                fetchDefaultError: 'Name could not be resolved',
            },
            createdAt: {
                label: 'Created date',
                ariaLabel: 'Sort dashboards by created date',
                tooltip: 'Created date not set',
            },
            actions: {
                ariaLabel: 'Actions',
            },
        },
        favoriteDashboardButtonTitle: {
            favorite: 'Add to favorites',
            unFavorite: 'Remove from favorites',
        },
        pivots: {
            all: 'All',
            favorites: 'Favorites',
            recents: 'Recents',
        },
        additionalDashboardActionsButtonTitle: 'Additional options',
        lastAccessedTooltipDateFormat: 'ddd, MMM Do YYYY, h:mm:ss a',
        openDashboardButtonTitle: 'Open',
        lastAccessedAriaLabel: 'Last accessed',
        deleteDashboard: 'Delete dashboard',
        duplicateDashboard: 'Duplicate dashboard',
        editPermissions: 'Manage permissions',
        forgetDashboard: 'Forget dashboard',
        noDashboards: 'No dashboards',
        callToAction: 'Create new dashboard',
        callToActionSample: 'Create sample dashboard',
        callToActionSampleAI: 'Create sample AI dashboard',
        errorLoadingDashboardsTitle: 'Error loading dashboards',
    },
    duration: {
        htmlTitle: 'Duration',
        label: 'Time range',
        bin: 'Bin/Bucket size',
        timeInterval: 'Time interval',
        timeUnit: 'Time unit',
        customDropdownOption: 'Custom time range',
        fixedRangeTimePickerTitle: 'Select time',
        formatDynamic: { last: 'Last' },
        formatCompressedSameDate: { at: 'at' },
        fixedDurationPicker: {
            end: 'End time',
            start: 'Start time',
            startDateAriaLabel: 'Start date',
            endDateAriaLabel: 'End date',
            title: 'Custom time range',
            apply: 'Apply',
            cancel: 'Cancel',
        },
        dynamicOptions: {
            '15minutes': 'Last 15 mins',
            '30minutes': 'Last 30 mins',
            '1hour': 'Last 1 hr',
            '3hours': 'Last 3 hrs',
            '6hours': 'Last 6 hrs',
            '12hours': 'Last 12 hrs',
            '24hours': 'Last 24 hrs',
            '2days': 'Last 2 days',
            '7days': 'Last 7 days',
            '14days': 'Last 14 days',
            '30days': 'Last 30 days',
            '60days': 'Last 60 days',
            '90days': 'Last 90 days',
            '180days': 'Last 180 days',
            '365days': 'Last 365 days',
            '730days': 'Last 730 days',
            '1095days': 'Last 1095 days',
        },
    },
    forms: {
        autoRefresh: {
            title: 'Auto refresh',
            minInterval: 'Minimum time interval',
            default: 'Default refresh rate',
            noInterval: 'Allow all refresh intervals',
            minIntervalInfo: 'Prevents users from setting an auto refresh interval smaller than the provided value',
            closePanelAriaLabel: 'Close auto refresh',
            intervalAriaLabel: 'Auto refresh interval',
        },
        dataSource: {
            title: 'Data sources',
            addTitle: 'Create new data source',
            editTitle: 'Edit data source',
            clusterUri: 'Cluster URI',
            new: 'New data source',
            name: 'Data source name',
            namePlaceholder: 'New data source name',
            db: 'Database',
            noSelectionTitle: 'Select a database',
            type: 'Data source type',
            errors: {
                duplicateName: 'Data source name already exists',
                noName: 'Data source name is required',
                noResults: 'No entries found. Check that you have permissions to access this resource',
                incompleteConnectionString: 'Connection string is incomplete',
                noDatabase: 'Database is required',
            },
            aria: {
                closePanelLabel: 'Close data sources',
                backPanelLabel: 'Back to data sources',
                editButtonLabel: 'Edit data source',
                deleteButtonLabel: 'Delete data source',
            },
        },
        newDashboard: {
            name: 'Dashboard name',
            namePlaceholder: 'New dashboard name',
        },
        parameter: {
            title: 'Parameters',
            unableToDetermineVisualCount: '-',
            previewMenu: {
                ariaLabel: 'Open parameter options',
                pin: 'Pin to dashboard',
                unpin: 'Unpin from dashboard',
                duplicate: 'Duplicate parameter',
            },
            new: 'New parameter',
            pinned: 'Pinned',
            closePanelAriaLabel: 'Close parameters',
            visualSingular: 'visual',
            visualPlural: 'visuals',
            filterSingular: 'filter',
            filterPlural: 'filters',
            doesNotExistPrefix: `Column "`,
            doesNotExistSuffix: '" does not exist in the current result set',
            save: {
                newTitle: 'Create parameter with warnings',
                editTitle: 'Apply changes with warnings',
                columnPrefix: 'The selections for the',
                and: 'and',
                valueColumn: '"Value"',
                labelColumn: '"Display name"',
                columnSingular: 'column',
                columnPlural: 'columns',
                doSingular: 'do',
                doPlural: 'does',
                columnSuffix: 'not exist in the result set.',
                incompleteQuery:
                    'This parameter query has not successfully run, and therefore we cannot verify your selections.',
                confirmationPrefix: 'Are you sure you want to',
                newConfirmationSuffix: 'create this parameter?',
                editConfirmationSuffix: 'apply changes to this parameter?',
            },
            delete: {
                parameterPrefix: 'This parameter (',
                parameterSuffix: ') is being used in',
                and: 'and',
                title: 'Delete parameter',
                parameterSingular: 'parameter',
                parameterPlural: 'parameters',
                sideEffectExplanation: 'Deleting this parameter will affect the existing behavior of those queries.',
                confirmationMessage: 'Are you sure you want to delete this parameter?',
            },
        },
        permissions: {
            addNew: 'Add new members',
            editors: 'Editors',
            nameOrEmail: 'Name or email',
            email: 'Email',
            group: 'Group',
            invalidInput: 'Invalid email input: ',
            loadingPermissions: 'Loading permissions...',
            members: 'Members',
            membersTooltip:
                'Add users that can edit or view this dashboard.\nAdd multiple users by UPN like alias@microsoft.com, separating each value by commas.\n\nEditors are automatically viewers by default.',
            messages: {
                addReminder:
                    'The dashboard has not yet been shared with the newly added user(s). Notify them by copying the link and sharing it with them directly.',
                duplicateAddError: {
                    alreadyMember: 'is already a member',
                    alreadyMembers: 'are already members',
                    ofThisDashboard: 'of this dashboard.',
                },
                permissionError: 'There was a problem saving membership changes',
                fetchError:
                    'There was a problem retrieving the list of dashboard members. Please try refreshing the list.',
            },
            name: 'Name',
            noMembers: 'No members found.',
            permission: 'Permission',
            searchMembersPlaceholder: 'Search all members',
            title: 'Dashboard permissions',
            type: 'Type',
            user: 'User',
            viewers: 'Viewers',
            permissionTypeTitleText: 'Permission level',
            searchAvailablePeopleInputTitle: 'Search people',
            closePermissionPanelButtonAriaLabel: 'Close permissions panel',
        },
        discardChanges: {
            title: 'Discard changes',
            subtext: 'Are you sure you want to discard these changes?',
        },
        deleteDashboard: {
            title: 'Delete dashboard',
            subText: 'Are you sure?',
            errorTile: 'Failed to delete dashboard',
        },
        createDashboard: {
            newTitle: 'New dashboard',
            duplicateTitle: 'Duplicate dashboard',
        },
        forgetDashboard: {
            title: 'Forget dashboard',
            subText: 'Are you sure?',
            acceptText: 'Forget',
        },
        closePermissionsPanel: {
            title: 'Close dashboard permissions',
            subText: 'Closing while an operation is still running may result in the task not being completed.',
            closeButton: 'Close permissions',
        },
    },
    dashboardPage: {
        warnings: {
            saveWithMigrationWarningsDialogTitle:
                'Data was lost when dashboard was loaded. Are you sure you wish to save?',
        },
        errors: {
            dashboardNotSaved: 'Error: Dashboard not saved',
        },
        tileMenu: {
            rename: 'Rename tile',
            duplicate: 'Duplicate tile',
            delete: 'Delete tile',
            edit: 'Edit tile',
            explore: 'Explore query',
            move: 'Move tile',
            pageMoveDisabledAlreadyPresentTitle: 'Tile is already on this page',
        },
        noDashboards: 'No tiles are defined for this page',
        unableToResolveDataSourceError: 'Failed to resolve data source',
        pageContent: {
            titleInputTitle: 'Dashboard name',
        },
        dashboard: 'Dashboard',
        maxLengthError: { start: 'Max length of', end: 'characters reached' },
        pagesNav: {
            title: 'Pages',
            addPageButtonText: 'Add page',
            newPageName: 'Page',
            renamePageButtonText: 'Rename page',
            reorderPageButtonText: 'Move to',
            reorderPageBottomOptionText: 'Bottom',
            deletePageButtonText: 'Delete page',
            reorderPagePageOptionPrefix: 'Above page',
            deletePagePrompt: {
                title: 'Are you sure you wish to delete page',
                subText1: 'This page has',
                subtext2: 'that will also be deleted.',
            },
            morePageActions: 'More page actions',
        },
        viewAllDashboards: 'View All Dashboards',
        dashboardLoadErrorMessage: {
            title: 'Error loading dashboard',
            clientError:
                'This dashboard could not be loaded. You either do not have permission to access it or it does not exist.',
            serverError: 'Unexpected error encountered loading this dashboard.',
        },
    },
    edits: {
        unsavedChanges: 'You have unsaved changes. Are you sure you want to leave?',
    },
    editTilePage: {
        loadingErrorDialog: {
            title: 'Error loading tile',
            dashboardNavButton: 'Back to dashboard',
        },
        resizePaneAria: 'Resize pane',
        pageContent: {
            tileTitleInputTitle: 'Tile title',
            titleUnknown: 'Unknown',
            doneEditing: 'Apply changes',
        },
        newTileTitle: 'New tile',
        tileNotFoundError: 'The tile could not be found',
        parameterNotApplied:
            'Because the query text is dirty, parameter changes will not be applied until query is run again',
        dataSourceNotApplied:
            'Because the query text is dirty, the change in source will not be applied until query is run again',
        dataSource: 'Scope',
        noSelectionTitle: 'Select a data source',
        targetParameter: 'Target parameter',
        querySamples: {
            basicSampleQuery: '// Please enter your KQL query (Example):\n// TableName | take 10\n',
            sampleMarkdown: 'Enter text in [markdown](https://github.github.com/gfm/) format\n',
            prefix: 'Please enter your KQL query (Example):',
            tableName: '<table name>',
            queryBuilding: {
                DurationParamComment: 'Time range filtering',
                singleSelectParameterComment: 'Single selection filtering',
                multiSelectorParameterComment: 'Multiple selection filtering',
                freeTextParameterComment: 'Freetext filtering',
                datetimeColumnPlaceholder: 'datetime column',
                columnNamePlaceholder: 'column name',
            },
        },
        schemaContext: {
            loadingMessage: 'Loading query schema',
            contextNotProvidedError: 'Unexpected error initalizing schema',
            missingDataSourceError: 'Unable to load query schema, no data source selected',
            errorInitializingSchema: {
                normal: 'Error initalizing schema',
                unexpected: 'Unexpected error initalizing schema',
            },
            errorUpdatingSchema: {
                normal: 'Error updating schema',
                unexpected: 'Unexpected error updating schema',
            },
        },
        visualConfig: {
            colorRules: {
                disableColorRulesToggleLabel: 'Disable conditional formatting',
                addRuleButtonText: 'Add rule',
                conditionOperatorLabel: 'Operator',
                conditionValueLabel: 'Value',
                highlightColorDropdownLabel: 'Highlight color',
                iconDropdownLabel: 'Icon',
                colorRuleCalloutTitle: 'Conditional formatting rule',
                noConditionOperatorText: 'None',
                noIconSelectedText: 'None',
                columnDropdownLabel: 'Column',
                ruleRowInferColumn: 'Infer column',
                expressionArgumentText: 'Value',
                ruleLabelInputLabel: 'Text label',
                textIndicatorInputLabel: 'Tag label',
                iconIndicatorLabelInputLabel: 'Icon label',
                indicatorTypeDropdownLabel: 'Accessible indicator',
                indicatorTypeDropdownOptionsText: {
                    none: 'None',
                    icon: 'Icon',
                    text: 'Tag',
                },
            },
            errorBannerExpandButtonTitle: 'See more',
            map__minBubbleSizeColumnDropdownLabel: 'Min bubble size column',
            map__latitudeColumnDropdownLabel: 'Latitude column',
            map__longitudeColumnDropdownLabel: 'Longitude column',
            multiStat__displayOrientation: {
                label: 'Display orientation',
                vertical: 'Vertical',
                horizontal: 'Horizontal',
            },
            multiStat__textSize: {
                label: 'Text size',
                auto: 'Recommended',
                small: 'Small',
                large: 'Large',
            },
            multiStat__labelColumnLabel: 'Label column',
            multiStat__valueColumnLabel: 'Value column',
            multiState__slot: {
                labelText: 'Slot configuration',
                formatSlot: {
                    rows: 'rows',
                    columns: 'columns',
                    slot: 'slot',
                    slotPlural: 'slots',
                },
                calloutTitle: 'Slot',
            },
            heatMap__dataColumnLabel: 'Data column',
            table__renderLinksForColumnsOptionLabel: 'Apply on columns',
            textInputInferPlaceholder: 'Infer',
            dropdownInputInferOption: 'Infer',
            searchPlaceholder: 'Search options...',
            searchAriaLabel: 'Visual options search',
            formattingSectionTitle: 'Visual formatting',
            interactionsSectionTitle: 'Interactions',
            panelTitle: 'Tile options',
            openButtonAria: 'Open visual pane',
            closeButtonAria: 'Close visual pane',
            noVisualSelected: 'You can format the visual only from the visual tab',
            yAxisPosition: {
                label: 'Position',
                rightOptionText: 'Right',
                leftOptionText: 'Left',
            },
            visualTypeVariableDropdownLabel: 'Visual format',
            hideLegendCheckboxLabel: 'Hide legend',
            batchedCheckboxLabel: 'Enable query batching',
            fullFetchCheckboxLabel: 'Enable full fetch of data for queries without timestamp (running value)',
            hideDataLabelsCheckboxLabel: 'Hide data labels',
            hideTileTitleCheckboxLabel: 'Hide tile title',
            line__hidePinpointTooltips: 'Hide pinpoint tooltips',
            xColumnTitleInputLabel: 'Label',
            yColumnTitleInputLabel: 'Label',
            visualType: 'Visual type',
            titleOption: 'Title',
            axisScale: {
                label: { xAxisScale: 'X axis scale', yAxisScale: 'Y axis scale' },
                linear: 'Linear',
                log: 'Logarithmic ',
            },
            noColumnsAvailableMessage: 'No columns available to select',
            xColumnDropdownLabel: 'X column',
            yColumnsDropdownLabel: 'Y columns',
            yColumnDropdownLabel: 'Y column',
            seriesColumnsDropdownLabel: 'Series columns',
            yAxisMinimumValue: {
                inputLabel: 'Minimum value',
            },
            yAxisMaximumValue: {
                inputLabel: 'Maximum value',
            },
            multipleYAxes: {
                addYAxisButtonText: 'Add Y axis',
                addYAxisDisabledTooltip: 'Number of Y axes cannot exceed the number of selected Y columns',
                mainAxis: 'Main axis',
                columnDropdownLabel: 'Column',
                yAxisLabelInputLabel: 'Label',
                yAxisRowInferColumn: 'Infer column',
                multipleYAxesCalloutTitle: 'Edit Y column',
                yColumnDropdownInfo: 'Each Y axis column should be also selected in the Data section',
                yColumnInvalidSelection: 'This column is no longer selected in the Data section',
            },
            horizontalLineInputLabel: 'Horizontal line value',
            verticalLineInputLabel: 'Vertical line value',
            collapseAllButtonText: 'Collapse all',
            collapseAllButtonTitle: 'Collapse all visual options sections',
            legendLocationDropdown: {
                label: 'Legend location',
                leftOption: 'Left',
                rightOption: 'Right',
            },
            colorStyleDropdown: {
                label: 'Color style',
                lightOption: 'Light',
                boldOption: 'Bold',
            },
            map__bubbleFormatDropdown: {
                label: 'Bubble format',
                dotOption: 'Dot',
                bubbleOption: 'Bubble',
                heatmapOption: 'Heat map',
                pieChartOption: 'Pie chart',
            },
            tileName: {
                label: 'Tile name',
                placeholder: 'Insert title',
            },
        },
        configurationList: {
            addItemButtonText: 'Add item',
        },
        visualConfigSections: {
            generalTitle: 'General',
            layoutTitle: 'Layout',
            dataTitle: 'Data',
            legendTitle: 'Legend',
            legendToggleTitleText: 'Show/hide legend',
            yAxisTitle: 'Y Axis',
            xAxisTitle: 'X Axis',
            colorRulesTitle: 'Conditional formatting',
            colorRulesToggleTitleText: 'Enable/Disable conditional formatting',
            urlLinksTitle: 'Render links',
            urlLinksToggleTitleText: 'Attempt to render text in columns as links',
            sectionToggleShowHideLabels: { enabled: 'Show', disabled: 'Hide' },
            sectionToggleOnOffLabels: { enabled: 'On', disabled: 'Off' },
        },
        queryState: {
            done: 'Done',
            loading: 'Running',
            error: 'Error',
            rowCount: 'records',
            formatElapsedTimeSecondsUnit: 's',
        },
        addVisual: 'Add visual',
        expandVisualToggleLabel: 'Expand preview',
        noQueryMessage: {
            title: "You haven't run a query yet",
            body: 'Run a query to create a new set of results and data visualizations',
        },
        missingDataSourceMessage: { title: 'No data source selected' },
        noDataSource: {
            title: 'You have no data sources connected',
            body: 'Use the scope dropdown above to create a new data source, or select an existing one',
            addDataSource: 'Data source',
            graphicAltText: 'No data sources',
        },
    },
    fabric: {
        graph: {
            suggestionsHeader: 'Suggested People',
            suggestionsAvailableAlert: 'People Picker Suggestions available',
            contactsHeader: 'Suggested Contacts',
            noResults: 'No results found',
            removePersonAriaLabel: 'Remove person',
        },
    },
    query: {
        errors: {
            invalidDataSource: 'Invalid data source configuration',
            invalidQuery: 'Invalid query text',
            missingScope: 'Unable to find scope with id',
            noScopes: 'No scopes available',
        },
    },
    validation: {
        noInput: 'Invalid input',
    },
    dashboardLoading: {
        savingDashboard: 'Saving dashboard...',
        deletingDashboard: 'Deleting dashboard...',
        loadingDashboard: 'Loading dashboard...',
        forgetDashboard: 'Forgetting dashboard...',
    },
    loading: 'Loading...',
    noEditPermissionsError: 'You do not have permissions to edit this dashboard.',
    initializeApp: {
        error: 'Error: Failed to initialize',
    },
    time: {
        formatAsOf: 'As of',
        never: 'never',
        now: 'now',
    },
    hostLib: {
        queryImporter: {
            pin: 'Pin',
            name: 'Query name',
            titleTitlePlaceholder: 'New query name',
            existingDashboard: 'Use existing',
            newDashboard: 'Create new',
            selectDashboard: 'Select dashboard',
            dashboardNoSelection: 'Select a dashboard',
            viewDashboardAfterCreation: 'View dashboard after creation',
            dedupeDataSource: 'Use existing data source if possible',
            errors: {
                queryNameMissing: 'Query name is required',
                dashboardNameMissing: 'Dashboard name is required',
                noDashboardSelected: 'Please select a dashboard',
                loadingSelectedDashboard: 'Checking for data source name conflicts',
            },
        },
    },
    visuals: {
        tileSmallerThanRequiredSizeError: {
            start: 'Current tile size',
            end: 'is smaller than the minimum supported tile size',
        },
        unknownVisualTypeMessage: { start: 'Visual type', end: 'is not supported' },
        resultsTabTitle: 'Results',
        visualTabTitle: 'Visual',
        colorRulesIconsLabels,
        colorRulesInferLabels,
        colorRulesColorLabels,
    },
    editParameterPage: {
        basicValueDataDisplayText,
        addTitle: 'Add parameter',
        editTitle: 'Edit parameter',
        preventNavigationWarning: 'Parameter has unsaved changes. Are you sure you want to leave?',
        buttons: {
            addOption: 'Add value',
            addQuery: 'Add query',
            editQuery: 'Edit query',
            autoPin: 'Pin as dashboard filter',
        },
        aria: {
            deleteValueLabel: 'Delete value',
            formatValueInputLabel: 'Value for option',
            formatDisplayNameInputLabel: 'Parameter display name for option',
            closeMessageBarLabel: 'Close message bar',
        },
        selection: {
            noDefault: 'No default value',
            emptyDefault: 'Select all',
        },
        dataSourceType: {
            static: 'Fixed values',
            query: 'Query',
        },
        dataType: 'Data type',
        selectionType: 'Parameter type',
        value: 'Value',
        variableName: 'Variable name',
        startVariableName: 'Start time variable name',
        endVariableName: 'End time variable name',
        addNoneOptionLabelTop: 'Select all',
        addNoneOptionLabelRight: 'Add empty "Select all" value',
        addNoneOptionTooltipText:
            'Adds null, or an empty string, to the available parameter options. Appears as "Select all" in options list.',
        addNoneOptionSampleDescription:
            'Adds an empty value to your possible options. This will need to be handled as a special case in consuming queries.',
        recordTicker: {
            noRecords: 'No records',
            missingDataSource: 'Missing data source',
            result: { start: 'Showing', middle: 'out of', end: 'records' },
        },
        displayName: 'Label',
        defaultValue: 'Default value',
        errors: {
            noDefaultValue: 'No default value provided',
            duplicateDisplayName: 'Parameter display name already exists',
            noVariableName: 'Parameter variable name is required',
            duplicateVariableName: 'Parameter variable name already exists',
            invalidCharacterVariableName: 'Parameter variable names may only contain alphanumeric characters and "_.-"',
            duplicateValue: 'Parameter value already exists',
        },
        queryResultsLabel: 'Query results',
        dataSourceTypeDropdownLabel: 'Source',
        labelQueryColumnDropdownPlaceholder: 'Defaults to value selection',
        labelQueryColumnDropdownClearSelectionOption: 'Match value selection',
        valueQueryColumnDropdownLabel: 'Value column',
        labelQueryColumnDropdownLabel: 'Label column',
        queryDataSourceDropdownLabel: 'Data source',
        noQueryResultColumnSelectedPlaceholder: 'Select a column',
        queryPanel: {
            title: 'Add a query',
            completeButtonText: 'Done',
            cancelButtonText: 'Cancel',
            noQueryMessage: "You haven't run a query yet",
            confirmCloseDialog: {
                title: 'Query has unsaved changes',
                subText: 'Changes will be lost if editor is closed',
                agree: 'Ok',
                cancel: 'Cancel',
            },
        },
    },
    parameterDropdown: {
        loading: 'Loading values...',
        queryError:
            'There was an error retrieving parameter values. Please try to refresh the dashboard or contact the dashboard owner.',
        searchPlaceholder: 'Search options',
        searchAriaLabel: 'Search options',
        formatInputPlaceholder: 'Enter filter value',
        recent: 'Recent',
        noRecents: 'No recents',
        filteredBy: 'Filtered by',
    },
    migration: {
        errors: {
            missingMigration:
                'Missing migration for dashboard version ${version}. Your dashboards version may be out of date, try refreshing the page for a newer client version.\n\nRequired version: ${targetVersion}\nReceived version: ${receivedVersion}',
            unexpectedCrash: 'Unexpected crash while loading dashboard. Dashboard may be corrupted.',
        },
        warnings: {
            onLoadWithWarningsTitle:
                'Dashboard is of a newer version than is supported. Some data was lost while loading.',
        },
        tileV7: {
            removedCrossFilterConfig: 'Removed cross filter config from: ',
        },
    },
    autoRefresh: {
        disable: 'Disable auto refresh',
        off: 'Off',
    },
    dataSourceTypes: {
        kusto: 'Kusto',
        loganalytics: 'Log Analytics',
    },
    aria: {
        dropdownListLabel: 'Dropdown options',
    },
};
