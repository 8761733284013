import { assertNever } from 'office-ui-fabric-react';

import { APP_CONSTANTS } from '../../../res';
import { err } from '../../../common';
import { BasicParam, tagScalar, RtdValue, tagArray, scalarValueAsGeneric, arrValueAsGeneric } from '../../../domain';

import type { EppUnionOptions, EppBasicParamOptions, EppParameterType, EppReducerState } from './reducer';
import { initialStateUnion } from './constants';
import { staticOption } from './util';

export function convertQueryDefaultValueToSelectionType(
    selectionKind: BasicParam.DropdownSelectionKind,
    value: undefined | RtdValue.UBasic
): undefined | RtdValue.UBasic {
    if (!value) {
        return undefined;
    }
    if (value.kind === 'null') {
        if (selectionKind === 'scalar') {
            return undefined;
        }
        return value;
    }

    switch (value.tag) {
        case 'bool':
            return convertQueryDefaultValueToSelectionTypeGeneric(selectionKind, value);
        case 'datetime':
            return convertQueryDefaultValueToSelectionTypeGeneric(selectionKind, value);
        case 'decimal':
            return convertQueryDefaultValueToSelectionTypeGeneric(selectionKind, value);
        case 'float64':
            return convertQueryDefaultValueToSelectionTypeGeneric(selectionKind, value);
        case 'int32':
            return convertQueryDefaultValueToSelectionTypeGeneric(selectionKind, value);
        case 'int64':
            return convertQueryDefaultValueToSelectionTypeGeneric(selectionKind, value);
        case 'string':
            return convertQueryDefaultValueToSelectionTypeGeneric(selectionKind, value);
    }
}

function convertQueryDefaultValueToSelectionTypeGeneric<T, K>(
    selectionKind: BasicParam.DropdownSelectionKind,
    value: RtdValue.ArrayOrScalar<T, K>
): undefined | RtdValue.Value<T, K> {
    switch (selectionKind) {
        case 'array-null':
            if (value.kind === 'scalar') {
                return tagArray(value.tag, [value.value]);
            }
            return value;
        case 'scalar-null':
            if (value.kind === 'array') {
                return tagScalar(value.tag, value.values[0]);
            }
            return value;
        case 'scalar':
            switch (value.kind) {
                case 'scalar':
                    return value;
                case 'array':
                    return tagScalar(value.tag, value.values[0]);
                default:
                    assertNever(value);
            }
            return value;
        default:
            assertNever(selectionKind);
    }
}

export function setParameterType(paramType: EppParameterType, prevState: EppReducerState): EppUnionOptions {
    if (paramType === 'duration') {
        if (prevState.union.kind === 'duration') {
            return prevState.union;
        }
        return {
            kind: 'duration',
            defaultValue: APP_CONSTANTS.defaultPickerDuration,
        };
    }

    // Can't convert from duration to primitive, so use default values instead.
    const fromUnion = prevState.union.kind === 'duration' ? initialStateUnion : prevState.union;

    switch (paramType) {
        case 'freetext': {
            if (fromUnion.union.kind === 'freetext') {
                return prevState.union;
            }
            if (!APP_CONSTANTS.parameter.selectionTypes.freetext.includes(fromUnion.dataType)) {
                return {
                    ...fromUnion,
                    dataType: 'string',
                    union: { kind: 'freetext', defaultValueEnteredText: '' },
                };
            }

            let defaultValueEnteredText: string;

            if (fromUnion.union.kind === 'static') {
                defaultValueEnteredText = [...fromUnion.union.options][0][1].value;
            } else if (fromUnion.union.defaultValue && fromUnion.union.defaultValue.kind !== 'null') {
                const tagged = fromUnion.union.defaultValue;
                if (tagged.kind === 'scalar') {
                    defaultValueEnteredText = scalarValueAsGeneric(tagged, (value, impl) =>
                        impl.valueToEditString(value.value, prevState.timeZone)
                    );
                } else {
                    defaultValueEnteredText = arrValueAsGeneric(tagged, (value, impl) =>
                        impl.valueToEditString(value.values[0], prevState.timeZone)
                    );
                }
            } else {
                defaultValueEnteredText = '';
            }

            return {
                ...fromUnion,
                union: { kind: 'freetext', defaultValueEnteredText },
            };
        }
        case 'array-null':
        case 'scalar': {
            if (fromUnion.union.kind === 'freetext') {
                const option = staticOption(fromUnion.union.defaultValueEnteredText);
                return {
                    kind: 'basic',
                    dataType: fromUnion.dataType,
                    union: {
                        kind: 'static',
                        options: new Map([[option.id, option]]),
                        selectionKind: paramType,
                        defaultValue: new Set([option.id]),
                        parsedConfig: err({ options: [], defaultValue: undefined }),
                    },
                };
            }
            if (paramType === fromUnion.union.selectionKind) {
                return fromUnion;
            }

            if (fromUnion.union.kind === 'static') {
                return {
                    ...fromUnion,
                    union: {
                        ...fromUnion.union,
                        selectionKind: paramType,
                    },
                };
            }

            return {
                ...fromUnion,
                union: {
                    ...fromUnion.union,
                    selectionKind: paramType,
                    defaultValue: convertQueryDefaultValueToSelectionType(paramType, fromUnion.union.defaultValue),
                },
            };
        }
        default:
            assertNever(paramType);
    }
}

export function eppSetBasicDataType(dataType: RtdValue.BasicType, state: EppBasicParamOptions): EppBasicParamOptions {
    if (state.union.kind === 'freetext') {
        return { ...state, dataType };
    }

    if (state.union.kind === 'query') {
        return {
            ...state,
            dataType,
            union: {
                ...state.union,
                defaultValue: undefined,
            },
        };
    }

    if (dataType === 'bool') {
        const options = new Map([staticOption('true'), staticOption('false')].map((v) => [v.id, v]));
        return { ...state, dataType, union: { ...state.union, options } };
    } else if (state.dataType === 'bool') {
        const option = staticOption();
        const options = new Map([[option.id, option]]);
        return { ...state, dataType, union: { ...state.union, options } };
    }

    return { ...state, dataType };
}
