import React, { useCallback } from 'react';
import {
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Text,
    IModalProps,
    IDialogContentProps,
} from 'office-ui-fabric-react';

import { RTDDialog } from '../../../../fabric/panelsWrapped';
import { APP_STRINGS } from '../../../../../res';
import { TFormComponent, formActionMacro } from '../../../../../common';

const dialogContentProps: IDialogContentProps = {
    showCloseButton: true,
};

const modalProps: IModalProps = {
    isBlocking: true,
};

export const SaveConfirmParameterDialog: TFormComponent<{
    isNew: boolean;
    valueColumnMissing: boolean;
    labelColumnMissing: boolean;
    incompleteQuery: boolean;
    onConfirm: () => void;
}> = ({ onClose, isNew, valueColumnMissing, labelColumnMissing, incompleteQuery, onConfirm }) => {
    const innerOnConfirm = useCallback(() => {
        onConfirm();
        onClose();
    }, [onConfirm, onClose]);

    return (
        <RTDDialog
            hidden={false}
            title={isNew ? APP_STRINGS.forms.parameter.save.newTitle : APP_STRINGS.forms.parameter.save.editTitle}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            onDismiss={onClose}
        >
            <Text>{formatDescription(valueColumnMissing, labelColumnMissing, incompleteQuery)}</Text>
            <br />
            <br />
            <Text>{formatConfirmation(isNew)}</Text>
            <DialogFooter>
                <PrimaryButton onClick={innerOnConfirm}>
                    {isNew ? APP_STRINGS.utilButtons.create : APP_STRINGS.utilButtons.apply}
                </PrimaryButton>
                <DefaultButton onClick={onClose} text={APP_STRINGS.utilButtons.cancel} />
            </DialogFooter>
        </RTDDialog>
    );
};

const formatDescription = (
    valueColumnMissing: boolean,
    labelColumnMissing: boolean,
    incompleteQuery: boolean
): string => {
    const strings = APP_STRINGS.forms.parameter.save;

    if (incompleteQuery) {
        return strings.incompleteQuery;
    }

    const columns: string[] = [];

    if (valueColumnMissing) {
        columns.push(strings.valueColumn);
    }

    if (labelColumnMissing) {
        columns.push(strings.labelColumn);
    }

    return `${strings.columnPrefix} ${columns.join(` ${strings.and} `)} ${
        columns.length > 1 ? strings.columnPlural : strings.columnSingular
    } ${columns.length > 1 ? strings.doSingular : strings.doPlural} ${strings.columnSuffix}`;
};

const formatConfirmation = (isNew: boolean): string =>
    `${APP_STRINGS.forms.parameter.save.confirmationPrefix} ${
        isNew
            ? APP_STRINGS.forms.parameter.save.newConfirmationSuffix
            : APP_STRINGS.forms.parameter.save.editConfirmationSuffix
    }`;

export const saveConfirmParameterDialogAction = formActionMacro(SaveConfirmParameterDialog, 'saveParameter');
