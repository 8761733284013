import { useEffect } from 'react';

import { StreamQueryResult } from '../../../domain';
import { APP_STRINGS } from '../../../res';

import { EditParameterPageDispatch } from '../types';

export const useSyncQueryResults = (queryStatus: StreamQueryResult, dispatch: EditParameterPageDispatch) => {
    useEffect(() => {
        if (queryStatus.kind === 'success') {
            const columns = queryStatus.result.columns;
            dispatch((innerDispatch, getState) => {
                const union = getState().union;
                if (union.kind === 'basic' && union.union.kind === 'query') {
                    const dataSource = union.union;
                    let isNewValueColumn = false;
                    let { valueColumn } = dataSource;
                    const { labelColumn } = dataSource;

                    if (valueColumn === undefined && queryStatus.result.columns.length === 1) {
                        valueColumn = columns[0].name;
                        isNewValueColumn = true;
                    }

                    // User can never select a column that wasn't previously in a result set, so the only time there can be a mismatch
                    // between the actual values and the selected values is when the result set changes
                    const valueColumnExists = valueColumn === undefined || columns.some((c) => c.name === valueColumn);
                    const labelColumnExists = labelColumn === undefined || columns.some((c) => c.name === labelColumn);
                    innerDispatch({
                        type: 'updateQueryDataSource',
                        changes: {
                            ...(isNewValueColumn ? { valueColumn } : {}),
                            // We could use a null assertion here, but it seemed nicer to just check against `undefined` again
                            valueColumnWarning:
                                !valueColumnExists && valueColumn !== undefined ? formatError(valueColumn) : undefined,
                            labelColumnWarning:
                                !labelColumnExists && labelColumn !== undefined ? formatError(labelColumn) : undefined,
                            queryDidComplete: true,
                        },
                    });
                }
            });
        } else if (queryStatus.status?.kind === 'done') {
            // Some form of error in running query
            dispatch({
                type: 'updateQueryDataSource',
                changes: {
                    queryDidComplete: false,
                },
            });
        }
    }, [queryStatus, dispatch]);
};

const formatError = (columnName: string): string =>
    `${APP_STRINGS.forms.parameter.doesNotExistPrefix}${columnName}${APP_STRINGS.forms.parameter.doesNotExistSuffix}`;
