import * as React from 'react';
import { useCallback, useState, MutableRefObject } from 'react';
import { IconButton, Stack } from 'office-ui-fabric-react';

import { useCore } from '../../../core';
import { APP_STRINGS } from '../../../res';

import { PermissionsFormDispatch } from './permissionsFormReducer';
import { ButtonSpinner } from './ButtonSpinner';
import { formatPermissionError } from './lib';

import styles from './RemoveCell.module.scss';

interface IRemoveCellProps {
    dashboardId: string;
    id: string;
    dispatch: PermissionsFormDispatch;
    isMounted: MutableRefObject<boolean>;
}

export const RemoveCell: React.FC<IRemoveCellProps> = ({ dashboardId, id, dispatch, isMounted }) => {
    const { membershipService } = useCore();
    const [isLoading, setIsLoading] = useState(false);

    const iconStyles = {
        iconName: 'Delete',
    };

    const onDelete = useCallback(async () => {
        setIsLoading(true);

        const promise = membershipService.deleteMembership(dashboardId, id);
        dispatch({ type: 'addAsyncOperation', operation: promise });
        promise
            .then(() => {
                if (!isMounted.current) {
                    return;
                }

                dispatch({
                    type: 'removeMember',
                    memberId: id,
                });
            })
            .catch((e) => {
                if (!isMounted.current) {
                    return;
                }

                dispatch({
                    type: 'setListError',
                    errorMessage: formatPermissionError(e.message),
                });
            })
            .finally(() => {
                if (!isMounted.current) {
                    return;
                }

                setIsLoading(false);
                dispatch({ type: 'removeAsyncOperation', operation: promise });
            });
    }, [id, dashboardId, isMounted, dispatch, setIsLoading, membershipService]);

    return (
        <Stack horizontal horizontalAlign="space-between">
            <IconButton
                className={styles.removeCell}
                name="Delete"
                title={APP_STRINGS.utilButtons.delete}
                iconProps={iconStyles}
                onClick={onDelete}
                disabled={isLoading}
            />
            {isLoading ? <ButtonSpinner /> : null}
        </Stack>
    );
};
