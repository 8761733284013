import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { IDropdownOption } from 'office-ui-fabric-react';

import { availableFeatureFlags, FeatureFlag } from '../core/domain';
import { useCore } from '../core';
import { RTDDropdown, RTDDropdownOption } from '../components';

const FeatureToggle = observer<{ flag: FeatureFlag }>(function FeatureToggle({ flag }) {
    const core = useCore();

    const options: RTDDropdownOption[] = [
        {
            key: 'tenant',
            text: `Tenant (${core.featureFlags.tenantFlags.includes(flag) ? 'on' : 'off'})`,
        },
        {
            key: 'on',
            text: 'On',
        },
        {
            key: 'off',
            text: 'Off',
        },
    ];

    const selectedKey = core.featureFlags.override[flag] ?? 'tenant';

    const onChange = (_ev: unknown, option?: IDropdownOption) => {
        if (option) {
            core.featureFlags.set(flag, option.key as 'on' | 'off' | 'tenant');
        }
    };

    return <RTDDropdown label={flag} options={options} selectedKey={selectedKey} onChange={onChange} />;
});

export const FeatureFlagToggles: React.FC = () => {
    return (
        <>
            {availableFeatureFlags.map((flag) => (
                <FeatureToggle key={flag} flag={flag} />
            ))}
        </>
    );
};
