import { ActionKind, EntityType, Action } from '../../../common/types';
import { dependencies } from '../../../dependencies';
import { assertNever } from '../../../utils/types';

export const keepFocusIconName = 'Flashlight';

/**
 * Get a list of actions (Clear, Edit, refresh or favorite) for the given entity type.
 * @param entityType The type of entity (Cluster, Database, Table, Function and so on.)
 * @param isFavorite Only relevant for databases and clusters. Represents the entity's favorite status. The action will toggle the current status, so if isFavorite is true the action will represent un-favorite.
 * @param info For info action (exist in Table, Function, Cluster and MaterializedViewTable) this value should be added to determine the hover text.
 */
export function getActions(entityType: EntityType, isFavorite = false, info?: string): Action[] {
    const actionKinds = entityTypeToActionKinds[entityType];
    const actions = (actionKinds ?? [])
        .map((actionKind) => getAction(actionKind, isFavorite, info))
        .filter((action) => action) as Action[];

    return actions;
}

/**
 * Map for each entity type the supported actions
 */
const entityTypeToActionKinds: { [t in EntityType]: ActionKind[] } = {
    Cluster: dependencies.featureFlags.ShowConnectionButtons
        ? ['refresh', 'delete', 'edit', 'info', 'favorite']
        : ['refresh', 'info', 'favorite'],
    Database: ['refresh', 'favorite'],
    Table: ['info'],
    Function: ['info'],
    Column: [],
    Folder: [],
    FunctionsFolder: [],
    TablesFolder: [],
    MaterializedViewTableFolder: [],
    ExternalTableFolder: [],
    LoadingIndicator: [],
    ExternalTable: [],
    MaterializedViewTable: ['info'],
};

export function getAction(actionKind: ActionKind, isFavorite: boolean, info: string | undefined): Action | undefined {
    switch (actionKind) {
        case 'delete':
            return {
                mainIcon: {
                    iconName: 'Clear',
                    iconType: 'fabric',
                    ariaLabel: 'Delete',
                },
                textOnHover: dependencies.strings.removeConnection,
            };
        case 'edit':
            return {
                mainIcon: {
                    iconName: 'Edit',
                    iconType: 'fabric',
                    ariaLabel: 'Edit',
                },
                textOnHover: dependencies.strings.editCluster,
            };
        case 'favorite':
            if (isFavorite) {
                return {
                    mainIcon: {
                        iconName: 'Unfavorite',
                        iconType: 'fabric',
                        ariaLabel: 'Unfavorite',
                    },
                    nonHoverIcon: {
                        iconName: 'FavoriteStarFill',
                        iconType: 'fabric',
                        ariaLabel: 'Empty favorite star',
                    },
                    textOnHover: dependencies.strings.removeFavorite,
                };
            } else {
                return {
                    mainIcon: {
                        iconName: 'AddFavorite',
                        iconType: 'fabric',
                        ariaLabel: 'Add favorite',
                    },
                    textOnHover: dependencies.strings.addFavorites,
                };
            }
        case 'info':
            // Do not show the info icon if there is no info to show.
            if (!info || info.length === 0) {
                return undefined;
            }

            return {
                mainIcon: {
                    iconName: 'Info',
                    iconType: 'fabric',
                    ariaLabel: 'Info',
                },
                textOnHover: info,
            };
        case 'refresh':
            return {
                mainIcon: {
                    iconName: 'Refresh',
                    iconType: 'fabric',
                    ariaLabel: 'Refresh',
                },
                textOnHover: dependencies.strings.refresh,
            };
        case 'keepFocus':
            return {
                mainIcon: {
                    iconName: keepFocusIconName,
                    iconType: 'fabric',
                    ariaLabel: 'Keep focus',
                },
                textOnHover: dependencies.strings.connectionPane$search$navButtonText,
            };
        default:
            assertNever(actionKind);
    }
}
