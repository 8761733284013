const nameAndNumberedDuplicateRegex = /^(.*)[-_]([0-9]+)$/;

/**
 * Calculates whether the provided string is a duplicate of another existing string, providing the number of
 * the match if so, and 0 otherwise.
 * @param existingNames The existing numbered names. `name` stripped of its number must match one of these
 * in order to be considered a duplicate
 * @param name The name to match (case sensitive)
 */
export const checkForNumberedDuplicateName = (existingNames: Set<string>, name: string): number => {
    const matches = name.match(nameAndNumberedDuplicateRegex);

    let numberlessName: string;
    let number = 1;

    if (matches && matches.length > 2) {
        numberlessName = matches[1];

        number = parseInt(matches[2]);
    } else {
        numberlessName = name;
    }

    if (!existingNames.has(numberlessName)) {
        // Not a duplicate
        return 0;
    }

    return number;
};

/**
 * Strips the duplicate number (if any) from the provided string
 */
export const extractNumberedBaseName = (name: string): string => {
    const matches = name.match(nameAndNumberedDuplicateRegex);

    return matches && matches.length > 2 ? matches[1] : name;
};
