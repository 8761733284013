import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import { Customizer, Spinner, SpinnerSize } from 'office-ui-fabric-react';

import { darkCustomizations, lightCustomizations, useThemeState } from '../../domain';
import { DevMenu } from '../../dev/DevMenu';
import { RootErrorBoundary } from '../../components/errors/RootErrorBoundary';
import { RequestGlobalLoadingProvider } from '../../components/PageContent';
import { useCore } from '../../core';
import { isMSAAccount } from '../../core/domain';
import { CatalogPage } from '../catalog/CatalogPage';
import { RtdCoreSuspense } from '../../core/react';

import { RouteSideEffects } from './RouteSideEffects';
import { MSAError } from './MSAError/MSAError';
import { DebugWrapper } from './DebugWrapper';

import { ManagedForms } from './ManagedForms';
import { DashboardRoute } from './DashboardRoute/DashboardRoute';

import styles from './RootPage.module.scss';

const onRenderLoading = (className?: string) => () =>
    (
        <RootWrapper className={`${className} ${styles.rootLoading}`}>
            <Spinner size={SpinnerSize.large} />
        </RootWrapper>
    );

const rootErrorWrapper = (className?: string) => (error: React.ReactNode) => {
    return (
        <RootWrapper className={`${className} ${styles.rootError}`}>
            <div>{error}</div>
        </RootWrapper>
    );
};

export const RootWrapper: React.FC<{ className?: string }> = ({ children, className }) => {
    const theme = useThemeState();

    return (
        <Customizer {...(theme.isDark ? darkCustomizations : lightCustomizations)}>
            <div className={classNames(theme.classNames, className)} id={styles.RTDRoot}>
                {children}
            </div>
        </Customizer>
    );
};

export interface IRootPageProps {
    className?: string;
}

const InnerRootPage: React.FC<IRootPageProps> = ({ className }) => {
    const core = useCore();

    // TODO: This should be reactive, "useCurrentUser"
    const account = core.userService.getCurrentUser();

    return (
        <DebugWrapper>
            <BrowserRouter>
                <RequestGlobalLoadingProvider>
                    {
                        // If no account is provided, or if the account is not MSA, render the normal app
                        !account || !isMSAAccount(account) ? (
                            <>
                                <RouteSideEffects />
                                {core.hostConfig.enableDevMenu && <DevMenu />}
                                <RootWrapper className={className}>
                                    <ManagedForms />
                                    <Switch>
                                        <Route path={core.navUtil.path.catalog} exact>
                                            <CatalogPage />
                                        </Route>
                                        <Route path={core.navUtil.path.dashboard(':dashboardId')}>
                                            <DashboardRoute />
                                        </Route>
                                        <Route path="*">
                                            <Redirect to={core.navUtil.path.catalog} />
                                        </Route>
                                    </Switch>
                                </RootWrapper>
                            </>
                        ) : (
                            // TODO #7180081: This error should be handled the same as other core errors.
                            <MSAError />
                        )
                    }
                </RequestGlobalLoadingProvider>
            </BrowserRouter>
        </DebugWrapper>
    );
};

export const RootPage: React.FC<IRootPageProps> = ({ className }) => {
    return (
        <RtdCoreSuspense onRenderLoading={onRenderLoading(className)}>
            <RootErrorBoundary wrapError={rootErrorWrapper(className)}>
                <InnerRootPage className={className} />
            </RootErrorBoundary>
        </RtdCoreSuspense>
    );
};
