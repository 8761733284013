import { utcToZonedTime, format } from 'date-fns-tz';

import { isSameDay } from '../../common';
import { APP_CONSTANTS, APP_STRINGS } from '../../res';
import type { TimeZone } from '../timezone';

import { Duration, TDuration } from './types';

/**
 * Creates a date offset by the provided range
 * @param currentTime The date to offset
 * @param range The range with which to offset the `currentTime`
 */
export const getTimeProceedingTimeByRange = (currentTime: Date, range: Duration.Dynamic): Date => {
    const date = new Date(currentTime);

    const count = range.count;

    switch (range.unit) {
        case 'minutes': {
            date.setMinutes(date.getMinutes() - count);
            break;
        }
        case 'hours': {
            date.setHours(date.getHours() - count);
            break;
        }
        case 'days': {
            date.setDate(date.getDate() - count);
            break;
        }
        case 'weeks': {
            date.setDate(date.getDate() - count * 7);
            break;
        }
        case 'months': {
            date.setMonth(date.getMonth() - count);
            break;
        }
    }

    return date;
};

export const formatDurationDynamic = (count: number, unit: string) =>
    `${APP_STRINGS.duration.formatDynamic.last} ${count} ${unit}`;

export const formatDurationFixed = (start: string, end: string) => {
    return `${start} - ${end}`;
};

export const durationDisplayString = (duration: TDuration, timeZone: TimeZone): string => {
    switch (duration.kind) {
        case 'fixed': {
            const startDate = format(
                utcToZonedTime(duration.start, timeZone),
                APP_CONSTANTS.durationPicker.format.dateTooltip,
                {
                    timeZone,
                }
            );
            const endDate = format(
                utcToZonedTime(duration.end, timeZone),
                APP_CONSTANTS.durationPicker.format.dateTooltip,
                {
                    timeZone,
                }
            );

            return formatDurationFixed(startDate, endDate);
        }
        case 'dynamic':
            return formatDurationDynamic(duration.count, duration.unit);
    }
};

export interface DurationPickerFormatStrings {
    dateTooltip: string;
    dateWithTime: string;
    dateWithTimeNoSeconds: string;
    date: string;
    time: string;
    timeWithNoSeconds: string;
}

export const formatDurationCompressedSameDate = (
    duration: Duration.Fixed,
    formatStringPatterns: DurationPickerFormatStrings,
    timeZone: TimeZone
) => {
    const startUTC = utcToZonedTime(duration.start, timeZone);
    const date = format(startUTC, formatStringPatterns.date, {
        timeZone,
    });

    const hasSeconds = duration.start.getSeconds() !== 0 || duration.end.getSeconds() !== 0;

    const timeFormat = hasSeconds ? formatStringPatterns.time : formatStringPatterns.timeWithNoSeconds;

    const startTime = format(startUTC, timeFormat, {
        timeZone,
    });
    const endTime = format(utcToZonedTime(duration.end, timeZone), timeFormat, {
        timeZone,
    });

    return `${date} ${APP_STRINGS.duration.formatCompressedSameDate.at} ${startTime} - ${endTime}`;
};

export const formatDurationCompressedFullDate = (
    duration: Duration.Fixed,
    formatStringPatterns: DurationPickerFormatStrings,
    timeZone: TimeZone
) => {
    const hasSeconds = duration.start.getSeconds() !== 0 || duration.end.getSeconds() !== 0;

    const timeFormat = hasSeconds ? formatStringPatterns.dateWithTime : formatStringPatterns.dateWithTimeNoSeconds;

    const startDate = format(utcToZonedTime(duration.start, timeZone), timeFormat, {
        timeZone,
    });

    const endDate = format(utcToZonedTime(duration.end, timeZone), timeFormat, {
        timeZone,
    });

    return `${startDate} - ${endDate}`;
};

export const pillShortenedTitle = (duration: TDuration, timeZone: TimeZone): string => {
    if (duration.kind !== 'fixed') {
        return durationDisplayString(duration, timeZone);
    }

    // If same day, use truncated, time-only formatting
    return isSameDay(duration.start, duration.end)
        ? formatDurationCompressedSameDate(duration, APP_CONSTANTS.durationPicker.format, timeZone)
        : formatDurationCompressedFullDate(duration, APP_CONSTANTS.durationPicker.format, timeZone);
};
