import { err, ok } from '../../../common';
import { APP_STRINGS } from '../../../res';

import { buildValueImpl } from '../valueImpl';

export const boolValueImpl = buildValueImpl<boolean, 'bool'>({
    dataType: 'bool',
    urlStringToValue(value) {
        switch (value) {
            case 'true':
                return ok(true);
            case 'false':
                return ok(false);
            default:
                return err(APP_STRINGS.domain.parameter.errors.boolError);
        }
    },
    valueToUrlString(value) {
        return value.toString();
    },
    valueToDisplayString(value) {
        return value.toString();
    },
    tryNarrowValue(value) {
        if (value.tag === 'bool') {
            return value;
        }
        return undefined;
    },
    isValue(value) {
        return typeof value === 'boolean';
    },
});
