import { tile } from '../tile';
import { ITileV2 } from '../tile/versions/2';

import { IDashboardV3 } from './3';

export interface IDashboardV4 extends Omit<IDashboardV3, '$schema' | 'tiles'> {
    $schema: '4';
    tiles: ITileV2[];
}

/**
 * Updates tiles to v2
 */
export function up(prev: IDashboardV3): IDashboardV4 {
    return {
        ...prev,
        $schema: '4',
        tiles: prev.tiles.map((t) => tile.v2.up(t)),
    };
}
