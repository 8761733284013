import { EntityType, Icon } from '../../../common/types';

export const iconMapper: { [t in EntityType | string]: Icon } = {
    Cluster: {
        iconName: 'Cluster',
        iconType: 'custom',
        isBold: true,
        ariaLabel: 'Cluster',
    },
    Database: {
        iconName: 'Database',
        iconType: 'fabric',
        isBold: true,
        ariaLabel: 'Database',
    },
    Folder: {
        iconName: 'FolderHorizontal',
        iconType: 'fabric',
        isBold: false,
        ariaLabel: 'Folder',
    },
    Function: {
        iconName: 'Variable',
        iconType: 'fabric',
        isBold: false,
        ariaLabel: 'Function',
    },
    Table: {
        iconName: 'Table',
        iconType: 'fabric',
        isBold: false,
        ariaLabel: 'Table',
    },
    ExternalTable: {
        iconName: 'ExternalTable',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'External Table',
    },
    Column: {
        iconName: 'Column',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Column',
    },
    FunctionsFolder: {
        iconName: 'FunctionsFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Functions Folder',
    },
    TablesFolder: {
        iconName: 'TablesFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Tables Folder',
    },
    MaterializedViewTableFolder: {
        iconName: 'MaterializedViewTableFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'Materialized Views Folder',
    },
    ExternalTableFolder: {
        iconName: 'ExternalTablesFolder',
        iconType: 'custom',
        isBold: false,
        ariaLabel: 'External Tables Folder',
    },
    LoadingIndicator: {
        iconName: 'Refresh',
        iconType: 'fabric',
        isBold: false,
        ariaLabel: 'Refresh',
    },
    MaterializedViewTable: {
        iconName: 'TableComputed',
        iconType: 'fabric',
        isBold: false,
        ariaLabel: 'Materialized View',
    },
    ReadOnlyFollowing: {
        // Database
        iconName: 'FollowerDatabase',
        ariaLabel: 'Follower Database',
        iconType: 'custom',
        isBold: true,
    },
    ReadOnly: {
        iconName: 'ReadOnlyDatabase',
        ariaLabel: 'read only Database',
        iconType: 'custom',
        isBold: true,
    },
};
