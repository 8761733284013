import JSBI from 'jsbi';

import { err, ok } from '../../../common';
import { APP_STRINGS } from '../../../res';

import { ValueImpl, buildValueImpl } from '../valueImpl';

const bigIntOne = JSBI.BigInt(1);

// From https://docs.microsoft.com/en-us/dotnet/api/system.data.sqltypes.sqldecimal?view=dotnet-plat-ext-3.1
const decimalMax = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(38));

// From https://stackoverflow.com/a/27159132
const doubleRegex = /^[+-]?(\d+\.\d*|\.?\d+)$/;

export const decimalValueImpl: ValueImpl<string, 'decimal'> = buildValueImpl({
    dataType: 'decimal',
    urlStringToValue(value: string) {
        if (!value.match(doubleRegex)) {
            return err(APP_STRINGS.domain.parameter.errors.error);
        }

        // Attempt parsing just to verify that it's valid and ignore the result
        if (isNaN(parseFloat(value))) {
            // Failed to convert to float
            return err(APP_STRINGS.domain.parameter.errors.error);
        }

        try {
            const bigInt = JSBI.BigInt(value.split('.')[0]);
            // > 10^38 -1
            const maxSize = JSBI.subtract(decimalMax, bigIntOne);

            if (JSBI.greaterThan(bigInt, maxSize)) {
                return err(`${APP_STRINGS.domain.parameter.errors.decimal.tooLarge}${maxSize}`);
            }
            // -10^38 + 1 (yes, actually plus one)
            const minSize = JSBI.add(JSBI.unaryMinus(decimalMax), bigIntOne);

            if (JSBI.lessThan(bigInt, minSize)) {
                return err(`${APP_STRINGS.domain.parameter.errors.decimal.tooSmall}${maxSize}`);
            }
        } catch (e) {
            // Failed to convert to BigInt
            return err(e.message);
        }

        return ok(value);
    },
    valueToUrlString(value: string) {
        return value;
    },
    /**
     * TODO: Use Number.prototype.toLocaleString() after
     * switching to native big ints
     */
    valueToDisplayString(value: string) {
        return value;
    },
    tryNarrowValue(value) {
        if (value.tag === 'decimal') {
            return value;
        }
        return undefined;
    },
    isValue(value: unknown) {
        return typeof value === 'string' && decimalValueImpl.urlStringToValue(value).kind === 'ok';
    },
});
