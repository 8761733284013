import * as React from 'react';
import { useCallback } from 'react';
import { TooltipHost, ActionButton } from 'office-ui-fabric-react';
import { ConfigurationItemRow } from './ConfigurationItemRow';
import styles from './styles.module.scss';

export type ApplyConfigChange<T> = (items: T[]) => T[] | undefined;

export interface BaseConfigItem {
    id: string;
}

const addConfigItemIconProps = { iconName: 'Add' };

const deleteConfigItem: <T extends BaseConfigItem>(itemId: string) => ApplyConfigChange<T> = (itemId) => (items) => {
    const index = items.findIndex((item) => item.id === itemId);

    if (index === -1) {
        return undefined;
    }
    return [...items.slice(0, index), ...items.slice(index + 1)];
};

export interface ConfigurationListProps<T> {
    items: T[];
    onChange: (applyChange: ApplyConfigChange<T>) => void;
    disabled?: boolean;
    addItemButtonText: string;
    ItemComponent: React.ComponentType<{ item: T }>;
    onEdit: (item: T, calloutTargetRef: React.MutableRefObject<null>) => void;
    onItemCreated?: (item: T, calloutTargetRef: React.MutableRefObject<null>) => void;
    maxItems?: number;
    addConfigItem: () => void;
    disableDelete?: (item: T) => boolean;
    addItemButtonTooltip?: string;
}

interface AddItemButtonProps {
    addItemButtonText: string;
    disabled?: boolean;
    onAddClick?: () => void;
    tooltipText?: string;
}

/**
 * AddItemButton is rendered at the bottom of the list in order to add a new item.
 * The reason for the wrapper div is that we need it to have a border-bottom of full width,
 * and at the same time to make sure the tooltip appears at the center of the button.
 * If the tooltip host would have full width without the wrapper div, the tooltip will not be centered.
 */
export const AddItemButton: React.FC<AddItemButtonProps> = ({
    addItemButtonText,
    disabled,
    onAddClick,
    tooltipText,
}) => {
    return (
        <div className={styles.addConfigItemButton}>
            <TooltipHost content={tooltipText}>
                <ActionButton iconProps={addConfigItemIconProps} onClick={onAddClick} disabled={disabled}>
                    {addItemButtonText}
                </ActionButton>
            </TooltipHost>
        </div>
    );
};

export const ConfigurationList = <T extends BaseConfigItem>({
    items,
    onChange,
    disabled,
    addItemButtonText,
    addItemButtonTooltip,
    ItemComponent,
    onEdit,
    onItemCreated,
    maxItems,
    addConfigItem,
    disableDelete,
}: ConfigurationListProps<T>) => {
    const onConfigItemDelete = useCallback((itemId: string) => onChange(deleteConfigItem(itemId)), [onChange]);

    const addItemButtonDisabled = disabled || (maxItems !== undefined && maxItems <= items.length);

    return (
        <div className={styles.configurationList}>
            {items.map((i) => (
                <ConfigurationItemRow
                    key={i.id}
                    onDelete={onConfigItemDelete}
                    item={i}
                    disabled={disabled}
                    ItemComponent={ItemComponent}
                    onEdit={onEdit}
                    onItemCreated={onItemCreated}
                    disableDelete={disableDelete}
                />
            ))}
            <AddItemButton
                disabled={addItemButtonDisabled}
                addItemButtonText={addItemButtonText}
                onAddClick={addConfigItem}
                tooltipText={addItemButtonTooltip}
            />
        </div>
    );
};
