import { useRef, useEffect } from 'react';
import { runInAction } from 'mobx';

import { MaybeQuery } from '../../../components/QueryEditing';
import { useCore } from '../../../core';
import { useCurrent } from '../../../common';
import { DashboardStore } from '../../../store';

import { streamResultCannotRun } from '../streamResult';
import { KustoQueryResult } from '../types';
import { useStreamingObserver } from './useStreamingObserver';

/**
 * Executes the provided query, attaching to the query data stream if it already exists
 *
 * **Note**: This hook is mirrored in `useExecuteParameter`, with the exception that it follows a different data path
 * @param query The query to execute
 * @param forceUpdateOnQueryChange If true, request new query data when `query` changes. Otherwise, only
 * attempt to fetch when first called, then attach to the data stream
 */
export function useExecuteQuery(
    store: DashboardStore,
    query: MaybeQuery,
    {
        isPersistent = false,
        preventSubscription = false,
        forceUpdateOnQueryChange = false,
    }: {
        forceUpdateOnQueryChange?: boolean;
        isPersistent?: boolean;
        preventSubscription?: boolean;
    } = {}
) {
    const { queryService } = useCore();
    const { result, observer, setState } = useStreamingObserver<KustoQueryResult>();
    const isInitialRenderRef = useRef(true);
    const lastQueryHash = useRef<string | undefined>(undefined);
    const currentForceUpdateOnQueryChange = useCurrent(forceUpdateOnQueryChange);

    useEffect(() => {
        // Should not depend on dashboard id, so the id changing doesn't cause queries to re-run
        const dashboardId = runInAction(() => store.state?.meta.id);
        if (preventSubscription || dashboardId === undefined) {
            return;
        }

        if (query.kind === 'err') {
            setState(streamResultCannotRun(query.err));
            return;
        }

        const startTime = new Date();

        // Request update if query isn't new
        // Don't request on initial render, as we may already have data
        if (
            currentForceUpdateOnQueryChange.current &&
            !isInitialRenderRef.current &&
            query.value.queryHash === lastQueryHash.current
        ) {
            queryService.requestQueryUpdate(query.value.queryHash);
        }

        const subscription = queryService
            .registerAndObserveQuery(query.value, lastQueryHash.current, dashboardId, isPersistent)
            .subscribe(observer(startTime));

        lastQueryHash.current = query.value.queryHash;

        return () => subscription.unsubscribe();
    }, [
        store,
        query,
        preventSubscription,
        isPersistent,
        queryService,
        currentForceUpdateOnQueryChange,
        observer,
        setState,
    ]);

    useEffect(() => {
        isInitialRenderRef.current = false;
    }, []);

    return result;
}
