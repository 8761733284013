import { v4 as uuid } from 'uuid';
import { runInAction } from 'mobx';

import { err, isAbortError, ok, parseException, Result } from '../../common';
import { UDataSource, IDashboardDocument, IManualKustoDataSource } from '../../core/domain';
import {
    createTile,
    VisualOptions,
    VisualConfig,
    createTelemetry,
    newDashboardDocument,
    updateLayoutWithTiles,
} from '../../domain';
import { IRtdDashboardsCore } from '../../core';
import { DashboardStore } from '../../store/dashboard';
import { MigrationResult, serializeDashboard } from '../../migration';
import { formatMigrationError } from '../../components/errors/formatMigrationError';

import { QueryImporterReducerState } from './queryImporterReducer';
import { dataSourcesEqual } from './lib';

type TargetDashboard = { type: 'new'; name: string } | { type: 'existing'; dashboard: IDashboardDocument };

function createDashboardDocumentPayload(
    providedDataSource: Omit<IManualKustoDataSource, 'id'>,
    targetDashboard: TargetDashboard,
    localState: QueryImporterReducerState,
    query: string,
    visualConfig: VisualConfig<string>,
    visualOptions: Partial<VisualOptions> | undefined,
    visualType: string | undefined
) {
    const newDataSource = (): UDataSource => ({
        ...providedDataSource,
        name: localState.dataSourceName,
        id: uuid(),
    });

    let oldDashboard: IDashboardDocument;
    let dataSource: UDataSource;

    if (targetDashboard.type === 'existing') {
        oldDashboard = targetDashboard.dashboard;

        if (localState.dedupeDataSource) {
            const matchingDataSource = Object.values(targetDashboard.dashboard.dataSources).find(
                (d) => d.kind === 'manual-kusto' && dataSourcesEqual(d, providedDataSource)
            );

            dataSource = matchingDataSource ?? newDataSource();
        } else {
            dataSource = newDataSource();
        }
    } else {
        oldDashboard = newDashboardDocument(targetDashboard.name);
        dataSource = newDataSource();
    }

    const layout = updateLayoutWithTiles(
        visualConfig,
        Object.values(oldDashboard.tiles),
        Object.values(oldDashboard.pages)
    );

    const pageId = Object.keys(layout)[0];

    const newTile = createTile({
        title: localState.tileTitle,
        query,
        dataSourceId: dataSource.id,
        visualConfig: visualConfig.visualTypes,
        pageLayout: layout[pageId],
        pageId,
        visualType,
        visualOptions,
    });

    const dashboardDocument: IDashboardDocument = {
        ...oldDashboard,
        dataSources: [...oldDashboard.dataSources.filter((d) => d.id !== dataSource.id), dataSource],
        tiles: [...oldDashboard.tiles.filter((t) => t.id !== newTile.id), newTile],
    };

    return { dashboardDocument, tileId: newTile.id };
}

export type QueryImportResult = Result<{ dashboardId: string; shouldVisitDashboard: boolean }>;

export async function importQuery(
    core: IRtdDashboardsCore,
    store: DashboardStore,
    visualConfig: VisualConfig<string>,
    query: string,
    providedDataSource: Omit<IManualKustoDataSource, 'id'>,
    applyToDashboard: IDashboardDocument | undefined,
    visualOptions: Partial<VisualOptions> | undefined,
    visualType: string | undefined,
    getLocalState: () => QueryImporterReducerState,
    signal: AbortSignal,
    onStartSave: (dashboardId: string) => void,
    onCompletedSave: (result: QueryImportResult) => void
) {
    const localState = getLocalState();

    let targetDashboard: TargetDashboard;

    if (localState.dashboard.type === 'existing') {
        if (applyToDashboard && localState.dashboard.id === (applyToDashboard.meta?.id ?? 'new')) {
            targetDashboard = {
                type: 'existing',
                dashboard: applyToDashboard,
            };
        } else {
            // Dashboard is loading
            return;
        }
    } else {
        targetDashboard = localState.dashboard;
    }

    const { dashboardDocument, tileId } = createDashboardDocumentPayload(
        providedDataSource,
        targetDashboard,
        localState,
        query,
        visualConfig,
        visualOptions,
        visualType
    );

    onStartSave(dashboardDocument.meta?.id ?? 'new');

    const trackEvent = createTelemetry('QueryImporter', core.telemetryService, store);

    trackEvent('StartSaving');

    let response: Result<IDashboardDocument>;

    try {
        let migrationResponse: MigrationResult<IDashboardDocument>;
        if (localState.dashboard.type === 'new') {
            migrationResponse = await core.dashboardService.createDashboard(
                serializeDashboard(dashboardDocument),
                signal
            );
        } else {
            migrationResponse = await core.dashboardService.updateDashboard(
                serializeDashboard(dashboardDocument),
                signal
            );
        }

        if (migrationResponse.kind === 'ok') {
            response = ok(migrationResponse.value.data);
        } else {
            response = err(formatMigrationError(migrationResponse.err));
        }
    } catch (e) {
        if (isAbortError(e)) {
            return;
        }
        core.telemetryService.trackException(e, {
            'Component Name': 'QueryImporter',
            dashboardId: dashboardDocument.meta?.id ?? 'new',
        });
        response = err(parseException(e));
    }

    trackEvent('SaveCompleted');

    if (response.kind === 'ok') {
        const shouldVisitDashboard = localState.visitDashboard;
        onCompletedSave(
            ok({
                dashboardId: response.value.meta?.id ?? 'new',
                shouldVisitDashboard,
            })
        );
        if (shouldVisitDashboard) {
            // TODO: Should be query param. Leaves dashboards in a bad state if
            // tile isn't successfully scrolled to
            runInAction(() => {
                store.pendingScrollToTile = tileId;
            });
        }
    } else {
        onCompletedSave(response);
    }
}
