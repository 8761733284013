import { ITileV6, IVisualOptionsV6 } from './6';

export interface CrossFilterConfig {
    dimensionId: string;
    parameterId: string;
}

export interface IVisualOptionsV7 extends IVisualOptionsV6 {
    crossFilterDisabled: boolean;
    crossFilter: null | CrossFilterConfig;
}

export interface ITileV7 extends Omit<ITileV6, 'visualOptions'> {
    visualOptions: Partial<IVisualOptionsV7>;
}

export function up(prev: ITileV6): ITileV7 {
    return prev;
}

export function down(prev: ITileV7): { removedCrossFilter: boolean; data: ITileV6 } {
    const { crossFilterDisabled, crossFilter, ...visualOptions } = prev.visualOptions;

    const data: ITileV6 = { ...prev, visualOptions };
    const removedCrossFilter = crossFilter !== undefined;

    return { data, removedCrossFilter };
}
