import * as React from 'react';
import { createContext, useContext, useEffect, useReducer } from 'react';

import { useCore } from '../../../../core';
import { APP_STRINGS } from '../../../../res';
import { MaybeQuery } from '../../../../components/QueryEditing/QueryProvider';
import { RtdTableSchema } from '../../../../domain/value';
import { kustoColumnsToDashboardColumns } from '../../../../domain/kusto';
import { StreamQueryResult } from '../../../../domain/query';

import { useETPSelector, ETPState } from '../../../../store/editTile';

const formatInitializeMessageFromError = (err: string | undefined) => {
    const strings = APP_STRINGS.editTilePage.schemaContext.errorInitializingSchema;
    return err ? `${strings.normal}: ${err}` : strings.unexpected;
};
const formatUpdateMessageFromError = (err: string | undefined) => {
    const strings = APP_STRINGS.editTilePage.schemaContext.errorUpdatingSchema;
    return err ? `${strings.normal}: ${err}` : strings.unexpected;
};

const schemaProviderPausedError: MaybeQuery = {
    kind: 'err',
    err: {
        title: APP_STRINGS.editTilePage.schemaContext.contextNotProvidedError,
    },
};

export type SchemaState =
    | { kind: 'available'; schema: RtdTableSchema; errorMessage?: string }
    | { kind: 'unavailable'; errorMessage: string }
    | { kind: 'uninitialized' };

const reducer = (state: SchemaState, action: StreamQueryResult): SchemaState => {
    switch (action.kind) {
        case 'success':
            return {
                kind: 'available',
                schema: {
                    columns: kustoColumnsToDashboardColumns(action.result.columns),
                },
            };
        case 'err': {
            if (state.kind === 'uninitialized' || state.kind === 'unavailable') {
                const errorMessage = formatInitializeMessageFromError(action.reason.title);
                return { kind: 'unavailable', errorMessage };
            }

            const updateErrorMessage = formatUpdateMessageFromError(action.reason.title);
            return { ...state, errorMessage: updateErrorMessage };
        }
        case 'notRun': {
            // Do nothing
            return state;
        }
    }
};

export const schemaLoadingState: SchemaState = { kind: 'uninitialized' };

export const schemaContext = createContext<SchemaState>(schemaLoadingState);

const pausedSelector = (s: ETPState) => s.type !== 'query' || s.visual === undefined;

export const SchemaProvider: React.FC<{
    query: MaybeQuery;
}> = ({ query: incomingQuery, children }) => {
    const { queryService } = useCore();
    const [state, dispatch] = useReducer(reducer, schemaLoadingState);
    // When this toggles to parsedQuery the schema provider will always run. If
    // this starts happening very often we should change the schema provider
    // implementation to handle this. As of writing, visual options cannot be
    // removed, and this cannot happen.
    const paused = useETPSelector(pausedSelector);

    const query: MaybeQuery = paused ? schemaProviderPausedError : incomingQuery;

    useEffect(() => {
        if (query.kind === 'err') {
            dispatch({ kind: 'err', didRun: false, reason: query.err });
            return;
        }

        const subscription = queryService.observeQuery(query.value.queryHash).subscribe({
            next: dispatch,
        });

        return () => subscription.unsubscribe();
    }, [query, queryService, dispatch]);

    return <schemaContext.Provider value={state}>{children}</schemaContext.Provider>;
};

export const useSchema = () => useContext(schemaContext);
