import * as React from 'react';
import { IPanelStyles, IPanelProps, IPanelHeaderRenderer, IRenderFunction, ActionButton } from 'office-ui-fabric-react';
import { useCallback, useRef } from 'react';

import { APP_STRINGS } from '../../../../../res';
import { RTDPanel } from '../../../../fabric';

import styles from './PanelWithBack.module.scss';

export interface PanelWithBackProps extends IPanelProps {
    showBack?: boolean;
    onBack?: () => void;
    backButtonAriaLabel?: string;
}

const backIconProps = { iconName: 'Back' };

export const PanelWithBack: React.FC<PanelWithBackProps> = ({
    children,
    showBack,
    onBack,
    backButtonAriaLabel,
    ...props
}) => {
    const headerRenderRef = useRef<IPanelHeaderRenderer | undefined>(undefined);
    const headerIdRef = useRef<string | undefined>(undefined);

    const onRenderHeader = useCallback(
        (localProps?: IPanelProps, defaultRender?: IPanelHeaderRenderer, headerId?: string) => {
            // Extremely dirty hack to preserve Fabric's standard rendering due to their
            // onRenderNavigationContent bug (see below)
            headerRenderRef.current = defaultRender;
            headerIdRef.current = headerId;
            // Render header in normal position only if editing
            return showBack ? (
                defaultRender ? (
                    <div className={styles.displacedTitle}>{defaultRender(localProps, undefined, headerId)}</div>
                ) : null
            ) : null;
        },
        [showBack]
    );

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (localProps, defaultRender) => (
            <div className={styles.navBar}>
                <ActionButton
                    className={styles.back}
                    iconProps={backIconProps}
                    onClick={onBack}
                    ariaLabel={backButtonAriaLabel}
                >
                    {APP_STRINGS.utilButtons.back}
                </ActionButton>
                <div className={styles.close}>{defaultRender?.(localProps)}</div>
            </div>
        ),
        [backButtonAriaLabel, onBack]
    );

    const onRenderNavigationContentPassthrough: IRenderFunction<IPanelProps> = useCallback(
        (localProps, defaultRender) => (
            <div className={styles.navBar}>
                {headerRenderRef.current?.(localProps, undefined, headerIdRef.current)}
                {defaultRender?.(localProps)}
            </div>
        ),
        []
    );

    return (
        <RTDPanel
            {...props}
            styles={panelStyles}
            onRenderHeader={onRenderHeader}
            // Fabric stupidly assigns a variable on component init based on this prop being set. Therefore we must provide a
            // passthrough func so it is set
            onRenderNavigationContent={showBack ? onRenderNavigationContent : onRenderNavigationContentPassthrough}
        >
            {children}
        </RTDPanel>
    );
};

const panelStyles: Partial<IPanelStyles> = {
    // Numbers are chosen to perfectly replicate Fabric styling
    commands: {
        height: 32,
        marginTop: 18,
    },
    navigation: {
        height: 32,
    },
    closeButton: {
        height: 32,
        width: 32,
        marginRight: 14,
    },
};
