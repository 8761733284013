import { openDB, deleteDB } from 'idb';

import { isSecurityError } from '../../common';
import { databaseName, databaseVersion } from './constants';
import { getOpenIndexedDBVersions } from './getOpenIndexedDBVersions';
import { DashboardsIndexedDBSchema, DashboardsIndexedDB } from './types';

/**
 * Indexed db spec: https://w3c.github.io/IndexedDB
 * Goals of this indexed db code include:
 *   * Tabs that use different indexed be schema versions can use indexed db
 *     alongside each other.
 *   * When opening a tab, we delete old database versions, making best effort
 *     to avoid deleting ones that are in use.
 *   * If we accidentally end up deleting a database that's in use, the users
 *     of that database _must_ disconnect and reconnect.
 *     * While a database is open  delete requests stay pending, and while a
 *       delete request is pending, new connections cannot be made.
 *     * This has caused a prod issue in the past after a rollback
 *       where an old version open in a tab, a newer version is open in a tab
 *       from and then a 3rd version gets opened from a rollback. If a delete
 *       request is pending in this scenario, the latest tab will not be able
 *       to connect to indexed db.
 */

/**
 * Minimum valid version we can pass to indexed db
 */
const minimumIndexedDBVersion = 1;

const dbNameWithVersion = (name: string, version: number) => `${name}-v${version}`;

/**
 * We create a new database on every version. This function attempts to delete
 * databases made with old version numbers.
 */
async function deleteOldDatabaseVersions() {
    const openVersions = await getOpenIndexedDBVersions();
    const names: string[] = [];

    // Database name from before we started adding the version number to it.
    names.push(databaseName);

    for (let i = minimumIndexedDBVersion; i < databaseVersion; i++) {
        if (!openVersions.has(i)) {
            names.push(dbNameWithVersion(databaseName, i));
        }
    }

    for (const name of names) {
        deleteDB(name).catch((e) => {
            if (isSecurityError(e)) {
                return;
            }
            throw e;
        });
    }
}

/**
 * Embed version number in database name so that every version creates a new
 * database. This is fine because we're using these databases as a cache,
 * and it's ok if we don't have the data in an old version. Doing this
 * allows us to allow an old version in the same browser as a newer version.
 */
function openDatabase(restart: () => void) {
    return openDB<DashboardsIndexedDBSchema>(
        dbNameWithVersion(databaseName, databaseVersion),
        minimumIndexedDBVersion,
        {
            upgrade: (db) => {
                db.createObjectStore('UserSessionCacheParameterRecents');
                const queryCache = db.createObjectStore('QueryResultCache');
                queryCache.createIndex('sourceId', 'sourceId');
                queryCache.createIndex('lastAccessed', 'lastAccessed');
            },
            blocking: restart,
        }
    ).catch((e) => {
        if (isSecurityError(e)) {
            return undefined;
        }
        throw e;
    });
}

export function initIndexedDB(): DashboardsIndexedDB {
    deleteOldDatabaseVersions();
    const box = { db: openDatabase(restart) };

    function restart() {
        // Opening a new connection before the last one has completely closed should
        // be fine.
        box.db.then((db) => db?.close());
        box.db = openDatabase(restart);
    }

    return box;
}
