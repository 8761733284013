import React, { useCallback, useState } from 'react';
import { DebouncedFunc } from 'lodash';
import { IDropdownOption, TextField } from 'office-ui-fabric-react';
import debounce from 'lodash/debounce';
import { KeysOfType } from '../../../../../../common';
import { BaseYAxisConfig, YAxisConfig } from '../../../../../../domain';
import { useETPDispatch, useETPSelector } from '../../../../../../store/editTile';
import { convertStringToNumber, useRegisterDebounce, visualOptionsSelectorBuilder } from '../../../../lib';
import { APP_CONSTANTS, APP_STRINGS } from '../../../../../../res';
import { RTDDropdown } from '../../../../../../components';
import { axisScaleOptions } from '../../../../constants';
import inputsStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { updateYAxisLimit } from './reducer';

const yAxesSelector = visualOptionsSelectorBuilder((s) => s.multipleYAxes);

interface BaseYAxisInputFieldProps {
    property: Exclude<KeysOfType<BaseYAxisConfig, string>, undefined>;
    label: string;
    inputType?: string;
    placeholder?: string;
}

export const BaseYAxisInputField: React.FC<BaseYAxisInputFieldProps> = ({
    property,
    label,
    inputType,
    placeholder,
}) => {
    const [dispatch, getState] = useETPDispatch();
    // Local values shadow global values, and are cleared when debounce resolves
    const [localValue, setLocalValue] = React.useState<undefined | string>(undefined);
    const multipleYAxes = useETPSelector(yAxesSelector);
    const baseAxis = multipleYAxes.base;
    const value = baseAxis[property];
    const onChange = React.useMemo(() => {
        const setGlobalValue = debounce((newValue: string) => {
            // Clear local state. Until this is done this input won't respond to
            // changes to the global value.
            const pageState = getState();
            if (pageState.type === 'query' && pageState.visual?.options.multipleYAxes !== undefined) {
                const stateMultipleYAxes = pageState.visual?.options.multipleYAxes;
                setLocalValue(undefined);
                dispatch({
                    type: 'updateVisualOptions',
                    options: {
                        multipleYAxes: {
                            ...stateMultipleYAxes,
                            base: { ...stateMultipleYAxes.base, [property]: newValue },
                        },
                    },
                });
            }
        }, APP_CONSTANTS.editTilePage.visualOptionsTextDebounce);

        const innerOnChange = ((_: unknown, newValue?: string) => {
            if (newValue !== undefined) {
                setLocalValue(newValue);
                setGlobalValue(newValue);
            }
        }) as DebouncedFunc<(_: unknown, newValue?: string) => void>;
        innerOnChange.flush = setGlobalValue.flush;
        innerOnChange.cancel = setGlobalValue.cancel;
        return innerOnChange;
    }, [dispatch, property, getState]);

    useRegisterDebounce(onChange);

    return (
        <TextField
            className={`${inputsStyles.basicInput} ${styles.formInput}`}
            value={localValue ?? value}
            onChange={onChange}
            label={label}
            type={inputType}
            placeholder={placeholder}
        />
    );
};

interface AxisLimitProps {
    property: 'yAxisMinimumValue' | 'yAxisMaximumValue';
}

const AxisLimit: React.FC<AxisLimitProps> = ({ property }) => {
    const [dispatch, getState] = useETPDispatch();
    const multipleYAxes = useETPSelector(yAxesSelector);
    const baseAxis = multipleYAxes.base;
    const limit = baseAxis[property];
    const [text, setText] = useState(limit);

    React.useEffect(() => setText(limit), [limit]);

    const { onValueChange, dispatchValueChange } = React.useMemo(() => {
        const dispatchChange = debounce((value: string) => {
            const pageState = getState();
            if (pageState.type === 'query' && pageState.visual?.options.multipleYAxes !== undefined) {
                const stateMultipleYAxes = pageState.visual.options.multipleYAxes;
                const base = stateMultipleYAxes.base;
                const updatedYAxis = updateYAxisLimit(base, property, value);

                dispatch({
                    type: 'updateVisualOptions',
                    options: {
                        multipleYAxes: {
                            ...stateMultipleYAxes,
                            base: updatedYAxis as BaseYAxisConfig,
                        },
                    },
                });
            }
        }, APP_CONSTANTS.debounce.high);
        return {
            dispatchValueChange: dispatchChange,
            onValueChange: (_: unknown, value?: string) => {
                setText(convertStringToNumber(value));
                dispatchValueChange(value as string);
            },
        };
    }, [dispatch, setText, property, getState]);

    useRegisterDebounce(dispatchValueChange);

    return (
        <TextField
            className={`${inputsStyles.basicInput} ${styles.formInput}`}
            placeholder={APP_STRINGS.editTilePage.visualConfig.textInputInferPlaceholder}
            value={text?.toString()}
            label={APP_STRINGS.editTilePage.visualConfig[property].inputLabel}
            type="number"
            onChange={onValueChange}
        />
    );
};

const BaseYAxisScale = () => {
    const selectedKeySelector = visualOptionsSelectorBuilder((s) => s.multipleYAxes.base.yAxisScale);

    const [dispatch, getState] = useETPDispatch();
    const selectedKey = useETPSelector(selectedKeySelector);
    const onChange = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            const pageState = getState();
            if (pageState.type === 'query' && pageState.visual?.options.multipleYAxes !== undefined) {
                const stateMultipleYAxes = pageState.visual.options.multipleYAxes;
                dispatch({
                    type: 'updateVisualOptions',
                    options: {
                        multipleYAxes: {
                            ...stateMultipleYAxes,
                            base: {
                                ...stateMultipleYAxes.base,
                                yAxisScale: option?.key as YAxisConfig['yAxisScale'],
                            },
                        },
                    },
                });
            }
        },
        [dispatch, getState]
    );

    return (
        <RTDDropdown
            className={`${inputsStyles.basicInput} ${styles.formInput}`}
            selectedKey={selectedKey}
            options={axisScaleOptions}
            onChange={onChange}
            label={APP_STRINGS.editTilePage.visualConfig.axisScale.label.yAxisScale}
        />
    );
};

export const BaseYAxisForm: React.FC = () => {
    return (
        <>
            <BaseYAxisInputField
                property="label"
                label={APP_STRINGS.editTilePage.visualConfig.yColumnTitleInputLabel}
            />
            <AxisLimit property="yAxisMaximumValue" />
            <AxisLimit property="yAxisMinimumValue" />
            <BaseYAxisScale />
        </>
    );
};
