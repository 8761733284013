import {
    SearchBoxBase,
    ISearchBox,
    ISearchBoxProps,
    KeyCodes,
    ISearchBoxStyleProps,
    ISearchBoxStyles,
    styled,
} from 'office-ui-fabric-react';
import { getStyles } from 'office-ui-fabric-react/lib/components/SearchBox/SearchBox.styles';

type UnsafeKeydownSearchBoxProps = Omit<ISearchBoxProps, 'onSearch' | 'onEscape'>;

class UnsafeKeydownSearchBoxBase extends SearchBoxBase implements ISearchBox {
    constructor(props: ISearchBoxProps) {
        super(props);

        // Overriding a private method, which TypeScript doesn't let us access
        // Allow enter and escape keypresses to pass through to the dropdown, rather than being captured by the search box
        const originalOnKeyDown = this['_onKeyDown'] as (ev: React.KeyboardEvent<HTMLInputElement>) => void;
        this['_onKeyDown'] = (ev: React.KeyboardEvent<HTMLInputElement>) => {
            if (ev.which === KeyCodes.enter || ev.which === KeyCodes.escape) {
                this.props.onKeyDown && this.props.onKeyDown(ev);
                if (!ev.defaultPrevented) {
                    return;
                }
            } else {
                originalOnKeyDown(ev);
            }
        };
    }
}

export const UnsafeKeydownSearchBox: React.FunctionComponent<UnsafeKeydownSearchBoxProps> = styled<
    ISearchBoxProps,
    ISearchBoxStyleProps,
    ISearchBoxStyles
>(UnsafeKeydownSearchBoxBase, getStyles, undefined, { scope: 'SearchBox' });
