import { getTheme, ITheme, ICustomizations } from 'office-ui-fabric-react';
import { DarkCustomizations } from '@uifabric/theme-samples';
import { NeutralColors } from '@uifabric/fluent-theme';
import mapValues from 'lodash/mapValues';

import { hexToRGBA } from '../../common';

type AvailableNeutralColors =
    | 'white'
    | 'gray10'
    | 'gray20'
    | 'gray30'
    | 'gray40'
    | 'gray50'
    | 'gray60'
    | 'gray90'
    | 'gray130'
    | 'gray150'
    | 'gray160'
    | 'gray190';

export interface RtdTheme {
    uiFabricTheme: ITheme;
    neutralColors: Record<AvailableNeutralColors, string>;
    additionalColors: {
        /**
         * Root background color doesn't necessarily correspond directly to the same slot in light/dark theme
         */
        rootBackgroundColor: string;
        loadingBackgroundColor: string;
    };
}

// Omitting unused values
const {
    white,
    gray10,
    gray20,
    gray30,
    gray40,
    gray50,
    gray60,
    gray90,
    gray120,
    gray130,
    gray140,
    gray150,
    gray160,
    gray170,
    gray180,
    gray190,
    gray200,
    gray220,
} = NeutralColors;

const lightNeutralColors: Record<AvailableNeutralColors, string> = {
    white,
    gray10,
    gray20,
    gray30,
    gray40,
    gray50,
    gray60,
    gray90,
    gray130,
    gray150,
    gray160,
    gray190,
};

// Links from https://www.figma.com/file/i10LUab0NvdBV5qVtZPNsu/ADX-%2B-Dashboards----Dark-theme?node-id=3963%3A291
const darkNeutralColors: Record<AvailableNeutralColors, string> = {
    white: gray200,
    gray10: gray190,
    gray20: gray180,
    gray30: gray170,
    gray40: gray160,
    gray50: gray150,
    gray60: gray140,
    gray90: gray120,
    gray130: gray90,
    gray150: gray60,
    gray160: gray20,
    gray190: gray10,
};

const darkMenuBackground = gray170;
const darkMenuItemBackgroundHovered = gray140;
const darkMenuItemBackgroundPressed = gray130;

function loadingBackgroundColor(palette: Record<AvailableNeutralColors, string>): string {
    const color = hexToRGBA(palette.white, 0.7);
    if (color === undefined) {
        throw new Error();
    }
    return color;
}

// Base Fabric theme is our light theme
export const lightTheme: RtdTheme = {
    uiFabricTheme: getTheme(),
    neutralColors: lightNeutralColors,
    additionalColors: {
        rootBackgroundColor: gray20,
        loadingBackgroundColor: loadingBackgroundColor(lightNeutralColors),
    },
};

export const cssVariableNames = mapValues(
    {
        ...getTheme().palette,
        // Could just as easily use darkTheme here. They have the
        // exact same set of keys and that's all we're looking for
        ...lightTheme.neutralColors,
        ...lightTheme.additionalColors,
    },
    (_, colorName) => `var(--${colorName})`
);

export const darkTheme = ((): RtdTheme => {
    const theme: ITheme = { ...DarkCustomizations.settings.theme };
    // Set overlay color to black at 40% for Panels and such
    theme.palette.whiteTranslucent40 = 'rgba(0, 0, 0, 0.4)';
    theme.semanticColors.bodySubtext = theme.palette.neutralSecondary;

    // Make dropdowns and other context menu like items have a lighter base
    theme.semanticColors.menuItemBackgroundHovered = darkMenuItemBackgroundHovered;
    theme.semanticColors.menuItemBackgroundPressed = darkMenuItemBackgroundPressed;
    theme.semanticColors.menuBackground = darkMenuBackground;

    return {
        uiFabricTheme: theme,
        neutralColors: darkNeutralColors,
        additionalColors: {
            rootBackgroundColor: gray220,
            loadingBackgroundColor: loadingBackgroundColor(darkNeutralColors),
        },
    };
})();

export const lightCustomizations: ICustomizations = {
    settings: {},
    scopedSettings: {},
};

export const darkCustomizations: ICustomizations = {
    settings: {},
    // Apply known needed fixes for dark theme
    scopedSettings: {
        PeoplePickerItem: {
            ...DarkCustomizations.scopedSettings['PeoplePickerItem'],
        },
        PersonaCoin: {
            ...DarkCustomizations.scopedSettings['PersonaCoin'],
        },
    },
};
