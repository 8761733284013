import React from 'react';

// An event should contain at least the component and the flow.
export interface FlowProperties {
    component: string;
    flow: string;
}

export interface TelemetryClient<T> {
    trackEvent: (
        name: string,
        properties?: ({ [name: string]: string } & Partial<T>) | undefined,
        measurements?: { [name: string]: number } | undefined
    ) => void;
}

export interface TelemetryClientFunc {
    <T extends FlowProperties = FlowProperties>(constantProps: T): TelemetryClient<T>;
}

export const TelemetryContext = React.createContext<TelemetryClientFunc>(() => ({ trackEvent: () => {} }));
