import { IList } from 'office-ui-fabric-react';

import { PrerenderedCustomListDropdownOption } from './types';

export interface CustomListDropdownMenuReducerState {
    listRef: IList | undefined;
    orderedFilteredKeys: string[] | undefined;

    activeIndex: number | undefined;
}

export type CustomListDropdownMenuAction =
    | {
          type: 'setListRef';
          listRef: IList | null;
      }
    | {
          type: 'setFilteredOptions';
          options: PrerenderedCustomListDropdownOption[] | undefined;
      }
    | {
          type: 'setActiveIndex';
          index: number | undefined;
      };

export const customListDropdownMenuReducer = (
    state: CustomListDropdownMenuReducerState,
    action: CustomListDropdownMenuAction
): CustomListDropdownMenuReducerState => {
    switch (action.type) {
        case 'setListRef': {
            return {
                ...state,
                listRef: action.listRef ?? undefined,
            };
        }
        case 'setFilteredOptions': {
            return {
                ...state,
                orderedFilteredKeys: action.options?.map((o) => o.key),
                activeIndex: undefined,
            };
        }
        case 'setActiveIndex': {
            return {
                ...state,
                activeIndex: action.index,
            };
        }
    }
};
