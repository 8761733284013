import { IDropdownOption, Label, TooltipHost } from 'office-ui-fabric-react';
import React, { useMemo, useCallback } from 'react';

import { APP_CONSTANTS, APP_STRINGS } from '../../../../../../res';
import { infer } from '../../../../../../domain';
import { CanInfer } from '../../../../../../common';
import { RTDDropdown } from '../../../../../../components';

import { ETPManagedConfigKeyForType } from '../../../../types';
import { visualOptionsSelectorBuilder } from '../../../../lib';
import { useETPDispatch, useETPSelector } from '../../../../../../store/editTile';

import styles from '../styles.module.scss';

import { ManagedConfigComponent } from '../../types';
import { ETPColumnOption, columnOptionKey, useSchemaDerived, columnDropdownOption } from './utils';

export const singleSelectInferOption: ETPColumnOption = {
    key: APP_CONSTANTS.emptySelection,
    text: APP_STRINGS.editTilePage.visualConfig.dropdownInputInferOption,
    data: infer,
};

export interface SingleColumnDropdownProps {
    id: string;
    className?: string;
    label: string;
    selectedColumn: CanInfer<string>;
    disabled?: boolean;
    onChange: (_: unknown, option?: IDropdownOption) => void;
}

export const SingleColumnDropdown: React.FC<SingleColumnDropdownProps> = ({
    id,
    className,
    label,
    selectedColumn,
    onChange,
    disabled,
}) => {
    const selectedKey =
        selectedColumn.type === 'infer' ? APP_CONSTANTS.emptySelection : columnOptionKey(selectedColumn.value);
    const schemaDerived = useSchemaDerived();

    const options: ETPColumnOption[] = useMemo(() => {
        if (selectedColumn.type !== 'infer' && !schemaDerived.optionsKeys.has(columnOptionKey(selectedColumn.value))) {
            return [singleSelectInferOption, columnDropdownOption(selectedColumn.value), ...schemaDerived.options];
        }
        return [singleSelectInferOption, ...schemaDerived.options];
    }, [selectedColumn, schemaDerived]);

    return (
        <div className={className}>
            <Label htmlFor={id}>{label}</Label>
            <TooltipHost content={schemaDerived.disabledReason}>
                <RTDDropdown
                    id={id}
                    selectedKey={selectedKey}
                    options={options}
                    onChange={onChange}
                    disabled={disabled || schemaDerived.disabledReason !== undefined}
                    aria-label={label}
                />
            </TooltipHost>
        </div>
    );
};

export const createSingleColumConfigOption = (
    key: ETPManagedConfigKeyForType<CanInfer<string>>,
    label: string
): ManagedConfigComponent => {
    const currentValueSelector = visualOptionsSelectorBuilder((s) => s[key]);

    return ({ disabled }) => {
        const [dispatch] = useETPDispatch();
        const selectedColumn = useETPSelector(currentValueSelector);

        const onChange = useCallback(
            (_: unknown, option?: IDropdownOption) => {
                dispatch({
                    type: 'updateVisualOptions',
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    options: { [key]: option!.data },
                });
            },
            [dispatch]
        );

        return (
            <SingleColumnDropdown
                className={styles.basicInput}
                label={label}
                selectedColumn={selectedColumn}
                id={`visual-options--${key}`}
                onChange={onChange}
                disabled={disabled}
            />
        );
    };
};
