import { useCallback, useContext } from 'react';
import { runInAction } from 'mobx';

import { dashboardsCoreContext } from '../core/react/context';

/**
 * While the dashboards component is mounted, this should be used to reset the
 * dashboards location from outside of dashboards. Updating history directly
 * causes problems. Using this for navigation is a product of trial and error,
 * and _not_ reading through dashboard's routing code carefully. If this starts
 * causing problems we should start by understanding how react-router-dom
 * (dashboards' history abstraction) interacts with the browser history.
 *
 * If you need to navigate away from dashboards use `useGetDashboardsUnsaved` to
 * find if a warning needs to be displayed. Resetting dashboards location is not
 * necessary if it's getting unmounted.
 *
 * @returns A callback that attempts to navigate dashboards to the catalog page.
 * Dashboards location may not change if there are unsaved changes.
 */
export function useResetDashboardsLocation() {
    const core = useContext(dashboardsCoreContext);

    return useCallback(() => {
        if (core.status !== 'ready') {
            return;
        }

        const history = runInAction(() => core.appContext.history.h.get());

        if (history) {
            history.push(core.appContext.navUtil.path.catalog);
        }
    }, [core]);
}
