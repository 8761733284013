import * as React from 'react';

import styles from './FullHeightWhiteWrapper.module.scss';

interface FullHeightWhiteWrapperProps {
    className?: string;
}

export const FullHeightWhiteWrapper: React.FC<FullHeightWhiteWrapperProps> = ({ className, children }) => {
    return (
        <div className={className ? `${styles.fullHeightWhiteWrapper} ${className}` : styles.fullHeightWhiteWrapper}>
            {children}
        </div>
    );
};
