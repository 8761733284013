import uniq from 'lodash/uniq';
import { APP_CONSTANTS } from '../../../res';
import { BasicParamConfig } from '../../../domain';
import { ok, Result } from '../../../common';
import { RecentEntryResult } from '../../../core/indexedDB';

export type IPrimitiveFreeTextParameterAction =
    | {
          type: 'setEnteredText';
          text: string;
          timeZone: string;
      }
    | {
          type: 'appendRecents';
          recentsToAppend: RecentEntryResult;
      }
    | { type: 'submitValue' }
    | { type: 'disableSubmitOnDismiss' };

export interface IPrimitiveFreeTextParameterSelectorState<T, K extends string> {
    enteredText: string;
    maybeValue: Result<T | typeof APP_CONSTANTS.parameter.allSelection>;
    parameterConfig: BasicParamConfig<T, K>;
    submitOnDismiss: boolean;

    recents: RecentEntryResult;
}

export function reducer<T, K extends string>(
    state: IPrimitiveFreeTextParameterSelectorState<T, K>,
    action: IPrimitiveFreeTextParameterAction
): IPrimitiveFreeTextParameterSelectorState<T, K> {
    switch (action.type) {
        case 'setEnteredText': {
            const text = action.text.trim();

            if (text === '') {
                return {
                    ...state,
                    enteredText: text,
                    maybeValue: ok(APP_CONSTANTS.parameter.allSelection),
                };
            }

            return {
                ...state,
                enteredText: action.text,
                maybeValue: state.parameterConfig.impl.editStringToValue(text, action.timeZone),
                submitOnDismiss: true,
            };
        }
        case 'appendRecents': {
            /**
             * Since we are appending recents whenever indexeddb resolves
             * there's a chance that the user could have entered an identical
             * input entry (e.g. same text). This would be a weird UX since
             * there could be duplicate entries so we need to
             * ensure the recents are all unique
             */
            const allRecents = uniq([...state.recents, ...action.recentsToAppend]);
            const newRecents =
                allRecents.length >= APP_CONSTANTS.maxRecents
                    ? allRecents.slice(0, APP_CONSTANTS.maxRecents - 1)
                    : allRecents;

            return { ...state, recents: newRecents };
        }
        case 'submitValue': {
            const newRecent = state.enteredText.trim();

            const currentIndex = state.recents.indexOf(newRecent);

            if (currentIndex !== -1) {
                // Move this entry to the top
                const latestRecents = [...state.recents];
                latestRecents.splice(currentIndex, 1);

                return {
                    ...state,
                    recents: [newRecent, ...latestRecents],
                    submitOnDismiss: true,
                };
            }

            const latestRecents =
                state.recents.length >= APP_CONSTANTS.maxRecents
                    ? state.recents.slice(0, APP_CONSTANTS.maxRecents - 1)
                    : state.recents;

            return {
                ...state,
                recents: [newRecent, ...latestRecents],
                submitOnDismiss: true,
            };
        }
        case 'disableSubmitOnDismiss': {
            return {
                ...state,
                submitOnDismiss: false,
            };
        }
    }
}
