import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

import { assertUnreachable, isSecurityError } from '../common';
import { APP_CONSTANTS } from '../res';

export const debugFlags = ['debugVisualConfig'] as const;

export type DebugFlag = typeof debugFlags[number];
export type DebugFlags = { readonly [T in DebugFlag]?: true };
export type DebugFlagContext = Readonly<[DebugFlags, (flag: DebugFlag, on: boolean) => void]>;

const debugFlagsContext = createContext<DebugFlagContext>([{}, assertUnreachable]);

export const useDebugFlagsContext = () => useContext(debugFlagsContext);

const getSavedFlags = (): DebugFlags => {
    let jsonFlags: string | null;
    try {
        jsonFlags = localStorage.getItem(APP_CONSTANTS.localStorageKeys.debugFlags);
    } catch (e) {
        if (isSecurityError(e)) {
            return {};
        }
        throw e;
    }

    if (jsonFlags) {
        return JSON.parse(jsonFlags);
    } else {
        return {};
    }
};

export const DebugFlagContextProvider: React.FC = ({ children }) => {
    const [flags, setFlags] = useState(getSavedFlags);

    const setFlag = useCallback(
        (flag: DebugFlag, on: boolean) =>
            setFlags((innerFlags) => {
                let newFlags: DebugFlags;
                if (on) {
                    newFlags = { ...innerFlags, [flag]: true };
                } else {
                    const { [flag]: _value, ...flagsCopy } = innerFlags;
                    newFlags = flagsCopy;
                }
                try {
                    localStorage.setItem(APP_CONSTANTS.localStorageKeys.debugFlags, JSON.stringify(newFlags));
                } catch (e) {
                    if (!isSecurityError(e)) {
                        throw e;
                    }
                }
                return newFlags;
            }),
        []
    );

    const value = useMemo<DebugFlagContext>(() => [flags, setFlag], [flags, setFlag]);

    return <debugFlagsContext.Provider value={value}>{children}</debugFlagsContext.Provider>;
};

export const useDebugFlag = (flag: DebugFlag): boolean => {
    const [flags] = useDebugFlagsContext();

    return flags[flag] ?? false;
};
