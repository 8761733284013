import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { rtdExtensions } from './rtd/telemetry';
import { setAppInsights } from './telemetryClient';

// Since this file cannot import dependencies (circular dependency - since dependencies creates a kusto client that in turn depends on telemetry.ts)
// we just add rtdExtensions anyway. Should be harmless.
const additionalConfig = rtdExtensions;
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a42243e6-b995-4ec3-9061-afb48a2d952e',
        enableAutoRouteTracking: true,
        ...additionalConfig,
    },
});

export const setupAppInsights = () => {
    appInsights.loadAppInsights();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    appInsights.context.application.ver = process.env.REACT_APP_VERSION!;
};

export const setUserIdInAppInsight = (userID: string) => {
    appInsights.setAuthenticatedUserContext(userID);
};

setAppInsights(appInsights);
