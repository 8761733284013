import { Err, err, ok, Result } from '../common';

export interface Schema<T extends string = string> {
    $schema: T;
}

export interface Migration<I extends Schema, Up extends Schema, Down extends Schema> {
    up?: (data: I) => Up;
    down?: (data: I, warnings: string[]) => Down;
}

export type ByVersion<V, T> = T extends { $schema: V } ? T : never;

export type Migrations<S extends Schema> = {
    migrations: {
        [V in S['$schema']]: Migration<ByVersion<V, S>, S, S>;
    };

    /**
     * Used to determine if we're going up or down
     */
    order: Array<S['$schema']>;
};

export type MigrationError =
    | {
          kind: 'missing-migration';
          version: string;
          targetVersion: string;
          receivedVersion: string;
      }
    | {
          kind: 'unexpected-crash';
          version: string;
          direction: 'up' | 'down';
          error: unknown;
      };

export type MigrationResult<T> = Result<{ data: T; warnings: string[] }, MigrationError>;

function missingMigration(version: string, targetVersion: string, receivedVersion: string): Err<MigrationError> {
    return err({ kind: 'missing-migration', version, targetVersion, receivedVersion });
}

function findDirection(
    currentVersion: string,
    targetVersion: string,
    versions: string[]
): Result<'up' | 'down', MigrationError> {
    const currentIndex = versions.indexOf(currentVersion);

    if (currentIndex === -1) {
        return missingMigration(currentVersion, targetVersion, currentVersion);
    }

    const targetIndex = versions.indexOf(targetVersion);

    // Should be impossible, should never be targeting a version that we don't
    // have migrations for
    if (targetIndex === -1) {
        throw new Error(`Missing target migration "${targetVersion}"`);
    }

    return ok(currentIndex < targetIndex ? 'up' : 'down');
}

/**
 * Not 100% type-safe. Double check the migrations are in the correct format,
 * and migration functions are typed correctly.
 *
 * @param migrations - Map of schema version to migration function that will
 *   bump the version. A final migration that does nothing is required to make
 *   the types work, but will not be run. Need to be in order. **Need to be in order from oldest to newest**
 * @param data - Data to be migrated
 * @param to - Schema version of the final shape we want the data to be in.
 *   Types enforce that this should be a key of the migrations map.
 */
export function migrate<S extends Schema<string>, T extends Schema['$schema']>(
    migrations: Migrations<S>,
    data: S,
    to: T
): MigrationResult<ByVersion<T, S>> {
    const direction = findDirection(data.$schema, to, migrations.order);
    if (direction.kind === 'err') {
        return direction;
    }

    let result: S = data;
    const warnings: string[] = [];

    while (result.$schema !== to) {
        const migration: undefined | ((d: S, w: string[]) => S) =
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (migrations.migrations as Partial<Record<string, Migration<any, any, any>>>)[result.$schema]?.[
                direction.value
            ];

        if (migration === undefined) {
            return missingMigration(result.$schema, to, data.$schema);
        }
        // Corrupt data can cause crashes, and we (will, as of writing) only
        // validate the final version, not the incoming version.
        try {
            result = migration(result, warnings);
        } catch (error) {
            return err({
                kind: 'unexpected-crash',
                version: result.$schema,
                direction: direction.value,
                error,
            });
        }
    }

    return ok({ data: result as ByVersion<T, S>, warnings });
}

export function addSchemaIfMissing<D, S extends string>(
    data: D,
    defaultSchemaVersion: S
): D extends { $schema: string } ? D : { $schema: S } & D {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if ('$schema' in data) {
        return data as any;
    }
    // Temporary hack to support loading data saved with either $schema or schema_version
    if ('schema_version' in data) {
        (data as any).$schema = (data as any).schema_version;
        delete (data as any).schema_version;
        return data as any;
    }

    return { $schema: defaultSchemaVersion, ...data } as any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
}
