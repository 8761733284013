import * as React from 'react';
import { useMemo } from 'react';

import { APP_STRINGS } from '../../../../../res';
import { DropdownIconOption } from '../../../../../components/fabric/dropdown/dropdownIconRenderProps';
import { useVisualConfig } from '../../../../../domain';

import { visualAddedSelectorBuilder } from '../../../lib';
import { useETPSelector } from '../../../../../store/editTile';

import { DefaultVariants } from '../types';
import { VisualTypePicker } from '../configUtils/VisualTypePicker';

import styles from './VisualizationTypePicker.module.scss';

const visualTypeSelector = visualAddedSelectorBuilder((o) => o.visualType);

export const VisualizationTypePicker: React.FC<{
    defaultVariants: DefaultVariants;
}> = ({ defaultVariants }) => {
    const visualConfig = useVisualConfig();
    const visualType = useETPSelector(visualTypeSelector);

    const options = useMemo(
        (): DropdownIconOption[] =>
            visualConfig.layout.map((option) => {
                if (typeof option === 'string') {
                    const visual = visualConfig.visualTypes[option];
                    return { key: option, text: visual.label, icon: visual.iconName };
                }
                return {
                    key: option.default,
                    text: option.label,
                    icon: option.iconName,
                };
            }),
        [visualConfig]
    );

    return (
        <VisualTypePicker
            className={styles.visualizationTypePicker}
            data-automation-id="visual-type"
            id="visualType"
            selectedKey={defaultVariants[visualType] ?? visualType}
            options={options}
            label={APP_STRINGS.editTilePage.visualConfig.visualType}
        />
    );
};
