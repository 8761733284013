import * as React from 'react';
import { Persona, IPersonaProps } from 'office-ui-fabric-react';
import { useState, useLayoutEffect } from 'react';
import { useCore } from '../../../core';
import { GraphPerson, ExtendedPersonaProps } from './types';
import { userOrGroupToPersona } from './util';

export interface GraphPersonaProps extends Omit<IPersonaProps, 'text'> {
    person: GraphPerson;
    onLoadPersona?: (persona: ExtendedPersonaProps) => void;
}

export const GraphPersona: React.FC<GraphPersonaProps> = ({ person, onLoadPersona, ...props }) => {
    const { graphService } = useCore();

    const [personaProps, setPersonaProps] = useState<IPersonaProps>({
        text: person.type === 'user' ? person.email ?? person.id : person.id,
    });

    // useLayoutEffect due to hitting the cache. We don't want a flicker if we can help it
    useLayoutEffect(() => {
        graphService
            .getUsersOrGroups([
                {
                    id: person.id,
                    type: person.type,
                },
            ])
            .then((results) => {
                const result = results[person.id];

                if (result) {
                    const persona = userOrGroupToPersona(result);

                    setPersonaProps(persona);

                    onLoadPersona?.(persona);
                }
            })
            .catch(() => {});
        // Do not reload on change of onLoadPersona
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [person, graphService]);

    return <Persona {...props} {...personaProps} />;
};
