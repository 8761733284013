import { v4 as uuid } from 'uuid';

import { DashboardMember, DashboardPermission, DashboardPermissions } from '../../../core/domain';
import { DispatchWithThunk } from '../../../store/redux/types';
import { APP_STRINGS } from '../../../res';
import { GraphPerson, ExtendedPersonaProps } from '../../fabric/graph/types';

export const formatDuplicateAddError = (names: string[]) => {
    const strings = APP_STRINGS.forms.permissions.messages.duplicateAddError;
    return `${names.join(', ')} ${names.length === 1 ? strings.alreadyMember : strings.alreadyMembers} ${
        strings.ofThisDashboard
    }`;
};

export interface PermissionsFormReducerState {
    members: { [memberItem: string]: DashboardMember };
    permission: DashboardPermission;
    addError?: string;
    duplicateError?: string;
    listErrors: { [id: string]: string };
    loading: boolean;
    pendingAsyncOperations: Set<Promise<void | DashboardPermissions>>;
    fetchError?: boolean;
    canEdit: boolean;
    fetchedPersonas: {
        [id: string]: ExtendedPersonaProps;
    };
}

export type IPermissionsFormAction =
    | { type: 'initialize'; permissions: DashboardPermissions }
    | { type: 'addMembers'; members: DashboardMember[] }
    | { type: 'editMember'; member: DashboardMember }
    | { type: 'removeMember'; memberId: string }
    | { type: 'changeAddPermission'; permission: DashboardPermission }
    | { type: 'setAddError'; errorMessage?: string }
    | { type: 'setDuplicateError'; duplicates: GraphPerson[] }
    | {
          type: 'setListError';
          errorMessage: string;
          fetchError?: boolean;
          errorId?: string;
      }
    | { type: 'removeListError'; errorId: string; fetchError?: boolean }
    | { type: 'setLoading'; loading: boolean }
    | {
          type: 'addAsyncOperation';
          operation: Promise<void | DashboardPermissions>;
      }
    | {
          type: 'removeAsyncOperation';
          operation: Promise<void | DashboardPermissions>;
      }
    | { type: 'addFetchedPersona'; persona: ExtendedPersonaProps };

export type PermissionsFormDispatch = DispatchWithThunk<IPermissionsFormAction, PermissionsFormReducerState>;

export const fetchErrorId = 'fetchListError';

export const permissionsFormReducerFunc = (
    state: PermissionsFormReducerState,
    action: IPermissionsFormAction
): PermissionsFormReducerState => {
    switch (action.type) {
        case 'initialize': {
            const dashboardMembers: Record<string, DashboardMember> = {};
            for (const member of action.permissions.members) {
                dashboardMembers[member.id] = member;
            }

            const listErrors = { ...state.listErrors };
            delete listErrors[fetchErrorId];

            return {
                ...state,
                members: dashboardMembers,
                canEdit: action.permissions.permission === DashboardPermission.editor,
                listErrors,
            };
        }
        case 'removeMember': {
            const dashboardMembers = { ...state.members };
            delete dashboardMembers[action.memberId];
            return { ...state, members: dashboardMembers };
        }
        case 'addMembers': {
            const dashboardMembers = { ...state.members };
            for (const member of action.members) {
                dashboardMembers[member.id] = { ...member, edited: true };
            }

            return { ...state, members: dashboardMembers };
        }
        case 'editMember':
            return {
                ...state,
                members: {
                    ...state.members,
                    [action.member.id]: { ...action.member, edited: true },
                },
            };
        case 'changeAddPermission':
            return { ...state, permission: action.permission };
        case 'setAddError':
            return { ...state, addError: action.errorMessage };
        case 'setDuplicateError': {
            if (action.duplicates.length === 0 && !state.duplicateError) {
                return state;
            } else if (action.duplicates.length === 0 && state.duplicateError) {
                // Remove the error if there are no longer duplicates
                return { ...state, duplicateError: undefined };
            } else if (action.duplicates.length > 0) {
                const duplicateError = formatDuplicateAddError(
                    action.duplicates.map((p) => p.displayName ?? (p.type === 'user' ? p.email ?? p.id : p.id))
                );
                return { ...state, duplicateError };
            }
            return state;
        }
        case 'setListError': {
            const listErrors = { ...state.listErrors };
            listErrors[action.errorId ?? uuid()] = action.errorMessage;
            return { ...state, listErrors, fetchError: action.fetchError ?? false };
        }
        case 'removeListError': {
            const listErrors = { ...state.listErrors };
            delete listErrors[action.errorId];

            return { ...state, listErrors };
        }
        case 'setLoading':
            return { ...state, loading: action.loading };
        case 'addFetchedPersona': {
            const graphPerson = action.persona.graphPerson;
            const existingPersona = state.fetchedPersonas[graphPerson.id];

            if (existingPersona) {
                // Ignore incoming persona if we already have one
                return state;
            }

            const fetchedPersonas = {
                ...state.fetchedPersonas,
                [graphPerson.id]: action.persona,
            };

            return {
                ...state,
                fetchedPersonas,
            };
        }
        case 'addAsyncOperation': {
            const asyncOperations = new Set(state.pendingAsyncOperations);
            asyncOperations.add(action.operation);
            return { ...state, pendingAsyncOperations: asyncOperations };
        }
        case 'removeAsyncOperation': {
            const asyncOperations = new Set(state.pendingAsyncOperations);
            asyncOperations.delete(action.operation);
            return { ...state, pendingAsyncOperations: asyncOperations };
        }
    }
};
