import { Database } from '../../../stores/cluster';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';

/**
 * Get or create a RowDataType from cache.
 * The cache itself is in Database.rowDataTypeCache - a volatile property that saves an ID-Entity hash.
 * */
export const getFromCacheOrCreate = (
    database: Database,
    id: string,
    create: () => RowDataType,
    updateOn?: (old: RowDataType) => boolean
) => {
    let rowData = database.getFromCache(id) as RowDataType;

    if (rowData === undefined || (updateOn && updateOn(rowData))) {
        rowData = create();
        database.setInCache(id, rowData);
    }

    return rowData;
};

/**
 * Get or create a RowDataType list from cache.
 * The cache itself is in Database.rowDataTypeCache - a volatile property that saves an ID-EntityList hash.
 * */
export const getFromCacheOrCreateList = (
    database: Database,
    id: string,
    create: () => RowDataType[]
): RowDataType[] => {
    let rowData = database.getFromCache(id) as RowDataType[];

    if (rowData === undefined) {
        rowData = create();
        database.setInCache(id, rowData);
    }

    return rowData;
};
