import * as React from 'react';
import { useCallback } from 'react';
import { Text, PrimaryButton, IIconProps } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../../../res';
import { promptNewDataSource, useGlobalDispatch } from '../../../../../store/redux';

import { ETPDispatch } from '../../../../../store/editTile';
import { useDashboardStore } from '../../../../../store';

import styles from './NoDataSources.module.scss';

type NoDataSourcesProps = { dispatch: ETPDispatch };

const addButtonIcon: IIconProps = { iconName: 'Add' };

export const NoDataSources: React.FC<NoDataSourcesProps> = ({ dispatch }) => {
    const dashboardStore = useDashboardStore();
    const [globalDispatch] = useGlobalDispatch();

    const onAdd = useCallback(async () => {
        const dataSource = dashboardStore.state && (await promptNewDataSource(dashboardStore.state, globalDispatch));
        if (dataSource) {
            dispatch({ type: 'updateDataSource', dataSourceId: dataSource.id });
        }
    }, [dashboardStore, dispatch, globalDispatch]);

    return (
        <div className={styles.wrapper}>
            <svg
                className={styles.graphic}
                width="63"
                height="63"
                viewBox="0 0 63 63"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
            >
                <path
                    className={styles.themeLight}
                    d="M40.8998 59.6214L6.75862 47.351C5.50732 46.8966 4.48632 45.9656 3.91832 44.7609C3.35031 43.5563 3.2814 42.1758 3.72661 40.9206L15.9939 6.86106C16.2161 6.21984 16.5654 5.63008 17.0207 5.12711C17.4761 4.62413 18.0282 4.21828 18.6441 3.93383C19.2599 3.64939 19.9267 3.49222 20.6047 3.47171C21.2827 3.4512 21.9578 3.56779 22.5898 3.81449L56.4592 16.1062C57.1 16.3286 57.6895 16.6782 58.1922 17.1339C58.6949 17.5896 59.1005 18.1421 59.3848 18.7583C59.6692 19.3746 59.8263 20.0419 59.8469 20.7203C59.8674 21.3987 59.751 22.0742 59.5045 22.7065L47.2239 56.5961C46.7679 57.8228 45.8534 58.824 44.6734 59.3885C43.4934 59.953 42.1403 60.0365 40.8998 59.6214Z"
                />
                <path
                    className={styles.themePrimary}
                    d="M59.629 35.371L35.3711 59.6289C34.4729 60.5152 33.2621 61.0124 32.0007 61.0128C30.7394 61.0133 29.5289 60.517 28.6314 59.6314L4.36858 35.3686C3.48298 34.471 2.98671 33.2606 2.98718 31.9993C2.98764 30.7379 3.4848 29.5271 4.37105 28.6289L28.629 4.37105C29.523 3.47699 30.7353 2.97446 31.9993 2.97399C33.2632 2.97353 34.4752 3.47517 35.3686 4.36857L59.6314 28.6314C60.5248 29.5248 61.0265 30.7368 61.026 32.0007C61.0255 33.2646 60.523 34.477 59.629 35.371V35.371Z"
                />
                <path
                    className={styles.white}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33 14V8L28 16H31V22L36 14H33ZM24 22H28V27H36V22H40V27H47V29C47 30.6569 45.6569 32 44 32H20C18.3431 32 17 30.6569 17 29V27H24V22ZM44 33H20V40C20 46.2907 24.8405 51.4508 31 51.9589V60.9077C31.3265 60.9772 31.6618 61.0129 32.0007 61.0128C32.3391 61.0127 32.6739 60.9768 33 60.9073V51.9589C39.1595 51.4508 44 46.2907 44 40V33ZM32 44C33.6569 44 35 42.6569 35 41C35 39.3431 33.6569 38 32 38C30.3431 38 29 39.3431 29 41C29 42.6569 30.3431 44 32 44Z"
                />
            </svg>
            <Text className={styles.title}>{APP_STRINGS.editTilePage.noDataSource.title}</Text>
            <Text className={styles.body}>{APP_STRINGS.editTilePage.noDataSource.body}</Text>
            <PrimaryButton iconProps={addButtonIcon} className={styles.addButton} onClick={onAdd}>
                {APP_STRINGS.editTilePage.noDataSource.addDataSource}
            </PrimaryButton>
        </div>
    );
};
