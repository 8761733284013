import { caseInsensitiveComparer } from '@kusto/common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { EntityType, Function, Table } from '../../../common/types';
import { Cluster, Database } from '../../../stores/cluster';
import { RowDataType } from '../../../stores/connectionPane/RowDataType';
import { getTelemetryClient } from '../../../utils/telemetryClient';
import { ClusterRowDataType } from './ClusterRowDataType';
import { ColumnRowDataType } from './ColumnRowDataType';
import {
    DatabaseFolderRowDataType,
    externalTablesFolderName,
    functionsFolderName,
    materializedViewFolderName,
    regularTablesFolderName,
} from './DatabaseFolderRowDataType';
import { DatabaseLoadingRowDataType } from './DatabaseLoadingRowDataType';
import { DatabaseRowDataType, databaseDescendantsComparer } from './DatabaseRowDataType';
import { FolderEntityType, isTable, sortedDatabaseFolders } from '../../../common/entityTypeUtils';
import { getFromCacheOrCreateList } from './RowDataTypeCache';
import { FunctionRowDataType } from './FunctionRowDataType';
import { TableRowDataType } from './TableRowDataType';

const { trackTrace } = getTelemetryClient({
    component: 'LazyLoadingFlow',
    flow: '',
});

/**
 * Creates a list of RowDataType from a list of clusters.
 * If an entity is passed in the `expanded` parameter, create a RowDataType list for its children,
 * otherwise add a lazy loading method that will be invoked when the entity is expanded.
 * When `showOnlyFavorites` is true add RowDataTypes only for clusters and databases that has `isFavorite` equals to true
 * or clusters and databases that are in the `includes` array.
 */
export const clustersToRowData = (
    clusters: Cluster[],
    showOnlyFavorites: boolean,
    includes: (Cluster | Database)[],
    expanded: { [id: string]: boolean }
): RowDataType[] => {
    // clusters and databases into a RowDataType array
    let rowData = clusters
        .filter((cluster) => {
            if (!cluster) {
                trackTrace('cluster is null', SeverityLevel.Error);
                return false;
            }
            return true;
        })
        .map((conn) => clusterToRowData(conn, showOnlyFavorites, includes))
        .reduce((prev, curr) => prev.concat(curr), []);

    // trigger childrenLoader for items in the expanded list.
    let index = 0;
    while (index < rowData.length) {
        const item = rowData[index];
        if (expanded[item.id] && item.childrenLoader) {
            rowData = rowData.concat(item.childrenLoader(item).slice(1));
            item.childrenLoader = undefined;
        }
        index++;
    }
    return rowData;
};

const clusterToRowData = (
    cluster: Cluster,
    showOnlyFavorites: boolean,
    includes: (Cluster | Database)[]
): RowDataType[] => {
    const isClusterMarkedAsFavorite = cluster.isFavorite || includes.indexOf(cluster) >= 0;
    const showCluster = !showOnlyFavorites || isClusterMarkedAsFavorite;
    if (
        !showCluster &&
        !Array.from(cluster.databases.values()).find((db) => db.isFavorite || includes.indexOf(db) >= 0)
    ) {
        return [];
    }

    const clusterRowData = new ClusterRowDataType(cluster) as RowDataType;
    if (cluster.fetchState !== 'done') {
        return [clusterRowData];
    }

    return [clusterRowData].concat(databasesToRowData(cluster, showOnlyFavorites, isClusterMarkedAsFavorite, includes));
};

const databasesToRowData = (
    cluster: Cluster,
    showOnlyFavorites: boolean,
    isClusterMarkedAsFavorite: boolean,
    includes: (Database | Cluster)[]
): RowDataType[] => {
    const databases = Array.from(cluster.databases.values());
    return databases
        .filter((db) => {
            if (!db) {
                trackTrace('database is undefined', SeverityLevel.Error, { cluserName: cluster.name });
                return false;
            }
            return (
                !showOnlyFavorites ||
                db.isFavorite ||
                isClusterMarkedAsFavorite ||
                includes.find((entity) => db === entity)
            );
        })
        .sort((a, b) => caseInsensitiveComparer(a.prettyName || a.name, b.prettyName || b.name))
        .reduce<RowDataType[]>((accumulatedDatabases, db) => {
            const isLoading = DatabaseRowDataType.isLoading(db);
            const details = DatabaseRowDataType.details(db, isLoading);
            const shouldRefreshCache = (old: RowDataType) =>
                old.isFavorite !== db.isFavorite || details !== old.details;
            const rowData = DatabaseRowDataType.fromCacheOrCreate(db, isLoading, details, shouldRefreshCache);
            accumulatedDatabases.push(rowData);

            if (isLoading || db.fetchState === 'notStarted') {
                accumulatedDatabases.push(DatabaseLoadingRowDataType.fromCacheOrCreate(db));
            } else {
                const placeHolder = createPlaceholderChild(db);
                if (placeHolder) {
                    accumulatedDatabases.push(placeHolder);
                    rowData.childrenLoader = databaseChildrenToRowData;
                }
            }
            return accumulatedDatabases;
        }, []);
};

/**
 * Lazy loading for a database. Will be called when a database is expanded.
 */
const databaseChildrenToRowData = (dbRowData: RowDataType) => {
    const db = dbRowData.baseData as Database;
    const functions = tableOrFunctionListToRowData(
        db,
        Array.from(db.functions.values()),
        functionsFolderName,
        EntityType.FunctionsFolder,
        true // appendToRootFolder
    );
    const tables = tableOrFunctionListToRowData(
        db,
        Array.from(db.regularTables.values()),
        regularTablesFolderName,
        EntityType.TablesFolder,
        false, // appendToRootFolder
        columnsToRowData
    ).map((item) => {
        // When getting the list from cache, childrenLoader might been used and removed by previous expand
        if (isTable(item.entityType)) {
            item.childrenLoader = columnsToRowData;
        }
        return item;
    });
    const externalTables = tableOrFunctionListToRowData(
        db,
        Array.from(db.externalTables.values()),
        externalTablesFolderName,
        EntityType.ExternalTableFolder,
        true, // appendToRootFolder
        columnsToRowData
    ).map((item) => {
        // When getting the list from cache, childrenLoader might been used and removed by previous expand
        if (isTable(item.entityType)) {
            item.childrenLoader = columnsToRowData;
        }
        return item;
    });
    const materializedViewTables = tableOrFunctionListToRowData(
        db,
        Array.from(db.materializedViewTables.values()),
        materializedViewFolderName,
        EntityType.MaterializedViewTableFolder,
        true, // appendToRootFolder
        columnsToRowData
    ).map((item) => {
        // When getting the list from cache, childrenLoader might been used and removed by previous expand
        if (isTable(item.entityType)) {
            item.childrenLoader = columnsToRowData;
        }
        return item;
    });
    let rows = functions.concat(materializedViewTables).concat(tables).concat(externalTables);

    rows = rows.sort(databaseDescendantsComparer);

    return rows;
};

const tableOrFunctionListToRowData = (
    database: Database,
    // eslint-disable-next-line @typescript-eslint/ban-types
    entities: (Table | Function)[],
    folderRootName: string,
    folderRootEntity: FolderEntityType,
    appendToRootFolder: boolean,
    childrenLoader?: (base: RowDataType, first?: boolean) => RowDataType[]
) =>
    getFromCacheOrCreateList(database, '$' + folderRootName + database.id, () => {
        const folderSet = new Set<string>();
        const entityRowDataList = entities
            .sort((a, b) => caseInsensitiveComparer(a.name, b.name))
            .map((entity) => {
                // non-empty paths begin from a folder named "Tables"
                const path = entity.folder
                    ? [folderRootName].concat(entity.folder.split(/[\\/]/g))
                    : appendToRootFolder
                    ? [folderRootName]
                    : [];

                // Collect all folders we see on the way.
                // when we bump into A\B\C we'll collect A, A\B, A\B\C
                for (let i = 1; i <= path.length; ++i) {
                    const slice = path.slice(0, i);
                    const folder = slice.join('/');
                    folderSet.add(folder);
                }

                if (isTable(entity.entityType)) {
                    const tableRowDataType = TableRowDataType.fromCacheOrCreate(entity as Table, database);
                    tableRowDataType.childrenLoader = columnsToRowData;
                    return tableRowDataType;
                } else if (entity.entityType === EntityType.Function) {
                    return FunctionRowDataType.fromCacheOrCreate(entity, database);
                } else {
                    // should never happen. Raise alert.
                    throw Error(`Unexpected entity type: ${entity.entityType}`);
                }
            });

        const folders = Array.from(folderSet)
            .sort(caseInsensitiveComparer)
            .map((folderString: string) => {
                const entityType = folderString === folderRootName ? folderRootEntity : EntityType.Folder;
                return DatabaseFolderRowDataType.fromCacheOrCreate(database, folderString, entityType);
            });

        const childrenPlaceHolder = childrenLoader
            ? entityRowDataList
                  .map((entityRowData) => childrenLoader(entityRowData, true)[0])
                  .filter((column) => column)
            : [];
        return folders.concat(entityRowDataList).concat(childrenPlaceHolder);
    });

/**
 * Lazy loading for columns. Will be called when a table is expanded.
 */
const columnsToRowData = (tableRowData: RowDataType, filterFirst?: boolean) => {
    const db = tableRowData.baseData as Database;
    const table = db.tables.get(tableRowData.id);

    if (!table) {
        return [];
    }

    return getFromCacheOrCreateList(db, '$table_columns/' + (filterFirst === true) + table.id, () =>
        Object.values(table.columns)
            .filter((col, index) => filterFirst !== true || index <= 0)
            .map((col) => ColumnRowDataType.fromCacheOrCreate(col, table, db))
    );
};

/**
 * Create a placeholder child for a database.
 * Why placeholder? This will allow the database to be expandable because a node is expandable only if it has at least one child.
 *
 * The placeholder child can be a root folder (Functions, External Table, Materialized Views and so on).
 * or if there are no entities that belongs to a root folder, it will return the first table with no folders.
 */
const createPlaceholderChild = (database: Database): RowDataType | undefined => {
    // Check if there are Functions, External Tables, Materialize Views or tables with folders.
    let placeHolder: RowDataType | undefined;
    const hasFunctions = database.functions.size > 0;
    const tables = Array.from(database.tables.values());
    let [hasExternalTableWithFolder, hasMaterializeViewWithFolder, hasTableWithFolder] = [false, false, false];
    tables.forEach((table) => {
        if (table.entityType === EntityType.ExternalTable) {
            hasExternalTableWithFolder = true;
        } else if (table.entityType === EntityType.MaterializedViewTable) {
            hasMaterializeViewWithFolder = true;
        } else if (!!table.folder) {
            hasTableWithFolder = true;
        }
    });

    // create a root folder, keeping the order in sortedRootFolders.
    for (const rootFolder of sortedDatabaseFolders) {
        if (rootFolder === EntityType.FunctionsFolder && hasFunctions) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                database,
                functionsFolderName,
                EntityType.FunctionsFolder
            );
        }
        if (rootFolder === EntityType.MaterializedViewTableFolder && hasMaterializeViewWithFolder) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                database,
                materializedViewFolderName,
                EntityType.MaterializedViewTableFolder
            );
        }
        if (rootFolder === EntityType.ExternalTableFolder && hasExternalTableWithFolder) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                database,
                externalTablesFolderName,
                EntityType.ExternalTableFolder
            );
        }
        if (rootFolder === EntityType.TablesFolder && hasTableWithFolder) {
            return DatabaseFolderRowDataType.fromCacheOrCreate(
                database,
                regularTablesFolderName,
                EntityType.TablesFolder
            );
        }
    }

    // if there are no functions or folders, show the first table.
    if (tables.length > 0) {
        placeHolder = TableRowDataType.fromCacheOrCreate(firstTable(tables), database);
    }
    return placeHolder;
};

/**
 * A helper method that returns the first table in tables using case-insensitive comparison.
 * (For performance reasons avoid calling sort.)
 */
const firstTable = (tables: Table[]): Table => {
    let first = tables[0];
    for (let i = 1; i < tables.length; i++) {
        const table = tables[i];
        if (table.name.localeCompare(first.name, undefined, { sensitivity: 'base' }) === -1) {
            first = table;
        }
    }
    return first;
};
