import { pick } from 'lodash';
import { v4 as uuid } from 'uuid';

import { ITile, VisualOptions, VisualConfig, ParameterConfig, ParameterValue, QueryHash } from '../../domain';
import { DispatchWithThunk } from '../redux/types';
import { APP_CONSTANTS, APP_STRINGS } from '../../res';
import {
    convertToEditPageVisualOptions,
    resetUnusedVisualOptions,
    buildSampleKustoQuery,
} from '../../pages/editTile/lib/util';
import type { ETPManagedConfigKey, ETPVisualOptions } from '../../pages/editTile/types';
import { etpVisualOptionsDefaults } from '../../pages/editTile/constants';

export interface ETPQuery {
    readonly query: string;
    readonly parameterSelections: ParameterValue[];
    readonly usedVariables: Set<string>;
    readonly dataSourceId: undefined | string;
    readonly tileId: string;
}

/**
 * Present if a visual has been added
 */
export interface ETPVisual {
    readonly options: ETPVisualOptions;
    readonly visualType: string;
    readonly configurationOpen: boolean;
}

export interface ETPQueryState extends ETPQuery {
    type: 'query';
    visual?: ETPVisual;
    renderedQuery?: ETPQuery;
    usedVariables: Set<string>;
    parameterSelections: ParameterValue[];
    title: string;
    visualPane: 'results' | 'visual';
    initialQueryHash: undefined | QueryHash;
}

export interface ETPMarkdownState {
    type: 'markdown';
    title: string;
    query: string;
}

export type ETPLoadingState = { type: 'loading' };
export type ETPErrorState = { type: 'error'; message: string };

export type ETPState = ETPLoadingState | ETPErrorState | ETPQueryState | ETPMarkdownState;

export interface UpdateYAxisLimit {
    type: 'updateYAxisLimit';
    property: 'yAxisMinimumValue' | 'yAxisMaximumValue';
    value: string;
}

type VisualOptionAction =
    | { type: 'updateVisualOptions'; options: Partial<ETPVisualOptions> }
    | { type: 'updateVisualType'; visualType: string }
    | { type: 'updateDataSource'; dataSourceId: undefined | string }
    | { type: 'updateTitle'; title: string }
    | { type: 'resetVisualOptions'; options: ETPManagedConfigKey[] };

export type ETPAction =
    | {
          type: 'initializeFromTile';
          tile: ITile;
          visualConfig: VisualConfig;
          queryHash: undefined | QueryHash;
          parameterSelections: ParameterValue[];
      }
    | {
          type: 'initializeNewQuery';
          availableParameters: readonly ParameterConfig[];
          defaultDataSourceId: undefined | string;
          parameterSelections: ParameterValue[];
      }
    | { type: 'reset' }
    | { type: 'initializeNewMarkdown' }
    | { type: 'runQuery' }
    | { type: 'updateQuery'; query: string }
    | { type: 'updateParameterSelections'; parameterSelections: ParameterValue[] }
    | {
          type: 'updateUsedVariables';
          usedParameters: Set<string>;
      }
    | { type: 'setVisualPane'; pane: 'results' | 'visual' }
    | { type: 'toggleVisualConfigurationOpen' }
    | { type: 'addVisual' }
    | { type: 'loadingError'; message: string }
    | UpdateYAxisLimit
    | VisualOptionAction;

export type ETPDispatch = DispatchWithThunk<ETPAction, ETPState>;

export const etpReducer = (state: ETPState, action: ETPAction): ETPState => {
    switch (action.type) {
        case 'initializeFromTile':
            if (action.tile.visualType === 'markdownCard') {
                return {
                    type: 'markdown',
                    query: action.tile.query,
                    title: action.tile.title,
                };
            } else {
                const visualOptions: VisualOptions | undefined = {
                    ...APP_CONSTANTS.visuals.defaultVisualOptions,
                    ...resetUnusedVisualOptions(
                        action.tile.visualOptions,
                        action.tile.visualType,
                        action.visualConfig.visualTypes
                    ),
                };

                const renderedQuery: ETPQuery = {
                    query: action.tile.query,
                    dataSourceId: action.tile.dataSourceId,
                    tileId: action.tile.id,
                    parameterSelections: action.parameterSelections,
                    usedVariables: action.tile.usedVariables,
                };

                return {
                    type: 'query',
                    visual: visualOptions && {
                        visualType: action.tile.visualType,
                        configurationOpen: true,
                        options: convertToEditPageVisualOptions(visualOptions),
                    },
                    title: action.tile.title,
                    visualPane: 'visual',
                    ...renderedQuery,
                    renderedQuery,
                    initialQueryHash: action.queryHash,
                };
            }
        case 'initializeNewQuery':
            return {
                type: 'query',
                query: buildSampleKustoQuery(action.availableParameters),
                title: APP_STRINGS.editTilePage.newTileTitle,
                visual: undefined,
                usedVariables: new Set(),
                dataSourceId: action.defaultDataSourceId,
                visualPane: 'results',
                initialQueryHash: undefined,
                tileId: uuid(),
                parameterSelections: action.parameterSelections,
            };
        case 'initializeNewMarkdown':
            return {
                type: 'markdown',
                query: APP_STRINGS.editTilePage.querySamples.sampleMarkdown,
                title: APP_STRINGS.editTilePage.newTileTitle,
            };
        case 'loadingError':
            return { type: 'error', message: action.message };
        case 'reset':
            if (state.type === 'loading') {
                return state;
            }
            return { type: 'loading' };
    }

    if (state.type === 'loading' || state.type === 'error') {
        return state;
    }

    switch (action.type) {
        case 'updateQuery':
            return { ...state, query: action.query };
        case 'updateTitle':
            return { ...state, title: action.title };
    }

    if (state.type !== 'query') {
        return state;
    }

    switch (action.type) {
        case 'runQuery': {
            const { query, dataSourceId, tileId, parameterSelections, usedVariables } = state;
            return {
                ...state,
                renderedQuery: {
                    query,
                    dataSourceId,
                    tileId,
                    usedVariables,
                    parameterSelections,
                },
            };
        }
        case 'updateDataSource': {
            const dirty = state.renderedQuery?.query === state.query;
            const renderedQuery: ETPQuery | undefined = dirty
                ? {
                      query: state.query,
                      usedVariables: state.usedVariables,
                      parameterSelections: state.parameterSelections,
                      dataSourceId: action.dataSourceId,
                      tileId: state.tileId,
                  }
                : state.renderedQuery;

            return { ...state, renderedQuery, dataSourceId: action.dataSourceId };
        }
        case 'updateParameterSelections': {
            const dirty = state.query !== state.renderedQuery?.query;
            return {
                ...state,
                parameterSelections: action.parameterSelections,
                renderedQuery: dirty
                    ? state.renderedQuery
                    : {
                          query: state.query,
                          parameterSelections: action.parameterSelections,
                          usedVariables: state.usedVariables,
                          dataSourceId: state.dataSourceId,
                          tileId: state.tileId,
                      },
            };
        }
        case 'updateUsedVariables':
            return {
                ...state,
                usedVariables: action.usedParameters,
            };
        case 'setVisualPane': {
            return { ...state, visualPane: action.pane };
        }
        case 'addVisual': {
            if (state.visual) {
                return state;
            }
            const visualOptions = {
                options: etpVisualOptionsDefaults,
                visualType: APP_CONSTANTS.visuals.defaultVisualType,
                configurationOpen: true,
            };
            return { ...state, visual: visualOptions, visualPane: 'visual' };
        }
    }

    if (state.visual === undefined) {
        return state;
    }

    switch (action.type) {
        case 'updateVisualType':
            return {
                ...state,
                visual: {
                    ...state.visual,
                    visualType: action.visualType,
                },
            };
        case 'updateVisualOptions':
            return {
                ...state,
                visual: {
                    ...state.visual,
                    options: { ...state.visual.options, ...action.options },
                },
            };
        case 'toggleVisualConfigurationOpen': {
            const visual = {
                ...state.visual,
                configurationOpen: !state.visual.configurationOpen,
            };
            return { ...state, visual };
        }
        case 'updateYAxisLimit':
            return {
                ...state,
                visual: {
                    ...state.visual,
                    options: updateYAxisLimit(state.visual.options, action),
                },
            };
        case 'resetVisualOptions': {
            const visual: ETPVisual = {
                ...state.visual,
                options: {
                    ...state.visual.options,
                    ...pick(etpVisualOptionsDefaults, ...action.options),
                },
            };
            return { ...state, visual };
        }
    }
};

export const updateYAxisLimit = (visualOptions: ETPVisualOptions, action: UpdateYAxisLimit): ETPVisualOptions => {
    let { yAxisMinimumValue, yAxisMaximumValue } = visualOptions;
    switch (action.property) {
        case 'yAxisMaximumValue':
            yAxisMaximumValue = action.value;
            break;
        case 'yAxisMinimumValue':
            yAxisMinimumValue = action.value;
            break;
    }
    const min = parseInt(yAxisMinimumValue, 10);
    const max = parseInt(yAxisMaximumValue, 10);
    if (!isNaN(min) && !isNaN(max)) {
        switch (action.property) {
            case 'yAxisMinimumValue':
                if (max < min) {
                    yAxisMaximumValue = min.toString();
                }
                break;
            case 'yAxisMaximumValue':
                if (min > max) {
                    yAxisMinimumValue = max.toString();
                }
                break;
        }
    }
    return { ...visualOptions, yAxisMaximumValue, yAxisMinimumValue };
};
