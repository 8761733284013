import * as React from 'react';

import { useGlobalSelector } from '../../store/redux/context';
import { IReduxState } from '../../store/redux/types';

const activeFormsSelector = (s: IReduxState) => s.shared.activeForms;

export const ManagedForms: React.FC = () => {
    const activeForms = useGlobalSelector(activeFormsSelector);

    return (
        <>
            {Object.entries(activeForms).map(([key, Form]) => (
                <Form key={key} />
            ))}
        </>
    );
};
