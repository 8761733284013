import { APP_STRINGS } from '../../../res';
import { tile } from '../tile';

import { IDashboardV10 } from './10';

export interface IDashboardV11 extends Omit<IDashboardV10, '$schema' | 'tiles'> {
    $schema: '11';
    tiles: tile.v7.ITileV7[];
}

export function up(prev: IDashboardV10): IDashboardV11 {
    return {
        ...prev,
        $schema: '11',
        tiles: prev.tiles.map(tile.v7.up),
    };
}

export function down(prev: IDashboardV11, warnings: string[]): IDashboardV10 {
    const crossFilterRemovedFrom: string[] = [];

    const tiles: tile.v6.ITileV6[] = prev.tiles.map((t) => {
        const res = tile.v7.down(t);
        if (res.removedCrossFilter) {
            crossFilterRemovedFrom.push(res.data.title);
        }
        return res.data;
    });

    if (crossFilterRemovedFrom.length !== 0) {
        warnings.push(APP_STRINGS.migration.tileV7.removedCrossFilterConfig + crossFilterRemovedFrom.join(', '));
    }

    return { ...prev, $schema: '10', tiles };
}
