/**
 * AuthState is an object that is passed in the authentication flow.
 * The state is added when redirecting to Microsoft identity platform to get an auto token
 * and it is passed back when redirecting back from Microsoft identity platform to the web site.
 */
export interface AuthState {
    /**
     * Saves the base path name - 'PR<number>', 'v<version>' or master.
     * The authentication providers should send the basePathName during login and handle it when auth redirected back to the web site.
     *
     * So network traffic will be:
     * 1. https://kustowebdf.azurewebsites.net/PR4914942/ (redirected to Microsoft identity platform with basePathName in the state.)
     * 2. https://kustowebdf.azurewebsites.net/ (Redirect based on basePathName)
     * 3. https://kustowebdf.azurewebsites.net/PR4914942/
     *
     * This means that the root domain (https://kustowebdf.azurewebsites.net/) should always have a version that supports "Redirect based on basePathName".
     *
     * Motivation:
     * The goal is to deploy each Pull Request (PR) and each Release Candidate (RC) to a different folder so PR 123 can be tested from
     * https://kustowebdf.azurewebsites.net/PR123 and RC v2.1.3 can be tested from https://preview.dataexplorer.azure.com/v2.3.4.
     * Azure Dev Ops pipelines handles the deployment to PR<version> and v<version>.
     * The main problem is redirecting during an authentication flow.
     * MSAL supports redirecting to a specific domain. If, during login, the redirect URL is set to https://kustowebdf.azurewebsites.net/PR4914942/
     * MSAL will redirect to that URL after auth finishes as long as https://kustowebdf.azurewebsites.net/PR4914942/ is an approved replied URL
     * for KWE's app in the First Party Portal.
     * However, the First Party Portal doesn't allow setting repliedURL with wild cards, those are considered security risk.
     * So this `https://kustowebdf.azurewebsites.net/PR*` is not allowed and therefore this type of redirection doesn't work.
     * So the solution is to always redirect to the root domain (https://kustowebdf.azurewebsites.net or https://preview.dataexplorer.azure.com)
     * and let the app in the root domain to redirect to https://<domain>/<basePathName> (e.g. https://kustowebdf.azurewebsites.net/PR4914942/).
     * To do that, the basePathName should be passed across the auth flow, and Auth State is used for that.
     */
    basePathName?: string;
}

export function stringifyAuthState(authState: AuthState): string {
    return JSON.stringify(authState);
}

export function parseAuthState(json: string): AuthState {
    try {
        const authStateJson = JSON.parse(json);
        if (authStateJson && typeof authStateJson === 'object') {
            return authStateJson as AuthState;
        }
    } catch {}

    return {};
}
