import * as React from 'react';
import { IPanelProps, Panel } from 'office-ui-fabric-react';
import { ReactNode, useCallback } from 'react';

import { RTDPanel } from '../panelsWrapped';

import { PanelFooter } from './PanelFooter';

export interface PanelWithFooterProps extends IPanelProps {
    footer?: ReactNode;
    footerLeft?: boolean;

    /**
     * If true, does not require RequestGlobalLoadingProvider to be mounted and uses
     * the standard Fabric Panel
     */
    disableGlobalLoading?: boolean;
}

export const PanelWithFooter: React.FC<PanelWithFooterProps> = ({
    footer,
    footerLeft,
    disableGlobalLoading,
    ...props
}) => {
    const onRenderFooter = useCallback(
        () => (footer ? <PanelFooter footerLeft={footerLeft}>{footer}</PanelFooter> : null),
        [footer, footerLeft]
    );

    const FinalPanel = disableGlobalLoading ? Panel : RTDPanel;

    return <FinalPanel {...props} onRenderFooter={onRenderFooter} />;
};
