/**
 * Map over an array, omitting any null or undefined values
 */
export const compactMap = <T, TOutput>(
    input: readonly T[],
    mapper: (element: T, index: number) => TOutput
): Array<Exclude<TOutput, undefined | null>> =>
    input.reduce(
        (
            acc: Array<Exclude<TOutput, undefined | null>>,
            element: T,
            index: number
        ): Array<Exclude<TOutput, undefined | null>> => {
            const resultElement = mapper(element, index);
            if (resultElement !== null && resultElement !== undefined) {
                acc.push(resultElement as Exclude<TOutput, undefined | null>);
            }

            return acc;
        },
        []
    );
