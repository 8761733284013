import * as React from 'react';
import { assertNever } from 'office-ui-fabric-react';
import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { APP_STRINGS } from '../../../../res';
import { DurationPicker } from '../../../../components/parameter';
import { TDuration } from '../../../../domain';

import { IEditParameterPageComponentProps } from '../../types';
import { EppReducerState, EppDurationOptions } from '../../reducer';

import pageStyles from '../../EditParameterPage.module.scss';

import { FreetextParameterConfig as TextFieldForm } from './FreetextParamConfig';
import { QueryDropdownParamConfig } from './QueryDropdownParamConfig/QueryDropdownParamConfig';
import { StaticDropdownParamConfig } from './StaticDropdownParamConfig/StaticDropdownParamConfig';

interface DurationDefaultConfigProps extends IEditParameterPageComponentProps {
    durationOptions: EppDurationOptions;
}

const DurationDefaultConfigForm: React.FC<DurationDefaultConfigProps> = observer(function DurationDefaultForm({
    durationOptions,
    dispatch,
}) {
    const onChangeDuration = useCallback(
        (duration: TDuration) =>
            dispatch({
                type: 'setDurationDefaultValue',
                value: duration,
            }),
        [dispatch]
    );

    return (
        <DurationPicker
            className={pageStyles.input}
            name={APP_STRINGS.editParameterPage.defaultValue}
            label={APP_STRINGS.editParameterPage.defaultValue}
            duration={durationOptions.defaultValue}
            isPill={false}
            onDurationUpdate={onChangeDuration}
        />
    );
});

export interface ParameterValueConfigProps extends IEditParameterPageComponentProps {
    state: EppReducerState;
    getState: () => EppReducerState;
}

export const DataSourceAndValueConfig: React.FC<ParameterValueConfigProps> = ({ state, dispatch, getState }) => {
    if (state.union.kind === 'duration') {
        return <DurationDefaultConfigForm dispatch={dispatch} durationOptions={state.union} />;
    }

    switch (state.union.union.kind) {
        case 'freetext':
            return (
                <TextFieldForm
                    defaultValueEnteredText={state.union.union.defaultValueEnteredText}
                    error={state.errors.defaultValueError}
                    dispatch={dispatch}
                />
            );
        case 'static':
            return (
                <StaticDropdownParamConfig
                    state={state}
                    dispatch={dispatch}
                    basicParamOptions={state.union}
                    dropdownOptions={state.union.union}
                />
            );
        case 'query':
            return (
                <QueryDropdownParamConfig
                    state={state}
                    getState={getState}
                    dispatch={dispatch}
                    basicParamOptions={state.union}
                    dropdownOptions={state.union.union}
                />
            );
        default:
            assertNever(state.union.union);
    }
};
