import * as React from 'react';
import { APP_STRINGS } from '../../res';
import styles from './UnsupportedVisualType.module.scss';

interface IUnsupportedVisualTypeProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
    type: string;
}

export const UnsupportedVisualType: React.FC<IUnsupportedVisualTypeProps> = ({ id, type, ...props }) => {
    return (
        <p {...props} id={id} data-automation-id="unsupported-visual-type">
            {APP_STRINGS.visuals.unknownVisualTypeMessage.start} <span className={styles.text}>{type}</span>{' '}
            {APP_STRINGS.visuals.unknownVisualTypeMessage.end}
        </p>
    );
};
