import { ICatalogPageState, CatalogPageActions } from './types';

export const initialCatalogPageState: ICatalogPageState = {
    catalogSearch: '',
    catalogSortField: 'title',
    catalogSortDescending: false,
    catalogTab: 'all',
};

export const catalogPageReducer = (
    // eslint-disable-next-line default-param-last
    state: ICatalogPageState = initialCatalogPageState,
    action: CatalogPageActions
): ICatalogPageState => {
    switch (action.type) {
        case 'setCatalogSearch': {
            return {
                ...state,
                catalogSearch: action.catalogSearch,
            };
        }
        case 'setCatalogSort': {
            return {
                ...state,
                catalogSortField: action.catalogSortField,
                catalogSortDescending: action.catalogSortDescending,
            };
        }
        case 'setCatalogTab': {
            return {
                ...state,
                catalogTab: action.catalogTab,
            };
        }
        case 'setDashboardList': {
            return {
                ...state,
                dashboardList: action.dashboardList,
            };
        }
        case 'removedDashboardListItem': {
            const dashboardList = state.dashboardList?.filter((item) => item.id !== action.dashboardId);
            return { ...state, dashboardList };
        }
    }
    return state;
};
