import * as React from 'react';
import { useRef, useCallback, useMemo, useEffect, RefObject } from 'react';
import { withSize, SizeMeProps } from 'react-sizeme';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import { when, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { APP_CONSTANTS, APP_STRINGS } from '../../../res';
import { NoData } from '../../../components';
import { calculateTileYScrollPosition } from '../../../domain';
import { DashboardLoaded, DashboardStore, useDashboardStore } from '../../../store/dashboard';

import { NoDataTile } from './NoDataTile';

// TODO: Bundle these
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import styles from './DashboardTiles.module.scss';
import { RGLTile } from './RGLTile';

const rglMargins: [number, number] = [
    APP_CONSTANTS.dashboardPage.grid.tilePadding,
    APP_CONSTANTS.dashboardPage.grid.tilePadding,
];

const rglContainerPadding: [number, number] = [
    APP_CONSTANTS.dashboardPage.grid.containerHorizontalPadding,
    APP_CONSTANTS.dashboardPage.grid.containerVerticalPadding,
];

const gridTotalMinWidth =
    APP_CONSTANTS.dashboardPage.grid.minimumGridWidth + APP_CONSTANTS.dashboardPage.grid.containerHorizontalPadding * 2;

function useScrollToTile(
    scrollContainer: React.RefObject<HTMLDivElement | null>,
    dashboardLoaded: DashboardLoaded,
    store: DashboardStore
) {
    useEffect(
        () =>
            when(
                () => store.pendingScrollToTile !== undefined,
                () => {
                    const tileId = store.pendingScrollToTile;
                    if (tileId === undefined) {
                        return;
                    }

                    const tile = dashboardLoaded.tilesRecord[tileId];

                    if (tile === undefined) {
                        return;
                    }

                    if (scrollContainer.current) {
                        const calculatedYOffset = calculateTileYScrollPosition(
                            tile,
                            scrollContainer.current.clientHeight
                        );

                        scrollContainer.current.scrollTop = calculatedYOffset;
                    }
                    store.pendingScrollToTile = undefined;
                }
            ),
        [dashboardLoaded, scrollContainer, store]
    );
}

export interface IDashboardTilesProps {
    pageState: DashboardLoaded;
    scrollContainer: RefObject<HTMLDivElement | null>;
    /**
     * This should be validated and errors handled before getting passed in.
     */
    selectedPageId: string;
}

interface InnerDashboardTileProps extends IDashboardTilesProps, SizeMeProps {}

export const DashboardTiles = withSize({ monitorWidth: true })(
    observer<InnerDashboardTileProps>(({ size, pageState, selectedPageId, scrollContainer }) => {
        const store = useDashboardStore();
        const isInitialRenderRef = useRef(true);
        useScrollToTile(scrollContainer, pageState, store);

        const selectedPageLayout = pageState.tilesLayout[selectedPageId];

        const onDragStart = useCallback(
            (_layout: unknown, _oldItem: unknown, _newItem: unknown, _placeholder: unknown, event: MouseEvent) => {
                const { target } = event;

                if (!(target instanceof HTMLElement)) {
                    return false;
                }

                return runInAction(() => pageState.changes !== undefined);
            },
            [pageState]
        );

        useEffect(() => {
            if (!isInitialRenderRef.current) {
                // No dashboard, haven't really "rendered" yet
                return;
            }

            isInitialRenderRef.current = false;
        }, []);

        const tileChildren = useMemo(
            () =>
                selectedPageLayout.map((tileLayout) => (
                    // div must exist for RGL to associate layouts to tiles
                    // Additionally, it provides sizing for expanding tiles
                    <div key={tileLayout.i}>
                        <RGLTile dashboardLoaded={pageState} tileId={tileLayout.i} layout={tileLayout} />
                    </div>
                )),
            [selectedPageLayout, pageState]
        );

        const onLayoutChange = useCallback(
            (newLayout: Layout[]) =>
                pageState.changePageLayout(
                    selectedPageId,
                    newLayout.map((layout) => ({
                        ...layout,
                        // Because RGL grid isn't generic across the layouts we provide,
                        // we need to assert that minW and minH are present
                        /* eslint-disable @typescript-eslint/no-non-null-assertion */
                        minW: layout.minW!,
                        minH: layout.minH!,
                        valid: layout.minW! <= layout.w && layout.minH! <= layout.h,
                        /* eslint-enable @typescript-eslint/no-non-null-assertion */
                    }))
                ),
            [selectedPageId, pageState]
        );

        if (selectedPageLayout.length === 0) {
            if (pageState.changes !== undefined) {
                return <NoDataTile />;
            }
            return (
                <div className={styles.noData}>
                    <NoData message={APP_STRINGS.dashboardPage.noDashboards} />
                </div>
            );
        }

        const rglClass =
            pageState.changes !== undefined ? `${styles.reactGridLayout} ${styles.resize}` : styles.reactGridLayout;

        const containerWidth = size.width;
        // While this should always be present based on the config we're passing
        // to react-sizeme's withSize HOC, it's types don't guarantee it
        if (containerWidth === null) {
            throw new Error('Missing react-sizeme width');
        }
        const width = Math.max(containerWidth, gridTotalMinWidth);

        return (
            <div className={styles.reactGridLayoutWrapper}>
                <ReactGridLayout
                    className={!isInitialRenderRef.current ? `${rglClass} ${styles.animate}` : rglClass}
                    style={{ width }}
                    layout={selectedPageLayout}
                    cols={APP_CONSTANTS.dashboardPage.grid.columnCount}
                    rowHeight={APP_CONSTANTS.dashboardPage.grid.rowHeight}
                    width={width}
                    margin={rglMargins}
                    onLayoutChange={onLayoutChange}
                    // RGL now supports changing isDraggable/isResizable without remounting,
                    // but there appears to be other issues with it, so we block drag events
                    // if we're not editing instead
                    isDraggable
                    isResizable
                    draggableHandle={`.${APP_CONSTANTS.dashboardPage.draggableTitleHeaderClassName}`}
                    draggableCancel={`.${APP_CONSTANTS.dashboardPage.notDraggableTitleHeaderClassName}`}
                    onDragStart={onDragStart}
                    containerPadding={rglContainerPadding}
                >
                    {tileChildren}
                </ReactGridLayout>
            </div>
        );
    })
);
