import { tile } from '../tile';

import { IDashboardV4 } from './4';

export interface IDashboardV5 extends Omit<IDashboardV4, '$schema'> {
    $schema: '5';
}

/**
 * Applies fix to corrupted tiles. See tilesV3 up migration for details
 */
export function up(prev: IDashboardV4): IDashboardV5 {
    const parameterIdsToNames = new Map<string, string[]>();

    for (const p of prev.parameters) {
        const names = p.type === 'duration' ? [p.beginVariableName, p.endVariableName] : [p.variableName];
        parameterIdsToNames.set(p.id, names);
    }

    return {
        ...prev,
        $schema: '5',
        tiles: prev.tiles.map((t) => tile.v3.up(t, parameterIdsToNames)),
    };
}
