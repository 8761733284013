import { APP_CONSTANTS } from '../res';
import type { AutoRefreshUpdateInterval } from '../core/domain';

/**
 * Validates whether the provided auto refresh `currentInterval` is valid given the bounds provided by the `minInterval`
 * @param currentInterval The currently configured auto refresh interval to validate
 * @param minInterval The minimum auto refresh interval
 */
export const validateAutoRefreshInterval = (
    currentInterval: AutoRefreshUpdateInterval,
    minInterval: AutoRefreshUpdateInterval | undefined
): boolean => {
    const startIndex = minInterval ? APP_CONSTANTS.orderedAutoRefreshIntervals.indexOf(minInterval) : 0;

    if (startIndex < 0) {
        throw new Error(`Could not find minInterval ${minInterval}. Is the auto refresh configuration correct?`);
    }

    const availableOptions = APP_CONSTANTS.orderedAutoRefreshIntervals.slice(startIndex);

    return availableOptions.includes(currentInterval);
};
