import * as React from 'react';
import { useMemo, useEffect, useState, useCallback } from 'react';
import fuzzysort from 'fuzzysort';
import { PanelType, SearchBox, MessageBarType, DefaultButton, Spinner } from 'office-ui-fabric-react';

import { APP_STRINGS } from '../../../res';
import { useCore } from '../../../core';
import { DashboardPermission, DashboardMember, DashboardPermissions } from '../../../core/domain/membership';
import { useUrlCallback } from '../../../domain/navigation';
import { useThunkReducer, useIsMounted, TFormComponent, formActionMacro, copyToClipboard } from '../../../common';
import { ButtonWithCallout, PanelWithFooter, GraphPerson } from '../../fabric';
import { RtdHeader } from '../../RtdHeader';
import { useGlobalDispatch } from '../../../store/redux';
import { SpinnerText } from '../../lib';

import {
    permissionsFormReducerFunc,
    PermissionsFormDispatch,
    PermissionsFormReducerState,
    fetchErrorId,
} from './permissionsFormReducer';
import { AddPermission } from './AddPermission';
import { PermissionsList } from './PermissionsList';
import { MessageBanner } from './MessageBanner';
import { createClosePermissionsAction } from './ClosePanelForm';

import styles from './DashboardPermissions.module.scss';

const noop = () => {};

export interface IDashboardPermissionFormArgs {
    dashboardId: string;
    dashboardTitle: string;
}

export const UpdatePermissionsPanel: TFormComponent<IDashboardPermissionFormArgs> = ({
    onClose,
    dashboardId,
    dashboardTitle,
}) => {
    const [globalDispatch] = useGlobalDispatch();
    const { membershipService } = useCore();
    const [searchQuery, setSearchQuery] = useState('');
    const getUrl = useUrlCallback();

    const [state, dispatch] = useThunkReducer(permissionsFormReducerFunc, {
        members: {},
        permission: DashboardPermission.viewer,
        listErrors: {},
        loading: true,
        fetchError: false,
        canEdit: false,
        fetchedPersonas: {},
        pendingAsyncOperations: new Set<Promise<void | DashboardPermissions>>(),
    });

    const { members, fetchedPersonas, listErrors, loading, fetchError, canEdit, pendingAsyncOperations } = state;
    const dialogIsMounted = useIsMounted();

    const permissionListItems = useMemo(() => Object.keys(members).map((key) => ({ ...members[key], key })), [members]);

    const sortedList = useMemo(() => {
        const isFiltering = !!(permissionListItems && searchQuery);
        if (!isFiltering) {
            return permissionListItems;
        }

        const graphPeople = permissionListItems.map(
            (
                item
            ): GraphPerson & {
                member: DashboardMember & { key: string };
            } => {
                const persona = fetchedPersonas[item.key];

                return persona
                    ? { ...persona.graphPerson, member: item }
                    : {
                          id: item.id,
                          type: item.type,
                          displayName: item.email,
                          member: item,
                      };
            }
        );

        const results = fuzzysort.go(searchQuery, graphPeople, {
            keys: ['displayName', 'upn', 'id'],
            threshold: -100,
            scoreFn: ([displayNameResult, upnResult, idResult]) =>
                Math.max(
                    // Attempt to weight the search
                    displayNameResult ? displayNameResult.score + 200 : -1000,
                    upnResult ? upnResult.score : -1000,
                    idResult ? idResult.score - 200 : -1000
                ),
        });

        return results.map((result) => result.obj.member);
    }, [searchQuery, fetchedPersonas, permissionListItems]);

    const onChangeSearch = useCallback(
        (_e: unknown, query?: string) => {
            if (query !== undefined) {
                setSearchQuery(query);
            }
        },
        [setSearchQuery]
    );

    const onClearSearch = useCallback((_e: unknown) => setSearchQuery(''), [setSearchQuery]);

    const copyLink = useCallback(() => {
        const path = getUrl(`/${dashboardId}`) as string;
        // Generates a new url replacing the path
        const url = new URL(path, window.location.href);
        copyToClipboard(url.href);
    }, [dashboardId, getUrl]);

    const fetchPermissions = useCallback(() => {
        dispatch({
            type: 'setLoading',
            loading: true,
        });

        const promise = membershipService.getPermissions(dashboardId);
        dispatch({ type: 'addAsyncOperation', operation: promise });

        promise
            .then((fetchedPermissions) => {
                if (!dialogIsMounted.current) {
                    return;
                }

                dispatch({
                    type: 'initialize',
                    permissions: fetchedPermissions,
                });
            })
            .catch(() => {
                if (fetchError || !dialogIsMounted.current) {
                    return;
                }

                dispatch({
                    type: 'setListError',
                    errorMessage: APP_STRINGS.forms.permissions.messages.fetchError,
                    fetchError: true,
                    errorId: fetchErrorId,
                });
            })
            .finally(() => {
                if (!dialogIsMounted.current) {
                    return;
                }
                dispatch({
                    type: 'setLoading',
                    loading: false,
                });
                dispatch({ type: 'removeAsyncOperation', operation: promise });
            });
    }, [membershipService, dashboardId, dialogIsMounted, fetchError, dispatch]);

    const handleOnClose = useCallback(() => {
        if (!loading && pendingAsyncOperations.size === 0) {
            onClose();
            return;
        }
        globalDispatch(createClosePermissionsAction({ onComplete: onClose }));
    }, [loading, pendingAsyncOperations, onClose, globalDispatch]);

    useEffect(() => {
        fetchPermissions();
        // This should only run on the initial fetch.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PanelWithFooter
            isOpen={true}
            type={PanelType.largeFixed}
            onRenderHeader={() => (
                <RtdHeader level={1} className={styles.permissionHeader}>
                    {APP_STRINGS.forms.permissions.title} <i>{dashboardTitle}</i>
                </RtdHeader>
            )}
            closeButtonAriaLabel={APP_STRINGS.forms.permissions.closePermissionPanelButtonAriaLabel}
            isFooterAtBottom={true}
            isLightDismiss={true}
            // Panel bug workaround: https://github.com/microsoft/fluentui/issues/6476
            onOuterClick={noop}
            footerLeft
            footer={
                <ButtonWithCallout
                    component={DefaultButton}
                    onClick={copyLink}
                    calloutContent={APP_STRINGS.utilButtons.copyLinkSuccess}
                >
                    {APP_STRINGS.utilButtons.copyLink}
                </ButtonWithCallout>
            }
            onDismiss={handleOnClose}
        >
            <>
                {canEdit && (
                    <AddPermission
                        dispatch={dispatch}
                        state={state}
                        dashboardId={dashboardId}
                        dashboardTitle={dashboardTitle}
                        isMounted={dialogIsMounted}
                    />
                )}
                <div
                    className={styles.sectionTitle}
                >{`${APP_STRINGS.forms.permissions.members} (${sortedList.length})`}</div>
                {listErrors &&
                    Object.entries(listErrors).map(([errorId, errorMessage]) => (
                        <ErrorBanner
                            key={errorId}
                            errorId={errorId}
                            errorMessage={errorMessage}
                            state={state}
                            dispatch={dispatch}
                            fetchMembers={fetchPermissions}
                        />
                    ))}
                {loading && !fetchError ? (
                    <Spinner label={APP_STRINGS.forms.permissions.loadingPermissions} />
                ) : (
                    <>
                        <SearchBox
                            className={styles.searchBar}
                            placeholder={APP_STRINGS.forms.permissions.searchMembersPlaceholder}
                            value={searchQuery}
                            onChange={onChangeSearch}
                            onClear={onClearSearch}
                            title={APP_STRINGS.forms.permissions.searchMembersPlaceholder}
                        />
                        <PermissionsList
                            items={sortedList}
                            fetchedPersonas={fetchedPersonas}
                            dashboardId={dashboardId}
                            dispatch={dispatch}
                            canEdit={canEdit}
                            isMounted={dialogIsMounted}
                        />
                    </>
                )}
            </>
        </PanelWithFooter>
    );
};

interface ErrorBannerProps {
    errorId: string;
    errorMessage: string;
    dispatch: PermissionsFormDispatch;
    fetchMembers: () => void;
    state: PermissionsFormReducerState;
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ errorId, errorMessage, dispatch, fetchMembers, state }) => {
    const onDismissError = useCallback(() => dispatch({ type: 'removeListError', errorId }), [errorId, dispatch]);
    const { loading, fetchError } = state;

    return (
        <MessageBanner
            key={errorId}
            className={styles.errorMessageBar}
            message={errorMessage}
            messageBarType={MessageBarType.error}
            onDismiss={onDismissError}
            isMultiline={true}
            actions={
                fetchError ? (
                    <DefaultButton className={styles.refreshButton} onClick={fetchMembers}>
                        <SpinnerText on={loading}>{APP_STRINGS.utilButtons.refresh}</SpinnerText>
                    </DefaultButton>
                ) : undefined
            }
        />
    );
};

export const updatePermissionsPanelAction = formActionMacro(UpdatePermissionsPanel, 'updatePermissions');
