import * as React from 'react';
import { useMemo, useCallback, useEffect, useRef } from 'react';
import { assertNever, IDropdownOption } from 'office-ui-fabric-react';

import { APP_STRINGS, APP_CONSTANTS } from '../../../res';
import { RTDBackingDropdown, RTDDropdownOption } from '../../fabric';
import { ok, useThunkReducer } from '../../../common';
import { useCore } from '../../../core';
import { rtdNullValue, BasicParamValue, tagScalar, RtdValue, useTimeZone } from '../../../domain';

import { ParameterDropdown } from '../ParameterDropdown';
import {
    reducer,
    IPrimitiveFreeTextParameterSelectorState,
    IPrimitiveFreeTextParameterAction,
} from './primitiveFreeTextReducer';
import { IParameterSelectorProps2 } from './types';

interface IPrimitiveFreeTextParameterSelectorProps<T, K extends string> extends IParameterSelectorProps2 {
    parameterValue: BasicParamValue<T, K>;
    onChange(value: BasicParamValue<T, K>): void;
}

export const allSelectionDropdownOption: RTDDropdownOption = {
    key: APP_CONSTANTS.parameter.allSelection,
    text: APP_STRINGS.domain.parameter.selection.allTitle,
};

const formatInputPlaceholder = (type: RtdValue.BasicType) =>
    `${APP_STRINGS.parameterDropdown.formatInputPlaceholder} (${APP_STRINGS.domain.value.dataTypes[type]})`;

export const FreeTextParameterSelector = <T, K extends RtdValue.BasicType>({
    parameterValue,
    isActive,
    variableNames,
    onChange,
}: IPrimitiveFreeTextParameterSelectorProps<T, K>) => {
    const { userSessionCache } = useCore();
    const timeZone = useTimeZone();

    const defaultEnteredText = useMemo(() => {
        if (parameterValue.data === undefined) {
            return '';
        }
        switch (parameterValue.data.kind) {
            case 'null':
                return '';
            case 'array':
                return parameterValue.config.impl.valueToEditString(parameterValue.data.values[0], timeZone);
            case 'scalar':
                return parameterValue.config.impl.valueToEditString(parameterValue.data.value, timeZone);
            default:
                assertNever(parameterValue.data);
        }
    }, [parameterValue, timeZone]);

    const [{ maybeValue, recents }, localDispatch, getState] = useThunkReducer<
        IPrimitiveFreeTextParameterSelectorState<T, K>,
        IPrimitiveFreeTextParameterAction,
        undefined
    >(reducer, undefined, (): IPrimitiveFreeTextParameterSelectorState<T, K> => {
        let value: T | typeof APP_CONSTANTS.parameter.allSelection;

        if (parameterValue.data === undefined) {
            value = APP_CONSTANTS.parameter.allSelection;
        } else {
            switch (parameterValue.data.kind) {
                case 'null':
                    value = APP_CONSTANTS.parameter.allSelection;
                    break;
                case 'array':
                    value = parameterValue.data.values[0];
                    break;
                case 'scalar':
                    value = parameterValue.data.value;
                    break;
                default:
                    assertNever(parameterValue.data);
            }
        }

        return {
            enteredText: defaultEnteredText,
            parameterConfig: parameterValue.config,
            maybeValue: ok(value),
            recents: [defaultEnteredText],
            submitOnDismiss: true,
        };
    });

    const isInitialRenderRef = useRef(true);

    const { submit, onDismiss } = useMemo(() => {
        const innerSubmit = (isDismiss: boolean) => {
            const { submitOnDismiss } = getState();

            if (isDismiss && !submitOnDismiss) {
                return;
            }

            if (maybeValue.kind === 'err') {
                // Nothing to set
                // Restore original enteredText value
                localDispatch({
                    type: 'setEnteredText',
                    text: defaultEnteredText,
                    timeZone,
                });
                return;
            }

            localDispatch({ type: 'submitValue' });

            const newValue: RtdValue.Value<T, K> =
                maybeValue.value === APP_CONSTANTS.parameter.allSelection
                    ? rtdNullValue
                    : tagScalar(parameterValue.config.dataType, maybeValue.value);
            onChange(new BasicParamValue(parameterValue.config, newValue));
        };

        // Split into two functions; one for normal submit, and one for potential submit, through dismiss
        return {
            submit: () => innerSubmit(false),
            onDismiss: () => innerSubmit(true),
        };
    }, [getState, maybeValue, localDispatch, onChange, parameterValue.config, defaultEnteredText, timeZone]);

    const onChangeParameterOption = useCallback(
        (_: unknown, option?: IDropdownOption) => {
            if (!option) {
                return;
            }

            const key = option.key as string;

            localDispatch({
                type: 'setEnteredText',
                text: key !== APP_CONSTANTS.parameter.allSelection ? key : '',
                timeZone,
            });

            submit();
        },
        [submit, localDispatch, timeZone]
    );

    const backingDropdown = useMemo(
        (): RTDBackingDropdown => ({
            type: 'input',
            validationError: maybeValue.err,
            onEnter: submit,
            onInputChange: (_, newValue) => {
                if (newValue !== undefined) {
                    localDispatch({
                        type: 'setEnteredText',
                        text: newValue,
                        timeZone,
                    });
                }
            },
            onEscape: (event) => {
                event.preventDefault();
                event.stopPropagation();

                // On escape, prevent onDismiss from submitting value
                localDispatch({
                    type: 'disableSubmitOnDismiss',
                });
            },
        }),
        [maybeValue.err, timeZone, submit, localDispatch]
    );

    // Update enteredText if the default changed (through URL or similar)
    useEffect(
        () =>
            localDispatch({
                type: 'setEnteredText',
                text: defaultEnteredText,
                timeZone,
            }),
        [defaultEnteredText, timeZone, localDispatch]
    );

    useEffect(() => {
        if (!isInitialRenderRef.current) {
            userSessionCache.saveParameterRecents(parameterValue.config.id, parameterValue.dataType, recents);
        }
    }, [recents, parameterValue, userSessionCache]);

    useEffect(() => {
        let canceled = false;

        userSessionCache.getParameterRecents(parameterValue.config.id, parameterValue.dataType).then((recentEntry) => {
            if (canceled) {
                return;
            }
            localDispatch({ type: 'appendRecents', recentsToAppend: recentEntry.recents });
        });

        return () => {
            canceled = true;
        };
    }, [localDispatch, parameterValue.config.id, parameterValue.dataType, userSessionCache]);

    useEffect(() => {
        if (!isInitialRenderRef.current) {
            return;
        }

        isInitialRenderRef.current = false;
    }, []);

    const placeholder = formatInputPlaceholder(parameterValue.dataType);

    const pillTextProps = useMemo((): {
        options: RTDDropdownOption[];
        selectedKey: string;
    } => {
        /**
         * We have to build out the recent "options"
         * for the dropdown since it's not handled
         * by the ParameterDropdown itself
         */
        const options = recents.map((recentText) => {
            if (recentText === '') {
                return allSelectionDropdownOption;
            }

            return {
                key: recentText,
                text: recentText,
            };
        });

        return {
            options,
            selectedKey: defaultEnteredText === '' ? allSelectionDropdownOption.key : defaultEnteredText,
        };
    }, [defaultEnteredText, recents]);

    return (
        <ParameterDropdown
            {...pillTextProps}
            name={parameterValue.config.displayName}
            ariaLabel={placeholder}
            placeholder={placeholder}
            onChange={onChangeParameterOption}
            disabled={!isActive}
            isPill
            isSelectAll={false}
            containsSelectAll={false}
            variables={variableNames}
            // Only show variables if not active
            showVariablesInPill={!isActive}
            onDismiss={onDismiss}
            backingDropdown={backingDropdown}
        />
    );
};
