import { APP_STRINGS } from '../../../../../res';

import { ETPManagedConfigKey } from '../../../types';
import { axisScaleOptions } from '../../../constants';

import {
    createStaticDropdown,
    createBoolToggle,
    createTextInput,
    ColorRulesManagedConfig,
    createSingleColumConfigOption,
    createInferableMultipleColumnOption,
    createMultipleColumnOption,
} from '../configUtils';
import { ManagedConfigComponent } from '../types';
import { ETPSlotInput } from '../configUtils/slot/ETPSlotInput';
import { MultipleYAxesManagedConfig } from '../configUtils/multipleYAxes/MultipleYAxes';

import { YAxisMinimumValue, YAxisMaximumValue } from './yAxisLimits';
import { VisualTypeVariant } from './VisualTypeVariant';
import { CrossFilterManagedConfig } from './CrossFilter';

const localAppStrings = APP_STRINGS.editTilePage.visualConfig;

const hideLegend = createBoolToggle('hideLegend', localAppStrings.hideLegendCheckboxLabel);
const hideDataLabels = createBoolToggle('hideDataLabels', localAppStrings.hideDataLabelsCheckboxLabel);
const hideTileTitle = createBoolToggle('hideTileTitle', localAppStrings.hideTileTitleCheckboxLabel);

const yAxisRight = createStaticDropdown(
    'yAxisRight',
    localAppStrings.yAxisPosition.label,
    [
        { key: 'right', text: 'Right' },
        { key: 'left', text: 'Left' },
    ],
    (o) => (o ? 'right' : 'left'),
    (o) => o.key === 'right'
);

const xAxisScale = createStaticDropdown(
    'xAxisScale',
    localAppStrings.axisScale.label.xAxisScale,
    axisScaleOptions,
    (o) => o,
    (o) => o.key
);

const yAxisScale = createStaticDropdown(
    'yAxisScale',
    localAppStrings.axisScale.label.yAxisScale,
    axisScaleOptions,
    (o) => o,
    (o) => o.key
);

const legendLocation = createStaticDropdown(
    'legendLocation',
    localAppStrings.legendLocationDropdown.label,
    [
        { key: 'left', text: localAppStrings.legendLocationDropdown.leftOption },
        { key: 'right', text: localAppStrings.legendLocationDropdown.rightOption },
    ],
    (o) => o,
    (o) => o.key
);

const colorStyle = createStaticDropdown(
    'colorStyle',
    localAppStrings.colorStyleDropdown.label,
    [
        { key: 'light', text: localAppStrings.colorStyleDropdown.lightOption },
        { key: 'bold', text: localAppStrings.colorStyleDropdown.boldOption },
    ],
    (o) => o,
    (o) => o.key
);

const line__hidePinpointTooltips = createBoolToggle(
    'line__hidePinpointTooltips',
    localAppStrings.line__hidePinpointTooltips
);

const map__bubbleFormat = createStaticDropdown(
    'map__bubbleFormat',
    localAppStrings.map__bubbleFormatDropdown.label,
    [
        { key: 'dot', text: localAppStrings.map__bubbleFormatDropdown.dotOption },
        {
            key: 'bubble',
            text: localAppStrings.map__bubbleFormatDropdown.bubbleOption,
        },
        {
            key: 'heatmap',
            text: localAppStrings.map__bubbleFormatDropdown.heatmapOption,
        },
        {
            key: 'pieChart',
            text: localAppStrings.map__bubbleFormatDropdown.pieChartOption,
        },
    ],
    (o) => o,
    (o) => o.key
);

const map__minBubbleSizeColumn = createSingleColumConfigOption(
    'map__minBubbleSizeColumn',
    localAppStrings.map__minBubbleSizeColumnDropdownLabel
);

const map__latitudeColumn = createSingleColumConfigOption(
    'map__latitudeColumn',
    localAppStrings.map__latitudeColumnDropdownLabel
);

const map__longitudeColumn = createSingleColumConfigOption(
    'map__longitudeColumn',
    localAppStrings.map__longitudeColumnDropdownLabel
);

const multiStat__displayOrientation = createStaticDropdown(
    'multiStat__displayOrientation',
    localAppStrings.multiStat__displayOrientation.label,
    [
        {
            key: 'vertical',
            text: localAppStrings.multiStat__displayOrientation.vertical,
        },
        {
            key: 'horizontal',
            text: localAppStrings.multiStat__displayOrientation.horizontal,
        },
    ],
    (o) => o,
    (o) => o.key
);

const multiStat__textSize = createStaticDropdown(
    'multiStat__textSize',
    localAppStrings.multiStat__textSize.label,
    [
        { key: 'auto', text: localAppStrings.multiStat__textSize.auto },
        { key: 'small', text: localAppStrings.multiStat__textSize.small },
        { key: 'large', text: localAppStrings.multiStat__textSize.large },
    ],
    (o) => o,
    (o) => o.key
);

const multiStat__labelColumn = createSingleColumConfigOption(
    'multiStat__labelColumn',
    localAppStrings.multiStat__labelColumnLabel
);

const multiStat__valueColumn = createSingleColumConfigOption(
    'multiStat__valueColumn',
    localAppStrings.multiStat__valueColumnLabel
);

const heatMap__dataColumn = createSingleColumConfigOption(
    'heatMap__dataColumn',
    localAppStrings.heatMap__dataColumnLabel
);

const table__enableRenderLinks = createBoolToggle(
    'table__enableRenderLinks',
    APP_STRINGS.editTilePage.visualConfigSections.urlLinksToggleTitleText
);

const table__renderLinksForColumns = createMultipleColumnOption(
    'table__renderLinksForColumns',
    localAppStrings.table__renderLinksForColumnsOptionLabel
);

// Options that take up more than one line should be defined outside of this object
/**
 * Contains all of the components that will be layed out by the visual config
 * through it's sections.
 */
export const managedConfigComponents: Record<ETPManagedConfigKey, ManagedConfigComponent> = {
    visualTypeVariant: VisualTypeVariant,
    xColumn: createSingleColumConfigOption('xColumn', localAppStrings.xColumnDropdownLabel),
    yColumn: createSingleColumConfigOption('yColumn', localAppStrings.yColumnDropdownLabel),
    yColumns: createInferableMultipleColumnOption('yColumns', localAppStrings.yColumnsDropdownLabel),
    seriesColumns: createInferableMultipleColumnOption('seriesColumns', localAppStrings.seriesColumnsDropdownLabel),
    horizontalLine: createTextInput('horizontalLine', localAppStrings.horizontalLineInputLabel),
    verticalLine: createTextInput('verticalLine', localAppStrings.verticalLineInputLabel),
    hideLegend,
    hideDataLabels,
    hideTileTitle,
    yAxisRight,
    multipleYAxes: MultipleYAxesManagedConfig,
    yAxisMinimumValue: YAxisMinimumValue,
    yAxisMaximumValue: YAxisMaximumValue,
    xColumnTitle: createTextInput('xColumnTitle', localAppStrings.xColumnTitleInputLabel),
    yColumnTitle: createTextInput('yColumnTitle', localAppStrings.yColumnTitleInputLabel),
    xAxisScale,
    yAxisScale,
    legendLocation,
    colorRulesDisabled: createBoolToggle('colorRulesDisabled', localAppStrings.colorRules.disableColorRulesToggleLabel),
    colorRules: ColorRulesManagedConfig,
    colorStyle,
    crossFilterDisabled: createBoolToggle(
        'crossFilterDisabled',
        localAppStrings.colorRules.disableColorRulesToggleLabel
    ),
    crossFilter: CrossFilterManagedConfig,
    line__hidePinpointTooltips,
    map__bubbleFormat,
    map__minBubbleSizeColumn,
    map__latitudeColumn,
    map__longitudeColumn,
    multiStat__displayOrientation,
    multiStat__textSize,
    multiStat__labelColumn,
    multiStat__valueColumn,
    multiStat__slot: ETPSlotInput,
    heatMap__dataColumn,
    table__enableRenderLinks,
    table__renderLinksForColumns,
};
