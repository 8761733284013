import { useCallback, useContext } from 'react';

import { dashboardsCoreContext } from '../core/react/context';

/**
 * @returns Returns a warning string when dashboards has unsaved changes,
 * undefined otherwise
 */
export function useGetDashboardsUnsaved() {
    const core = useContext(dashboardsCoreContext);

    return useCallback(() => {
        if (core.status !== 'ready') return undefined;

        return core.appContext.history.leaveDashboardsBlocked();
    }, [core]);
}
