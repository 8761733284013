import { gzip } from 'pako';
import * as rp from 'request-promise-native';
import { Tabs, NotificationType } from './rootStore';
import { Cluster, getClusterAndDatabaseFromEntity, Database } from './cluster';
import { ConnectionPane } from './connectionPane';
import { DeepLinkProperties } from '../utils/deepLinkParser';
import { getTelemetryClient } from '../utils/telemetryClient';
import { Tab, QueryContextInfo } from './tab';
import { testConnection } from '../utils/testConnection';
import { dependencies } from '../dependencies';
import { AppPages } from '../common/types';
import { assertNever } from '../utils/types';

const { trackEvent, trackException } = getTelemetryClient({
    component: 'deepLinkHandler',
    flow: 'handleDeepLink',
});

const setQueryTextAsync = async (tab?: Tab, query?: string, querySrc?: string) => {
    if (!tab) {
        return false;
    }
    // If we didn't provide a query but did provide a query source, get the query from there.
    if (!query && querySrc) {
        try {
            query = (await getQueryFromBlob(querySrc)) as string;
        } catch (ex) {
            trackException(ex);
        }
    }

    if (query) {
        tab.setText(query);
        return true;
    }
    return false;
};

/**
 * make local state changes to handle cluster / database / query deep links.
 * Deep links come in 2 flavors:
 * 1. change entity in context for the current tab. This is useful for cases like #connect directives
 * 2. try to find a tab that already has the entity in context, or create a new tab if none is found.
 *    this is more suitable when this is an actually browsed deep link. Note that if there's a query
 *    in the deep link, logic will open a new tab anyway - in order not to mess with the existing text.
 * creates a new tab, fetches cluster schema and executes the query.
 * @param connectionPane the connection pane store
 * @param tabs the tabs store
 * @param deepLink the deep link to handle
 * @param useCurrentTab whether should use the current tab in context or create a new tab
 * @param shouldRefetchSchema should get the schema from server if we already have the cluster in the client side
 */
export const handleDeepLink = async (
    connectionPane: ConnectionPane,
    tabs: Tabs,
    deepLinkProperties: DeepLinkProperties,
    useCurrentTab: boolean,
    shouldRefetchSchema: boolean,
    emptyOnDeepLink = false
) => {
    const { clusterName, connectionString, databaseName, deepLink, forget, querySrc, query } = deepLinkProperties;

    trackEvent('handleDeepLink.Start', {
        deepLink: JSON.stringify(deepLinkProperties),
        useCurrentTab: useCurrentTab.toString(),
    });

    const pathname = deepLink.pathname;
    if (forget) {
        window.history.pushState(null, '', pathname);
    }

    if (!clusterName || !connectionString) {
        return;
    }

    const linkHasQuery = !!query || !!querySrc;
    const tab = findOrCreateTab(
        linkHasQuery,
        tabs,
        useCurrentTab || (linkHasQuery && emptyOnDeepLink),
        clusterName,
        databaseName
    );

    const queryUpdated = setQueryTextAsync(tab, query, querySrc);

    // Perform a case-insensitive search for the cluster.
    let cluster = Array.from(connectionPane.connections.values()).find(
        (k) => k.connectionString.toLowerCase() === connectionString.toLowerCase()
    );

    if (!cluster) {
        const testResult = await testConnection(clusterName, connectionString);
        if (testResult.isError) {
            connectionPane.setNotification({
                title: dependencies.strings.deepLinkHandler$connectionTestFailed,
                content: testResult.description + '\n' + connectionString,
                type: NotificationType.Error,
                fadeOut: false,
            });

            return;
        }
        cluster = await connectionPane.addConnection(clusterName, connectionString);
    } else {
        connectionPane.setEntityInContextByObject(cluster);
        if (shouldRefetchSchema) {
            await cluster.fetchCurrentSchema(dependencies.featureFlags.RefreshConnection || false, false);
        }

        tab.setEntityInContext(cluster);
    }

    if (databaseName && cluster) {
        const lowerCaseDbName = databaseName.toLowerCase();
        // look for the right database in name or pretty name (case insensitive)
        const databases = Array.from(cluster.databases.values());
        const database = databases.find((candidateDb) => {
            return (
                candidateDb.name.toLowerCase() === lowerCaseDbName ||
                (!!candidateDb.prettyName && candidateDb.prettyName.toLowerCase() === lowerCaseDbName)
            );
        });

        if (database) {
            tab.setEntityInContext(database);

            if (connectionPane.entityInContext !== database && tabs.tabInContext === tab) {
                connectionPane.setEntityInContextByObject(database);
            }

            if (await queryUpdated) {
                tab.requestRun('ReadOnly');
            }
        }
    }
};

export const getRelativeUrlForEntity = (
    entity: Cluster | Database,
    appPage?: AppPages,
    rout?: string,
    path?: string
): string => {
    const { cluster, database } = getClusterAndDatabaseFromEntity(entity);

    const clusterPath = `clusters/${cluster.name}`;
    const databasePath = database ? `databases/${database.name}` : '';

    const parts = [appPage as string, rout, clusterPath, databasePath, path];

    return '/' + parts.filter((part) => part && part.length > 0).join('/');
};

type DeepLinkTool = 'self' | 'kustoDesktop' | 'kustoWeb';

/**
 *
 * Creates a deep link to a query.
 * @param queryInfo
 * @param tool can be self (for same websites we're running on), kustoweb - for kwe, kustoDesktop - for desktop app. default - self.
 */
export const createDeepLinkToQuery = (queryInfo: QueryContextInfo, tool?: DeepLinkTool): string | undefined => {
    let queryText = queryInfo.query;
    if (!queryText || queryText.length === 0) {
        return undefined;
    }

    queryText = queryText.trim();

    const queryParam = createQueryParam(queryText);

    switch (tool) {
        case undefined:
        case 'self':
            const href = window.location.href;
            const separator = href.indexOf('?') === -1 ? '?' : '&';
            const deepLinkUrl = `${href}${separator}${queryParam}`;
            return deepLinkUrl;
        case 'kustoWeb':
            return `${queryInfo.url}${queryInfo.dbname}?${queryParam}&web=1`;
        case 'kustoDesktop':
            return `${queryInfo.url}${queryInfo.dbname}?${queryParam}&web=0`;
        default:
            assertNever(tool);
    }
};

export const createQueryParam = (query: string) => {
    const zipped = gzip(query, { to: 'string' });
    const encoded = btoa(zipped);
    return `query=${encoded}`;
};

export const createAllDeepLinks = (queryInfo: QueryContextInfo): { [ind in DeepLinkTool]: string | undefined } => {
    return {
        self: createDeepLinkToQuery(queryInfo, 'self'),
        kustoDesktop: createDeepLinkToQuery(queryInfo, 'kustoDesktop'),
        kustoWeb: createDeepLinkToQuery(queryInfo, 'kustoWeb'),
    };
};

function getQueryFromBlob(connectionString: string) {
    return rp.get(connectionString);
}

/**
 * If there's a query in the deep link create a new tab. otherwise
 * try to find an existing tab with the same entity in context, unless requested by caller to use current tab.
 */
function findOrCreateTab(
    linkHasQuery: boolean,
    tabs: Tabs,
    useCurrentTab: boolean,
    clusterName: string,
    databaseName: string | undefined
): Tab {
    // If we were requested to kusto reuse the current tab, we'll do nothing.
    if (useCurrentTab && tabs.tabInContext) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return tabs.tabInContext!;
    }

    // If the tab in context doesn't have any entity or text, just reuse it.
    // This happens if you are accessing web explorer for the first time using a deep link.
    // Up until this change you would get 2 tabs = one empty and without and entity in context, and the other is the deep link.
    if (tabs.tabInContext && tabs.tabInContext.text === '' && !tabs.tabInContext.entityInContext) {
        return tabs.tabInContext;
    }

    // If deep link has query, we will always add a new tab and paste the query there.
    if (linkHasQuery) {
        return tabs.addTab();
    }

    // Try to find a tab that's already connected to the right entity, otherwise create new tab.
    const relevantTabs = tabs.tabs.filter((tab) => {
        if (!tab.entityInContext) {
            // If this is an empty tab without any context, we may reuse it.
            // If not, we won't reuse it
            return tab.text === undefined || tab.text === null || tab.text === '';
        }

        const clusterAndDatabaseInContext = getClusterAndDatabaseFromEntity(tab.entityInContext);
        if (clusterAndDatabaseInContext.cluster.name.toLowerCase() !== clusterName.toLowerCase()) {
            return false;
        }

        // Link to a cluster, so if our tab has that cluster in context (and not one of its databases)
        // it's a good candidate
        if (!databaseName) {
            return !clusterAndDatabaseInContext.database;
        }

        // The link is to a database. if no database is in context, we cannot reuse this tab.
        if (!clusterAndDatabaseInContext.database) {
            return false;
        }

        // if the deep link fits the database name or pretty name, we can reuse it.
        const dbNameLowerCase = databaseName.toLowerCase();
        const prettyNameInContext = clusterAndDatabaseInContext.database.prettyName;
        return (
            clusterAndDatabaseInContext.database.name.toLowerCase() === dbNameLowerCase ||
            (prettyNameInContext && prettyNameInContext.toLowerCase() === dbNameLowerCase)
        );
    });

    // couldn't find a tab with the right context. we'll create a new one.
    if (relevantTabs.length <= 0) {
        return tabs.addTab();
    }

    // if the tabInContext is relevant, just return it and don't change the tab in context
    const relevantTabInContext = relevantTabs.find((t) => t.id === tabs.tabInContext.id);
    if (relevantTabInContext !== undefined) {
        return relevantTabInContext;
    }

    // Otherwise, take the 1st tab that matches the required entity
    tabs.setTabInContext(relevantTabs[0]);
    return relevantTabs[0];
}
